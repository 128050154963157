<template>
  <div class="day-picker">
    <v-checkbox
      :disabled="disabled"
      v-for="day in weekdays"
      v-model="selectedDays"
      :value="day"
      :key="day"
      :class="{ selected: isSelected(day) }"
      multiple
      @change="changed"
    >
      <template v-slot:label>
        <div class="day-input" v-if="isMobile">{{ day | firstLetter }}</div>
        <div class="day-input" v-else>{{ day | firstThreeLetters }}</div>
      </template>
    </v-checkbox>
  </div>
</template>

<script>
export default {
  name: "DayPicker",
  data() {
    return {
      selectedDays: [...this.initialValue],
      weekdays: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday"
      ]
    };
  },
  methods: {
    isSelected(day) {
      return this.selectedDays.includes(day);
    },
    changed() {
      this.$emit("day-picked", this.selectedDays);
    }
  },
  created() {
    this.selectedDays = this.initialValue;
  },
  props: {
    selected: Boolean,
    disabled: Boolean,
    initialValue: Array
  },
  filters: {
    firstThreeLetters: value => value.substring(0, 3),
    firstLetter: value => value[0]
  },
  watch: {
    initialValue(initialValues) {
      this.selectedDays = [...initialValues];
    }
  }
};
</script>

<style lang="scss"></style>
