import { setStoreValue } from "@/store/utils";

export const SET_ORGANISATION_USERS = "SET_ORGANISATION_USERS";
export const SET_IS_FETCHING_USERS = "SET_IS_FETCHING_ORGANISATION_USERS";

const mutations = {
  [SET_IS_FETCHING_USERS]: setStoreValue("isFetchingUsers"),
  [SET_ORGANISATION_USERS]: setStoreValue("organisationUsers")
};

export default mutations;
