<template>
  <div class="inline-filters">
    <div
      v-for="(filterOptions, filterKey, index) in availableFilters"
      :key="index"
    >
      <Permissioned section="shifts.filters">
        <InlineFilter
          v-if="size(filterOptions)"
          :label="getFilterTitle(filterKey)"
          :filters="filters[filterKey]"
          :options="filterOptions"
          @clear="handleClear(filterKey)"
        >
          <RadioButton
            v-if="filterKey === 'filledStatus'"
            :key="filters[filterKey]"
            :options="getFilterOptions(filterOptions, filterKey)"
            @select="values => updateFilterFor(filterKey, values)"
            :selected-value="filters[filterKey]"
          />
          <FilterCheckbox
            v-else
            :options="getFilterOptions(filterOptions, filterKey)"
            :selectedValues="filters[filterKey]"
            @change="values => updateFilterFor(filterKey, values)"
          />
        </InlineFilter>
        <InlineFilter
          v-if="index === size(availableFilters) - 1"
          label="Start Date"
          :filters="[filters.startDate, filters.startDateUntil]"
          @clear="clearDates(['startDate', 'startDateUntil'])"
          isDate
          rangeDate
        >
          <DatePicker
            :key="filters.startDate"
            :inputValue="filters.startDate"
            @updatedDate="value => updateFilterFor('startDate', value)"
            @clear="clearDates(['startDate'])"
            label=""
            clearable
          />
          <DatePicker
            :key="filters.startDateUntil"
            :inputValue="filters.startDateUntil"
            @updatedDate="value => updateFilterFor('startDateUntil', value)"
            @clear="clearDates(['startDateUntil'])"
            label="Until"
            clearable
          />
        </InlineFilter>
      </Permissioned>
    </div>
  </div>
</template>

<script>
import DatePicker from "@/components/common/DatePicker";
import Permissioned from "@/components/common/Permissioned";
import { size, map } from "lodash";
import { filterMixin } from "@/mixins/filter.mixin";
import RadioButton from "@/components/common/RadioButton";

export default {
  components: {
    Permissioned,
    DatePicker,
    RadioButton
  },
  mixins: [filterMixin],
  props: {
    isLoading: Boolean,
    outerSelectedFilters: {
      type: Object,
      default: () => ({})
    },
    filterLabels: {
      type: Object,
      default: () => ({})
    },
    availableFilters: Object
  },
  methods: {
    getFilterTitle(filterName) {
      const label = this.filterLabels[filterName];
      if (label) {
        return label;
      }
      switch (filterName) {
        case "bookingId":
          return "Booking";
        case "addressId":
          return "Location";
        case "filledStatus":
          return "Shifts Filled";
        default:
          return filterName;
      }
    },
    clearDates(fields) {
      map(fields, field => (this.filters[field] = null));
      this.saveChanges();
    },
    size
  }
};
</script>
