<template>
  <AppLayout class="finance container col-lg-9 col-sm-12">
    <template v-slot:header>
      <BaseListToolbar
        title="Finance"
        @search="updateSearchValue"
        @sort="onSort"
        :sortingData="sortingData"
      />
    </template>
    <template v-slot:body>
      <v-tabs
        v-model="selectedTab"
        centered
        class="basic-tabs"
        @change="handleTabChange"
      >
        <v-tab v-for="sectionName in financeSections" :key="sectionName">
          {{ sectionName }}
        </v-tab>
      </v-tabs>
      <router-view
        :key="selectedTab"
        :search="search"
        @openDialog="setActiveDialog"
      />
    </template>
  </AppLayout>
</template>

<script>
import AppLayout from "@/components/common/base-layouts/AppLayout";
import GenerateInvoices from "@/views/finance/components/Generate/GenerateInvoices";
import GeneratePayroll from "@/views/finance/components/Generate/GeneratePayroll";
import { AUTH_NAMESPACE } from "@/store/modules/auth";
import { createNamespacedHelpers } from "vuex";
import BaseListToolbar from "@/components/common/Toolbar/BaseListToolbar";

const { mapState: authMapState } = createNamespacedHelpers(AUTH_NAMESPACE);
export default {
  components: {
    AppLayout,
    BaseListToolbar
  },
  data: () => ({
    search: null,
    selectedTab: null,
    activeDialog: null,
    generateDialogs: [GenerateInvoices, GeneratePayroll],
    sortingData: null
  }),
  computed: {
    ...authMapState(["permissions"]),
    financeSections() {
      const sections = [];
      if (this.permissions["finance.view-invoices"]) {
        sections.push("invoices");
      }

      if (this.permissions["finance.view-payroll"]) {
        sections.push("payroll");
      }

      return sections;
    }
  },
  methods: {
    handleTabChange() {
      const tabName = this.financeSections[this.selectedTab];
      this.$router.push({ name: `finance.${tabName}` });
    },
    setActiveDialog(name) {
      this.activeDialog = name;
    },
    isDialogOpen(name) {
      return this.activeDialog === name;
    },
    updateSearchValue(text) {
      this.search = text;
    },
    onSort() {
      //
    }
  }
};
</script>

<style lang="scss">
.finance {
  position: relative;

  .v-input__icon.v-input__icon--append {
    display: none;
  }

  @media only screen and (max-width: $tablet-breakpoint - 1px) {
    .desktop-button {
      display: none;
    }

    .v-input__icon.v-input__icon--append {
      display: none;
    }
  }
}
</style>
