<template>
  <div class="input-item input-grouping">
    <div class="inputs">
      <Select
        autocomplete
        placeholder="Field"
        :items="allFieldsNames"
        item-text="text"
        item-value="field"
        :value="field"
        @change="handleFieldChange"
      />
      <div class="by-label" v-if="field.intervals && field.intervals.length">
        by
      </div>
      <ValidationProvider
        v-slot="{ errors }"
        :name="`interval${index}`"
        v-if="withInterval"
        rules="required"
      >
        <v-select
          placeholder="Interval"
          :items="intervals"
          :value="grouping.interval_number"
          :errorMessages="showValidationErrors ? errors : ''"
          item-text="text"
          @change="handleChange('interval_number')($event)"
        />
      </ValidationProvider>
      <ValidationProvider
        v-slot="{ errors }"
        :name="`dateInterval${index}`"
        v-if="withDateInterval"
        rules="required"
      >
        <v-select
          placeholder="Interval"
          :items="intervals"
          item-text="text"
          :value="grouping.interval_date"
          :errorMessages="showValidationErrors ? errors : ''"
          @change="handleChange('interval_date')($event)"
        />
      </ValidationProvider>
    </div>
    <v-btn icon @click="onDelete"><v-icon>mdi-close</v-icon></v-btn>
  </div>
</template>

<script>
import { UNIT_TYPES } from "@/constants/reports";
import Select from "@/components/common/Select";
import { getFieldOptions, thousandSeparator } from "@/utils/reports";
import { ValidationProvider } from "vee-validate";
import { currencyFilter } from "@/utils/filters/currencyFilter";

export default {
  name: "ReportGrouping",
  components: {
    Select,
    ValidationProvider
  },
  props: {
    grouping: Object,
    availableFields: Array,
    onChange: Function,
    onDelete: Function,
    showValidationErrors: Boolean,
    labels: Object,
    index: Number
  },
  data() {
    return {
      unitTypes: UNIT_TYPES
    };
  },
  computed: {
    allFieldsNames() {
      return getFieldOptions({
        fields: this.availableFields,
        labels: this.labels
      });
    },
    field() {
      return this.availableFields.find(
        field => field.field === this.grouping.field
      );
    },
    intervals() {
      return this.field.intervals.map(value => {
        const text = format => {
          switch (format) {
            case "number":
              return thousandSeparator(value);
            case "currency":
              return currencyFilter(value + "00", this.currencyCode);
            case "datetime":
              return this.unitTypes[value];
            default:
              return value;
          }
        };
        return { value, text: text(this.field.type) };
      });
    },
    withInterval() {
      return (
        this.field.type !== "datetime" &&
        this.field.intervals &&
        this.field.intervals.length
      );
    },
    withDateInterval() {
      return (
        this.field.type === "datetime" &&
        this.field.intervals &&
        this.field.intervals.length
      );
    },
    currencyCode() {
      return "GBP";
    }
  },
  methods: {
    handleChange(key) {
      return value => {
        this.onChange({ ...this.grouping, [key]: value });
      };
    },
    handleFieldChange(fieldName) {
      const field = this.availableFields.find(
        field => field.field === fieldName
      );
      const interval =
        field.intervals && field.intervals.length ? field.intervals[0] : null;

      if (field.type === "datetime") {
        this.onChange({
          field: fieldName,
          interval_date: interval
        });
      } else {
        this.onChange({
          field: fieldName,
          interval_number: interval
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.input-grouping {
  .by-label {
    flex-grow: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    border: 1px solid $default-line;
    margin: 0 -1px;

    @media only screen and (max-width: $mobile-breakpoint) {
      display: none;
    }
  }
}
</style>
