import { ORGANISATION_MODEL } from "@/models/organisation-model";

export const PUBLISHING_SCHEDULE_MODEL = "publishing-schedule";

const publishingScheduleModel = {
  tierName: "",
  publishOnDate: "",
  state: "",
  agency: {
    jsonApi: "hasOne",
    type: ORGANISATION_MODEL
  },
  booking: {
    jsonApi: "hasOne",
    type: "booking"
  },
  quotas: {
    jsonApi: "hasOne",
    type: "publishing-schedule-quotas"
  }
};

export default publishingScheduleModel;
