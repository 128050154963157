import store from "@/store";
import { filter, get } from "lodash";

const getPermissionedTableHeaders = (data, section) =>
  filter(data, ({ permission }) => {
    return !permission || isPermissioned(`${section}.${permission}`);
  });

const isPermissioned = key => {
  return store && get(store.state.auth.permissions, key);
};

export { getPermissionedTableHeaders, isPermissioned };
