export const HELP_CENTRE_LINKS = {
  msp: "https://msp-help-centre.helpscoutdocs.com/",
  agency: "https://agency-help-centre.helpscoutdocs.com/",
  client: "https://client-help-centre.helpscoutdocs.com/",
  worker: "https://worker-help-centre.helpscoutdocs.com/"
};

export const MAIN_NAVIGATION_ITEMS = [
  // temporary icon fix for the safari
  {
    title: "Home",
    icon: require("@/assets/svg/navigation-drawer/dashboard_blue.svg"),
    permission: "dashboard",
    routeName: "dashboard"
  },
  {
    title: "Workers",
    icon: require("@/assets/svg/navigation-drawer/workers_blue.svg"),
    permission: "workers",
    routeName: "workers"
  },
  {
    title: "Files",
    icon: require("@/assets/svg/navigation-drawer/files_blue.svg"),
    permission: "files.view",
    routeName: "files",
    activeClass: "white-rect"
  },
  {
    title: "Bookings",
    icon: require("@/assets/svg/navigation-drawer/bookings_blue.svg"),
    permission: "bookings",
    routeName: "bookings"
  },
  {
    title: "Shifts",
    icon: require("@/assets/svg/shifts-icon-blue.svg"),
    permission: "shifts.navigation-view",
    routeName: "shifts",
    activeClass: "white-rect-nofill white-line-nofill"
  },
  {
    title: "Applications",
    icon: require("@/assets/svg/navigation-drawer/applications_blue.svg"),
    permission: "applications",
    routeName: "applications",
    description: "View your existing applications",
    activeClass: "white-line"
  },
  {
    title: "Timesheets",
    icon: require("@/assets/svg/timesheet-icon-blue.svg"),
    permission: "timesheets",
    routeName: "timesheets"
  },
  {
    title: "Finance",
    icon: require("@/assets/svg/navigation-drawer/finance_blue.svg"),
    permission: "finance",
    routeName: "finance",
    activeClass: "white-circle"
  },
  {
    title: "Agencies",
    icon: require("@/assets/svg/navigation-drawer/agencies_blue.svg"),
    permission: "agencies",
    routeName: "agencies"
  },
  {
    title: "Reports",
    icon: require("@/assets/svg/navigation-drawer/reports_blue.svg"),
    permission: "focused-reports.view",
    routeName: "reports",
    activeClass: "white-rect"
  },
  {
    title: "Exports",
    icon: require("@/assets/svg/navigation-drawer/reports_blue.svg"),
    permission: "reports",
    routeName: "exports",
    activeClass: "white-rect"
  }
];

export const SETTINGS_NAVIGATION_ITEM = {
  icon: require("@/assets/svg/navigation-drawer/settings_blue.svg"),
  permission: "settings",
  routeName: "settings/account",
  activeClass: "fill-none"
};

export const HELP_NAVIGATION_ITEM = {
  icon: require("@/assets/svg/navigation-drawer/help_blue.svg"),
  isExternalLink: true,
  href: "https://alvius-vms.zendesk.com/hc/en-gb"
};
