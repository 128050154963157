<template>
  <BaseAction>
    <SecondaryButton
      @click.native="openCancelBookingDialog"
      textColor="#d10a3a"
    >
      <v-icon class="mb-1">
        mdi-cancel
      </v-icon>
      Cancel Booking
    </SecondaryButton>
    <CancelBookingDialog
      :isOpen="isCancelBookingOpen"
      @close="closeCancelBookingDialog"
    />
  </BaseAction>
</template>

<script>
import CancelBookingDialog from "@/views/bookings/components/ConfirmDialogs/CancelBookingDialog";
import BaseAction from "@/components/common/BaseAction";
import SecondaryButton from "@/components/common/Button/SecondaryButton";

export default {
  name: "CancelBookingAction",
  components: { BaseAction, SecondaryButton, CancelBookingDialog },
  data() {
    return {
      isCancelBookingOpen: false
    };
  },
  methods: {
    openCancelBookingDialog() {
      this.isCancelBookingOpen = true;
    },
    closeCancelBookingDialog() {
      this.isCancelBookingOpen = false;
    }
  }
};
</script>
