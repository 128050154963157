<template>
  <div class="base-action">
    <slot />
  </div>
</template>

<script>
export default {
  name: "BaseAction"
};
</script>

<style scoped lang="scss">
.base-action {
  @media only screen and (max-width: $mobile-breakpoint - 1) {
    margin-bottom: 0.5rem;
  }
  @media only screen and (min-width: $mobile-breakpoint) {
    margin-right: 0.5rem;
  }
}
</style>
