<template>
  <div class="icon">
    <v-icon size="1.75rem">
      mdi-check-circle
    </v-icon>
  </div>
</template>

<script>
export default {
  name: "CheckIcon"
};
</script>

<style scoped lang="scss">
.icon {
  margin: auto 0.75rem;
  i {
    color: $success;
    width: auto;
  }
}
</style>
