<template>
  <div class="snackbar-wrapper">
    <v-snackbar top :timeout="0" color="error" :value="isVisible">
      <v-icon class="mr-3">"mdi-alert"</v-icon>
      <div v-if="error" class="text-wrapper">
        <div class="title">{{ error.title }}</div>
        <div class="subtitle-2">{{ error.detail }}</div>
      </div>
      <div v-else class="title">Something went wrong</div>
      <v-btn text @click="hideError(null)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  props: {
    error: {
      type: Object,
      required: false
    },
    isVisible: {
      type: Boolean,
      require: false
    },
    hideError: {
      type: Function,
      required: true
    }
  }
};
</script>

<style lang="scss">
.snackbar-wrapper {
  .text-wrapper {
    margin-bottom: 10px;
    word-break: break-word;
    display: flex;
    flex-direction: column;
  }
}
</style>
