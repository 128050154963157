<template>
  <div>
    <ErrorButton light shrink @click="isDialogOpen = true">
      Archive
    </ErrorButton>
    <BaseDialog :is-open="isDialogOpen">
      <template v-slot:title>
        Are you sure you want to archive this organisation unit?
      </template>
      <template v-slot:actions>
        <PrimaryButton text @click="close">Cancel</PrimaryButton>
        <ErrorButton @click="handleSubmit" :loading="isLoading">
          Archive
        </ErrorButton>
      </template>
    </BaseDialog>
  </div>
</template>

<script>
import BaseDialog from "@/components/common/BaseDialog";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import ErrorButton from "@/components/common/Button/ErrorButton";
import { ORGANISATION_UNITS_NAMESPACE } from "@/store/modules/organisations/organisation-units";
import { createNamespacedHelpers } from "vuex";
import { ARCHIVE_ORGANISATION_UNIT } from "@/store/modules/organisations/organisation-units/actions";

const { mapActions } = createNamespacedHelpers(ORGANISATION_UNITS_NAMESPACE);

export default {
  name: "ArchiveUnitDialog",
  components: { ErrorButton, PrimaryButton, BaseDialog },
  props: {
    id: [String, Number]
  },
  data() {
    return {
      isDialogOpen: false,
      isLoading: false
    };
  },
  methods: {
    ...mapActions({
      archiveOrganisationUnit: ARCHIVE_ORGANISATION_UNIT
    }),
    close() {
      this.isDialogOpen = false;
    },
    async handleSubmit() {
      this.isLoading = true;
      try {
        await this.archiveOrganisationUnit(this.id);
      } catch (e) {
        console.error(e);
      } finally {
        this.close();
        this.isLoading = false;
      }
    }
  }
};
</script>

<style scoped></style>
