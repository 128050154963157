<template>
  <BaseDialog :isOpen="isOpen">
    <template v-slot:title>
      <span>Are you sure you want to remove this reason for booking?</span>
    </template>
    <template v-slot:actions>
      <div class="remove-reason-actions">
        <PrimaryButton @click.native="close" text>
          Cancel
        </PrimaryButton>
        <ErrorButton @click.native="handleRemove" :loading="isLoading">
          Remove Reason
        </ErrorButton>
      </div>
    </template>
  </BaseDialog>
</template>

<script>
import BaseDialog from "@/components/common/BaseDialog";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import ErrorButton from "@/components/common/Button/ErrorButton";
import { createNamespacedHelpers } from "vuex";
import { DELETE_ORGANISATION_REASON } from "@/store/modules/organisations/organisation-reasons/actions";
import { ORGANISATION_REASONS_NAMESPACE } from "@/store/modules/organisations/organisation-reasons";

const { mapActions } = createNamespacedHelpers(ORGANISATION_REASONS_NAMESPACE);

export default {
  name: "RemoveReasonDialog",
  components: { ErrorButton, PrimaryButton, BaseDialog },
  data() {
    return {
      isLoading: false
    };
  },
  props: {
    isOpen: Boolean,
    id: String
  },
  methods: {
    ...mapActions({
      deleteReason: DELETE_ORGANISATION_REASON
    }),
    async handleRemove() {
      this.isLoading = true;
      try {
        await this.deleteReason(this.id);
        this.close();
      } finally {
        this.isLoading = false;
      }
    },
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss">
.remove-reason-actions {
  padding: 0.5rem;
}
</style>
