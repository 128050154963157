import { setStoreValue } from "@/store/utils";

export const SET_IS_FETCHING_BOOKING_TEMPLATES =
  "SET_IS_FETCHING_BOOKING_TEMPLATES";
export const SET_BOOKING_TEMPLATES = "SET_BOOKING_TEMPLATES";
export const SET_CURRENT_BOOKING_TEMPLATE = "SET_CURRENT_BOOKING_TEMPLATE";
export const SET_IS_ADDING_BOOKING_TEMPLATE = "SET_IS_ADDING_BOOKING_TEMPLATE";
export const SET_IS_FETCHING_BOOKING_TEMPLATE =
  "SET_IS_FETCHING_BOOKING_TEMPLATE";
export const SET_IS_EDITING_BOOKING_TEMPLATE =
  "SET_IS_EDITING_BOOKING_TEMPLATE";

const mutations = {
  [SET_IS_FETCHING_BOOKING_TEMPLATES]: setStoreValue(
    "isFetchingOrganisationBookingTemplates"
  ),
  [SET_BOOKING_TEMPLATES]: setStoreValue("organisationBookingTemplates"),
  [SET_IS_ADDING_BOOKING_TEMPLATE]: setStoreValue("isAddingBookingTemplate"),
  [SET_CURRENT_BOOKING_TEMPLATE]: setStoreValue("currentBookingTemplate"),
  [SET_IS_FETCHING_BOOKING_TEMPLATE]: setStoreValue(
    "isFetchingBookingTemplate"
  ),
  [SET_IS_EDITING_BOOKING_TEMPLATE]: setStoreValue("isEditingBookingTemplate")
};

const state = {
  organisationBookingTemplates: [],
  isFetchingOrganisationBookingTemplates: false,
  isAddingBookingTemplate: false,
  currentBookingTemplate: {},
  isFetchingBookingTemplate: false,
  isEditingBookingTemplate: false
};

export { mutations, state };
