export const BUDGET_MODEL = "budget";

const budgetModel = {
  po_number: 0,
  organisation_unit_id: "",
  total: 0,
  currency_code: 0,
  remaining_budget: 0
};

export default budgetModel;
