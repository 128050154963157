<template>
  <div class="data-table" :class="{ 'separator-hidden': hideRowSeparator }">
    <v-data-table
      item-key="id"
      :headers="headers"
      :loading="isLoading"
      :items="isLoading ? [] : items"
      :search="search"
      :mobile-breakpoint="null"
      :must-sort="mustSort"
      :custom-filter="customFilter"
      :custom-sort="customSort"
      :items-per-page="itemsPerPage"
      :show-select="showSelect"
      :hide-default-header="noDataAvailable || isMobile || hideDefaultHeader"
      :calculate-widths="calculateWidths"
      :page.sync="page"
      @click:row="selectItem"
      @page-count="pageCount = $event"
      hide-default-footer
    >
      <template v-slot:body.append>
        <slot name="summary" />
      </template>
      <template v-slot:item="{ item }" v-if="isMobile">
        <div class="flex-item-wrapper">
          <div
            v-for="(header, index) in headers"
            :key="index"
            @click="() => selectItem(item)"
            class="flex-item"
            :class="header.classMobile"
          >
            <div class="table-label">{{ header.text }}</div>
            <div v-if="header.value === 'status'">
              <slot name="status" :item="item" />
            </div>
            <div v-else-if="header.value === 'name'">
              <slot name="name" :item="item" />
            </div>
            <div v-else-if="header.value === 'awr'">
              {{ item.awr | awrLabel }}
            </div>
            <div v-else-if="header.value === 'action'">
              <slot name="action" :item="item" />
            </div>
            <div v-else-if="header.value === 'cost'">
              <slot name="cost" :item="item" />
            </div>
            <div v-else-if="header.value === 'roles'">
              <slot name="roles" :item="item" />
            </div>
            <div v-else>
              {{ get(item, header.value) }}
            </div>
          </div>
        </div>
        <v-divider
          v-if="showDivider(items.indexOf(item), items.length)"
          class="mb-5"
        />
      </template>
      <template v-slot:footer>
        <div class="v-data-footer">
          <v-pagination
            v-if="!disablePagination"
            v-model="page"
            :total-visible="totalVisible"
            :length="pageCount"
            circle
          />
          <slot name="pagination" />
          <div
            v-if="!disableFiltering"
            class="table__filter"
            :class="{ filtering: filtering }"
            @click.stop="toggleFilter"
          >
            <v-icon color="primary">mdi-filter-variant</v-icon>
            <span v-if="filtering" class="white--text">{{ filterCount }}</span>
          </div>
        </div>
      </template>
      <template v-slot:body="{ items }" v-if="showCustomBody">
        <slot name="body" :items="items" />
      </template>
      <template v-slot:item.status="{ item }">
        <slot name="status" :item="item" />
      </template>
      <template v-slot:item.name="{ item }">
        <slot name="name" :item="item" />
      </template>
      <template v-slot:item.awr="{ item }">
        {{ item.awr | awrLabel }}
      </template>
      <template v-slot:item.action="{ item }">
        <slot name="action" :item="item" />
      </template>
      <template v-slot:item.cost="{ item }">
        <slot name="cost" :item="item" />
      </template>
      <template v-slot:item.roles="{ item }">
        <slot name="roles" :item="item" />
      </template>
      <template v-slot:item.filled="{ item }">
        <slot name="filled" :item="item" />
      </template>
      <template v-slot:item.costCode="{ item }">
        <div class="no-wrap">{{ item.costCode }}</div>
      </template>
      <template v-slot:item.poNumber="{ item }">
        <div class="no-wrap">{{ item.poNumber }}</div>
      </template>
    </v-data-table>
    <TableFilter
      v-click-outside="disableFilter"
      :showFilter="isFilterActive"
      @toggle-filter="toggleFilter"
    >
      <template v-slot:table-filters>
        <slot name="filters" />
        <div class="clear-filters" v-if="filtering && !disableClearButton">
          <ErrorButton light @click.native="clearFilters" fullWidth>
            Clear filters
            <template v-slot:append:icon>
              <v-icon right size="1.3rem" class="error-text">mdi-close</v-icon>
            </template>
          </ErrorButton>
        </div>
      </template>
    </TableFilter>
  </div>
</template>

<script>
import TableFilter from "@/components/common/TableFilter";
import ClickOutside from "vue-click-outside";
import ErrorButton from "@/components/common/Button/ErrorButton";
import { get } from "lodash";

export default {
  props: {
    /**
     * An array of objects containing the text and values
     * for the table headers:
     * @example
     * [{ text: String, value: String }]
     */
    headers: {
      type: Array,
      required: true
    },
    /**
     * An array of objects
     * for the table items:
     * @example
     * [{ text: String, value: String }]
     */
    items: {
      type: Array,
      required: true
    },
    /**
     * Number of items per page
     */
    itemsPerPage: {
      type: Number,
      required: false,
      default: 20
    },
    /**
     * Search text
     */
    search: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * Boolean if filter is active
     */
    isFiltering: {
      type: Boolean,
      required: false
    },
    /**
     * Count of filter being use
     */
    filterCount: {
      type: Number,
      required: false
    },
    customFilter: {
      type: Function,
      required: false
    },
    customSort: {
      type: Function,
      required: false
    },
    mustSort: {
      type: Boolean,
      required: false
    },
    isLoading: {
      type: Boolean,
      required: false
    },
    showSelect: {
      type: Boolean,
      required: false
    },
    hideFooter: {
      type: Boolean,
      required: false
    },
    disableFiltering: {
      type: Boolean,
      required: false
    },
    hideRowSeparator: {
      type: Boolean,
      required: false
    },
    disableClearButton: {
      type: Boolean,
      required: false
    },
    hideDefaultHeader: {
      type: Boolean,
      required: false,
      default: false
    },
    customTableBody: Boolean,
    calculateWidths: Boolean,
    disablePagination: Boolean
  },
  components: {
    TableFilter,
    ErrorButton
  },
  directives: {
    ClickOutside
  },
  data() {
    return {
      isFilterActive: false,
      page: 1,
      pageCount: 0
    };
  },
  methods: {
    get,
    disableFilter() {
      if (this.isFilterActive) {
        this.isFilterActive = false;
      }
    },
    toggleFilter() {
      this.isFilterActive = !this.isFilterActive;
    },
    selectItem(item) {
      this.$emit("onSelect", item);
    },
    clearFilters() {
      this.isFilterActive = false;
      this.$emit("onClearFilters");
    },
    showDivider(index, totalCount) {
      return index !== totalCount - 1;
    }
  },
  watch: {
    isFiltering(newValue) {
      this.isFiltering = newValue;
    }
  },
  computed: {
    filtering() {
      return this.filterCount > 0;
    },
    noDataAvailable() {
      return !this.items.length;
    },
    showCustomBody() {
      return this.customTableBody && this.isMobile && !this.noDataAvailable;
    },
    totalVisible() {
      return this.$vuetify.breakpoint.smAndDown ? 4 : 7;
    }
  },
  filters: {
    awrLabel(value) {
      return value ? "Post-AWR" : "Pre-AWR";
    }
  }
};
</script>

<style lang="scss">
.table {
  display: flex;
  &__filter {
    position: fixed;
    z-index: 2;
    right: 135px;
    bottom: 12px;
    cursor: pointer;
  }
}

.flex-item-wrapper {
  padding: 0 10px;
  margin-bottom: 25px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .mobile-item-header {
    padding: 5px;
    margin-bottom: 5px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $secondary-text;
  }

  .item-title {
    font-size: 18px;
    width: 100%;
    padding: 3px 5px;
  }

  .flex-item {
    word-break: break-word;
    padding: 5px;
    width: 50%;

    &--full-width {
      width: 100%;
    }
  }

  &.bordered:not(:last-child) {
    padding-bottom: 25px;
    border-bottom: 1px solid $secondary-line;
  }
}

.table-label {
  color: $secondary-text;
}

.filtering {
  background-color: $primary;
  padding: 1px 10px;
  border-radius: 20px;

  i.v-icon.notranslate.mdi.mdi-filter-variant.theme--light.primary--text,
  label {
    color: #fff !important;
  }

  span {
    font-size: 14px !important;
  }
}

.v-data-footer__select {
  display: none !important;
}

th {
  white-space: nowrap;
}

.v-data-footer {
  bottom: 0;
  position: fixed;
  width: 100%;
  background-color: white;
  height: 50px;

  .v-pagination.v-pagination {
    justify-content: start;
  }

  button.v-pagination__navigation {
    border: none;
    box-shadow: none;
  }

  .v-pagination__navigation .v-icon {
    font-size: x-large;
  }
}

.v-data-footer__pagination {
  position: absolute;
  left: 70px;
}

.v-data-footer__icons-before {
  position: absolute;
  left: 10px;
}

.v-data-footer__icons-after {
  position: absolute;
  left: 40px;
}

.separator-hidden {
  .theme--light.v-data-table
    tbody
    tr:not(:last-child)
    td:not(.v-data-table__mobile-row),
  .theme--light.v-data-table tbody tr:not(:last-child) td:last-child {
    border-bottom: none !important;
  }
  .theme--light.v-data-table thead tr th {
    color: #000 !important;
  }
}

.theme--light.v-data-table thead tr:last-child th {
  border-bottom: 2px solid #d2d2d2;
}
.v-data-table td {
  padding: 10px;
}

.v-data-table__wrapper {
  margin-bottom: 50px;
}

@media only screen and (max-width: 670px) {
  .v-data-table__empty-wrapper {
    text-align: start !important;
  }
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem;
}
</style>
