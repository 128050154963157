<template>
  <div class="details-container">
    <div class="details-header">
      <slot name="header">
        <h3>{{ title }}</h3>
      </slot>
    </div>
    <div class="details">
      <slot name="body" />
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseDetails",
  props: {
    title: String
  }
};
</script>

<style scoped lang="scss"></style>
