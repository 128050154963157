<template>
  <div class="booking-template-list">
    <div>
      <div>
        <div
          class="d-flex justify-space-between align-end"
          :class="{ 'flex-column-reverse': isMobile }"
        >
          <BaseSearchBar
            @search="text => (searchText = text)"
            :class="isMobile ? 'mb-2' : 'mr-2'"
          />
          <NewBookingTemplateAction />
        </div>
      </div>
    </div>
    <Datatable
      :headers="headers"
      :itemsPerPage="15"
      hideRowSeparator
      :items="organisationBookingTemplates"
      :is-loading="isFetchingOrganisationBookingTemplates"
      :search="searchText"
      :customFilter="customFilter"
      @onSelect="handleSelect"
    >
      <template v-slot:status="{ item }">
        <Chip :color="getColorByStatus(item.status)">
          {{ item.status }}
        </Chip>
      </template>
    </Datatable>
  </div>
</template>

<script>
import BaseSearchBar from "@/components/common/BaseSearchBar";
import Datatable from "@/components/common/Datatable";
import NewBookingTemplateAction from "@/views/settings/Organisation/BookingTemplates/actions/NewBookingTemplateAction";
import { createNamespacedHelpers } from "vuex";
import { ORGANISATION_BOOKING_TEMPLATES_NAMESPACE } from "@/store/modules/organisations/organisation-booking-templates";
import { copyState } from "@/utils/helpers";
import { map } from "lodash";
import { FETCH_ORGANISATION_BOOKING_TEMPLATES } from "@/store/modules/organisations/organisation-booking-templates/actions";
import { customTableFussyFilter } from "@/utils/filters";
import { getColorByStatus } from "@/utils/agencies";
import Chip from "@/components/common/Chip";

const { mapActions, mapState } = createNamespacedHelpers(
  ORGANISATION_BOOKING_TEMPLATES_NAMESPACE
);

export default {
  name: "OrganisationBookingTemplateList",
  components: {
    NewBookingTemplateAction,
    Datatable,
    BaseSearchBar,
    Chip
  },
  data() {
    return {
      searchText: "",
      isEditBookingTemplateDialogOpen: false,
      selectedBookingTemplate: {}
    };
  },
  computed: {
    ...mapState(
      copyState([
        "organisationBookingTemplates",
        "isFetchingOrganisationBookingTemplates"
      ])
    ),
    headers() {
      return [
        {
          text: "Name",
          value: "jobTitle"
        },
        {
          text: "Status",
          value: "status"
        }
      ];
    },
    searchKeys() {
      return map(this.headers, "value");
    }
  },
  created() {
    this.fetchOrganisationBookingTemplates();
  },
  methods: {
    ...mapActions({
      fetchOrganisationBookingTemplates: FETCH_ORGANISATION_BOOKING_TEMPLATES
    }),
    handleSelect({ id }) {
      this.$router.push({
        name: "bookingTemplateDetails",
        params: { id }
      });
    },
    customFilter(value, searchValue, item) {
      return customTableFussyFilter(searchValue, item, this.searchKeys);
    },
    getColorByStatus
  }
};
</script>

<style lang="scss">
.booking-template-list {
  tr {
    cursor: pointer;
  }
}
</style>
