<template>
  <ValidationObserver ref="timeItem">
    <div
      class="time-item-container"
      :class="{ 'time-item-container--transparent': isBlank }"
    >
      <div class="validation-message-wrapper" v-if="showErrorMessage">
        <div class="validation-message">
          All fields are required
        </div>
      </div>
      <div
        class="time-wrapper"
        @mouseover="onMouseOver"
        @mouseleave="onMouseLeave"
        :class="{ 'time-wrapper-hovered': absent || (isHovered && !isMobile) }"
      >
        <div class="time">
          <div class="time-item activity my-1">
            <div class="time-item__label">Activity</div>
            <ValidationProvider
              class="time-item activity"
              v-slot="{ errors }"
              name="activity"
              rules="required"
            >
              <Select
                label=""
                item-text="text"
                item-value="value"
                :error-messages="errors"
                :value="activity"
                :items="availableActivities"
                :disabled="isDisabled"
                @input="value => edit('activity', value)"
                single-line
                hide-details
              />
            </ValidationProvider>
          </div>
          <div class="d-flex my-1 time__start-end-break">
            <div class="time-item">
              <div class="time-item__label">Start</div>
              <TextTimePicker
                v-if="isUnitTime || part"
                validateName="startTime"
                label="Start"
                :showValidationErrors="showValidationErrors"
                :disabled="isDisabled"
                :time="absent ? '' : startTime"
                @select="value => edit('startTime', value)"
                @input="$emit('editing')"
                externalValidation
              />
              <span v-else class="d-flex fill-height align-center">
                {{
                  this.absent || this.isDisabled
                    ? "-"
                    : this.expectedStartTime || startTime
                }}
              </span>
            </div>
            <div class="time-item">
              <div class="time-item__label">End</div>
              <TextTimePicker
                v-if="isUnitTime || part"
                validateName="endTime"
                :showValidationErrors="showValidationErrors"
                :disabled="isDisabled"
                :time="absent ? '' : endTime"
                @select="value => edit('endTime', value)"
                @input="$emit('editing')"
                externalValidation
              />
              <span v-else class="d-flex fill-height align-center">
                {{
                  this.absent || this.isDisabled
                    ? "-"
                    : this.expectedEndTime || endTime
                }}
              </span>
            </div>
            <div class="time-item break" v-if="isUnitTime">
              <div class="time-item__label">Break</div>
              <div class="break-values">
                <ValidationProvider
                  class="time-item break"
                  v-slot="{ errors }"
                  name="breakMinutes"
                  :rules="breakTimeRules"
                >
                  <v-text-field
                    name="breakMinutes"
                    :error-messages="showValidationErrors ? errors : ''"
                    :min="0"
                    :max="maximumBreakTime"
                    type="number"
                    :disabled="isDisabled"
                    @input="value => edit('breakMinutes', value)"
                    class="time-item__field"
                    :value="absent ? '' : breakMinutes"
                    :height="50"
                  />
                </ValidationProvider>
                <div class="minutes">
                  {{ this.$vuetify.breakpoint.lgAndUp ? "minutes" : "mins" }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tail">
          <div class="worked" v-if="!isUnitTime">
            <div class="worked__label">Full Day</div>
            <v-switch
              class="worked__switch"
              color="#475E69"
              :disabled="showLoader"
              :input-value="worked"
              @change="value => editWorkDay('worked', value)"
              inset
              hide-details
            />
          </div>
          <div class="worked" v-if="!isUnitTime">
            <div class="worked__label">Part Day</div>
            <v-switch
              class="worked__switch"
              color="#475E69"
              :disabled="showLoader"
              :input-value="part"
              @change="value => editWorkDay('part', value)"
              inset
              hide-details
            />
          </div>
          <div class="absent">
            <div class="absent__label">Absent</div>
            <v-switch
              class="absent__switch"
              color="#475E69"
              :disabled="showLoader"
              :input-value="absent"
              @change="value => editWorkDay('absent', value)"
              inset
              hide-details
            />
          </div>
          <div class="tail__total">{{ total }}</div>
          <div class="actions">
            <v-btn
              class="add-button"
              color="primary"
              @click="addTime"
              v-if="showButton && !hideAddTimeButton && showAddButton"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-btn
              class="close-button"
              @click="removeTime"
              outlined
              v-if="showButton && !hideRemoveButton"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import moment from "moment";
import TextTimePicker from "@/components/common/TimePicker/TextTimePicker";
import { find, size } from "lodash";
import { createNamespacedHelpers } from "vuex";
import Select from "@/components/common/Select";
import { TIMESHEETS_NAMESPACE } from "@/store/modules/timesheets";
import { EDIT_ENTRY } from "@/store/modules/timesheets/actions";
import { SET_ENTRY } from "@/store/modules/timesheets/mutations";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { GET_DEFAULT_ENTRY_TIME } from "@/store/modules/timesheets/getters";
import {
  formatTime,
  totalDuration,
  getDurationBetween,
  getFormattedDuration
} from "@/utils/time";
import { DAY_HOURS, DAY_MINUTES } from "@/constants/common";

const {
  mapActions,
  mapMutations,
  mapState,
  mapGetters
} = createNamespacedHelpers(TIMESHEETS_NAMESPACE);

export default {
  name: "TimeItem",
  props: {
    time: {
      type: Object,
      required: true
    },
    activities: {
      type: Array,
      required: true
    },
    isRemoving: {
      type: Boolean,
      required: false
    },
    hideRemoveButton: {
      type: Boolean,
      required: false
    },
    // type string is used to trigger child components
    triggerEdit: String,
    hideAddTimeButton: Boolean,
    expectedTimes: Object,
    allActivities: Array
  },
  components: {
    TextTimePicker,
    ValidationObserver,
    ValidationProvider,
    Select
  },
  created() {
    this.adjustTimeData();
  },
  data() {
    return {
      showValidationErrors: false,
      activity: this.getActivityValue(),
      isHovered: false,
      selectedLabel: "",
      startTime: "",
      endTime: "",
      breakMinutes: 0,
      absent: false,
      worked: false,
      part: false,
      expectedStartTime: "",
      expectedEndTime: "",
      isDataEdited: false,
      showErrorMessage: false,
      isEditing: false,
      totalTime: 0,
      selectedActivity: {}
    };
  },
  computed: {
    ...mapState({
      currentTimesheet: state => state.currentTimesheet
    }),
    ...mapGetters({
      defaultEntryTimes: GET_DEFAULT_ENTRY_TIME
    }),
    isBreakMinutesValueValid() {
      return this.breakMinutes.length && this.breakMinutes >= 0;
    },
    total() {
      if (this.absent) {
        return "Absent";
      }
      return this.getTotalValueForTheUnit();
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    showButton() {
      return !this.showLoader && (this.isHovered || this.isMobile);
    },
    isDisabled() {
      return (
        this.absent ||
        this.showLoader ||
        ((!this.startTime || !this.endTime) &&
          !this.part &&
          !this.isFullday &&
          !this.isUnitTime)
      );
    },
    showLoader() {
      return this.isEditing || this.isRemoving;
    },
    isUnitTime() {
      return this.currentTimesheet.timeUnitName === "hour";
    },
    isBlank() {
      return this.isUnitTime
        ? !this.expectedStartTime && !this.expectedEndTime && !this.startTime
        : !this.worked &&
            !this.expectedStartTime &&
            !this.expectedEndTime &&
            !this.absent;
    },
    maximumBreakTime() {
      const areTimesIncorrect = !this.startTime || !this.endTime;
      if (areTimesIncorrect || this.absent) {
        return 0;
      }
      const start = moment(this.startTime, "HH:mm");
      const end = moment(this.endTime, "HH:mm");
      let totalWorkedMinutes = end.diff(start, "minutes");
      if (totalWorkedMinutes < 0) {
        totalWorkedMinutes += DAY_MINUTES;
      }
      return totalWorkedMinutes;
    },
    breakTimeRules() {
      return `required|minNumber:0|maxBreakTime:${this.maximumBreakTime}`;
    },
    isNotOT() {
      return this.time.expectedEndTime && this.time.expectedEndTime;
    },
    isFullday() {
      return (
        this.time.startTime === formatTime(this.expectedTimes.startTime) &&
        this.time.endTime === formatTime(this.expectedTimes.endTime) &&
        !this.absent
      );
    },
    isPart() {
      return (
        this.time.startTime &&
        this.time.endTime &&
        !this.isFullday &&
        !this.absent
      );
    },
    formattedStartTime() {
      return moment(this.startTime, "HH:mm");
    },
    formattedEndTime() {
      return moment(this.endTime, "HH:mm").subtract(
        this.breakMinutes,
        "minutes"
      );
    },
    showAddButton() {
      return size(this.allActivities) > 1;
    },
    availableActivities() {
      return [...this.activities, this.selectedActivity];
    }
  },
  methods: {
    adjustTimeData() {
      this.selectedLabel = this.time.payRates.activityTypeName;
      this.startTime = this.time.startTime;
      this.endTime = this.time.endTime;
      this.breakMinutes = this.time.breakMinutes.toString();
      this.absent = this.time.absent;
      this.worked = this.isFullday;
      this.part = this.isPart;
      this.expectedStartTime = this.time.expectedStartTime
        ? formatTime(this.time.expectedStartTime)
        : "";
      this.expectedEndTime = this.time.expectedEndTime
        ? formatTime(this.time.expectedEndTime)
        : "";
      this.selectedActivity = find(this.allActivities, {
        value: this.getActivityValue()
      });
    },
    onMouseOver() {
      if (!this.isHovered) {
        this.isHovered = true;
      }
    },
    onMouseLeave() {
      if (this.isHovered) {
        this.isHovered = false;
      }
    },
    ...mapActions({
      editEntry: EDIT_ENTRY
    }),
    ...mapMutations({
      setEntry: SET_ENTRY
    }),
    addTime() {
      this.$emit("addTime");
    },
    getActivityValue() {
      return this.time.payRates.id;
    },
    edit(property, value) {
      this.isDataEdited = true;
      this.$emit("editing");
      this[property] = value;
      if (property === "activity") {
        this.selectedLabel = find(this.allActivities, { value }).text;
        const prevPayRate = this.selectedActivity;
        this.selectedActivity = find(this.allActivities, { value });
        this.$emit("updateActivity", {
          payRate: this.selectedActivity,
          prevPayRate
        });
      }
      if (property === "absent" && value === true) {
        this.worked = false;
        this.part = false;
      }
      if (property === "worked" && value === true) {
        this.absent = false;
        this.part = false;
      }
      if (property === "part" && value === true) {
        this.worked = false;
        this.absent = false;
      }
      if ((property === "part" || property === "absent") && value === false) {
        this.worked = true;
      }
      this.getTotalPartDay();
      const editedEntry = { ...this.time, ...this.getEntryToUpdate() };
      this.setEntry({
        editedEntry,
        id: this.time.id
      });
    },
    editWorkDay(property, value) {
      this.edit(property, value);
      this.updateEntry(
        property === "part" || property === "absent" ? !value : value
      );
    },
    updateEntry(value) {
      this.edit(
        "startTime",
        value
          ? this.expectedStartTime ||
              formatTime(this.defaultEntryTimes.expectedStartTime)
          : ""
      );
      this.edit(
        "endTime",
        value
          ? this.expectedEndTime ||
              formatTime(this.defaultEntryTimes.expectedEndTime)
          : ""
      );
    },
    async sendEditedData() {
      const entry = this.getEntryToUpdate();
      const isValid = await this.$refs.timeItem.validate();
      this.showValidationErrors = true;
      const areAllFieldsValid = isValid && this.isBreakMinutesValueValid;
      if (entry.absent || areAllFieldsValid) {
        this.isEditing = true;
        this.showErrorMessage = false;
        try {
          await this.editEntry({
            entry,
            id: this.time.id
          });
          this.$emit("success");
        } catch {
          this.adjustTimeData();
        } finally {
          this.isDataEdited = false;
          this.showValidationErrors = false;
          this.isEditing = false;
        }
      } else {
        this.showErrorMessage = true;
      }
    },
    getEntryToUpdate() {
      let {
        startTime,
        endTime,
        absent,
        breakMinutes,
        worked,
        totalTime
      } = this;
      if (absent) {
        startTime = null;
        endTime = null;
        totalTime = 0;
      }
      if (worked) {
        totalTime = 1;
      }

      return {
        startTime,
        absent,
        breakMinutes,
        endTime,
        totalTime,
        "pay-rates": {
          id: this.activity
        },
        timesheet: {
          id: this.currentTimesheet.id
        }
      };
    },
    removeTime() {
      this.$emit("removeTime", this.time);
    },
    getTotalValueForTheUnit() {
      if (this.isUnitTime) {
        return this.getTotalTimeForTimeUnit();
      }
      return this.worked ? "1 Day" : this.getTotalPartDay();
    },

    getTotalPartDay() {
      if (!this.startTime || !this.endTime) {
        this.totalTime = -1;
        return "-";
      }
      const start = this.expectedStartTime
        ? moment(this.expectedStartTime, "HH:mm")
        : moment(formatTime(this.expectedTimes.startTime), "HH:mm");
      const end = this.expectedEndTime
        ? moment(this.expectedEndTime, "HH:mm").subtract(
            this.breakMinutes,
            "minutes"
          )
        : moment(formatTime(this.expectedTimes.endTime), "HH:mm").subtract(
            this.breakMinutes,
            "minutes"
          );
      let workedHours = this.formattedEndTime.diff(
        this.formattedStartTime,
        "hours"
      );
      if (workedHours < 0) {
        workedHours += DAY_HOURS;
      }
      this.totalTime = workedHours / end.diff(start, "hours");
      return totalDuration(this.totalTime);
    },
    getTotalTimeForTimeUnit() {
      const initialTotalValue = "0h 0m";
      const areTimesIncorrect = !this.startTime || !this.endTime;
      if (areTimesIncorrect || this.absent) {
        return initialTotalValue;
      }
      const duration = getDurationBetween(
        this.formattedStartTime,
        this.formattedEndTime
      );
      return getFormattedDuration(duration);
    }
  },
  watch: {
    triggerEdit(newValue) {
      if (newValue && this.isDataEdited) {
        this.sendEditedData();
      }
    }
  }
};
</script>

<style lang="scss">
.validation-message-wrapper {
  width: 100%;
  padding: 10px 15px;

  .validation-message {
    padding: 10px 20px;
    width: 100%;
    color: $error;
    border: 2px solid $error;
  }
}
.time {
  .break {
    .v-messages.theme--light.error--text {
      display: table;
      padding: 3px 5px;
    }
    .v-messages__message {
      width: 275px;
      font-size: 15px;
    }
    .v-messages__wrapper {
      width: 275px;
    }
  }
}
</style>
