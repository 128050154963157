<template>
  <div>
    <slot name="content" />
    <div class="d-flex justify-center">
      <BaseFooter>
        <ExpandedButtons class="align-center">
          <div class="actions-bar">
            <div class="select-multiple-buttons rounded">
              <v-btn
                v-for="(section, key) in sections"
                :key="key"
                @click="handleTabChange(key)"
                :disabled="section.disabled"
                :class="{ active: section.active }"
                text
              >
                <InlineSvg :src="section.icon" contain width="20px" />
              </v-btn>
            </div>
          </div>
          <slot name="actions" />
          <v-btn
            class="actions-bar mr-1"
            color="primary"
            v-if="data && data.length"
            @click="downloadCSV"
            text
          >
            <v-icon>
              mdi-download
            </v-icon>
          </v-btn>
        </ExpandedButtons>
      </BaseFooter>
    </div>
  </div>
</template>

<script>
import BaseFooter from "@/components/common/BaseFooter";
import ExpandedButtons from "@/components/common/ExpandedButtons";
import InlineSvg from "vue-inline-svg";

export default {
  props: {
    sections: Array,
    data: Array
  },
  components: {
    BaseFooter,
    ExpandedButtons,
    InlineSvg
  },
  methods: {
    handleTabChange(key) {
      this.$emit("changeTab", key);
    },
    downloadCSV() {
      this.$emit("downloadCSV");
    }
  }
};
</script>
