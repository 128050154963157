var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseDialog',{attrs:{"isOpen":_vm.isOpen},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',[_vm._v("Upload File")])]},proxy:true},{key:"body",fn:function(){return [_c('FileUpload',{attrs:{"uploadingUrl":_vm.getAddFileUrl,"fileTypes":[
        '.pdf',
        '.jpeg',
        '.jpg',
        '.png',
        '.doc',
        '.docx',
        '.xlsx',
        '.csv'
      ],"files":_vm.files,"isLoading":_vm.isUploading,"disableAutoProceed":"","customActions":""},on:{"unselectFile":_vm.unselectFile,"selectFile":_vm.selectFile,"onUploadSuccess":_vm.updateFiles}})]},proxy:true},{key:"actions",fn:function(){return [_c('div',{staticClass:"accept-booking-actions"},[_c('PrimaryButton',{attrs:{"text":""},nativeOn:{"click":function($event){return _vm.cancel.apply(null, arguments)}}},[_vm._v(" Cancel ")]),_c('PrimaryButton',{attrs:{"disabled":_vm.disableUpload,"loading":_vm.isLoading},nativeOn:{"click":function($event){return _vm.uploadNewFile.apply(null, arguments)}}},[_vm._v(" Upload File ")])],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }