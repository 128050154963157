import { RRule } from "rrule";

export const getFrequencyBySelect = frequency => {
  switch (frequency) {
    case repeatOptions.NO_REPEAT:
      return {
        freq: RRule.DAILY
      };
    case repeatOptions.WEEKLY:
      return {
        freq: RRule.WEEKLY
      };
    case repeatOptions.MONTHLY:
      return {
        freq: RRule.MONTHLY
      };
    case repeatOptions.YEARLY:
      return {
        freq: RRule.YEARLY
      };
  }
};

export const repeatOptions = {
  NO_REPEAT: "Doesn't repeat",
  WEEKLY: "Weekly",
  MONTHLY: "Monthly",
  YEARLY: "Yearly"
};
