<template>
  <div class="w-full">
    <h2 class="pb-2 mb-2">Notifications</h2>
    <div class="settings__notification w-full">
      <div
        v-for="(notificationTypeValue,
        notificationTypeName,
        index) in notifications"
        :key="index"
      >
        <h4>{{ notificationTypeName | capitalize }}</h4>
        <v-divider inset />
        <div class="settings__notification--items">
          <div
            class="settings__notification--item"
            v-for="(setting, name, index) in notificationTypeValue"
            :key="index"
          >
            <p>{{ getNotificationName(notificationTypeName, name) }}</p>
            <v-switch
              :readonly="!canEditNotification(notificationTypeName, name)"
              :input-value="notificationTypeValue[name]"
              @change="val => editNotification(notificationTypeName, name, val)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep, kebabCase } from "lodash";
import getNotificationName from "../modules/getNotificationName";
import { isPermissioned } from "@/utils/permissions";

export default {
  props: {
    initialNotifications: {
      type: Object,
      required: true
    }
  },
  created() {
    this.notifications = cloneDeep(this.initialNotifications);
  },
  data() {
    return {
      notifications: []
    };
  },
  methods: {
    editNotification(notificationType, notificationName, value) {
      const notificationData = {
        key: `notifications.emails.${notificationType}.${notificationName}`,
        value
      };
      this.$emit("editNotification", notificationData);
    },
    getNotificationName,
    canEditNotification(notificationTypeName, name) {
      const permissionName = kebabCase(
        getNotificationName(notificationTypeName, name)
      );
      return (
        isPermissioned("settings.edit-notifications") &&
        isPermissioned(`settings.edit-notifications.${permissionName}`)
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.settings__notification {
  padding-left: 0 !important;

  hr.v-divider.v-divider--inset.theme--light {
    margin-left: 150px;
    margin-top: -15px;
  }

  &--items {
    padding: 20px 0;
  }

  &--item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin: 0;

    .v-input--selection-controls {
      margin-top: 0;
      padding-top: 0;
    }
  }
}
</style>
