<template>
  <div>
    <v-dialog fullscreen :value="showDialog" v-if="showDialog" persistent>
      <v-card class="login-iframe-wrapper">
        <v-progress-circular
          indeterminate
          size="64"
          color="primary"
          v-if="isLoadingSelfDetails"
        />
        <iframe v-else ref="iframe" frameBorder="0" :src="iframeSrc" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { GET_AUTH_DIALOG_VISIBILITY_STATUS } from "@/store/modules/global/getters";
import { SET_AUTH_DIALOG_VISIBILITY } from "@/store/modules/global/mutation-types";
import { FETCH_SELF_DETAILS } from "@/store/modules/auth/action-types";
import { mapMutations, mapGetters } from "vuex";
import { AUTH_NAMESPACE } from "@/store/modules/auth";
import isJSON from "is-json";
import { getToken } from "@/services/utils";

const { mapActions: mapAuthActions, mapState } = createNamespacedHelpers(
  AUTH_NAMESPACE
);
const IFRAME_LOGIN_MESSAGE = "loggedIn";

export default {
  name: "LoginDialog",
  props: {
    isOpen: Boolean
  },
  created() {
    window.addEventListener("message", this.onMessage);
  },
  data() {
    return {
      iframeSrc: `${process.env.VUE_APP_AUTH_URL}/login`
    };
  },
  computed: {
    ...mapState({
      isLoadingSelfDetails: state => state.isLoadingSelfDetails
    }),
    ...mapGetters({
      showDialog: GET_AUTH_DIALOG_VISIBILITY_STATUS
    })
  },
  methods: {
    ...mapAuthActions({
      fetchSelf: FETCH_SELF_DETAILS
    }),
    ...mapMutations({
      setDialogVisibility: SET_AUTH_DIALOG_VISIBILITY
    }),
    async adjustUserData({ navigateToHomePage }) {
      await this.fetchSelf();
      this.setDialogVisibility(false);
      let path = "/";
      if (!navigateToHomePage && this.$router.history.pending) {
        path = this.$router.history.pending.path;
      }

      this.$router.replace(path);
    },
    onMessage({ data: message }) {
      // auth api returns the token for the development
      const isNewUser = !getToken();
      if (isJSON(message)) {
        const { token } = JSON.parse(message);
        if (token) {
          if (window.location.href.indexOf("localhost") > -1) {
            localStorage.setItem("token", token);
          } else {
            // TODO remove this code, it's just for testing the subdomains
            localStorage.setItem("token", token);
          }
          this.adjustUserData({ navigateToHomePage: isNewUser });
        }
        // auth api returns success message if the pages are on the same domain
      } else if (message === IFRAME_LOGIN_MESSAGE) {
        this.adjustUserData({ navigateToHomePage: isNewUser });
      }
    }
  }
};
</script>

<style lang="scss"></style>
