export const COMPLIANCE_MODEL = "compliance-check";

const complianceModel = {
  complianceCheckName: "",
  checkVerified: false,
  documentTypeName: null,
  documentReferenceNumber: null,
  documentValidFromDate: null,
  documentValidToDate: null,
  registeringBody: null,
  checkHasUpdateService: false,
  updatedAt: "",
  createdAt: "",
  files: [],
  user: {
    jsonApi: "hasOne",
    type: "user"
  }
};

export default complianceModel;
