<template>
  <div class="agencies-selection">
    <ValidationProvider
      v-slot="{ errors }"
      name="selectedAgencies"
      rules="required"
    >
      <Select
        autocomplete
        :error-messages="showValidationErrors ? errors : ''"
        :value="selectedAgencies"
        @input="handleSelectedAgencies($event)"
        :items="agencies"
        item-text="name"
        item-value="id"
        ref="autocomplete"
        chips
        small-chips
        multiple
        :loading="isLoading"
      >
        <template v-slot:selection="{ data }">
          <v-chip
            close
            @click:close="unselectAgency(data.item)"
            class="selected-agency-chip"
          >
            {{ data.item.name }}
          </v-chip>
        </template>
        <template v-slot:item="{ item }">
          <StatusItem class="agency-select-item">
            <template v-slot:icon>
              <div class="mr-2 gray-background-icon square-40">
                <v-img
                  :src="require('@/assets/svg/agency-icon.svg')"
                  class="agency-icon my-auto"
                  max-width="20"
                  max-height="20"
                  aspect-ratio="1"
                />
              </div>
            </template>
            {{ item.name }}
          </StatusItem>
        </template>
      </Select>
    </ValidationProvider>
  </div>
</template>

<script>
import StatusItem from "@/components/common/StatusItem";
import { isArray } from "lodash";
import { ValidationProvider } from "vee-validate";
import Select from "@/components/common/Select";

export default {
  name: "AgenciesSelection",
  components: { StatusItem, ValidationProvider, Select },
  data() {
    return {
      innerSelectedWorkers: []
    };
  },
  computed: {
    selectedAgencies: {
      get() {
        return isArray(this.outerSelectedWorkers)
          ? this.outerSelectedWorkers
          : this.innerSelectedWorkers;
      },
      set(val) {
        this.innerSelectedWorkers = val;
      }
    }
  },
  props: {
    agencies: {
      type: Array,
      required: true
    },
    outerSelectedWorkers: {
      type: Array,
      required: false
    },
    preventSelecting: {
      type: Boolean,
      required: false
    },
    showValidationErrors: {
      type: Boolean,
      required: false
    },
    isLoading: Boolean
  },
  methods: {
    handleSelectedAgencies(selectedAgencies) {
      this.$nextTick(() => {
        this.$refs.autocomplete.isMenuActive = false;
      });
      this.selectedAgencies = selectedAgencies;
      this.$emit("handleSelectedAgencies", selectedAgencies);
    },
    unselectAgency(currentAgency) {
      this.innerSelectedWorkers = this.selectedAgencies.filter(
        agency => agency !== currentAgency.id
      );
      this.$emit("unselectAgency", currentAgency);
    }
  }
};
</script>

<style lang="scss">
.agency-select-item {
  font-weight: bold;
  padding: 8px 0;

  .v-icon.v-icon {
    background: $background;
    height: 60px;
    width: 60px;
    margin-right: 1rem;
  }
}

.agencies-selection {
  .agency-icon {
    width: 20px;
    height: 20px;
  }
  .v-input__control .v-input__slot {
    padding: 5px !important;
    height: auto !important;
  }
  .selected-agency-chip {
    background: $selected !important;

    span {
      color: white;
      font-weight: bold;
    }
    .v-icon {
      margin-right: 0.5rem;
      color: white;
    }
  }
}
</style>
