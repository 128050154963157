export const SHIFT_MODEL = "shift";

const shiftModel = {
  startDate: "",
  endDate: "",
  filledDate: "",
  workersRequired: 0,
  workersAllocated: 0,
  workersConfirmed: 0,
  expectedBreakMinutes: 0,
  status: "",
  shiftIds: [],
  shifts: {
    jsonApi: "hasMany",
    type: "shifts"
  },
  applicationShifts: {
    jsonApi: "hasMany",
    type: "application-shift"
  },
  pattern: {
    jsonApi: "hasOne",
    type: "shift-pattern"
  },
  booking: {
    jsonApi: "hasOne",
    type: "booking"
  },
  quotas: {
    jsonApi: "hasMany",
    type: "publishing-schedule-quotas"
  }
};

export const shiftModelActions = ["allocate", "bulk-cancel"];

export default shiftModel;
