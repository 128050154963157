<template>
  <div class="expanded-buttons" v-resize="onResize">
    <slot />
    <div class="visibility-triggers" v-if="showVisibilityTriggers">
      <PrimaryButton
        text
        @click.native="showButtons"
        fullWidth
        v-if="!showMore"
      >
        More
        <v-icon>mdi-chevron-up</v-icon></PrimaryButton
      >
      <PrimaryButton text @click.native="hideButtons" fullWidth v-else>
        Less
        <v-icon>mdi-chevron-down</v-icon>
      </PrimaryButton>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import { size } from "lodash";

export default {
  name: "ExpandedButtons",
  components: {
    PrimaryButton
  },
  data() {
    return {
      showMore: false,
      actionsNumber: 0
    };
  },
  mounted() {
    if (this.isMobile) {
      this.toggleButtonsVisibility(false);
    }
  },
  computed: {
    showVisibilityTriggers() {
      return this.isMobile && this.actionsNumber > 1;
    }
  },
  methods: {
    showButtons() {
      this.showMore = true;
      this.toggleButtonsVisibility(true);
    },
    hideButtons() {
      this.showMore = false;
      this.toggleButtonsVisibility(false);
    },
    toggleButtonsVisibility(show) {
      const $actions = $(".actions-bar")
        .first()
        .children();
      this.actionsNumber = size($actions);
      $actions.each(function(index) {
        if (index) {
          show ? $(this).show() : $(this).hide();
        }
      });
    },
    onResize() {
      const isMobile = window.innerWidth < 600;
      if (isMobile) {
        this.hideButtons();
      } else {
        this.showButtons();
      }
    }
  }
};
</script>

<style lang="scss">
.expanded-buttons {
  .visibility-triggers {
    padding: 0 10px 10px 10px;
  }

  @media only screen and (max-width: $mobile-breakpoint - 1px) {
    width: 100%;
    background-color: $white;
    .visibility-triggers {
      display: flex;
    }
  }
  @media only screen and (min-width: $mobile-breakpoint) {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
</style>
