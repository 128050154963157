import { NOTIFICATION_MODEL } from "./notifications/notification-model";

export const USER_SETTINGS_MODEL = "user_setting";

const userSettingsModel = {
  notifications: {
    jsonApi: "hasMany",
    type: NOTIFICATION_MODEL
  }
};
export default userSettingsModel;
