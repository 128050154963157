<template>
  <BaseAction>
    <SecondaryButton
      :loading="isLoading"
      @click.native="handleClick"
      color="primary"
    >
      <inline-svg :src="require('@/assets/svg/thumb_up.svg')" width="25" />
      Approve
    </SecondaryButton>
  </BaseAction>
</template>

<script>
import SecondaryButton from "@/components/common/Button/SecondaryButton";
import { APPROVE_CANDIDATE } from "@/store/modules/applications/actions";
import { createNamespacedHelpers } from "vuex";
import { APPLICATION_NAMESPACE } from "@/store/modules/applications";
import BaseAction from "@/components/common/BaseAction";
import { POST_GLOBAL_MESSAGE } from "@/store/modules/global/action-types";
import { mapActions } from "vuex";
import { map, size } from "lodash";
import pluralize from "pluralize";
import { getApplicationActionIcon } from "@/utils/bookings";
import InlineSvg from "vue-inline-svg";

const { mapActions: mapApplicationActions } = createNamespacedHelpers(
  APPLICATION_NAMESPACE
);

export default {
  name: "ApproveCandidatesAction",
  components: {
    BaseAction,
    SecondaryButton,
    InlineSvg
  },
  data() {
    return {
      isOpen: false,
      isLoading: false
    };
  },
  props: {
    applicationsIds: {
      type: Array,
      required: true
    }
  },
  methods: {
    ...mapActions({
      postGlobalMessage: POST_GLOBAL_MESSAGE
    }),
    ...mapApplicationActions({
      approveCandidate: APPROVE_CANDIDATE
    }),
    async handleClick() {
      this.isLoading = true;
      const requests = map(this.applicationsIds, applicationId =>
        this.approveCandidate({ applicationId })
      );
      await Promise.all(requests);
      this.postSuccesMessage(size(requests));
      this.$emit("statusChanged");
      this.isLoading = false;
    },
    postSuccesMessage(applicationNumber) {
      const message = {
        snackbar: true,
        type: "success",
        title: `You have approved the ${pluralize(
          "application",
          applicationNumber
        )}`
      };
      this.postGlobalMessage(message);
    },
    getApplicationActionIcon
  }
};
</script>
