<template>
  <ValidationObserver ref="payRate">
    <div class="d-flex justify-space-between">
      <p class="mb-3 font-weight-bold">Pay Rate {{ index + 1 }}</p>
      <div @click="onRemove" v-if="canRemove">
        <v-icon>mdi-close</v-icon>
      </div>
    </div>
    <v-row>
      <v-col class="col-md-6 col-12">
        <ValidationProvider v-slot="{ errors }" rules="required">
          <v-text-field
            :disabled="loading"
            :error-messages="showValidationErrors ? errors : ''"
            label="Activity"
            v-model="payRate.activityTypeName"
          />
        </ValidationProvider>
      </v-col>
      <v-col class="col-md-6 col-12">
        <div class="d-flex">
          <ValidationProvider
            v-slot="{ errors }"
            rules="required"
            class="full-width"
          >
            <v-text-field
              prefix="£"
              type="number"
              :disabled="loading"
              :error-messages="showValidationErrors ? errors : ''"
              v-model="payRate.preAwr"
              label="Pre-AWR"
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            rules="required"
            class="ml-5 full-width"
          >
            <v-text-field
              prefix="£"
              label="Post-AWR"
              type="number"
              :disabled="loading"
              :error-messages="showValidationErrors ? errors : ''"
              v-model="payRate.postAwr"
            />
          </ValidationProvider>
        </div>
      </v-col>
    </v-row>
  </ValidationObserver>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  name: "PayRate",
  components: { ValidationProvider, ValidationObserver },
  props: {
    index: Number,
    canRemove: Boolean,
    payRateData: Object
  },
  data() {
    return {
      loading: false,
      showValidationErrors: false,
      payRate: { ...this.payRateData }
    };
  },
  methods: {
    onRemove() {
      this.$emit("remove");
    },
    getPayRate() {
      return this.payRate;
    },
    async validate() {
      this.showValidationErrors = true;
      const isValid = await this.$refs.payRate.validate();
      return isValid;
    }
  }
};
</script>

<style lang="scss"></style>
