<template>
  <div class="d-flex mb-4">
    <div class="filtered-property">
      <slot />
      <FilterIndicator v-if="showIndicator" />
    </div>
  </div>
</template>

<script>
import FilterIndicator from "@/components/common/FilterIndicator";
import { isArray, size } from "lodash";

export default {
  name: "FilterProperty",
  components: { FilterIndicator },
  props: {
    selected: [Boolean, String, Number, Array],
    fullHeight: Boolean
  },
  computed: {
    showIndicator() {
      return isArray(this.selected) ? size(this.selected) : this.selected;
    }
  }
};
</script>

<style lang="scss" scoped></style>
