<template>
  <p class="show-hide-text">
    <span v-if="!showText" class="font-weight-bold">············</span>
    <slot name="default" v-else></slot>
    <PrimaryButton
      v-if="!showText"
      @click.native="showText = !showText"
      class="p-0"
      shrink
      text
    >
      Show
    </PrimaryButton>
  </p>
</template>

<script>
import PrimaryButton from "@/components/common/Button/PrimaryButton";

export default {
  components: {
    PrimaryButton
  },
  data() {
    return {
      showText: false
    };
  }
};
</script>
<style lang="scss">
.show-hide-text {
  .base-button.shrink.v-btn:not(.v-btn--round).v-size--default {
    padding: 0 0.75rem !important;
  }
}
</style>
