<template>
  <app-layout
    parent="/agencies"
    isDetails
    :isLoading="isLoading"
    :title="currentAgency.name"
  >
    <template v-slot:body>
      <div class="container pl-5 pr-5 pb-10">
        <div class="mt-2" v-if="isLoading">
          <v-skeleton-loader width="100%" type="card-heading" />
          <v-skeleton-loader
            v-for="i in 4"
            :key="i"
            width="100%"
            type="heading, list-item"
          />
        </div>
        <BasicDetails v-else :currentAgency="currentAgency" />
        <UsersList :agencyId="currentAgencyId" />
      </div>
    </template>
  </app-layout>
</template>

<script>
import appLayout from "@/components/common/base-layouts/AppLayout";
import BasicDetails from "@/views/agencies/components/BasicDetails";
import UsersList from "@/views/agencies/components/UsersList";
import { FETCH_AGENCY } from "@/store/modules/agencies/action-types";
import { AGENCY_NAMESPACE } from "@/store/modules/agencies";
import { createNamespacedHelpers } from "vuex";
import { isEmpty } from "lodash";

const { mapState, mapActions } = createNamespacedHelpers(AGENCY_NAMESPACE);
export default {
  components: { appLayout, BasicDetails, UsersList },
  mounted() {
    if (isEmpty(this.currentAgency)) {
      this.fetchCurrentAgency(this.currentAgencyId);
    }
  },
  computed: {
    ...mapState({
      currentAgency: state => state.currentAgency,
      isLoading: state => state.isFetchingData
    }),
    currentAgencyId() {
      return this.$route.params.id;
    }
  },
  methods: {
    ...mapActions({
      fetchCurrentAgency: FETCH_AGENCY
    })
  }
};
</script>

<style lang="scss" scoped></style>
