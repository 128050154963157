export const TIMESHEET_NOTIFICATIONS_MODEL = "timesheet-notifications";

const timesheetNotificationsModel = {
  approve: false,
  submit: false,
  disputed: false,
  verified: false,
  verify: false
};

export default timesheetNotificationsModel;
