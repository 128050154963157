import JSONApiService from "@/services/JSONApiService";
import {
  include,
  AUTHOR,
  ENTRIES,
  EXPENSES,
  COMMENTS,
  PAY_RATES,
  BOOKING,
  ROOT_CLIENT,
  nested,
  WORKER,
  WORKER_AGENCY,
  LOCATION
} from "@/models/relationships";
import { TIMESHEET_MODEL } from "@/models/timesheet-model";
import { EXPENSE_MODEL } from "@/models/expense-model";
import { FILE_MODEL } from "@/models/file-model";
import { ENTRY_MODEL } from "@/models/entry-model";
import { COMMENT_MODEL } from "@/models/comment-model";
import { disablePluralizeForCall } from "@/services/utils";
import APIService, { withPrefix } from "@/services/APIService";
import { ORGANISATION_MODEL } from "@/models/organisation-model";
import { USER_MODEL } from "@/models/user-model";

const API = withPrefix(APIService.client(), "timesheets");

const callTimesheetAction = ({ timesheetId, action, method, commit }) => {
  const api = JSONApiService(commit);
  api.one(TIMESHEET_MODEL, timesheetId);
  disablePluralizeForCall(api, () => api.all(action));
  return api[method]({}, timesheetListIncludes);
};

const timesheetListIncludes = include(
  ENTRIES,
  COMMENTS,
  ROOT_CLIENT,
  WORKER,
  WORKER_AGENCY,
  BOOKING,
  nested(BOOKING, LOCATION),
  nested(ENTRIES, PAY_RATES)
);

const timesheetsApi = ({ commit } = {}) => ({
  approveTimesheet: async timesheetId =>
    callTimesheetAction({
      timesheetId,
      action: "approve",
      method: "patch",
      commit
    }),
  disputeTimesheet: async timesheetId =>
    callTimesheetAction({
      timesheetId,
      action: "dispute",
      method: "patch",
      commit
    }),
  creditTimesheet: async (timesheetId, data) =>
    API.patch(`${timesheetId}/credit`, {
      data
    }),
  verifyTimesheet: async timesheetId =>
    callTimesheetAction({
      timesheetId,
      action: "verify",
      method: "patch",
      commit
    }),
  submitTimesheet: async timesheetId =>
    callTimesheetAction({
      timesheetId,
      action: "submit",
      method: "patch",
      commit
    }),
  recallTimesheet: async timesheetId =>
    callTimesheetAction({
      timesheetId,
      action: "recall",
      method: "patch",
      commit
    }),
  getTimesheetsV2: async params =>
    APIService.client().getV2("trg-timesheets", { params }),
  getTimesheets: async params =>
    JSONApiService(commit)
      .all(TIMESHEET_MODEL)
      .get({ ...timesheetListIncludes, ...params }),
  getTimesheet: async timesheetId =>
    JSONApiService(commit).find(
      TIMESHEET_MODEL,
      timesheetId,
      include(ENTRIES, EXPENSES, BOOKING, WORKER, nested(ENTRIES, PAY_RATES))
    ),
  getTimesheetComments: async timesheetId =>
    JSONApiService(commit)
      .one(TIMESHEET_MODEL, timesheetId)
      .all(COMMENT_MODEL)
      .get(include(AUTHOR)),
  getTimesheetEntries: async timesheetId =>
    JSONApiService(commit)
      .one(TIMESHEET_MODEL, timesheetId)
      .all(ENTRY_MODEL)
      .get(include(PAY_RATES)),
  getTimesheetExpenses: async timesheetId =>
    JSONApiService(commit)
      .one(TIMESHEET_MODEL, timesheetId)
      .all(EXPENSE_MODEL)
      .get(),
  addTimesheetComment: async (timesheetId, comment) =>
    JSONApiService(commit)
      .one(TIMESHEET_MODEL, timesheetId)
      .create(COMMENT_MODEL, comment),
  addTimesheetExpense: async expense =>
    JSONApiService(commit).create(EXPENSE_MODEL, expense),
  editTimesheetExpense: async (expense, id) =>
    JSONApiService(commit)
      .one(EXPENSE_MODEL, id)
      .patch(expense),
  addTimesheetEntry: async (entry, includePayRates = true) =>
    JSONApiService(commit).create(
      ENTRY_MODEL,
      entry,
      includePayRates ? include(PAY_RATES) : null
    ),
  editTimesheetEntry: async (entry, id) =>
    JSONApiService(commit)
      .one(ENTRY_MODEL, id)
      .patch(entry, include(PAY_RATES)),
  deleteTimesheetEntry: async id =>
    JSONApiService(commit)
      .one(ENTRY_MODEL, id)
      .destroy(),
  deleteTimesheetExpense: async id =>
    JSONApiService(commit)
      .one(EXPENSE_MODEL, id)
      .destroy(),
  deleteTimesheetExpenseFile: async (expenseId, timesheetId) => {
    const api = JSONApiService(commit)
      .one(TIMESHEET_MODEL, timesheetId)
      .one(EXPENSE_MODEL, expenseId);

    disablePluralizeForCall(api, () => api.all(FILE_MODEL));
    return api.destroy();
  },
  createTimesheet: async data =>
    JSONApiService(commit).create(TIMESHEET_MODEL, data, include(WORKER)),
  searchWorkers: async params =>
    JSONApiService(commit)
      .all(USER_MODEL)
      .get({
        ...params,
        ...include(ORGANISATION_MODEL)
      })
});

export default timesheetsApi;
