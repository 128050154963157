<template>
  <div class="settings">
    <div class="account base-footer-spacing">
      <h2 class="w-full mb-3" v-if="isPermissioned('settings.view-basic')">
        Basic Details
      </h2>
      <div v-if="isEditingDetails">
        <v-skeleton-loader type="list-item" v-for="i in 4" :key="i" />
      </div>
      <div
        class="account-settings mb-3"
        v-else-if="isPermissioned('settings.view-basic')"
      >
        <ValidationObserver ref="basicDetails">
          <BasicDetails
            :userData="userData"
            @edit="editUserField"
            :showValidationErrors="showValidationErrors"
          />
        </ValidationObserver>
        <div class="password-item">
          <v-text-field
            v-if="isPermissioned('settings.view-basic.password')"
            type="password"
            label="Password"
            value="password"
            readonly
          >
            <template v-slot:append>
              <ChangePasswordAction
                v-if="isPermissioned('settings.edit-basic.password')"
              />
            </template>
          </v-text-field>
        </div>
      </div>
      <div v-if="isLoadingWorkers || isEditingDetails">
        <v-skeleton-loader type="list-item" v-for="i in 10" :key="i" />
      </div>
      <NotificationSettings
        @editNotification="editNotification"
        v-else-if="
          !isLoadingWorkers &&
            has(userSettings, 'notifications.emails') &&
            userSettings.notifications.emails.length !== 0
        "
        :initialNotifications="userSettings.notifications.emails"
      />
    </div>
    <BaseFooter>
      <Permissioned class="actions-bar" section="settings" :vertical="isMobile">
        <PrimaryButton
          @click.native="handleSave"
          :loading="isEditingDetails"
          :oneOfPermissions="editPermissions"
          :fullWidth="isMobile"
        >
          Save Changes
        </PrimaryButton>
      </Permissioned>
    </BaseFooter>
  </div>
</template>

<script>
import NotificationSettings from "@/views/settings/components/NotificationSettings";
import BasicDetails from "@/views/settings/components/BasicDetails";
import BaseFooter from "@/components/common/BaseFooter";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import ChangePasswordAction from "@/views/settings/components/actions/ChangePasswordAction";
import { createNamespacedHelpers } from "vuex";
import { isEmpty, has } from "lodash";
import Permissioned from "@/components/common/Permissioned";
import { AUTH_NAMESPACE } from "@/store/modules/auth";
import {
  FETCH_USER_SETTINGS,
  EDIT_USER_SETTINGS
} from "@/store/modules/users/actions";
import { USERS_NAMESPACE } from "@/store/modules/users";
import {
  GET_IS_FETCHING_USER_SETTINGS,
  GET_USER_SETTINGS
} from "@/store/modules/users/getters";
import { EDIT_USER_DETAILS } from "@/store/modules/auth/action-types";
import { ValidationObserver } from "vee-validate";
import { isPermissioned } from "@/utils/permissions";

const {
  mapState: mapAuthState,
  mapActions: mapAuthActions
} = createNamespacedHelpers(AUTH_NAMESPACE);

const {
  mapActions: mapUsersActions,
  mapGetters: mapUsersGetters
} = createNamespacedHelpers(USERS_NAMESPACE);

const EDIT_PERMISSIONS = ["edit-basic", "edit-notifications"];

export default {
  name: "Account",
  components: {
    PrimaryButton,
    BaseFooter,
    BasicDetails,
    NotificationSettings,
    ChangePasswordAction,
    Permissioned,
    ValidationObserver
  },
  created() {
    this.fetchUserSettings();
  },
  data() {
    return {
      editableData: {},
      editedNotifications: [],
      isEditingDetails: false,
      editPermissions: EDIT_PERMISSIONS,
      showValidationErrors: false
    };
  },
  computed: {
    ...mapAuthState({
      userData: state => state.userData
    }),
    ...mapUsersGetters({
      userSettings: GET_USER_SETTINGS,
      isLoadingWorkers: GET_IS_FETCHING_USER_SETTINGS
    })
  },
  methods: {
    ...mapAuthActions({
      editUserDetails: EDIT_USER_DETAILS
    }),
    ...mapUsersActions({
      fetchUserSettings: FETCH_USER_SETTINGS,
      editUserSettings: EDIT_USER_SETTINGS
    }),
    editUserField({ property, value }) {
      this.editableData[property] = value;
    },
    async handleSave() {
      this.showValidationErrors = true;
      const isValid = await this.$refs.basicDetails.validate();
      if (isValid) {
        this.showValidationErrors = false;
        try {
          this.isEditingDetails = true;
          if (!isEmpty(this.editableData)) {
            await this.editUserDetails({
              id: this.userData.id,
              editedUser: this.editableData
            });
          }
          if (this.editedNotifications.length) {
            await this.editUserSettings(this.editedNotifications);
          }
        } finally {
          this.editedNotifications = [];
          this.editableData = {};
          this.isEditingDetails = false;
        }
      }
    },
    editNotification(notification) {
      this.editedNotifications = [...this.editedNotifications, notification];
    },
    has,
    isPermissioned
  }
};
</script>

<style lang="scss"></style>
