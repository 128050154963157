<template>
  <div class="col-sm-12">
    <div class="base-list-toolbar">
      <div class="one-third left">
        <DynamicSearchBar @search="onSearch" v-if="!hideSearchBar" />
      </div>
      <div class="one-third title">
        <h1>
          {{ title }}
        </h1>
        <slot name="filters" />
      </div>
      <div class="one-third right">
        <slot name="tool-right" />
        <SortComponent
          v-if="sortingData"
          :sortingLabel="sortingLabel"
          :sortingData="sortingData"
          @onSort="onSort"
        />
      </div>
    </div>
    <slot name="inline-filters" />
  </div>
</template>

<script>
import DynamicSearchBar from "@/components/common/DynamicSearchBar";
import SortComponent from "@/components/common/SortComponent";

export default {
  name: "BaseSearchBar",
  components: {
    DynamicSearchBar,
    SortComponent
  },
  data() {
    return {
      searchText: null
    };
  },
  props: {
    title: String,
    hideSearchBar: Boolean,
    sortingData: Array,
    sortingLabel: String
  },
  methods: {
    onSearch(text) {
      this.$emit("search", text);
    },
    onSort(text) {
      this.$emit("sort", text);
    }
  }
};
</script>

<style lang="scss">
.base-list-toolbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .one-third {
    display: flex;
  }

  .right {
    display: flex;
    justify-content: flex-end;
  }

  .title {
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  h1 {
    word-break: initial;
  }

  @media only screen and (max-width: $tablet-breakpoint) {
    flex-direction: column;

    .title {
      order: -1;
    }

    .one-third {
      justify-content: center;
      margin: 4px 0;
    }

    .left {
      justify-content: flex-start;
    }

    .right {
      justify-content: center;
    }
  }
}
</style>
