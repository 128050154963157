import moment from "moment";
import { getDateRange, formatTime } from "@/utils/time";
import { API_DATE_FORMAT, ENTRY_TIME_FORMAT } from "@/constants/common";

export const shiftDateFilter = date => {
  return date ? moment(date).format("dddd Do MMMM YYYY") : "";
};

export const shiftHoursFilter = date => {
  return formatTime(date, {
    startFormat: API_DATE_FORMAT,
    endFormat: ENTRY_TIME_FORMAT
  });
};

export const fullDateFilter = date => {
  return date ? moment(date).format("Do MMMM YYYY") : "";
};

export const rangeDateFilter = ({ startDate, endDate }) =>
  getDateRange({
    startDate,
    endDate,
    format: "Do MMM"
  });
