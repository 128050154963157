<template>
  <div class="shift-widget">
    <div class="date-picker">
      <div
        class="v-picker v-card v-picker--date v-date-range__pickers theme--light"
      >
        <div class="v-picker__body v-picker__body--no-title theme--light">
          <div>
            <div
              class="v-date-picker-table v-date-picker-table--date theme--light"
            >
              <table aria-label="shifts-calendar">
                <thead>
                  <tr>
                    <th v-for="(shiftDay, index) in shiftDays" :key="index">
                      {{ shiftDay.day | dayOfWeek }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      :class="{
                        'v-date-range__in-range v-date-range__range-start': isStart(
                          shiftDay
                        ),
                        'v-date-range__in-range': isInRange(shiftDay),
                        'v-date-range__in-range v-date-range__range-end': isEnd(
                          shiftDay
                        ),
                        'v-date-range__in-range v-date-range__range-start-end':
                          isEnd(shiftDay) && isStart(shiftDay)
                      }"
                      v-for="(shiftDay, index) in shiftDays"
                      :key="index"
                    >
                      <button
                        type="button"
                        :class="getClass(shiftDay)"
                        @click="selectDay(shiftDay.day)"
                      >
                        <div class="v-btn__content">
                          {{ shiftDay.day | dayOfMonth }}
                        </div>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { filter, isEmpty, reduce, size } from "lodash";

const DISPLAY_DAYS_COUNT = 14;

export default {
  props: {
    shifts: Array
  },
  created() {
    this.generateDates();
  },
  data() {
    return {
      selectedDates: [
        moment().format("DD-MM-YYYY"),
        moment()
          .add(1, "d")
          .format("DD-MM-YYYY")
      ],
      firstSelectedDate: null,
      shiftDays: []
    };
  },
  computed: {
    isRangeSelected() {
      return size(this.selectedDates) === 2;
    }
  },
  methods: {
    handleChange() {
      this.$emit("select", this.selectedDates);
    },
    selectDay(day) {
      if (this.isRangeSelected && !this.firstSelectedDate) {
        this.firstSelectedDate = day;
      } else {
        this.selectedDates = [this.firstSelectedDate, day];
        this.firstSelectedDate = null;
        this.sortDates();
        this.handleChange();
      }
    },
    sortDates() {
      if (
        moment(this.selectedDates[0], "DD-MM-YYYY").isAfter(
          moment(this.selectedDates[1], "DD-MM-YYYY"),
          "day"
        )
      ) {
        let lastDate = this.selectedDates[0];
        this.selectedDates[0] = this.selectedDates[1];
        this.selectedDates[1] = lastDate;
      }
    },
    generateDates() {
      for (let daysToAdd = 0; daysToAdd < DISPLAY_DAYS_COUNT; daysToAdd++) {
        const day = moment()
          .add(daysToAdd, "d")
          .format("DD-MM-YYYY");
        const shifts = filter(
          this.shifts,
          ({ startDate }) => moment(startDate).format("DD-MM-YYYY") === day
        );
        this.shiftDays = [
          ...this.shiftDays,
          {
            day,
            shifts
          }
        ];
      }
    },
    getClass({ shifts }) {
      const filledShifts = reduce(
        shifts,
        (filled, shift) => {
          filled.confirmedCount += shift.workersConfirmed;
          filled.requiredCount += shift.workersRequired;
          return filled;
        },
        { confirmedCount: 0, requiredCount: 0 }
      );
      const filledPercentage =
        (filledShifts.confirmedCount / filledShifts.requiredCount) * 100;
      return `v-btn v-btn--text v-btn--rounded theme--light ${!isEmpty(
        shifts
      ) && this.getColor(filledPercentage.toFixed(2))}`;
    },
    getColor(percentage) {
      switch (true) {
        case percentage > 99:
          return "filled";
        case percentage > 79:
          return "filled80";
        default:
          return "unfilled";
      }
    },
    isStart({ day }) {
      return day === this.selectedDates[0];
    },
    isEnd({ day }) {
      return day === this.selectedDates[1];
    },
    isInRange({ day }) {
      return (
        moment(day, "DD-MM-YYYY").isSameOrBefore(
          moment(this.selectedDates[1], "DD-MM-YYYY"),
          "day"
        ) &&
        moment(day, "DD-MM-YYYY").isSameOrAfter(
          moment(this.selectedDates[0], "DD-MM-YYYY"),
          "day"
        )
      );
    }
  },
  filters: {
    dayOfWeek(day) {
      return moment(day, "DD-MM-YYYY").format("dd");
    },
    dayOfMonth(day) {
      return moment(day, "DD-MM-YYYY").format("D");
    }
  }
};
</script>

<style lang="scss">
.shift-widget {
  padding: 20px 0;

  .v-date-picker-header {
    display: none;
  }
  .theme--light.v-date-picker-table th {
    color: black;
  }

  .v-date-picker-table table {
    width: 625px !important;
  }

  .unfilled {
    background-color: #ea0b40;
    color: white;
  }
  .filled80 {
    background-color: #ffb800;
    color: white;
  }
  .filled {
    background-color: #17d99b;
    color: white;
  }
  .v-date-picker-table--date .v-btn {
    height: 35px;
    width: 35px;
  }
  .v-date-picker-table table th,
  .v-date-picker-table table td {
    height: 35px !important;
    padding: 2px 0 !important;
  }
  .v-date-range__in-range {
    background-color: rgb(219, 223, 225) !important;
    left: 0 !important;
  }
  .v-date-range__range-start {
    border-radius: 20px 0 0 20px !important;
  }
  .v-date-range__range-end {
    border-radius: 0 20px 20px 0 !important;
  }

  .v-date-range__range-start-end {
    border-radius: 50% !important;
    background-color: transparent !important;
  }

  @media only screen and (max-width: $mobile-breakpoint - 1) {
    .date-picker .v-card:not(.v-sheet--tile):not(.v-card--shaped) {
      height: auto !important;
    }
    .date-picker .v-date-picker-table {
      height: auto !important;
      overflow: scroll;
      padding-bottom: 10px;
    }
  }
}
</style>
