<template>
  <v-menu
    v-model="open"
    min-width="300"
    offset-y
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on }">
      <v-btn text color="primary">
        <div v-on="on">
          <v-icon size="1.2rem" class="mb-1">
            {{ sortingDirectionIcon }}
          </v-icon>
          <span> {{ sortingLabel }} </span>
        </div>
      </v-btn>
    </template>
    <v-col cols="12" class="sorting-details">
      <div class="d-flex justify-space-between">
        <h3 class="title font-weight-bold mb-2">Sort By</h3>
        <v-icon @click="open = false">
          mdi-close
        </v-icon>
      </div>
      <div v-for="(sortingParam, i) in sortingData" :key="i">
        <div class="font-weight-bold mb-2">{{ sortingParam.label }}</div>
        <v-radio-group class="radio">
          <div
            class="radio--standard"
            @click="handleSelect(sortingParam, option.value)"
            :class="{
              'radio--standard__active': isActive(
                sortingParam.label,
                option.value
              )
            }"
            v-for="(option, index) in sortingParam.options"
            :key="`opt-${index}`"
            aria-label="Select sorting param"
          >
            <v-radio
              :label="option.label"
              :value="option.value"
              :ripple="false"
              color="white"
            />
          </div>
        </v-radio-group>
      </div>
    </v-col>
  </v-menu>
</template>

<script>
export default {
  name: "SortComponent",
  props: {
    sortingData: Array,
    sortingLabel: {
      type: String,
      default: "Date"
    }
  },
  data() {
    return {
      sortingDirection: "-",
      showSortingDetails: false,
      open: false
    };
  },
  computed: {
    sortingDirectionIcon() {
      return this.sortingDirection === "+" ? "mdi-arrow-up" : "mdi-arrow-down";
    }
  },
  methods: {
    isActive(label, direction) {
      return this.sortingLabel === label && this.sortingDirection === direction;
    },
    handleSelect({ value, label }, direction) {
      this.sortingLabel = label;
      this.sortingDirection = direction;
      this.$emit("onSort", `${direction}${value}`);
      this.open = false;
    }
  }
};
</script>

<style lang="scss">
.sorting-details {
  margin-top: 10px;
  background-color: $white;

  .radio {
    .v-radio {
      label {
        left: -20px !important;
      }
    }

    &--standard {
      padding: 10px 0;
      background-color: $white !important;
      border: none !important;

      &:after {
        top: 9px;
        left: 0;
      }

      &__active {
        background-color: #ffffff !important;
        border: none !important;
        box-shadow: none !important;

        &:before {
          top: 14px;
          left: 4.6px;
        }
      }
    }
  }
}
</style>
