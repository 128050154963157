<template>
  <div class="app-bar" :class="{ 'mt-12': isImpersonating }">
    <a href="#app-content" class="skip-link" tabindex="1">
      Skip to main content
    </a>
    <div class="d-flex align-center">
      <NavigationAccountMenu />
      <v-menu v-if="showMobileMenu">
        <template v-slot:activator="{ on }">
          <div v-on="on" class="mx-5">
            <v-icon color="primary">
              mdi-menu
            </v-icon>
          </div>
        </template>
        <v-list>
          <v-list-item-group class="my-2">
            <Permissioned vertical>
              <NavigationItem
                v-for="(item, index) in navigationItems"
                :key="index"
                :item="item"
                :organisationUnitId="organisationUnitId"
                :permission="item.permission"
              />
            </Permissioned>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </div>
    <Permissioned class="mx-5 navigation-container" v-if="!showMobileMenu">
      <NavigationItem
        v-for="(item, index) in navigationItems"
        :key="index"
        :item="item"
        :organisationUnitId="organisationUnitId"
        :permission="item.permission"
      />
    </Permissioned>
    <Permissioned class="settings-nav-item">
      <NavigationItem
        :item="settingsNavigationItem"
        :permission="settingsNavigationItem.permission"
      />
      <NavigationItem :item="helpNavigationItem" />
    </Permissioned>
  </div>
</template>

<script>
import Permissioned from "@/components/common/Permissioned";
import {
  HELP_NAVIGATION_ITEM,
  MAIN_NAVIGATION_ITEMS,
  SETTINGS_NAVIGATION_ITEM
} from "@/constants/navigation";
import NavigationItem from "@/components/navigation/components/NavigationItem";
import NavigationAccountMenu from "@/components/navigation/components/NavigationAccountMenu";
import { createNamespacedHelpers } from "vuex";
import { AUTH_NAMESPACE } from "@/store/modules/auth";
import { getImpersonateUserId } from "@/services/utils";

const { mapState } = createNamespacedHelpers(AUTH_NAMESPACE);

export default {
  components: { Permissioned, NavigationItem, NavigationAccountMenu },
  computed: {
    ...mapState({
      user: state => state.userData,
      permissions: state => state.permissions
    }),
    helpCentreLink() {
      return this.helpCentreLinks[this.user.accountType];
    },
    showMobileMenu() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    isImpersonating() {
      return Boolean(getImpersonateUserId());
    },
    organisationUnitId() {
      return this.user.organisation && this.user.organisation.id;
    }
  },
  data() {
    return {
      navigationItems: MAIN_NAVIGATION_ITEMS,
      settingsNavigationItem: SETTINGS_NAVIGATION_ITEM,
      helpNavigationItem: HELP_NAVIGATION_ITEM
    };
  }
};
</script>
