import {
  BOOKINGS_NAMESPACE,
  FETCH_CURRENT_BOOKING_CANDIDATES
} from "@/store/modules/bookings/actions";

export const bookingCandidatesUpdater = async ({ dispatch, handler }) => {
  const response = await handler();
  dispatch(`${BOOKINGS_NAMESPACE}/${FETCH_CURRENT_BOOKING_CANDIDATES}`, null, {
    root: true
  });
  return response;
};
