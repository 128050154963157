import TIMESHEET_LIST from "./timesheets/timesheet-list";
import TIMESHEET from "./timesheets/timesheet";
import MOCKED_TIMESHEET_COMMENTS from "./timesheets/comments";

export const MOCKED_TIMESHEETS_STATE = {
  timesheets: TIMESHEET_LIST,
  currentTimesheet: TIMESHEET,
  timesheetComments: MOCKED_TIMESHEET_COMMENTS
};

export const SORTING_DATA = null;
