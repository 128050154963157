<template>
  <div class="date-picker-wrapper">
    <v-menu
      ref="menu1"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      content-class="datepicker"
      max-width="290px"
      min-width="290px"
      :disabled="readonly"
      offset-y
    >
      <template v-slot:activator="{ on }">
        <ValidationProvider
          v-slot="{ errors }"
          :name="validateName"
          :rules="validationRules"
          v-if="required"
        >
          <v-text-field
            :error-messages="showValidationErrors ? errors : ''"
            :hide-details="hideDetails"
            readonly
            slot="activator"
            :value="dateText"
            :label="label"
            :placeholder="placeholder"
            persistent-hint
            prepend-inner-icon="mdi-calendar-range"
            v-on="on"
            :clearable="clearable"
            @click:clear="clearDates"
          />
        </ValidationProvider>
        <v-text-field
          v-else
          hide-details
          readonly
          slot="activator"
          :value="dateText"
          :label="label"
          :placeholder="placeholder"
          persistent-hint
          prepend-inner-icon="mdi-calendar-range"
          v-on="on"
          :clearable="clearable"
          @click:clear="clearDates"
        />
      </template>
      <div class="date-picker">
        <v-date-picker
          class="v-date-range__pickers"
          v-model="dates"
          @input="updateDate"
          @click:date="checkDates"
          :show-current="false"
          color="primary"
          :events="highlightDates"
          :event-color="highlightClasses"
          :multiple="isRange"
          :dark="isDark"
          :min="minDate"
          :max="maxDate"
          :first-day-of-week="1"
          no-title
        />
      </div>
      <div class="mobile-field-date-picker" v-if="isMobile">
        <v-icon @click="closePicker" :class="{ light: isDark }">
          mdi-close
        </v-icon>
      </div>
    </v-menu>
  </div>
</template>

<script>
import moment from "moment";
import { isArray, isString, map, last, size, toLower } from "lodash";
import { BASE_DATE_FORMAT } from "@/constants/common.js";
import { ValidationProvider } from "vee-validate";

export default {
  name: "DatePicker",
  props: {
    /**
     * String of label for datepicker
     */
    label: {
      type: String,
      required: false,
      default: "Date"
    },
    /**
     * Boolean if true set the picker to activate range of dates
     */
    isRange: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Boolean if true set the picker to dark mode
     */
    isDark: {
      type: Boolean,
      required: false,
      default: false
    },
    inputValue: {
      type: [String, Array],
      required: false
    },
    minDate: {
      type: String,
      required: false
    },
    maxDate: {
      type: String,
      required: false
    },
    showValidationErrors: {
      type: Boolean,
      required: false
    },
    format: {
      type: String,
      required: false,
      default: BASE_DATE_FORMAT
    },
    required: Boolean,
    validateName: {
      type: String,
      default: "dateValidate"
    },
    hideDetails: Boolean,
    legalAge: Boolean,
    readonly: Boolean,
    clearable: Boolean
  },
  components: {
    ValidationProvider
  },
  mounted() {
    if (!this.isRange) {
      this.innerDates = "";
    }
  },
  data() {
    return {
      menu: false,
      dateFormat: this.format,
      innerDates: [],
      highlightClasses: [],
      highlightDates: []
    };
  },
  computed: {
    placeholder() {
      const format = toLower(this.format);
      return this.isRange ? `${format} - ${format}` : format;
    },
    dates: {
      get() {
        return isString(this.inputValue) || isArray(this.inputValue)
          ? this.inputValue
          : this.innerDates;
      },
      set(val) {
        this.innerDates = val;
      }
    },
    dateText() {
      if (this.isRange) {
        this.highlight();
        return this.dateRangeText();
      }

      if (this.dates.length > 0) {
        return moment(this.dates).format(this.dateFormat);
      }

      return null;
    },
    bothSelected() {
      return this.dates[0] && this.dates[1];
    },
    validationRules() {
      return this.legalAge ? "required|legalAge" : "required";
    }
  },
  methods: {
    checkDates() {
      if (this.isRange) {
        this.sortDates();
        if (this.dates.length > 2) {
          this.dates.splice(0, 2);
        }
      }
    },
    sortDates() {
      if (this.dates[0] > this.dates[1]) {
        let lastDate = this.dates[0];
        this.dates[0] = this.dates[1];
        this.dates[1] = lastDate;
      }
    },
    updateDate(val) {
      if (this.isRange) {
        this.checkDates();
        this.highlight();
      }

      if (this.isRange && size(val) > 2) {
        val = [last(val)];
        this.dates = val;
      }

      if (size(val) === 2 || !this.isRange) {
        this.menu = false;
      }

      this.$emit("updatedDate", val);
      this.$emit("onChange", val);
    },
    dateRangeText() {
      let formattedDates = map(this.dates, date => {
        return moment(date).format(this.dateFormat);
      });

      if (this.dates.length === 1) {
        formattedDates[0] = moment(this.dates[0]).format(this.dateFormat);
      }

      return formattedDates.join(" - ");
    },
    highlight() {
      if (!this.bothSelected) {
        this.resetHighlights();
        return;
      }

      const dates = [];
      const classes = {};
      const start = moment(this.dates[0]);
      const end = moment(this.dates[1]);
      const diff = Math.abs(end.diff(start, "days"));

      // Loop though all the days in range.
      for (let i = 1; i <= diff + 1; i++) {
        const date = moment(start)
          .add(i, "days")
          .utc()
          .format("YYYY-MM-DD");
        dates.push(date);
        const classesArr = [];

        classesArr.push(`v-date-range__in-range`);

        i === 1 && classesArr.push(`v-date-range__range-start`);
        i === diff + 1 && classesArr.push(`v-date-range__range-end`);
        classes[date] = classesArr.join(" ");
      }
      this.highlightDates = dates;
      this.highlightClasses = classes;
    },
    resetHighlights() {
      this.highlightDates = [];
      this.highlightClasses = {};
    },
    closePicker() {
      this.menu = false;
    },
    clearDates() {
      this.dates = this.isRange ? [] : "";
      this.$emit("updatedDate", this.dates);
      this.$emit("onChange", this.dates);
      this.$emit("clear");
    }
  }
};
</script>

<style lang="scss">
.date-picker-wrapper {
  .v-input__slot {
    height: 50px;
    padding: 6.5px 15px !important;
  }

  .v-input {
    &__prepend-inner {
      padding-right: 10px !important;
      margin: 0 !important;
      padding-top: 5px !important;
    }

    &__append-inner {
      padding-top: 2px !important;
    }
  }

  .v-icon.v-icon {
    width: 18px;
    font-feature-settings: unset;
  }

  .v-picker__body {
    height: 340px;
  }
}

.v-date-picker-table {
  height: auto !important;
  td {
    border-radius: 0;
    padding-top: 2px !important;
  }

  .v-btn {
    font-size: 18px;
  }
}

.v-date-picker-table__events {
  height: 100% !important;
  width: 100% !important;
  top: 0 !important;
  z-index: -1 !important;
}

.v-date-picker-table--date .v-btn {
  border-radius: 50% !important;
}

.v-date-picker-table table {
  width: auto !important;
  margin: auto !important;
  border-collapse: collapse;

  & th,
  & td {
    height: 38px !important;
    width: 37px !important;
    font-size: 18px;
  }
}

.v-date-range__in-range {
  height: 100% !important;
  width: calc(100% + 5px) !important;
  margin: 0 !important;
  border-radius: 0 !important;
  background-color: rgba($primary, 0.5) !important;
  position: relative;
  left: -2px;
}

.v-date-range__range-start {
  border-radius: 16px 0 0 16px !important;
}

.v-date-range__range-end {
  width: 97% !important;
  border-radius: 0 16px 16px 0 !important;
}

.mobile-field-date-picker {
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  padding-left: 10px;
  height: 45px;

  .v-input.v-text-field {
    margin-top: 0;
  }
}

@media only screen and (max-width: $mobile-breakpoint - 1) {
  .datepicker.v-menu__content {
    top: 0 !important;
  }
  .v-menu__content.theme--light.v-menu__content--fixed.menuable__content__active.datepicker {
    right: 0 !important;
    left: 0 !important;
    bottom: 0 !important;
    max-width: 100% !important;
    width: 100%;
    height: 100vh;
    border-radius: 0 !important;
  }
  .mobile-field-date-picker {
    display: flex;

    .v-input__slot {
      background: transparent !important;
    }

    .v-input__control {
      min-height: 50px;
      height: 35px;
    }

    .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
      > .v-input__control
      > .v-input__slot,
    .v-text-field.v-text-field--enclosed .v-text-field__details {
      padding: 0 0;
    }

    .v-icon.v-icon.v-icon--link {
      cursor: pointer;
      margin-bottom: 5px;
    }
  }

  .date-picker {
    .v-card:not(.v-sheet--tile):not(.v-card--shaped) {
      border-radius: 0 !important;
      width: 100% !important;
      max-width: 100% !important;
      height: 100vh !important;
    }

    .v-picker__body {
      width: 100% !important;
      margin: 0;
    }

    .v-date-picker-header {
      margin-top: 45px;
    }

    .v-date-picker-table {
      height: 100vh !important;
    }
  }
}
</style>
