<template>
  <v-btn
    class="secondary-button"
    :class="{ 'full-width': fullWidth, shrink }"
    :style="{ color: textColor }"
    :color="color"
    :disabled="disabled"
    :rounded="rounded"
    elevation="0"
    :text="text"
    :light="light"
    @click="handleClick"
    :loading="loading"
    :href="href"
    :to="to"
  >
    <div class="d-flex align-center" :class="{ 'flex-column': !isMobile }">
      <slot />
    </div>
  </v-btn>
</template>

<script>
export default {
  name: "SecondaryButton",
  props: {
    disabled: Boolean,
    color: {
      type: String,
      required: false,
      default: null
    },
    text: {
      type: Boolean,
      required: false,
      default: false
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    dense: {
      type: Boolean,
      required: false,
      default: false
    },
    fullWidth: {
      type: Boolean,
      required: false,
      default: false
    },
    shrink: {
      type: Boolean,
      required: false,
      default: false
    },
    textColor: {
      type: String,
      required: false
    },
    to: {
      type: String,
      required: false
    },
    href: String,
    rounded: Boolean,
    light: Boolean
  },
  methods: {
    handleClick(event) {
      if (!this.loading) {
        this.$emit("click", event);
      }
    }
  }
};
</script>
