<template>
  <v-system-bar
    v-if="isUpdateAvailable"
    color="black"
    class="v-system-bar--default update-bar"
    :height="50"
    app
  >
    <div class="v-system-bar__description">
      Please reload, there is a new version of the app
    </div>
    <v-btn small class="base-system-btn white--text" @click="reloadApp">
      Reload Page
    </v-btn>
  </v-system-bar>
</template>

<script>
import { refreshPageMixin } from "@/mixins/refresh-page.mixin";

export default {
  mounted() {
    this.initVersionCheck("/version.json");
  },
  mixins: [refreshPageMixin]
};
</script>
