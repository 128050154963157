import { isMockedDataEnabled } from "@/utils/mocked-data";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  shiftCandidates: [],
  isFetchingShiftCandidates: false,
  availableCandidates: [],
  isFetchingAvailableCandidates: false,
  isFetchingApplicationShifts: false,
  applicationShifts: [],
  isFetchingApplications: false,
  applicationsToReview: [],
  unavailableCandidates: [],
  isFetchingUnavailableCandidates: false,
  unavailableWorkers: [],
  isFetchingUnavailableWorkers: false
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions: isMockedDataEnabled() ? {} : actions
};
