import APIService, { withPrefix } from "@/services/APIService";
import JSONApiService from "@/services/JSONApiService";
import { BOOKING_TEMPLATE_MODEL } from "@/models/booking-template-model";
import { FIELD_NECESSITY_MODEL } from "@/models/field-necessity-model";
import {
  include,
  TEMPLATE_PAY_RATES,
  TEMPLATE_PAY_RATES_ITEMS
} from "@/models/relationships";
import { hireReasonEndpoints } from "@/models/hire-reason-model";
import { disablePluralizeForCall } from "@/services/utils";
import { ADDRESS_MODEL } from "@/models/address-model";
import { rejectionReasonEndpoints } from "@/models/rejection-reason-model";
import { COUNTRY_MODEL } from "@/models/country-model";
import {
  COMPLIANCE_REQUIREMENT,
  OTHER_REQUIREMENT
} from "@/models/requirement-model";
import { PUBLISHING_SCHEDULE_TAG } from "@/models/tag-model";
import { COST_CATEGORY } from "@/models/cost-category-model";
import { TIME_UNIT_MODEL } from "@/models/time-unit-model";

const API = withPrefix(APIService.client(), "data");

const DATA = "data";

const dataApi = commit => ({
  getBookingTemplates: () =>
    JSONApiService(commit)
      .all(BOOKING_TEMPLATE_MODEL)
      .get({
        filter: { status: "active" },
        ...include(TEMPLATE_PAY_RATES)
      }),
  getBookingTemplate: id =>
    JSONApiService(commit)
      .one(BOOKING_TEMPLATE_MODEL, id)
      .get(include(TEMPLATE_PAY_RATES, TEMPLATE_PAY_RATES_ITEMS)),
  getComplianceRequirements: () =>
    JSONApiService(commit)
      .all(DATA)
      .all(COMPLIANCE_REQUIREMENT)
      .get(),
  getOtherRequirements: () =>
    JSONApiService(commit)
      .all(DATA)
      .all(OTHER_REQUIREMENT)
      .get(),
  getIncomeTypes: () => API.get("income-types"),
  getLocations: params =>
    JSONApiService(commit)
      .all(ADDRESS_MODEL)
      .get({ ...params }),
  getReasonsForHire: organisationId => {
    const api = JSONApiService(commit).all(DATA);
    disablePluralizeForCall(api, () =>
      api.all(hireReasonEndpoints.REASONS_FOR_HIRE)
    );
    return api.get({ filter: { client_id: organisationId } });
  },
  getRequirements: () => API.get("requirements"),
  getRejectionReasons: () =>
    JSONApiService(commit)
      .all(DATA)
      .all(rejectionReasonEndpoints.REASONS_FOR_REJECTION)
      .get(),
  getCountries: () =>
    JSONApiService(commit)
      .all(DATA)
      .all(COUNTRY_MODEL)
      .get(),
  getFieldNecessities: params => {
    return JSONApiService(commit)
      .all(FIELD_NECESSITY_MODEL)
      .get({ ...params });
  },
  getPublishingScheduleTags: () =>
    JSONApiService(commit)
      .all(DATA)
      .all(PUBLISHING_SCHEDULE_TAG)
      .get(),
  getCostCategories: () =>
    JSONApiService(commit)
      .all(DATA)
      .all(COST_CATEGORY)
      .get(),
  removeFile: async url => APIService.client().delete(url),
  getTimeUnits: async params =>
    JSONApiService(commit)
      .all(DATA)
      .all(TIME_UNIT_MODEL)
      .get(params),
  getSystemParameters: () => API.get("system-parameters")
});

export default dataApi;
