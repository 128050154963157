import Vue from "vue";
import VueRouter from "vue-router";
import Timesheets from "@/views/timesheets/TimesheetList.vue";
import TimesheetDetails from "@/views/timesheets/details/TimesheetDetails.vue";
import SubmitTimesheet from "@/views/timesheets/SubmitTimesheet.vue";
import FinanceTabs from "@/views/finance/FinanceTabs.vue";
import Dashboard from "@/views/Dashboard.vue";
import Agencies from "@/views/agencies/Agencies.vue";
import AgencyDetails from "@/views/agencies/components/AgencyDetails.vue";
import PageNotFound from "@/views/PageNotFound.vue";
import AccessDenied from "@/views/AccessDenied.vue";
import { isPermissioned } from "@/utils/permissions";
import { NS_FETCH_SELF_DETAILS } from "@/store/modules/auth/action-types";
import { SET_AUTH_DIALOG_VISIBILITY } from "@/store/modules/global/mutation-types";
import store from "@/store";
import { MAIN_NAVIGATION_ITEMS } from "@/router/constants";
import { settingsRouter } from "@/router/settings-router";
import { reportsRouter } from "@/router/reports-router";
import { bookingsRouter } from "@/router/bookings-router";
import { getToken } from "@/services/utils";
import { get } from "lodash";
import { workersRouter } from "@/router/workers-router";
import Files from "@/views/files/Files";
import FileDetails from "@/views/files/components/FileDetails";
import Exports from "@/views/exports/ExportTabs";
import Shifts from "@/views/shifts/Shifts";

const {
  DASHBOARD,
  AGENCIES,
  TIMESHEETS,
  FINANCE,
  EXPORTS
} = MAIN_NAVIGATION_ITEMS;

Vue.use(VueRouter);

export const redirectWithPermissionCheck = (next, permission) => {
  if (!isPermissioned(permission)) {
    next({ name: "accessDenied" });
  } else next();
};

const getFirstPermissionedRouteName = () => {
  const names = Object.values(MAIN_NAVIGATION_ITEMS);
  for (let name of names) {
    if (isPermissioned(name)) {
      return name;
    }
  }
  return "accessDenied";
};

const checkForNewToken = token => {
  if (token) {
    localStorage.setItem("token", token);
    localStorage.removeItem("selectedProfile");
  }
  return token;
};

const routes = [
  {
    path: "*",
    name: "pageNotFound",
    component: PageNotFound
  },
  {
    path: "/access-denied",
    name: "accessDenied",
    component: AccessDenied
  },
  {
    path: "/dashboard",
    name: DASHBOARD,
    component: Dashboard,
    beforeEnter: (to, from, next) => {
      redirectWithPermissionCheck(next, DASHBOARD);
    }
  },
  {
    path: "/agencies",
    name: AGENCIES,
    component: Agencies,
    beforeEnter: (to, from, next) => {
      redirectWithPermissionCheck(next, AGENCIES);
    }
  },
  {
    path: "/agencies/:id",
    name: "agencyDetails",
    component: AgencyDetails,
    beforeEnter: (to, from, next) => {
      redirectWithPermissionCheck(next, "agencies.view");
    }
  },
  {
    path: "/timesheets",
    name: TIMESHEETS,
    component: Timesheets,
    beforeEnter: (to, from, next) => {
      redirectWithPermissionCheck(next, TIMESHEETS);
    }
  },
  {
    path: "/timesheets/:id",
    name: "timesheetDetails",
    component: TimesheetDetails,
    beforeEnter: (to, from, next) => {
      redirectWithPermissionCheck(next, "timesheets.view");
    }
  },
  {
    path: "/timesheets/submit/:id",
    name: "submitTimesheet",
    component: SubmitTimesheet
  },
  {
    path: "/finance",
    name: FINANCE,
    component: FinanceTabs,
    beforeEnter: (to, from, next) => {
      redirectWithPermissionCheck(next, FINANCE);
    },
    children: [
      {
        path: "invoices",
        name: "finance.invoices",
        component: () => import("@/views/finance/components/Invoices/Invoices"),
        beforeEnter: (to, from, next) => {
          redirectWithPermissionCheck(next, "finance.view-invoices");
        }
      },
      {
        path: "payroll",
        name: "finance.payroll",
        component: () => import("@/views/finance/components/Payroll/Payroll"),
        beforeEnter: (to, from, next) => {
          redirectWithPermissionCheck(next, "finance.view-payroll");
        }
      }
    ]
  },
  {
    path: "/files",
    name: "files",
    component: Files,
    beforeEnter: (to, from, next) => {
      redirectWithPermissionCheck(next, "files.view");
    }
  },
  {
    path: "/files/:id",
    name: "fileDetails",
    component: FileDetails,
    beforeEnter: (to, from, next) => {
      redirectWithPermissionCheck(next, "files.view");
    }
  },
  {
    path: "/exports",
    name: EXPORTS,
    component: Exports,
    beforeEnter: (to, from, next) => {
      redirectWithPermissionCheck(next, "reports");
    }
  },
  {
    path: "/shifts",
    name: "shifts",
    component: Shifts,
    beforeEnter: (to, from, next) => {
      redirectWithPermissionCheck(next, "shifts.navigation-view");
    }
  },
  ...bookingsRouter,
  ...settingsRouter,
  ...workersRouter,
  ...reportsRouter
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  const token = checkForNewToken(to.query.token) || getToken();
  if (!token) {
    return store.commit(SET_AUTH_DIALOG_VISIBILITY, true);
  }
  const hasPermissions = get(store, "state.auth.permissions");
  if (!hasPermissions) {
    await store.dispatch(NS_FETCH_SELF_DETAILS);
  }
  if (to.path === "/") {
    next({ name: getFirstPermissionedRouteName() });
  } else {
    next();
  }
});

export default router;
