<template>
  <BaseDialog isOpen>
    <template v-slot:title>
      <div>
        An invitation email has been sent
      </div>
    </template>
    <template v-slot:body>
      The email contains the following link which you can send to them directly
      if you prefer.
      <v-text-field class="input-action" height="50" :value="link" readonly>
        <template v-slot:append>
          <div>
            <PrimaryButton light @click.native="copyLink" shrink>
              Copy
            </PrimaryButton>
          </div>
        </template>
      </v-text-field>
    </template>
    <template v-slot:actions>
      <div>
        <PrimaryButton @click.native="close" text>
          Cancel
        </PrimaryButton>
        <PrimaryButton @click.native="close">
          Okay
        </PrimaryButton>
      </div>
    </template>
  </BaseDialog>
</template>

<script>
import BaseDialog from "@/components/common/BaseDialog";
import PrimaryButton from "@/components/common/Button/PrimaryButton";

export default {
  name: "InvitationSentDialog",
  components: { PrimaryButton, BaseDialog },
  props: {
    link: String
  },
  data() {
    return {
      isLoading: false
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    copyLink() {
      navigator.clipboard.writeText(this.link);
    }
  }
};
</script>
