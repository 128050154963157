<template>
  <BaseDialog :isOpen="isOpen">
    <template v-slot:title>
      <span>Upload File</span>
    </template>
    <template v-slot:body>
      <FileUpload
        @unselectFile="unselectFile"
        @selectFile="selectFile"
        @onUploadSuccess="updateFiles"
        :uploadingUrl="getAddFileUrl"
        :fileTypes="[
          '.pdf',
          '.jpeg',
          '.jpg',
          '.png',
          '.doc',
          '.docx',
          '.xlsx',
          '.csv'
        ]"
        :files="files"
        :isLoading="isUploading"
        disableAutoProceed
        customActions
      />
    </template>
    <template v-slot:actions>
      <div class="accept-booking-actions">
        <PrimaryButton @click.native="cancel" text>
          Cancel
        </PrimaryButton>
        <PrimaryButton
          @click.native="uploadNewFile"
          :disabled="disableUpload"
          :loading="isLoading"
        >
          Upload File
        </PrimaryButton>
      </div>
    </template>
  </BaseDialog>
</template>

<script>
import BaseDialog from "@/components/common/BaseDialog";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import { createNamespacedHelpers } from "vuex";
import FileUpload from "@/components/common/FileUpload";
import APIService from "@/services/APIService";
import { filter, forEach, first, isEmpty } from "lodash";
import { FILES_NAMESPACE } from "@/store/modules/files";
import {
  CREATE_FILE,
  UPLOAD_FILE,
  FETCH_FILES
} from "@/store/modules/files/actions";
import { AUTH_NAMESPACE } from "@/store/modules/auth";

const { mapActions } = createNamespacedHelpers(FILES_NAMESPACE);
const { mapState } = createNamespacedHelpers(AUTH_NAMESPACE);

export default {
  components: { PrimaryButton, BaseDialog, FileUpload },
  props: {
    isOpen: Boolean
  },
  data() {
    return {
      isLoading: false,
      isUploading: false,
      files: []
    };
  },
  computed: {
    ...mapState(["userData"]),
    fileName() {
      const file = first(this.files);
      return file.name;
    },
    disableUpload() {
      return isEmpty(this.files);
    }
  },
  methods: {
    ...mapActions({
      createFile: CREATE_FILE,
      uploadFile: UPLOAD_FILE,
      fetchFiles: FETCH_FILES
    }),
    cancel() {
      this.$emit("close");
    },
    selectFile({ uppyInstance, file }) {
      this.uppyInstance = uppyInstance;
      this.files.push(file);
    },
    unselectFile({ uppyInstance, file: fileToBeRemoved }) {
      this.files = filter(this.files, file => file.id !== fileToBeRemoved.id);
      uppyInstance.reset();
    },
    async uploadFiles(fileId) {
      forEach(this.files, ({ id }) => this.setFileUrl(id, fileId));
      if (this.uppyInstance) {
        await this.uppyInstance.upload();
      }
    },
    async setFileUrl(id, fileId) {
      return this.uppyInstance.setFileState(id, {
        xhrUpload: { endpoint: this.getAddFileUrl(fileId) }
      });
    },
    async uploadNewFile() {
      this.isLoading = true;
      const { data } = await this.createFile({
        name: this.fileName,
        organisationUnitId: this.userData.organisation.id
      });
      await this.uploadFiles(data.id);
      this.files = [];
      this.fetchFiles();
      this.isLoading = false;
      this.$emit("close");
    },
    getAddFileUrl(fileId) {
      const host = APIService.host;
      return `${host}/files/${fileId}/file`;
    },
    updateFiles(files) {
      this.files = files;
    }
  }
};
</script>
