export const INVOICE_MODEL = "invoice";

const invoiceModel = {
  invoiceNumber: "",
  dateCreated: "",
  issuer: "",
  recipient: "",
  total: 0,
  currencyCode: "",
  invoicePdf: null,
  invoiceCsv: null,
  organisationUnitId: null,
  periodEndDate: null,
  periodStart: "",
  periodEnd: "",
  invoiceType: "",
  clientInvoiceSet: null,
  state: "",
  "client-invoice-sets": {
    jsonApi: "hasOne",
    type: "client-invoice-sets"
  }
};

export default invoiceModel;
