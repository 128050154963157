<template>
  <div class="status-item">
    <slot name="icon" />
    <p class="status-name">
      <slot />
    </p>
    <slot name="status" />
  </div>
</template>

<script>
export default {
  name: "StatusItem"
};
</script>

<style lang="scss">
.status-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 100%;

  .v-icon,
  .v-chip {
    margin: auto 0.5rem auto 0;
  }

  p.status-name {
    flex: 1;
    white-space: nowrap;
    margin: auto 0;

    @media only screen and (max-width: $mobile-breakpoint - 1) {
      white-space: normal;
    }
  }

  .v-chip {
    text-transform: uppercase;
    font-weight: 600;
    height: 25px;
  }
}
</style>
