<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateInput"
        v-bind="attrs"
        v-on="on"
        class="ReportSingleDateFilter-input"
        placeholder="dd/mm/yyyy"
        v-mask="'##/##/####'"
        :errorMessages="errorMessages"
        @change="onDateInputChanged"
      />
    </template>
    <v-date-picker v-model="date" no-title scrollable>
      <v-spacer />
      <v-btn text color="primary" @click="menu = false">
        Cancel
      </v-btn>
      <v-btn text color="primary" @click="$refs.menu.save(date)">
        OK
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import {
  getReportFormatDate,
  isValidDateString,
  formatDate
} from "@/utils/time";
import { BASE_DATE_FORMAT } from "@/constants/common";

export default {
  name: "ReportSingleDateFilter",
  data: instance => ({
    date: new Date(instance.value).toISOString().substr(0, 10),
    menu: false,
    modal: false,
    dateInput: getReportFormatDate(instance.value)
  }),
  props: {
    value: String,
    onChange: Function,
    errorMessages: [String, Array]
  },
  methods: {
    onDateInputChanged() {
      if (isValidDateString(this.dateInput)) {
        this.date = formatDate({
          date: this.dateInput,
          startFormat: BASE_DATE_FORMAT
        });
      } else {
        this.dateInput = getReportFormatDate(this.date);
      }
    }
  },
  watch: {
    date(value) {
      this.dateInput = getReportFormatDate(value);
      this.onChange(value);
    }
  }
};
</script>
