export const COMMENT_MODEL = "comment";

const commentModel = {
  body: "",
  createdAt: "",
  updatedAt: "",
  commentableType: "",
  commentableId: "",
  notifyListeners: false,
  author: {
    jsonApi: "hasOne",
    type: "user"
  }
};

export default commentModel;
