<template>
  <EmptyStates description="No data to display" />
</template>

<script>
import EmptyStates from "@/components/common/EmptyStates";

export default {
  name: "ReportEmptyState",
  components: {
    EmptyStates
  }
};
</script>

<style scoped lang="scss"></style>
