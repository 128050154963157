<template>
  <app-layout class="shifts container col-lg-9 col-sm-12">
    <template #header>
      <BaseListToolbar title="Shifts" hide-search-bar>
        <template #inline-filters>
          <ShiftsFilter
            @onClearFilters="updateFilters({})"
            :is-loading="isFetchingShifts"
            :available-filters="availableFilters"
            :outer-selected-filters="shiftsFilters"
            @filter="updateFilters"
          />
        </template>
      </BaseListToolbar>
    </template>
    <template #body>
      <div v-if="isFetchingShifts">
        <v-skeleton-loader v-for="i in 10" :key="i" type="list-item" />
      </div>
      <div v-else>
        <ListTypeSelection
          v-if="!shiftsFilters.startDate"
          class="my-4 px-2"
          :text="listTypeSelectionText"
          @selectListType="changeListType"
        >
          <template v-slot:icon>
            <v-icon color="primary" class="ml-2">
              {{ `mdi-timer-${showPastShifts ? "off-" : ""}outline` }}
            </v-icon>
          </template>
        </ListTypeSelection>
        <div class="my-2" v-for="(shifts, key) in groupedShifts" :key="key">
          <h1 class="text-center my-2">{{ key | shiftDate }}</h1>
          <div v-for="(shift, index) in shifts" :key="index">
            <div class="shift-item">
              <ShiftItem
                :shift="shift"
                :showCheckBox="Boolean(selectedShiftsCount)"
                :isSelected="isSelected(shift)"
                @select="selectShift(shift)"
              />
            </div>
          </div>
        </div>
        <v-pagination
          class="my-10"
          v-if="showPagination"
          v-model="page.currentPage"
          :total-visible="page.pageSize"
          :length="page.lastPage"
          @input="loadShifts"
        />
      </div>
      <BaseFooter class="relative">
        <MultipleSelectAction
          v-show="selectedShiftsCount"
          :selectedCount="selectedShiftsCount"
          :isSelectAll="isSelectAll"
          @selectAll="selectAllShifts"
          @cancel="clearSelectedShifts"
        />
      </BaseFooter>
      <BaseFooter v-if="showFooter">
        <ExpandedButtons class="actions-bar">
          <!-- TODO: allow fetching candidates for multiple booking and shifts
          <SubmitCandidatesAction
            v-if="showSubmitButton"
            :selected-shifts="selectedShifts"
            :filters="filters"
            @updateList="loadShifts"
          />
          <AllocateWorkersAction
            v-if="showAllocateButton"
            :selected-shifts="selectedShifts"
            @updateList="loadShifts"
          /> -->
          <EditQuotasAction
            :shift="firstShift"
            v-if="showEditQuotas"
            @update="loadShifts"
          />
          <ConfirmAllocationsAction
            v-if="showConfirmAllocationsButton"
            :shifts="selectedShifts"
            @updateList="loadShifts"
          />
          <CancelShiftAction
            v-if="showCancelButton"
            :shifts="selectedShifts"
            @updateList="loadShifts"
          />
          <DeclineAllocationsAction
            v-if="showDeclineAllocationsButton"
            :shifts="selectedShifts"
            @updateList="loadShifts"
          />
          <PrintTimesheetAction
            v-if="showPrintTimesheetButton"
            :shifts="selectedShiftsId"
            @updateList="loadShifts"
          />
          <PlaceWorkersAction
            v-if="showPlaceWorkers"
            :shifts="selectedShifts"
            @update="loadShifts"
          />
        </ExpandedButtons>
      </BaseFooter>
    </template>
  </app-layout>
</template>

<script>
import { filter, first, size, some } from "lodash";
import ShiftItem from "@/views/shifts/components/ShiftItem";
import { groupByDate } from "@/utils/helpers";
import MultipleSelectAction from "@/components/common/Button/MultipleSelectAction";
import BaseFooter from "@/components/common/BaseFooter";
import { createNamespacedHelpers } from "vuex";
import {
  BOOKINGS_NAMESPACE,
  FETCH_SHIFTS
} from "@/store/modules/bookings/actions";
import ExpandedButtons from "@/components/common/ExpandedButtons";
import { checkIfSelectedApplicationShiftsArePermitted } from "@/utils/shifts";
// import AllocateWorkersAction from "@/views/bookings/BookingDetails/components/Shifts/AllocateWorkers/AllocateWorkersAction";
// import SubmitCandidatesAction from "@/views/bookings/components/actions/SubmitCandidatesAction";
import ConfirmAllocationsAction from "@/views/bookings/components/actions/ConfirmAllocationsAction";
import DeclineAllocationsAction from "@/views/bookings/components/actions/DeclineAllocationsAction";
import CancelShiftAction from "@/views/bookings/components/actions/CancelShiftAction";
import PrintTimesheetAction from "@/views/bookings/components/actions/PrintTimesheetAction";
import ShiftsFilter from "@/views/shifts/components/ShiftsFilter";
import AppLayout from "@/components/common/base-layouts/AppLayout";
import { SHIFT_PERMISSIONS_STATUSES } from "@/constants/shifts";
import { isEmpty, map } from "lodash";
import moment from "moment";
import BaseListToolbar from "@/components/common/Toolbar/BaseListToolbar";
import ListTypeSelection from "@/components/common/ListTypeSelection";
import { paginationMixin } from "@/mixins/pagination.mixin";
import { SET_SHIFTS_FILTERS } from "@/store/modules/bookings/mutations";
import { isPermissioned } from "@/utils/permissions";
import EditQuotasAction from "@/views/shifts/components/actions/EditQuotasAction";
import PlaceWorkersAction from "@/views/shifts/components/actions/PlaceWorkersAction";

const {
  DECLINE,
  CONFIRM,
  ALLOCATE,
  SUBMIT,
  CANCEL
} = SHIFT_PERMISSIONS_STATUSES;
const { mapActions, mapState, mapMutations } = createNamespacedHelpers(
  BOOKINGS_NAMESPACE
);

export default {
  name: "Shifts",
  components: {
    AppLayout,
    ShiftItem,
    MultipleSelectAction,
    BaseFooter,
    ExpandedButtons,
    // AllocateWorkersAction,
    // SubmitCandidatesAction,
    ConfirmAllocationsAction,
    DeclineAllocationsAction,
    CancelShiftAction,
    BaseListToolbar,
    ListTypeSelection,
    ShiftsFilter,
    PrintTimesheetAction,
    EditQuotasAction,
    PlaceWorkersAction
  },
  mixins: [paginationMixin],
  created() {
    this.loadShifts();
  },
  data() {
    return {
      selectedShifts: [],
      lastExpiredShiftId: null,
      selectMultiple: true,
      showPastShifts: false,
      availableFilters: {}
    };
  },
  computed: {
    ...mapState({
      shifts: state => state.shifts,
      isFetchingShifts: state => state.isFetchingShifts,
      shiftsFilters: state => state.shiftsFilters
    }),
    groupedShifts() {
      return groupByDate({ items: this.shifts, format: "MM/DD/YYYY" });
    },
    selectedShiftsCount() {
      return size(this.selectedShifts);
    },
    isSelectAll() {
      return this.selectedShiftsCount === size(this.shifts);
    },
    showConfirmAllocationsButton() {
      return checkIfSelectedApplicationShiftsArePermitted({
        shifts: this.selectedShifts,
        permission: CONFIRM,
        nestedApplicationShifts: true
      });
    },
    showDeclineAllocationsButton() {
      return checkIfSelectedApplicationShiftsArePermitted({
        shifts: this.selectedShifts,
        permission: DECLINE,
        nestedApplicationShifts: true
      });
    },
    showAllocateButton() {
      return checkIfSelectedApplicationShiftsArePermitted({
        shifts: this.selectedShifts,
        permission: ALLOCATE,
        nestedApplicationShifts: true
      });
    },
    showSubmitButton() {
      return checkIfSelectedApplicationShiftsArePermitted(
        {
          shifts: this.selectedShifts,
          permission: SUBMIT
        },
        "shifts"
      );
    },
    showCancelButton() {
      return checkIfSelectedApplicationShiftsArePermitted(
        {
          shifts: this.selectedShifts,
          permission: CANCEL
        },
        "shifts"
      );
    },
    showPrintTimesheetButton() {
      return isPermissioned(`shifts.print-timesheet`);
    },
    showPlaceWorkers() {
      return isPermissioned(`shifts.submit-and-confirm-worker`);
    },
    showFooter() {
      return (
        (this.showConfirmAllocationsButton ||
          this.showDeclineAllocationsButton ||
          this.showAllocateButton ||
          this.showSubmitButton ||
          this.showPlaceWorkers ||
          this.showPrintTimesheetButton) &&
        !isEmpty(this.selectedShifts)
      );
    },
    listTypeSelectionText() {
      return `${this.listActionName} Past Shifts`;
    },
    listActionName() {
      return !this.showPastShifts ? "Show" : "Hide";
    },
    requestParams() {
      const filter = this.showPastShifts
        ? {}
        : { startDate: moment().format("DD-MM-YYYY") };
      return {
        filter: {
          ...filter,
          ...this.shiftsFilters
        },
        "page[size]": this.page.pageSize,
        "page[number]": this.page.currentPage
      };
    },
    selectedShiftsId() {
      return map(this.selectedShifts, ({ id }) => id);
    },
    firstShift() {
      return first(this.selectedShifts);
    },
    showEditQuotas() {
      return (
        size(this.selectedShifts) === 1 &&
        isPermissioned(`shifts.edit.workers-required`)
      );
    }
  },
  methods: {
    ...mapActions({
      fetchShifts: FETCH_SHIFTS
    }),
    ...mapMutations({
      setShiftsFilter: SET_SHIFTS_FILTERS
    }),
    async loadShifts() {
      this.selectedShifts = [];
      const data = await this.fetchShifts(this.requestParams);
      if (data) {
        this.adjustPageDetails(data.meta);
      }
    },
    adjustPageDetails({ page, filters }) {
      this.updatePageDetails({ page });
      this.availableFilters = filters;
    },
    selectShift(shift) {
      if (this.isShiftAlreadySelected(shift)) {
        this.unselectShift(shift);
      } else {
        this.selectedShifts = [...this.selectedShifts, shift];
      }
    },
    isShiftAlreadySelected(shift) {
      return some(this.selectedShifts, ({ id }) => id === shift.id);
    },
    unselectShift(shift) {
      this.selectedShifts = filter(
        this.selectedShifts,
        ({ id }) => id !== shift.id
      );
    },
    isSelected(shift) {
      return some(this.selectedShifts, shift);
    },
    clearSelectedShifts() {
      this.selectedShifts = [];
    },
    selectAllShifts() {
      this.selectedShifts = [...this.shifts];
    },
    changeListType() {
      this.showPastShifts = !this.showPastShifts;
      this.resetPagination();
      this.loadShifts();
    },
    updateFilters(filters) {
      this.setShiftsFilter(filters);
      this.resetPagination();
      this.loadShifts();
    }
  },
  watch: {
    isLoading(newValue) {
      if (newValue) {
        this.clearSelectedShifts();
      }
    }
  }
};
</script>
