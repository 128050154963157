<template>
  <v-btn
    class="base-button"
    :class="{ 'full-width': fullWidth, shrink }"
    :color="color"
    :disabled="disabled"
    elevation="0"
    :style="{ height: dense ? smallButtonHeight : largeButtonHeight }"
    :text="text"
    @click="handleClick"
    :loading="loading"
  >
    <slot />
  </v-btn>
</template>

<script>
import {
  smallButtonHeight,
  largeButtonHeight
} from "@/sass/abstracts/_variables.scss";

export default {
  name: "BaseButton",
  data() {
    return {
      smallButtonHeight,
      largeButtonHeight
    };
  },
  props: {
    disabled: Boolean,
    color: {
      type: String,
      required: false,
      default: null
    },
    text: {
      type: Boolean,
      required: false,
      default: false
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    dense: {
      type: Boolean,
      required: false,
      default: false
    },
    fullWidth: {
      type: Boolean,
      required: false,
      default: false
    },
    shrink: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    handleClick(event) {
      if (!this.loading) {
        this.$emit("click", event);
      }
    }
  }
};
</script>
