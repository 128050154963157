import { find } from "lodash";

export const GET_DEFAULT_ENTRY_TIME = "GET_DEFAULT_ENTRY_TIME";

export const IS_FETCHING_CURRENT_TIMESHEET = "IS_FETCHING_CURRENT_TIMESHEET";

const timesheetGetters = {
  [GET_DEFAULT_ENTRY_TIME]: state => {
    const entry =
      find(
        state.timesheetEntries,
        ({ expectedStartTime, expectedEndTime }) =>
          !!expectedStartTime && !!expectedEndTime
      ) || {};
    return {
      expectedStartTime: entry.expectedStartTime || "",
      expectedEndTime: entry.expectedEndTime || ""
    };
  },
  [IS_FETCHING_CURRENT_TIMESHEET]: state => state.isFetchingCurrentTimesheet
};

export default timesheetGetters;
