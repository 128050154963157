import { getFullName, getRoles } from "@/utils/users";

export const fullNameFilter = user => {
  return getFullName(user);
};

export const rolesFilter = roles => {
  return getRoles(roles);
};

export const accountTypeFilter = accountType => {
  return accountType === "worker" ? "Worker" : "Staff";
};
