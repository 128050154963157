import {
  FETCH_REPORTS,
  GET_REPORT,
  FETCH_USER_REPORTS,
  DELETE_USER_REPORT
} from "@/store/modules/exports/action-types";

import {
  SET_IS_FETCHING_REPORTS,
  SET_REPORTS,
  SET_USER_REPORTS
} from "@/store/modules/exports/mutation-types";
import {
  handleWithErrorMessage,
  handleWithGlobalMessage
} from "@/services/utils";
import api from "@/services/modules/exports";
import { setStoreValue } from "@/store/utils";

const state = {
  reports: [],
  userReports: [],
  isFetchingReports: false
};

const actions = {
  async [FETCH_REPORTS]({ commit, dispatch }) {
    return handleWithErrorMessage({
      dispatch,
      request: api({ commit }).getReports,
      setLoadingFunction: isLoading =>
        commit(SET_IS_FETCHING_REPORTS, isLoading),
      setDataFunction: ({ data }) => commit(SET_REPORTS, data)
    });
  },
  async [GET_REPORT]({ commit, dispatch }, { id, params }) {
    return handleWithGlobalMessage(
      () => api({ commit }).getReport(id, params),
      dispatch
    );
  },
  async [FETCH_USER_REPORTS]({ commit, dispatch }) {
    return handleWithErrorMessage({
      dispatch,
      request: api({ commit }).getUserReports,
      setLoadingFunction: isLoading =>
        commit(SET_IS_FETCHING_REPORTS, isLoading),
      setDataFunction: ({ data }) => commit(SET_USER_REPORTS, data)
    });
  },
  async [DELETE_USER_REPORT]({ commit, dispatch }, id) {
    return handleWithGlobalMessage(
      () => api({ commit }).deleteUserReport(id),
      dispatch
    );
  }
};

const mutations = {
  [SET_REPORTS]: setStoreValue("reports"),
  [SET_USER_REPORTS]: setStoreValue("userReports"),
  [SET_IS_FETCHING_REPORTS]: setStoreValue("isFetchingReports")
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
