<template>
  <div class="shift-pattern mt-3">
    <v-row>
      <div class="header">
        <span
          class="shift-color"
          :style="{ 'background-color': color }"
          v-if="!isMobile"
        />
        <h3>Shift Pattern {{ patternNumber }}</h3>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              class="mr-2"
              @click="duplicateShift"
            >
              mdi-content-copy
            </v-icon>
          </template>
          <span>Duplicate Shift Pattern</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" @click="deleteShift">
              mdi-close
            </v-icon>
          </template>
          <span>Remove Shift Pattern</span>
        </v-tooltip>
      </div>
    </v-row>
    <v-row no-gutters class="body">
      <v-col class="col-md-5 col-12 date-picker mb-3">
        <DatePicker
          :input-value="initialDateValues"
          is-dark
          required
          is-range
          label="Start and End Date"
          @updatedDate="updateDate"
          :showValidationErrors="validate"
        />
      </v-col>
      <v-col class="col-md-7 col-12 mb-3">
        <v-label>Every</v-label>
        <DayPicker
          @day-picked="updateDays"
          :initial-value="convertedWeekdays"
        />
      </v-col>
      <v-col class="col-md-3 col-12 working-hours">
        <v-label>Working Hours</v-label>
        <div>
          <TimeRangePicker
            required
            :show-validation-errors="validate"
            :from-hour="shiftPattern.fromHour"
            :to-hour="shiftPattern.toHour"
            @updateRange="updateWorkingHours"
          />
        </div>
      </v-col>
      <v-col class="col-md-3 col-12">
        <v-text-field
          label="Workers Required"
          type="number"
          :value="shiftPattern.workersRequired"
          @change="updateWorkers"
          hide-details
        />
      </v-col>
      <v-col class="col-6">
        <Select
          label="Activity"
          :items="activityOptions"
          :loading="!activityOptions"
          :value="shiftPattern.activity || defaultActivity"
          @change="updateActivity"
          hide-details
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DatePicker from "@/components/common/DatePicker";
import DayPicker from "@/views/bookings/NewBooking/steps/components/DayPicker";
import { RRule } from "rrule";
import TimeRangePicker from "@/components/common/TimePicker/TimeRangePicker";
import { getFrequencyBySelect, repeatOptions } from "@/utils/recurrence-rules";
import Select from "@/components/common/Select";
import { formatDateForVCalendar, formatDate } from "@/utils/time";
import { GET_BOOKING_TEMPLATE } from "@/store/modules/data/getters";
import { DATA_NAMESPACE } from "@/store/modules/data";
import { createNamespacedHelpers } from "vuex";
import { map, set, first, reverse, sortBy, find } from "lodash";
import { convertToWeekdays } from "@/utils/bookings";
import {
  GET_ACTIVITIES,
  GET_CURRENT_PAY_RATES
} from "@/store/modules/bookings/getters";
import { BOOKINGS_NAMESPACE } from "@/store/modules/bookings/actions";
import moment from "moment";
import { DATE_FORMAT_WITH_SEPARATOR_REVERSED } from "@/constants/common";

const { mapGetters } = createNamespacedHelpers(DATA_NAMESPACE);
const { mapGetters: mapBookingGetters } = createNamespacedHelpers(
  BOOKINGS_NAMESPACE
);

export default {
  name: "ShiftPattern",
  components: { TimeRangePicker, DayPicker, DatePicker, Select },
  data: () => ({
    repeatOptionsValues: Object.values(repeatOptions),
    daysToRules: {
      Monday: RRule.MO,
      Tuesday: RRule.TU,
      Wednesday: RRule.WE,
      Thursday: RRule.TH,
      Friday: RRule.FR,
      Saturday: RRule.SA,
      Sunday: RRule.SU
    },
    initialDateValues: null,
    shiftPattern: {
      recurrence: {
        freq: RRule.WEEKLY,
        dtstart: null,
        until: null,
        byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR]
      },
      fromHour: null,
      toHour: null,
      workersRequired: 1,
      activity: "Standard",
      status: "draft"
    }
  }),
  computed: {
    ...mapGetters({
      template: GET_BOOKING_TEMPLATE
    }),
    ...mapBookingGetters({
      currentBookingActivities: GET_ACTIVITIES,
      currentPayRates: GET_CURRENT_PAY_RATES
    }),
    activityOptions() {
      const templatePayRates = this.template && this.template.templatePayRates;
      if (templatePayRates && !this.isEdit) {
        return sortBy(map(templatePayRates, payRate => payRate.payRateName));
      }
      return sortBy(this.currentBookingActivities);
    },
    defaultActivity() {
      const templatePayRates = this.template && this.template.templatePayRates;
      let defaultPayRate = {};
      defaultPayRate =
        templatePayRates && !this.isEdit
          ? find(templatePayRates, payRate => payRate.isDefault)
          : find(this.currentPayRates, payRate => payRate.isDefault);
      return defaultPayRate
        ? defaultPayRate.payRateName ?? defaultPayRate.activityTypeName
        : "";
    },
    today() {
      return moment(new Date()).format(DATE_FORMAT_WITH_SEPARATOR_REVERSED);
    },
    convertedWeekdays() {
      const workDays = convertToWeekdays(
        this.shiftPattern.recurrence.byweekday
      );
      if (workDays.length > 0) {
        return workDays;
      }
      return ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
    },
    selectedFreq() {
      if (this.shiftPattern.recurrence) {
        const reversedFrequency = Object.values(repeatOptions);
        return reverse(reversedFrequency)[this.shiftPattern.recurrence.freq];
      }

      return this.repeatOptionsValues[1];
    }
  },
  methods: {
    formatDateForVCalendar,
    deleteShift() {
      this.$emit("delete", this.id);
    },
    updateWorkingHours({ fromHour, toHour }) {
      this.shiftPattern = {
        ...this.shiftPattern,
        fromHour,
        toHour
      };
      this.$emit("edit", this.shiftPattern, this.id);
    },
    updateWorkers(workersRequired) {
      this.shiftPattern.workersRequired = workersRequired;
      this.$emit("edit", this.shiftPattern, this.id);
    },
    updateDate(date) {
      if (this.initialDateValues) {
        this.initialDateValues = null;
      }
      const startDate = date[0];
      const endDate = date[1] || startDate;

      set(this.shiftPattern, "recurrence.dtstart", new Date(startDate));
      set(this.shiftPattern, "recurrence.until", new Date(endDate));
      if (this.shiftPattern.recurrence.byweekday.length < 1) {
        this.shiftPattern.recurrence.byweekday = [
          RRule.MO,
          RRule.TU,
          RRule.WE,
          RRule.TH,
          RRule.FR
        ];
      }
      this.$emit("edit", this.shiftPattern, this.id);
    },
    updateDays(days) {
      this.shiftPattern.recurrence.byweekday = days
        .map(day => this.daysToRules[day])
        .filter(Boolean);
      this.$emit("edit", this.shiftPattern, this.id);
    },
    updateActivity(activity) {
      this.shiftPattern.activity = activity;
      this.$emit("edit", this.shiftPattern, this.id);
    },
    shoulAddCount() {
      if (this.shiftPattern.recurrence.freq === 3) {
        this.shiftPattern.recurrence.count = this.shiftPattern.recurrence.byweekday.length;
      }
    },
    updateFrequency(frequency) {
      const frequencyRule = getFrequencyBySelect(frequency);
      const { dtstart, until, byweekday } = this.shiftPattern.recurrence;
      this.shiftPattern.recurrence = {
        dtstart,
        until,
        byweekday,
        ...frequencyRule
      };
      this.$emit("edit", this.shiftPattern, this.id);
    },
    duplicateShift() {
      this.$emit("duplicate", this.shiftPattern);
    }
  },
  mounted() {
    if (this.initialShiftPattern) {
      this.shiftPattern = this.initialShiftPattern;
      if (!this.shiftPattern.activity) {
        this.shiftPattern.activity = this.defaultActivity;
      }
      if (
        this.initialShiftPattern.recurrence &&
        this.initialShiftPattern.recurrence.dtstart
      ) {
        this.initialDateValues = [
          formatDate({ date: this.shiftPattern.recurrence.dtstart }),
          formatDate({ date: this.shiftPattern.recurrence.until })
        ];
      }
    }
  },
  watch: {
    activityOptions(options) {
      this.shiftPattern.activity = first(options);
      this.$emit("edit", this.shiftPattern, this.id);
    }
  },
  props: {
    initialShiftPattern: Object,
    id: String,
    patternNumber: Number,
    color: String,
    validate: Boolean,
    isEdit: Boolean
  }
};
</script>

<style lang="scss"></style>
