<template>
  <v-progress-linear
    :color="color"
    :height="height"
    :value="value"
    :style="{ borderRadius: '20px', width }"
  />
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "primary"
    },
    height: {
      type: String,
      default: "10"
    },
    width: {
      type: String,
      default: "100px"
    },
    value: Number
  }
};
</script>
