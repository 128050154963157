<template>
  <div class="organisation-tab-container">
    <div class="organisation-tab mx-2">
      <v-btn-toggle
        class="organisation-section-buttons mb-2"
        v-model="selectedTab"
        tile
        group
        mandatory
        @change="handleSectionChange"
      >
        <v-btn
          v-for="(section, sectionKey, index) in sections"
          :key="sectionKey"
          :value="index"
        >
          {{ section }}
        </v-btn>
      </v-btn-toggle>
      <router-view />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { last } from "lodash";
import { AUTH_NAMESPACE } from "@/store/modules/auth";

const { mapState } = createNamespacedHelpers(AUTH_NAMESPACE);

export default {
  name: "OrganisationTab",
  data() {
    return {
      selectedTab: 3
    };
  },
  methods: {
    handleSectionChange(selectedTab) {
      const sectionKey = Object.keys(this.sections)[selectedTab];
      this.$router.replace(`/settings/organisation/${sectionKey}`);
    }
  },
  mounted() {
    this.selectedTab = this.initialSection;
  },
  computed: {
    ...mapState({
      permissions: state => state.permissions
    }),
    initialSection() {
      const sectionKey = last(this.$router.currentRoute.path.split("/"));
      return Object.keys(this.sections).indexOf(sectionKey);
    },
    sections() {
      return {
        ...(this.permissions["settings.view-org-users"] && {
          users: "Users"
        }),
        ...(this.permissions["settings.edit-organisation-unit"] && {
          units: "Units"
        }),
        ...(this.permissions["settings.edit-locations"] && {
          locations: "Locations"
        }),
        ...(this.permissions["settings.edit-booking-templates"] && {
          templates: "Booking Templates"
        }),
        ...(this.permissions["settings.edit-reasons-for-booking"] && {
          reasons: "Reasons For Booking"
        }),
        ...(this.permissions["settings.edit-publishing-schedule"] && {
          schedules: "Publishing Schedules"
        })
      };
    }
  }
};
</script>

<style lang="scss">
.organisation-tab-container {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}
.organisation-tab {
  max-width: 1100px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  .organisation-section-buttons {
    display: flex;
    overflow: auto;
    align-items: center;
    justify-content: center;
    > .v-btn {
      &:not(.v-btn--round).v-size--default {
        height: 34px !important;
      }

      &.v-item--active::before {
        background-color: $button-select-marked;
        font-weight: bold;
      }

      &:not(.v-item--active) {
        font-weight: normal;
      }
    }
  }
}
</style>
