<template>
  <div>
    <ReasonForBookingDialog
      v-if="dialogSettings.dialog"
      :dialogSettings="dialogSettings"
      @close="closeDialog"
      actionName="Create Reason"
      :key="id"
      @submit="handleCreate"
      :loading="isLoading"
    />
    <CreateButtonMobile @click.native="openDialog" v-if="isMobile" />
    <PrimaryButton @click.native="openDialog" v-else>
      New Reason for Booking
    </PrimaryButton>
  </div>
</template>

<script>
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import ReasonForBookingDialog from "@/views/settings/Organisation/ReasonsForBooking/dialogs/ReasonForBookingDialog";
import { CREATE_ORGANISATION_REASON } from "@/store/modules/organisations/organisation-reasons/actions";
import { createNamespacedHelpers } from "vuex";
import { ORGANISATION_REASONS_NAMESPACE } from "@/store/modules/organisations/organisation-reasons";
import CreateButtonMobile from "@/components/common/CreateButtonMobile";

const { mapActions } = createNamespacedHelpers(ORGANISATION_REASONS_NAMESPACE);

export default {
  name: "NewReasonAction",
  components: { ReasonForBookingDialog, PrimaryButton, CreateButtonMobile },
  data() {
    return {
      reasonDialogOpen: false,
      id: null,
      isLoading: false,
      dialogSettings: {
        dialog: false,
        title: "New Reason for Booking"
      }
    };
  },
  methods: {
    ...mapActions({
      createReason: CREATE_ORGANISATION_REASON
    }),
    openDialog() {
      this.dialogSettings.dialog = true;
    },
    closeDialog() {
      this.dialogSettings.dialog = false;
    },
    async handleCreate(newReason) {
      this.isLoading = true;
      try {
        await this.createReason(newReason);
        this.closeDialog();
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style scoped></style>
