<template>
  <BaseAction>
    <ResetPasswordDialog
      v-if="isDialogOpen"
      @close="isDialogOpen = false"
      @reset="$emit('reset')"
      :user="user"
    />
    <SecondaryButton
      textColor="#0a70f5"
      @click.native.stop="isDialogOpen = true"
      dense
    >
      Reset Password
    </SecondaryButton>
  </BaseAction>
</template>

<script>
import SecondaryButton from "@/components/common/Button/SecondaryButton";
import ResetPasswordDialog from "@/views/settings/Organisation/Users/dialogs/ResetPasswordDialog";
import BaseAction from "@/components/common/BaseAction";

export default {
  name: "ResetPassword",
  components: { ResetPasswordDialog, SecondaryButton, BaseAction },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isDialogOpen: false
    };
  }
};
</script>
