<template>
  <div class="impersonate dialog__form container">
    <h1 class="pt-3">Select user to impersonate</h1>
    <div class="pt-1 mb-3">
      When you impersonate a user you can perform any actions they can
    </div>
    <Select
      label=""
      :items="items"
      item-text="name"
      :loading="isLoading"
      v-model="impersonateUser"
      @change="handleChange"
      autocomplete
    >
      <template v-slot:selection="{ data }">
        <v-chip
          v-bind="data.attr"
          :input-value="data.selected"
          color="blue-grey"
          class="white--text"
          v-on="data.on"
        >
          <v-icon left>mdi-account</v-icon>
          <span v-text="data.item.name"></span>
        </v-chip>
      </template>
      <v-divider class="col-12 mt-4" />
      <template class="impersonate" v-slot:item="{ item }">
        <v-list-item-avatar class="impersonate" color="blue-grey" tile>
          <v-icon>mdi-account</v-icon>
        </v-list-item-avatar>
        <v-list-item-content class="impersonate">
          <v-list-item-title v-text="item.title" />
          <v-list-item-subtitle v-text="item.email" />
        </v-list-item-content>
      </template>
    </Select>
  </div>
</template>

<script>
import Select from "@/components/common/Select";
import { createNamespacedHelpers } from "vuex";
import { AUTH_NAMESPACE } from "@/store/modules/auth";
import { FETCH_USERS_TO_IMPERSONATE } from "@/store/modules/auth/action-types";
import { getFullName, getUserRoles } from "@/utils/users";
import { map, isEmpty } from "lodash";

const { mapState, mapActions } = createNamespacedHelpers(AUTH_NAMESPACE);

export default {
  components: {
    Select
  },
  created() {
    if (this.shouldFetchUsers) {
      this.fetchUsers();
    }
  },
  data() {
    return {
      impersonateUser: null
    };
  },
  computed: {
    ...mapState({
      users: state => state.usersToImpersonate,
      isLoading: state => state.isFetchingUsers
    }),
    items() {
      return map(this.users, user => {
        const userRoles = getUserRoles(user);
        const name = getFullName(user);
        const title = userRoles ? `${name} (${userRoles})` : `${name}`;
        return { title, name, ...user };
      });
    },
    shouldFetchUsers() {
      return isEmpty(this.users);
    }
  },
  methods: {
    ...mapActions({
      fetchUsers: FETCH_USERS_TO_IMPERSONATE
    }),
    handleChange() {
      this.$emit("onChange", this.impersonateUser);
    }
  }
};
</script>

<style lang="scss">
.v-avatar.v-list-item__avatar.impersonate.v-avatar--tile.blue-grey {
  border-radius: 4px;
}

.impersonate {
  .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
    > .v-input__control
    > .v-input__slot,
  .v-text-field.v-text-field--enclosed .v-text-field__details {
    padding: 5px 12px;
  }

  .v-text-field.v-text-field--solo .v-label {
    top: calc(50% - 10px);
  }

  .v-list-item__title {
    text-transform: capitalize;
  }
}
</style>
