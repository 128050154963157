<template>
  <DialogFull :data="dialogSettings" @close="close">
    <template v-slot:dialog.body>
      <div class="edit-basic-details-content">
        <div class="text-center mb-2" v-if="isEditingBookingPayRates">
          <v-progress-circular size="30" indeterminate color="primary" />
        </div>
        <div class="col-md-6 col-sm-12" v-else>
          <v-tabs v-model="selectedTab">
            <v-tab v-for="activityName in activitiesNames" :key="activityName">
              {{ activityName }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="selectedTab">
            <v-tab-item
              v-for="activityName in activitiesNames"
              :key="activityName"
            >
              <v-form ref="form" lazy-validation>
                <div
                  v-for="(awrType, index) in sortBy(
                    Object.keys(allActivities[activityName])
                  ).reverse()"
                  :key="index"
                >
                  <div class="cost-item pa-0">
                    <div class="hourly-rate">
                      <div class="rate-name">
                        {{
                          getPayRateName(
                            allActivities[activityName][awrType].awr
                          )
                        }}
                      </div>
                      <div class="hourly-rate-input">
                        <v-text-field
                          :rules="validationRules"
                          prepend-inner-icon="mdi-currency-gbp"
                          dense
                          type="number"
                          :value="
                            payRateDisplayAmount(
                              allActivities[activityName][awrType]
                            )
                          "
                          @input="
                            val =>
                              editPayRate(
                                val,
                                allActivities[activityName][awrType]
                              )
                          "
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </v-form>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </div>
    </template>
    <template v-slot:dialog.action>
      <v-spacer />
      <PrimaryButton
        :loading="isEditingBookingPayRates"
        class="edit-basic-details-action"
        @click.native="save"
      >
        Save Changes
      </PrimaryButton>
    </template>
  </DialogFull>
</template>

<script>
import DialogFull from "@/components/common/DialogFull";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import { createNamespacedHelpers } from "vuex";
import { BOOKINGS_NAMESPACE } from "@/store/modules/bookings/actions";
import { IS_EDITING_BOOKING_PAYRATES } from "@/store/modules/bookings/getters";
import { isRequired } from "@/utils/validation/rules";
import { every, sortBy } from "lodash";
import {
  getAmountInMonetaryDisplayFormat,
  getAmountInApiFormat
} from "@/utils/helpers";

const { mapGetters } = createNamespacedHelpers(BOOKINGS_NAMESPACE);

export default {
  name: "EditPayRates",
  components: {
    DialogFull,
    PrimaryButton
  },
  data() {
    return {
      editedPayRates: {},
      description: null,
      selectedTab: null
    };
  },
  computed: {
    ...mapGetters({
      isEditingBookingPayRates: IS_EDITING_BOOKING_PAYRATES
    }),
    activitiesNames() {
      return Object.keys(this.allActivities);
    },
    dialogSettings() {
      return {
        title: "Edit Pay Rates",
        dialog: this.isOpen
      };
    },
    validationRules() {
      return [
        ...this.isRequired("Hourly Rate"),
        ...this.cantBeNegativeNumber()
      ];
    }
  },
  props: {
    allActivities: {
      type: Object,
      required: true
    },
    isOpen: Boolean
  },
  methods: {
    editPayRate(payRateAmount, { currencyCode, activityTypeName, awr, id }) {
      const rate = getAmountInApiFormat(payRateAmount).toFixed();
      const editedPayRate = {
        currency: currencyCode,
        activity: activityTypeName,
        rate,
        awr
      };
      this.editedPayRates[id] = editedPayRate;
    },
    payRateDisplayAmount({ payRateAmount }) {
      return getAmountInMonetaryDisplayFormat(payRateAmount);
    },
    getPayRateName(awr) {
      return `${awr ? "Post" : "Pre"}-AWR Pay Rate`;
    },
    async save() {
      const validators = this.$refs.form.map(validator => validator.validate());
      const formsValidationStatuses = await Promise.all(validators);
      const areFormsValid = every(formsValidationStatuses);
      if (areFormsValid) {
        this.$emit("edit", this.editedPayRates);
        this.editedPayRates = {};
      }
    },
    close() {
      this.$emit("close");
      this.editedPayRates = {};
    },
    cantBeNegativeNumber() {
      return [
        value => Boolean(value >= 0) || "The number can't be less than 0"
      ];
    },
    isRequired,
    sortBy
  }
};
</script>

<style lang="scss">
.edit-basic-details-content {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  .hourly-rate {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: space-between;
    .rate-name {
      margin-top: 15px;

      @media only screen and (max-width: $mobile-breakpoint) {
        margin-top: 7px;
      }
    }
    > .hourly-rate-input {
      width: 120px;
    }
  }
}
.edit-basic-details-action {
  &.v-btn {
    height: 50px !important;
    width: 250px;
    span {
      text-transform: none;
      font-weight: bold;
    }
  }
}
</style>
