import { handleWithErrorMessage, errorHandler } from "@/services/utils";
import api from "@/services/modules/organisations/organisations";
import {
  SET_IS_ORGANISATIONS_LOADING,
  SET_ORGANISATIONS,
  SET_ROOT_ORGANISATIONS
} from "@/store/modules/organisations/mutations";
import { sortBy } from "lodash";

export const FETCH_ORGANISATIONS = "FETCH_ORGANISATIONS";
export const CREATE_ORGANISATION = "CREATE_ORGANISATION";
export const UPDATE_ORGANISATION = "UPDATE_ORGANISATION";
export const FETCH_ROOT_ORGANISATIONS = "FETCH_ROOT_ORGANISATIONS";

const organisationsActions = {
  async [FETCH_ORGANISATIONS]({ dispatch, commit }, params) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api({ commit }).getOrganisations(params),
      setLoadingFunction: isLoading =>
        commit(SET_IS_ORGANISATIONS_LOADING, isLoading),
      setDataFunction: ({ data }) =>
        commit(SET_ORGANISATIONS, sortBy(data, ["name"]))
    });
  },
  async [FETCH_ROOT_ORGANISATIONS]({ dispatch, commit }, params) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api({ commit }).getOrganisations(params),
      setLoadingFunction: isLoading =>
        commit(SET_IS_ORGANISATIONS_LOADING, isLoading),
      setDataFunction: ({ data }) =>
        commit(SET_ROOT_ORGANISATIONS, { data, params })
    });
  },
  async [CREATE_ORGANISATION]({ dispatch, commit }, newOrganisation) {
    try {
      const { data } = await api({ commit }).createOrganisation(
        newOrganisation
      );
      return data.id;
    } catch (exception) {
      errorHandler(dispatch, exception);
    }
  },
  async [UPDATE_ORGANISATION]({ dispatch, commit }, organisation) {
    try {
      await api({ commit }).updateOrganisation(organisation);
    } catch (exception) {
      errorHandler(dispatch, exception);
    }
  }
};

export default organisationsActions;
