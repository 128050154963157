<template>
  <app-layout title="Settings">
    <template v-slot:body>
      <Permissioned section="settings">
        <v-tabs
          v-model="selectedTab"
          @change="handleTabChange"
          permission="edit-organisation"
          class="settings-tabs"
        >
          <v-tab v-for="section in sections" :key="section">
            {{ section | capitalize }}
          </v-tab>
        </v-tabs>
      </Permissioned>
      <router-view />
    </template>
  </app-layout>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import appLayout from "@/components/common/base-layouts/AppLayout";
import Permissioned from "@/components/common/Permissioned";
import { MAIN_NAVIGATION_ITEMS } from "@/router/constants";
import { AUTH_NAMESPACE } from "@/store/modules/auth";

const { SETTINGS } = MAIN_NAVIGATION_ITEMS;
const { mapState } = createNamespacedHelpers(AUTH_NAMESPACE);

const TAB = {
  ACCOUNT: 0,
  ORGANISATION: 1
};

export default {
  name: "SettingsTab",
  components: { appLayout, Permissioned },
  data() {
    return {
      selectedTab: this.initialSection,
      sections: ["account", "organisation"]
    };
  },
  created() {
    this.selectedTab = this.initialSection;
  },
  methods: {
    handleTabChange(selectedTab) {
      const tabName = this.sections[selectedTab];
      this.$router.replace(`/${SETTINGS}/${tabName}`);
    }
  },
  computed: {
    ...mapState({
      permissions: state => state.permissions
    }),
    initialSection() {
      const path = this.$router.currentRoute.path;
      return path.includes(this.sections[TAB.ACCOUNT])
        ? TAB.ACCOUNT
        : TAB.ORGANISATION;
    }
  }
};
</script>

<style lang="scss">
.settings-tabs .v-slide-group__content {
  justify-content: center;
}

.v-tab {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
