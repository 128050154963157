import organisationsActions from "@/store/modules/organisations/actions";
import organisationsMutations from "@/store/modules/organisations/mutations";
import organisationsGetters from "@/store/modules/organisations/getters";
import { isMockedDataEnabled } from "@/utils/mocked-data";
import { MOCKED_ORGANISATIONS_STATE } from "@/constants/organisations";
import organisationLocations from "@/store/modules/organisations/organisation-locations";
import organisationReasons from "@/store/modules/organisations/organisation-reasons";
import organisationSchedules from "@/store/modules/organisations/organisation-schedules";
import organisationUnits from "@/store/modules/organisations/organisation-units";
import organisationBookingTemplates from "@/store/modules/organisations/organisation-booking-templates";

const state = {
  organisations: [],
  isOrganisationsLoading: false,
  rootClientOrganisations: [],
  rootAgencyOrganisations: []
};

export default {
  namespaced: true,
  state: isMockedDataEnabled() ? MOCKED_ORGANISATIONS_STATE : state,
  actions: isMockedDataEnabled() ? {} : organisationsActions,
  mutations: isMockedDataEnabled() ? {} : organisationsMutations,
  getters: organisationsGetters,
  modules: {
    organisationSchedules,
    organisationReasons,
    organisationLocations,
    organisationUnits,
    organisationBookingTemplates
  }
};
