/* eslint-disable prettier/prettier */
import { setStoreValue } from '@/store/utils';

export const SET_ORGANISATIONS = "SET_ORGANISATIONS";
export const SET_IS_ORGANISATIONS_LOADING = "SET_IS_ORGANISATIONS_LOADING";
export const SET_ROOT_ORGANISATIONS = "SET_ROOT_ORGANISATIONS";

const organisationsMutations = {
  [SET_ORGANISATIONS]: setStoreValue("organisations"),
  [SET_IS_ORGANISATIONS_LOADING]: setStoreValue("isOrganisationsLoading"),
  [SET_ROOT_ORGANISATIONS](state, { data,  params }) {
    if (params.filter.organisation_type === "client") {
      state.rootClientOrganisations = data;
      return;
    }
    state.rootAgencyOrganisations = data;
  },
};

export default organisationsMutations;
