<template>
  <ValidationObserver ref="allocateWorkersValidation">
    <div class="select-workers-step">
      <div class="container col-md-10 col-sm-12 step-wrapper">
        <div class="d-flex flex-wrap my-2" v-if="shifts.length">
          <ShiftLink
            class="mr-2 min-width-150"
            v-for="shift in shifts"
            :key="shift.id"
            :shift-id="shift.id"
          />
        </div>
        <div v-else class="shift-link">
          <v-icon color="primary">mdi-calendar-range</v-icon>
          <span>All shifts</span>
        </div>
        <h1>Candidates</h1>
        <p class="description">
          Select candidates to allocate to all of the selected shifts
        </p>
        <WorkersSelection
          :isLoading="isLoading"
          :showValidationErrors="showValidationErrors"
          :workers="[...workers, ...selectedWorkers]"
          :unavailableCandidates="
            searchKey ? formattedUnavailableCandidates : []
          "
          @handleSelectedWorkers="setWorkersSelectionStatus"
          @unselectWorker="unselectWorker"
          @search="handleSearchUnavailable"
          :selectedWorkers="selectedWorkers"
          :maxHeight="0"
          appendIcon=""
        />
        <p class="description" v-if="remainingWorkers">
          You can allocate {{ remainingWorkers }} more
          {{ pluralize("candidates", remainingWorkers) }}
        </p>
        <p class="description" v-else>
          You can't allocate any more candidates
        </p>
        <div v-if="isLoading">
          <v-skeleton-loader v-for="i in 5" :key="i" type="list-item-avatar" />
        </div>
        <div class="overflow-auto" v-else>
          <div
            class="worker-wrapper"
            v-for="(worker, index) in unselectedWorkers"
            :key="index"
            @click="
              !preventSelecting &&
                !isClashing(worker.reasons) &&
                selectWorker(worker)
            "
            :class="{ disabled: preventSelecting }"
          >
            <div class="d-flex align-center">
              <v-icon x-large class="icon-wrapper">
                {{ `mdi-account${worker.isUnavailable ? "-off-outline" : ""}` }}
              </v-icon>
              <div>
                <div class="font-weight-bold">
                  {{ worker.fullName }}
                </div>
                <v-tooltip
                  :disabled="!isClashing(worker.reasons)"
                  nudge-right="10"
                  color="white"
                  bottom
                >
                  <template v-slot:activator="{ on }">
                    <p class="secondary-text mb-0" v-on="on">
                      {{ worker.formattedReason }}
                    </p>
                  </template>
                  <div v-for="(reason, index) in worker.reasons" :key="index">
                    <div v-if="reason.shift && reason.booking_name">
                      <strong class="black-text">
                        {{ reason.shift.start_date | dateFormat }} -
                        {{ reason.shift.start_date | hourFormat }}-
                        {{ reason.shift.end_date | hourFormat }}
                      </strong>
                      <p class="secondary-text">{{ reason.booking_name }}</p>
                    </div>
                  </div>
                </v-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import ShiftLink from "@/components/bookings/ShiftLink";
import WorkersSelection from "@/components/bookings/WorkersSelection";
import { filter, forEach, includes, isEmpty, map, some, reduce } from "lodash";
import { ValidationObserver } from "vee-validate";
import pluralize from "pluralize";
import { getFullName, getReasons } from "@/utils/users";
import { formatDate } from "@/utils/time";
import moment from "moment";
import { API_DATE_FORMAT } from "@/constants/common";
import { mapState } from "vuex";
import {
  checkIfSelectedApplicationShiftsArePermitted,
  checkIfSelectedShiftsArePermitted
} from "@/utils/shifts";

const HOUR_MINUTE_FORMAT = "HH:mm";
const DAY_FORMAT = "Do MMMM YYYY";
export default {
  name: "SelectWorkers",
  props: {
    shifts: Array,
    validate: Boolean,
    workers: Array,
    isLoading: Boolean,
    unavailableCandidates: Array,
    searchKey: String,
    isV2: Boolean
  },
  components: {
    WorkersSelection,
    ShiftLink,
    ValidationObserver
  },
  data() {
    return {
      showValidationErrors: false,
      selectedWorkers: [],
      selectedWorkersIds: [],
      hideSelector: Boolean(this.searchKey)
    };
  },
  computed: {
    ...mapState("auth", {
      permissions: state => state.permissions
    }),
    remainingWorkers() {
      return this.maxWorkers - this.selectedWorkersIds.length;
    },
    preventSelecting() {
      return this.remainingWorkers <= 0;
    },
    maxWorkers() {
      return this.shifts.length
        ? Math.max(
            ...this.shifts.map(
              ({ workersRequired, workersConfirmed, workersAllocated }) =>
                workersRequired - (workersAllocated + workersConfirmed)
            )
          )
        : 1;
    },
    allShifts() {
      return isEmpty(this.shifts);
    },
    formattedUnavailableCandidates() {
      return reduce(
        this.unavailableCandidates,
        (candidates, candidate) => {
          candidates = [
            ...candidates,
            {
              ...candidate,
              isUnavailable: true,
              formattedReason: getReasons(candidate),
              fullName: getFullName(candidate)
            }
          ];
          return candidates;
        },
        []
      );
    },
    unselectedWorkers() {
      const workers = [
        ...this.workers,
        ...(this.searchKey ? this.formattedUnavailableCandidates : [])
      ];
      return filter(
        workers,
        ({ id }) => !includes(this.selectedWorkersIds, id)
      );
    },
    canAllocateUnavailableUser() {
      return this.isV2
        ? checkIfSelectedShiftsArePermitted({
            shifts: this.shifts,
            permission: "allocate.unavailable-candidates"
          })
        : checkIfSelectedApplicationShiftsArePermitted(
            {
              shifts: this.shifts,
              permission: "allocate.unavailable-candidates"
            },
            "shifts"
          );
    }
  },
  methods: {
    setWorkersSelectionStatus(selectedWorkersIds) {
      this.selectedWorkers = [];
      this.selectedWorkersIds = selectedWorkersIds;
      forEach(this.formattedUnavailableCandidates, worker => {
        if (includes(selectedWorkersIds, worker.id)) {
          this.selectedWorkers = [...this.selectedWorkers, worker];
        }
      });
      forEach(this.workers, worker => {
        if (includes(selectedWorkersIds, worker.id)) {
          this.selectedWorkers = [...this.selectedWorkers, worker];
        }
      });
      this.$emit("change", this.selectedWorkers);
    },
    unselectWorker(selectedWorker) {
      this.selectedWorkers = filter(
        this.selectedWorkers,
        worker => worker.id !== selectedWorker.id
      );
      this.selectedWorkersIds = map(this.selectedWorkers, ({ id }) => id);
      this.$emit("change", this.selectedWorkers);
    },
    async validateForm() {
      this.showValidationErrors = true;
      const isValid = await this.$refs.allocateWorkersValidation.validate();
      this.$emit(isValid ? "valid" : "invalid");
    },
    selectWorker(selectedWorker) {
      this.selectedWorkers = [...this.selectedWorkers, selectedWorker];
      this.selectedWorkersIds = map(this.selectedWorkers, ({ id }) => id);
      this.$emit("change", this.selectedWorkers);
    },
    isClashing(reasons) {
      return some(reasons, ({ name }) => includes(name, "clashing"));
    },
    handleSearchUnavailable(key) {
      if (this.canAllocateUnavailableUser) {
        this.$emit("search", key);
      }
    },
    pluralize
  },
  watch: {
    validate(shouldValidate) {
      if (shouldValidate) {
        this.validateForm();
      }
    },
    searchKey(value) {
      if (value) {
        this.hideSelector = true;
      }
    }
  },
  filters: {
    dateFormat(date) {
      return formatDate({
        date,
        endFormat: DAY_FORMAT
      });
    },
    hourFormat(date) {
      return moment(date, API_DATE_FORMAT).format(HOUR_MINUTE_FORMAT);
    }
  }
};
</script>

<style lang="scss">
.select-workers-step {
  height: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;

  .step-wrapper {
    padding-bottom: 90px;
  }

  .description {
    color: $secondary-text;
  }

  .worker-wrapper {
    border-bottom: 1px solid $secondary-line;
    border-radius: 0;

    .icon-wrapper {
      background-color: rgba(94, 122, 141, 0.1);
      padding: 6px 8px;
      width: 60px;
      height: 60px;
      border-radius: 4px;
      margin-right: 14px;
    }
  }

  .worker-wrapper.disabled {
    cursor: default;
    color: $secondary-line !important;
    p {
      color: $secondary-line !important;
    }
  }

  .worker-wrapper:not(.disabled) {
    &:hover {
      background-color: rgba(94, 122, 141, 0.1);
    }
  }
  .footer {
    position: fixed;
    width: 100%;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    padding: 0.5rem;

    .v-btn {
      padding: 0.75rem 2.5rem;
      height: auto;
      text-transform: none;
      font-weight: bold;
    }

    @media only screen and (max-width: $mobile-breakpoint - 1) {
      justify-content: center;
    }
  }
}
</style>
