import axios from "axios";

const VERSION_CHECK_TIME_FREQUENCY = 1800000;

export const refreshPageMixin = {
  data() {
    return {
      currentHash: "{{POST_BUILD_ENTERS_HASH_HERE}}",
      token: localStorage.getItem("user-token"),
      isUpdateAvailable: false
    };
  },
  methods: {
    initVersionCheck(url, frequency = VERSION_CHECK_TIME_FREQUENCY) {
      setInterval(() => {
        this.checkVersion(url);
      }, frequency);
    },
    async checkVersion(url) {
      try {
        const fileResponse = await axios.get(
          `${url}?t=${new Date().getTime()}`
        );
        const newHash = fileResponse.data.hash;
        this.isUpdateAvailable = this.hasHashChanged(newHash);
      } catch (error) {
        this.loading = false;
        if (!error.response) {
          this.errorStatus = "Error: Network Error";
        } else {
          this.errorStatus = error.response.data.message;
        }
      }
    },
    hasHashChanged(newHash) {
      if (
        !this.currentHash ||
        this.currentHash === "{{POST_BUILD_ENTERS_HASH_HERE}}"
      ) {
        return true;
      }

      return this.currentHash !== newHash;
    },
    reloadApp() {
      this.currentHash = this.newHash;
      window.location.reload();
    }
  }
};
