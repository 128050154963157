<template>
  <ValidationObserver ref="submitWorkersValidation">
    <DialogFull
      :steps="{ current: step, total: totalSteps }"
      :data="dialogSettings"
      progress
      @onStepBack="stepBack"
      @close="close"
    >
      <template v-slot:dialog.body>
        <SubmitWorkersForBooking
          v-if="shouldStepViewBeShown(1)"
          :showValidationErrors="showValidationErrors"
          @onChange="setSelectedWorkers"
        />
        <SubmitWorkersForShifts
          v-else
          @onChange="setSelectedShifts"
          @onSubmitForAllShifts="onSubmitForAllShifts"
          :showValidationErrors="showValidationErrors"
        />
      </template>
      <template v-slot:dialog.action>
        <PrimaryButton
          @click.native="stepForward"
          v-if="shouldStepViewBeShown(1) && !isFixedTerm"
          :loading="isLoading"
        >
          Continue
        </PrimaryButton>
        <PrimaryButton @click.native="onSubmit" :loading="isLoading" v-else>
          Submit Workers
        </PrimaryButton>
      </template>
    </DialogFull>
  </ValidationObserver>
</template>

<script>
import DialogFull from "@/components/common/DialogFull";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import SubmitWorkersForBooking from "@/views/bookings/components/SubmitWorkers/components/SubmitWorkersForBooking";
import SubmitWorkersForShifts from "@/views/bookings/components/SubmitWorkers/components/SubmitWorkersForShifts";
import { ValidationObserver } from "vee-validate";
import {
  SUBMIT_FOR_SHIFTS,
  SUBMIT_FOR_ALL_SHIFTS
} from "@/store/modules/applications/actions";
import { SUBMIT_WORKERS } from "@/store/modules/applications/actions";
import { createNamespacedHelpers } from "vuex";
import { APPLICATION_NAMESPACE } from "@/store/modules/applications";
import { map, size } from "lodash";
import { POST_GLOBAL_MESSAGE } from "@/store/modules/global/action-types";
import { mapActions } from "vuex";
import pluralize from "pluralize";

const { mapActions: mapApplicationActions } = createNamespacedHelpers(
  APPLICATION_NAMESPACE
);

const MAX_STEP = 2;

export default {
  name: "NewBooking",
  components: {
    PrimaryButton,
    DialogFull,
    SubmitWorkersForBooking,
    SubmitWorkersForShifts,
    ValidationObserver
  },
  inject: ["currentBookingId"],
  data() {
    return {
      step: 1,
      maxSteps: MAX_STEP,
      bookingData: {},
      isLoading: false,
      showValidationErrors: false,
      selectedWorkersIds: [],
      selectedApplicationsIds: [],
      selectedShifts: [],
      canSubmitForAllShifts: false
    };
  },
  computed: {
    dialogSettings() {
      return {
        dialog: this.isOpen,
        title: "Submit Worker for Booking"
      };
    },
    totalSteps() {
      return this.isFixedTerm ? this.maxSteps - 1 : this.maxSteps;
    }
  },
  methods: {
    ...mapActions({
      postGlobalMessage: POST_GLOBAL_MESSAGE
    }),
    ...mapApplicationActions({
      submitWorkers: SUBMIT_WORKERS,
      submitForShifts: SUBMIT_FOR_SHIFTS,
      submitForAllShifts: SUBMIT_FOR_ALL_SHIFTS
    }),
    stepBack() {
      if (this.step > 1) {
        this.step -= 1;
      }
    },
    async stepForward() {
      this.showValidationErrors = true;
      const isFormValid = await this.$refs.submitWorkersValidation.validate();
      if (isFormValid) {
        this.step += 1;
        this.showValidationErrors = false;
      }
    },
    async onSubmitWorkers() {
      const submitRequests = this.selectedWorkersIds.map(workerId =>
        this.submitWorkers({
          user: { id: workerId },
          booking: { id: this.currentBookingId }
        })
      );
      const applicationsResponses = await Promise.all(submitRequests);
      this.selectedApplicationsIds = map(
        applicationsResponses,
        ({ data }) => data.id
      );
    },
    async onSubmit() {
      this.showValidationErrors = true;
      if (this.canSubmitForAllShifts || this.isFixedTerm) {
        this.submitWorkersForAllShifts();
      } else if (this.selectedShifts.length) {
        this.submitWorkersForSelectedShifts();
      }
    },
    async submitWorkersForAllShifts() {
      this.isLoading = true;
      try {
        await this.submitForAllShifts({
          workers: this.selectedWorkersIds,
          bookingId: this.currentBookingId
        });
        this.$emit("close");
        this.postSuccessMessage();
      } finally {
        this.isLoading = false;
        this.showValidationErrors = false;
      }
    },
    async submitWorkersForSelectedShifts() {
      this.isLoading = true;
      try {
        if (!size(this.selectedApplicationsIds)) {
          await this.onSubmitWorkers();
        }
        const submitRequests = this.selectedApplicationsIds.map(workerId =>
          this.submitForShifts({
            worker: workerId,
            shifts: this.selectedShifts
          })
        );
        await Promise.all(submitRequests);
        this.$emit("close");
        this.postSuccessMessage();
      } finally {
        this.isLoading = false;
        this.showValidationErrors = false;
      }
    },
    postSuccessMessage() {
      const message = {
        snackbar: true,
        type: "success",
        title: `${pluralize(
          "Application",
          size(this.selectedApplicationsIds)
        )} created`
      };
      this.postGlobalMessage(message);
    },
    setSelectedWorkers(selectedWorkersIds) {
      this.selectedWorkersIds = selectedWorkersIds;
    },
    setSelectedShifts(selectedShifts) {
      this.selectedShifts = selectedShifts;
    },
    onSubmitForAllShifts(val) {
      this.canSubmitForAllShifts = val;
    },
    clearForm() {
      this.step = 1;
    },
    close() {
      this.clearForm();
      this.$emit("close");
    },
    shouldStepViewBeShown(viewStep) {
      return this.step === viewStep;
    }
  },
  props: {
    isOpen: Boolean,
    isFixedTerm: Boolean
  }
};
</script>

<style lang="scss">
.v-card__actions {
  z-index: 9999;
}
</style>
