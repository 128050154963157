import { setStoreValue } from "@/store/utils";
import { filter, find } from "lodash";

export const SET_IS_FETCHING_CURRENT_SCHEDULE =
  "SET_IS_FETCHING_CURRENT_SCHEDULE";
export const SET_CURRENT_PUBLISHING_SCHEDULE =
  "SET_CURRENT_PUBLISHING_SCHEDULE";
export const SET_IS_FETCHING_SCHEDULE_TEMPLATES =
  "SET_IS_FETCHING_SCHEDULE_TEMPLATES";
export const SET_PUBLISHING_SCHEDULE_TEMPLATES =
  "SET_PUBLISHING_SCHEDULE_TEMPLATES";
export const ADD_NEW_PUBLISHING_SCHEDULES = "ADD_NEW_PUBLISHING_SCHEDULES";
export const REMOVE_AGENCY_FROM_TIER = "REMOVE_AGENCY_FROM_TIER";

const mutations = {
  [SET_IS_FETCHING_CURRENT_SCHEDULE]: setStoreValue(
    "isFetchingCurrentSchedule"
  ),
  [SET_CURRENT_PUBLISHING_SCHEDULE]: setStoreValue("currentPublishingSchedule"),
  [SET_IS_FETCHING_SCHEDULE_TEMPLATES]: setStoreValue(
    "isFetchingScheduleTemplates"
  ),
  [SET_PUBLISHING_SCHEDULE_TEMPLATES]: setStoreValue(
    "publishingScheduleTemplates"
  ),
  [ADD_NEW_PUBLISHING_SCHEDULES](state, data) {
    state.publishingScheduleTemplates.push(data);
  },
  [REMOVE_AGENCY_FROM_TIER](state, { tierId, agency }) {
    const tier = find(
      state.currentPublishingSchedule.tiers,
      tier => tier.id === tierId
    );
    tier.organisations = filter(
      tier.organisations,
      organisation => organisation.id !== agency.id
    );
  }
};

const state = {
  currentPublishingSchedule: null,
  isFetchingCurrentSchedule: false,
  isFetchingScheduleTemplates: false,
  publishingScheduleTemplates: []
};

export { mutations, state };
