<template>
  <div class="list-item with-icon">
    <v-list-item-avatar tile>
      <v-img height="30px" contain src="@/assets/svg/application-icon.svg" />
    </v-list-item-avatar>
    <v-list-item-content>
      <div class="item-right">
        <div class="description">
          <div class="d-flex" :class="{ 'flex-column': isMobile }">
            <span class="font-weight-bold">{{ candidate | fullName }}</span>
            <span class="mx-4">B-{{ bookingId }}</span>
          </div>
          <div class="status-wrapper">
            <div class="application-status-item">
              <span class="mx-1"> {{ orgUnitName }} </span>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column align-center">
          <Chip
            :color="getColorByStatus(application.status)"
            class="chip--status"
            shrink
          >
            {{ application.status | upperCase }}
          </Chip>
        </div>
      </div>
    </v-list-item-content>
  </div>
</template>

<script>
import { getColorByStatus } from "@/utils/bookings";
import Chip from "@/components/common/Chip";
import { get } from "lodash";

export default {
  props: {
    application: {
      type: Object,
      required: true
    }
  },
  components: {
    Chip
  },
  computed: {
    bookingId() {
      const booking = get(this.application, "booking");
      return booking ? booking.id : "";
    },
    orgUnitName() {
      const organisation = get(this.application, "organisation");
      return organisation && organisation.name;
    },
    candidate() {
      return get(this.application, "user");
    }
  },
  methods: {
    getColorByStatus
  }
};
</script>
