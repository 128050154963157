export const ASSESSMENT_MODEL = "assessment";

const assessmentModel = {
  rating: 0,
  notes: "",
  createdAt: "",
  application: {
    jsonApi: "hasOne",
    type: "applications"
  },
  createdBy: {
    jsonApi: "hasOne",
    type: "user"
  }
};

export default assessmentModel;
