<template>
  <BaseDialog :isOpen="isOpen">
    <template v-slot:title>
      <span>Are you sure you want to recall this timesheet?</span>
    </template>
    <template v-slot:body>
      <div class="mb-6">
        This will allow you to edit this timesheet, but until you resubmit it,
        the timesheet cannot be approved.
      </div>
    </template>
    <template v-slot:actions>
      <div>
        <PrimaryButton @click.native="cancel" text>
          Cancel
        </PrimaryButton>
        <PrimaryButton @click.native="handleClick" :loading="isLoading">
          Recall Timesheet
        </PrimaryButton>
      </div>
    </template>
  </BaseDialog>
</template>

<script>
import BaseDialog from "@/components/common/BaseDialog";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import { RECALL_TIMESHEET } from "@/store/modules/timesheets/actions";
import { createNamespacedHelpers } from "vuex";
import { TIMESHEETS_NAMESPACE } from "@/store/modules/timesheets";

const { mapActions } = createNamespacedHelpers(TIMESHEETS_NAMESPACE);

export default {
  components: { PrimaryButton, BaseDialog },
  data() {
    return {
      isLoading: false
    };
  },
  props: {
    isOpen: Boolean,
    id: {
      type: String,
      required: true
    }
  },
  methods: {
    ...mapActions({
      recallTimesheet: RECALL_TIMESHEET
    }),
    async handleClick() {
      this.isLoading = true;
      try {
        await this.recallTimesheet(this.id);
        this.redirectToSubmit();
      } catch {
        this.$emit("close");
      } finally {
        this.isLoading = false;
      }
    },
    cancel() {
      this.$emit("close");
    },
    redirectToSubmit() {
      this.$router.push({
        name: "submitTimesheet",
        params: { id: this.id }
      });
    }
  }
};
</script>
