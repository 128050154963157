<template>
  <v-overlay v-if="isLoading" z-index="9">
    <v-progress-circular indeterminate size="64" />
  </v-overlay>
  <v-app v-else id="inspire" class="base-app" :class="{ safari: isSafari }">
    <GlobalMessage />
    <LoginDialog />
    <ProfileSelector />
    <SystemErrorPage />
    <AppUpdateBar />
    <Impersonate />
    <navigation-drawer>
      <template v-slot:user-avatar>
        <v-img
          class="nav__user__avatar"
          src="https://avatars0.githubusercontent.com/u/9064066?v=4&s=460"
        />
      </template>
      <template v-slot:company-name>Talentpool</template>
    </navigation-drawer>
    <div class="base-app-content" id="app-content">
      <router-view />
    </div>
  </v-app>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import NavigationDrawer from "@/components/navigation/NavigationDrawer";
import GlobalMessage from "@/components/common/GlobalMessage";
import AppUpdateBar from "@/components/common/AppUpdateBar";
import LoginDialog from "@/components/common/LoginDialog";
import ProfileSelector from "@/components/common/ProfileSelector";
import { FETCH_SELF_DETAILS } from "@/store/modules/auth/action-types";
import { GET_AUTH_DIALOG_VISIBILITY_STATUS } from "@/store/modules/global/getters";
import { AUTH_NAMESPACE } from "@/store/modules/auth";
import { mapGetters } from "vuex";
import SystemErrorPage from "@/components/common/SystemErrorPage";
import Impersonate from "@/components/impersonate/Impersonate";

const { mapState, mapActions } = createNamespacedHelpers(AUTH_NAMESPACE);

export default {
  computed: {
    ...mapState({
      isLoadingSelfDetails: state => state.isLoadingSelfDetails
    }),
    ...mapGetters({
      showDialog: GET_AUTH_DIALOG_VISIBILITY_STATUS
    }),
    isLoading() {
      return !this.showDialog && this.isLoadingSelfDetails;
    }
  },
  methods: {
    ...mapActions({
      fetchSelf: FETCH_SELF_DETAILS
    })
  },
  mounted() {
    if (!this.isLoadingSelfDetails) {
      this.fetchSelf();
    }
  },
  components: {
    LoginDialog,
    GlobalMessage,
    NavigationDrawer,
    ProfileSelector,
    SystemErrorPage,
    AppUpdateBar,
    Impersonate
  }
};
</script>

<style lang="scss">
@import "@/sass/main.scss";

.base-app {
  height: 100vh;

  &.safari {
    margin-top: 20px;
  }
}
</style>
