<template>
  <v-btn v-if="isMobile" class="mobile-create-button" fab large color="primary">
    <v-icon large>mdi-plus</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "CreateButtonMobile"
};
</script>

<style scoped>
.mobile-create-button {
  z-index: 1;
}
</style>
