<template>
  <div>
    <v-text-field
      :label="label"
      v-if="isCurrency"
      :placeholder="placeHolder"
      prepend-inner-icon="mdi-currency-gbp"
      type="number"
      @input="handleChangeValue"
      :value="inputValue"
      hide-details
      :disabled="disabled"
    />
    <v-text-field
      v-else
      :label="label"
      :placeholder="placeHolder"
      :type="type"
      @input="handleChangeValue"
      :value="inputValue"
      :rules="rules"
      :hide-details="!withErrors"
      :disabled="disabled"
    >
      <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
      <template
        v-for="(_, name) in $scopedSlots"
        :slot="name"
        slot-scope="slotData"
        ><slot :name="name" v-bind="slotData"
      /></template>
    </v-text-field>
  </div>
</template>

<script>
export default {
  props: {
    /**
     *  Boolean if currency
     */
    isCurrency: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     *  Text for placeholder
     */
    placeHolder: {
      type: String,
      required: false,
      default: null
    },
    /**
     *  Text for Label
     */
    label: {
      type: String,
      required: false,
      default: null
    },
    /**
     *  Value of the Text field
     */
    value: {
      type: String,
      required: false,
      default: null
    },
    type: String,
    rules: Array,
    withErrors: {
      type: Boolean,
      default: false
    },
    disabled: Boolean
  },
  computed: {
    inputValue() {
      return this.value;
    }
  },
  methods: {
    handleChangeValue(newValue) {
      this.$emit("change", newValue);
    }
  }
};
</script>

<style lang="scss">
.base-text-field {
  .v-input__icon {
    margin-top: 15%;
  }

  .v-input__slot {
    height: 50px;
  }
}

.v-messages {
  min-height: auto;
}

.v-input__slot {
  min-height: auto;
}

.v-text-field__details {
  min-height: auto;
}

.v-text-field {
  padding-top: 0px !important;
  margin-top: 34px;
}
</style>
