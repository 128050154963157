<template>
  <BaseAction>
    <SecondaryButton @click.native="openDialog" textColor="#d10a3a">
      <inline-svg
        :src="require('@/assets/svg/thumb-down-red.svg')"
        width="25"
      />
      Reject Booking
    </SecondaryButton>
    <RejectBookingDialog
      :isOpen="isOpen"
      @close="closeDialog"
      @reject="handleReject"
    />
  </BaseAction>
</template>

<script>
import RejectBookingDialog from "@/views/bookings/components/ConfirmDialogs/RejectBookingDialog";
import BaseAction from "@/components/common/BaseAction";
import SecondaryButton from "@/components/common/Button/SecondaryButton";
import InlineSvg from "vue-inline-svg";

export default {
  name: "RejectBookingAction",
  components: {
    BaseAction,
    RejectBookingDialog,
    SecondaryButton,
    InlineSvg
  },
  data() {
    return {
      isOpen: false
    };
  },
  methods: {
    openDialog() {
      this.isOpen = true;
    },
    closeDialog() {
      this.isOpen = false;
    },
    handleReject() {
      this.$emit("reject");
    }
  }
};
</script>
