import api from "@/services/modules/addresses";
import {
  handleWithErrorMessage,
  handleWithGlobalMessage
} from "@/services/utils";
import {
  SET_IS_FETCHING_LOCATIONS,
  SET_ORGANISATION_LOCATIONS
} from "@/store/modules/organisations/organisation-locations/mutations";
import { filter } from "lodash";

export const FETCH_ORGANISATION_LOCATIONS = "FETCH_ORGANISATION_LOCATIONS";
export const CREATE_ORGANISATION_LOCATION = "CREATE_ORGANISATION_LOCATION";
export const UPDATE_ORGANISATION_LOCATION = "UPDATE_ORGANISATION_LOCATION";
export const DELETE_ORGANISATION_LOCATION = "DELETE_ORGANISATION_LOCATION";

const actions = {
  async [FETCH_ORGANISATION_LOCATIONS]({ commit, dispatch }) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api({ commit }).getAllAddresses(),
      setLoadingFunction: isLoading =>
        commit(SET_IS_FETCHING_LOCATIONS, isLoading),
      setDataFunction: ({ data }) => commit(SET_ORGANISATION_LOCATIONS, data)
    });
  },
  async [CREATE_ORGANISATION_LOCATION]({ commit, dispatch, state }, location) {
    const { data: newLocation } = await handleWithGlobalMessage(
      () => api({ commit }).addNewAddress(location),
      dispatch
    );
    const currentLocations = state.organisationLocations;
    commit(SET_ORGANISATION_LOCATIONS, [...currentLocations, newLocation]);
    dispatch(FETCH_ORGANISATION_LOCATIONS);
  },
  async [UPDATE_ORGANISATION_LOCATION]({ commit, dispatch, state }, location) {
    const { data: newLocation } = await handleWithGlobalMessage(
      () => api({ commit }).updateAddress(location),
      dispatch
    );
    const currentLocations = state.organisationLocations;
    commit(
      SET_ORGANISATION_LOCATIONS,
      currentLocations.map(reason => {
        if (reason.id !== newLocation.id) return reason;
        return newLocation;
      })
    );
    dispatch(FETCH_ORGANISATION_LOCATIONS);
  },
  async [DELETE_ORGANISATION_LOCATION]({ commit, dispatch, state }, id) {
    await handleWithGlobalMessage(
      () => api({ commit }).deleteAddress(id),
      dispatch
    );
    const currentLocations = state.organisationLocations;
    commit(
      SET_ORGANISATION_LOCATIONS,
      filter(currentLocations, location => location.id !== id)
    );
    dispatch(FETCH_ORGANISATION_LOCATIONS);
  }
};

export default actions;
