import { getDurationBetween } from "@/utils/time";
import moment from "moment";
import { getDateRange } from "@/utils/time";
import { every, size, reduce, first, filter, map } from "lodash";

export const TIMESHEET_STATUSES = {
  DRAFT: "draft",
  SUBMITTED: "submitted",
  APPROVED: "approved",
  DISPUTED: "disputed",
  CREDITED: "credited",
  VERIFIED: "verified",
  UNVERIFIED: "unverified"
};

export const getColorByStatus = status => {
  switch (status.toLowerCase()) {
    case TIMESHEET_STATUSES.APPROVED:
      return "green";
    case TIMESHEET_STATUSES.SUBMITTED:
      return "yellow";
    case TIMESHEET_STATUSES.DISPUTED:
    case TIMESHEET_STATUSES.UNVERIFIED:
      return "red";
    case TIMESHEET_STATUSES.VERIFIED:
      return "blue";
    case TIMESHEET_STATUSES.DRAFT:
    case TIMESHEET_STATUSES.CREDITED:
    default:
      return "gray";
  }
};

export const getTotalDurationBetweenTimes = times => {
  return times.reduce(
    (totalTime, { startTime, endTime, absent, breakMinutes }) => {
      if (!startTime || !endTime || absent) {
        return totalTime;
      }
      return getDurationBetween(startTime, endTime).add(
        totalTime.subtract(Number(breakMinutes), "minutes")
      );
    },
    moment.duration(0)
  );
};

export const getGroupedTimesheets = timesheets =>
  timesheets.reduce((groupedByDate, timesheet) => {
    const key = getDateRange({
      startDate: timesheet.weekCommencing,
      endDate: timesheet.weekEnding,
      format: "Do MMM"
    });

    if (groupedByDate[key]) {
      groupedByDate[key].push(timesheet);
      return groupedByDate;
    }
    return { ...groupedByDate, [key]: [timesheet] };
  }, {});

export const checkIfSelectedTimesheetsArePermitted = ({
  timesheets,
  permission
}) => {
  if (!size(timesheets)) {
    return false;
  }
  return every(timesheets, ({ meta: { permissions } }) => {
    return permissions[`timesheets.${permission}`];
  });
};

export const getTimesheetDates = (startTime, endTime) => {
  let dates = [];
  const start = moment(startTime, "YYYY-MM-DD");
  const end = moment(endTime, "YYYY-MM-DD");
  const duration = end.diff(start, "days");
  // every(duration, day => { TODO: fix when we remove blockers
  const date = moment(start).add(duration, "days");
  dates.push(date.format("YYYY-MM-DD"));
  // });
  return dates;
};

export const groupTimesheetsByDate = timesheets => {
  return reduce(
    timesheets,
    (groupedTimesheets, timesheet) => {
      const key = getDateRange({
        startDate: timesheet.weekCommencing,
        endDate: timesheet.weekEnding,
        format: "Do MMM"
      });
      if (groupedTimesheets[key]) {
        groupedTimesheets[key].push(timesheet);
        return groupedTimesheets;
      }
      return { ...groupedTimesheets, [key]: [timesheet] };
    },
    {}
  );
};

export const groupTimesheetsByBooking = timesheetsToGroup => {
  return reduce(
    timesheetsToGroup,
    (dateGroups, timesheets, date) => {
      const groupedTimesheetByBooking = reduce(
        timesheets,
        (groupedByBooking, timesheet) => {
          const { booking } = timesheet;
          const key = booking
            ? `<h3>${booking.title}</h3><p class="ml-2 secondary-text">B-${booking.id}</p>`
            : "No bookings";
          if (groupedByBooking[key]) {
            groupedByBooking[key].push(timesheet);
            return groupedByBooking;
          }
          return { ...groupedByBooking, [key]: [timesheet] };
        },
        {}
      );
      dateGroups[date] = groupedTimesheetByBooking;
      return dateGroups;
    },
    {}
  );
};

// TODO: remove when the alternate timesheets is final
export const groupTimesheetsByOrg = timesheetsToGroup => {
  const groupedByDates = groupTimesheetsByDate(timesheetsToGroup);
  return reduce(
    groupedByDates,
    (groupedByOrg, timesheets, index) => {
      const groupedTimesheets = reduce(
        timesheets,
        (bookingGroups, timesheet) => {
          const location = timesheet.booking && timesheet.booking.location;
          const clientName = timesheet.rootClient && timesheet.rootClient.name;
          const key = `${clientName ? clientName : ""} - ${location.id}`;
          if (bookingGroups[key]) {
            bookingGroups[key].push(timesheet);
            return bookingGroups;
          }
          return { ...bookingGroups, [key]: [timesheet] };
        },
        {}
      );
      groupedByOrg[index] = groupedTimesheets;
      return groupedByOrg;
    },
    {}
  );
};
// TODO: remove when the alternate timesheets is final
export const groupTimesheetsByAgency = timesheets => {
  return reduce(
    timesheets,
    (groupedByAgency, timesheet) => {
      const key =
        timesheet.worker &&
        timesheet.worker.organisation &&
        `${timesheet.worker.organisation.agencyName}`;
      if (groupedByAgency[key]) {
        groupedByAgency[key].push(timesheet);
        return groupedByAgency;
      }
      return { ...groupedByAgency, [key]: [timesheet] };
    },
    {}
  );
};

export const getWorkerTimesheet = (timesheets, id) => {
  return first(filter(timesheets, ({ worker }) => worker.id === id));
};

export const groupEntriesByAgency = entries => {
  return reduce(
    entries,
    (groupedByAgency, entry) => {
      const key = entry.agency.name;
      if (groupedByAgency[key]) {
        groupedByAgency[key].entries.push(entry);
        return groupedByAgency;
      }
      const newGroup = { agencyId: entry.agency.id, entries: [entry] };
      return { ...groupedByAgency, [key]: newGroup };
    },
    {}
  );
};

export const getBeforeAndAfterWeekDates = (firstWeekday, shiftStartDate) => {
  const weekdays = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday"
  ];
  firstWeekday = firstWeekday ? firstWeekday.toLowerCase() : "monday";
  shiftStartDate = moment(shiftStartDate).format("YYYY-MM-DD");

  // Split the date part by '-' to extract year, month, and day
  const [year, month, day] = shiftStartDate.split("-");

  const startDate = new Date(Date.UTC(year, month - 1, day));

  const firstWeekdayIndex = weekdays.indexOf(firstWeekday);
  const daysToSubtract = (startDate.getUTCDay() + 6 - firstWeekdayIndex) % 7;

  const startOfWeek = new Date(
    startDate.getTime() - daysToSubtract * 24 * 60 * 60 * 1000
  );
  const endOfWeek = new Date(startOfWeek.getTime() + 6 * 24 * 60 * 60 * 1000);
  const after = new Date(startOfWeek.getTime() - 1 * 24 * 60 * 60 * 1000);
  const before = new Date(endOfWeek.getTime() + 1 * 24 * 60 * 60 * 1000);

  const formatDateString = date => {
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const day = String(date.getUTCDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  return {
    afterDate: formatDateString(after),
    beforeDate: formatDateString(before)
  };
};

export const getTimesheetTotalMinutes = timesheets => {
  return reduce(
    timesheets,
    (totalHours, timesheet) => {
      map(timesheet.entries, ({ totalTime }) => (totalHours += totalTime));
      return totalHours;
    },
    0
  );
};
