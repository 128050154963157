<template>
  <DialogFull
    progress
    :data="dialogSettings"
    :steps="steps"
    @onStepBack="stepBack"
    @close="close"
  >
    <template v-slot:dialog.body>
      <div class="px-16 mx-10">
        <ValidationObserver ref="bulkTimesheets">
          <div v-show="steps.current === 1">
            <h1 class="mb-3 mt-2">Select Shift to Upload For</h1>
            <ValidationProvider
              v-slot="{ errors }"
              name="bookingId"
              rules="required"
            >
              <Select
                v-model="selectedBooking"
                item-text="label"
                label="Booking"
                class="mb-3"
                placeholder="Select booking"
                :items="bookingItems"
                :loading="isLoadingBookings"
                :errorMessages="showValidationErrors ? errors : ''"
                @input="selectBooking"
                autocomplete
              />
            </ValidationProvider>
            <div v-if="selectedBooking">
              <label for="shifts" class="mt-4">Shifts</label>
              <ListTypeSelection
                v-if="!isLoadingShifts"
                :text="listTypeSelectionText"
                @selectListType="changeListType"
              />
              <div v-if="isLoadingShifts">
                <v-skeleton-loader v-for="i in 10" :key="i" type="list-item" />
              </div>
              <RadioButton
                v-else
                @select="selectShift"
                :selected-value="selectedShifts"
                :options="shiftItems"
              />
            </div>
          </div>
          <div v-show="steps.current === 2">
            <h1>Timesheet Entries</h1>
            <h3 class="pt-4">{{ timesheetDetails }}</h3>
            <p class="my-4 secondary-text" v-if="!workers.length">
              No confirmed workers on selected shift
            </p>
            <BulkTimesheetEntry
              :timesheets="timesheets"
              :generatingTimesheets="generatingTimesheets"
              :bookingPayRates="bookingPayRates"
              :firstWeekday="systemParameters.first_weekday"
              @change="handleEntriesChange"
              @addWorkers="addWorkers"
            />
          </div>
        </ValidationObserver>
      </div>
    </template>
    <template v-slot:dialog.action>
      <p class="secondary-text mb-0 mr-2" v-if="formattingShifts">
        Preparing selected shifts...
      </p>
      <PrimaryButton
        v-if="steps.current === steps.total"
        :loading="isSaving"
        :disabled="!timesheets.length"
        @click.native="handleSaveBulkTimesheets"
      >
        Save
      </PrimaryButton>
      <PrimaryButton
        :disabled="!selectedBooking || !selectedShifts"
        :loading="generatingTimesheets || formattingShifts"
        @click.native="nextStep"
        v-else
      >
        Continue
      </PrimaryButton>
    </template>
  </DialogFull>
</template>

<script>
import DialogFull from "@/components/common/DialogFull";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Select from "@/components/common/Select";
import {
  BOOKINGS_NAMESPACE,
  FETCH_BOOKINGS,
  FETCH_BOOKING_SHIFTS,
  FETCH_BOOKING_PAYRATES
} from "@/store/modules/bookings/actions";
import {
  CREATE_TIMESHEET,
  EDIT_ENTRY,
  REMOVE_ENTRY,
  ADD_COMMENT,
  FETCH_SHIFT_TIMESHEETS,
  SUBMIT_TIMESHEET,
  ADD_ENTRY_FROM_NEW_TIMESHEET
} from "@/store/modules/timesheets/actions";
import { TIMESHEETS_NAMESPACE } from "@/store/modules/timesheets";
import { createNamespacedHelpers } from "vuex";
import {
  GET_BOOKINGS,
  IS_LOADING_BOOKINGS,
  GET_BOOKING_SHIFTS,
  IS_LOADING_BOOKING_SHIFTS
} from "@/store/modules/bookings/getters";
import { isEmpty, map, filter, reduce, first, find, size } from "lodash";
import { getFormattedLocation } from "@/utils/locations";
import RadioButton from "@/components/common/RadioButton";
import ListTypeSelection from "@/components/common/ListTypeSelection";
import BulkTimesheetEntry from "@/views/timesheets/components/BulkTimesheetEntry";
import { groupShiftByTime, getConfirmedWorkers } from "@/utils/shifts";
import { TIMESHEET_MODEL } from "@/models/timesheet-model";
import { formatDate } from "@/utils/time";
import { API_TIME_FORMAT } from "@/constants/common";
import { getWorkerTimesheet } from "@/utils/timesheets";
import { DATA_NAMESPACE } from "@/store/modules/data";
import { FETCH_SYSTEM_PARAMETERS } from "@/store/modules/data/actions";
import { getShiftDateRange } from "@/utils/shifts";
import moment from "moment";
import { RRule } from "rrule";
import { shiftDayFormat, shiftHourRange } from "@/utils/shifts";

const { mapActions, mapGetters } = createNamespacedHelpers(BOOKINGS_NAMESPACE);
const { mapActions: timesheetsMapAction } = createNamespacedHelpers(
  TIMESHEETS_NAMESPACE
);
const { mapActions: mapDataActions, mapState } = createNamespacedHelpers(
  DATA_NAMESPACE
);

const DEFAULT_AWR = 0;
const SHIFT_DAY_FORMAT = "dddd Do MMMM YYYY";

export default {
  components: {
    DialogFull,
    PrimaryButton,
    ValidationProvider,
    ValidationObserver,
    Select,
    RadioButton,
    ListTypeSelection,
    BulkTimesheetEntry
  },
  props: {
    isOpen: Boolean,
    booking: Object
  },
  async created() {
    await this.fetchBookings({ filter: { status: ["live", "closed"] } });
    this.selectBooking();
    this.fetchSystemParameters();
  },
  data() {
    return {
      steps: {
        current: 1,
        total: 2
      },
      isSaving: false,
      selectedBooking: this.booking,
      selectedShifts: null,
      listWithTimesheets: false,
      generatingTimesheets: false,
      showValidationErrors: false,
      formattingShifts: false,
      timesheets: [],
      entries: [],
      newWorkers: [],
      bookingPayRates: []
    };
  },
  computed: {
    ...mapGetters({
      bookings: GET_BOOKINGS,
      isLoadingBookings: IS_LOADING_BOOKINGS,
      bookingShifts: GET_BOOKING_SHIFTS,
      isLoadingShifts: IS_LOADING_BOOKING_SHIFTS
    }),
    ...mapState({
      systemParameters: state => state.systemParameters
    }),
    bookingItems() {
      return map(this.bookings, booking => ({
        ...booking,
        label: `B-${booking.id} | ${booking.title} | ${getFormattedLocation(
          booking.location
        )} ${
          size(booking.shiftPatterns)
            ? " | " + this.shiftPatternDates(booking)
            : ""
        }`
      }));
    },
    shiftItems() {
      return map(groupShiftByTime(this.bookingShifts), item => item);
    },
    dialogSettings() {
      return {
        dialog: this.isOpen,
        title: "Bulk Timesheets"
      };
    },
    listTypeSelectionText() {
      return `${
        this.listWithTimesheets ? "Hide" : "Show"
      } Submitted for Shifts`;
    },
    workers() {
      const confirmedWorkers = reduce(
        this.selectedShifts,
        (workers, shift) => {
          workers = [
            ...workers,
            ...getConfirmedWorkers(shift.applicationShifts)
          ];
          return workers;
        },
        []
      );
      return [...confirmedWorkers, ...this.newWorkers];
    },
    timesheetsToCreate() {
      return filter(this.timesheets, ({ id }) => !id);
    },
    entriesToCreate() {
      return filter(this.entries, ({ id }) => !id);
    },
    entriesToUpdate() {
      return filter(this.entries, ({ id }) => id);
    },
    entriesToRemove() {
      return filter(this.entries, ({ id, shouldRemove }) => id && shouldRemove);
    },
    weekCommencing() {
      const shift = first(this.selectedShifts);
      return formatDate({
        date: shift.startDate,
        endFormat: "YYYY-MM-DD"
      });
    },
    timesheetDetails() {
      const shift = first(this.selectedShifts);
      return (
        this.selectedBooking &&
        shift &&
        `B-${this.selectedBooking.id} | ${
          this.selectedBooking.title
        } | ${getFormattedLocation(this.selectedBooking.location)},
      ${shiftDayFormat(shift.startDate, SHIFT_DAY_FORMAT)} ${shiftHourRange(
          shift.startDate,
          shift.endDate
        )}`
      );
    }
  },
  methods: {
    ...mapActions({
      fetchBookings: FETCH_BOOKINGS,
      fetchBookingShifts: FETCH_BOOKING_SHIFTS,
      fetchBookingPayRates: FETCH_BOOKING_PAYRATES
    }),
    ...timesheetsMapAction({
      createTimesheet: CREATE_TIMESHEET,
      addEntry: ADD_ENTRY_FROM_NEW_TIMESHEET,
      editEntry: EDIT_ENTRY,
      removeEntry: REMOVE_ENTRY,
      addComment: ADD_COMMENT,
      fetchTimesheets: FETCH_SHIFT_TIMESHEETS,
      submitTimesheet: SUBMIT_TIMESHEET
    }),
    ...mapDataActions({
      fetchSystemParameters: FETCH_SYSTEM_PARAMETERS
    }),
    async handleSaveBulkTimesheets() {
      this.isSaving = true;
      try {
        const createdTimesheets = await this.createTimesheets();
        await this.createEntries(createdTimesheets);
        await this.updateEntries();
        await this.removeEntries();
        await this.submiteDraftTimesheets();
        await this.createComments(createdTimesheets);
        this.$emit("save");
        this.close();
      } finally {
        this.isSaving = false;
      }
    },
    async submiteDraftTimesheets() {
      const requests = filter(
        this.timesheets,
        ({ id, status }) => status === "draft" && this.submitTimesheet(id)
      );
      Promise.all(requests);
    },
    async createEntries(timesheets) {
      const createEntries = map(
        this.entriesToCreate,
        ({
          id,
          timesheet,
          absent,
          breakMinutes,
          expectedBreakMinutes,
          payRateId,
          startTime,
          endTime,
          dayDate,
          worker,
          shouldRemove,
          shift
        }) =>
          !shouldRemove &&
          !id &&
          this.addEntry({
            timesheet: timesheet.id
              ? timesheet
              : getWorkerTimesheet(timesheets, worker.id),
            absent,
            breakMinutes: Number(breakMinutes),
            expectedBreakMinutes: Number(expectedBreakMinutes),
            startTime,
            endTime,
            dayDate,
            shiftId: shift.id,
            expectedStartTime: shift.startTime,
            expectedEndTime: shift.endTime,
            "pay-rates": { id: payRateId }
          })
      );
      return Promise.all(createEntries);
    },
    async updateEntries() {
      const requests = map(
        this.entriesToUpdate,
        ({
          id,
          absent,
          breakMinutes,
          payRateId,
          startTime,
          endTime,
          shouldRemove
        }) =>
          !shouldRemove &&
          id &&
          this.editEntry({
            id,
            entry: {
              absent,
              breakMinutes: Number(breakMinutes),
              startTime,
              endTime,
              "pay-rates": { id: payRateId }
            }
          })
      );
      return Promise.all(requests);
    },
    async removeEntries() {
      const requests = map(
        this.entriesToRemove,
        ({ id, shouldRemove }) => shouldRemove && id && this.removeEntry(id)
      );
      return await Promise.all(requests);
    },
    async createComments(timesheets) {
      const requests = map(
        this.entries,
        ({ timesheet, comment, shouldRemove, worker }) => {
          const timesheetId = timesheet.id
            ? timesheet.id
            : getWorkerTimesheet(timesheets, worker.id).id;
          if (!shouldRemove && comment) {
            return this.addComment({
              timesheetId: timesheetId,
              comment: {
                body: comment,
                commentableType: TIMESHEET_MODEL,
                commentableId: timesheetId
              }
            });
          }
        }
      );
      return Promise.all(requests);
    },
    async createTimesheets() {
      const requests = map(
        this.timesheetsToCreate,
        ({ booking_id, worker_id, weekCommencing, weekEnding, status }) => {
          return this.createTimesheet({
            booking_id,
            worker_id,
            weekCommencing,
            weekEnding,
            status
          });
        }
      );
      return Promise.all(requests);
    },
    async generateTimesheets() {
      let generatedTimesheets = [];
      await Promise.all(
        map(this.selectedShifts, async shift => {
          let existingTimesheets = await this.fetchTimesheets({
            filter: { shift: shift.id }
          });
          const workersWithoutTimesheets = reduce(
            this.getWorkersForShift(shift.id),
            (workers, confirmedWorker) => {
              if (
                isEmpty(
                  filter(
                    existingTimesheets,
                    ({ worker }) => worker.id === confirmedWorker.id
                  )
                )
              ) {
                workers.push(confirmedWorker);
              }
              return workers;
            },
            []
          );
          const newTimesheets = map(workersWithoutTimesheets, worker => ({
            worker,
            worker_id: worker.id,
            booking_id: this.selectedBooking.id,
            weekCommencing: shift.startDate,
            weekEnding: shift.endDate,
            entries: [],
            status: "submitted",
            shift
          }));
          existingTimesheets = await Promise.all(
            map(existingTimesheets, timesheet => ({
              ...timesheet,
              shift
            }))
          );
          generatedTimesheets = [
            ...generatedTimesheets,
            ...existingTimesheets,
            ...newTimesheets
          ];
        })
      );
      return generatedTimesheets;
    },
    getWorkersForShift(shiftId) {
      let shift = find(this.selectedShifts, shift => shift.id === shiftId);
      return [
        ...getConfirmedWorkers(shift.applicationShifts),
        ...this.newWorkers
      ];
    },
    async nextStep() {
      this.generatingTimesheets = true;
      try {
        this.timesheets = await this.generateTimesheets();
        this.steps.current++;
      } finally {
        this.generatingTimesheets = false;
      }
    },
    close() {
      this.steps.current = 1;
      this.selectedBooking = null;
      this.listWithTimesheets = false;
      this.timesheets = [];
      this.entries = [];
      this.$emit("close");
    },
    loadShifts() {
      if (this.selectedBooking) {
        this.fetchBookingShifts({
          id: this.selectedBooking.id,
          params: !this.listWithTimesheets && {
            filter: { has_no_timesheet: true }
          }
        });
      }
    },
    changeListType() {
      this.listWithTimesheets = !this.listWithTimesheets;
      this.loadShifts();
    },
    selectBooking() {
      this.loadShifts();
    },
    async selectShift(value) {
      this.selectedShifts = value;
      this.formatShifts();
      this.setPayRates();
    },
    async formatShifts() {
      this.formattingShifts = true;
      this.selectedShifts = await Promise.all(
        map(this.selectedShifts, async shift => {
          const defaultPayRate =
            shift.pattern &&
            !isEmpty(shift.pattern.payRates) &&
            find(shift.pattern.payRates, { isDefault: true });
          const payRate =
            shift.pattern &&
            shift.pattern.payRates &&
            shift.pattern.payRates[0];
          return {
            ...shift,
            startTime: formatDate({
              date: shift.startDate,
              endFormat: API_TIME_FORMAT
            }),
            endTime: formatDate({
              date: shift.endDate,
              endFormat: API_TIME_FORMAT
            }),
            weekCommencing: this.weekCommencing,
            defaultPayRateId: defaultPayRate && defaultPayRate.id,
            payRateId: payRate && payRate.id
          };
        })
      );
      this.formattingShifts = false;
    },
    async setPayRates() {
      const filter = {
        awr: DEFAULT_AWR,
        weekCommencing: this.weekCommencing
      };
      const { data } = await this.fetchBookingPayRates({
        bookingId: this.selectedBooking.id,
        params: { filter }
      });
      this.bookingPayRates = data;
    },
    handleEntriesChange(entries) {
      this.entries = entries;
    },
    stepBack() {
      this.steps.current--;
      this.newWorkers = [];
    },
    async addWorkers(workers) {
      this.newWorkers = [...this.newWorkers, ...workers];
      this.generatingTimesheets = true;
      this.timesheets = await this.generateTimesheets();
      this.generatingTimesheets = false;
    },
    allShiftRange(booking) {
      return reduce(
        booking.shiftPatterns,
        (allShiftRRule, shiftPattern) => {
          const formattedRule = RRule.parseString(shiftPattern.rrule);
          if (
            !allShiftRRule.dtstart ||
            moment(allShiftRRule.dtstart).isAfter(formattedRule.dtstart)
          ) {
            allShiftRRule.dtstart = formattedRule.dtstart;
          }
          if (
            !allShiftRRule.until ||
            moment(allShiftRRule.until).isBefore(formattedRule.until)
          ) {
            allShiftRRule.until = formattedRule.until;
          }
          return allShiftRRule;
        },
        {}
      );
    },
    shiftPatternDates(booking) {
      return getShiftDateRange(this.allShiftRange(booking));
    }
  }
};
</script>
