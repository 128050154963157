<template>
  <v-data-table
    :headers="headers"
    :items="groupedDates"
    :mobile-breakpoint="null"
    class="timesheet-time-details mt-1"
    hide-default-footer
  >
    <template v-slot:item.date="{ item }">
      <div class="table-field">
        <div>{{ item.date.day }}</div>
        <h2>{{ item.date.dd }}</h2>
      </div>
    </template>
    <template v-slot:item.times="{ item }">
      <div class="table-field" v-for="(time, index) in item.times" :key="index">
        <div>{{ time }}</div>
        <div class="table-field__label">
          {{ item.payRates[index] }}
        </div>
      </div>
    </template>
    <template v-slot:item.totalWorked="{ item }">
      <div
        class="table-field align-end"
        v-for="(workedDetails, index) in item.totalWorked"
        :key="index"
      >
        <div class="discrepancy-field align-end">
          <div class="table-field__hours">
            {{
              isUnitTime
                ? getMinutesAsHrsAndMinsTime(workedDetails.totalTime)
                : item.isAbsent[index]
                ? "Absent"
                : getDaysWorked(workedDetails.totalTime)
            }}
          </div>
        </div>
        <div class="table-field__label" v-if="isUnitTime">
          {{ getBreakMinutesInfo(workedDetails.breakMinutes) }}
        </div>
      </div>
    </template>
    <template v-slot:item.discrepancy="{ item }">
      <div
        class="table-field align-end"
        v-for="(discrepancy, index) in item.discrepancy"
        :key="index"
      >
        <div
          v-if="discrepancy.show && isUnitTime"
          class="discrepancy align-end"
          :class="{ 'text-green': !discrepancy.prefix }"
        >
          {{ discrepancy.text }}
        </div>
      </div>
    </template>
    <template v-slot:item.costs="{ item }">
      <div
        class="table-field discrepancy-field"
        v-for="(cost, index) in item.costs"
        :key="index"
      >
        <div
          class="table-field__cost mr-2"
          v-if="permissions['timesheets.view.cost-entries']"
        >
          {{ cost.cost | cost | currency(cost.currencyCode) }}
        </div>
        <div
          v-if="showCostDiscrepancy(cost)"
          class="discrepancy"
          :class="{ 'text-green': getCostDiscrepancy(cost) < 0 }"
        >
          {{ getFormattedCostDiscrepancy(cost) }}
        </div>
      </div>
    </template>
    <template v-slot:body.append>
      <tr class="total-worked">
        <td :colspan="bottomColspan" />
        <td class="font-weight-bold">
          <div
            v-if="showTotalTimeDiscrepancy && isUnitTime"
            class="discrepancy-field discrepancy"
            :class="{ 'text-green': totalTimeDiscrepancy < 0 }"
          >
            {{ formattedTotalExpectedTime }}
          </div>
        </td>
        <td class="font-weight-bold">
          <div class="discrepancy-field">
            <div class="table-field__total-hours">
              {{ totalWeekTime }}
            </div>
          </div>
        </td>
        <td class="font-weight-bold">
          <div
            class="discrepancy-field"
            v-if="permissions['timesheets.view.total-cost']"
          >
            <div class="table-field__total-cost">
              {{ totalCost | cost | currency(globalCurrencyCode) }}
            </div>
            <div
              v-if="showTotalCostDiscrepancy"
              class="discrepancy"
              :class="{ 'text-green': totalCostDiscrepancy < 0 }"
            >
              {{ formattedTotalExpectedCost }}
            </div>
          </div>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import moment from "moment";
import { DATE_FORMAT_WITH_DAY } from "@/constants/common";
import { groupByDate } from "@/utils/helpers";
import { getPermissionedTableHeaders } from "@/utils/permissions";
import { sortBy } from "lodash";
import pluralize from "pluralize";
import { createNamespacedHelpers } from "vuex";
import { TIMESHEETS_NAMESPACE } from "@/store/modules/timesheets";
import { totalDuration, getMinutesAsHrsAndMinsTime } from "@/utils/time";

const { mapState } = createNamespacedHelpers(TIMESHEETS_NAMESPACE);

const ENTRY_TIME_FORMAT = "HH:mm";
const EMPTY_TIME = "00:00:00";
const MIN_SUFFIX = "min";
const DAY_SUFFIX = "day";

export default {
  name: "TimesheetDetailsTime",
  data() {
    return {
      totalCost: 0,
      totalExpectedCost: 0,
      totalExpectedWeekTime: 0,
      totalWorked: 0,
      globalCurrencyCode: "GBP"
    };
  },
  props: {
    permissions: Object
  },
  computed: {
    ...mapState({
      currentTimesheet: state => state.currentTimesheet
    }),
    times() {
      return this.currentTimesheet.entries;
    },
    totalWeekTime() {
      if (this.isUnitTime) {
        return getMinutesAsHrsAndMinsTime(this.totalWorked);
      }
      return this.getTotalDaysWorked();
    },
    formattedTotalExpectedTime() {
      const hourDiscrepancy = this.totalWorked - this.totalExpectedWeekTime;
      const prefix = hourDiscrepancy > 0 ? "+" : "";
      return `(${prefix}${getMinutesAsHrsAndMinsTime(hourDiscrepancy)})!`;
    },
    formattedTotalExpectedCost() {
      const totalCostDiscrepancy = this.totalCost - this.totalExpectedCost;
      const currency = this.$options.filters.currency;
      const prefix = totalCostDiscrepancy > 0 ? "+" : "";
      return `(${prefix}${currency(
        totalCostDiscrepancy,
        this.globalCurrencyCode
      )})!`;
    },
    totalTimeDiscrepancy() {
      return this.totalWorked - this.totalExpectedWeekTime;
    },
    totalCostDiscrepancy() {
      return this.totalCost - this.totalExpectedCost;
    },
    showTotalCostDiscrepancy() {
      return this.totalCostDiscrepancy !== 0;
    },
    showTotalTimeDiscrepancy() {
      return this.totalTimeDiscrepancy !== 0;
    },
    groupedDates() {
      return this.getGroupedDates();
    },
    headers() {
      const headers = [
        {
          text: "Date",
          value: "date",
          align: "center",
          width: 50
        },
        {
          text: "Time",
          value: "times",
          permission: "time-entries",
          sortable: false,
          class: "min-width-150"
        },
        ...(this.isUnitTime
          ? [
              {
                text: "Discrepancy",
                value: "discrepancy",
                sortable: false,
                class: "min-width-200"
              },
              {
                text: "Hours",
                value: "totalWorked",
                sortable: false,
                width: 170,
                class: "min-width-100"
              }
            ]
          : []),
        ...(!this.isUnitTime
          ? [
              {
                text: "Days",
                value: "totalWorked",
                width: "50%",
                sortable: false,
                class: "min-width-200"
              }
            ]
          : []),
        {
          text: "Cost",
          value: "costs",
          sortable: false,
          width: 170,
          permission: "cost-entries",
          class: "min-width-150"
        }
      ];

      return getPermissionedTableHeaders(headers, "timesheets.view");
    },
    isUnitTime() {
      return this.currentTimesheet.timeUnitName === "hour";
    },
    bottomColspan() {
      return this.isUnitTime ? 2 : 1;
    }
  },
  methods: {
    getBreakMinutesInfo(breakMinutes) {
      return `${breakMinutes} ${pluralize(MIN_SUFFIX, breakMinutes)} break`;
    },
    getTotalDaysWorked() {
      return totalDuration(this.totalWorked, "day");
    },
    getDaysWorked(days) {
      if (days <= 0) {
        return "-";
      }
      return `${days} ${pluralize(DAY_SUFFIX, days)}`;
    },
    timeDiscrepancy({ expectedTotalTime, totalTime }) {
      return totalTime - expectedTotalTime;
    },
    getCostDiscrepancy({ cost, expectedCost }) {
      return cost - expectedCost;
    },
    showCostDiscrepancy(cost) {
      return this.getCostDiscrepancy(cost) !== 0;
    },
    getFormattedCostDiscrepancy({ cost, expectedCost, currencyCode }) {
      const costDiscrepancy = this.getCostDiscrepancy({ cost, expectedCost });
      const currency = this.$options.filters.currency;
      const prefix = costDiscrepancy > 0 ? "+" : "";
      return `(${prefix}${currency(costDiscrepancy, currencyCode)})!`;
    },
    getHourDiscrepancy(hourDetails) {
      const { expectedTotalTime, totalTime } = hourDetails;
      const discrepancyInMinutes = totalTime - expectedTotalTime;
      const prefix = discrepancyInMinutes > 0 ? "+" : "";
      return {
        text: `(${prefix}${getMinutesAsHrsAndMinsTime(discrepancyInMinutes)})!`,
        prefix,
        show: discrepancyInMinutes !== 0
      };
    },
    getGroupedDates() {
      this.totalCost = 0;
      this.totalExpectedCost = 0;
      this.totalExpectedWeekTime = 0;
      this.totalWorked = 0;
      const sortedTimes = sortBy(this.times, [`dayDate`]);
      const groupedDatesObj = groupByDate({
        items: sortedTimes,
        key: "dayDate",
        format: DATE_FORMAT_WITH_DAY
      });

      return Object.keys(groupedDatesObj).reduce((groupedDates, key) => {
        const times = [];
        const totalWorked = [];
        const costs = [];
        const payRates = [];
        const isAbsent = [];
        const discrepancy = [];
        let date = {};
        groupedDatesObj[key].forEach(
          ({
            startTime,
            endTime,
            cost,
            currency_code: currencyCode,
            expected_cost: expectedCost,
            expectedTotalTime,
            totalTime,
            breakMinutes,
            absent,
            dayDate,
            "pay-rates": payRate
          }) => {
            if (!this.globalCurrencyCode) {
              this.globalCurrencyCode = currencyCode;
            }
            this.totalCost += cost;
            this.totalExpectedCost += expectedCost;
            const start = this.getFormattedTime(startTime);
            const end = this.getFormattedTime(endTime);
            this.totalWorked += totalTime;
            this.totalExpectedWeekTime += expectedTotalTime;
            const time = `${start}-${end}`;
            times.push(time);
            totalWorked.push({ totalTime, expectedTotalTime, breakMinutes });
            costs.push({ cost, currencyCode, expectedCost });
            payRates.push(payRate ? payRate.activityTypeName : "Standard");
            isAbsent.push(absent);
            discrepancy.push(
              this.getHourDiscrepancy({
                totalTime,
                expectedTotalTime,
                breakMinutes
              })
            );
            date.day = moment(dayDate).format("ddd");
            date.dd = moment(dayDate).format("DD");
          }
        );
        return [
          ...groupedDates,
          { date, times, totalWorked, costs, payRates, isAbsent, discrepancy }
        ];
      }, []);
    },
    getFormattedTime(time) {
      return moment(time || EMPTY_TIME, "HH:mm:ss").format(ENTRY_TIME_FORMAT);
    },
    getMinutesAsHrsAndMinsTime
  }
};
</script>

<style lang="scss">
.timesheet-time-details {
  &.v-data-table {
    thead {
      visibility: hidden;
      display: none;
    }
    td {
      border-bottom: none !important;
    }
    .total-worked td {
      border-top: 1px solid $secondary-line;
    }
  }
}
.text-green {
  color: green !important;
}
</style>
