// The order of object keys can't be changed
export const MAIN_NAVIGATION_ITEMS = {
  DASHBOARD: "dashboard",
  WORKERS: "workers",
  AGENCIES: "agencies",
  BOOKINGS: "bookings",
  TIMESHEETS: "timesheets",
  FINANCE: "finance",
  REPORTS: "reports",
  SETTINGS: "settings",
  EXPORTS: "exports"
};

export const SETTINGS_SECTIONS = {
  ACCOUNT: `settings/account`,
  ORGANISATION: `settings/organisation`
};
