<template>
  <ValidationObserver ref="form">
    <BaseDialog :isOpen="dialogSettings.dialog">
      <template v-slot:title>
        <span>{{ dialogSettings.title }} </span>
      </template>
      <template v-slot:body>
        <div class="flex-gap-20 mt-3 mb-3">
          <ValidationProvider v-slot="{ errors }" rules="required">
            <v-text-field
              label="Name"
              v-model="reason.hireReason"
              :error-messages="shouldValidate ? errors : ''"
            />
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" rules="required">
            <Select
              outlined
              dense
              label="Status"
              :items="statuses"
              item-text="label"
              item-value="value"
              v-model="reason.status"
              :error-messages="shouldValidate ? errors : ''"
            />
          </ValidationProvider>
        </div>
      </template>
      <template v-slot:actions>
        <PrimaryButton @click.native="close" text>Cancel</PrimaryButton>
        <PrimaryButton @click.native="submit" :loading="loading">{{
          actionName
        }}</PrimaryButton>
      </template>
    </BaseDialog>
  </ValidationObserver>
</template>

<script>
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import Select from "@/components/common/Select";
import BaseDialog from "@/components/common/BaseDialog";
import { isRequired } from "@/utils/validation/rules";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  name: "ReasonForBookingDialog",
  components: {
    BaseDialog,
    Select,
    PrimaryButton,
    ValidationObserver,
    ValidationProvider
  },
  props: {
    dialogSettings: Object,
    loading: Boolean,
    reasonForBooking: {
      type: Object,
      default: () => ({})
    },
    actionName: String
  },
  data() {
    return {
      reason: {},
      shouldValidate: false,
      statuses: [
        { label: "Active", value: "active" },
        { label: "Inactive", value: "inactive" }
      ]
    };
  },
  computed: {
    isValid() {
      return ["hireReason", "status"].every(value => this.reason[value]);
    }
  },
  created() {
    this.reason = { ...this.reasonForBooking };
  },
  methods: {
    isRequired,
    submit() {
      this.reason = { ...this.reason };
      this.shouldValidate = true;
      this.$refs.form.validate();
      if (this.isValid) {
        this.$emit("submit", this.reason);
      }
    },
    close() {
      this.shouldValidate = false;
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss"></style>
