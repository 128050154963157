<template>
  <div class="confirm-allocation">
    <div class="confirm-allocation-content col-8 ml-auto mr-auto">
      <h2>Confirm Allocation</h2>
      <p class="secondary-text">
        Are you sure you want to allocate candidates to
        {{ allShifts ? "all shifts?" : "the following shifts?" }}
      </p>
      <div
        v-for="allocation in allocations"
        :key="allocation.shiftId"
        class="mb-8"
      >
        <ShiftLink :shift-id="allocation.id" />
        <h3 v-if="!allShifts">{{ allocation.date }}</h3>
        <p class="secondary-text" v-if="!allShifts">
          {{ allocation.startDate | hourAndMinutes }}-{{
            allocation.endDate | hourAndMinutes
          }}
        </p>
        <div
          v-for="worker in allocation.workers"
          :key="worker.id"
          class="d-flex py-2 pl-2 mr-2 align-center shrink"
        >
          <div
            class="icon-rounded mr-2"
            :style="{
              backgroundColor:
                styles[
                  `${worker.isUnavailable ? 'redBackground' : 'grayBackground'}`
                ]
            }"
          >
            <v-icon color="black">mdi-account-outline</v-icon>
          </div>
          <div class="d-flex align-center flex-wrap justify">
            <p class="mb-0 mr-1">
              {{ worker | fullName }}
            </p>
            <strong v-if="worker.isUnavailable" class="mb-0 error-text">
              {{ `(${worker.formattedReason})` }}
            </strong>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ShiftLink from "@/components/bookings/ShiftLink";
import { FULL_DAY_MONTH_FORMAT } from "@/utils/time";
import moment from "moment";
import { isEmpty } from "lodash";
import styles from "@/sass/abstracts/_chips-colors.scss";

export default {
  name: "ConfirmAllocation",
  components: {
    ShiftLink
  },
  data() {
    return {
      styles
    };
  },
  computed: {
    allocations() {
      return this.shifts.length
        ? this.shifts.map(shift => ({
            ...(shift || []),
            workers: this.workers,
            date: moment(shift.startDate).format(FULL_DAY_MONTH_FORMAT)
          }))
        : [{ workers: this.workers }];
    },
    allShifts() {
      return isEmpty(this.shifts);
    }
  },
  props: {
    shifts: Array,
    workers: Array
  }
};
</script>

<style lang="scss">
.confirm-allocation {
  display: flex;
  flex-direction: column;
  > h2 {
    font-size: 32px;
  }
  > p {
    font-size: 22px;
  }

  .confirm-allocation-content {
    padding-bottom: 90px;
  }

  .icon-wrapper {
    background-color: rgba(94, 122, 141, 0.1);
    padding: 6px 8px;
    border-radius: 4px;
    margin-right: 14px;
    width: 40px;
    height: 40px;

    .v-icon {
      margin: auto;
      width: 100%;
    }
  }

  .footer {
    position: fixed;
    width: 100%;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    padding: 0.5rem;
    left: 0;

    @media only screen and (max-width: $mobile-breakpoint - 1) {
      justify-content: center;
    }
  }
}
</style>
