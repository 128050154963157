<template>
  <Permissioned section="shift-patterns" :id="id">
    <PrimaryButton
      class="mr-2 my-1"
      @click.native="$emit('submit')"
      :loading="loading"
      permission="submit"
      dense
      light
    >
      Submit For Approval
    </PrimaryButton>

    <ErrorButton
      permission="delete"
      :loading="loading"
      @click.native="$emit('delete')"
      light
      dense
    >
      Delete
    </ErrorButton>
    <ErrorButton
      class="mr-2 my-1"
      :loading="loading"
      permission="reject"
      @click.native="$emit('reject')"
      light
    >
      Reject
      <inline-svg
        class="ml-1"
        :src="require('@/assets/svg/reject-icon.svg')"
        width="28"
      />
    </ErrorButton>
    <PrimaryButton
      @click.native="$emit('approve')"
      :loading="loading"
      permission="approve"
      light
    >
      Approve
      <inline-svg
        class="ml-1"
        :src="require('@/assets/svg/approve-icon.svg')"
        width="28"
      />
    </PrimaryButton>
  </Permissioned>
</template>

<script>
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import ErrorButton from "@/components/common/Button/ErrorButton";
import Permissioned from "@/components/common/Permissioned";
import InlineSvg from "vue-inline-svg";

export default {
  props: {
    id: String,
    loading: Boolean
  },
  components: {
    PrimaryButton,
    ErrorButton,
    Permissioned,
    InlineSvg
  }
};
</script>

<style lang="scss" scoped>
.shift-entry {
  .permissioned-container {
    display: block;
  }
}
</style>
