<template>
  <v-container>
    <v-skeleton-loader
      v-if="isFetchingTimesheetEntries || isLoadingPayRates"
      type="table"
    />
    <div v-else>
      <div class="headers">
        <div class="left">
          <div class="activity">
            Activity
          </div>
          <div>
            Start
          </div>
          <div>
            End
          </div>
          <div v-if="isUnitTime">
            Break
          </div>
        </div>
        <div class="right">
          <div v-if="!isUnitTime">
            Full Day
          </div>
          <div v-if="!isUnitTime">
            Part Day
          </div>
          <div>
            Absent
          </div>
          <div>
            Total
          </div>
          <div />
        </div>
      </div>
      <TimesheetEntry
        v-for="(entry, index) in groupedEntries"
        :key="index"
        :activities="activities"
        :times="entry.times"
        :date="entry.date"
        :triggerEdit="triggerEdit"
        :expectedTimes="expectedTimes"
        @editing="handleEditing"
        @success="handleSuccess"
      />
      <div class="total mt-3">
        <p>Total</p>
        <span>{{ totalWeekTime }}</span>
      </div>
    </div>
  </v-container>
</template>

<script>
import TimesheetEntry from "@/components/timesheets/time/TimesheetEntry";
import moment from "moment";
import { sortBy, forEach, first, filter } from "lodash";
import { groupByDate } from "@/utils/helpers";
import { getTotalDurationBetweenTimes } from "@/utils/timesheets";
import { createNamespacedHelpers } from "vuex";
// store timesheets
import { TIMESHEETS_NAMESPACE } from "@/store/modules/timesheets";
import { FETCH_CURRENT_TIMESHEET_ENTRIES } from "@/store/modules/timesheets/actions";

const { mapActions: mapTimesheetActions, mapState } = createNamespacedHelpers(
  TIMESHEETS_NAMESPACE
);
import { map } from "lodash";
import {
  formatTime,
  totalDuration,
  formatDate,
  getFormattedDuration
} from "@/utils/time";

// store bookings
import {
  BOOKINGS_NAMESPACE,
  FETCH_BOOKING_PAYRATES
} from "@/store/modules/bookings/actions";
import {
  GET_BOOKING_PAYRATES,
  IS_LOADING_BOOKING_PAYRATES
} from "@/store/modules/bookings/getters";

const { mapActions: mapActionsBooking, mapGetters } = createNamespacedHelpers(
  BOOKINGS_NAMESPACE
);

export default {
  name: "TimeList",
  props: {
    // type string is used to trigger child components
    triggerEdit: String
  },
  components: { TimesheetEntry },
  created() {
    const filter = {
      awr: Number(this.currentTimesheet.awr),
      weekCommencing: this.weekCommencing
    };
    this.fetchCurrentTimesheetEntries(this.currentTimesheetId);
    this.fetchBookingPayRates({
      bookingId: this.currentTimesheet.booking.id,
      params: { filter }
    });
  },
  computed: {
    ...mapGetters({
      bookingPayRates: GET_BOOKING_PAYRATES,
      isLoadingPayRates: IS_LOADING_BOOKING_PAYRATES
    }),
    ...mapState({
      entries: state => state.timesheetEntries,
      isFetchingTimesheetEntries: state => state.isFetchingTimesheetEntries,
      currentTimesheet: state => state.currentTimesheet
    }),
    isUnitTime() {
      return this.currentTimesheet.timeUnitName === "hour";
    },
    groupedEntries() {
      const groupedEntriesObj = groupByDate({
        items: this.entries,
        key: "dayDate",
        format: "YYYY-MM-DD"
      });
      const res = Object.keys(groupedEntriesObj).reduce((groupedDates, key) => {
        const times = [];
        groupedEntriesObj[key].forEach(item => {
          const { "pay-rates": payRates } = item;
          //TODO remove time formatting, should be returned as HH:MM from the API
          const startTime = item.startTime
            ? moment(item.startTime, "HH:mm:ss").format("HH:mm")
            : "";
          const endTime = item.endTime
            ? moment(item.endTime, "HH:mm:ss").format("HH:mm")
            : "";
          times.push({ ...item, startTime, endTime, payRates });
        });
        return [...groupedDates, { date: key, times }];
      }, []);

      return sortBy(res, [`date`]);
    },
    totalWeekTime() {
      if (this.isUnitTime) {
        return this.getTotalWeekHours();
      }
      return this.getTotalWeekDays();
    },
    currentTimesheetId() {
      return this.$route.params.id;
    },
    activities() {
      return map(this.bookingPayRates, item => {
        const { activityTypeName, id } = item;
        return {
          text: activityTypeName,
          value: id,
          ...item
        };
      });
    },
    expectedTimes() {
      const expectedTimes = first(
        filter(
          this.entries,
          entry => entry.expectedStartTime && entry.expectedEndTime
        )
      );
      return expectedTimes &&
        expectedTimes.expectedStartTime &&
        expectedTimes.expectedEndTime
        ? {
            startTime: expectedTimes.expectedStartTime,
            endTime: expectedTimes.expectedEndTime
          }
        : {};
    },
    weekCommencing() {
      return formatDate({
        date: this.currentTimesheet.weekCommencing,
        endFormat: "YYYY-MM-DD"
      });
    }
  },
  methods: {
    ...mapTimesheetActions({
      fetchCurrentTimesheetEntries: FETCH_CURRENT_TIMESHEET_ENTRIES
    }),
    ...mapActionsBooking({
      fetchBookingPayRates: FETCH_BOOKING_PAYRATES
    }),
    selectTimesheet(id) {
      this.selectedTimesheet = id;
    },
    handleEditing() {
      this.$emit("editing");
    },
    handleSuccess() {
      this.$emit("success");
    },
    getTotalWeekHours() {
      const totalDuration = this.groupedEntries.reduce((total, { times }) => {
        return getTotalDurationBetweenTimes(times).add(total);
      }, moment.duration(0));
      return getFormattedDuration(totalDuration);
    },
    getTotalWeekDays() {
      const totalDays = this.groupedEntries.reduce((total, { times }) => {
        forEach(times, time => {
          const isEmptyPartTime = time.totalTime === -1;
          if (
            time.startTime === formatTime(this.expectedTimes.startTime) &&
            time.endTime === formatTime(this.expectedTimes.endTime) &&
            !time.absent &&
            !isEmptyPartTime
          ) {
            total += 1;
          } else {
            if (!isEmptyPartTime) {
              total += time.totalTime;
            }
          }
        });
        return total;
      }, 0);
      return totalDuration(totalDays);
    }
  }
};
</script>

<style scoped lang="scss">
.v-divider {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
</style>
