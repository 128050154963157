<template>
  <div class="d-flex justify-center">
    <ImportBookings
      ref="bulkBookingForm"
      @close="closeDialog"
      @success="$emit('success')"
      :isOpen="isOpen"
    />
    <SecondaryButton
      @click.native="openDialog"
      permission="create"
      color="primary"
    >
      <div class="d-flex align-center justify-center mb-1">
        <v-icon medium>mdi-briefcase</v-icon>
        <v-icon small>mdi-plus</v-icon>
      </div>
      Bulk Bookings
    </SecondaryButton>
  </div>
</template>

<script>
import ImportBookings from "@/views/bookings/NewBooking/ImportBookings";
import SecondaryButton from "@/components/common/Button/SecondaryButton";

export default {
  name: "BulkBookingAction",
  components: {
    ImportBookings,
    SecondaryButton
  },
  data() {
    return {
      isOpen: false,
      contractType: null
    };
  },
  methods: {
    openDialog() {
      this.isOpen = true;
      document.title = "Bulk Role - Roles - Track";
    },
    closeDialog() {
      this.isOpen = false;
      document.title = "Roles - Track";
    }
  }
};
</script>
<style lang="scss">
.job-icon {
  path.stroke {
    stroke: $white;
  }

  path.fill {
    fill: $white;
  }

  rect.stroke {
    stroke: $white;
  }

  rect.fill {
    fill: $white;
  }

  line.stroke {
    stroke: $white;
  }
}
</style>
