<template>
  <v-list-item
    :active-class="`active ${item.activeClass}`"
    class="nav-item"
    color="primary"
    target="_"
    :to="to"
    :href="href"
  >
    <inline-svg :src="item.icon" />
    <v-list-item-title class="nav-item-title" v-if="item.title">
      {{ item.title }}
    </v-list-item-title>
  </v-list-item>
</template>

<script>
import InlineSvg from "vue-inline-svg";

export default {
  name: "NavigationItem",
  components: {
    InlineSvg
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    organisationUnitId: String
  },
  computed: {
    targetRoute() {
      if (this.item.href) {
        return this.item.href;
      }
      const API_URL = process.env.VUE_APP_API_URL;
      return this.item.isExternalLink
        ? `${API_URL}/2/${this.item.routeName}`
        : `/${this.item.routeName}`;
    },
    to() {
      return this.item.isExternalLink ? null : this.targetRoute;
    },
    href() {
      return this.item.isExternalLink && this.targetRoute;
    }
  }
};
</script>

<style lang="scss">
.v-list-item.nav-item {
  height: 48px;
  border-radius: 4px;

  .nav-item-title {
    margin-left: 10px;
  }

  @media all and (min-width: $large-tablet-breakpoint) and (-ms-high-contrast: none),
    (-ms-high-contrast: active) {
    max-width: 200px;
  }

  .v-icon {
    margin-right: 5px;
    color: $primary;
  }

  &.active {
    background-color: $river-bed !important;
    box-shadow: $box-shadow;

    svg path {
      fill: $white;
      stroke: $white;
    }

    .v-list-item__title {
      color: $white !important;
    }

    &.white-line line {
      fill: $white;
      stroke: $white;
    }

    &.white-rect rect {
      fill: $white;
      stroke: $white;
    }

    &.fill-none svg path {
      fill: none;
    }

    &.white-line-nofill line {
      stroke: $white;
    }

    &.white-rect-nofill rect {
      stroke: $white;
    }

    &.white-circle circle {
      stroke: $white;
    }
  }

  .v-list-item__title {
    color: $primary;
    font-weight: bold;
  }
}
</style>
