<template>
  <div class="inline-filters" v-if="!isLoading">
    <div
      v-for="(filterOptions, filterKey, index) in availableFilters"
      :key="index"
    >
      <Permissioned section="timesheets.filters">
        <InlineFilter
          v-if="size(filterOptions)"
          :label="getFilterTitle(filterKey)"
          :filters="filters[filterKey]"
          :options="filterOptions"
          @clear="handleClear(filterKey)"
        >
          <FilterCheckbox
            :options="getFilterOptions(filterOptions, filterKey)"
            :selectedValues="filters[filterKey]"
            @change="values => updateFilterFor(filterKey, values)"
          />
        </InlineFilter>
        <InlineFilter
          v-if="index === size(availableFilters) - 1 && !isGroupedTimesheets"
          label="Week Commencing"
          :filters="[filters.after_date, filters.before_date]"
          @clear="clearDates"
          isDate
          rangeDate
        >
          <DatePicker
            :inputValue="filters.after_date"
            class="my-2 d-flex"
            label="After"
            @updatedDate="value => updateFilterFor('after_date', value)"
          />
          <DatePicker
            :inputValue="filters.before_date"
            class="d-flex"
            label="Before"
            @updatedDate="value => updateFilterFor('before_date', value)"
          />
        </InlineFilter>
      </Permissioned>
    </div>
  </div>
</template>

<script>
import DatePicker from "@/components/common/DatePicker";
import InlineFilter from "@/components/common/InlineFilter";
import FilterCheckbox from "@/components/common/FilterCheckbox";
import Permissioned from "@/components/common/Permissioned";
import { filterMixin } from "@/mixins/filter.mixin";
import { size, startCase } from "lodash";

export default {
  components: {
    InlineFilter,
    DatePicker,
    FilterCheckbox,
    Permissioned
  },
  props: {
    isLoading: Boolean,
    outerSelectedFilters: {
      type: Object,
      default: () => ({})
    },
    filterLabels: {
      type: Object,
      default: () => ({})
    },
    availableFilters: Object,
    isGroupedTimesheets: Boolean
  },
  mixins: [filterMixin],
  methods: {
    getFilterTitle(filterName) {
      const label = this.filterLabels[filterName];
      if (label) {
        return label;
      }
      switch (filterName) {
        case "day-date":
          return "Date";
        case "worker_id":
          return "Worker";
        case "status":
          return "Status";
        case "booking_id":
        case "bookings":
        case "booking-id":
          return "Bookings";
        case "invoice_id":
          return "Invoice";
        case "timesheet_approver_id":
          return "Approver";
        case "root_client_id":
          return "Client";
        default:
          return startCase(filterName);
      }
    },
    clearDates() {
      this.filters.after_date = null;
      this.filters.before_date = null;
      this.saveChanges();
    },
    size
  }
};
</script>

<style lang="scss"></style>
