import InlineFilter from "@/components/common/InlineFilter";
import FilterCheckbox from "@/components/common/FilterCheckbox";
import { isEmpty, map, omitBy, startCase, sortBy } from "lodash";
import Vue from "vue";

export const filterMixin = {
  components: {
    InlineFilter,
    FilterCheckbox
  },
  props: {
    isLoading: Boolean,
    outerSelectedFilters: {
      type: Object,
      default: () => ({})
    },
    filterLabels: {
      type: Object,
      default: () => ({})
    },
    availableFilters: Object
  },
  data() {
    return {
      filters: Object.assign({}, this.outerSelectedFilters)
    };
  },
  computed: {},
  methods: {
    clearFilters() {
      this.$emit("onClearFilters");
    },
    saveChanges() {
      const formattedFilters = omitBy(this.filters, isEmpty);
      this.$emit("filter", formattedFilters);
    },
    updateFilterFor(key, value) {
      Vue.set(this.filters, key, value);
      this.saveChanges();
    },
    getFilterOptions(options, filterName) {
      const formattedOptions =
        filterName === "status"
          ? map(options, option => {
              return { text: startCase(option.text), value: option.value };
            })
          : options;
      return filterName === "day-date"
        ? formattedOptions
        : sortBy(formattedOptions, ["text"]);
    },
    handleClear(key) {
      Vue.set(this.filters, key, []);
      this.saveChanges();
    }
  },
  watch: {
    outerSelectedFilters(newValue) {
      this.filters = Object.assign({}, newValue);
    }
  }
};
