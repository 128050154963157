<template>
  <div>
    <div class="d-flex align-end">
      <v-menu bottom offset-y>
        <template v-slot:activator="{ on, attrs }">
          <div class="flex-grow-1" v-bind="attrs" v-on="on">
            <WorkersSelection
              :isLoading="isLoading"
              :showValidationErrors="showValidationErrors"
              :preventSelecting="hideSelector"
              :workers="[...filteredWorkers, ...selectedWorkers]"
              :selectedWorkers="selectedWorkers"
              :maxHeight="0"
              :disabled="generatingTimesheets"
              @unselectWorker="unselectWorker"
              @search="handleSearch"
            />
          </div>
        </template>
        <v-list class="bulk-timesheet-select" v-if="hasWorkers">
          <v-list-item
            v-for="(worker, index) in filteredWorkers"
            :key="index"
            @click="selectWorker(worker)"
          >
            <v-list-item-title>
              {{ worker | fullNameWithAgency }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <PrimaryButton @click="isOpen = true" text dense>
              + Create worker...
            </PrimaryButton>
          </v-list-item>
        </v-list>
        <v-list v-if="!hasWorkers && shouldSearch && !isLoading">
          <p class="my-1 mx-2 secondary-text">Not found.</p>
          <v-divider />
          <v-list>
            <PrimaryButton @click="isOpen = true" text dense>
              + Create worker...
            </PrimaryButton>
          </v-list>
        </v-list>
      </v-menu>
      <PrimaryButton
        class="flex-grow-0"
        @click="addWorkers"
        :loading="generatingTimesheets"
        :disabled="emptySelectedWorkers"
      >
        Add Worker
      </PrimaryButton>
    </div>
    <CreateWorkerDialog
      v-if="isOpen"
      :isOpen="isOpen"
      :agencyId="agencyId"
      @close="isOpen = false"
      @add="handleAddWorker"
    />
  </div>
</template>

<script>
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import WorkersSelection from "@/components/bookings/WorkersSelection";
import { filter, isEmpty, size, some } from "lodash";
import { TIMESHEETS_NAMESPACE } from "@/store/modules/timesheets";
import { FETCH_AGENCY_WORKERS } from "@/store/modules/timesheets/actions";
import { createNamespacedHelpers } from "vuex";
import { getFullName } from "@/utils/users";
import CreateWorkerDialog from "@/views/workers/NewWorker/dialogs/CreateWorkerDialog";

const { mapActions } = createNamespacedHelpers(TIMESHEETS_NAMESPACE);

export default {
  props: {
    agencyId: String,
    entries: Array,
    generatingTimesheets: Boolean
  },
  components: { WorkersSelection, PrimaryButton, CreateWorkerDialog },
  data() {
    return {
      showValidationErrors: false,
      isLoading: false,
      selectedWorkers: [],
      workers: [],
      hideSelector: Boolean(this.searchKey),
      searchKey: "",
      isOpen: false
    };
  },
  computed: {
    hasWorkers() {
      return !isEmpty(this.filteredWorkers);
    },
    requestParams() {
      return {
        filter: {
          account_type: "worker",
          ...(this.agencyId && { organisation_id: this.agencyId }),
          ...(size(this.searchKey.trim()) && { search: this.searchKey }),
          status: ["invited", "active"]
        }
      };
    },
    shouldSearch() {
      return size(this.searchKey) > 2;
    },
    filteredWorkers() {
      return filter(
        this.workers,
        ({ id }) =>
          !some(
            this.selectedWorkers,
            selectedWorker => selectedWorker.id === id
          ) && !some(this.entries, entry => entry.workerId === id)
      );
    },
    emptySelectedWorkers() {
      return isEmpty(this.selectedWorkers);
    }
  },
  methods: {
    ...mapActions({
      fetchWorkers: FETCH_AGENCY_WORKERS
    }),
    async handleSearch(search) {
      this.searchKey = search;
      this.workers = [];
      if (this.shouldSearch) {
        this.isLoading = true;
        const { data } = await this.fetchWorkers(this.requestParams);
        this.workers = data;
        this.isLoading = false;
      }
    },
    selectWorker(worker) {
      this.selectedWorkers = [...this.selectedWorkers, worker];
    },
    unselectWorker(worker) {
      this.selectedWorkers = filter(
        this.selectedWorkers,
        ({ id }) => id !== worker.id
      );
    },
    addWorkers() {
      this.$emit("add", this.selectedWorkers);
      this.selectedWorkers = [];
    },
    handleAddWorker(worker) {
      this.selectWorker(worker);
    }
  },
  filters: {
    fullNameWithAgency(worker) {
      const agencyName =
        worker.organisation && ` | ${worker.organisation.name}`;
      return `${getFullName(worker)}${agencyName}`;
    }
  },
  watch: {
    generatingTimesheets() {
      this.selectedWorkers = [];
    }
  }
};
</script>

<style lang="scss" scoped>
.v-list {
  padding: 2px 0;
}

button.base-button {
  border-radius: 0 5px 5px 0 !important;
  height: 54.5px !important;
}
</style>
