export const USER_ROLE_MODEL = "user_role";

const userRoleModel = {
  roles: {
    jsonApi: "hasMany",
    type: "role"
  }
};

export default userRoleModel;
