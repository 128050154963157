<template>
  <AppLayout class="files container col-lg-9 col-sm-12">
    <template v-slot:header>
      <BaseListToolbar title="Files" @search="updateSearchValue" />
    </template>
    <template v-slot:body>
      <div v-if="isFetchingFiles" class="my-6">
        <v-skeleton-loader
          data-test="loader"
          v-for="i in 10"
          :key="i"
          type="list-item-avatar"
        />
      </div>
      <div v-else class="my-6">
        <div v-if="files.length">
          <v-list-item
            @click="viewFile(file)"
            v-for="file in filteredFiles"
            :key="file.id"
          >
            <FileItem :file="file" />
          </v-list-item>
        </div>
        <EmptyStates
          v-else
          data-test="empty-list"
          icon="roles-empty-state"
          description="No files"
        />
      </div>
      <BaseFooter class="mb-2" v-if="isPermissioned('files.upload')">
        <ExpandedButtons>
          <UploadFileAction />
        </ExpandedButtons>
      </BaseFooter>
    </template>
  </AppLayout>
</template>

<script>
import AppLayout from "@/components/common/base-layouts/AppLayout";
import BaseListToolbar from "@/components/common/Toolbar/BaseListToolbar";
import { createNamespacedHelpers } from "vuex";
import { FILES_NAMESPACE } from "@/store/modules/files";
import { FETCH_FILES } from "@/store/modules/files/actions";
import { filter } from "lodash";
import EmptyStates from "@/components/common/EmptyStates";
import ExpandedButtons from "@/components/common/ExpandedButtons";
import BaseFooter from "@/components/common/BaseFooter";
import FileItem from "@/views/files/components/FileItem";
import UploadFileAction from "@/views/files/components/actions/UploadFileAction";
import { isPermissioned } from "@/utils/permissions";

const { mapState, mapActions } = createNamespacedHelpers(FILES_NAMESPACE);

export default {
  components: {
    AppLayout,
    BaseListToolbar,
    EmptyStates,
    ExpandedButtons,
    BaseFooter,
    FileItem,
    UploadFileAction
  },
  async created() {
    this.fetchFiles();
  },
  data: () => ({
    search: ""
  }),
  computed: {
    ...mapState(["files", "isFetchingFiles"]),
    filteredFiles() {
      return filter(this.files, file => {
        if (this.search) {
          const name = file.name.toString().toLowerCase();
          const searchKey = this.search.toString().toLowerCase();
          return name.indexOf(searchKey) > -1;
        }
        return true;
      });
    }
  },
  methods: {
    ...mapActions({
      fetchFiles: FETCH_FILES
    }),
    updateSearchValue(text) {
      this.search = text;
    },
    viewFile(file) {
      this.$router.push({
        name: "fileDetails",
        params: { id: file.id }
      });
    },
    isPermissioned
  }
};
</script>
