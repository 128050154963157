import { handleWithErrorMessage } from "@/services/utils";
import api from "@/services/modules/files";
import {
  SET_IS_FETCHING_FILES,
  SET_FILES,
  SET_IS_FETCHING_FILE,
  SET_FILE
} from "@/store/modules/files/mutations";

export const FETCH_FILES = "FETCH_FILES";
export const CREATE_FILE = "CREATE_FILE";
export const UPLOAD_FILE = "UPLOAD_FILE";
export const FETCH_FILE = "FETCH_FILE";
export const DELETE_FILE = "DELETE_FILE";
export const DOWNLOAD_FILE = "DOWNLOAD_FILE";

const filessActions = {
  async [FETCH_FILES]({ dispatch, commit }) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api({ commit }).getFiles(),
      setLoadingFunction: isLoading => commit(SET_IS_FETCHING_FILES, isLoading),
      setDataFunction: ({ data }) => commit(SET_FILES, data)
    });
  },
  async [FETCH_FILE]({ dispatch, commit }, id) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api({ commit }).getFile(id),
      setLoadingFunction: isLoading => commit(SET_IS_FETCHING_FILE, isLoading),
      setDataFunction: ({ data }) => commit(SET_FILE, data)
    });
  },
  async [CREATE_FILE]({ dispatch, commit }, data) {
    return handleWithErrorMessage({
      request: () => api({ commit }).createFile(data),
      dispatch
    });
  },
  async [UPLOAD_FILE]({ dispatch, commit }, { id, data }) {
    return handleWithErrorMessage({
      request: () => api({ commit }).uploadFile(id, data),
      dispatch
    });
  },
  async [DELETE_FILE]({ dispatch, commit }, id) {
    return handleWithErrorMessage({
      request: () => api({ commit }).deleteFile(id),
      dispatch
    });
  },
  async [DOWNLOAD_FILE]({ dispatch, commit }, id) {
    return handleWithErrorMessage({
      request: () => api({ commit }).downloadFile(id),
      dispatch
    });
  }
};

export default filessActions;
