<template>
  <GenerateDialog
    :isOpen="isOpen"
    :isLoading="isLoading"
    @close="close"
    title="Generate Payroll"
    :onSubmit="handleSubmit"
  />
</template>

<script>
import { GENERATE_PAYROLL_ITEM } from "@/store/modules/invoices/action-types";
import { createNamespacedHelpers } from "vuex";
import { INVOICE_NAMESPACE } from "@/store/modules/invoices";
import GenerateDialog from "@/views/finance/components/Generate/components/GenerateDialog";

const { mapActions } = createNamespacedHelpers(INVOICE_NAMESPACE);

export default {
  name: "GeneratePayroll",
  components: { GenerateDialog },
  props: {
    isOpen: Boolean
  },
  data() {
    return {
      isLoading: false
    };
  },
  methods: {
    ...mapActions({
      generatePayroll: GENERATE_PAYROLL_ITEM
    }),
    close() {
      this.$emit("close");
    },
    async handleSubmit(formValue) {
      this.isLoading = true;
      try {
        await this.generatePayroll(formValue);
        this.close();
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style scoped lang="scss"></style>
