<template>
  <BaseAction>
    <ResendInvitationDialog
      v-if="isDialogOpen"
      @close="isDialogOpen = false"
      @resent="onResent"
      :user="user"
    />
    <PrimaryButton
      @click.native.stop="isDialogOpen = true"
      class="resend-button"
      text
      dense
    >
      Resend Invite
      <v-icon class="ml-2">
        mdi-send
      </v-icon>
    </PrimaryButton>
    <InvitationSentDialog
      v-if="isDialogSentOpen"
      @close="onDoneEmailLink"
      :link="link"
    />
  </BaseAction>
</template>

<script>
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import ResendInvitationDialog from "@/views/settings/Organisation/Users/dialogs/ResendInvitationDialog";
import BaseAction from "@/components/common/BaseAction";
import InvitationSentDialog from "@/views/settings/Organisation/Users/dialogs/InvitationSentDialog.vue";

export default {
  name: "ResendInvitation",
  components: {
    InvitationSentDialog,
    ResendInvitationDialog,
    PrimaryButton,
    BaseAction
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isDialogOpen: false,
      isDialogSentOpen: false,
      link: ""
    };
  },
  methods: {
    onResent(link) {
      this.link = link;
      this.isDialogSentOpen = true;
    },
    onDoneEmailLink() {
      this.isDialogSentOpen = false;
      this.$emit("resent");
    }
  }
};
</script>

<style lang="scss">
button.base-button.base-primary-button.v-btn.v-btn--flat.v-btn--text.primary--text.resend-button {
  padding: 0 5px !important;
}
</style>
