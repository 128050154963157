<template>
  <div class="compliance__details">
    <div class="compliance__details__label">{{ label }}</div>
    <div class="compliance__details__value">
      <ShowHideText>{{ compliance.documentReferenceNumber }}</ShowHideText>
    </div>
  </div>
</template>

<script>
import ShowHideText from "@/components/common/ShowHideText";

export default {
  components: {
    ShowHideText
  },
  props: {
    /**
     *  Compliance object
     * @example
     * {
     *  label: String
     *  checked: Boolean
     * }
     */
    compliance: {
      type: Object,
      required: true
    },
    label: String
  }
};
</script>
