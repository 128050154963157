<template>
  <v-dialog :value="isOpen" @input="handleChange" width="500" persistent>
    <v-card>
      <v-card-title class="h2 base-dialog-title" primary-title>
        <slot name="title" />
      </v-card-title>
      <slot name="body" />
      <v-card-actions>
        <span class="base-dialog-actions">
          <slot name="actions" />
        </span>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "BaseDialog",
  props: {
    isOpen: Boolean,
    width: {
      type: Number,
      required: false,
      default: 500
    }
  },
  methods: {
    handleChange(value) {
      this.$emit("updateDialog", value);
    }
  }
};
</script>

<style lang="scss">
.v-dialog > .v-card > .v-card__actions {
  padding: 0px;
}

.v-dialog.v-dialog--fullscreen > .v-card > .v-card__actions {
  padding: 15px;
}

.v-dialog > .v-card > .v-card__title {
  margin-bottom: 5px;
}

.base-dialog-title {
  font-weight: bold !important;
  line-height: 1.8rem;
}
</style>
