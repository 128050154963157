export const PAY_RATE_TEMPLATE_MODEL = "template-pay-rate";

const payRateTemplateModel = {
  payRateName: "",
  yearsReferencesRequired: 0,
  amount: 0,
  currencyCode: "",
  awr: 0,
  isDefault: 0,
  templatePayRateItems: {
    jsonApi: "hasMany",
    type: "templatePayRateItems"
  },
  bookingTemplate: {
    jsonApi: "hasOne",
    type: "bookingTemplate"
  }
};

export default payRateTemplateModel;
