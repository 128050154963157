export const QUOTA_MODEL = "publishing-schedule-quota";
import { SHIFT_MODEL } from "@/models/shift-model";

const quotaModel = {
  booking_publishing_schedule_id: "",
  shift_id: "",
  worker_quota: "",
  num_workers_promised: "",
  workerQuota: "",
  workersPromised: "",
  publishingScheduleId: "",
  shiftId: "",
  user: {
    jsonApi: "hasOne",
    type: SHIFT_MODEL
  }
};

export default quotaModel;
