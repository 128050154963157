/* eslint-disable prettier/prettier */
export const GET_ORGANISATIONS = "GET_ORGANISATIONS";
export const GET_IS_FETCHING_ORGANISATIONS = "GET_IS_FETCHING_ORGANISATIONS";
export const GET_PUBLISHING_SCHEDULE_TEMPLATES = "GET_PUBLISHING_SCHEDULE_TEMPLATES";
export const GET_IS_FETCHING_SCHEDULE_TEMPLATES = "GET_IS_FETCHING_SCHEDULE_TEMPLATES";

const organisationsGetters = {
  [GET_ORGANISATIONS]: state => state.organisations,
  [GET_IS_FETCHING_ORGANISATIONS]: state => state.isOrganisationsLoading,
  [GET_IS_FETCHING_SCHEDULE_TEMPLATES]: state => state.isFetchingScheduleTemplates,
  [GET_PUBLISHING_SCHEDULE_TEMPLATES]: state => state.publishingScheduleTemplates
};

export default organisationsGetters;
