<template>
  <vc-calendar
    mode="range"
    is-expanded
    class="mobile-calendar"
    :attributes="calendarShifts"
    :from-page="firstShiftDate"
    :locale="{ firstDayOfWeek: 2, masks: { weekdays: 'WW' } }"
  />
</template>

<script>
export default {
  name: "ShiftStepMobileCalendar",
  computed: {
    calendarShifts() {
      return [
        {
          highlight: true,
          dates: this.shiftPatterns
        }
      ];
    }
  },
  props: {
    shiftPatterns: Array,
    firstShiftDate: Object
  }
};
</script>

<style lang="scss"></style>
