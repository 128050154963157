<template>
  <BaseDialog :isOpen="isOpen">
    <template v-slot:title>
      Create Worker
    </template>
    <template v-slot:body>
      <ValidationObserver ref="quotaForm">
        <div>
          <ValidationProvider
            v-slot="{ errors }"
            name="firstName"
            rules="required"
          >
            <Select
              v-model="worker.organisation.id"
              label="Agency"
              item-text="name"
              item-value="id"
              :loading="isFetching"
              :error-messages="getErrorMessages(errors)"
              :items="agencies"
            />
          </ValidationProvider>
          <v-text-field
            v-model="worker.agency_worker_reference"
            label="Unique ID (optional)"
            placeholder=""
          />
          <v-text-field
            v-model="worker.client_worker_reference"
            label="Unique ID 2 (optional)"
            placeholder=""
          />
          <ValidationProvider
            v-slot="{ errors }"
            name="firstName"
            rules="required"
          >
            <v-text-field
              v-model="worker.firstName"
              label="First Name"
              placeholder=""
              :error-messages="getErrorMessages(errors)"
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="lastName"
            rules="required"
          >
            <v-text-field
              v-model="worker.lastName"
              label="Last Name"
              placeholder=""
              :error-messages="getErrorMessages(errors)"
            />
          </ValidationProvider>
        </div>
      </ValidationObserver>
    </template>
    <template v-slot:actions>
      <PrimaryButton @click.native="close" text :disabled="isLoading">
        Cancel
      </PrimaryButton>
      <PrimaryButton
        @click.native="createWorker"
        :loading="isLoading || isFetching"
      >
        Add Worker
      </PrimaryButton>
    </template>
  </BaseDialog>
</template>

<script>
import BaseDialog from "@/components/common/BaseDialog";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Select from "@/components/common/Select";
import { AGENCY_NAMESPACE } from "@/store/modules/agencies";
import { FETCH_ALL_AGENCIES } from "@/store/modules/agencies/action-types";
import { createNamespacedHelpers } from "vuex";
import { isEmpty } from "lodash";
import { ADD_NEW_WORKER } from "@/store/modules/workers/action-types";
import { WORKERS_NAMESPACE } from "@/store/modules/workers/workers";

const { mapState, mapActions } = createNamespacedHelpers(AGENCY_NAMESPACE);
const { mapActions: mapWorkerActions } = createNamespacedHelpers(
  WORKERS_NAMESPACE
);

export default {
  components: {
    PrimaryButton,
    BaseDialog,
    ValidationObserver,
    ValidationProvider,
    Select
  },
  props: {
    isOpen: Boolean,
    agencyId: String
  },
  created() {
    if (isEmpty(this.agencies)) {
      this.fetchAgencies({ "filter[organisation_type]": "agency" });
    }
  },
  data() {
    return {
      showValidationErrors: false,
      isLoading: false,
      worker: {
        firstName: "",
        lastName: "",
        organisation: { id: this.agencyId },
        status: "active"
      }
    };
  },
  computed: {
    ...mapState({
      agencies: state => state.agencies,
      isFetching: state => state.isFetchingData
    })
  },
  methods: {
    ...mapActions({
      fetchAgencies: FETCH_ALL_AGENCIES
    }),
    ...mapWorkerActions({
      addNewWorker: ADD_NEW_WORKER
    }),
    close() {
      this.$emit("close");
    },
    getErrorMessages(errors) {
      return this.showValidationErrors ? errors : "";
    },
    async createWorker() {
      this.isLoading = true;
      try {
        const newWorker = await this.addNewWorker(this.worker);
        this.$emit("add", newWorker);
        this.close();
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>
