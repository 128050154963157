<template>
  <v-skeleton-loader type="table-thead, table-tbody, table-tfoot" loading />
</template>

<script>
export default {
  name: "ReportLoader"
};
</script>

<style scoped lang="scss"></style>
