/* eslint-disable prettier/prettier */
import { setStoreValue } from '@/store/utils';

export const SET_IS_FETCHING_SHIFT_CANDIDATES = "SET_IS_FETCHING_SHIFT_CANDIDATES";
export const SET_SHIFT_CANDIDATES = 'SET_SHIFT_CANDIDATES';
export const SET_IS_FETCHING_AVAILABLE_CANDIDATES = 'SET_IS_FETCHING_AVAILABLE_CANDIDATES';
export const SET_AVAILABLE_CANDIDATES = 'SET_AVAILABLE_CANDIDATES';
export const SET_IS_FETCHING_APPLICATION_SHIFTS = 'SET_IS_FETCHING_APPLICATION_SHIFTS';
export const SET_APPLICATION_SHIFTS = 'SET_APPLICATION_SHIFTS';
export const SET_APPLICATION_TO_REVIEW = 'SET_APPLICATION_TO_REVIEW';
export const SET_IS_FETCHING_APPLICATIONS = 'SET_IS_FETCHING_APPLICATIONS';
export const SET_UNAVAILABLE_CANDIDATES = 'SET_UNAVAILABLE_CANDIDATES';
export const SET_IS_FETCHING_UNAVAILABLE_CANDIDATES = 'SET_IS_FETCHING_UNAVAILABLE_CANDIDATES';
export const SET_UNAVAILABLE_WORKERS = 'SET_UNAVAILABLE_WORKERS';
export const SET_IS_FETCHING_UNAVAILABLE_WORKERS = 'SET_IS_FETCHING_UNAVAILABLE_WORKERS';

const applicationsMutations = {
  [SET_IS_FETCHING_SHIFT_CANDIDATES]: setStoreValue("isFetchingShiftCandidates"),
  [SET_SHIFT_CANDIDATES]: setStoreValue("shiftCandidates"),
  [SET_IS_FETCHING_AVAILABLE_CANDIDATES]: setStoreValue("isFetchingAvailableCandidates"),
  [SET_AVAILABLE_CANDIDATES]: setStoreValue("availableCandidates"),
  [SET_IS_FETCHING_APPLICATION_SHIFTS]: setStoreValue("isFetchingApplicationShifts"),
  [SET_APPLICATION_SHIFTS]: setStoreValue("applicationShifts"),
  [SET_APPLICATION_TO_REVIEW]: setStoreValue("applicationsToReview"),
  [SET_IS_FETCHING_APPLICATIONS]: setStoreValue("isFetchingApplications"),
  [SET_IS_FETCHING_UNAVAILABLE_CANDIDATES]: setStoreValue("isFetchingUnavailableCandidates"),
  [SET_UNAVAILABLE_CANDIDATES]: setStoreValue("unavailableCandidates"),
  [SET_IS_FETCHING_UNAVAILABLE_WORKERS]: setStoreValue("isFetchingUnavailableWorkers"),
  [SET_UNAVAILABLE_WORKERS]: setStoreValue("unavailableWorkers"),
};

export default applicationsMutations;
