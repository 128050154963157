export const SET_WORKER_APPLICATIONS = "SET_WORKER_APPLICATIONS";
export const SET_WORKER_TIMESHEETS = "SET_WORKER_TIMESHEETS";
export const SET_IS_FETCHING_DATA = "SET_FETCHING_DATA";
export const SET_IS_FETCHING_APPLICATIONS = "SET_FETCHING_APPLICATIONS";
export const SET_IS_FETCHING_TIMESHEETS = "SET_FETCHING_TIMESHEETS";
export const SET_ERROR = "SET_ERROR";
export const SET_WORKERS = "SET_WORKERS";
export const SET_WORKER = "SET_WORKER";
export const SET_NEW_WORKER = "SET_NEW_WORKER";
export const SET_INTERMEDIARY_COMPANY = "SET_INTERMEDIARY_COMPANY";
export const SET_WORKER_COMPLIANCE_FILE = "SET_WORKER_COMPLIANCE_FILE";
export const SET_WORKER_ID = "SET_WORKER_ID";
export const CLEAR_INTERMEDIARY_COMPANY = "CLEAR_INTERMEDIARY_COMPANY";
export const CLEAR_CURRENT_WORKER = "CLEAR_CURRENT_WORKER";
export const SET_WORKER_FILTERS = "SET_WORKER_FILTERS";

// external mutation types
export const NS_SET_ERROR = "workers/SET_ERROR";
