<template>
  <div>
    <div class="expense-item-editable">
      <div class="expense-item-wrapper">
        <v-row no-gutters class="expense">
          <v-col class="col-12">
            <ValidationProvider
              v-slot="{ errors }"
              name="description"
              rules="required"
            >
              <v-text-field
                :readonly="showLoader"
                :error-messages="showValidationErrors ? errors : ''"
                name="description"
                label="Description"
                v-model="description"
              />
            </ValidationProvider>
          </v-col>
          <v-col class="col-12">
            <ValidationProvider
              v-slot="{ errors }"
              name="expenseAmount"
              rules="required"
            >
              <v-text-field
                :disabled="showLoader"
                name="expenseAmount"
                :min="0"
                :error-messages="showValidationErrors ? errors : ''"
                label="Amount"
                type="number"
                class="currency"
                v-model="expenseAmount"
              />
            </ValidationProvider>
          </v-col>
          <v-col class="col-12">
            <v-label>File</v-label>
            <FileUpload
              :isLoading="showLoader"
              :uploadingUrl="getAddFileUrl()"
              :fileTypes="['.pdf', '.jpeg', '.jpg', '.png']"
              customActions
              disableAutoProceed
              @onUploadSuccess="onUpload"
              @selectFile="selectFile"
              :showValidationErrors="showValidationErrors"
              @unselectFile="unselectFile"
              @show="onShow"
              class="mt-2"
              :id="data.id"
              :files="files"
            />
          </v-col>
        </v-row>
      </div>
    </div>
    <PDFViewFull :data="fileDialog" />
  </div>
</template>

<script>
import FileUpload from "@/components/common/FileUpload";
import { ValidationProvider } from "vee-validate";
import { createNamespacedHelpers } from "vuex";
import { TIMESHEETS_NAMESPACE } from "@/store/modules/timesheets";
import {
  ADD_EXPENSE,
  UPDATE_EXPENSES,
  EDIT_EXPENSE,
  REMOVE_EXPENSE_FILE,
  REMOVE_EXPENSE
} from "@/store/modules/timesheets/actions";
import { POST_GLOBAL_MESSAGE } from "@/store/modules/global/action-types";
import PDFViewFull from "@/components/common/PDFViewFull";
import APIService from "@/services/APIService";
import { size, first, map } from "lodash";
import { mapActions } from "vuex";
import { getToken } from "@/services/utils";
import { getMonetaryValue, getAmountInApiFormat } from "@/utils/helpers";

const {
  mapActions: mapTimesheetActions,
  mapState: mapTimesheetState
} = createNamespacedHelpers(TIMESHEETS_NAMESPACE);

export default {
  name: "ExpenseItemEditable",
  components: {
    FileUpload,
    ValidationProvider,
    PDFViewFull
  },
  computed: {
    ...mapTimesheetState({
      storedExpenses: state => state.timesheetExpenses
    }),
    currentTimesheetId() {
      return this.$route.params.id;
    },
    showLoader() {
      return this.isLoading || this.isAdding || this.isRemoving;
    },
    showRemoveButton() {
      return !this.isRemoving && !this.isEmpty && !this.isLoading;
    },
    currentExpense() {
      return {
        description: this.description,
        expenseAmount: getAmountInApiFormat(this.expenseAmount),
        currencyCode: this.currencyCode
      };
    }
  },
  data() {
    return {
      description: this.data.description,
      expenseAmount: getMonetaryValue(this.data.expenseAmount),
      currencyCode: this.data.currencyCode,
      isLoading: false,
      isAdding: false,
      files: this.data.file ? [this.data.file] : [],
      fileDialog: {
        title: "",
        dialog: false,
        src: ""
      },
      uppyInstance: null,
      currentFile: null
    };
  },
  methods: {
    ...mapActions({
      postGlobalMessage: POST_GLOBAL_MESSAGE
    }),
    ...mapTimesheetActions({
      addExpense: ADD_EXPENSE,
      updateExpenses: UPDATE_EXPENSES,
      editExpense: EDIT_EXPENSE,
      removeFile: REMOVE_EXPENSE_FILE,
      removeExpense: REMOVE_EXPENSE
    }),
    async edit() {
      if (size(this.files) || this.currentFile) {
        let newFile = first(this.files);
        this.isLoading = true;
        this.$emit("isLoading", true);
        try {
          const { data: updatedExpense } = await this.editExpense({
            expense: this.currentExpense,
            id: this.data.id
          });
          if (this.currentFile) {
            const fileResponse = await this.uploadFile(updatedExpense.id);
            if (size(fileResponse.successful)) {
              newFile = first(fileResponse.successful).response.body.data;
            }
          }
          this.updateExpensesList(updatedExpense, newFile);
        } finally {
          this.isLoading = false;
          this.$emit("isLoading", false);
        }
      }
    },
    async updateExpensesList(updatedExpense, newFile) {
      const updatedExpenses = map(this.storedExpenses, expense =>
        expense.id !== updatedExpense.id
          ? expense
          : { ...updatedExpense, file: newFile }
      );
      this.updateExpenses([...updatedExpenses]);
    },
    async uploadFile(id) {
      this.uppyInstance.setFileState(this.currentFile.id, {
        xhrUpload: { endpoint: this.getAddFileUrl(id) }
      });
      return this.uppyInstance.upload(id);
    },
    selectFile({ uppyInstance, file }) {
      this.uppyInstance = uppyInstance;
      this.currentFile = file;
    },
    unselectFile({ uppyInstance }) {
      this.currentFile = null;
      this.files = [];
      uppyInstance.reset();
    },
    onUpload(allFiles) {
      this.allFiles = allFiles;
    },
    onShow(file) {
      const token = getToken();
      this.fileDialog.src = `${file.url}?token=${token}`;
      this.fileDialog.title = file.fileName;
      this.fileDialog.dialog = true;
    },
    async add() {
      if (this.currentFile) {
        this.$emit("isLoading", true);
        this.isAdding = true;
        try {
          const { data: addedExpense } = await this.addExpense({
            ...this.currentExpense,
            timesheet: { id: this.currentTimesheetId }
          });
          const fileResponse = await this.uploadFile(addedExpense.id);
          this.onAddExepenseFinished(fileResponse, addedExpense);
        } finally {
          this.isAdding = false;
          this.$emit("isLoading", false);
        }
      }
    },
    onAddExepenseFinished(fileResponse, addedExpense) {
      if (size(fileResponse.failed)) {
        this.removeExpense({
          id: addedExpense.id,
          showSucccessMessage: false
        });
        throw first(fileResponse.failed);
      } else {
        const file = first(fileResponse.successful).response.body.data;
        this.updateExpenses([
          ...this.storedExpenses,
          { ...addedExpense, file: file }
        ]);
        this.postSuccesMessage();
      }
    },
    getAddFileUrl(expenseId) {
      const host = APIService.host;
      return `${host}/timesheets/${
        this.currentTimesheetId
      }/expenses/${expenseId || this.data.id}/file`;
    },
    postSuccesMessage() {
      const message = {
        snackbar: true,
        type: "success",
        title: `The expense has been successfully added`
      };
      this.postGlobalMessage(message);
    }
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    isEmpty: Boolean,
    isRemoving: Boolean,
    showValidationErrors: Boolean
  }
};
</script>

<style lang="scss">
.expense-item-editable {
  margin-bottom: 70px;

  .expense-item-wrapper {
    align-items: center;
    display: flex;

    .expense {
      .details {
        display: flex;
        align-items: center;
        margin-right: 50px;
      }
      .col {
        padding: 0 0.2rem;
      }

      & > .remove-button {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        i {
          margin: 0 1rem;
        }

        @media (min-width: $mobile-breakpoint) {
          flex-direction: column;
          justify-content: center;
        }
      }

      hr {
        margin: 1rem 0 0.5rem;
      }
    }
  }
}
</style>
