<template>
  <ValidationObserver ref="generate">
    <BaseDialog :isOpen="isOpen" @close="close">
      <template v-slot:title>
        <span>{{ title }}</span>
      </template>
      <template v-slot:body>
        <div class="flex-gap-15 mt-3 mb-3">
          <ValidationProvider v-slot="{ errors }" rules="required">
            <Select
              :error-messages="getErrorMessages(errors)"
              :items="organisationUnits"
              label="Organisation"
              placeholder="Select Organisation"
              :value="form.organisationUnitId"
              item-text="name"
              item-value="id"
              @change="value => set('organisationUnitId', value.id)"
              return-object
              clearable
            />
          </ValidationProvider>
          <Select
            v-if="isInvoice"
            :items="invoiceTypes"
            label="Invoice Type"
            placeholder="Select type"
            :value="form.invoiceType"
            item-text="label"
            item-value="value"
            @change="type => set('invoiceType', type.value)"
            return-object
          />
          <DatePicker
            class="mt-2"
            :showValidationErrors="showValidationErrors"
            :label="dateLabel"
            @updatedDate="value => set('periodEndDate', value)"
            required
          />
        </div>
      </template>
      <template v-slot:actions>
        <div class="generate-button">
          <PrimaryButton @click.native="close" text>
            Cancel
          </PrimaryButton>
          <PrimaryButton :loading="isLoading" @click.native="handleSubmit">
            {{ title }}
          </PrimaryButton>
        </div>
      </template>
    </BaseDialog>
  </ValidationObserver>
</template>

<script>
import DatePicker from "@/components/common/DatePicker";
import BaseDialog from "@/components/common/BaseDialog";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Select from "@/components/common/Select";
import { AUTH_NAMESPACE } from "@/store/modules/auth";
import { createNamespacedHelpers } from "vuex";
import { FETCH_ROOT_ORGANISATIONS } from "@/store/modules/organisations/actions";
import { ORGANISATIONS_NAMESPACE } from "@/store/modules/organisations";
import { INVOICE_TYPES } from "@/constants/invoices";

const { mapState: authMapState } = createNamespacedHelpers(AUTH_NAMESPACE);
const {
  mapState: orgMapState,
  mapActions: orgMapActions
} = createNamespacedHelpers(ORGANISATIONS_NAMESPACE);
const INIT_FORM_DATA = {
  organisationUnitId: "",
  invoiceType: "invoice",
  periodEndDate: ""
};

export default {
  name: "GenerateInvoices",
  components: {
    PrimaryButton,
    BaseDialog,
    DatePicker,
    ValidationObserver,
    ValidationProvider,
    Select
  },
  props: {
    title: String,
    isOpen: Boolean,
    onSubmit: {
      type: Function,
      required: true
    },
    isLoading: Boolean,
    isInvoice: Boolean
  },
  mounted() {
    const filter = {
      organisation_type: this.organisationType,
      is_root: true
    };
    this.fetchRootOrganisations({ filter });
  },
  computed: {
    ...authMapState({
      user: state => state.userData
    }),
    ...orgMapState({
      rootClientOrganisations: state => state.rootClientOrganisations,
      rootAgencyOrganisations: state => state.rootAgencyOrganisations
    }),
    dialogSettings() {
      return {
        title: `Generate ${this.title}`,
        dialog: this.isOpen
      };
    },
    organisationUnits() {
      return this.isInvoice
        ? this.rootClientOrganisations
        : this.rootAgencyOrganisations;
    },
    dateLabel() {
      return `${this.title} Period End Date`;
    },
    organisationType() {
      return this.isInvoice ? "client" : "agency";
    }
  },
  data() {
    return {
      showValidationErrors: false,
      form: Object.assign({}, INIT_FORM_DATA),
      invoiceTypes: INVOICE_TYPES
    };
  },
  methods: {
    ...orgMapActions({
      fetchRootOrganisations: FETCH_ROOT_ORGANISATIONS
    }),
    getErrorMessages(errors) {
      return this.showValidationErrors ? errors : "";
    },
    close() {
      this.form = Object.assign({}, INIT_FORM_DATA);
      this.$refs.generate.reset();
      this.$emit("close");
    },
    async handleSubmit() {
      this.showValidationErrors = true;
      const isFormValid = await this.$refs.generate.validate();
      if (isFormValid) {
        this.onSubmit(this.form);
      }
    },
    set(fieldName, value) {
      this.form[fieldName] = value;
    }
  }
};
</script>

<style scoped lang="scss">
.generate-button {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media only screen and (max-width: $mobile-breakpoint - 1) {
    justify-content: center;
  }
}
</style>
