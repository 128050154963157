export const APPLICATION_SHIFT_MODEL = "application-shift";

const applicationShiftModel = {
  status: "",
  shift: {
    jsonApi: "hasOne",
    type: "shift"
  },
  application: {
    jsonApi: "hasOne",
    type: "application"
  }
};

export default applicationShiftModel;
