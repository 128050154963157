<template>
  <BaseDialog :isOpen="isOpen">
    <template v-slot:title>
      Are you sure you want to reopen the booking?
    </template>
    <template v-slot:body>
      <div class="accept-booking-description">
        Reopening the booking will become live.
      </div>
    </template>
    <template v-slot:actions>
      <div class="accept-booking-actions">
        <PrimaryButton @click.native="cancel" text>
          Cancel
        </PrimaryButton>
        <PrimaryButton @click.native="handleSubmit" :loading="isLoading">
          Reopen Booking
        </PrimaryButton>
      </div>
    </template>
  </BaseDialog>
</template>

<script>
import BaseDialog from "@/components/common/BaseDialog";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import { createNamespacedHelpers } from "vuex";
import {
  REOPEN_BOOKING,
  BOOKINGS_NAMESPACE
} from "@/store/modules/bookings/actions";

const { mapActions } = createNamespacedHelpers(BOOKINGS_NAMESPACE);
export default {
  name: "ReopenBookingDialog",
  components: { PrimaryButton, BaseDialog },
  props: {
    isOpen: Boolean
  },
  data() {
    return {
      isLoading: false
    };
  },
  inject: ["currentBookingId"],
  methods: {
    ...mapActions({
      reopenBooking: REOPEN_BOOKING
    }),
    async handleSubmit() {
      this.isLoading = true;
      await this.reopenBooking(this.currentBookingId);
      this.$emit("close");
      this.isLoading = false;
    },
    cancel() {
      this.$emit("close");
    }
  }
};
</script>
