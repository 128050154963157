<template>
  <div>
    <PrimaryButton light dense shrink @click.native="openDialog">
      Edit
      <template v-slot:append:icon>
        <v-icon>mdi-pencil</v-icon>
      </template>
    </PrimaryButton>
    <EditQuotaDialog
      v-if="isOpen"
      :bookingId="bookingId"
      :publishingSchedules="publishingSchedules"
      :isOpen="isOpen"
      @close="closeDialog"
      @update="$emit('update')"
    />
  </div>
</template>

<script>
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import EditQuotaDialog from "../dialogs/EditQuotaDialog";

export default {
  props: {
    bookingId: String,
    publishingSchedules: Array
  },
  components: {
    PrimaryButton,
    EditQuotaDialog
  },
  data() {
    return {
      isOpen: false
    };
  },
  methods: {
    openDialog() {
      this.isOpen = true;
    },
    closeDialog() {
      this.isOpen = false;
    }
  }
};
</script>
