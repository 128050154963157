<template>
  <div class="select">
    <label v-if="label">{{ label }}</label>
    <v-autocomplete
      class="select--items"
      :class="classContent"
      ref="select"
      v-if="autocomplete"
      v-model="inputValue"
      @change="handleChange"
      @update:search-input="handleSearch"
      @blur="handleBlur"
      label=""
      :placeholder="placeholder"
      :items="items"
      :item-text="itemText"
      :item-value="itemVal"
      :multiple="multiple"
      :chips="chips"
      :clearable="clearable"
      :menuProps="menuProps"
      :disabled="disabled"
      :hide-details="hideDetails"
      :rules="rules"
      :return-object="returnObject"
      :error-messages="errorMessages"
      :value-comparator="valueComparator"
      :value="value"
      :height="height"
      full-width
      flat
      :loading="loading"
      :appendIcon="appendIcon"
      :search-input.sync="searchKey"
    >
      <template v-slot:prepend>
        <PrimaryButton text @click.native="closeSelect">Done</PrimaryButton>
      </template>
      <template v-slot:selection="data" v-if="hasSlot('selection')">
        <slot name="selection" :data="data" />
      </template>
      <template v-slot:item="{ item }" v-if="hasSlot('item')">
        <slot name="item" :item="item" />
      </template>
    </v-autocomplete>
    <v-select
      :loading="loading"
      class="select--items"
      :class="classContent"
      ref="select"
      v-else
      v-model="inputValue"
      @change="handleChange"
      label=""
      :placeholder="placeholder"
      :items="items"
      :item-text="itemText"
      :item-value="itemVal"
      :multiple="multiple"
      :chips="chips"
      :clearable="clearable"
      :menuProps="menuProps"
      :disabled="disabled"
      :hide-details="hideDetails"
      :rules="rules"
      :single-line="singleLine"
      :return-object="returnObject"
      :error-messages="errorMessages"
      :value-comparator="valueComparator"
      :value="value"
      full-width
      :appendIcon="appendIcon"
    >
      <template v-slot:prepend>
        <PrimaryButton text @click.native="closeSelect">Done</PrimaryButton>
      </template>
      <template v-slot:selection="data" v-if="hasSlot('selection')">
        <slot name="selection" :data="data" />
      </template>
      <template v-slot:item="{ item }" v-if="hasSlot('item')">
        <slot name="item" :item="item" />
      </template>
    </v-select>
  </div>
</template>

<script>
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import { delay } from "lodash";

export default {
  props: {
    /**
     *  Items array
     */
    items: {
      type: Array,
      required: true
    },
    /**
     *  String to customize item value
     */
    value: [String, Object, Number, Array, Boolean, Date],
    /**
     *  Boolean for autocomplete
     */
    autocomplete: {
      type: Boolean,
      default: false
    },
    /**
     *  Boolean to set multiple select
     */
    multiple: {
      type: Boolean,
      default: false
    },
    /**
     *  Boolean to set clearable select
     */
    clearable: {
      type: Boolean,
      default: false
    },
    /**
     *  Boolean to set item to chips
     */
    chips: {
      type: Boolean,
      default: false
    },
    /**
     *  Boolean to disable
     */
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     *  Boolean to Single line
     */
    singleLine: {
      type: Boolean,
      default: false
    },
    /**
     *  Boolean to Return object
     */
    returnObject: {
      type: Boolean,
      default: false
    },
    /**
     *  String for label
     */
    label: {
      type: String
    },
    /**
     *  String to customize item text
     */
    itemText: {
      type: [String, Array],
      default: null
    },
    /**
     *  String to customize item value
     */
    itemValue: {
      type: String,
      default: null
    },
    classContent: {
      type: String,
      default: undefined
    },
    valueComparator: {
      type: Function
    },
    loading: Boolean,
    menuProps: {
      type: [Object, Array, String],
      required: false,
      default: () => {
        return {
          closeOnClick: false,
          closeOnContentClick: false,
          disableKeys: true,
          openOnClick: false,
          maxHeight: 304
        };
      }
    },
    hideDetails: {
      type: Boolean || String,
      default: undefined
    },
    rules: Array,
    errorMessages: [String, Array],
    hideSlots: Array,
    placeholder: String,
    height: String,
    appendIcon: {
      type: String,
      default: "$dropdown"
    },
    shouldClearSearch: Boolean
  },
  components: {
    PrimaryButton
  },
  data: () => ({
    itemData: null,
    searchKey: "",
    timer: null
  }),
  methods: {
    handleChange() {
      this.$emit("change", this.itemData);
      this.searchKey = "";
      if (!this.multiple) {
        this.$refs.select.blur();
      }
    },
    closeSelect() {
      this.$refs.select.blur();
    },
    hasSlot(name = "default") {
      return !!this.$slots[name] || !!this.$scopedSlots[name];
    },
    handleSearch(key) {
      this.searchKey = key;
      clearTimeout(this.timer);
      this.timer = delay(
        () => this.$emit("search", this.searchKey),
        1000,
        "later"
      );
    },
    handleBlur() {
      if (this.shouldClearSearch) this.searchKey = "";
    }
  },
  computed: {
    itemVal() {
      return this.itemValue || null;
    },
    inputValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.itemData = value;
        this.$emit("input", value);
      }
    }
  }
};
</script>

<style lang="scss"></style>
