import Vue from "vue";
import Vuex from "vuex";
import invoices from "./modules/invoices";
import layout from "./modules/layout";
import settings from "./modules/settings";
import workers from "./modules/workers";
import agencies from "./modules/agencies";
import bookings from "@/store/modules/bookings/bookings";
import global from "@/store/modules/global/global";
import applications from "@/store/modules/applications";
import timesheets from "@/store/modules/timesheets";
import dashboard from "@/store/modules/dashboard";
import data from "@/store/modules/data";
import auth from "@/store/modules/auth";
import users from "@/store/modules/users";
import organisations from "@/store/modules/organisations";
import addresses from "@/store/modules/addresses";
import reports from "@/store/modules/reports";
import exports from "@/store/modules/exports";
import { isNotTestingEnv } from "@/services/utils";
import files from "@/store/modules/files";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";
let store;
if (isNotTestingEnv()) {
  store = new Vuex.Store({
    strict: debug,
    modules: {
      settings,
      invoices,
      layout,
      workers,
      agencies,
      bookings,
      global,
      applications,
      timesheets,
      dashboard,
      data,
      auth,
      users,
      organisations,
      addresses,
      reports,
      files,
      exports
    }
  });
}

export default store;
