import Vue from "vue";
import Vuetify from "vuetify/lib";
import styles from "../sass/abstracts/_variables.scss";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: styles.primary,
        error: styles.error,
        success: styles.success
      }
    }
  }
});
