<template>
  <v-list-item-avatar :style="style" :size="size" tile>
    {{ initials }}
  </v-list-item-avatar>
</template>

<script>
export default {
  props: {
    initials: String,
    size: {
      type: String,
      default: "45"
    }
  },
  computed: {
    style() {
      const textColor = process.env.VUE_APP_AVATAR_TEXT_COLOR;
      const backgroundColor = process.env.VUE_APP_AVATAR_BG_COLOR;
      const useDefault = !textColor || !backgroundColor;

      return {
        color: `${useDefault ? "#fff" : textColor} !important`,
        background: `${useDefault ? "#0965dc" : backgroundColor} !important`,
        "border-color": `${
          useDefault ? "#0965dc" : backgroundColor
        } !important`,
        justifyContent: "center"
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.v-avatar.v-list-item__avatar.v-avatar--tile {
  border-radius: 4px !important;
  font-weight: bold;
  margin: 10px;
  margin-left: 0;
  color: #fff !important;
}

.v-avatar.v-list-item__avatar.v-avatar--tile.light {
  color: $primary-darker !important;
  background: rgba($primary, 0.1) !important;
  border-color: $primary-darker !important;
}
</style>
