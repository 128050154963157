import { render, staticRenderFns } from "./OrganisationUnitItem.vue?vue&type=template&id=513cc8d2&scoped=true&"
import script from "./OrganisationUnitItem.vue?vue&type=script&lang=js&"
export * from "./OrganisationUnitItem.vue?vue&type=script&lang=js&"
import style0 from "./OrganisationUnitItem.vue?vue&type=style&index=0&id=513cc8d2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "513cc8d2",
  null
  
)

export default component.exports