<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on }">
      <v-btn color="primary" large dark v-on="on">
        {{ label }}
        <v-icon>mdi-chevron-down</v-icon>
      </v-btn>
    </template>
    <MenuList :items="items" @select="handleSelect" />
  </v-menu>
</template>

<script>
import MenuList from "./MenuList";

export default {
  name: "PrimaryMenu",
  components: { MenuList },
  props: {
    label: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true
    }
  },
  methods: {
    handleSelect(action) {
      this.$emit("select", action);
    }
  }
};
</script>

<style scoped></style>
