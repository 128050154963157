<template>
  <v-container class="submit-workers-dialog col-12 col-md-8">
    <h1 class="workers-title">Workers</h1>
    <p class="description">
      Select workers to submit for the booking
    </p>
    <WorkersSelection
      appendIcon=""
      :showValidationErrors="showValidationErrors"
      :selectedWorkers="selectedWorkersIds"
      @handleSelectedWorkers="setSelectedWorkers"
      @unselectWorker="unselectWorker"
      @search="handleSearch"
      :workers="isLoadingCandidates ? [] : workers"
      :is-loading="isLoadingCandidates || isFetchingUnavailableWorkers"
      :maxHeight="0"
    />
    <div class="select-workers-step mt-2">
      <div v-if="isLoadingCandidates || isFetchingUnavailableWorkers">
        <v-skeleton-loader v-for="i in 5" :key="i" type="list-item" />
      </div>
      <div class="overflow-auto" v-else>
        <div
          class="worker-wrapper"
          :class="{ 'not-allowed': worker.isUnavailable }"
          v-for="(worker, index) in formattedWorkers"
          :key="index"
          @click="handleSelectWorker(worker)"
        >
          <div class="d-flex align-center">
            <v-icon x-large class="icon-wrapper">
              {{ `mdi-account${worker.isUnavailable ? "-off-outline" : ""}` }}
            </v-icon>
            <div>
              <div class="font-weight-bold">
                {{ worker | fullName }}
              </div>
              <p class="secondary-text mb-0">
                {{ worker.formattedReason }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import WorkersSelection from "@/components/bookings/WorkersSelection";
import { createNamespacedHelpers } from "vuex";
import { filter, map, size, reduce, includes, lowerCase } from "lodash";
import {
  FETCH_AVAILABLE_CANDIDATES,
  FETCH_UNAVAILABLE_WORKERS
} from "@/store/modules/applications/actions";
import { APPLICATION_NAMESPACE } from "@/store/modules/applications";
import {
  GET_IS_FETCHING_AVAILABLE_CANDIDATES,
  GET_AVAILABLE_CANDIDATES,
  GET_UNAVAILABLE_WORKERS,
  GET_IS_FETCHING_UNAVAILABLE_WORKERS
} from "@/store/modules/applications/getters";
import { getFullName, getRequirements } from "@/utils/users";

const { mapActions: mapActions, mapGetters } = createNamespacedHelpers(
  APPLICATION_NAMESPACE
);
export default {
  name: "SubmitWorkersForBooking",
  components: {
    WorkersSelection
  },
  props: {
    showValidationErrors: Boolean
  },
  async created() {
    await this.fetchWorkersToSubmit(this.currentBookingId);
    this.updateFilteredWorkers();
  },
  data() {
    return {
      isLoading: false,
      selectedWorkersIds: [],
      searchKey: "",
      filteredWorkers: this.workers
    };
  },
  computed: {
    ...mapGetters({
      workers: GET_AVAILABLE_CANDIDATES,
      isLoadingCandidates: GET_IS_FETCHING_AVAILABLE_CANDIDATES,
      unavailableWorkers: GET_UNAVAILABLE_WORKERS,
      isFetchingUnavailableWorkers: GET_IS_FETCHING_UNAVAILABLE_WORKERS
    }),
    formattedUnavailableWorkers() {
      return map(this.unavailableWorkers, worker => ({
        ...worker,
        isUnavailable: true,
        formattedReason: getRequirements(worker),
        fullName: getFullName(worker)
      }));
    },
    formattedWorkers() {
      return this.searchKey
        ? [...this.filteredWorkers, ...this.formattedUnavailableWorkers]
        : this.filteredWorkers;
    }
  },
  inject: ["currentBookingId"],
  methods: {
    ...mapActions({
      fetchWorkersToSubmit: FETCH_AVAILABLE_CANDIDATES,
      fetchUnavailableWorkers: FETCH_UNAVAILABLE_WORKERS
    }),
    setSelectedWorkers(selectedWorkersIds) {
      this.selectedWorkersIds = selectedWorkersIds;
      this.$emit("onChange", this.selectedWorkersIds);
    },
    unselectWorker(selectedWorker) {
      this.selectedWorkersIds = filter(
        this.selectedWorkersIds,
        id => id !== selectedWorker.id
      );
      this.updateFilteredWorkers();
      this.$emit("onChange", this.selectedWorkersIds);
    },
    handleSearch(search) {
      this.searchKey = search;
      if (size(search) > 1) {
        const params = {
          search,
          bookingId: this.currentBookingId
        };
        this.fetchUnavailableWorkers(params);
      }
      this.updateFilteredWorkers();
    },
    updateFilteredWorkers() {
      this.filteredWorkers = this.searchKey
        ? reduce(
            this.workers,
            (filteredWorkers, worker) => {
              const formattedWorker = {
                ...worker,
                fullName: getFullName(worker)
              };
              if (
                includes(
                  lowerCase(formattedWorker.fullName),
                  lowerCase(this.searchKey)
                ) &&
                !includes(this.selectedWorkersIds, formattedWorker.id)
              ) {
                filteredWorkers.push(formattedWorker);
              }
              return filteredWorkers;
            },
            []
          )
        : filter(
            this.workers,
            worker => !includes(this.selectedWorkersIds, worker.id)
          );
    },
    handleSelectWorker(worker) {
      if (!worker.isUnavailable) {
        this.setSelectedWorkers([...this.selectedWorkersIds, worker.id]);
        this.updateFilteredWorkers();
      }
    }
  }
};
</script>
<style lang="scss">
.submit-workers-dialog {
  margin-top: 5rem;

  .workers-title {
    margin-bottom: 0.5rem;
  }
  .description {
    color: $secondary-text;
    font-size: 22px;
  }
}
</style>
