<template>
  <div>
    <DialogFull
      :data="dialogSettings"
      v-if="dialogSettings.dialog"
      @close="close"
    >
      <template v-slot:dialog.body>
        <BasicDetails
          ref="basicDetails"
          :bookingTemplate="bookingTemplate"
          @onSubmit="handleEdit"
        />
      </template>
      <template v-slot:dialog.action>
        <PrimaryButton
          :loading="isEditingBookingTemplate"
          @click.native="onValidate"
        >
          Save Changes
        </PrimaryButton>
      </template>
    </DialogFull>
    <Permissioned section="settings">
      <PrimaryButton
        permission="edit-booking-templates"
        @click.native="openDialog"
        light
        dense
        shrink
      >
        Edit
        <template v-slot:append:icon
          ><v-icon class="ml-1">mdi-pencil</v-icon></template
        >
      </PrimaryButton>
    </Permissioned>
  </div>
</template>

<script>
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import DialogFull from "@/components/common/DialogFull";
import Permissioned from "@/components/common/Permissioned";
import BasicDetails from "@/views/settings/Organisation/BookingTemplates/components/BasicDetails";
import { createNamespacedHelpers } from "vuex";
import { ORGANISATION_BOOKING_TEMPLATES_NAMESPACE } from "@/store/modules/organisations/organisation-booking-templates";
import { copyState } from "@/utils/helpers";
import { EDIT_ORGANISATION_BOOKING_TEMPLATE } from "@/store/modules/organisations/organisation-booking-templates/actions";

const { mapActions, mapState } = createNamespacedHelpers(
  ORGANISATION_BOOKING_TEMPLATES_NAMESPACE
);

export default {
  name: "EditBasicDetailsAction",
  components: { PrimaryButton, Permissioned, DialogFull, BasicDetails },
  props: {
    bookingTemplate: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialogSettings: {
        title: "Edit Booking Template",
        dialog: false
      },
      id: null
    };
  },
  computed: {
    ...mapState(copyState(["isEditingBookingTemplate"]))
  },
  methods: {
    ...mapActions({
      editBookingTemplate: EDIT_ORGANISATION_BOOKING_TEMPLATE
    }),
    openDialog() {
      this.dialogSettings.dialog = true;
    },
    close() {
      this.dialogSettings.dialog = false;
    },
    onValidate() {
      this.$refs.basicDetails.validateForm();
    },
    async handleEdit(data) {
      await this.editBookingTemplate(data);
      this.close();
    }
  }
};
</script>

<style scoped></style>
