export const APPROVE_TIMESHEET = "APPROVE_TIMESHEET";
export const VERIFY_TIMESHEET = "VERIFY_TIMESHEET";
export const DISPUTE_TIMESHEET = "DISPUTE_TIMESHEET";
export const SUBMIT_TIMESHEET = "SUBMIT_TIMESHEET";
export const CREDIT_TIMESHEET = "CREDIT_TIMESHEET";
export const GET_ALL_TIMESHEETS = "GET_ALL_TIMESHEETS";
export const GET_CURRENT_TIMESHEET = "GET_CURRENT_TIMESHEET";
export const FETCH_CURRENT_TIMESHEET_COMMENTS =
  "FETCH_CURRENT_TIMESHEET_COMMENTS";
export const FETCH_CURRENT_TIMESHEET_EXPENSES =
  "FETCH_CURRENT_TIMESHEET_EXPENSES";
export const FETCH_CURRENT_TIMESHEET_ENTRIES =
  "FETCH_CURRENT_TIMESHEET_ENTRIES";
export const ADD_COMMENT = "ADD_COMMENT";
export const ADD_ENTRY = "ADD_ENTRY";
export const EDIT_ENTRY = "EDIT_ENTRY";
export const ADD_EXPENSE = "ADD_EXPENSE";
export const EDIT_EXPENSE = "EDIT_EXPENSE";
export const REMOVE_ENTRY = "REMOVE_ENTRY";
export const REMOVE_EXPENSE = "REMOVE_EXPENSE";
export const REMOVE_EXPENSE_FILE = "REMOVE_EXPENSE_FILE";
export const UPDATE_EXPENSES = "UPDATE_EXPENSES";
export const UPDATE_EXPENSE = "UPDATE_EXPENSE";
export const RECALL_TIMESHEET = "RECALL_TIMESHEET";
export const FETCH_TIMESHEETS_WITH_ACTION = "FETCH_TIMESHEETS_WITH_ACTION";
export const CREATE_TIMESHEET = "CREATE_TIMESHEET";
export const FETCH_SHIFT_TIMESHEETS = "FETCH_SHIFT_TIMESHEETS";
export const FETCH_TIMESHEETS_V2 = "FETCH_TIMESHEETS_V2";
export const FETCH_AGENCY_WORKERS = "FETCH_AGENCY_WORKERS";
export const ADD_ENTRY_FROM_NEW_TIMESHEET = "ADD_ENTRY_FROM_NEW_TIMESHEET";

import {
  SET_CURRENT_TIMESHEET,
  SET_CURRENT_TIMESHEET_COMMENTS,
  SET_CURRENT_TIMESHEET_ENTRIES,
  SET_CURRENT_TIMESHEET_EXPENSES,
  SET_IS_FETCHING_CURRENT_TIMESHEET,
  SET_IS_FETCHING_CURRENT_TIMESHEET_COMMENTS,
  SET_IS_FETCHING_CURRENT_TIMESHEET_ENTRIES,
  SET_IS_FETCHING_CURRENT_TIMESHEET_EXPENSES,
  SET_IS_FETCHING_TIMESHEETS,
  SET_IS_REMOVING_ENTRY,
  SET_IS_REMOVING_EXPENSE,
  SET_TIMESHEETS,
  SET_ENTRIES_TO_EDIT,
  SET_ENTRY,
  SET_TIMESHEET_TO_VERIFY,
  SET_TIMESHEET_TO_APPROVE,
  SET_SHIFT_TIMESHEETS
} from "@/store/modules/timesheets/mutations";
import {
  handleWithErrorMessage,
  handleWithGlobalMessage,
  handleWithMessageAndLoading,
  errorHandler
} from "@/services/utils";
import api from "@/services/modules/timesheets";
import { last, filter } from "lodash";
import { updateTimesheetList } from "@/store/modules/timesheets/utils";

const actions = {
  async [APPROVE_TIMESHEET]({ dispatch, commit }, timesheetId) {
    return handleWithGlobalMessage(
      () => api({ commit }).approveTimesheet(timesheetId),
      dispatch
    );
  },
  async [DISPUTE_TIMESHEET]({ dispatch, commit }, timesheetId) {
    return handleWithGlobalMessage(
      () => api({ commit }).disputeTimesheet(timesheetId),
      dispatch
    );
  },
  async [VERIFY_TIMESHEET]({ dispatch, commit }, timesheetId) {
    return handleWithGlobalMessage(
      () => api({ commit }).verifyTimesheet(timesheetId),
      dispatch
    );
  },
  async [SUBMIT_TIMESHEET]({ dispatch, commit, state }, timesheetId) {
    const { data: submittedTimesheet } = await handleWithGlobalMessage(
      () => api({ commit }).submitTimesheet(timesheetId),
      dispatch
    );
    updateTimesheetList(commit, state, submittedTimesheet);
  },
  async [CREDIT_TIMESHEET]({ dispatch, commit }, { timesheetId, data }) {
    return handleWithGlobalMessage(
      () => api({ commit }).creditTimesheet(timesheetId, data),
      dispatch
    );
  },
  async [RECALL_TIMESHEET]({ dispatch, commit }, timesheetId) {
    return handleWithGlobalMessage(
      () => api({ commit }).recallTimesheet(timesheetId),
      dispatch
    );
  },
  async [ADD_EXPENSE]({ dispatch, commit }, expense) {
    return handleWithErrorMessage({
      request: () => api({ commit }).addTimesheetExpense(expense),
      dispatch
    });
  },
  async [UPDATE_EXPENSES]({ commit }, expenses) {
    commit(SET_CURRENT_TIMESHEET_EXPENSES, expenses);
  },
  async [EDIT_EXPENSE]({ dispatch, commit }, { expense, id }) {
    return handleWithErrorMessage({
      request: () => api({ commit }).editTimesheetExpense(expense, id),
      dispatch
    });
  },
  async [ADD_ENTRY]({ dispatch, commit, state }, entry) {
    const { data: newEntry } = await handleWithGlobalMessage(
      () => api({ commit }).addTimesheetEntry(entry),
      dispatch
    );

    commit(SET_CURRENT_TIMESHEET_ENTRIES, [
      ...state.timesheetEntries,
      newEntry
    ]);
  },
  async [ADD_ENTRY_FROM_NEW_TIMESHEET]({ dispatch, commit, state }, entry) {
    const { data: newEntry } = await handleWithErrorMessage({
      request: () => api({ commit }).addTimesheetEntry(entry, false),
      dispatch
    });
    commit(SET_CURRENT_TIMESHEET_ENTRIES, [
      ...state.timesheetEntries,
      newEntry
    ]);
  },
  async [REMOVE_ENTRY]({ dispatch, commit, state }, idToRemove) {
    await handleWithMessageAndLoading({
      dispatch,
      request: () => api({ commit }).deleteTimesheetEntry(idToRemove),
      setLoadingFunction: isLoading => commit(SET_IS_REMOVING_ENTRY, isLoading)
    });
    const filteredEntries = filter(
      state.timesheetEntries,
      ({ id }) => id !== idToRemove
    );
    commit(SET_CURRENT_TIMESHEET_ENTRIES, filteredEntries);
  },
  async [REMOVE_EXPENSE]({ dispatch, commit }, { id, showSucccessMessage }) {
    const handleMessage = showSucccessMessage
      ? handleWithMessageAndLoading
      : handleWithErrorMessage;
    return handleMessage({
      dispatch,
      request: () => api({ commit }).deleteTimesheetExpense(id),
      setLoadingFunction: isLoading =>
        commit(SET_IS_REMOVING_EXPENSE, isLoading)
    });
  },
  async [REMOVE_EXPENSE_FILE](
    { dispatch, commit },
    { expenseId, timesheetId }
  ) {
    return handleWithErrorMessage({
      request: () =>
        api({ commit }).deleteTimesheetExpenseFile(expenseId, timesheetId),
      dispatch
    });
  },
  async [EDIT_ENTRY]({ dispatch, commit, state }, { entry, id }) {
    try {
      const entriesToEditBefore = [...state.entriesToEdit, id];
      commit(SET_ENTRIES_TO_EDIT, entriesToEditBefore);
      const { data: editedEntry } = await api({ commit }).editTimesheetEntry(
        entry,
        id
      );
      commit(SET_ENTRY, { editedEntry, id });
    } catch (e) {
      errorHandler(dispatch, e);
      throw e;
    } finally {
      const entriesToEditAfter = filter(
        state.entriesToEdit,
        entryId => entryId !== id
      );
      commit(SET_ENTRIES_TO_EDIT, entriesToEditAfter);
    }
  },
  async [GET_ALL_TIMESHEETS]({ commit, dispatch }, params) {
    const setLoadingFunction = isLoading => {
      commit(SET_IS_FETCHING_TIMESHEETS, isLoading);
    };

    const setDataFunction = ({ data }) => {
      commit(SET_TIMESHEETS, data);
    };

    const request = () => api({ commit }).getTimesheets(params);

    let attempts = 3;
    try {
      setLoadingFunction(true);
      let response;
      while (attempts) {
        try {
          response = await request();
          setDataFunction(response);
          return response;
        } catch (e) {
          if (attempts === 0) {
            throw e; // Rethrow the last error after all attempts fail
          }
          attempts--;
        }
      }
    } catch (e) {
      errorHandler(dispatch, e);
      throw e; // Rethrow the error to propagate it if needed
    } finally {
      setLoadingFunction(false);
    }
  },
  async [FETCH_TIMESHEETS_V2]({ commit, dispatch }, params) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api({ commit }).getTimesheetsV2(params),
      setLoadingFunction: isLoading =>
        commit(SET_IS_FETCHING_TIMESHEETS, isLoading),
      setDataFunction: ({ data }) => commit(SET_TIMESHEETS, data.data)
    });
  },
  async [FETCH_SHIFT_TIMESHEETS]({ commit, dispatch }, params) {
    const { data } = await handleWithErrorMessage({
      dispatch,
      request: () => api({ commit }).getTimesheets(params),
      setLoadingFunction: isLoading =>
        commit(SET_IS_FETCHING_TIMESHEETS, isLoading),
      setDataFunction: ({ data }) => commit(SET_SHIFT_TIMESHEETS, data)
    });
    return data;
  },
  async [FETCH_TIMESHEETS_WITH_ACTION](
    { commit, dispatch },
    { type, root_client_id }
  ) {
    let mutation = SET_TIMESHEET_TO_APPROVE;
    let params = { filter: { to_approve: true, root_client_id } };
    if (type === "verify") {
      params = { filter: { to_verify: true, root_client_id } };
      mutation = SET_TIMESHEET_TO_VERIFY;
    }
    return handleWithErrorMessage({
      dispatch,
      request: () => api({ commit }).getTimesheets(params),
      setLoadingFunction: isLoading =>
        commit(SET_IS_FETCHING_TIMESHEETS, isLoading),
      setDataFunction: ({ data }) => commit(mutation, data)
    });
  },
  async [GET_CURRENT_TIMESHEET]({ commit, dispatch }, id) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api({ commit }).getTimesheet(id),
      setLoadingFunction: isLoading =>
        commit(SET_IS_FETCHING_CURRENT_TIMESHEET, isLoading),
      setDataFunction: ({ data }) => {
        commit(SET_CURRENT_TIMESHEET, data);
      }
    });
  },
  async [FETCH_CURRENT_TIMESHEET_COMMENTS]({ dispatch, commit }, timesheetId) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api({ commit }).getTimesheetComments(timesheetId),
      setLoadingFunction: isLoading =>
        commit(SET_IS_FETCHING_CURRENT_TIMESHEET_COMMENTS, isLoading),
      setDataFunction: ({ data }) =>
        commit(SET_CURRENT_TIMESHEET_COMMENTS, data)
    });
  },
  async [FETCH_CURRENT_TIMESHEET_EXPENSES]({ dispatch, commit }, timesheetId) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api({ commit }).getTimesheetExpenses(timesheetId),
      setLoadingFunction: isLoading =>
        commit(SET_IS_FETCHING_CURRENT_TIMESHEET_EXPENSES, isLoading),
      setDataFunction: ({ data }) =>
        commit(SET_CURRENT_TIMESHEET_EXPENSES, data)
    });
  },
  async [FETCH_CURRENT_TIMESHEET_ENTRIES]({ dispatch, commit }, timesheetId) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api({ commit }).getTimesheetEntries(timesheetId),
      setLoadingFunction: isLoading =>
        commit(SET_IS_FETCHING_CURRENT_TIMESHEET_ENTRIES, isLoading),
      setDataFunction: ({ data }) => commit(SET_CURRENT_TIMESHEET_ENTRIES, data)
    });
  },
  async [ADD_COMMENT](
    { dispatch, commit, state, rootState },
    { timesheetId, comment }
  ) {
    const currentComments = state.timesheetComments;
    const author = rootState.auth.userData;
    const lastComment = last(currentComments);
    const newComments = [
      ...currentComments,
      {
        id: lastComment ? parseInt(lastComment.id, 10) + 1 : 1,
        type: "comments",
        body: comment.body,
        createdAt: new Date(),
        author
      }
    ];

    commit(SET_CURRENT_TIMESHEET_COMMENTS, newComments);
    try {
      await handleWithErrorMessage({
        dispatch,
        request: () =>
          api({ commit }).addTimesheetComment(timesheetId, comment),
        setLoadingFunction: isLoading =>
          commit(SET_IS_FETCHING_CURRENT_TIMESHEET_COMMENTS, isLoading)
      });
      await dispatch[FETCH_CURRENT_TIMESHEET_COMMENTS];
    } catch (e) {
      commit(SET_CURRENT_TIMESHEET_COMMENTS, currentComments);
      throw e;
    }
  },
  async [CREATE_TIMESHEET]({ dispatch, commit }, data) {
    const { data: createdTimesheet } = await handleWithErrorMessage({
      dispatch,
      request: () => api({ commit }).createTimesheet(data)
    });
    return createdTimesheet;
  },
  async [FETCH_AGENCY_WORKERS]({ dispatch, commit }, params) {
    return handleWithErrorMessage({
      request: () => api({ commit }).searchWorkers(params),
      dispatch
    });
  }
};

export default actions;
