export const SHIFT_PATTERN_MODEL = "shift-pattern";

const shiftPatternModel = {
  id: 0,
  rrule: "",
  workersRequired: 0,
  shiftStart: "",
  shiftEnd: "",
  activity: "",
  status: "",
  approvedDate: "",
  submittedForApprovalDate: "",
  booking: {
    jsonApi: "hasOne",
    type: "booking"
  },
  payRates: {
    jsonApi: "hasMany",
    type: "payRates"
  }
};

export default shiftPatternModel;
