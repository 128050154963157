<template>
  <div class="organisation-list">
    <div class="toolbar">
      <BaseSearchBar @search="text => (searchText = text)" class="mr-2" />
      <NewReasonAction />
    </div>
    <Datatable
      :headers="headers"
      :itemsPerPage="15"
      hideRowSeparator
      :items="organisationReasons"
      :is-loading="isFetchingReasons"
      :search="searchText"
      :customFilter="customFilter"
    >
      <template v-slot:status="{ item: { status } }">
        <ActivityStatusChip :status="status">{{ status }}</ActivityStatusChip>
      </template>
      <template v-slot:action="{ item }">
        <DotMenu :items="menuItems(item)" @select="openActionDialog" />
      </template>
    </Datatable>
    <EditReasonAction
      :is-open="isEditReasonDialogOpen"
      :reason-for-booking="selectedReason"
      @close="isEditReasonDialogOpen = false"
    />
    <RemoveReasonDialog
      :is-open="isRemoveReasonDialogOpen"
      :id="selectedReason.id"
      @close="isRemoveReasonDialogOpen = false"
    />
  </div>
</template>

<script>
import BaseSearchBar from "@/components/common/BaseSearchBar";
import Datatable from "@/components/common/Datatable";
import { createNamespacedHelpers } from "vuex";
import { AUTH_NAMESPACE } from "@/store/modules/auth";
import { copyState } from "@/utils/helpers";
import { get, map } from "lodash";
import NewReasonAction from "@/views/settings/Organisation/ReasonsForBooking/actions/NewReasonAction";
import EditReasonAction from "@/views/settings/Organisation/ReasonsForBooking/actions/EditReasonAction";
import RemoveReasonDialog from "@/views/settings/Organisation/ReasonsForBooking/dialogs/RemoveReasonDialog";
import { FETCH_ORGANISATION_REASONS } from "@/store/modules/settings/actions";
import { ORGANISATION_REASONS_NAMESPACE } from "@/store/modules/organisations/organisation-reasons";
import ActivityStatusChip from "@/components/common/ActivityStatusChip";
import { customTableFussyFilter } from "@/utils/filters";
import { DotMenu } from "@/components/common/Menu";

const { mapActions, mapState } = createNamespacedHelpers(
  ORGANISATION_REASONS_NAMESPACE
);
const { mapState: mapAuthState } = createNamespacedHelpers(AUTH_NAMESPACE);

export default {
  name: "OrganisationReasonsList",
  components: {
    ActivityStatusChip,
    EditReasonAction,
    NewReasonAction,
    Datatable,
    BaseSearchBar,
    DotMenu,
    RemoveReasonDialog
  },
  data() {
    return {
      searchText: "",
      isEditReasonDialogOpen: false,
      isRemoveReasonDialogOpen: false,
      selectedReason: {}
    };
  },
  computed: {
    ...mapState(copyState(["organisationReasons", "isFetchingReasons"])),
    ...mapAuthState(copyState(["userData"])),
    headers() {
      return [
        {
          text: "Name",
          value: "hireReason",
          class: "max-width-200"
        },
        {
          text: "Status",
          value: "status",
          class: "max-width-200"
        },
        {
          text: "",
          value: "action",
          sortable: false,
          align: "end"
        }
      ];
    },
    searchKeys() {
      return map(this.headers, "value");
    },
    selfOrganisationId() {
      return get(this.userData, "organisation.id");
    }
  },
  created() {
    if (this.selfOrganisationId) {
      this.fetchOrganisationReasons(this.selfOrganisationId);
    }
  },
  methods: {
    ...mapActions({
      fetchOrganisationReasons: FETCH_ORGANISATION_REASONS
    }),
    menuItems(item) {
      return [
        {
          title: `Edit`,
          action: () => {
            this.handleEditReason(item);
          }
        },
        {
          title: `Remove`,
          action: () => {
            this.handleRemoveReason(item);
          }
        }
      ];
    },
    handleEditReason(reason) {
      this.selectedReason = reason;
      this.isEditReasonDialogOpen = true;
    },
    handleRemoveReason(reason) {
      this.selectedReason = reason;
      this.isRemoveReasonDialogOpen = true;
    },
    customFilter(value, searchValue, item) {
      return customTableFussyFilter(searchValue, item, this.searchKeys);
    },
    openActionDialog(action) {
      action();
    }
  },
  watch: {
    selfOrganisationId(organisationId) {
      this.fetchOrganisationReasonsforbookings(organisationId);
    }
  }
};
</script>

<style lang="scss"></style>
