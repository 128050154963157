import { SHIFT_MODEL } from "@/models/shift-model";
import pluralize from "pluralize";
import { ASSESSMENT_MODEL } from "@/models/assessment-model";
import { BOOKING_MODEL } from "@/models/booking-model";
import { USER_MODEL } from "@/models/user-model";
import { COMPLIANCE_MODEL } from "@/models/compliance-model";
import { REFERENCE_MODEL } from "@/models/reference-model";
import { ORGANISATION_MODEL } from "@/models/organisation-model";

export const APPLICATION_MODEL = "application";

const applicationModel = {
  status: "",
  applicationReference: "",
  rejectionReason: "",
  awr: false,
  reason: "",
  shifts: {
    jsonApi: "hasMany",
    type: pluralize(SHIFT_MODEL)
  },
  assessments: {
    jsonApi: "hasMany",
    type: pluralize(ASSESSMENT_MODEL)
  },
  booking: {
    jsonApi: "hasOne",
    type: pluralize(BOOKING_MODEL)
  },
  createdBy: {
    jsonApi: "hasOne",
    type: pluralize(USER_MODEL)
  },
  user: {
    jsonApi: "hasOne",
    type: pluralize(USER_MODEL)
  },
  complianceChecks: {
    jsonApi: "hasMany",
    type: pluralize(COMPLIANCE_MODEL)
  },
  references: {
    jsonApi: "hasMany",
    type: pluralize(REFERENCE_MODEL)
  },
  organisation: {
    jsonApi: "hasOne",
    type: ORGANISATION_MODEL
  }
};

export const applicationActions = {
  APPLICATIONS: "applications",
  AVAILABLE_CANDIDATES: "available-candidates",
  REQUEST_INTERVIEW: "request-interview",
  WITHDRAW: "withdraw",
  REJECT: "reject",
  APPROVE: "approve",
  SHORTLIST: "shortlist",
  WITHDRAW_SHIFTS: "withdraw-shift",
  SUBMIT: "submit",
  APPLICATION_SHIFT: "application-shift",
  SHIFT: "shift",
  UNAVAILABLE_CANDIDATES: "unavailable-candidates"
};

export default applicationModel;
