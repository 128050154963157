<template>
  <div class="reports-config" v-if="showFilters || showGroupings">
    <span class="filters__title" v-if="showFilters">Filter by</span>
    <v-chip v-for="(filter, index) in report.filters" :key="index">
      <strong class="mr-1">{{ filter.field | startCase }}</strong>
      {{ operatorTypes[filter.operator] | lowerCase }}
      {{ formattedValue(filter) }}
      {{ filter.unit ? pluralize(filter.unit, Number(filter.value)) : "" }}
    </v-chip>
    <span class="filters__title" v-if="showGroupings">
      Group by
    </span>
    <v-chip v-for="(grouping, index) in report.groupings" :key="index">
      <strong class="mr-1">{{ grouping.field | startCase }}</strong>
      <template v-if="grouping.interval_date">
        by {{ grouping.interval_date }}
      </template>
      <template v-if="grouping.interval_number">
        by {{ grouping.interval_number }}
      </template>
    </v-chip>
  </div>
</template>

<script>
import { OPERATOR_TYPES } from "@/constants/reports";
import { getReportFormatDate } from "@/utils/time";
import { includes, isEmpty } from "lodash";
import pluralize from "pluralize";

const notDateOperators = [
  OPERATOR_TYPES.in_last_date_range,
  OPERATOR_TYPES.in_next_date_range
];
export default {
  name: "ReportConfig",
  props: {
    report: Object
  },
  computed: {
    operatorTypes: () => OPERATOR_TYPES,
    showGroupings() {
      return this.report.groupings && !isEmpty(this.report.groupings);
    },
    showFilters() {
      return this.report.filters && !isEmpty(this.report.filters);
    }
  },
  methods: {
    pluralize,
    formattedValue({ field, value, operator }) {
      if (
        (includes(field, "_date") || includes(field, "_date_")) &&
        this.shouldFormatDate(operator)
      ) {
        const dates = value.split(",");
        if (dates.length > 1) {
          return `${getReportFormatDate(dates[0])} - ${getReportFormatDate(
            dates[1]
          )}`;
        }
        return getReportFormatDate(value);
      }
      const values = value.split(" | ");
      return values.length > 1
        ? `${values[0]} +${values.length - 1}`
        : values[0];
    },
    shouldFormatDate(operator) {
      return !includes(notDateOperators, this.operatorTypes[operator]);
    }
  }
};
</script>

<style lang="scss">
.reports-config {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 15px;
  @media only screen and (max-width: $mobile-breakpoint - 1) {
    width: 100%;
    .v-chip {
      overflow: scroll;
    }
  }
}
.filters__title {
  margin-top: 5px;
}
.reports-config > *:not(:last-child) {
  margin-right: 16px;
}
</style>
