<template>
  <div class="shift-calendar">
    <div class="d-flex">
      <v-btn fab text small color="grey darken-2" @click="prev">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-spacer />
      <v-toolbar-title>{{ title }}</v-toolbar-title>
      <v-spacer />
      <v-btn fab text small @click="next">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </div>
    <v-calendar
      v-model="calendar"
      :events="shifts"
      :event-color="event => event.color"
      :weekdays="[1, 2, 3, 4, 5, 6, 0]"
      ref="calendar"
    />
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "ShiftStepCalendar",
  data() {
    return {
      calendar: new Date().toISOString(),
      dayOptions: { outside: true }
    };
  },
  computed: {
    shifts() {
      return this.events;
    },
    title() {
      const displayedDate = moment(this.calendar);
      return displayedDate.format("MMMM YYYY");
    }
  },
  watch: {
    shifts(newVal, oldVal) {
      if (newVal.length === 0) {
        this.calendar = new Date().toISOString();
      } else if (oldVal.length === 0 || oldVal.length > newVal.length) {
        const month = moment(newVal[0].start).month();
        const year = moment(newVal[0].start).year();
        this.calendar = moment([year, month]).format(`YYYY-MM-DD`);
      }
    }
  },
  props: {
    events: {
      type: Array,
      required: true
    }
  },
  methods: {
    stepForward() {
      this.$emit("nextStep");
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    }
  }
};
</script>

<style lang="scss"></style>
