<template>
  <app-layout title="">
    <template v-slot:body>
      <div class="page-not-found">
        <div class="title-error">
          404 ERROR
        </div>
        <div class="title">
          Nobody's here...
        </div>
        <v-icon size="5rem">mdi-alert-circle</v-icon>
        <span class="mt-10">
          It looks like you're trying to access a page
        </span>
        <span>
          that either has been deleted or never even existed.
        </span>
      </div>
    </template>
  </app-layout>
</template>

<script>
import appLayout from "@/components/common/base-layouts/AppLayout";

export default {
  components: {
    appLayout
  }
};
</script>

<style lang="scss">
.page-not-found {
  text-align: center;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  .v-icon {
    cursor: auto;
  }

  .title-error {
    font-weight: bold;
    margin-bottom: 20px;
    font-size: 40px;
  }
}
</style>
