import {
  handleWithErrorMessage,
  handleWithMessageAndLoading
} from "@/services/utils";
import api from "@/services/modules/organisations/organisation-booking-templates";
import {
  SET_IS_FETCHING_BOOKING_TEMPLATES,
  SET_IS_FETCHING_BOOKING_TEMPLATE,
  SET_IS_ADDING_BOOKING_TEMPLATE,
  SET_IS_EDITING_BOOKING_TEMPLATE,
  SET_BOOKING_TEMPLATES,
  SET_CURRENT_BOOKING_TEMPLATE
} from "@/store/modules/organisations/organisation-booking-templates/mutations";

export const FETCH_ORGANISATION_BOOKING_TEMPLATES =
  "FETCH_ORGANISATION_BOOKING_TEMPLATES";
export const ADD_ORGANISATION_BOOKING_TEMPLATE =
  "ADD_ORGANISATION_BOOKING_TEMPLATE";
export const FETCH_ORGANISATION_BOOKING_TEMPLATE =
  "FETCH_ORGANISATION_BOOKING_TEMPLATE";
export const EDIT_ORGANISATION_BOOKING_TEMPLATE =
  "EDIT_ORGANISATION_BOOKING_TEMPLATE";

const actions = {
  async [FETCH_ORGANISATION_BOOKING_TEMPLATES]({ commit, dispatch }) {
    return handleWithErrorMessage({
      dispatch,
      request: api(commit).getAll,
      setLoadingFunction: isLoading =>
        commit(SET_IS_FETCHING_BOOKING_TEMPLATES, isLoading),
      setDataFunction: ({ data }) => commit(SET_BOOKING_TEMPLATES, data)
    });
  },
  async [FETCH_ORGANISATION_BOOKING_TEMPLATE]({ dispatch, commit }, id) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api(commit).getBookingTemplate(id),
      setLoadingFunction: isLoading =>
        commit(SET_IS_FETCHING_BOOKING_TEMPLATE, isLoading),
      setDataFunction: ({ data }) => commit(SET_CURRENT_BOOKING_TEMPLATE, data)
    });
  },
  async [ADD_ORGANISATION_BOOKING_TEMPLATE]({ commit, dispatch }, data) {
    return handleWithMessageAndLoading({
      dispatch,
      request: () => api(commit).add(data),
      setLoadingFunction: isLoading =>
        commit(SET_IS_ADDING_BOOKING_TEMPLATE, isLoading)
    });
  },
  async [EDIT_ORGANISATION_BOOKING_TEMPLATE]({ commit, dispatch }, data) {
    return handleWithMessageAndLoading({
      dispatch,
      request: () => api(commit).edit(data),
      setLoadingFunction: isLoading =>
        commit(SET_IS_EDITING_BOOKING_TEMPLATE, isLoading),
      setDataFunction: data => commit(SET_CURRENT_BOOKING_TEMPLATE, data)
    });
  }
};

export default actions;
