<template>
  <div class="timesheets mt-2">
    <AppLayout customToolbar centered>
      <template v-slot:body>
        <div class="container col-lg-9 col-sm-12">
          <BaseListToolbar
            title="Timesheets"
            @search="updateSearchValue"
            @sort="onSort"
            :sortingData="sortingData"
            :hideSearchBar="shouldDisplayGroupedTimesheets"
          >
            <template v-slot:inline-filters>
              <TimesheetListFilter
                @onClearFilters="clearFilters"
                :isLoading="isLoadingTimesheets"
                :availableFilters="availableFilters"
                :filterLabels="filterLabels"
                :outerSelectedFilters="timesheetFilters"
                :isGroupedTimesheets="shouldDisplayGroupedTimesheets"
                @filter="updateFilters"
              />
            </template>
          </BaseListToolbar>
          <div v-if="isLoadingTimesheets">
            <v-skeleton-loader
              data-test="loader"
              v-for="i in 10"
              :key="i"
              type="list-item-avatar"
            />
          </div>
          <div v-else>
            <div v-if="isNotEmpty">
              <div v-if="shouldDisplayGroupedTimesheets">
                <div v-for="(dateGroupings, index) in timesheets" :key="index">
                  <h2 class="date-range">
                    {{ dateGroupings.dayDate | fullDate }}
                  </h2>
                  <div
                    class="text-center"
                    v-for="(rootClient, index) in dateGroupings['root-clients']"
                    :key="index"
                  >
                    <div
                      v-for="(location, index) in rootClient.locations"
                      :key="index"
                      class="text-center"
                    >
                      <h3>
                        {{ rootClient.rootClientName }}
                      </h3>
                      <p class="secondary-text">
                        {{ getFormattedLocation(location) }}
                      </p>
                      <TimesheetByBookings
                        v-for="(booking, index) in location.bookings"
                        :key="index"
                        :booking="booking"
                        :selectMultiple="selectMultiple"
                        :selectedTimesheets="selectedTimesheets"
                        @select="selectGroupedTimesheets"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div
                  v-for="(groupedTimesheets,
                  dateRange,
                  index) in groupedByBooking"
                  :key="index"
                >
                  <h2 class="date-range">
                    {{ dateRange }}
                  </h2>
                  <div
                    v-for="(timesheets,
                    bookingTitle,
                    index) in groupedTimesheets"
                    :key="index"
                  >
                    <div
                      class="d-flex justify-center align-baseline"
                      v-html="bookingTitle"
                    />
                    <TimesheetListItem
                      showWorker
                      v-for="timesheet in timesheets"
                      :key="timesheet.id"
                      :selectMultiple="selectMultiple"
                      :timesheet="timesheet"
                      :isSelected="isSelected(timesheet.id)"
                      @select="selectTimesheet(timesheet)"
                      showBasicDetails
                    />
                  </div>
                </div>
              </div>
              <v-pagination
                class="my-10"
                :total-visible="page.pageSize"
                v-if="showPagination"
                v-model="page.currentPage"
                @input="loadTimesheets"
                :length="page.lastPage"
              />
            </div>
            <EmptyStates
              v-else
              data-test="empty-list"
              icon="roles-empty-state"
              :description="emptyStateDescription"
              :isFiltered="isFiltered"
            />
          </div>
        </div>
        <BaseFooter class="mb-4">
          <ExpandedButtons class="actions-bar">
            <VerifyTimesheetAction
              v-if="showVerifyAction"
              class="mr-4"
              :timesheetsIds="selectedTimesheetsIds"
              @onUpdate="updateList"
            />
            <DisputeTimesheetAction
              v-if="showDisputeAction"
              class="mr-4"
              :timesheetsIds="selectedTimesheetsIds"
              @disputed="updateList"
              isMultiple
            />
            <ApproveTimesheetAction
              v-if="showApproveAction"
              class="mr-4"
              :timesheetsIds="selectedTimesheetsIds"
              @onUpdate="updateList"
            />
            <BulkTimesheetsAction
              v-if="showBulkAction"
              @save="loadTimesheets"
              @close="loadTimesheets"
            />
          </ExpandedButtons>
        </BaseFooter>
      </template>
    </AppLayout>
  </div>
</template>

<script>
import AppLayout from "@/components/common/base-layouts/AppLayout";
import BaseListToolbar from "@/components/common/Toolbar/BaseListToolbar";
import { SORTING_DATA } from "@/constants/timesheets";
import TimesheetListItem from "@/views/timesheets/details/components/TimesheetListItem";
import { createNamespacedHelpers } from "vuex";
import { TIMESHEETS_NAMESPACE } from "@/store/modules/timesheets";
import {
  GET_ALL_TIMESHEETS,
  FETCH_TIMESHEETS_V2
} from "@/store/modules/timesheets/actions";
import { SET_TIMESHEET_FILTERS } from "@/store/modules/timesheets/mutations";
import TimesheetListFilter from "@/views/timesheets/TimesheetListFilter";
import { size, map, filter, includes, some, split, first } from "lodash";
import { BASE_DATE_FORMAT, LONG_DATE_FORMAT } from "@/constants/common.js";
import moment from "moment";
import { AUTH_NAMESPACE } from "@/store/modules/auth";
import { customTableFussyFilter, formatFilters } from "@/utils/filters";
import { getLinkParams } from "@/router/utils";
import {
  getSortingParam,
  setGlobalClientIdFilter,
  getClientIdFilter,
  setClientIdFilter
} from "@/utils/helpers";
import { paginationMixin } from "@/mixins/pagination.mixin";
import { isPermissioned } from "@/utils/permissions";
import {
  getGroupedTimesheets,
  checkIfSelectedTimesheetsArePermitted,
  groupTimesheetsByBooking
} from "@/utils/timesheets";
import EmptyStates from "@/components/common/EmptyStates";
import ExpandedButtons from "@/components/common/ExpandedButtons";
import ApproveTimesheetAction from "@/views/timesheets/components/actions/ApproveTimesheetAction";
import DisputeTimesheetAction from "@/views/timesheets/components/actions/DisputeTimesheetAction";
import VerifyTimesheetAction from "@/views/timesheets/components/actions/VerifyTimesheetAction";
import BulkTimesheetsAction from "@/views/timesheets/components/actions/BulkTimesheetsAction";
import BaseFooter from "@/components/common/BaseFooter";
import TimesheetByBookings from "@/views/timesheets/details/components/TimesheetByBookings";
import { getFormattedLocation } from "@/utils/locations";
import { isWorker } from "@/utils/users";

const {
  mapState: timesheetMapState,
  mapActions: timesheetMapActions,
  mapMutations
} = createNamespacedHelpers(TIMESHEETS_NAMESPACE);

const { mapState: authMapState } = createNamespacedHelpers(AUTH_NAMESPACE);

export const EDITABLE_STATUSES = ["draft", "disputed"];

export default {
  name: "Timesheets",
  mixins: [paginationMixin],
  components: {
    TimesheetListFilter,
    AppLayout,
    BaseListToolbar,
    TimesheetListItem,
    EmptyStates,
    ExpandedButtons,
    ApproveTimesheetAction,
    DisputeTimesheetAction,
    VerifyTimesheetAction,
    BulkTimesheetsAction,
    BaseFooter,
    TimesheetByBookings
  },
  async created() {
    const { status } = getLinkParams(this.$route.params);
    await this.loadTimesheets();
    this.adjustInitialFilters({ status });
  },
  data: () => ({
    search: "",
    menuItems: [{ id: "submitTimesheet", title: "Submit Timesheet" }],
    availableFilters: {},
    sort: "",
    sortingData: SORTING_DATA,
    filterLabels: {},
    selectedTimesheets: []
  }),

  computed: {
    ...authMapState(["permissions", "userData"]),
    ...timesheetMapState({
      timesheets: state => state.timesheets,
      isLoadingTimesheets: state => state.isFetchingTimesheets,
      timesheetFilters: state => state.timesheetFilters
    }),
    emptyStateDescription() {
      return this.isFiltered
        ? "No timesheets match your search criteria"
        : "No timesheets have been created";
    },
    groupedTimesheets() {
      return getGroupedTimesheets(this.timesheets);
    },
    groupedByBooking() {
      return groupTimesheetsByBooking(this.groupedTimesheets);
    },
    isNotEmpty() {
      return size(this.timesheets);
    },
    isFiltered() {
      return Boolean(size(this.timesheetFilters) || size(this.search));
    },
    requestParams() {
      return {
        filter: {
          ...this.timesheetFilters,
          ...(size(this.search.trim()) && { search: this.search })
        },
        "page[size]": this.page.pageSize,
        "page[number]": this.page.currentPage,
        ...(this.sort && { sort: this.sort })
      };
    },
    mobileHeaders() {
      return filter(this.headers, ({ customMobileView }) => !customMobileView);
    },
    searchKeys() {
      return map(this.headers, "value");
    },
    formattedTimesheets() {
      return map(this.timesheets, item => {
        const {
          worker,
          verifier,
          approver,
          weekCommencing,
          currency_code,
          title,
          rootClient,
          approvalDate,
          approvedBy
        } = item;
        const formattedWeekCommencingDate = weekCommencing
          ? moment(weekCommencing).format(BASE_DATE_FORMAT)
          : "";
        const formattedWeekCommencingLongDate = weekCommencing
          ? moment(weekCommencing).format(LONG_DATE_FORMAT)
          : "";
        const formattedApprovalDate = approvalDate
          ? moment(approvalDate).format(BASE_DATE_FORMAT)
          : "";
        return {
          ...item,
          worker,
          weekCommencing: formattedWeekCommencingDate,
          weekCommencingLongDate: formattedWeekCommencingLongDate,
          currencyCode: currency_code,
          approvalDate: formattedApprovalDate,
          approver,
          approvedBy,
          verifier,
          title,
          rootClient: rootClient ? rootClient.name : ""
        };
      });
    },
    filterCount() {
      return size(this.timesheetFilters);
    },
    selectMultiple() {
      return Boolean(size(this.selectedTimesheets));
    },
    selectedTimesheetsIds() {
      return map(this.selectedTimesheets, ({ id }) => id);
    },
    // TODO: update action condition if the permissions is included in the api
    showVerifyAction() {
      return this.shouldDisplayGroupedTimesheets
        ? !this.shouldHideAction("verified") && this.selectMultiple
        : checkIfSelectedTimesheetsArePermitted({
            timesheets: this.selectedTimesheets,
            permission: "verify"
          });
    },
    showDisputeAction() {
      return this.shouldDisplayGroupedTimesheets
        ? !this.shouldHideAction("disputed") && this.selectMultiple
        : checkIfSelectedTimesheetsArePermitted({
            timesheets: this.selectedTimesheets,
            permission: "dispute"
          });
    },
    showApproveAction() {
      return this.shouldDisplayGroupedTimesheets
        ? !this.shouldHideAction("approved") && this.selectMultiple
        : checkIfSelectedTimesheetsArePermitted({
            timesheets: this.selectedTimesheets,
            permission: "approve"
          });
    },
    showBulkAction() {
      return this.permissions["timesheets.bulk-edit"] && !this.selectMultiple;
    },
    shouldDisplayGroupedTimesheets() {
      return (
        process.env.VUE_APP_IS_GROUPED_TIMESHEETS === "true" &&
        !isWorker(this.userData.roles)
      );
    }
  },
  methods: {
    ...mapMutations({
      setTimesheetFilters: SET_TIMESHEET_FILTERS
    }),
    ...timesheetMapActions({
      getTimesheets: GET_ALL_TIMESHEETS,
      fetchTimesheetsV2: FETCH_TIMESHEETS_V2
    }),
    async loadTimesheets() {
      const data = this.shouldDisplayGroupedTimesheets
        ? await this.fetchTRGTimesheets()
        : await this.fetchTimesheets();
      if (data && data.meta) {
        this.adjustPageDetails(data.meta);
      }
    },
    async fetchTimesheets() {
      return await this.getTimesheets(this.requestParams);
    },
    async fetchTRGTimesheets() {
      const { data } = await this.fetchTimesheetsV2(
        this.getFormattedRequestParams()
      );
      return data;
    },
    getFormattedRequestParams() {
      const { filter } = this.requestParams;
      return formatFilters(filter);
    },
    triggerCheckboxMode() {
      this.selectedTimesheetsIds = [];
      this.selectMultiple = !this.selectMultiple;
    },
    adjustPageDetails({ page, filters, labels }) {
      if (page) {
        this.updatePageDetails({ page });
      }
      this.availableFilters = filters;
      this.filterLabels = labels;
    },
    adjustInitialFilters({ status }) {
      const clientIdFilters = getClientIdFilter();
      const root_client_id = setClientIdFilter(
        this.availableFilters["root_client_id"],
        clientIdFilters
      );
      if (root_client_id) {
        this.setTimesheetFilters({
          ...this.timesheetFilters,
          ...(status && { status }),
          root_client_id
        });
      }
      if (
        this.shouldDisplayGroupedTimesheets &&
        !this.timesheetFilters["status"]
      ) {
        this.setTimesheetFilters({
          status: "submitted"
        });
      }
    },
    ...timesheetMapActions({
      getTimesheets: GET_ALL_TIMESHEETS
    }),
    onSort(items, paramNameArray, sortDescArray) {
      const sortingParam = getSortingParam(paramNameArray, sortDescArray);
      if (this.sort !== sortingParam && sortingParam) {
        this.resetPagination();
        this.sort = sortingParam;
        this.loadTimesheets();
      }
      return this.formattedTimesheets;
    },
    handleSelect({ id, status }) {
      const showSubmitTimesheetView =
        includes(EDITABLE_STATUSES, status) &&
        isPermissioned(`timesheets.edit`);
      this.$router.push({
        name: showSubmitTimesheetView ? "submitTimesheet" : "timesheetDetails",
        params: { id }
      });
    },
    clearFilters() {
      this.setTimesheetFilters({});
      this.resetPagination();
      this.loadTimesheets();
    },
    customFilter(value, searchValue, item) {
      return customTableFussyFilter(searchValue, item, this.searchKeys);
    },
    updateFilters(filters) {
      setGlobalClientIdFilter(filters);
      this.setTimesheetFilters(filters);
      this.resetPagination();
      this.loadTimesheets();
    },
    updateSearchValue(text) {
      this.search = text;
      this.resetPagination();
      this.loadTimesheets();
    },
    updateList() {
      this.selectedTimesheets = [];
      this.loadTimesheets();
    },
    isSelected(id) {
      return some(this.selectedTimesheets, timesheet => {
        return timesheet.id === id;
      });
    },
    selectTimesheet(timesheet) {
      if (this.isSelected(timesheet.id)) {
        this.selectedTimesheets = filter(
          this.selectedTimesheets,
          ({ id }) => id !== timesheet.id
        );
      } else {
        this.selectedTimesheets = [
          ...(this.selectMultiple ? this.selectedTimesheets : []),
          timesheet
        ].filter(Boolean);
      }
    },
    isGroupSelected(timesheets) {
      const firstTimesheet = first(timesheets);
      return this.isSelected(firstTimesheet.id);
    },
    selectGroupedTimesheets(timesheets) {
      if (this.isGroupSelected(timesheets)) {
        map(timesheets, timesheet => {
          this.selectedTimesheets = filter(
            this.selectedTimesheets,
            ({ id }) => id !== timesheet.id
          );
        });
      } else {
        this.selectedTimesheets = [
          ...(this.selectMultiple ? this.selectedTimesheets : []),
          ...timesheets
        ].filter(Boolean);
      }
    },
    getLocation(timesheets) {
      if (timesheets) {
        const key = Object.keys(timesheets)[0];
        const timesheet = first(timesheets[key]);
        const { booking } = timesheet;
        return booking && getFormattedLocation(booking.location);
      }
      return "";
    },
    shouldHideAction(status) {
      return some(this.selectedTimesheets, timesheet => {
        return timesheet.status === status;
      });
    },
    size,
    getFormattedLocation
  },
  filters: {
    organisationText(orgWithDate) {
      const splitText = split(orgWithDate, "-");
      return first(splitText);
    }
  }
};
</script>

<style lang="scss">
.timesheets {
  .date-range {
    display: flex;
    justify-content: center;
    padding: 25px 0;

    @media only screen and (max-width: $mobile-breakpoint - 1) {
      background-color: $background;
      margin-left: -15px;
      width: 100vw;
      box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
    }
  }

  position: relative;

  .mobile-actions-section {
    display: flex;
    flex: 1;
    width: fit-content;
    justify-content: flex-end;
  }

  tr {
    cursor: pointer;
  }

  .chip-status {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    font-weight: bold;
  }
}
</style>
