<template>
  <BaseAction>
    <SecondaryButton @click.native="openDialog" textColor="#d10a3a">
      <inline-svg
        :src="require('@/assets/svg/thumb-down-red.svg')"
        width="25"
      />
      Reject
    </SecondaryButton>
    <RejectCandidate
      v-if="isOpen"
      :isOpen="isOpen"
      @close="closeDialog"
      :applications="applications"
    />
  </BaseAction>
</template>

<script>
import RejectCandidate from "@/views/bookings/JobCandidate/components/RejectCandidate";
import BaseAction from "@/components/common/BaseAction";
import SecondaryButton from "@/components/common/Button/SecondaryButton";
import InlineSvg from "vue-inline-svg";

export default {
  name: "RejectCandidateAction",
  components: {
    BaseAction,
    RejectCandidate,
    SecondaryButton,
    InlineSvg
  },
  props: {
    applications: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isOpen: false
    };
  },
  methods: {
    openDialog() {
      this.isOpen = true;
    },
    closeDialog({ success }) {
      this.isOpen = false;
      if (success) {
        this.$emit("statusChanged");
      }
    }
  }
};
</script>
