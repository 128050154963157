<template>
  <div>
    <PublishingScheduleDialog
      :dialogSettings="dialogSettings"
      @close="close"
      :publishingSchedule="publishingSchedule"
      :actionName="actionName"
      @submit="handleEdit"
      :loading="isLoading"
    />
    <PrimaryButton light dense shrink @click.native="openDialog">
      Edit
      <template v-slot:append:icon>
        <v-icon class="ml-1">mdi-pencil</v-icon>
      </template>
    </PrimaryButton>
  </div>
</template>

<script>
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import PublishingScheduleDialog from "@/views/settings/Organisation/PublishingSchedules/dialogs/PublishingScheduleDialog";
import { ORGANISATION_SCHEDULES_NAMESPACE } from "@/store/modules/organisations/organisation-schedules";
import { createNamespacedHelpers } from "vuex";
import { UPDATE_PUBLISHING_SCHEDULE } from "@/store/modules/organisations/organisation-schedules/actions";

const { mapActions } = createNamespacedHelpers(
  ORGANISATION_SCHEDULES_NAMESPACE
);

export default {
  name: "EditPublishingScheduleAction",
  components: { PrimaryButton, PublishingScheduleDialog },
  props: {
    publishingSchedule: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      actionName: "Save Changes",
      isLoading: false,
      dialogSettings: {
        dialog: false,
        title: "Edit Publishing Schedule"
      }
    };
  },
  methods: {
    ...mapActions({
      editSchedule: UPDATE_PUBLISHING_SCHEDULE
    }),
    openDialog() {
      this.dialogSettings.dialog = true;
    },
    close() {
      this.dialogSettings.dialog = false;
    },
    async handleEdit(editedSchedule) {
      this.isLoading = true;
      try {
        await this.editSchedule({
          ...editedSchedule,
          id: this.publishingSchedule.id
        });
        this.close();
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style scoped></style>
