<template>
  <div>
    <v-btn @click.native="isDeleteReportOpen = true" color="#d10a3a" icon>
      <v-icon large>
        mdi-trash-can-outline
      </v-icon>
    </v-btn>
    <DeleteReportDialog
      :isOpen="isDeleteReportOpen"
      @close="isDeleteReportOpen = false"
    />
  </div>
</template>

<script>
import DeleteReportDialog from "@/views/reports/dialogs/DeleteReportDialog";

export default {
  components: { DeleteReportDialog },
  data() {
    return {
      isDeleteReportOpen: false
    };
  }
};
</script>
