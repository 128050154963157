import JSONApiService from "@/services/JSONApiService";
import {
  USER_MODEL,
  USER_STAFF_MODEL,
  INVITE_USER,
  RESET_PASSWORD
} from "@/models/user-model";
import { disablePluralizeForCall } from "@/services/utils";
import APIService from "@/services/APIService";
import { SETTINGS, ROLES } from "@/models/relationships";
import { ORGANISATION } from "@/models/relationships";
import { include } from "@/models/relationships";

const userApi = commit => ({
  getTimesheetVerifiers: async organisationId =>
    JSONApiService(commit)
      .all(USER_MODEL)
      .get({ filter: { timesheet_verifier_org_id: organisationId } }),
  getTimesheetApprovers: async organisationId =>
    JSONApiService(commit)
      .all(USER_MODEL)
      .get({ filter: { timesheet_approver_org_id: organisationId } }),
  getUsers: async params =>
    JSONApiService(commit)
      .all(USER_MODEL)
      .get({ ...params, ...include(ORGANISATION) }),
  getUserRoles: async userId =>
    JSONApiService(commit)
      .one(USER_MODEL, userId)
      .all(ROLES)
      .get(),
  getUserSettings: async () => {
    const api = JSONApiService(commit);
    disablePluralizeForCall(api, () => {
      api.all(USER_MODEL);
    });
    return api.all(SETTINGS).get();
  },
  editUserSettings: async editedSettings =>
    APIService.client().patch(`${USER_MODEL}/${SETTINGS}`, {
      data: editedSettings
    }),
  createUser: async user =>
    JSONApiService(commit).create(
      USER_STAFF_MODEL,
      user,
      include(ORGANISATION)
    ),
  editUserDetails: async user =>
    JSONApiService(commit).update(
      USER_STAFF_MODEL,
      user,
      include(ORGANISATION)
    ),
  inviteUser: async id => {
    const api = JSONApiService(commit);
    api.one(USER_MODEL, id);
    disablePluralizeForCall(api, () => {
      api.all(INVITE_USER);
    });
    return api.post({});
  },
  resetUserPassword: async id => {
    const api = JSONApiService(commit);
    api.one(USER_MODEL, id);
    disablePluralizeForCall(api, () => {
      api.all(RESET_PASSWORD);
    });
    return api.post({});
  }
});

export default userApi;
