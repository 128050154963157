<template>
  <div class="labeled-text" @click="handleClick" :class="{ clickable }">
    <div class="d-flex">
      <v-icon v-if="icon" v-show="icon">{{ icon }}</v-icon>
      <div class="icon-container"><slot name="icon" /></div>
      <div class="content">
        <div class="label" :class="{ bold }">{{ label }}</div>
        <div v-if="hasContent"><slot /></div>
        <div v-else>-</div>
      </div>
    </div>
    <div class="action"><slot name="action" /></div>
  </div>
</template>

<script>
export default {
  name: "LabeledText",
  props: {
    label: String,
    icon: String,
    bold: {
      type: Boolean,
      default: true
    },
    clickable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasContent() {
      return !!this.$slots.default;
    }
  },
  methods: {
    handleClick(event) {
      if (this.clickable) {
        this.$emit("click", event);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.labeled-text {
  justify-content: space-between;
  flex-wrap: wrap;

  .bold {
    font-weight: 600 !important;
  }
  @media only screen and (max-width: $mobile-breakpoint - 1) {
    .action {
      max-width: 100%;
    }
  }
}
</style>
