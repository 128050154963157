<template>
  <BaseDialog :isOpen="isOpen">
    <template v-slot:title>
      <span>
        Are you sure you want to request an interview with
        {{ pluralize("this", size(applicationsIds)) }}
        {{ pluralize("candidate", size(applicationsIds)) }}?
      </span>
    </template>
    <template v-slot:body>
      <div class="accept-booking-description">
        An interview will be arranged by the Managed Service Provider.
      </div>
    </template>
    <template v-slot:actions>
      <div class="accept-booking-actions">
        <PrimaryButton @click.native="cancel" text>
          Cancel
        </PrimaryButton>
        <PrimaryButton @click.native="handleSubmit" :loading="isLoading">
          Request {{ pluralize("Interview", size(applicationsIds)) }}
        </PrimaryButton>
      </div>
    </template>
  </BaseDialog>
</template>

<script>
import BaseDialog from "@/components/common/BaseDialog";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import { createNamespacedHelpers } from "vuex";
import { REQUEST_INTERVIEW } from "@/store/modules/applications/actions";
import { APPLICATION_NAMESPACE } from "@/store/modules/applications";
import pluralize from "pluralize";
import { map, size } from "lodash";

const { mapActions } = createNamespacedHelpers(APPLICATION_NAMESPACE);
export default {
  name: "RequestInterviewDialog",
  components: { PrimaryButton, BaseDialog },
  props: {
    isOpen: Boolean,
    applicationsIds: Array
  },
  data() {
    return {
      isLoading: false
    };
  },
  methods: {
    ...mapActions({
      requestInterview: REQUEST_INTERVIEW
    }),
    async handleSubmit() {
      this.isLoading = true;
      const requests = map(this.applicationsIds, applicationId =>
        this.requestInterview(applicationId)
      );
      await Promise.all(requests);
      this.postSuccesMessage(size(requests));
      this.$emit("close", { success: true });
      this.isLoading = false;
    },
    cancel() {
      this.$emit("close", { success: false });
    },
    pluralize,
    size
  }
};
</script>

<style lang="scss">
.accept-booking-description {
  min-height: 100px;
}
</style>
