export const CLIENT_INVOICE_SET_MODEL = "client-invoice-set";

const clientInvoiceSetModel = {
  client_id: "",
  end_date: "",
  "created-at": "",
  "updated-at": "",
  client: {
    jsonApi: "hasOne",
    type: "organisations"
  }
};

export default clientInvoiceSetModel;
