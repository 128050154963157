<template>
  <div class="report-request data-table separator-hidden">
    <datatable
      hide-row-separator
      hide-default-footer
      :headers="headers"
      :items="userReports"
      :itemsPerPage="15"
      :isLoading="isFetchingReports"
      @onClearFilters="clearFilters"
    >
      <template v-slot:name="{ item }">
        <div class="report-request__file my-2 d-flex align-items-center">
          <v-img
            src="@/assets/svg/file__icon-excel.svg"
            class="file-icon ml-1"
          />
          <div class="ml-3">
            <span>{{ item.name }}</span>
            <small class="text-muted d-block" v-if="item.filters">
              {{ item.filters }}
            </small>
          </div>
        </div>
      </template>
      <template v-slot:action="{ item }">
        <div class="report-request__action d-flex justify-content-end">
          <primary-button
            color="primary"
            class="my-1 "
            v-if="item.status === 'complete'"
            :loading="isDownloading[item.id]"
            @click="handleDownload(item.id)"
          >
            Download
          </primary-button>
          <error-button
            light
            class="my-1 ml-2"
            v-if="item.status !== 'requested'"
            :loading="isDeleting[item.id]"
            @click="handleDelete(item.id)"
          >
            Delete
          </error-button>
        </div>
      </template>
      <template v-slot:status="{ item }">
        <Chip
          :color="getColorByStatus(item.status)"
          class="chip--status"
          shrink
        >
          {{ item.status | upperCase }}
        </Chip>
      </template>
    </datatable>
  </div>
</template>

<script>
import Datatable from "@/components/common/Datatable";
import ErrorButton from "@/components/common/Button/ErrorButton";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import Chip from "@/components/common/Chip";
import { createNamespacedHelpers } from "vuex";
import { EXPORTS_NAMESPACE } from "@/store/modules/exports";
import {
  DELETE_USER_REPORT,
  FETCH_USER_REPORTS
} from "@/store/modules/exports/action-types";
import { map } from "lodash";
import { getToken } from "@/services/utils";
import APIService from "@/services/APIService";
import { forceFileDownload } from "@/utils/files";
import { baseDateFilter } from "@/utils/filters/baseDateFilter";

const { mapState, mapActions } = createNamespacedHelpers(EXPORTS_NAMESPACE);

export default {
  name: "UserReports",
  components: {
    Datatable,
    PrimaryButton,
    ErrorButton,
    Chip
  },
  data() {
    return {
      isLoading: false,
      isDeleting: [],
      isDownloading: []
    };
  },
  async created() {
    await this.fetchUserReports();
  },
  computed: {
    ...mapState({
      isFetchingReports: state => state.isFetchingReports,
      userReports: state =>
        map(state.userReports, userReport => ({
          id: userReport.id,
          name: userReport["report-title"],
          status: userReport["report-status"],
          generatedAt: userReport["report-generated-at"]
            ? baseDateFilter(userReport["report-generated-at"])
            : "",
          expiresAt: userReport["report-expires-at"]
            ? baseDateFilter(userReport["report-expires-at"])
            : "",
          filters: userReport["report-filters"]
        }))
    }),
    headers() {
      return [
        {
          text: "Report",
          value: "name",
          class: "min-width-250",
          classMobile: "flex-item--full-width"
        },
        {
          text: "Date Generated",
          value: "generatedAt",
          class: "max-width-150"
        },
        {
          text: "Expiration Date",
          value: "expiresAt",
          class: "max-width-150"
        },
        {
          text: "Status",
          value: "status",
          class: "min-width-150"
        },
        {
          text: "",
          value: "action",
          classMobile: "flex-item--full-width"
        }
      ];
    }
  },
  methods: {
    ...mapActions({
      fetchUserReports: FETCH_USER_REPORTS,
      deleteUserReport: DELETE_USER_REPORT
    }),
    clearFilters() {
      this.attributeFilters = {};
      this.fetchUserReports();
    },
    getColorByStatus(status) {
      switch (status.toLowerCase()) {
        case "requested":
          return "blue";
        case "complete":
          return "green";
        case "error":
        case "expired":
          return "red";
        default:
          return "gray";
      }
    },
    async handleDownload(report) {
      this.$set(this.isDownloading, report, true);
      const token = `token=${getToken()}`;
      const downloadUrl = `${APIService.host}/user-reports/${report}/file?${token}`;
      // solve cypress issue on download
      if (window.Cypress) {
        return;
      }

      await forceFileDownload(downloadUrl);
      setTimeout(() => {
        this.$set(this.isDownloading, report, false);
      }, 1000);
    },
    async handleDelete(report) {
      this.$set(this.isDeleting, report, true);
      await this.deleteUserReport(report);
      this.$set(this.isDeleting, report, false);
      await this.fetchUserReports();
    }
  }
};
</script>

<style lang="scss" scoped>
.report-request {
  &__file {
    align-items: center;
  }

  &__action {
    justify-content: flex-end;
  }

  .file-icon {
    width: 40px;
    height: 50px;
    flex: none;
  }
}
</style>
