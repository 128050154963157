<template>
  <div class="list-item my-1">
    <div class="file-icon mr-3">
      <v-img :src="icon" />
    </div>
    <v-list-item-content>
      <div class="item-right">
        <div class="description">
          <div class="d-flex" :class="{ 'flex-column': isMobile }">
            <span class="font-weight-bold">{{ file.name }}</span>
          </div>
          <div class="status-wrapper">
            <div class="application-status-item">
              <span class="mx-1">{{ file.userName }}</span> |
              <span class="mx-1">{{ file.organisationName }}</span> |
              <span class="mx-1">{{ file.updatedAt | fullDate }}</span>
            </div>
          </div>
        </div>
      </div>
    </v-list-item-content>
  </div>
</template>

<script>
import mime from "mime-types";

export default {
  props: {
    file: {
      type: Object,
      required: true
    }
  },
  computed: {
    extension() {
      const { file } = this.file;
      return file.extension || (file.mimeType && mime.extension(file.mimeType));
    },
    icon() {
      switch (this.extension) {
        case "pdf":
          return require("@/assets/svg/file__icon-pdf.svg");
        case "png":
        case "jpg":
        case "gif":
        case "jpeg":
        case "bmp":
        case "svg":
          return require("@/assets/svg/file__icon-image.svg");
        case "doc":
        case "docx":
          return require("@/assets/svg/file__icon-word.svg");
        case "xls":
        case "xlsx":
        case "csv":
          return require("@/assets/svg/file__icon-excel.svg");
        default:
          return require("@/assets/svg/file__icon-text.svg");
      }
    }
  }
};
</script>
