import {
  handleWithErrorMessage,
  handleWithGlobalMessage,
  addGlobalMessage,
  errorHandler
} from "@/services/utils";
import organisationScheduleApi from "@/services/modules/organisations/organisation-schedules";
import {
  SET_IS_FETCHING_CURRENT_SCHEDULE,
  SET_CURRENT_PUBLISHING_SCHEDULE,
  SET_IS_FETCHING_SCHEDULE_TEMPLATES,
  SET_PUBLISHING_SCHEDULE_TEMPLATES,
  ADD_NEW_PUBLISHING_SCHEDULES,
  REMOVE_AGENCY_FROM_TIER
} from "@/store/modules/organisations/organisation-schedules/mutations";

export const FETCH_ORGANISATION_SCHEDULES = "FETCH_ORGANISATION_SCHEDULES";
export const FETCH_PUBLISHING_SCHEDULE_TEMPLATES =
  "FETCH_PUBLISHING_SCHEDULE_TEMPLATES";
export const GET_PUBLISHING_SCHEDULE = "GET_PUBLISHING_SCHEDULE";
export const CREATE_PUBLISHING_SCHEDULE = "CREATE_PUBLISHING_SCHEDULE";
export const UPDATE_PUBLISHING_SCHEDULE = "UPDATE_PUBLISHING_SCHEDULE";
export const DELETE_PUBLISHING_SCHEDULE = "DELETE_PUBLISHING_SCHEDULE";
export const ADD_AGENCIES_TO_SCHEDULE_TIER = "ADD_AGENCIES_TO_SCHEDULE_TIER";
export const DELETE_AGENCY_FROM_TIER = "DELETE_AGENCY_FROM_TIER";
export const DELETE_TIER = "DELETE_TIER";
export const EDIT_TIER = "EDIT_TIER";
export const ADD_TIER = "ADD_TIER";

const actions = {
  async [FETCH_PUBLISHING_SCHEDULE_TEMPLATES]({ commit, dispatch }) {
    return handleWithErrorMessage({
      dispatch,
      request: organisationScheduleApi(commit).getPublishingScheduleTemplates,
      setLoadingFunction: isLoading =>
        commit(SET_IS_FETCHING_SCHEDULE_TEMPLATES, isLoading),
      setDataFunction: ({ data }) =>
        commit(SET_PUBLISHING_SCHEDULE_TEMPLATES, data)
    });
  },
  async [GET_PUBLISHING_SCHEDULE]({ commit, dispatch }, id) {
    return handleWithErrorMessage({
      dispatch,
      request: () => organisationScheduleApi(commit).get(id),
      setLoadingFunction: isLoading =>
        commit(SET_IS_FETCHING_CURRENT_SCHEDULE, isLoading),
      setDataFunction: ({ data }) =>
        commit(SET_CURRENT_PUBLISHING_SCHEDULE, data)
    });
  },
  async [CREATE_PUBLISHING_SCHEDULE](
    { commit, dispatch },
    newPublishingSchedule
  ) {
    const { data } = await handleWithGlobalMessage(
      () => organisationScheduleApi(commit).create(newPublishingSchedule),
      dispatch
    );
    commit(ADD_NEW_PUBLISHING_SCHEDULES, data);
    return data;
  },
  async [UPDATE_PUBLISHING_SCHEDULE]({ commit, dispatch }, editedSchedule) {
    try {
      const { data, meta } = await organisationScheduleApi(commit).update(
        editedSchedule
      );
      addGlobalMessage(dispatch, { type: "success", meta });
      commit(SET_CURRENT_PUBLISHING_SCHEDULE, data);
      return data;
    } catch (exception) {
      errorHandler(dispatch, exception);
    }
  },
  async [DELETE_PUBLISHING_SCHEDULE]({ commit, dispatch }) {
    return handleWithGlobalMessage(
      () => organisationScheduleApi(commit).delete(),
      dispatch
    );
  },
  async [DELETE_AGENCY_FROM_TIER]({ commit, dispatch }, { tierId, agency }) {
    try {
      const { meta } = await organisationScheduleApi(
        commit
      ).deleteAgencyFromTier(tierId, agency);
      addGlobalMessage(dispatch, { type: "success", meta });
      commit(REMOVE_AGENCY_FROM_TIER, { tierId, agency });
    } catch (exception) {
      errorHandler(dispatch, exception);
    }
  },
  async [DELETE_TIER]({ commit, dispatch }, id) {
    return handleWithGlobalMessage(
      () => organisationScheduleApi(commit).deleteTier(id),
      dispatch
    );
  },
  async [EDIT_TIER]({ commit, dispatch }, { id, editedTier }) {
    return handleWithGlobalMessage(
      () =>
        organisationScheduleApi(commit).editTier({
          id,
          editedTier
        }),
      dispatch
    );
  },
  async [ADD_TIER]({ commit, dispatch }, tier) {
    return handleWithGlobalMessage(
      () => organisationScheduleApi(commit).addTier(tier),
      dispatch
    );
  },
  async [ADD_AGENCIES_TO_SCHEDULE_TIER](
    { commit, dispatch },
    { tierId, agencies }
  ) {
    return handleWithGlobalMessage(
      () =>
        organisationScheduleApi(commit).addAgenciesToScheduleTier(
          tierId,
          agencies
        ),
      dispatch
    );
  }
};

export default actions;
