<template>
  <BaseDialog :isOpen="isOpen">
    <template v-slot:title>
      <span>
        Are you sure you want to send the booking to {{ agency.name }}?
      </span>
    </template>
    <template v-slot:body>
      <div class="send-to-agency-description">
        This will immediately send the booking to {{ agency.name }}, allowing
        them to submit candidates for the booking.
      </div>
    </template>
    <template v-slot:actions>
      <div class="send-to-agency-actions">
        <PrimaryButton @click.native="cancel" text>
          Cancel
        </PrimaryButton>
        <PrimaryButton @click.native="handleSubmit" :loading="isLoading">
          Send to Agency
        </PrimaryButton>
      </div>
    </template>
  </BaseDialog>
</template>

<script>
import BaseDialog from "@/components/common/BaseDialog";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import {
  BOOKINGS_NAMESPACE,
  PUBLISH_BOOKING
} from "@/store/modules/bookings/actions";
import { createNamespacedHelpers } from "vuex";

const { mapActions } = createNamespacedHelpers(BOOKINGS_NAMESPACE);

export default {
  name: "SendToAgencyDialog",
  components: { PrimaryButton, BaseDialog },
  props: {
    isOpen: Boolean,
    agency: Object
  },
  data() {
    return {
      isLoading: false
    };
  },
  inject: ["currentBookingId"],
  methods: {
    ...mapActions({
      sendToAgency: PUBLISH_BOOKING
    }),
    async handleSubmit() {
      this.isLoading = true;
      try {
        // TODO: fill unitId
        await this.sendToAgency({
          bookingId: this.currentBookingId,
          unitId: this.agency.id
        });
        this.$emit("close");
        this.$emit("publish");
      } finally {
        this.isLoading = false;
      }
    },
    cancel() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss">
.send-to-agency-description {
  min-height: 100px;
}
.send-to-agency-actions {
  padding: 0.5rem;
}
</style>
