import {
  TOGGLE_DRAWER,
  UPDATE_IMPERSONATE_USER,
  TOGGLE_IMPERSONATE_DIALOG,
  SET_API_AVAILABILITY
} from "./mutation-types";

const state = {
  drawer: null,
  impersonateDialog: {
    dialog: false,
    title: "Impersonate User"
  },
  isImpersonating:
    JSON.parse(localStorage.getItem("impersonateUserId")) !== null,
  impersonateUser: {},
  isApiNotAvailable: false
};

const mutations = {
  [TOGGLE_DRAWER](state) {
    state.drawer = !state.drawer;
  },
  [TOGGLE_IMPERSONATE_DIALOG](state, action) {
    state.impersonateDialog.dialog = action;
  },
  [UPDATE_IMPERSONATE_USER](state, { user }) {
    state.impersonateUser = user;
    localStorage.setItem("impersonateUserId", user.id);
    localStorage.setItem("impersonateUser", JSON.stringify(user));
    state.isImpersonating = !!user.id;
  },
  [SET_API_AVAILABILITY](state, isAvaiable) {
    state.isApiNotAvailable = isAvaiable;
  }
};

export default {
  namespaced: true,
  state,
  mutations
};
