<template>
  <BaseAction>
    <PrimaryButton @click.native="openDialog" :full-width="isMobile">
      Credit Timesheet
    </PrimaryButton>
    <CreditDialog
      :isOpen="isDialogOpen"
      @close="closeDialog"
      @onUpdate="onUpdate"
      :id="id"
    />
  </BaseAction>
</template>

<script>
import CreditDialog from "@/views/timesheets/components/dialogs/CreditDialog";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import BaseAction from "@/components/common/BaseAction";

export default {
  name: "CreditTimesheetAction",
  components: {
    PrimaryButton,
    BaseAction,
    CreditDialog
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isDialogOpen: false
    };
  },
  methods: {
    openDialog() {
      this.isDialogOpen = true;
    },
    closeDialog() {
      this.isDialogOpen = false;
    },
    onUpdate() {
      this.closeDialog();
      this.$emit("onUpdate");
    }
  }
};
</script>
