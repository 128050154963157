<template>
  <div class="input-item report-field">
    <div class="inputs">
      <div class="drag d-flex">
        <v-icon>mdi-menu</v-icon>
      </div>
      <ValidationProvider
        v-slot="{ errors }"
        :name="`fields-${index}`"
        rules="required"
      >
        <Select
          autocomplete
          placeholder="Field"
          :items="allFieldsNames"
          item-text="text"
          item-value="field"
          :value="selectedField.field"
          :errorMessages="showValidationErrors ? errors : ''"
          @change="handleFieldChange($event)"
        />
      </ValidationProvider>
      <ValidationProvider
        v-if="withValue"
        v-slot="{ errors }"
        :name="`fieldValue-${index}`"
        rules="required"
      >
        <Select
          v-if="withValue"
          placeholder="Measure"
          :items="measures"
          item-text="text"
          item-value="value"
          :errorMessages="showValidationErrors ? errors : ''"
          :value="selectedField.measure"
          @change="handleMeasureChange($event)"
        />
      </ValidationProvider>
    </div>
    <v-btn icon @click="onDelete"><v-icon>mdi-close</v-icon></v-btn>
  </div>
</template>

<script>
import { MEASURE_TYPES } from "@/constants/reports";
import Select from "@/components/common/Select";
import { getFieldOptions } from "@/utils/reports";
import { ValidationProvider } from "vee-validate";

export default {
  name: "ReportField",
  props: {
    selectedField: Object,
    availableFields: Array,
    hasGrouping: Boolean,
    onChange: Function,
    onDelete: Function,
    showValidationErrors: Boolean,
    labels: Object,
    index: Number
  },
  components: {
    Select,
    ValidationProvider
  },
  data() {
    return {
      measureTypes: MEASURE_TYPES
    };
  },
  computed: {
    allFieldsNames() {
      return getFieldOptions(
        { fields: this.availableFields, labels: this.labels },
        this.hasGrouping
      );
    },
    field() {
      return this.availableFields.find(
        field => field.field === this.selectedField.field
      );
    },
    measures() {
      return this.field.measures.map(value => ({
        value,
        text: this.measureTypes[value] || value
      }));
    },
    withValue() {
      return (
        this.hasGrouping &&
        this.field.measures &&
        this.field.measures.length &&
        this.field.field !== "count"
      );
    }
  },
  methods: {
    handleFieldChange(field) {
      const result = { field };
      const { measures = [] } =
        this.availableFields.find(avField => avField.field === field) || {};
      if (measures.length && field !== "count") {
        result.measure = measures[0];
      }
      this.onChange(result);
    },
    handleMeasureChange(measure) {
      this.onChange({ ...this.selectedField, measure });
    }
  },
  watch: {
    hasGrouping(newValue) {
      if (!newValue && this.selectedField.field === "count") {
        this.onDelete();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.report-field {
  .drag {
    width: 50px;
    height: 50px;
    flex-grow: 0;
    border: 1px solid $default-line;
    border-right: 0;
    border-radius: 4px 0 0 4px;
    align-items: center;
    justify-content: center;
  }
}
</style>
