<template>
  <v-row class="shift-step">
    <v-col class="col-lg-10 col-xl-7 col-12">
      <h1 class="pt-3 mb-3">Shift Calendar</h1>
      <ValidationObserver ref="shiftsValidation">
        <ShiftStepMobileCalendar
          v-if="isMobile"
          :shift-patterns="mobileShiftPatterns"
        />
        <ShiftStepCalendar :events="events" v-else />
        <MessageBox v-if="areShiftsOverlapping" type="warning" class="my-2">
          Shift patterns overlap
        </MessageBox>
        <ShiftPattern
          v-for="(shift, index) in shifts"
          :key="shift.id"
          @edit="editShift"
          @delete="deleteShift"
          @duplicate="duplicateShift"
          :initial-shift-pattern="shift"
          :id="shift.id"
          :pattern-number="index + 1"
          :color="shift.color"
          :validate="validate"
          :isEdit="isEdit"
        />
        <PrimaryButton light fullWidth @click.native="newShiftRow" class="mt-5">
          Add Shift +
        </PrimaryButton>
        <slot />
      </ValidationObserver>
    </v-col>
  </v-row>
</template>

<script>
import ShiftPattern from "@/views/bookings/NewBooking/steps/components/ShiftPattern";
import generateId from "uuid/v4";
import { RRule } from "rrule";
import ShiftStepCalendar from "@/views/bookings/NewBooking/steps/components/ShiftStepCalendar";
import styles from "@/sass/abstracts/_variables.scss";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import {
  formatDateForVCalendar,
  getSequencesOfDates,
  hasOverlappingDates
} from "@/utils/time";
import { ValidationObserver } from "vee-validate";
import ShiftStepMobileCalendar from "@/views/bookings/NewBooking/steps/components/ShiftStepMobileCalendar";
import { convertToShiftPatterns } from "@/utils/bookings";
import MessageBox from "@/components/common/MessageBox";
import { normalizedShift } from "@/utils/shifts";

export default {
  name: "ShiftStep",
  components: {
    MessageBox,
    ShiftStepMobileCalendar,
    PrimaryButton,
    ShiftStepCalendar,
    ShiftPattern,
    ValidationObserver
  },
  data() {
    return {
      colors: [
        styles.primary,
        styles.yellow,
        styles.success,
        styles.purple,
        styles.default_line,
        styles.selected
      ],
      shifts: this.initialShifts
    };
  },
  props: {
    validate: Boolean,
    initialShifts: {
      type: Array,
      required: false,
      default: () => []
    },
    isNewBooking: {
      type: Boolean,
      required: false,
      default: false
    },
    isEdit: Boolean
  },
  computed: {
    mobileShiftPatterns() {
      const shiftPatternDays = this.shifts.reduce(
        (shiftEvents, { recurrence }) => {
          if (this.isRecurrenceObjectInvalid(recurrence)) {
            return shiftEvents;
          }

          const eventOccurrences = new RRule(recurrence).between(
            recurrence.dtstart,
            recurrence.until,
            true
          );

          return [...shiftEvents, ...eventOccurrences];
        },
        []
      );
      return getSequencesOfDates(shiftPatternDays);
    },
    events() {
      return this.shifts.reduce((shiftEvents, shift, index) => {
        const { recurrence, color } = shift;
        if (this.isRecurrenceObjectInvalid(recurrence)) {
          return shiftEvents;
        }

        const eventOccurrences = new RRule(recurrence).all();
        return [
          ...shiftEvents,
          ...eventOccurrences.map(date => ({
            name: `Shift pattern ${index + 1}`,
            start: formatDateForVCalendar(date),
            end: formatDateForVCalendar(date),
            color
          }))
        ];
      }, []);
    },
    areShiftsOverlapping() {
      const dates = this.shifts.reduce((shiftEvents, shift) => {
        const { recurrence, fromHour, toHour } = shift;
        if (
          this.isRecurrenceObjectInvalid(recurrence) ||
          !fromHour ||
          !toHour
        ) {
          return false;
        }

        const eventOccurrences = new RRule(recurrence).between(
          recurrence.dtstart,
          recurrence.until,
          true
        );

        return [
          ...shiftEvents,
          ...eventOccurrences.map(date => ({
            date: formatDateForVCalendar(date),
            fromHour,
            toHour
          }))
        ];
      }, []);
      return dates && hasOverlappingDates(dates);
    },
    normalizedShifts() {
      return this.shifts.map(shift => normalizedShift(shift));
    }
  },
  methods: {
    isDateCorrect(date) {
      return date && Boolean(new Date(date).getTime());
    },
    stepForward() {
      this.$emit("nextStep", { shifts: this.shifts });
    },
    editShift(editedProps, id) {
      this.shifts = this.shifts.map(shift => {
        if (shift.id === id) {
          return Object.assign(shift, editedProps);
        }
        return shift;
      });
      this.$emit("change", { shifts: this.normalizedShifts });
    },
    deleteShift(id) {
      if (this.shifts.length === 1) {
        this.shifts = [this.getDefaultShiftPattern()];
      } else {
        this.shifts = this.shifts.filter(shift => shift.id !== id);
      }
      this.$emit("change", { shifts: this.normalizedShifts });
    },
    duplicateShift(shift) {
      const id = generateId();
      const duplicatedShift = {
        ...shift,
        id,
        color: this.getNextColor(),
        recurrence: { ...shift.recurrence }
      };
      this.shifts = [...this.shifts, duplicatedShift];
      this.$emit("change", { shifts: this.normalizedShifts });
    },
    newShiftRow() {
      this.shifts = [...this.shifts, this.getDefaultShiftPattern()];
      this.$emit("change", { shifts: this.normalizedShifts });
    },
    getDefaultShiftPattern() {
      return {
        id: generateId(),
        color: this.getNextColor(),
        workersRequired: 1,
        recurrence: { byweekday: [], freq: RRule.WEEKLY },
        status: this.isNewBooking ? "approved" : "draft"
      };
    },
    getNextColor() {
      const firstColor = this.colors.shift();
      this.colors = [...this.colors, firstColor];
      return firstColor;
    },
    getShiftPatterns(shifts) {
      return convertToShiftPatterns(shifts).map(shift => ({
        ...shift,
        color: this.getNextColor(),
        status: this.isNewBooking ? "approved" : "draft"
      }));
    },
    isRecurrenceObjectInvalid(recurrence) {
      return (
        !recurrence ||
        !recurrence.freq ||
        !this.isDateCorrect(recurrence.dtstart) ||
        !this.isDateCorrect(recurrence.until)
      );
    }
  },
  watch: {
    async validate(shouldValidate) {
      if (shouldValidate) {
        const isValid = await this.$refs.shiftsValidation.validate();
        if (isValid) {
          this.$emit("valid");
        }
      }
    }
  },
  mounted() {
    const initialShiftPatterns = this.getShiftPatterns(this.initialShifts);
    if (initialShiftPatterns.length) {
      this.shifts = initialShiftPatterns;
    } else {
      this.shifts = [this.getDefaultShiftPattern()];
    }
  }
};
</script>

<style lang="scss">
.shift-step {
  display: flex;
  justify-content: center;

  @media only screen and (max-width: $mobile-breakpoint - 1) {
    margin: 0;
  }

  .mobile-calendar {
    border: none;
  }
}
</style>
