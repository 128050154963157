<template>
  <div class="bulk-timesheet-entry">
    <div
      class="my-4"
      v-for="(agencyEntries, key, index) in groupedEntries"
      :key="index"
    >
      <div class="list-item ml-1">
        <v-list-item-avatar tile>
          <v-img
            height="30px"
            contain
            :src="require('@/assets/svg/domain.svg')"
          />
        </v-list-item-avatar>
        <v-list-item-content>
          <h3>{{ key }}</h3>
        </v-list-item-content>
      </div>
      <v-simple-table class="my-4 mx-0">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left" id="worker">
                Worker
              </th>
              <th class="text-left" id="worker">
                Activity
              </th>
              <th class="text-left" id="time">
                Time
              </th>
              <th class="text-left" id="break">
                Break
              </th>
              <th class="text-left" id="absent">
                Absent
              </th>
              <th class="text-left" id="total-hours">
                Total Hours This Week
              </th>
              <th id="none" />
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(entry, index) in agencyEntries.entries"
              :key="index"
              :class="{ 'gray-bg': entry.shouldRemove }"
            >
              <template v-if="!entry.shouldRemove">
                <td
                  class="pr-4 min-width-250"
                  :class="{ 'pb-14': entry.showComment && !entry.shouldRemove }"
                >
                  <div v-if="!entry.isOverTime">
                    <div class="list-item with-icon multiple-select-list">
                      <div>
                        <div v-if="selectMultiple" class="multiselect-wrapper">
                          <v-checkbox class="checkbox-large-icon mr-2" />
                        </div>
                        <v-list-item-avatar v-else tile>
                          <v-img
                            height="30px"
                            contain
                            src="@/assets/svg/user-icon.svg"
                          />
                        </v-list-item-avatar>
                      </div>
                      <v-list-item-content>
                        <div class="item-right">
                          <div
                            v-if="entry.worker"
                            class="description"
                            :class="{ striked: entry.shouldRemove }"
                          >
                            <div>U-{{ entry.workerId }}</div>
                            <div class="d-flex mt-1 w-full">
                              <strong>{{ entry.worker | fullName }}</strong>
                              <strong class="ml-1"
                                >| {{ entry.agency.name }}</strong
                              >
                            </div>
                          </div>
                          <div v-if="allowComment">
                            <v-icon
                              v-if="entry.shouldRemove"
                              large
                              color="grey"
                            >
                              mdi-message-plus-outline
                            </v-icon>
                            <v-icon
                              v-else
                              large
                              color="primary"
                              @click="entry.showComment = !entry.showComment"
                            >
                              mdi-message-plus-outline
                            </v-icon>
                          </div>
                        </div>
                      </v-list-item-content>
                    </div>
                    <div
                      class="comment"
                      v-if="
                        entry.showComment && !entry.shouldRemove && allowComment
                      "
                    >
                      <v-textarea
                        v-model="entry.comment"
                        rows="2"
                        background-color="amber lighten-5"
                        @change="handleChange"
                        autofocus
                      />
                    </div>
                  </div>
                </td>
                <td
                  class="max-width-200 pb-2"
                  :class="{ 'pb-15': entry.showComment && !entry.shouldRemove }"
                >
                  <Select
                    v-model="entry.payRateId"
                    label=""
                    item-text="activityTypeName"
                    item-value="id"
                    :items="entry.activities"
                    single-line
                    hide-details
                  />
                </td>
                <td
                  :class="{ 'pb-14': entry.showComment && !entry.shouldRemove }"
                >
                  <TimeRange
                    :fromHour="entry.startTime"
                    :toHour="entry.endTime"
                    :disabled="entry.shouldRemove || entry.absent"
                    @updateRange="event => updateHours(event, entry)"
                    required
                  />
                </td>
                <td
                  :class="{ 'pb-14': entry.showComment && !entry.shouldRemove }"
                >
                  <v-text-field
                    v-mask="'##'"
                    v-model="entry.breakMinutes"
                    class="ml-2 mins"
                    placeholder="00"
                    suffix="mins"
                    :disabled="entry.shouldRemove || entry.absent"
                    @change="handleChange"
                  />
                </td>
                <td
                  class="pb-4"
                  :class="{ 'pb-15': entry.showComment && !entry.shouldRemove }"
                >
                  <v-switch
                    v-model="entry.absent"
                    class="ml-4"
                    color="#4B5D69"
                    :disabled="showLoader"
                    :input-value="entry.absent"
                    :readonly="entry.shouldRemove"
                    @change="handleAbsent(entry)"
                    inset
                    hide-details
                  />
                </td>
                <td
                  :class="{
                    'pb-14': entry.showComment && !entry.shouldRemove,
                    'red--text font-weight-medium': getWorkerTotalWeekHours(
                      entry
                    ).isOverTime,
                    striked: entry.shouldRemove
                  }"
                >
                  {{ getWorkerTotalWeekHours(entry).formattedTime }}
                  {{ getWorkerTotalWeekHours(entry).isOverTime ? "!" : "" }}
                  <v-btn
                    text
                    icon
                    @click="addOvertimeEntry(entry, index)"
                    v-if="
                      getWorkerTotalWeekHours(entry).isOverTime &&
                        !entry.isOverTime
                    "
                  >
                    <v-icon class="red--text">mdi-plus</v-icon>
                  </v-btn>
                </td>
                <td
                  :class="{ 'pb-14': entry.showComment && !entry.shouldRemove }"
                >
                  <v-btn text icon @click="handleRemove(entry)">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </td>
              </template>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-skeleton-loader v-if="generatingEntries" type="list-item-three-line" />
      <SelectWorkerByAgency
        :entries="agencyEntries.entries"
        :agencyId="agencyEntries.agencyId"
        :generatingTimesheets="generatingTimesheets"
        @add="addWorkers"
      />
    </div>
    <SelectWorkerByAgency
      v-if="!size(groupedEntries)"
      :entries="[]"
      :generatingTimesheets="generatingTimesheets"
      @add="addWorkers"
    />
  </div>
</template>

<script>
import TimeRange from "@/views/timesheets/components/TimeRange";
import { mask } from "vue-the-mask";
import { isEmpty, map, reduce, sortBy, find, size, filter } from "lodash";
import {
  getTimesheetDates,
  groupEntriesByAgency,
  getBeforeAndAfterWeekDates,
  getTimesheetTotalMinutes
} from "@/utils/timesheets";
import {
  formatDate,
  getMinutesAsHrsAndMinsTime,
  getTotalMinutes
} from "@/utils/time";
import { isPermissioned } from "@/utils/permissions";
import Select from "@/components/common/Select";
import SelectWorkerByAgency from "@/components/timesheets/SelectWorkerByAgency";
import { createNamespacedHelpers } from "vuex";
import { TIMESHEETS_NAMESPACE } from "@/store/modules/timesheets";
import { GET_ALL_TIMESHEETS } from "@/store/modules/timesheets/actions";
import generateId from "uuid/v4";

const TIME_FORMAT = "HH:mm";
const EXPECTED_WEEK_HOURS = 48;
const DAY_MINUTES = 24 * 60;

const { mapActions: timesheetMapActions } = createNamespacedHelpers(
  TIMESHEETS_NAMESPACE
);

export default {
  props: {
    timesheets: Array,
    generatingTimesheets: Boolean,
    bookingPayRates: Array,
    firstWeekday: String
  },
  directives: { mask },
  components: { TimeRange, Select, SelectWorkerByAgency },
  data() {
    return {
      entries: [],
      selectMultiple: false,
      validate: false,
      showLoader: false,
      generatingEntries: false
    };
  },
  computed: {
    isUnitTime() {
      return this.timesheet.timeUnitName === "hour";
    },
    allowComment() {
      return isPermissioned(`timesheets.comment`);
    },
    groupedEntries() {
      return groupEntriesByAgency(this.entries);
    }
  },
  methods: {
    ...timesheetMapActions({
      getTimesheets: GET_ALL_TIMESHEETS
    }),
    updateHours({ fromHour, toHour }, entry) {
      entry.startTime = fromHour;
      entry.endTime = toHour;
      this.handleChange();
    },
    handleAbsent(entry) {
      if (entry.absent) {
        entry.startTime = null;
        entry.endTime = null;
        entry.breakMinutes = 0;
      }
      this.handleChange();
    },
    handleRemove(entry) {
      entry.shouldRemove = !entry.shouldRemove;
      if (!entry.isOverTime) {
        this.removeOvertimeEntries(entry);
      }
      this.handleChange();
    },
    removeOvertimeEntries(entry) {
      map(this.entries, workerEntry => {
        if (
          workerEntry.dayDate === entry.dayDate &&
          workerEntry.workerId === entry.workerId
        ) {
          workerEntry.shouldRemove = true;
        }
      });
    },
    addOvertimeEntry(entry, index) {
      let overTimeEntry = {
        ...entry,
        payRateId: "",
        activities: filter(
          entry.activities,
          ({ id }) => id !== entry.payRateId
        ),
        startTime: null,
        endTime: null,
        breakMinutes: 0,
        isOverTime: true
      };
      this.entries.splice(index + 1, 0, overTimeEntry);
    },
    async generateEntries() {
      const oldEntries = this.entries;
      this.generatingEntries = true;
      this.entries = [];
      const activities = sortBy(this.bookingPayRates, "activityTypeName");
      await Promise.all(
        map(
          this.timesheets,
          async ({
            id,
            weekCommencing,
            weekEnding,
            worker,
            entries,
            shift
          }) => {
            let workerTotalWeekMinutes = await this.getWorkerWeekMinutes({
              workerId: worker.id,
              startDate: weekCommencing
            });
            const expectedBreakMinutes = shift.expectedBreakMinutes;
            const isOvertime = false;
            if (!isEmpty(entries)) {
              const timesheetEntries = reduce(
                entries,
                (formattedEntries, entry) => {
                  if (entry.shiftId === Number(shift.id)) {
                    const payRateId =
                      entry["pay-rates"] && entry["pay-rates"].id;
                    formattedEntries.push({
                      ...entry,
                      worker: worker,
                      workerId: worker && worker.id,
                      agency: worker && worker.organisation,
                      payRateId,
                      showComment: false,
                      shouldRemove: false,
                      timesheet: { id },
                      activities,
                      shift,
                      workerTotalWeekMinutes,
                      isOvertime
                    });
                  }
                  return formattedEntries;
                },
                []
              );
              this.entries = [...this.entries, ...timesheetEntries];
            } else {
              const dates = getTimesheetDates(weekCommencing, weekEnding);
              map(dates, date => {
                const oldEntry = find(
                  oldEntries,
                  entry =>
                    !entry.isOverTime &&
                    entry.dayDate === date &&
                    entry.workerId === worker.id
                );
                const overTimeEntry = find(
                  oldEntries,
                  entry =>
                    entry.isOverTime &&
                    entry.dayDate === date &&
                    entry.workerId === worker.id &&
                    !entry.shouldRemove
                );
                const entry = {
                  uuid: generateId(),
                  absent: oldEntry ? oldEntry.absent : false,
                  timesheet: { id },
                  dayDate: date,
                  startTime: oldEntry
                    ? oldEntry.startTime
                    : formatDate({
                        date: weekCommencing,
                        endFormat: TIME_FORMAT
                      }),
                  endTime: oldEntry
                    ? oldEntry.endTime
                    : formatDate({
                        date: weekEnding,
                        endFormat: TIME_FORMAT
                      }),
                  payRateId: shift.payRateId,
                  worker: worker,
                  workerId: worker && worker.id,
                  agency: worker && worker.organisation,
                  showComment: false,
                  shouldRemove: oldEntry ? oldEntry.shouldRemove : false,
                  breakMinutes: oldEntry
                    ? oldEntry.breakMinutes
                    : expectedBreakMinutes,
                  expectedBreakMinutes,
                  activities,
                  shift,
                  workerTotalWeekMinutes,
                  isOvertime
                };
                this.entries.push(entry);
                if (overTimeEntry) {
                  this.entries.push(overTimeEntry);
                }
              });
            }
          }
        )
      );
      this.generatingEntries = false;
    },
    handleChange() {
      this.$emit("change", this.entries);
    },
    addWorkers(workers) {
      this.$emit("addWorkers", workers);
    },
    async getWorkerWeekMinutes({ workerId, startDate }) {
      const { beforeDate, afterDate } = getBeforeAndAfterWeekDates(
        this.firstWeekday,
        startDate
      );
      const { data: timesheets } = await this.fetchTimesheets({
        filter: {
          worker_id: [workerId],
          before_date: beforeDate,
          after_date: afterDate
        }
      });
      return getTimesheetTotalMinutes(timesheets);
    },
    async fetchTimesheets(requestParams) {
      return await this.getTimesheets(requestParams);
    },
    getWorkerTotalWeekMinutes(entry) {
      const workerEntries = filter(
        this.entries,
        ({ dayDate, workerId }) =>
          dayDate === entry.dayDate && workerId === entry.workerId
      );
      let totalWeekMinutes = entry.workerTotalWeekMinutes;

      map(workerEntries, workerEntry => {
        if (
          workerEntry.startTime &&
          workerEntry.endTime &&
          !workerEntry.shouldRemove
        ) {
          let currentMinutes =
            getTotalMinutes(workerEntry.startTime, workerEntry.endTime) -
            workerEntry.breakMinutes;
          // add day minutes to overnight work hours
          if (currentMinutes < 0) {
            currentMinutes += DAY_MINUTES;
          }

          totalWeekMinutes += currentMinutes;
        }
      });

      return totalWeekMinutes;
    },
    getWorkerTotalWeekHours(entry) {
      const totalWeekMinutes = this.getWorkerTotalWeekMinutes(entry);

      return {
        formattedTime: getMinutesAsHrsAndMinsTime(totalWeekMinutes),
        isOverTime: totalWeekMinutes / 60 > EXPECTED_WEEK_HOURS
      };
    },
    size
  },
  watch: {
    async timesheets() {
      await this.generateEntries();
      this.handleChange();
    }
  }
};
</script>

<style lang="scss">
.bulk-timesheet-entry {
  margin-top: 35px;
  .list-item .v-avatar.v-list-item__avatar.v-avatar--tile {
    width: 50px !important;
    height: 50px !important;
    min-width: 50px !important;
    margin-right: 12px;
    background-color: #f4f4f4;
  }
  .range-picker-wrapper .range-picker .time-picker-from {
    width: 70px;
    border-right: none;
    .v-text-field .v-input__slot {
      border-radius: 4px 0 0 4px;
      border-right: none;
    }
  }
  .range-picker-wrapper .range-picker .time-picker-to {
    width: 76px;
    .v-text-field .v-input__slot {
      border-radius: 0 4px 4px 0;
      border-left: none;
    }
    .v-text-field__slot {
      margin-left: 8px;
    }
  }
  .v-input.v-text-field {
    margin-top: 0;
  }
  .v-data-table td {
    padding: 0 5px;
  }
  th {
    font-weight: normal;
    color: black !important;
    font-size: 18px !important;
  }
  td {
    position: relative;
    .comment {
      position: absolute;
      right: 10px;
      bottom: 0;
      .v-textarea textarea {
        font-size: 14px;
        width: 350px;
      }
    }
  }
}

.bulk-timesheet-select {
  .theme--light.v-list-item.v-list-item--highlighted::before {
    background: inherit;
  }
}

.gray-bg {
  background: #e4e2e2;
}
.striked {
  text-decoration: line-through;
}
</style>
