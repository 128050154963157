import {
  handleWithErrorMessage,
  handleWithGlobalMessage
} from "@/services/utils";
import organisationUnitApi from "@/services/modules/organisations/organisation-units";
import {
  SET_IS_FETCHING_ORGANISATION_UNITS,
  SET_ORGANISATION_UNITS
} from "@/store/modules/organisations/organisation-units/mutations";

export const FETCH_ORGANISATION_UNITS = "FETCH_ORGANISATION_UNITS";
export const ARCHIVE_ORGANISATION_UNIT = "ARCHIVE_ORGANISATION_UNIT";
export const PUBLISH_ORGANISATION_UNIT = "PUBLISH_ORGANISATION_UNIT";
export const CREATE_ORGANISATION_UNIT = "CREATE_ORGANISATION_UNIT";
export const EDIT_ORGANISATION_UNIT = "EDIT_ORGANISATION_UNIT";
export const DUPLICATE_ORGANISATION_UNIT = "DUPLICATE_ORGANISATION_UNIT";
export const DELETE_ORGANISATION_UNIT = "DELETE_ORGANISATION_UNIT";
import { get } from "lodash";

const actions = {
  async [FETCH_ORGANISATION_UNITS]({ commit, dispatch, rootState }) {
    const userData = rootState.auth.userData;
    const organisationType = get(userData, "organisation.organisationType");
    const params = {
      filter: {
        organisation_type: `${organisationType}`
      }
    };

    if (organisationType) {
      return handleWithErrorMessage({
        dispatch,
        request: () => organisationUnitApi(commit).getAll(params),
        setLoadingFunction: isLoading =>
          commit(SET_IS_FETCHING_ORGANISATION_UNITS, isLoading),
        setDataFunction: ({ data }) => commit(SET_ORGANISATION_UNITS, data)
      });
    }
  },
  async [ARCHIVE_ORGANISATION_UNIT]({ commit, dispatch }, id) {
    await handleWithGlobalMessage(
      () => organisationUnitApi(commit).archiveOrganisationUnit(id),
      dispatch
    );
    dispatch(FETCH_ORGANISATION_UNITS);
  },
  async [CREATE_ORGANISATION_UNIT]({ commit, dispatch }, data) {
    await handleWithGlobalMessage(
      () => organisationUnitApi(commit).createOrganisationUnit(data),
      dispatch
    );
    dispatch(FETCH_ORGANISATION_UNITS);
  },
  async [EDIT_ORGANISATION_UNIT]({ commit, dispatch }, { organisation, id }) {
    await handleWithGlobalMessage(
      () =>
        organisationUnitApi(commit).editOrganisationUnit({ organisation, id }),
      dispatch
    );
    dispatch(FETCH_ORGANISATION_UNITS);
  },
  async [PUBLISH_ORGANISATION_UNIT]({ commit, dispatch }, id) {
    await handleWithGlobalMessage(
      () => organisationUnitApi(commit).publishOrganisationUnit(id),
      dispatch
    );
    dispatch(FETCH_ORGANISATION_UNITS);
  },
  async [DUPLICATE_ORGANISATION_UNIT]({ commit, dispatch }, id) {
    await handleWithGlobalMessage(
      () => organisationUnitApi(commit).duplicateOrganisationUnit(id),
      dispatch
    );
    dispatch(FETCH_ORGANISATION_UNITS);
  },
  async [DELETE_ORGANISATION_UNIT]({ commit, dispatch }, id) {
    await handleWithGlobalMessage(
      () => organisationUnitApi(commit).deleteDraftOrganisationUnit(id),
      dispatch
    );
    dispatch(FETCH_ORGANISATION_UNITS);
  }
};

export default actions;
