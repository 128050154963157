import { each, isNumber, includes, toLower, filter, reduce } from "lodash";
import { compareDates } from "@/utils/helpers";
import fuseSearch from "@/utils/filters/fuseSearch";
import moment from "moment";

/**
 * If filter value is equal to the table value
 * @param  {String} tableValue
 * @param  {String} filterValue
 */
export const equal = (tableValue, filterValue) => {
  if (!filterValue) return true;

  if (tableValue != null) {
    tableValue = tableValue.toString().toLowerCase();
  }

  return tableValue == filterValue.toString().toLowerCase();
};
/**
 * If filter value is equal to the table value
 * @param  {String} tableValue
 * @param  {String} filterValue
 */
export const like = (tableValue, filterValue) => {
  if (!filterValue) return true;

  if (tableValue) {
    tableValue = tableValue.toString().toLowerCase();
    filterValue = filterValue.toString().toLowerCase();

    return tableValue.indexOf(filterValue) > -1;
  }

  return false;
};
/**
 * If table value is greater than the filter
 * @param  {Any} tableValue
 * @param  {Any} filterValue
 */
export const greaterThan = (tableValue, filterValue) => {
  if (!filterValue) return true;

  if (isNumber(tableValue)) {
    return tableValue > filterValue;
  }

  return false;
};
/**
 * If table value is less than the filter
 * @param  {Any} tableValue
 * @param  {Any} filterValue
 */
export const lessThan = (tableValue, filterValue) => {
  if (!filterValue) return true;

  if (isNumber(tableValue)) {
    return tableValue < filterValue;
  }

  return false;
};
/**
 * Count the used filters
 * @param  {Object} filters
 */
export const filterCount = object => {
  let count = 0;

  // exclude search in count
  let filters = Object.assign({}, object);
  delete filters.search;

  filters = Object.values(filters);

  filters.forEach(filter => {
    if (filter) {
      count++;
    }
  });

  return count;
};
/**
 * Custom filter for searching all columns
 * @param  {String} search
 * @param  {Object} items
 */
export const search = (search, items) => {
  if (!search) return true;
  let count = 0;

  search = search.toLowerCase();
  each(items, item => {
    if (item) {
      if (
        item
          .toString()
          .toLowerCase()
          .indexOf(search) > -1
      )
        count++;
    }
  });

  return count > 0;
};

export const before = (value, filterValue, format) => {
  if (!filterValue) return true;
  const formattedFilterValue = moment(filterValue).format(format);
  if (value) {
    return compareDates(formattedFilterValue, value, format) >= 0;
  }
  return false;
};

export const after = (value, filterValue, format) => {
  if (!filterValue) return true;
  const formattedFilterValue = moment(filterValue).format(format);
  if (value) {
    return compareDates(formattedFilterValue, value, format) <= 0;
  }
  return false;
};

export const searchInObject = (searchValue, obj) => {
  return Object.values(obj).some(val =>
    includes(toLower(val), toLower(searchValue))
  );
};

export const searchInFilterOptions = (searchValue, options) => {
  if (searchValue) {
    return filter(options, option =>
      includes(toLower(option.text), toLower(searchValue))
    );
  }
  return options;
};

export const customTableFussyFilter = (searchValue, item, keys) => {
  const result = fuseSearch(searchValue, [item], { keys });
  return Boolean(result.length);
};

export const formatFilters = filters => {
  return reduce(
    filters,
    (formattedFilters, values, key) => {
      const filterKey = `filter[${key}]`;
      formattedFilters = { ...formattedFilters, [filterKey]: values };
      return formattedFilters;
    },
    []
  );
};
