import { get } from "lodash";

const HTTP_UNAUTHORIZED_CODE = 401;

export const HTTP_UNAUTHORIZED_MESSAGE = "unauthorized";

const getStatusCode = responseOrException =>
  get(responseOrException, "response.status") ||
  get(responseOrException, "status");

export const isUnauthorized = error =>
  getStatusCode(error) === HTTP_UNAUTHORIZED_CODE;
