<template>
  <div>
    <h2 class="mb-2 mt-5">Users</h2>
    <Datatable
      :headers="headers"
      :itemsPerPage="15"
      :items="users"
      :is-loading="isFetchingUsers"
      disableFiltering
    >
      <template v-slot:roles="{ item }">
        {{ getRoles(item.roles) }}
      </template>
    </Datatable>
  </div>
</template>

<script>
import Datatable from "@/components/common/Datatable";
import { copyState } from "@/utils/helpers";
import { startCase, map } from "lodash";
import { withFullName } from "@/utils/users";
import { createNamespacedHelpers } from "vuex";
import { SETTINGS_NAMESPACE } from "@/store/modules/settings";
import { FETCH_ORGANISATION_USERS } from "@/store/modules/settings/actions";

const { mapActions, mapState } = createNamespacedHelpers(SETTINGS_NAMESPACE);

export default {
  props: {
    agencyId: { type: String, required: true }
  },
  components: { Datatable },
  created() {
    const filter = { organisation_id: this.agencyId };
    this.fetchOrganisationUsers({ filter });
  },
  computed: {
    ...mapState(copyState(["organisationUsers", "isFetchingUsers"])),
    headers() {
      return [
        {
          text: "Name",
          value: "fullName"
        },
        {
          text: "Email",
          value: "email"
        },
        {
          text: "Role(s)",
          value: "roles"
        }
      ];
    },
    users() {
      return this.organisationUsers.map(withFullName);
    }
  },
  methods: {
    ...mapActions({
      fetchOrganisationUsers: FETCH_ORGANISATION_USERS
    }),
    getRoles(roles) {
      roles = map(roles, role => startCase(role));
      const rolesData = roles.join(", ");
      return rolesData;
    }
  }
};
</script>

<style lang="scss" scoped></style>
