import filesActions from "@/store/modules/files/actions";
import filesMutations from "@/store/modules/files/mutations";

const state = {
  files: [],
  isFetchingFiles: false,
  isFetchingFile: false,
  file: {}
};

export default {
  namespaced: true,
  state: state,
  actions: filesActions,
  mutations: filesMutations
};
