<template>
  <ValidationObserver ref="detailsDialog">
    <DialogFull
      :data="dialogSettings"
      @close="close"
      noOverflow
      v-if="dialogSettings.dialog"
    >
      <template v-slot:dialog.body>
        <div
          class="container edit-agency-dialog flex-gap-15 col-lg-10 col-sm-12 p-3 flex-gap-15"
        >
          <v-row>
            <v-col class="col-12 input-item mb-3 mt-5">
              <ValidationProvider v-slot="{ errors }" rules="required">
                <v-text-field
                  :disabled="loading"
                  :error-messages="showValidationErrors ? errors : ''"
                  v-model="agency.name"
                  label="Name"
                />
              </ValidationProvider>
            </v-col>
            <v-col class="col-md-6 col-12 input-item mb-5">
              <ValidationProvider v-slot="{ errors }" rules="required">
                <v-text-field
                  :disabled="loading"
                  :error-messages="showValidationErrors ? errors : ''"
                  v-model="agency.companyRegistrationNumber"
                  class="mr-3"
                  label="Company Number"
                />
              </ValidationProvider>
            </v-col>
            <v-col class="col-md-6 col-12 input-item mb-5">
              <ValidationProvider v-slot="{ errors }" rules="required">
                <v-text-field
                  :disabled="loading"
                  :error-messages="showValidationErrors ? errors : ''"
                  v-model="agency.vatNumber"
                  label="VAT Number"
                />
              </ValidationProvider>
            </v-col>
            <v-col class="col-12 input-item mb-2">
              <h3>Address</h3>
            </v-col>
            <v-col class="col-12 input-item mb-3">
              <ValidationProvider v-slot="{ errors }" rules="required|max:60">
                <v-text-field
                  :disabled="loading"
                  :error-messages="showValidationErrors ? errors : ''"
                  v-model="address.line1"
                  label="Address Line 1"
                />
              </ValidationProvider>
            </v-col>
            <v-col class="col-12 input-item mb-3">
              <ValidationProvider
                v-slot="{ errors }"
                name="line2"
                rules="max:60"
              >
                <v-text-field
                  :disabled="loading"
                  :error-messages="showValidationErrors ? errors : ''"
                  v-model="address.line2"
                  label="Address Line 2"
                />
              </ValidationProvider>
            </v-col>
            <v-col class="col-md-6 col-12 input-item mb-3">
              <ValidationProvider v-slot="{ errors }" rules="required|max:60">
                <v-text-field
                  :disabled="loading"
                  :error-messages="showValidationErrors ? errors : ''"
                  v-model="address.line3"
                  label="Town/City"
                  class="mr-3"
                />
              </ValidationProvider>
            </v-col>
            <v-col class="col-md-6 col-12 input-item mb-3">
              <ValidationProvider v-slot="{ errors }" rules="required|max:10">
                <v-text-field
                  :disabled="loading"
                  :error-messages="showValidationErrors ? errors : ''"
                  v-model="address.postCode"
                  label="Postcode"
                />
              </ValidationProvider>
            </v-col>
            <v-col class="col-12 input-item mb-3">
              <ValidationProvider v-slot="{ errors }" rules="required">
                <Select
                  :loading="isFetchingCountries"
                  :disabled="loading || isFetchingCountries"
                  v-model="address.country"
                  label="Country"
                  :items="isFetchingCountries ? [] : countries"
                  item-text="label"
                  item-value="id"
                  :error-messages="showValidationErrors ? errors : ''"
                  autocomplete
                />
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row v-if="!isEditMode">
            <v-col class="col-12 input-item">
              <h3>Administration User</h3>
            </v-col>
            <v-col class="col-12 input-item">
              <ValidationProvider v-slot="{ errors }" rules="required">
                <v-text-field
                  :disabled="loading"
                  :error-messages="showValidationErrors ? errors : ''"
                  v-model="admin.firstName"
                  label="First Name"
                />
              </ValidationProvider>
            </v-col>
            <v-col class="col-12 input-item">
              <ValidationProvider v-slot="{ errors }" rules="required">
                <v-text-field
                  :disabled="loading"
                  :error-messages="showValidationErrors ? errors : ''"
                  v-model="admin.lastName"
                  label="Last Name"
                />
              </ValidationProvider>
            </v-col>
            <v-col class="col-12 input-item">
              <ValidationProvider v-slot="{ errors }" rules="required|email">
                <v-text-field
                  :disabled="loading"
                  :error-messages="showValidationErrors ? errors : ''"
                  v-model="admin.email"
                  label="Email Address"
                />
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="col-12 input-item">
              <h3>Publishing Schedules</h3>
            </v-col>
            <v-col class="col-12">
              <ValidationProvider v-slot="{ errors }" rules="required">
                <Select
                  return-object
                  :disabled="loading || isFetchingTemplates"
                  label=""
                  :error-messages="showValidationErrors ? errors : ''"
                  :loading="isFetchingTemplates"
                  autocomplete
                  :value="selectedTiers"
                  @input="handleSelectedTiers($event)"
                  :items="isFetchingTemplates ? [] : tierOptions"
                  item-text="name"
                  item-value="id"
                  ref="autocomplete"
                  chips
                  small-chips
                  multiple
                >
                  <template v-slot:selection="{ data }">
                    <v-chip
                      close
                      @click:close="unselectTier(data.item)"
                      class="selected-worker-chip"
                    >
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                  <template v-slot:item="{ item }">
                    {{ item.name }}
                  </template>
                </Select>
              </ValidationProvider>
            </v-col>
          </v-row>
        </div>
      </template>
      <template v-slot:dialog.action>
        <PrimaryButton @click.native="onSubmit" :loading="loading">
          {{ actionName }}
        </PrimaryButton>
      </template>
    </DialogFull>
  </ValidationObserver>
</template>

<script>
import DialogFull from "@/components/common/DialogFull";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { createNamespacedHelpers } from "vuex";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import Select from "@/components/common/Select";
import { forEach, map, first, filter, difference, find } from "lodash";

import { DATA_NAMESPACE } from "@/store/modules/data";
import { FETCH_COUNTRIES } from "@/store/modules/data/actions";
import {
  GET_COUNTRIES,
  GET_IS_FETCHING_DATA
} from "@/store/modules/data/getters";

import { FETCH_PUBLISHING_SCHEDULE_TEMPLATES } from "@/store/modules/organisations/organisation-schedules/actions";
import {
  GET_IS_FETCHING_SCHEDULE_TEMPLATES,
  GET_PUBLISHING_SCHEDULE_TEMPLATES
} from "@/store/modules/organisations/organisation-schedules/getters";
import { ORGANISATION_SCHEDULES_NAMESPACE } from "@/store/modules/organisations/organisation-schedules";

const {
  mapActions: mapOrganisationSchedulesActions,
  mapGetters: mapOrganisationSchedulesGetters
} = createNamespacedHelpers(ORGANISATION_SCHEDULES_NAMESPACE);

const {
  mapGetters: mapDataGetters,
  mapActions: mapDataActions
} = createNamespacedHelpers(DATA_NAMESPACE);

export default {
  name: "AgencyDetailsDialog",
  components: {
    PrimaryButton,
    DialogFull,
    ValidationObserver,
    ValidationProvider,
    Select
  },
  props: {
    isOpen: Boolean,
    loading: Boolean,
    title: String,
    actionName: String,
    agencyDetails: Object
  },
  async created() {
    this.fetchCountries();
    if (this.agencyDetails) {
      this.adjustFormFields();
    }
    await this.fetchPublishingScheduleTemplates();
    if (this.agencyDetails) {
      this.adjustSelectedTiersForTheAgency();
    }
  },
  data() {
    return {
      selectedTiers: [],
      address: {
        line1: "",
        line2: "",
        line3: "",
        postCode: "",
        country: ""
      },
      admin: {
        firstName: "",
        lastName: "",
        email: ""
      },
      agency: {
        name: "",
        companyRegistrationNumber: "",
        vatNumber: ""
      },
      showValidationErrors: false
    };
  },
  computed: {
    ...mapDataGetters({
      countries: GET_COUNTRIES,
      isFetchingCountries: GET_IS_FETCHING_DATA
    }),
    isEditMode() {
      return Boolean(this.agencyDetails);
    },
    ...mapOrganisationSchedulesGetters({
      isFetchingTemplates: GET_IS_FETCHING_SCHEDULE_TEMPLATES,
      publishingScheduleTemplates: GET_PUBLISHING_SCHEDULE_TEMPLATES
    }),
    initialSelectedRoles() {
      return this.adminRoles || [];
    },
    dialogSettings() {
      return {
        dialog: this.isOpen,
        title: this.title
      };
    },
    tierOptions() {
      return this.publishingScheduleTemplates.reduce(
        (options, { name, tiers }) => {
          forEach(tiers, tier => {
            options.push({
              name: `${name} (${tier.tier_name})`,
              scheduleName: name,
              id: tier.id
            });
          });
          return options;
        },
        []
      );
    }
  },
  methods: {
    ...mapDataActions({
      fetchCountries: FETCH_COUNTRIES
    }),
    ...mapOrganisationSchedulesActions({
      fetchPublishingScheduleTemplates: FETCH_PUBLISHING_SCHEDULE_TEMPLATES
    }),
    adjustFormFields() {
      const {
        addresses,
        name,
        companyRegistrationNumber,
        vatNumber
      } = this.agencyDetails;
      this.address = Object.assign({}, first(addresses));
      this.agency.name = name;
      this.agency.companyRegistrationNumber = companyRegistrationNumber;
      this.agency.vatNumber = vatNumber;
    },
    adjustSelectedTiersForTheAgency() {
      const matchedTiers = map(
        this.agencyDetails["publishing-schedule-tiers"],
        ({ id }) => find(this.tierOptions, { id })
      ).filter(Boolean);

      this.selectedTiers = matchedTiers;
    },
    handleSelectedTiers(tiers) {
      const newSelectedTier = first(difference(tiers, this.selectedTiers));
      const filteredTiers = filter(tiers, tier => {
        const hasUniqueSchedule =
          tier && tier.scheduleName !== newSelectedTier.scheduleName;
        return hasUniqueSchedule;
      });
      this.selectedTiers = [...filteredTiers, newSelectedTier];
    },
    unselectTier(currentTier) {
      this.selectedTiers = filter(
        this.selectedTiers,
        ({ id }) => id !== currentTier.id
      );
    },
    async onSubmit() {
      this.showValidationErrors = true;
      const isValid = await this.$refs.detailsDialog.validate();
      if (isValid) {
        this.$emit("submit", {
          address: this.address,
          ...(!this.isEditMode && { admin: this.admin }),
          "publishing-schedule-tiers": map(this.selectedTiers, ({ id }) => ({
            id
          })),
          ...this.agency
        });
      }
    },
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss">
.edit-agency-dialog .col {
  padding: 0px;
}
</style>
