<template>
  <BaseDialog :isOpen="isOpen">
    <template v-slot:title>
      <span>Edit AWR Status</span>
    </template>
    <template v-slot:body>
      <Select
        v-model="newAwrStatus"
        :items="awrStatuses"
        item-text="label"
        item-value="value"
      />
    </template>
    <template v-slot:actions>
      <div>
        <ErrorButton @click.native="cancel" text>
          Cancel
        </ErrorButton>
        <PrimaryButton @click.native="saveAwrStatus" :loading="isLoading">
          Save Changes
        </PrimaryButton>
      </div>
    </template>
  </BaseDialog>
</template>

<script>
import BaseDialog from "@/components/common/BaseDialog";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import ErrorButton from "@/components/common/Button/ErrorButton";
import { UPDATE_APPLICATION } from "@/store/modules/applications/actions";
import { APPLICATION_NAMESPACE } from "@/store/modules/applications";
import { createNamespacedHelpers } from "vuex";
import Select from "@/components/common/Select";
import generateId from "uuid/v4";

const { mapActions } = createNamespacedHelpers(APPLICATION_NAMESPACE);

const AWR_STATUSES = [
  { label: "Pre-AWR", value: false },
  { label: "Post-AWR", value: true }
];

export default {
  name: "EditAwrStatus",
  components: { Select, ErrorButton, PrimaryButton, BaseDialog },
  data() {
    return {
      newAwrStatus: this.application.awr,
      isLoading: false,
      id: generateId()
    };
  },
  props: {
    isOpen: Boolean,
    application: Object
  },
  computed: {
    awrStatuses() {
      return AWR_STATUSES;
    }
  },
  methods: {
    ...mapActions({
      updateApplication: UPDATE_APPLICATION
    }),
    async close() {
      this.id = generateId();
      this.$emit("close");
    },
    async saveAwrStatus() {
      try {
        this.isLoading = true;
        await this.updateApplication({
          id: this.application.id,
          awr: this.newAwrStatus
        });
        this.close();
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    cancel() {
      this.close();
    }
  }
};
</script>

<style></style>
