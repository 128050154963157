<template>
  <BaseDialog :isOpen="isOpen">
    <template v-slot:title>
      Edit PO Number
    </template>
    <template v-slot:body>
      <div class="mt-3">
        <v-text-field v-model="poNumber" label="PO Number" placeholder=" " />
      </div>
    </template>
    <template v-slot:actions>
      <PrimaryButton @click.native="close" text :disabled="isLoading">
        Cancel
      </PrimaryButton>
      <PrimaryButton @click.native="onSubmit" :loading="isLoading">
        Save
      </PrimaryButton>
    </template>
  </BaseDialog>
</template>

<script>
import BaseDialog from "@/components/common/BaseDialog";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import {
  EDIT_PO_NUMBER,
  FETCH_BUDGETS
} from "@/store/modules/bookings/actions";
import { createNamespacedHelpers } from "vuex";

const { mapActions, mapState } = createNamespacedHelpers("bookings");

export default {
  components: {
    PrimaryButton,
    BaseDialog
  },
  props: {
    isOpen: Boolean
  },
  data() {
    return {
      showValidationErrors: false,
      isLoading: false,
      poNumber: ""
    };
  },
  created() {
    this.poNumber = this.currentBooking.poNumber;
  },
  computed: {
    ...mapState({
      currentBooking: state => state.currentBooking
    }),
    budgetParams() {
      return {
        filter: {
          ...(this.currentBooking.poNumber && {
            po_number: this.currentBooking.poNumber
          }),
          ...(this.currentBooking.rootClient && {
            organisation_unit_id: this.currentBooking.rootClient.id
          })
        }
      };
    },
    shouldFetchBudgets() {
      return this.currentBooking.poNumber && this.currentBooking.rootClient;
    }
  },
  methods: {
    ...mapActions({
      editPONumber: EDIT_PO_NUMBER,
      fetchBudgets: FETCH_BUDGETS
    }),
    close() {
      this.$emit("close");
    },
    async onSubmit() {
      try {
        this.isLoading = true;
        await this.editPONumber({
          bookingId: this.currentBooking.id,
          data: {
            poNumber: this.poNumber
          }
        });
        if (this.shouldFetchBudgets) {
          this.fetchBudgets(this.budgetParams);
        }
        this.close();
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style lang="scss"></style>
