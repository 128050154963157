import { required, email, max, min } from "vee-validate/dist/rules";
import { extend } from "vee-validate";
import moment from "moment";

extend("required", {
  ...required,
  message: "This field is required"
});

extend("max", {
  params: ["length"],
  ...max,
  message: "This field must be at most {length} characters"
});

extend("min", {
  params: ["length"],
  ...min,
  message: "This field must be at least {length} characters"
});

extend("email", {
  ...email,
  message: "This field must be a valid email"
});
extend("minNumber", {
  params: ["number"],
  message: "The number can't be less than {number}",
  validate: (value, { number }) => value >= number
});

extend("maxBreakTime", {
  params: ["maxNumber"],
  message: "Break time must be shorter than total time for the shift",
  validate: (value, { maxNumber }) => Number(value) <= Number(maxNumber)
});

extend("email", {
  ...email,
  message: "This field must be a valid email"
});

extend("legalAge", {
  message: "The age must be at least 17 years old.",
  validate: value => {
    return moment().diff(moment(value, "DD/MM/YYYY"), "years") > 16;
  }
});

extend("validDate", {
  message: "The date is invalid.",
  validate: value => {
    const date = moment(value, "DD/MM/YYYY").format("YYYY-MM-DD");
    return moment(date, "YYYY-MM-DD").isValid();
  }
});

extend("validDateRange", {
  message: "The date range is invalid.",
  validate: value => {
    const dates = value.split("-");
    const startDate = moment(dates[0], "DD/MM/YYYY").format("YYYY-MM-DD");
    const endDate = moment(dates[1], "DD/MM/YYYY").format("YYYY-MM-DD");
    return moment(startDate).isBefore(moment(endDate));
  }
});

extend("validTimeRange", {
  message: "The time range is invalid.",
  validate: value => {
    const times = value.split("-");
    const startTime = moment(times[0], "HH:mm:ss");
    const endTime = moment(times[1], "HH:mm:ss");
    return moment(startTime).diff(moment(endTime)) < 0;
  }
});

export const isRequired = (fieldName, shouldValidate = true) => {
  if (!shouldValidate) {
    return [];
  }
  return [value => !!value || `${fieldName} is required`];
};

export const isNotEmpty = (fieldName, shouldValidate = true) => {
  if (!shouldValidate) {
    return [];
  }
  return [value => !!value.length || `${fieldName} is required`];
};
