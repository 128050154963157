<template>
  <div class="compliance__details">
    <div class="compliance__details__label">{{ label }}</div>
    <div class="compliance__details__value">
      {{ compliance.documentValidToDate | baseDate }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    /**
     *  Compliance object
     * @example
     * {
     *  label: String
     *  checked: Boolean
     * }
     */
    compliance: {
      type: Object,
      required: true
    },
    label: String
  }
};
</script>
