import {
  filter,
  find,
  includes,
  map,
  reduce,
  snakeCase,
  startCase
} from "lodash";
import { costFilter } from "@/utils/filters/costFilter";
import { currencyFilter } from "@/utils/filters/currencyFilter";
import { formatDate } from "@/utils/time";

export const sortReportDataByGroupingFields = ({ groupings, reportData }) => {
  return map(reportData, item => {
    const reportKeys = Object.keys(item);
    const sortedKeys = reportKeys.sort((a, b) => {
      if (includes(groupings, a) && includes(groupings, b)) {
        return 0;
      } else if (includes(groupings, a)) {
        return -1;
      } else {
        return 1;
      }
    });
    return sortedKeys.reduce((sortedItem, key) => {
      sortedItem[startCase(key)] = item[key];
      return sortedItem;
    }, {});
  });
};

export const getDefaultFilterValue = ({ fieldType, filterType }) => {
  const value = getDefaultFieldTypeValue(fieldType);
  if (filterType === "between") {
    return { value: `${value},${value}` };
  } else if (
    filterType === "in_last_date_range" ||
    filterType === "in_next_date_range"
  ) {
    return { value: "1", unit: "month" };
  } else {
    return { value };
  }
};

const getDefaultFieldTypeValue = fieldType => {
  if (fieldType === "number") {
    return "0";
  } else if (fieldType === "datetime") {
    return new Date().toISOString().substr(0, 10);
  } else {
    return "";
  }
};

export const getFieldOptions = ({ fields, labels }, hideCount) => {
  if (!hideCount) {
    fields = filter(fields, ({ field }) => field !== "count");
  }
  return map(fields, ({ field }) => ({
    field,
    text: labels[field]
  }));
};

export const thousandSeparator = number => {
  return number ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "";
};

export const formatValues = ({
  reportData,
  fields,
  isDataTable = false,
  currencyCode = "GBP",
  groupings = []
}) =>
  map(reportData, data => {
    return reduce(
      Object.keys(data),
      (formattedItems, key) => {
        const currentField = find(
          fields,
          ({ field }) =>
            field === snakeCase(key) || includes(snakeCase(key), field)
        );
        const grouping = find(
          groupings,
          ({ field }) => field === currentField.field
        );
        const isDateGrouping =
          Boolean(grouping) && currentField.type === "datetime";
        const isBoolean = currentField.type === "boolean";
        const formatCurrencyValues = () => {
          if (typeof data[key] === "string" && includes(data[key], " - ")) {
            const amount = data[key].split(" - ");
            return `${currencyFilter(
              costFilter(Number(amount[0])),
              currencyCode
            )} - ${currencyFilter(
              costFilter(Number(amount[1])),
              currencyCode
            )}`;
          }
          return currencyFilter(
            data[key] ? costFilter(data[key]) : 0,
            currencyCode
          );
        };
        formattedItems[isDataTable ? snakeCase(key) : startCase(key)] =
          currentField && currentField.type === "currency"
            ? formatCurrencyValues()
            : includes(key, ["Name"]) || includes(key, ["Status"])
            ? startCase(data[key])
            : isDateGrouping
            ? formatGroupByDate(data[key], grouping.interval_date)
            : isBoolean
            ? getBooleanText(data[key])
            : data[key];
        return formattedItems;
      },
      {}
    );
  });

export const formattedDate = (date, interval) => {
  switch (interval) {
    case "day":
      return formatDate({
        date,
        endFormat: "Do MMMM, YYYY"
      });
    case "week":
      return formatDate({
        date,
        startFormat: "YYYY-w",
        endFormat: "wo [week] of YYYY"
      });
    case "month":
      return formatDate({
        date,
        endFormat: "MMMM, YYYY"
      });
    case "quarter":
      return formatDate({
        date,
        startFormat: "YYYY-Q",
        endFormat: "Qo [quarter] of YYYY"
      });
    case "year":
      return date;
    default:
      return "Do MMMM, YYYY hh:ss";
  }
};

export const formatGroupByDate = (date, interval) => {
  return date ? formattedDate(date, interval) : "";
};

export const getBooleanText = value => {
  return value ? "Yes" : "No";
};

export const fieldType = (fields, fieldKey) => {
  const field = find(
    fields,
    ({ field }) =>
      field === snakeCase(fieldKey) || snakeCase(fieldKey).includes(field)
  );
  return field ? field.type : "";
};
