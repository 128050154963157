<template>
  <BaseAction>
    <SecondaryButton
      @click.native="handleClick"
      :loading="isLoading"
      textColor="#0965dc"
    >
      <v-img class="mb-1" height="30px" contain :src="icon" />
      Verify
    </SecondaryButton>
  </BaseAction>
</template>

<script>
import SecondaryButton from "@/components/common/Button/SecondaryButton";
import {
  GET_CURRENT_TIMESHEET,
  VERIFY_TIMESHEET
} from "@/store/modules/timesheets/actions";
import { createNamespacedHelpers } from "vuex";
import { TIMESHEETS_NAMESPACE } from "@/store/modules/timesheets";
import { map, size } from "lodash";
import BaseAction from "@/components/common/BaseAction";

const { mapActions } = createNamespacedHelpers(TIMESHEETS_NAMESPACE);

export default {
  name: "VerifyTimesheetAction",
  props: {
    timesheetsIds: {
      type: Array,
      required: true
    }
  },
  components: {
    SecondaryButton,
    BaseAction
  },
  computed: {
    icon() {
      return require(`@/assets/svg/verify.svg`);
    }
  },
  data() {
    return {
      isLoading: false
    };
  },
  methods: {
    ...mapActions({
      getCurrentTimesheet: GET_CURRENT_TIMESHEET,
      verifyTimesheet: VERIFY_TIMESHEET
    }),
    async handleClick() {
      this.isLoading = true;
      try {
        const requests = map(this.timesheetsIds, async timesheetId => {
          const verified = await this.verifyTimesheet(timesheetId);
          size(this.timesheetsIds) === 1 &&
            this.getCurrentTimesheet(timesheetId);
          return verified;
        });
        await Promise.all(requests);
        this.$emit("onUpdate");
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>
