import actions from "@/store/modules/timesheets/actions";
import mutations from "@/store/modules/timesheets/mutations";
import getters from "@/store/modules/timesheets/getters";
import { isMockedDataEnabled } from "@/utils/mocked-data";
import { MOCKED_TIMESHEETS_STATE } from "@/constants/timesheets";

const state = {
  timesheets: [],
  currentTimesheet: null,
  isFetchingTimesheets: false,
  isFetchingCurrentTimesheet: false,
  timesheetComments: [],
  timesheetEntries: [],
  timesheetEntry: null,
  timesheetExpenses: [],
  isFetchingTimesheetComments: false,
  isFetchingTimesheetExpenses: false,
  isFetchingTimesheetEntries: false,
  isRemovingExpense: false,
  isRemovingEntry: false,
  entriesToEdit: [],
  timesheetFilters: {},
  timesheetsToVerify: [],
  timesheetsToApprove: [],
  shiftTimesheets: []
};

export default {
  namespaced: true,
  state: isMockedDataEnabled() ? MOCKED_TIMESHEETS_STATE : state,
  actions: isMockedDataEnabled() ? {} : actions,
  mutations: isMockedDataEnabled() ? {} : mutations,
  getters: getters
};
