<template>
  <v-dialog v-model="data.dialog" width="500">
    <v-card>
      <v-card-title class="h2 dialog-text" primary-title>
        {{ data.title }}
      </v-card-title>
      <v-card-text>
        <slot name="dialog.body" />
        <slot />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <slot name="dialog.actions" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    /**
     *  Dialog data object
     */
    data: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.dialog-text {
  word-break: break-word;
}
.dialog-text.h2 {
  font-weight: bold;
}
</style>
