import APIService, { withPrefix } from "@/services/APIService";
import JSONApiService from "@/services/JSONApiService";
import { REFERENCE_MODEL } from "@/models/reference-model";
import { isNotTestingEnv } from "@/services/utils";

const API = isNotTestingEnv() && withPrefix(APIService.client(), "references");

const referenceApi = ({ commit } = {}) => ({
  addNewReference: async data => {
    return JSONApiService(commit).create(REFERENCE_MODEL, data);
  },
  deleteReference: async id => API.delete(`${id}`)
});

export default referenceApi;
