<template>
  <BaseAction>
    <SecondaryButton
      :loading="isLoading"
      @click.native="handleClick"
      color="primary"
    >
      <v-icon>mdi-star-outline</v-icon>
      Shortlist
    </SecondaryButton>
  </BaseAction>
</template>

<script>
import SecondaryButton from "@/components/common/Button/SecondaryButton";
import { SHORTLIST_CANDIDATE } from "@/store/modules/applications/actions";
import { createNamespacedHelpers } from "vuex";
import { APPLICATION_NAMESPACE } from "@/store/modules/applications";
import BaseAction from "@/components/common/BaseAction";
import pluralize from "pluralize";
import { map, size } from "lodash";
import { POST_GLOBAL_MESSAGE } from "@/store/modules/global/action-types";
import { mapActions } from "vuex";
import { getApplicationActionIcon } from "@/utils/bookings";

const { mapActions: mapApplicationActions } = createNamespacedHelpers(
  APPLICATION_NAMESPACE
);

export default {
  name: "ShortlistCandidatesAction",
  components: {
    BaseAction,
    SecondaryButton
  },
  data() {
    return {
      isOpen: false,
      isLoading: false
    };
  },
  props: {
    applicationsIds: {
      type: Array,
      required: true
    }
  },
  methods: {
    ...mapActions({
      postGlobalMessage: POST_GLOBAL_MESSAGE
    }),
    ...mapApplicationActions({
      shortlistCandidate: SHORTLIST_CANDIDATE
    }),
    async handleClick() {
      this.isLoading = true;
      const requests = map(this.applicationsIds, applicationId =>
        this.shortlistCandidate({ applicationId })
      );
      await Promise.all(requests);
      this.postSuccesMessage(size(requests));
      this.$emit("statusChanged");
      this.isLoading = false;
    },
    postSuccesMessage(applicationNumber) {
      const message = {
        snackbar: true,
        type: "success",
        title: `You have shortlisted the ${pluralize(
          "worker",
          applicationNumber
        )}`
      };
      this.postGlobalMessage(message);
    },
    getApplicationActionIcon
  }
};
</script>
