<template>
  <div class="list-item with-icon">
    <v-list-item-avatar tile>
      <v-img height="30px" contain src="@/assets/svg/booking_list_icon.svg" />
    </v-list-item-avatar>
    <v-list-item-content>
      <div class="item-right">
        <div class="description">
          <div class="d-flex" :class="{ 'flex-column': isMobile }">
            <span class="font-weight-bold">{{ booking.title }}</span>
          </div>
          <div
            class="d-flex flex-wrap booking-item"
            :class="{ 'flex-column': isMobile }"
          >
            <span class="mr-1" v-if="rootOrgUnitName">
              {{ `${rootOrgUnitName} |` }}
            </span>
            <span class="mr-1">{{ `${clientName}` }}</span>
            <span class="mr-1" v-if="shiftPatternDates">
              {{ `| ${shiftPatternDates}` }}
            </span>
            <span class="mr-1" v-if="booking.poNumber">
              {{ `| PO ${booking.poNumber}` }}
            </span>
            <span class="mr-1" v-if="location">
              {{ `| ${location}` }}
            </span>
          </div>
        </div>
        <div class="d-flex flex-column" :class="{ 'align-end': !isMobile }">
          <span class="booking-list-item__mono mb-1">B-{{ booking.id }}</span>
          <Chip
            :color="getColorByStatus(booking.status)"
            class="chip--status"
            shrink
          >
            {{ booking.status | upperCase }}
          </Chip>
        </div>
      </div>
    </v-list-item-content>
  </div>
</template>

<script>
import { getColorByStatus } from "@/utils/bookings";
import Chip from "@/components/common/Chip";
import { get, reduce } from "lodash";
import { getFormattedLocation } from "@/utils/locations";
import { getShiftDateRange } from "@/utils/shifts";
import { RRule } from "rrule";
import moment from "moment";

export default {
  props: {
    booking: {
      type: Object,
      required: true
    }
  },
  components: {
    Chip
  },
  computed: {
    rootOrgUnitName() {
      const root = get(this.booking, "rootClient");
      return root && root.name;
    },
    clientName() {
      const client = get(this.booking, "client");
      return client && client.name;
    },
    location() {
      return getFormattedLocation(this.booking.location);
    },
    allShiftRange() {
      return reduce(
        this.booking.shiftPatterns,
        (allShiftRRule, shiftPattern) => {
          const formattedRule = RRule.parseString(shiftPattern.rrule);
          if (
            !allShiftRRule.dtstart ||
            moment(allShiftRRule.dtstart).isAfter(formattedRule.dtstart)
          ) {
            allShiftRRule.dtstart = formattedRule.dtstart;
          }
          if (
            !allShiftRRule.until ||
            moment(allShiftRRule.until).isBefore(formattedRule.until)
          ) {
            allShiftRRule.until = formattedRule.until;
          }
          return allShiftRRule;
        },
        {}
      );
    },
    shiftPatternDates() {
      return getShiftDateRange(this.allShiftRange);
    }
  },
  methods: {
    getColorByStatus
  }
};
</script>
