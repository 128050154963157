<template>
  <DialogFull :data="dialogSettings" @close="close">
    <template v-slot:dialog.body>
      <div
        v-if="workersNotCompleted && workersNotCompleted.length"
        class="container"
      >
        <AllocationIncompleteWorkersList :workers="workersNotCompleted" />
      </div>
      <div v-else class="container">
        <AllocationList
          :shifts="shifts"
          :title="dialogSettings.title"
          :message="dialogSettings.message"
          @onSelectApplicationIds="emitSelectedApplicationIds"
          :isV2="isV2"
        />
      </div>
    </template>
    <template
      v-slot:dialog.action
      v-if="!(workersNotCompleted && workersNotCompleted.length)"
    >
      <ErrorButton
        @click.native="handleSubmit"
        :full-width="isMobile"
        v-if="errorButton"
        :loading="isLoading"
      >
        {{ dialogSettings.title }}
      </ErrorButton>
      <PrimaryButton @click.native="handleSubmit" v-else :loading="isLoading">
        {{ dialogSettings.title }}
      </PrimaryButton>
    </template>
  </DialogFull>
</template>

<script>
import AllocationList from "@/views/bookings/components/AllocationList";
import DialogFull from "@/components/common/DialogFull";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import ErrorButton from "@/components/common/Button/ErrorButton";
import AllocationIncompleteWorkersList from "@/views/bookings/components/AllocationIncompleteWorkersList.vue";

export default {
  name: "AllocationsDialog",
  components: {
    DialogFull,
    AllocationList,
    PrimaryButton,
    ErrorButton,
    AllocationIncompleteWorkersList
  },
  props: {
    dialogSettings: Object,
    shifts: Array,
    workersNotCompleted: Array,
    applicationId: String,
    errorButton: Boolean,
    isLoading: Boolean,
    isV2: Boolean
  },
  methods: {
    close() {
      this.$emit("close");
    },
    handleSubmit() {
      this.$emit("submit");
    },
    emitSelectedApplicationIds(selectedApplicationIds) {
      this.$emit("onSelectApplicationIds", selectedApplicationIds);
    }
  }
};
</script>

<style lang="scss"></style>
