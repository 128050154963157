<template>
  <v-dialog
    v-model="data.dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card class="dialog--cv">
      <v-toolbar color="white">
        <v-spacer class="dialog--spacer" />
        <v-toolbar-title>{{ data.title }}</v-toolbar-title>
        <v-btn icon @click="data.dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <embed :src="data.src" :style="styles" type="application/pdf" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    /**
     *  Dialog data object
     * @example
     * {
     *  dialog: true,
     *  src: "https://pdflink"
     * }
     */
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    styles() {
      return { width: "100%", height: "90vh", margin: 0 };
    }
  }
};
</script>

<style lang="scss"></style>
