<template>
  <div class="publishing-schedule">
    <LabeledText
      :label="label"
      @mouseover.native="hover = true"
      @mouseleave.native="hover = false"
    >
      <template v-slot:icon>
        <CheckIcon v-if="confirmed" />
        <WaitingIcon v-else />
      </template>
      <template v-slot:action v-if="hover">
        <Permissioned section="bookings">
          <PrimaryButton
            v-if="!confirmed"
            shrink
            permission="publish-to-agency"
            light
            @click.native="isSendDialogOpen = true"
          >
            Send to Agency
          </PrimaryButton>
        </Permissioned>
      </template>
      <div class="d-flex">
        <div class="publishing-schedule__publish-date">
          {{ publishOnDate() }}
        </div>
        <div class="secondary-text ml-1" v-if="quota.workerQuota">
          {{
            `| ${quota.workerQuota} ${pluralize(
              "worker",
              quota.workerQuota
            )} permitted`
          }}
        </div>
      </div>
    </LabeledText>
    <SendToAgencyDialog
      :is-open="isSendDialogOpen"
      @close="isSendDialogOpen = false"
      :agency="{ name: label, id: unitId }"
      @publish="handlePublish"
    />
  </div>
</template>

<script>
import CheckIcon from "@/components/bookings/CheckIcon";
import WaitingIcon from "@/components/bookings/WaitingIcon";
import LabeledText from "@/components/common/BaseDetails/LabeledText";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import SendToAgencyDialog from "@/views/bookings/components/ConfirmDialogs/SendToAgencyDialog";
import Permissioned from "@/components/common/Permissioned";
import moment from "moment";
import pluralize from "pluralize";

export default {
  name: "PublishScheduleItem",
  components: {
    Permissioned,
    SendToAgencyDialog,
    CheckIcon,
    WaitingIcon,
    LabeledText,
    PrimaryButton
  },
  data() {
    return {
      hover: false,
      isSendDialogOpen: false
    };
  },
  methods: {
    handlePublish() {
      this.$emit("publish");
    },
    publishOnDate() {
      const pDate = moment(this.publishingDate).format("DD MMMM YYYY, HH:mm");
      return pDate;
    },
    pluralize
  },
  props: {
    confirmed: Boolean,
    label: String,
    unitId: String,
    publishingDate: String,
    quota: Object
  }
};
</script>

<style lang="scss"></style>
