<template>
  <div class="timesheet-details-header">
    <div class="title">
      <span class="secondary-text"> T-{{ timesheet.id }} </span>
      <span
        class="secondary-text mx-3"
        v-if="this.timesheet.invoiceNumbers.length"
      >
        {{
          this.timesheet.invoiceNumbers
            ? this.timesheet.invoiceNumbers.join(", ")
            : ""
        }}
      </span>
      <div class="d-flex">
        <TimesheetChip :status="timesheet.status" class="mx-3">
          {{ timesheet.status }}
        </TimesheetChip>
      </div>
    </div>
    <span class="mt-2">
      <h1 class="centered">
        {{ timesheet.worker | fullName }}{{ this.timesheet.worker ? ", " : "" }}
        {{ dateRange }}
      </h1>
    </span>
    <router-link
      class="secondary-text mt-2"
      :to="{ name: 'bookingDetails', params: { id: timesheet.booking.id } }"
    >
      {{ timesheet.title }} (ID {{ timesheet.booking.id }})
    </router-link>
  </div>
</template>

<script>
import TimesheetChip from "@/views/timesheets/components/TimesheetChip";
import { getDateRange } from "@/utils/time";

export default {
  name: "TimesheetDetailsHeader",
  props: {
    timesheet: {
      type: Object,
      required: true
    }
  },
  components: { TimesheetChip },
  data() {
    return {
      isConfirmed: false
    };
  },
  computed: {
    dateRange() {
      const { weekCommencing, weekEnding } = this.timesheet;
      return getDateRange({
        startDate: weekCommencing,
        endDate: weekEnding,
        format: "Do MMM"
      });
    }
  },
  methods: {
    handleBack() {
      this.$router.push("/timesheets");
    }
  }
};
</script>

<style lang="scss">
.timesheet-details-header {
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    display: flex;
    align-items: center;

    @media only screen and (max-width: $mobile-breakpoint - 1px) {
      flex-direction: column-reverse;
    }
  }
}
</style>
