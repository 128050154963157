<template>
  <AppLayout class="reports" title="Exports">
    <template v-slot:header>
      <div class="reports__date">
        <DatePicker
          showValidationErrors
          label=""
          is-range
          hide-details
          @onChange="onDateChange"
          class="date-picker-wrapper--no-label"
        />
      </div>
      <Select
        class="mb-2"
        label=""
        placeholder="Select clients"
        v-model="clients"
        :items="agencies"
        :loading="isLoading"
        :disabled="isLoading"
        item-text="name"
        item-value="id"
        @change="handleClientChange"
        autocomplete
        multiple
        chips
        clearable
      />
    </template>
    <template v-slot:body>
      <div class="d-flex align-center">
        <v-tabs v-model="selectedTab" class="exports-tab-container">
          <v-tab
            v-for="(component, sectionName) in reportSections"
            :key="sectionName"
          >
            {{ sectionName }}
          </v-tab>
        </v-tabs>
        <base-button
          class="reports__reload mr-2 mb-0"
          shrink
          @click="handleReload"
          v-if="selectedTab === 1"
        >
          <v-icon>mdi-refresh</v-icon> Refresh
        </base-button>
      </div>

      <component
        :is="Object.values(reportSections)[selectedTab]"
        :date-range="dateRange"
        :clients="clients"
        :permissions="permissions"
        ref="reportsContent"
        @openDialog="setActiveDialog"
      />
    </template>
  </AppLayout>
</template>

<script>
import AppLayout from "@/components/common/base-layouts/AppLayout";
import DatePicker from "@/components/common/DatePicker";
import Exports from "@/views/exports/Exports";
import UserExports from "@/views/exports/UserExports";
import BaseButton from "@/components/common/Button/BaseButton";
import { AUTH_NAMESPACE } from "@/store/modules/auth";
import { createNamespacedHelpers } from "vuex";
import Select from "@/components/common/Select";
import { AGENCY_NAMESPACE } from "@/store/modules/agencies";
import { FETCH_ALL_AGENCIES } from "@/store/modules/agencies/action-types";
import { setGlobalClientIdFilter, getClientIdFilter } from "@/utils/helpers";
import { isEmpty, map } from "lodash";

const { mapState: authMapState } = createNamespacedHelpers(AUTH_NAMESPACE);
const { mapState, mapActions } = createNamespacedHelpers(AGENCY_NAMESPACE);

export default {
  components: {
    AppLayout,
    DatePicker,
    UserExports,
    BaseButton,
    Select
  },
  data: () => ({
    search: null,
    selectedTab: null,
    activeDialog: null,
    dateFrom: null,
    dateTo: null,
    dateRange: null,
    invalidDaterange: false,
    error: {
      title: `Invalid Date`,
      detail: `Please select correct Date Range`
    },
    clients: []
  }),
  created() {
    const filter = {
      organisation_type: "client",
      is_root: true
    };
    const clientIdFilters = getClientIdFilter();
    if (clientIdFilters) {
      this.clients = map(clientIdFilters, id => String(id));
    }
    this.getAllClients({ filter });
  },
  computed: {
    ...authMapState(["permissions"]),
    ...mapState({
      agencies: state => state.agencies,
      isLoading: state => state.isFetchingData
    }),
    reportSections() {
      let sections = {};
      sections["Exports"] = Exports;
      sections["My Requests"] = UserExports;

      return sections;
    }
  },
  methods: {
    ...mapActions({
      getAllClients: FETCH_ALL_AGENCIES
    }),
    setActiveDialog(name) {
      this.activeDialog = name;
    },
    onDateChange(val) {
      this.dateRange = val;
    },
    handleReload() {
      this.$refs.reportsContent.fetchUserReports();
    },
    handleClientChange(values) {
      this.clients = values;
      isEmpty(values)
        ? setGlobalClientIdFilter({})
        : setGlobalClientIdFilter({ client_id: values });
    }
  }
};
</script>

<style lang="scss" scoped>
.reports {
  &__reload {
    position: absolute;
    right: 0;
  }
}
.exports-tab-container {
  .v-slide-group__content {
    justify-content: center;
  }
}
</style>
