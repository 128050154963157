<template>
  <div class="internal-information-wrapper">
    <div class="internal-information container col-lg-10 col-sm-12">
      <h1 class="pt-3 pb-3 mb-3">Internal Information</h1>
      <InternalInformationSection
        @change="handleChange"
        :validate="validate"
        @valid="handleValidated"
      />
    </div>
  </div>
</template>

<script>
import InternalInformationSection from "@/views/bookings/NewBooking/steps/components/InternalInformationSection";

export default {
  name: "InternalInformation",
  components: {
    InternalInformationSection
  },
  data() {
    return {
      internalInfo: {}
    };
  },
  props: {
    validate: Boolean
  },
  methods: {
    handleChange(internalInformation) {
      this.internalInfo = internalInformation;
      this.$emit("change", this.internalInfo);
    },
    handleValidated() {
      this.$emit("valid");
    }
  }
};
</script>

<style lang="scss">
.internal-information-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
