<template>
  <ValidationObserver ref="disputeTimesheetValidation">
    <BaseDialog :isOpen="isOpen">
      <template v-slot:title>
        <span>Why do you want to dispute the timesheet?</span>
      </template>
      <template v-slot:body>
        <v-form ref="form">
          <ValidationProvider
            v-slot="{ errors }"
            name="description"
            rules="required"
          >
            <v-textarea
              :error-messages="showValidationErrors ? errors : ''"
              class="dispute-field"
              v-model="description"
              height="180"
              color="primary"
              placeholder="Add comment..."
            />
          </ValidationProvider>
        </v-form>
      </template>
      <template v-slot:actions>
        <PrimaryButton @click.native="close" text>
          Cancel
        </PrimaryButton>
        <ErrorButton @click.native="handleClick" :loading="isLoading">
          Dispute
        </ErrorButton>
      </template>
    </BaseDialog>
  </ValidationObserver>
</template>

<script>
import BaseDialog from "@/components/common/BaseDialog";
import {
  GET_CURRENT_TIMESHEET,
  DISPUTE_TIMESHEET,
  ADD_COMMENT,
  FETCH_CURRENT_TIMESHEET_COMMENTS
} from "@/store/modules/timesheets/actions";
import { createNamespacedHelpers } from "vuex";
import { TIMESHEETS_NAMESPACE } from "@/store/modules/timesheets";
import ErrorButton from "@/components/common/Button/ErrorButton";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { TIMESHEET_MODEL } from "@/models/timesheet-model";
import { map } from "lodash";

const { mapActions } = createNamespacedHelpers(TIMESHEETS_NAMESPACE);

export default {
  name: "DisputeDialog",
  components: {
    PrimaryButton,
    ErrorButton,
    BaseDialog,
    ValidationObserver,
    ValidationProvider
  },
  data() {
    return {
      showValidationErrors: false,
      description: "",
      isLoading: false
    };
  },
  props: {
    isOpen: Boolean,
    timesheetsIds: {
      type: Array,
      required: true
    },
    isMultiple: Boolean
  },
  methods: {
    ...mapActions({
      getCurrentTimesheet: GET_CURRENT_TIMESHEET,
      disputeTimesheet: DISPUTE_TIMESHEET,
      fetchCurrentTimesheetComments: FETCH_CURRENT_TIMESHEET_COMMENTS,
      addComment: ADD_COMMENT
    }),
    close() {
      this.$emit("close");
    },
    async handleClick() {
      this.showValidationErrors = true;
      const isFormValid = await this.$refs.disputeTimesheetValidation.validate();
      if (isFormValid) {
        this.isLoading = true;
        try {
          const timesheetRequests = map(this.timesheetsIds, timesheetId =>
            this.disputeTimesheet(timesheetId)
          );
          const commentRequests = map(this.timesheetsIds, timesheetId =>
            this.addComment({
              timesheetId,
              comment: {
                body: this.description,
                commentableType: TIMESHEET_MODEL,
                commentableId: timesheetId,
                notifyListeners: false
              }
            })
          );
          await Promise.all(timesheetRequests);
          await Promise.all(commentRequests);
          if (!this.isMultiple) {
            this.$router.push({
              name: "submitTimesheet",
              params: { id: this.id }
            });
          } else {
            this.$emit("disputed");
          }
        } catch {
          this.close();
        } finally {
          this.isLoading = false;
          this.description = "";
        }
      }
    }
  }
};
</script>

<style scoped></style>
