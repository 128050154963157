<template>
  <div class="report-editor">
    <v-skeleton-loader
      class="mt-6"
      type="list-item-three-line"
      v-if="isLoading"
    />
    <div class="container my-6 d-flex flex-column" v-else>
      <ValidationObserver ref="reportFields">
        <ReportFilterSection
          class="my-2"
          ref="filters"
          :fields="report.meta.fields"
          :filters="reportData.filters"
          :focus="reportData.focus"
          :labels="report.labels"
          :showValidationErrors="showValidationErrors"
          @updated="value => handleUpdate(value, 'filters')"
        />
        <ReportGroupingSection
          class="my-2"
          ref="groupings"
          :fields="report.meta.fields"
          :groupings="reportData.groupings"
          :selectedFields="reportData.fields"
          :labels="report.labels"
          :showValidationErrors="showValidationErrors"
          @updated="value => handleUpdate(value, 'groupings')"
        />
        <ReportFieldSection
          class="my-4"
          ref="fields"
          :fields="report.meta.fields"
          :selectedFields="reportData.fields"
          :labels="report.labels"
          :hasGrouping="Boolean(size(reportData.groupings))"
          :showValidationErrors="showValidationErrors"
          @updated="value => handleUpdate(value, 'fields')"
        />
        <h2>Report Name</h2>
        <ValidationProvider
          v-slot="{ errors }"
          name="reportName"
          rules="required"
        >
          <v-text-field
            :value="reportData.name"
            @change="value => handleUpdate(value, 'name')"
            :errorMessages="showValidationErrors ? errors : ''"
            dense
          />
        </ValidationProvider>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import ReportFilterSection from "@/views/reports/forms/ReportFilterSection";
import ReportFieldSection from "@/views/reports/forms/ReportFieldSection";
import ReportGroupingSection from "@/views/reports/forms/ReportGroupingSection";
import { size } from "lodash";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  name: "ReportEditor",
  components: {
    ReportFilterSection,
    ReportFieldSection,
    ReportGroupingSection,
    ValidationObserver,
    ValidationProvider
  },
  props: {
    report: Object,
    isLoading: Boolean,
    showValidationErrors: Boolean
  },
  data() {
    return {
      reportData: {
        id: this.report.id,
        filters: this.report.filters.map(rawFilter => {
          const hasUnitField =
            rawFilter.operator === "in_last_date_range" ||
            rawFilter.operator === "in_next_date_range";
          return {
            id: rawFilter.id,
            field: rawFilter.field,
            operator: rawFilter.operator,
            value: rawFilter.value,
            ...(hasUnitField && { unit: rawFilter.unit })
          };
        }),
        fields: this.report.fields,
        groupings: this.report.groupings,
        name: this.report.name,
        focus: this.report.focus
      }
    };
  },
  methods: {
    handleUpdate(value, key) {
      this.$set(this.reportData, key, value);
      this.$emit("updated", this.reportData);
    },
    async validateFields() {
      return this.$refs.reportFields.validate();
    },
    size
  },
  watch: {
    report(newValue) {
      const { focus } = newValue;
      this.reportData.focus = focus;
    }
  }
};
</script>

<style lang="scss">
.report-editor {
  .input-item {
    display: flex;
    width: 100%;

    @media only screen and (min-width: $mobile-breakpoint) {
      .v-btn {
        border: 1px solid $default-line;
        border-radius: 0 4px 4px 0;
        height: 50px;
        width: 50px;
      }
    }

    .v-input.v-text-field {
      margin-top: 0;
      padding-top: 0;
    }
  }

  .add-item {
    margin-bottom: 25px;
  }

  .v-list {
    padding-bottom: 0;
  }

  .drag {
    i {
      cursor: move;
    }
  }

  .inputs {
    display: flex;
    flex-grow: 1;

    @media only screen and (min-width: $mobile-breakpoint) {
      & > * {
        flex-grow: 1;
      }

      .v-input:not(.v-input--is-focused) {
        & .v-input__slot {
          border-right: 0;
        }
      }

      & > :first-child {
        .v-input__slot {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      & > *:not(:first-child) {
        .v-input__slot {
          border-radius: 0;
        }
      }
    }

    @media only screen and (max-width: $mobile-breakpoint - 1) {
      flex-direction: column;
      margin-right: 10px;
      display: contents;

      & > div {
        width: 100%;
      }
    }
  }

  .v-list-item {
    padding: 0 !important;
  }
  .v-select.v-select--chips .v-select__selections {
    max-height: 35px;
    overflow: auto;
  }
}
</style>
