<template>
  <div class="text-center my-4">
    <h2 class="my-2">Unfilled Shifts</h2>
    <div v-if="isFetchingShifts">
      <v-skeleton-loader v-for="i in 3" :key="i" type="list-item-avatar" />
    </div>
    <div class="my-2" v-else>
      <ShiftCalendar :shifts="shifts" @select="updateSelectedDates" />
      <div class="my-2" v-for="(shifts, key) in groupedShifts" :key="key">
        <h3 class="text-center" v-if="isToday(key) || isTomorrow(key)">
          {{ isToday(key) ? "Today" : "Tomorrow" }}
        </h3>
        <h3 class="text-center" v-else>{{ key | shiftDate }}</h3>
        <div v-for="(shift, index) in shifts" :key="index">
          <div class="shift-item my-2">
            <ShiftItem :shift="shift" isDashboard />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ShiftItem from "@/views/shifts/components/ShiftItem";
import { groupByDate } from "@/utils/helpers";
import { createNamespacedHelpers } from "vuex";
import {
  BOOKINGS_NAMESPACE,
  FETCH_SHIFTS
} from "@/store/modules/bookings/actions";
import moment from "moment";
import ShiftCalendar from "@/components/dashboard/shifts/ShiftCalendar";
import { filter } from "lodash";

const { mapActions, mapState } = createNamespacedHelpers(BOOKINGS_NAMESPACE);
export default {
  components: {
    ShiftItem,
    ShiftCalendar
  },
  created() {
    this.loadShifts([this.today]);
    this.selectedDates = [this.today, this.tomorrow];
  },
  data() {
    return {
      selectedDates: [this.today, this.tomorrow]
    };
  },
  computed: {
    ...mapState({
      shifts: state => state.shifts,
      isFetchingShifts: state => state.isFetchingShifts
    }),
    groupedShifts() {
      return groupByDate({ items: this.filteredShifts, format: "MM/DD/YYYY" });
    },
    filteredShifts() {
      return filter(
        this.shifts,
        ({ startDate }) =>
          moment(startDate).isSameOrBefore(
            moment(this.selectedDates[1], "DD-MM-YYYY"),
            "day"
          ) &&
          moment(startDate).isSameOrAfter(
            moment(this.selectedDates[0], "DD-MM-YYYY"),
            "day"
          )
      );
    },
    today() {
      return moment().format("DD-MM-YYYY");
    },
    dateUntil() {
      return moment()
        .add(14, "d")
        .format("DD-MM-YYYY");
    },
    tomorrow() {
      return moment()
        .add(1, "d")
        .format("DD-MM-YYYY");
    }
  },
  methods: {
    ...mapActions({
      fetchShifts: FETCH_SHIFTS
    }),
    loadShifts() {
      const filter = {
        startDate: this.today,
        startDateUntil: this.dateUntil
      };
      this.fetchShifts({ filter });
    },
    updateSelectedDates(dates) {
      this.selectedDates = dates;
    },
    isToday(day) {
      return moment(day, "MM/DD/YYYY").isSame(
        moment(this.today, "DD-MM-YYYY", "day")
      );
    },
    isTomorrow(day) {
      return moment(day, "MM/DD/YYYY").isSame(
        moment(this.tomorrow, "DD-MM-YYYY", "day")
      );
    }
  }
};
</script>

<style lang="scss"></style>
