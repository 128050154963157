<template>
  <div class="app__index">
    <v-app-bar
      v-if="!customToolbar"
      :app="!isMobile"
      flat
      height="auto"
      class="content__header"
      :class="{ centered }"
      id="app-bar"
    >
      <div v-if="isDetails" class="d-flex justify-center w-100">
        <BackButton data-test="back-button" @click.native.stop="handleBack">
          <slot name="backButtonText">Back</slot>
        </BackButton>
      </div>
      <slot name="top-header" v-if="!isLoading" />
      <div data-test="skeleton-loader" v-if="isLoading">
        <v-skeleton-loader width="500" type="heading" />
      </div>
      <div class="content__header__details w-100" v-else>
        <v-toolbar-title
          v-if="title"
          class="content__header__title"
          :class="{ 'small-title': smallTitle }"
          data-test="page-header"
        >
          <h1>{{ title }}</h1>
        </v-toolbar-title>
        <slot name="header" v-if="!isLoading" />
      </div>
    </v-app-bar>
    <v-main class="main-content content-wrapper">
      <slot name="body" />
    </v-main>
  </div>
</template>

<script>
import BackButton from "@/components/common/Button/BackButton";

export default {
  props: {
    /**
     * String for title
     */
    title: {
      type: String,
      required: false
    },
    /**
     * Boolean set to true if details layout
     */
    isDetails: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * String for parent route
     */
    parent: {
      type: String,
      required: false
    },
    isLoading: Boolean,
    centered: Boolean,
    customToolbar: Boolean,
    smallTitle: Boolean
  },
  components: {
    BackButton
  },
  methods: {
    handleBack() {
      this.$router.push(this.parent);
    }
  }
};
</script>
