export const ADDRESS_MODEL = "address";

const addressModel = {
  line1: "",
  line2: "",
  line3: "",
  postCode: "",
  country: "",
  isPrimary: false,
  isBilling: false,
  createdAt: "",
  updatedAt: ""
};

export default addressModel;
