<template>
  <div class="compliance__details">
    <div
      class="compliance--references"
      v-for="(reference, index) in references"
      :key="index"
    >
      <div class="compliance__details__label">Reference Type</div>
      <div class="compliance__details__value">
        {{ reference.referenceTypeName }}
      </div>

      <div class="compliance__details__label">Period Covered</div>
      <div class="compliance__details__value">
        {{ reference.periodStartDate | baseDate }}
        -
        {{ reference.periodEndDate | baseDate }}
      </div>
      <div class="compliance__details__label">Reference Received</div>
      <div class="compliance__details__value">
        {{ reference.referenceReceivedDate | baseDate }}
      </div>
      <v-divider inset v-if="!isLastIndex(index)" class="mb-5" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    /**
     *  Compliance object
     * @example
     * {
     *  label: String
     *  checked: Boolean
     * }
     */
    references: {
      type: Array,
      required: true
    }
  },
  methods: {
    isLastIndex(index) {
      return index === Object.keys(this.references).length - 1;
    }
  }
};
</script>

<style lang="scss" scoped></style>
