import Settings from "@/views/settings/SettingsTab";
import { createRouteWithPermissionCheck } from "@/router/utils";
import AccountTab from "@/views/settings/AccountTab";
import OrganisationTab from "@/views/settings/Organisation/OrganisationTab";
import OrganisationUsersList from "@/views/settings/Organisation/Users/OrganisationUsersList";
import OrganisationUnitsList from "@/views/settings/Organisation/Units/OrganisationUnitsList";
import OrganisationBookingTemplateList from "@/views/settings/Organisation/BookingTemplates/OrganisationBookingTemplateList";
import BookingTemplateDetails from "@/views/settings/Organisation/BookingTemplates/BookingTemplateDetails/BookingTemplateDetails";
import OrganisationLocationsList from "@/views/settings/Organisation/Locations/OrganisationLocationsList";
import OrganisationReasonsForBookingList from "@/views/settings/Organisation/ReasonsForBooking/OrganisationReasonsForBookingList";
import PublishingSchedulesList from "@/views/settings/Organisation/PublishingSchedules/PublishingSchedulesList";
import PublishingScheduleDetails from "@/views/settings/Organisation/PublishingSchedules/PublishingScheduleDetails";
import { redirectWithPermissionCheck } from "@/router/index";
import { MAIN_NAVIGATION_ITEMS } from "@/router/constants";

const { SETTINGS } = MAIN_NAVIGATION_ITEMS;

export const settingsRouter = [
  {
    path: "/settings",
    name: SETTINGS,
    component: Settings,
    beforeEnter: (to, from, next) => {
      redirectWithPermissionCheck(next, SETTINGS);
    },
    children: [
      createRouteWithPermissionCheck({
        path: "account",
        component: AccountTab
      }),
      createRouteWithPermissionCheck({
        path: "organisation",
        component: OrganisationTab,
        permission: "settings.edit-organisation",
        children: [
          createRouteWithPermissionCheck({
            path: "users",
            component: OrganisationUsersList,
            permission: "settings.view-org-users"
          }),
          createRouteWithPermissionCheck({
            path: "units",
            component: OrganisationUnitsList,
            permission: "settings.edit-organisation-unit"
          }),
          createRouteWithPermissionCheck({
            path: "templates",
            component: OrganisationBookingTemplateList,
            permission: "settings.edit-booking-templates"
          }),
          createRouteWithPermissionCheck({
            path: "locations",
            component: OrganisationLocationsList,
            permission: "settings.edit-locations"
          }),
          createRouteWithPermissionCheck({
            path: "reasons",
            component: OrganisationReasonsForBookingList,
            permission: "settings.edit-reasons-for-booking"
          }),
          createRouteWithPermissionCheck({
            path: "schedules",
            component: PublishingSchedulesList,
            permission: "settings.edit-publishing-schedule"
          })
        ]
      })
    ]
  },
  {
    path: "/settings/organisation/schedules/:id",
    name: "scheduleDetails",
    component: PublishingScheduleDetails,
    beforeEnter: (to, from, next) => {
      redirectWithPermissionCheck(next, SETTINGS);
    }
  },
  {
    path: "/settings/organisation/templates/:id",
    name: "bookingTemplateDetails",
    component: BookingTemplateDetails,
    beforeEnter: (to, from, next) => {
      redirectWithPermissionCheck(next, "settings.edit-booking-templates");
    }
  }
];
