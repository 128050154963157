export const FETCH_REPORTS = "FETCH_REPORTS";
export const GET_REPORT = "GET_REPORT";
export const GET_REPORT_DATA = "GET_REPORT_DATA";
export const FETCH_REPORT_CHARTS = "FETCH_REPORT_CHARTS";
export const CREATE_REPORT_CHART = "CREATE_REPORT_CHART";
export const EDIT_REPORT_CHART = "EDIT_REPORT_CHART";
export const CREATE_REPORT = "CREATE_REPORT";
export const UPDATE_REPORT = "UPDATE_REPORT";
export const DELETE_REPORT = "DELETE_REPORT";
export const FETCH_REPORTS_SHARED_WITH_USERS =
  "FETCH_REPORTS_SHARED_WITH_USERS";
export const CREATE_REPORTS_SHARED_WITH_USER =
  "CREATE_REPORTS_SHARED_WITH_USER";
export const EDIT_REPORTS_SHARED_WITH_USER = "EDIT_REPORTS_SHARED_WITH_USER";
export const DELETE_REPORTS_SHARED_WITH_USER =
  "DELETE_REPORTS_SHARED_WITH_USER";
export const UPDATE_SINGLE_REPORT = "UPDATE_SINGLE_REPORT";
