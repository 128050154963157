<template>
  <div class="timesheet-expenses-details">
    <v-data-table
      :headers="headers"
      :items="expenses"
      :mobile-breakpoint="null"
    >
      <template v-slot:item.file="{ item }">
        <div
          v-if="item.file"
          class="file"
          @click="() => handleViewFile(item.file)"
        >
          <span> View File </span>
          <v-icon color="#0965DC" size="1rem">mdi-arrow-top-right</v-icon>
        </div>
      </template>
      <template v-slot:item.expenseAmount="{ item }">
        <div>
          <span>
            {{ item.expenseAmount | currency(item.currencyCode) }}
          </span>
        </div>
      </template>
      <template v-slot:body.append v-if="expenses.length">
        <tr>
          <td colspan="2" />
          <td
            class="font-weight-bold"
            v-if="permissions['timesheets.view.total-cost']"
          >
            <div class="mr-2">
              {{ totalCost | cost | currency(globalCurrencyCode) }}
            </div>
          </td>
        </tr>
      </template>
    </v-data-table>
    <PDFViewFull :data="fileDialog" />
  </div>
</template>

<script>
import PDFViewFull from "@/components/common/PDFViewFull";
import { sumBy, first } from "lodash";
import { getToken } from "@/services/utils";

export default {
  name: "TimesheetDetailsExpenses",
  components: {
    PDFViewFull
  },
  props: {
    permissions: Object,
    expenses: Array
  },
  async created() {
    this.globalCurrencyCode = first(this.expenses).currencyCode;
  },
  data() {
    return {
      globalCurrencyCode: "GBP",
      fileDialog: {
        title: "File Name",
        dialog: false,
        src:
          "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
      }
    };
  },
  methods: {
    handleViewFile(file) {
      const token = getToken();
      this.fileDialog.src = `${file.url}?token=${token}`;
      this.fileDialog.title = file.fileName;
      this.fileDialog.dialog = true;
    }
  },
  computed: {
    currentTimesheetId() {
      return this.$route.params.id;
    },
    totalCost() {
      return sumBy(this.expenses, "expenseAmount");
    },
    headers() {
      return [
        {
          text: "Description",
          value: "description",
          width: "50%",
          sortable: false
        },
        { text: "File", value: "file", sortable: false },
        { text: "Cost", value: "expenseAmount" }
      ];
    }
  }
};
</script>

<style lang="scss">
.timesheet-expenses-details {
  margin-bottom: $timesheet-footer-height + 40px;
  width: 100%;

  .file {
    font-weight: bold;
    cursor: pointer;
    color: #0965dc;
  }
  &.v-data-table th {
    color: $primary-text !important;
  }
}
</style>
