<template>
  <DialogFull :data="dialogSettings" @close="close">
    <div class="container col-lg-8 col-sm-12">
      <h2 class="mt-6">Share report with other users</h2>
      <Select
        autocomplete
        v-model="selectedUser"
        @input="handleAddUser($event)"
        :disabled="isFetchingUsers"
        :loading="isFetchingUsers"
        :items="filteredUsers"
        item-text="fullName"
      />
      <SharedUsersItem
        :isLoading="isFetchingReportsSharedWithUsers"
        :selectedUsers="selectedUsers"
        @change="handleChangePermission"
        @remove="addUserToBeRemoved"
      />
      <Checkbox
        @change="handleChangeVisibility"
        :selected-values="globallyVisible ? ['checked'] : []"
        :options="[
          {
            label: 'Allow all admins and super admin users to view this report',
            value: 'checked'
          }
        ]"
        isLarge
      />
    </div>
    <template v-slot:dialog.action>
      <PrimaryButton @click.native="handleSave" :loading="isSaving">
        Save Changes
      </PrimaryButton>
    </template>
  </DialogFull>
</template>

<script>
import DialogFull from "@/components/common/DialogFull";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import { createNamespacedHelpers } from "vuex";
import {
  FETCH_REPORTS_SHARED_WITH_USERS,
  CREATE_REPORTS_SHARED_WITH_USER,
  EDIT_REPORTS_SHARED_WITH_USER,
  DELETE_REPORTS_SHARED_WITH_USER,
  UPDATE_SINGLE_REPORT
} from "@/store/modules/reports/action-types";
import { REPORTS_NAMESPACE } from "@/store/modules/reports";
import { FETCH_USERS } from "@/store/modules/users/actions";
import {
  GET_USERS,
  GET_IS_FETCHING_USERS
} from "@/store/modules/users/getters";
import { USERS_NAMESPACE } from "@/store/modules/users";
import Select from "@/components/common/Select";
import { getFullName } from "@/utils/users";
import { find, reduce, filter, map, isEmpty } from "lodash";
import SharedUsersItem from "@/components/reports/SharedUsersItem";
import Checkbox from "@/components/common/Checkbox";

const { mapActions, mapState } = createNamespacedHelpers(REPORTS_NAMESPACE);
const { mapActions: mapUsersAction, mapGetters } = createNamespacedHelpers(
  USERS_NAMESPACE
);
export default {
  components: {
    DialogFull,
    PrimaryButton,
    Select,
    SharedUsersItem,
    Checkbox
  },
  props: {
    sharedDialog: Boolean,
    report: Object
  },
  created() {
    this.fetchUsers(this.params);
    this.setUsersToBeUpdated();
  },
  data() {
    return {
      dialogSettings: {
        dialog: this.sharedDialog,
        title: "Share Report"
      },
      isSaving: false,
      selectedUser: "",
      usersToBeRemoved: [],
      usersToBeAdded: [],
      usersTobeUpdated: [],
      globallyVisible: this.report.globallyVisible
    };
  },
  computed: {
    ...mapState(["reportsSharedWithUsers", "isFetchingReportsSharedWithUsers"]),
    ...mapGetters({
      users: GET_USERS,
      isFetchingUsers: GET_IS_FETCHING_USERS
    }),
    currentReportId() {
      return this.$route.params.reportId;
    },
    filter() {
      return { focused_report_id: this.currentReportId };
    },
    params() {
      return { filter: { role: ["msp user", "msp admin"] } };
    },
    filteredUsers() {
      return reduce(
        this.users,
        (filteredUsers, user) => {
          if (
            !find(
              this.reportsSharedWithUsers,
              shared => shared.user.id === user.id
            ) &&
            !find(
              this.usersToBeAdded,
              userToBeAdded => userToBeAdded.user.id === user.id
            )
          ) {
            filteredUsers.push({
              fullName: getFullName(user),
              ...user
            });
          }
          return filteredUsers;
        },
        []
      );
    },
    selectedUsers() {
      return [...this.usersTobeUpdated, ...this.usersToBeAdded];
    }
  },
  methods: {
    ...mapActions({
      fetchReportsShared: FETCH_REPORTS_SHARED_WITH_USERS,
      createReportsShared: CREATE_REPORTS_SHARED_WITH_USER,
      editReportsShared: EDIT_REPORTS_SHARED_WITH_USER,
      deleteReportsShared: DELETE_REPORTS_SHARED_WITH_USER,
      updateReport: UPDATE_SINGLE_REPORT
    }),
    ...mapUsersAction({ fetchUsers: FETCH_USERS }),
    async setUsersToBeUpdated() {
      await this.fetchReportsShared(this.filter);
      this.usersTobeUpdated = [...this.reportsSharedWithUsers];
    },
    handleChangePermission({ permission, selected }) {
      if (selected.id) {
        this.usersTobeUpdated = map(this.usersTobeUpdated, userTobeUpdated => {
          if (selected.id === userTobeUpdated.id) {
            userTobeUpdated.permission = permission;
          }
          return userTobeUpdated;
        });
      } else {
        this.usersToBeAdded = map(this.usersToBeAdded, userToBeAdded => {
          if (selected.user.id === userToBeAdded.user.id) {
            userToBeAdded.permission = permission;
          }
          return userToBeAdded;
        });
      }
    },
    addUserToBeRemoved(selected) {
      this.selectedUser = "";
      if (selected.id) {
        this.usersTobeUpdated = filter(
          this.usersTobeUpdated,
          ({ id }) => id !== selected.id
        );
        this.usersToBeRemoved.push(selected.id);
      } else {
        this.usersToBeAdded = filter(
          this.usersToBeAdded,
          ({ user }) => user.id !== selected.user.id
        );
      }
    },
    handleAddUser(user) {
      this.usersToBeAdded.push({
        report: { id: this.currentReportId },
        permission: "read",
        user
      });
    },
    async handleSave() {
      this.isSaving = true;
      const usersToBeAddedRequests = map(this.usersToBeAdded, userToBeAdded =>
        this.createReportsShared(userToBeAdded)
      );
      const usersTobeUpdatedRequests = map(
        this.usersTobeUpdated,
        userTobeUpdated => {
          delete userTobeUpdated.report;
          delete userTobeUpdated.user;
          this.editReportsShared(userTobeUpdated);
        }
      );
      const usersToBeRemovedRequests = map(this.usersToBeRemoved, id =>
        this.deleteReportsShared(id)
      );
      await this.updateReport({
        id: this.currentReportId,
        globallyVisible: this.globallyVisible
      });
      await Promise.all(usersToBeAddedRequests);
      await Promise.all(usersTobeUpdatedRequests);
      await Promise.all(usersToBeRemovedRequests);
      this.isSaving = false;
      this.setUsersToBeUpdated();
      this.close();
    },
    handleChangeVisibility(value) {
      this.globallyVisible = !isEmpty(value);
    },
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss"></style>
