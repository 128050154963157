<template>
  <div class="filter-checkbox">
    <div class="d-flex mb-2">
      <PrimaryButton @click="handleSelectAll" text shrink>
        Select All
      </PrimaryButton>
      <ErrorButton
        @click="handleClear"
        text
        shrink
        v-if="!isEmpty(selectedValues)"
      >
        Clear
      </ErrorButton>
    </div>
    <v-text-field v-model="searchText" placeholder="Search" clearable />
    <div class="filter-checkbox-container">
      <v-checkbox
        v-for="(option, index) in filteredOptions"
        :key="`option-${index}`"
        color="black"
        :label="option.text"
        :value="option.value"
        :input-value="selectedValues"
        @change="saveChanges"
        multiple
        dense
      />
    </div>
  </div>
</template>

<script>
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import ErrorButton from "@/components/common/Button/ErrorButton";
import { map, isEmpty, uniq, size } from "lodash";
import { searchInFilterOptions } from "@/utils/filters";

export default {
  model: {
    prop: "selectedValues",
    event: "change"
  },
  props: {
    /**
     * Array of objects containing the label and values
     * [{
     *  value,
     *  label
     * }]
     */
    options: {
      type: Array,
      required: true
    },
    /**
     * Default selected value
     */
    selectedValues: {
      type: [Array, String, Number],
      required: false,
      default() {
        return [];
      }
    }
  },
  components: { PrimaryButton, ErrorButton },
  data() {
    return {
      searchText: ""
    };
  },
  computed: {
    filteredOptions() {
      return searchInFilterOptions(this.searchText, this.options);
    }
  },
  methods: {
    saveChanges(newValues) {
      if (newValues) {
        const values =
          size(newValues) === size(this.selectedValues) - 1 && !this.searchText
            ? newValues
            : uniq([...this.selectedValues, ...newValues]);
        this.$emit("change", values);
      }
    },
    handleSelectAll() {
      const values = [];
      map(this.options, option => values.push(option.value));
      this.saveChanges(values);
    },
    handleClear() {
      this.$emit("change", []);
      this.saveChanges();
    },
    isEmpty
  }
};
</script>

<style lang="scss">
.filter-checkbox {
  .v-input.v-text-field {
    margin-top: 0;
  }
  .base-button.shrink.v-btn:not(.v-btn--round).v-size--default {
    padding: 0.2rem !important;
  }
}

.filter-checkbox-container {
  max-height: 500px;
  overflow: scroll;
}
</style>
