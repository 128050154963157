import APIService, { withPrefix } from "@/services/APIService";
import JSONApiService from "@/services/JSONApiService";
import { INVOICE_MODEL } from "@/models/invoice-model";
import { PAYROLL_MODEL } from "@/models/payroll-model";
import { isNotTestingEnv } from "@/services/utils";
import {
  include,
  nested,
  CLIENT_INVOICE_SET,
  CLIENT
} from "@/models/relationships";

const API = isNotTestingEnv() && withPrefix(APIService.client(), "invoices");

const invoicesApi = ({ commit } = {}) => ({
  getAllInvoices: async params =>
    JSONApiService(commit)
      .all(INVOICE_MODEL)
      .get({
        ...params,
        ...include(CLIENT_INVOICE_SET, nested(CLIENT_INVOICE_SET, CLIENT))
      }),
  getPayroll: async params =>
    JSONApiService(commit)
      .all(PAYROLL_MODEL)
      .get(params),
  getInvoice: async invoiceId => API.get(`${invoiceId}`),
  generateInvoice: async data =>
    JSONApiService(commit).create(INVOICE_MODEL, data),
  generatePayroll: async data =>
    JSONApiService(commit).create(PAYROLL_MODEL, data)
});

export default invoicesApi;
