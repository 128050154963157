export const IS_LOADING_SHIFT_CANDIDATES = "IS_LOADING_SHIFT_CANDIDATES";
export const GET_SHIFT_CANDIDATES = "GET_SHIFT_CANDIDATES";
export const GET_IS_FETCHING_AVAILABLE_CANDIDATES =
  "GET_IS_FETCHING_AVAILABLE_CANDIDATES";
export const GET_AVAILABLE_CANDIDATES = "GET_AVAILABLE_CANDIDATES";
export const GET_APPLICATION_SHIFTS = "GET_APPLICATION_SHIFTS";
export const IS_LOADING_APPLICATION_SHIFTS = "IS_LOADING_APPLICATION_SHIFTS";
export const GET_UNAVAILABLE_CANDIDATES = "GET_UNAVAILABLE_CANDIDATES";
export const GET_IS_FETCHING_UNAVAILABLE_CANDIDATES =
  "GET_IS_FETCHING_UNAVAILABLE_CANDIDATES";
export const GET_UNAVAILABLE_WORKERS = "GET_UNAVAILABLE_WORKERS";
export const GET_IS_FETCHING_UNAVAILABLE_WORKERS =
  "GET_IS_FETCHING_UNAVAILABLE_WORKERS";

const getters = {
  [IS_LOADING_SHIFT_CANDIDATES]: state => state.isFetchingShiftCandidates,
  [GET_SHIFT_CANDIDATES]: state => state.shiftCandidates,
  [GET_IS_FETCHING_AVAILABLE_CANDIDATES]: state =>
    state.isFetchingAvailableCandidates,
  [GET_AVAILABLE_CANDIDATES]: state => state.availableCandidates,
  [GET_APPLICATION_SHIFTS]: state => state.applicationShifts,
  [IS_LOADING_APPLICATION_SHIFTS]: state => state.isFetchingApplicationShifts,
  [GET_IS_FETCHING_UNAVAILABLE_CANDIDATES]: state =>
    state.isFetchingUnavailableCandidates,
  [GET_UNAVAILABLE_CANDIDATES]: state => state.unavailableCandidates,
  [GET_IS_FETCHING_UNAVAILABLE_WORKERS]: state =>
    state.isFetchingUnavailableWorkers,
  [GET_UNAVAILABLE_WORKERS]: state => state.unavailableWorkers
};

export default getters;
