var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.readonly)?_c('div',_vm._l((_vm.files),function(file){return _c('FileComponent',{key:file.id,staticClass:"py-2",attrs:{"isRemoving":_vm.isLoading,"file":file,"disabled":"","customActions":""},on:{"show":function($event){return _vm.onShow(file)}}})}),1):_c('FileUpload',{attrs:{"uploadingUrl":_vm.getAddFileUrl,"fileTypes":[
      '.pdf',
      '.jpeg',
      '.jpg',
      '.png',
      '.doc',
      '.docx',
      '.xlsx',
      '.ppt'
    ],"files":_vm.files,"id":_vm.bookingId,"isLoading":_vm.isLoading,"customActions":"","isMultiple":""},on:{"removeFile":_vm.onRemoveFile,"show":_vm.onShow,"selectFile":_vm.selectFile,"onUploadSuccess":_vm.updateFiles}}),_c('PDFViewFull',{attrs:{"data":_vm.fileDialog}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }