<template>
  <ValidationObserver ref="reports">
    <div class="wrapper" v-if="isFetchingReports">
      <v-skeleton-loader
        elevation="1"
        class="skeleton-items"
        type="list-item-three-line"
        v-for="(item, index) in skeletonItems"
        :key="index"
      />
    </div>
    <Permissioned
      section="reports"
      class="wrapper mt-4 reports__container"
      v-else-if="reports.length"
    >
      <report-card
        @validate="validate"
        v-for="report in reports"
        :key="report.id"
        :report="report"
        :dateFrom="dateFrom"
        :dateTo="dateTo"
        :clients="clients"
        :permission="checkPermission(report.title)"
      />
    </Permissioned>
  </ValidationObserver>
</template>

<script>
import Permissioned from "@/components/common/Permissioned";
import reportCard from "@/components/exports/ExportCard";
import { createNamespacedHelpers } from "vuex";
import { ValidationObserver } from "vee-validate";
import { EXPORTS_NAMESPACE } from "@/store/modules/exports";
import { FETCH_REPORTS } from "@/store/modules/exports/action-types";

const { mapState, mapActions } = createNamespacedHelpers(EXPORTS_NAMESPACE);

export default {
  components: {
    reportCard,
    Permissioned,
    ValidationObserver
  },
  props: {
    dateRange: Array,
    clients: Array
  },
  data() {
    return {
      skeletonItems: new Array(8).fill().map((a, i) => i),
      invalidDaterange: false,
      error: {
        title: `Invalid Date`,
        detail: `Please select correct Date Range`
      }
    };
  },
  created() {
    this.fetchReports();
  },
  computed: {
    ...mapState({
      reports: state => state.reports,
      isFetchingReports: state => state.isFetchingReports
    }),
    dateFrom() {
      return this.dateRange ? this.dateRange[0] : null;
    },
    dateTo() {
      return this.dateRange ? this.dateRange[1] : null;
    }
  },
  methods: {
    ...mapActions({
      fetchReports: FETCH_REPORTS
    }),
    onDateChange(val) {
      this.dateFrom = val[0];
      this.dateTo = val[1];
    },
    checkPermission(permissionTitle) {
      switch (permissionTitle) {
        case `WTR Report`:
          return ``;
        case `Agency Request Status Report`:
          return `agency-request-status`;
        case `Employment Intermediary Report`:
          return `employment-intermediary`;
        case `Finance Report`:
          return `finance`;
        case `Shift Status Report`:
          return `shift-status`;
        case `Timesheet Status & Exceptions Report`:
          return `timesheet-status-and-exceptions`;
        case `Candidate Compliance Report`:
          return `candidate-compliance`;
        case `Agency Timesheet Status & Exceptions Report`:
          return `timesheet-status-and-exceptions-agency`;
        case `Current Temps Report`:
          return `current-temps`;
        default:
          return ``;
      }
    },
    async validate() {
      this.invalidDaterange = true;
      await new Promise(() =>
        setTimeout(() => {
          this.onSnackbarClose();
        }, 2000)
      );
    },
    onSnackbarClose() {
      this.invalidDaterange = false;
    }
  }
};
</script>
