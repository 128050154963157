<template>
  <v-btn text class="mr-1 mr-2 px-3 py-3">
    <v-icon>mdi-chevron-left</v-icon>
    <slot name="default"></slot>
  </v-btn>
</template>

<script>
export default {
  name: "BackButton"
};
</script>

<style scoped lang="scss"></style>
