<template>
  <BaseDetails class="base-details" v-if="showSchedules">
    <template v-slot:header>
      <h2 class="pb-3">Publishing Schedule</h2>
      <Permissioned section="bookings.edit">
        <EditQuotaAction
          permission="quotas"
          :bookingId="currentBookingId"
          :publishingSchedules="publishingSchedules"
          @update="fetchPublishingSchedule(currentBookingId)"
        />
      </Permissioned>
    </template>
    <v-skeleton-loader v-if="isLoadingSchedules" type="list-item-three-line" />
    <div v-for="(scheduleGroup, tierName) in groupedSchedules" :key="tierName">
      <p class="subsection-title">{{ tierName }}</p>
      <PublishScheduleItem
        v-for="schedule in scheduleGroup"
        :key="schedule.id"
        :label="schedule.agency.name"
        :publishingDate="schedule.publishOnDate"
        :confirmed="isConfirmed(schedule)"
        :unitId="schedule.id"
        :quota="schedule.quotas ? schedule.quotas : {}"
        @publish="handlePublish"
      />
    </div>
  </BaseDetails>
</template>

<script>
import BaseDetails from "@/components/common/BaseDetails/BaseDetails";
import PublishScheduleItem from "@/components/bookings/PublishScheduleItem";
import { createNamespacedHelpers } from "vuex";
import {
  BOOKINGS_NAMESPACE,
  FETCH_CURRENT_BOOKING_PUBLISHING_SCHEDULE
} from "@/store/modules/bookings/actions";
import {
  GET_CURRENT_BOOKING_PUBLISHING_SCHEDULE,
  IS_LOADING_BOOKING_PUBLISHING_SCHEDULE
} from "@/store/modules/bookings/getters";
import { groupByPropertyName } from "@/utils/bookings";
import EditQuotaAction from "../actions/EditQuotaAction";
import Permissioned from "@/components/common/Permissioned";

const { mapActions, mapGetters } = createNamespacedHelpers(BOOKINGS_NAMESPACE);
const { mapState } = createNamespacedHelpers("auth");

export default {
  name: "PublishingSchedule",
  components: {
    PublishScheduleItem,
    BaseDetails,
    EditQuotaAction,
    Permissioned
  },
  inject: ["currentBookingId"],
  created() {
    this.fetchPublishingSchedule(this.currentBookingId);
  },
  computed: {
    ...mapGetters({
      isLoading: IS_LOADING_BOOKING_PUBLISHING_SCHEDULE,
      publishingSchedules: GET_CURRENT_BOOKING_PUBLISHING_SCHEDULE
    }),
    ...mapState({
      user: state => state.userData
    }),
    groupedSchedules() {
      return groupByPropertyName(this.publishingSchedules, "tierName");
    },
    hasSchedules() {
      return this.isLoading || this.publishingSchedules.length;
    },
    isLoadingSchedules() {
      return this.isLoading && !this.publishingSchedules.length;
    },
    showSchedules() {
      return this.hasSchedules && this.user.accountType === "msp";
    }
  },
  methods: {
    ...mapActions({
      fetchPublishingSchedule: FETCH_CURRENT_BOOKING_PUBLISHING_SCHEDULE
    }),
    isConfirmed(schedule) {
      return schedule.state !== "pending";
    },
    handlePublish() {
      this.fetchPublishingSchedule(this.currentBookingId);
    }
  }
};
</script>

<style lang="scss">
.base-details {
  .subsection-title {
    font-size: 1.1rem;
  }

  .caption {
    margin-bottom: 0;
  }
}
</style>
