import { isMockedDataEnabled } from "@/utils/mocked-data";
import { MOCKED_SETTINGS_STATE } from "@/constants/settings";
import actions from "@/store/modules/settings/actions";
import mutations from "@/store/modules/settings/mutations";
import getters from "@/store/modules/settings/getters";

const state = {
  organisationUsers: [],
  isFetchingUsers: false
};

export default {
  namespaced: true,
  state: isMockedDataEnabled() ? MOCKED_SETTINGS_STATE : state,
  actions: isMockedDataEnabled() ? {} : actions,
  mutations: isMockedDataEnabled() ? {} : mutations,
  getters
};
