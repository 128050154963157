<template>
  <div>
    <div
      class="submit-timesheet"
      v-if="currentTimesheet && !isLoadingTimesheet"
    >
      <div class="wrapper">
        <div class="table-wrapper pr-0">
          <AppLayout
            parent="/timesheets"
            isDetails
            class="timesheet--details container col-lg-8 col-md-9 col-sm-12"
          >
            <template #backButtonText>Timesheets</template>
            <template v-slot:header>
              <TimesheetDetailsHeader :timesheet="currentTimesheet" />
            </template>
            <template v-slot:body>
              <Permissioned
                section="timesheets"
                :id="currentTimesheet.id"
                class="timesheet-comments-wrapper"
              >
                <Comment
                  :isLoading="isLoadingComments"
                  class="timesheet-comments"
                  permission="view.comments"
                  :comments="comments"
                  @comment="addNewComment"
                />
              </Permissioned>
              <div>
                <v-tabs v-model="selectedTab" class="timesheet-tabs">
                  <v-tab
                    v-for="tabHeader in tabs"
                    :key="tabHeader"
                    :value="tabHeader"
                  >
                    {{ tabHeader }}
                  </v-tab>
                  <v-tab-item>
                    <TimeList
                      :key="listId"
                      :triggerEdit="triggerEdit"
                      @editing="handleEditing"
                      @success="handleSuccess"
                    />
                  </v-tab-item>
                  <v-tab-item>
                    <ExpenseList
                      :permissions="permissions"
                      ref="expenseList"
                      permission="expenses"
                    />
                  </v-tab-item>
                </v-tabs>
                <ValidationObserver ref="terms" permission="submit">
                  <Terms
                    class="pb-2 pt-5"
                    :value="isConfirmed"
                    @onConfirmationValueChanged="handleConfirmationChanged"
                  />
                </ValidationObserver>
              </div>
            </template>
          </AppLayout>
        </div>
      </div>
      <BaseFooter>
        <ExpandedButtons>
          <Permissioned
            section="timesheets"
            :id="currentTimesheet.id"
            class="d-flex actions-bar"
            :class="{ vertical: isMobile, 'align-center': isMobile }"
          >
            <SecondaryButton
              permission="submit"
              @click.native="handleSubmit"
              :loading="isLoading"
              class="submit-timesheet-button"
              v-if="!isEditing && !editState"
              color="primary"
            >
              <inline-svg
                :src="require('@/assets/svg/submit-timesheet.svg')"
                class="mb-1"
              />
              Submit Timesheet
            </SecondaryButton>
            <template v-else>
              <ErrorButton
                light
                :loading="isFetchingTimesheetEntries"
                :full-width="isMobile"
                @click.native="handleCancelEditing"
              >
                Cancel
              </ErrorButton>
              <PrimaryButton
                class="save-changes-btn"
                :class="{
                  'ml-2': !isMobile,
                  'full-width': isMobile,
                  'mt-1': isMobile
                }"
                :loading="Boolean(entriesToEdit.length)"
                :disabled="isFetchingTimesheetEntries"
                @click.native="handleEdit"
              >
                Save Changes
              </PrimaryButton>
            </template>
          </Permissioned>
        </ExpandedButtons>
      </BaseFooter>
    </div>
    <v-overlay v-else z-index="9">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
  </div>
</template>

<script>
import AppLayout from "@/components/common/base-layouts/AppLayout";
import { ValidationObserver } from "vee-validate";
import TimeList from "@/components/timesheets/time/TimeList";
import ExpenseList from "@/views/timesheets/components/ExpenseList";
import Comment from "@/components/common/Comment";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import { BASE_DATE_FORMAT } from "@/constants/common.js";
import moment from "moment";
import { TIMESHEETS_NAMESPACE } from "@/store/modules/timesheets";
import { SET_CURRENT_TIMESHEET } from "@/store/modules/timesheets/mutations";
import { createNamespacedHelpers } from "vuex";
import {
  ADD_COMMENT,
  FETCH_CURRENT_TIMESHEET_COMMENTS,
  GET_CURRENT_TIMESHEET,
  SUBMIT_TIMESHEET,
  FETCH_CURRENT_TIMESHEET_ENTRIES
} from "@/store/modules/timesheets/actions";
import Permissioned from "@/components/common/Permissioned";
import { isPermissioned } from "@/utils/permissions";
import { TIMESHEET_MODEL } from "@/models/timesheet-model";
import Terms from "@/views/timesheets/components/Terms";
import { EDITABLE_STATUSES } from "@/views/timesheets/TimesheetList.vue";
import { includes } from "lodash";
import { AUTH_NAMESPACE } from "@/store/modules/auth";
import generateRandomId from "uuid/v4";
import ErrorButton from "@/components/common/Button/ErrorButton";
import TimesheetDetailsHeader from "@/views/timesheets/details/components/TimesheetDetailsHeader";
import { isWorker } from "@/utils/users";
import BaseFooter from "@/components/common/BaseFooter";
import ExpandedButtons from "@/components/common/ExpandedButtons";
import InlineSvg from "vue-inline-svg";
import SecondaryButton from "@/components/common/Button/SecondaryButton";

const { mapActions, mapMutations, mapState } = createNamespacedHelpers(
  TIMESHEETS_NAMESPACE
);
const { mapState: authMapState } = createNamespacedHelpers(AUTH_NAMESPACE);

export default {
  components: {
    ValidationObserver,
    Permissioned,
    PrimaryButton,
    AppLayout,
    TimeList,
    ExpenseList,
    Comment,
    Terms,
    ErrorButton,
    TimesheetDetailsHeader,
    SecondaryButton,
    BaseFooter,
    ExpandedButtons,
    InlineSvg
  },
  data() {
    return {
      isConfirmed: null,
      showComments: true,
      isLoading: false,
      selectedTab: null,
      hasNewExpense: false,
      triggerEdit: null,
      isEditing: false,
      listId: 0
    };
  },
  async created() {
    const { data: currentTimesheet } = await this.getCurrentTimesheet(
      this.currentTimesheetId
    );
    if (
      (!isPermissioned(`timesheets[${this.currentTimesheetId}].edit`) ||
        !includes(EDITABLE_STATUSES, currentTimesheet.status)) &&
      !this.editState
    ) {
      this.$router.push({
        name: "timesheetDetails"
      });
    }
    this.fetchCurrentTimesheetComments(this.currentTimesheetId);
  },
  methods: {
    ...mapActions({
      getCurrentTimesheet: GET_CURRENT_TIMESHEET,
      fetchCurrentTimesheetComments: FETCH_CURRENT_TIMESHEET_COMMENTS,
      addComment: ADD_COMMENT,
      submitTimesheet: SUBMIT_TIMESHEET,
      fetchCurrentTimesheetEntries: FETCH_CURRENT_TIMESHEET_ENTRIES
    }),
    ...mapMutations({
      setCurrentTimesheet: SET_CURRENT_TIMESHEET
    }),
    async addNewComment(comment) {
      await this.addComment({
        timesheetId: this.currentTimesheetId,
        comment: {
          body: comment,
          commentableType: TIMESHEET_MODEL,
          commentableId: this.currentTimesheetId
        }
      });
      await this.fetchCurrentTimesheetComments(this.currentTimesheetId);
    },
    async handleSubmit() {
      await this.addNewExpense();
      const areTermsChecked = await this.$refs.terms.validate();
      if (areTermsChecked) {
        this.isLoading = true;
        try {
          await this.submitTimesheet(this.currentTimesheetId);
          this.$router.push("/timesheets");
          this.setCurrentTimesheet(null);
        } finally {
          this.isLoading = false;
        }
      }
    },
    toggleComments() {
      this.showComments = !this.showComments;
    },
    async addNewExpense() {
      if (!this.hasNewExpense) {
        return true;
      }
      await this.$refs.expenseList.$refs.newExpense.onAddExpenseOnSubmit(false);
    },
    handleConfirmationChanged(value) {
      this.isConfirmed = value;
    },
    handleEdit() {
      // random id is used to trigger child components
      this.triggerEdit = generateRandomId();
    },
    async handleCancelEditing() {
      try {
        await this.fetchCurrentTimesheetEntries(this.currentTimesheetId);
        this.isEditing = false;
      } catch {
        this.isEditing = true;
      }
    },
    handleEditing() {
      this.isEditing = true;
    },
    handleSuccess() {
      this.isEditing = false;
      this.listId++;
    }
  },
  computed: {
    ...authMapState(["permissions", "userData"]),
    ...mapState({
      currentTimesheet: state => state.currentTimesheet,
      isLoadingTimesheet: state => state.isFetchingCurrentTimesheet,
      entriesToEdit: state => state.entriesToEdit,
      comments: state => state.timesheetComments,
      isFetchingTimesheetComments: state => state.isFetchingTimesheetComments,
      isFetchingTimesheetEntries: state => state.isFetchingTimesheetEntries
    }),
    formattedTimesheet() {
      const { title, weekCommencing } = this.currentTimesheet;
      const formattedWeekCommencingDate = weekCommencing
        ? moment(weekCommencing).format(BASE_DATE_FORMAT)
        : "";
      return {
        ...this.currentTimesheet,
        role: title,
        weekCommencing: formattedWeekCommencingDate
      };
    },
    tabs() {
      return [
        "Time",
        ...(this.permissions[
          `timesheets[${this.currentTimesheetId}].edit.expenses`
        ]
          ? ["Expenses"]
          : [])
      ];
    },
    currentTimesheetId() {
      return this.$route.params.id;
    },
    headers() {
      return [
        { text: "Date", value: "date" },
        { text: "Time", value: "time", sortable: false },
        { text: "Hours", value: "hours" },
        { text: "Cost", value: "cost" }
      ];
    },
    error() {
      return null;
    },
    isErrorVisible() {
      return !!this.error;
    },
    tableHeaderItems() {
      return [
        { label: "Role", value: "role" },
        {
          label: "Week Commencing",
          value: "weekCommencing"
        }
      ];
    },
    isLoadingComments() {
      return this.isFetchingTimesheetComments && !this.comments.length;
    },
    editState() {
      return (
        !isWorker(this.userData.roles) &&
        !includes(EDITABLE_STATUSES, this.currentTimesheet.status)
      );
    }
  }
};
</script>

<style lang="scss"></style>
