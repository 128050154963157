<template>
  <DialogFull :data="dialogSettings" @close="close">
    <v-form ref="form" lazy-validation class="flex-gap-20 container mt-5">
      <TextField
        label="Address Line 1"
        @change="locationData.line1 = $event"
        outlined
        dense
        :value="locationData.line1"
        :rules="isRequired('Address Line 1', shouldValidate)"
        with-errors
      />
      <TextField
        label="Address Line 2"
        @change="locationData.line2 = $event"
        :value="locationData.line2"
        outlined
        dense
        :rules="isRequired('Address Line 2', shouldValidate)"
        with-errors
      />
      <TextField
        label="Address Line 3 (optional)"
        @change="locationData.line3 = $event"
        :value="locationData.line3"
        with-errors
        outlined
        dense
      />
      <TextField
        label="Post Code"
        @change="locationData.postCode = $event"
        :value="locationData.postCode"
        outlined
        dense
        :rules="isRequired('Post Code', shouldValidate)"
        with-errors
      />
      <Select
        @change="locationData.country = $event"
        :value="locationData.country"
        label="Country"
        placeholder="Select Country"
        :items="countries"
        item-text="countryName"
        item-value="id"
        autocomplete
        :rules="isRequired('Country', shouldValidate)"
      />
    </v-form>
    <template v-slot:dialog.action>
      <PrimaryButton @click.native="submit" :loading="loading">{{
        actionName
      }}</PrimaryButton>
    </template>
  </DialogFull>
</template>

<script>
import DialogFull from "@/components/common/DialogFull";
import TextField from "@/components/common/TextField";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import Select from "@/components/common/Select";
import { createNamespacedHelpers } from "vuex";
import { DATA_NAMESPACE } from "@/store/modules/data";
import {
  GET_COUNTRIES,
  GET_IS_FETCHING_DATA
} from "@/store/modules/data/getters";
import { FETCH_COUNTRIES } from "@/store/modules/data/actions";
import { isRequired } from "@/utils/validation/rules";

const INIT_LOCATION_DATA = {
  status: "Active"
};
const { mapActions, mapGetters } = createNamespacedHelpers(DATA_NAMESPACE);

export default {
  name: "LocationDialog",
  components: { Select, PrimaryButton, TextField, DialogFull },
  props: {
    isOpen: Boolean,
    location: Object,
    title: String,
    actionName: String,
    loading: Boolean
  },
  data() {
    return {
      locationData: {},
      shouldValidate: false
    };
  },
  computed: {
    ...mapGetters({
      countries: GET_COUNTRIES,
      isLoadingCountries: GET_IS_FETCHING_DATA
    }),
    dialogSettings() {
      return {
        dialog: this.isOpen,
        title: this.title
      };
    },
    isValid() {
      return ["line1", "line2", "postCode", "country"].every(
        value => this.locationData[value]
      );
    }
  },
  created() {
    this.fetchCountries();
    this.locationData = { ...INIT_LOCATION_DATA, ...this.location };
  },
  methods: {
    ...mapActions({
      fetchCountries: FETCH_COUNTRIES
    }),
    isRequired,
    close() {
      this.shouldValidate = false;
      this.$emit("close");
    },
    submit() {
      this.locationData = { ...this.locationData };
      this.shouldValidate = true;
      this.$refs.form.validate();
      if (this.isValid) {
        this.$emit("submit", this.locationData);
      }
    }
  }
};
</script>

<style lang="scss"></style>
