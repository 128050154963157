<template>
  <v-chip
    label
    class="base-chip no-select"
    :class="{ shrink }"
    :style="{
      backgroundColor: styles[`${chipColor}Background`],
      width,
      fontWeight: 600
    }"
  >
    <slot />
  </v-chip>
</template>

<script>
import styles from "@/sass/abstracts/_chips-colors.scss";
import { getColorByStatus } from "@/utils/bookings";

export default {
  name: "Chip",
  data() {
    return {
      styles,
      defaultColor: "gray"
    };
  },
  computed: {
    chipColor() {
      return (
        this.color ||
        (this.status && getColorByStatus(this.status)) ||
        this.defaultColor
      );
    }
  },
  props: {
    color: {
      type: String,
      required: false
    },
    status: {
      type: String,
      required: false
    },
    width: {
      type: String,
      required: false
    },
    shrink: Boolean
  }
};
</script>

<style scoped></style>
