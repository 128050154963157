<template>
  <BaseAction>
    <SecondaryButton @click.native="openDialog" color="primary">
      <v-icon>mdi-lock-open-variant-outline</v-icon>
      Reopen Booking
    </SecondaryButton>
    <ReopenBookingDialog :isOpen="isOpen" @close="closeDialog" />
  </BaseAction>
</template>

<script>
import SecondaryButton from "@/components/common/Button/SecondaryButton";
import ReopenBookingDialog from "@/views/bookings/components/ConfirmDialogs/ReopenBookingDialog";
import BaseAction from "@/components/common/BaseAction";

export default {
  name: "ReopenBookingAction",
  components: {
    BaseAction,
    ReopenBookingDialog,
    SecondaryButton
  },
  data() {
    return {
      isOpen: false
    };
  },
  methods: {
    openDialog() {
      this.isOpen = true;
    },
    closeDialog() {
      this.isOpen = false;
    }
  }
};
</script>
