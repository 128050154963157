<template>
  <Permissioned section="workers" vertical>
    <BaseDetails permission="view.cv">
      <template v-slot:header>
        <h3 class="mt-3 mb-3">CV</h3>
        <v-btn
          class="fullscreen-button mt-2"
          text
          @click="handleViewCV"
          v-if="type === 'application/pdf'"
        >
          <span class="button-content">Full-Screen</span>
          <v-icon>mdi-fullscreen</v-icon>
        </v-btn>
      </template>
      <template v-slot:body>
        <embed
          v-if="type === 'application/pdf'"
          :src="cvUrlWithToken"
          :style="styles"
          type="application/pdf"
        />
        <PDFViewFull :data="fileDialog" v-if="type === 'application/pdf'" />
        <FileComponent
          v-if="file && type !== 'application/pdf'"
          class="py-2"
          :disablePreview="false"
          :file="file"
          :disabled="true"
          customActions
          @show="onShow"
        />
      </template>
    </BaseDetails>
  </Permissioned>
</template>

<script>
import BaseDetails from "@/components/common/BaseDetails/BaseDetails";
import Permissioned from "@/components/common/Permissioned";
import PDFViewFull from "@/components/common/PDFViewFull";
import { getToken } from "@/services/utils";
import FileComponent from "@/components/common/FileComponent.vue";

export default {
  name: "CVPreview",
  components: { FileComponent, Permissioned, BaseDetails, PDFViewFull },
  data() {
    return {
      fileDialog: { dialog: false }
    };
  },
  props: {
    type: String,
    url: String,
    name: String
  },
  computed: {
    cvUrlWithToken() {
      const token = getToken();
      return `${this.url}?token=${token}`;
    },
    styles() {
      return { width: "100%", height: "450px", marginBottom: "15px" };
    },
    file() {
      return {
        name: this.name || "",
        mimeType: this.type,
        url: this.url
      };
    }
  },
  methods: {
    handleViewCV() {
      this.fileDialog.src = this.cvUrlWithToken;
      this.fileDialog.title = "CV";
      this.fileDialog.dialog = true;
    },
    onShow() {
      window.open(this.cvUrlWithToken, "_blank");
    }
  }
};
</script>

<style lang="scss">
.fullscreen-button.v-btn {
  color: $primary-darker;

  .button-content {
    font-weight: bold;
    text-transform: none;
  }

  .v-icon {
    margin-left: 0.25rem;
  }
}
</style>
