<template>
  <ValidationProvider v-slot="{ errors }" :name="validateName" rules="required">
    <v-text-field
      :error-messages="showValidationErrors ? errors : ''"
      v-mask="mask"
      v-model="value"
      @change="handleTimeChange"
      @input="handleEmitInput"
      type="text"
      hide-details
      class="text-time-picker"
      placeholder="00:00"
      :disabled="disabled"
      :readonly="readonly"
    />
  </ValidationProvider>
</template>

<script>
import { mask } from "vue-the-mask";
import moment from "moment";
import { ValidationProvider } from "vee-validate";

const VALID_TIME_REGEX = new RegExp(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/);

export default {
  name: "TextTimePicker",
  directives: {
    mask
  },
  components: {
    ValidationProvider
  },
  data() {
    return {
      mask: "##:##",
      value: this.time
    };
  },
  methods: {
    handleTimeChange(time) {
      if (
        VALID_TIME_REGEX.test(time) ||
        this.externalValidation ||
        time === ""
      ) {
        this.emitChangesWithDelay(time);
      }
    },
    emitChangesWithDelay(time) {
      const formattedTime = time
        ? moment(time, "HH:mm:ss").format("HH:mm")
        : "";
      this.$emit("select", formattedTime);
    },
    handleEmitInput() {
      this.$emit("input");
    }
  },
  watch: {
    time(newValue) {
      this.value = newValue;
    }
  },
  props: {
    externalValidation: Boolean,
    time: {
      type: String,
      required: false
    },
    disabled: Boolean,
    showValidationErrors: Boolean,
    validateName: {
      type: String,
      default: "timeValidate"
    },
    readonly: Boolean
  }
};
</script>

<style lang="scss"></style>
