<template>
  <v-container class="container col-lg-10 col-sm-12">
    <h1 class="pb-3">Role</h1>
    <v-text-field
      placeholder="Type to find booking template"
      v-model="searchText"
      class="text-field__no-label mb-2"
    />
    <div>
      <v-skeleton-loader
        v-if="isLoadingTemplates"
        type="list-item-avatar-three-line"
      />
      <div
        v-else
        v-for="template in filteredTemplates"
        :key="template.id"
        class="job-template-container"
      >
        <JobItem
          class="job-template"
          :loading="selectedTemplate === template"
          @click.native="stepForward(template)"
          :role="template.jobTitle"
          :description="template.description"
        />
      </div>
    </div>
  </v-container>
</template>

<script>
import JobItem from "@/views/bookings/NewBooking/steps/components/JobItem";
import { DATA_NAMESPACE } from "@/store/modules/data";
import { createNamespacedHelpers } from "vuex";
import { FETCH_BOOKING_TEMPLATES } from "@/store/modules/data/actions";
import {
  GET_BOOKING_TEMPLATES,
  GET_IS_FETCHING_DATA
} from "@/store/modules/data/getters";

const { mapActions, mapGetters } = createNamespacedHelpers(DATA_NAMESPACE);

export default {
  name: "RoleStep",
  components: { JobItem },
  data() {
    return {
      searchText: "",
      isLoading: false,
      selectedTemplate: null,
      searchKeys: ["jobTitle", "description"]
    };
  },
  computed: {
    ...mapGetters({
      bookingTemplates: GET_BOOKING_TEMPLATES,
      isFetchingData: GET_IS_FETCHING_DATA
    }),
    filteredTemplates() {
      return this.$search(this.searchText, this.bookingTemplates, {
        keys: this.searchKeys
      });
    },
    isLoadingTemplates() {
      return this.isFetchingData && !this.bookingTemplates.length;
    }
  },
  created() {
    this.fetchBookingTemplates();
  },
  methods: {
    ...mapActions({
      fetchBookingTemplates: FETCH_BOOKING_TEMPLATES
    }),
    stepForward(template) {
      this.selectedTemplate = template;
      this.$emit("nextStep", { template });
    }
  }
};
</script>

<style lang="scss" scoped>
.job-template {
  padding: 10px;
  border-radius: $default-border-radius;
  cursor: pointer;
  &:hover {
    background-color: $background-hover;
  }
  &:active {
    background-color: $background-selected;
  }
}

.job-template-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>
