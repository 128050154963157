import JSONApiService from "@/services/JSONApiService";
import { FILE_MODEL } from "@/models/file-model";
import APIService from "@/services/APIService";
import pluralize from "pluralize";

const reportsApi = ({ commit } = {}) => ({
  getFiles: async () => JSONApiService(commit).findAll(FILE_MODEL),
  getFile: async id => JSONApiService(commit).find(FILE_MODEL, id),
  createFile: async data => JSONApiService(commit).create(FILE_MODEL, data),
  uploadFile: async (id, data) =>
    APIService.client().postFile(`${pluralize(FILE_MODEL)}/${id}/file`, data),
  deleteFile: async id => JSONApiService(commit).destroy(FILE_MODEL, id),
  downloadFile: async id =>
    APIService.client().get(`${pluralize(FILE_MODEL)}/${id}/file`)
});

export default reportsApi;
