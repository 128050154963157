<template>
  <app-layout class="bookings container col-lg-9 col-sm-12">
    <template v-slot:header>
      <BaseListToolbar
        title="Bookings"
        @search="updateSearchValue"
        @sort="onSort"
        :sortingData="sortingData"
      >
        <template v-slot:inline-filters>
          <BookingListFilter
            v-if="!isLoadingBookings"
            @onClearFilters="clearFilters"
            :isLoading="isLoadingBookings"
            :availableFilters="availableFilters"
            :filterLabels="filterLabels"
            :outerSelectedFilters="bookingListFilters"
            @filter="updateFilters"
          />
        </template>
      </BaseListToolbar>
    </template>
    <template v-slot:body>
      <div v-if="isLoadingBookings">
        <v-skeleton-loader
          data-test="loader"
          v-for="i in 10"
          :key="i"
          type="list-item-avatar"
        />
      </div>
      <div v-else>
        <div class="mb-6 flex-gap-15" v-if="isNotEmpty">
          <v-list-item
            v-for="booking in bookings"
            :key="booking.id"
            @click="() => handleSelect(booking)"
          >
            <BookingListItem :booking="booking" data-test="booking" />
          </v-list-item>
          <v-pagination
            :total-visible="page.pageSize"
            v-if="showPagination"
            v-model="page.currentPage"
            @input="loadBookings"
            :length="page.lastPage"
          />
        </div>
        <EmptyStates
          v-else
          data-test="empty-list"
          icon="roles-empty-state"
          :description="emptyStateDescription"
          :isFiltered="isFiltered"
        />
        <BaseFooter v-if="isPermissioned('bookings.create')">
          <div class="actions-bar">
            <NewBookingAction />
          </div>
          <div class="actions-bar" style="display: none">
            <BulkBookingAction
              @success="loadBookings"
              v-if="isPermissioned('bookings.create.bulk')"
            />
          </div>
        </BaseFooter>
      </div>
    </template>
  </app-layout>
</template>

<script>
import appLayout from "@/components/common/base-layouts/AppLayout";
import {
  sortTableItems,
  setGlobalClientIdFilter,
  getClientIdFilter,
  setClientIdFilter
} from "@/utils/helpers";
import BaseFooter from "@/components/common/BaseFooter";
import BaseListToolbar from "@/components/common/Toolbar/BaseListToolbar";
import NewBookingAction from "@/views/bookings/NewBooking/actions/NewBookingAction";
import {
  BOOKINGS_NAMESPACE,
  FETCH_BOOKINGS
} from "@/store/modules/bookings/actions";
import { createNamespacedHelpers } from "vuex";
import {
  GET_BOOKINGS,
  GET_BOOKING_LIST_FILTERS,
  IS_LOADING_BOOKINGS
} from "@/store/modules/bookings/getters";
import BookingListFilter from "@/views/bookings/BookingListFilter";
import {
  SET_CURRENT_BOOKING,
  SET_BOOKING_LIST_FILTERS
} from "@/store/modules/bookings/mutations";
import { size } from "lodash";
import { isPermissioned } from "@/utils/permissions";
import BookingListItem from "@/views/bookings/components/BookingListItem";
import { SORTING_DATA } from "@/constants/bookings";
import EmptyStates from "@/components/common/EmptyStates";
import { paginationMixin } from "@/mixins/pagination.mixin";
import { getLinkParams } from "@/router/utils";
import BulkBookingAction from "@/views/bookings/NewBooking/actions/BulkBookingAction";

const { mapActions, mapGetters, mapMutations } = createNamespacedHelpers(
  BOOKINGS_NAMESPACE
);

export default {
  name: "BookingList",
  mixins: [paginationMixin],
  components: {
    appLayout,
    NewBookingAction,
    BookingListItem,
    BaseListToolbar,
    BookingListFilter,
    BaseFooter,
    EmptyStates,
    BulkBookingAction
  },
  async created() {
    const { status } = getLinkParams(this.$route.params);
    await this.loadBookings();
    this.adjustInitialFilters({ status });
  },
  data: () => ({
    sortingData: SORTING_DATA,
    search: "",
    availableFilters: {},
    filterLabels: {},
    sort: ""
  }),
  computed: {
    ...mapGetters({
      bookings: GET_BOOKINGS,
      bookingListFilters: GET_BOOKING_LIST_FILTERS,
      isLoadingBookings: IS_LOADING_BOOKINGS
    }),
    totalVisible() {
      return this.$vuetify.breakpoint.smAndDown ? 4 : 7;
    },
    requestParams() {
      return {
        filter: {
          ...this.bookingListFilters,
          ...(size(this.search.trim()) && { search: this.search })
        },
        ...this.requestPageParams,
        ...(this.sort && { sort: this.sort })
      };
    },
    isNotEmpty() {
      return size(this.bookings);
    },
    isFiltered() {
      return Boolean(size(this.bookingListFilters) || size(this.search));
    },
    emptyStateDescription() {
      return this.isFiltered
        ? "No bookings match your search criteria"
        : "No bookings have been created";
    }
  },
  methods: {
    ...mapActions({
      fetchBookings: FETCH_BOOKINGS
    }),
    ...mapMutations({
      setCurrentBooking: SET_CURRENT_BOOKING,
      setBookingListFilters: SET_BOOKING_LIST_FILTERS
    }),
    async loadBookings() {
      const data = await this.fetchBookings(this.requestParams);
      if (data) {
        this.adjustPageDetails(data.meta);
      }
    },
    adjustPageDetails({ page, filters, labels }) {
      this.updatePageDetails({ page });
      this.availableFilters = filters;
      this.filterLabels = labels;
    },
    adjustInitialFilters({ status }) {
      const clientIdFilters = getClientIdFilter();
      const root_client_id = setClientIdFilter(
        this.availableFilters["root_client_id"],
        clientIdFilters
      );
      if (root_client_id) {
        this.setBookingListFilters({
          ...this.bookingListFilters,
          ...(status && { status }),
          root_client_id
        });
        this.loadBookings();
      }
    },
    updateSearchValue(text) {
      this.search = text;
      this.loadBookings();
    },
    onSort(text) {
      this.sort = text;
      this.loadBookings();
    },
    customSort(items, arr, isDescArray) {
      const options = { dateValuesLabels: ["date"] };
      return sortTableItems(items, arr, isDescArray, options);
    },
    handleSelect({ id }) {
      const selectedBooking = this.bookings.find(booking => booking.id === id);

      this.setCurrentBooking(selectedBooking);
      this.$router.push({
        name: "bookingDetails",
        params: { id }
      });
    },
    updateFilters(filters) {
      setGlobalClientIdFilter(filters);
      this.setBookingListFilters(filters);
      this.resetPagination();
      this.loadBookings();
    },
    clearFilters() {
      this.setBookingListFilters({});
      this.resetPagination();
      this.loadBookings();
    },
    isPermissioned
  }
};
</script>

<style lang="scss">
.bookings {
  position: relative;

  .container {
    padding-top: 0;
  }

  tr {
    cursor: pointer;
  }

  @media only screen and (max-width: $tablet-breakpoint - 1px) {
    .desktop-button {
      display: none;
    }
  }
  .list-item .v-list-item__content {
    width: 100%;
  }
  .list-item {
    .v-icon {
      font-size: 18px;
      vertical-align: baseline;
      color: $primary-text;
    }
  }
  .list-item .v-avatar.v-list-item__avatar {
    background-color: #e3f2fe;
  }
}
</style>
