<template>
  <ValidationObserver ref="basicDetails">
    <div class="container mt-5">
      <h2>Basic Details</h2>
      <div class="mt-5 flex-gap-20">
        <Permissioned section="settings" class="flex-column">
          <ValidationProvider
            v-slot="{ errors }"
            rules="required"
            permission="edit-booking-templates.client"
          >
            <Select
              :errorMessages="getErrorMessages(errors)"
              :disabled="isLoadingOrganisations"
              :loading="isLoadingOrganisations"
              v-model="bookingTemplateData.client.id"
              label="Client"
              item-text="name"
              item-value="id"
              :items="organisations"
              outlined
              dense
            />
          </ValidationProvider>
        </Permissioned>
        <ValidationProvider v-slot="{ errors }" rules="required|max:250">
          <v-text-field
            :error-messages="getErrorMessages(errors)"
            label="Booking name"
            v-model="bookingTemplateData.jobTitle"
          />
        </ValidationProvider>
        <div class="flex">
          <v-label>Description</v-label>
          <v-textarea
            v-model="bookingTemplateData.description"
            outlined
            dense
            class="mt-2"
          />
        </div>
        <Select
          v-model="bookingTemplateData.otherRequirements"
          :disabled="isLoadingOtherRequirements"
          :loading="isLoadingOtherRequirements"
          autocomplete
          multiple
          label="Non-Compliance Requirements"
          :items="formattedOtherRequirements"
          outlined
          dense
        />
        <Select
          v-model="bookingTemplateData.publishingScheduleTags"
          autocomplete
          multiple
          label="Publishing Schedule Tags"
          :items="tags"
          :disabled="isFetchingPublishingScheduleTags"
          :loading="isFetchingPublishingScheduleTags"
          outlined
          dense
        />
        <div>
          <v-label>Compliance Requirements</v-label>
          <div v-if="isLoadingComplianceRequirements">
            <v-skeleton-loader v-for="i in 10" :key="i" type="list-item" />
          </div>
          <Checkbox
            mixed
            isLarge
            v-else
            :selected-values="bookingTemplateData.complianceRequirements"
            :options="formattedComplianceRequirements"
            @change="val => (bookingTemplateData.complianceRequirements = val)"
          />
        </div>
        <Select
          label="Years of references required (gaps of no more than 6 weeks)"
          :items="yearsReferencesOptions"
          :value="bookingTemplateData.yearsReferencesRequired"
          @change="
            value => (bookingTemplateData.yearsReferencesRequired = value || 0)
          "
          outlined
          dense
          clearable
        />
        <ValidationProvider v-slot="{ errors }" rules="required">
          <Select
            v-model="bookingTemplateData.timeUnitName"
            label="Time Unit"
            item-value="name"
            item-text="label"
            :items="formattedTimeUnits"
            :loading="isFetchingTimeUnits"
            :errorMessages="getErrorMessages(errors)"
            outlined
            dense
          />
        </ValidationProvider>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import { isPermissioned } from "@/utils/permissions";
import Select from "@/components/common/Select";
import Checkbox from "@/components/common/Checkbox";
import Permissioned from "@/components/common/Permissioned";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { FETCH_ORGANISATIONS } from "@/store/modules/organisations/actions";
import { ORGANISATIONS_NAMESPACE } from "@/store/modules/organisations";
import {
  GET_IS_FETCHING_ORGANISATIONS,
  GET_ORGANISATIONS
} from "@/store/modules/organisations/getters";
import { map, get, merge, omit, capitalize } from "lodash";
import { createNamespacedHelpers } from "vuex";
import { copyState } from "@/utils/helpers";
import { DATA_NAMESPACE } from "@/store/modules/data";
import { AUTH_NAMESPACE } from "@/store/modules/auth";
import {
  FETCH_COMPLIANCE_REQUIREMENTS,
  FETCH_OTHER_REQUIREMENTS,
  FETCH_PUBLISHING_SCHEDULE_TAGS,
  FETCH_TIME_UNITS
} from "@/store/modules/data/actions";
import {
  GET_OTHER_REQUIREMENTS,
  GET_COMPLIANCE_REQUIREMENTS,
  GET_IS_FETCHING_OTHER_REQUIREMENTS,
  GET_IS_FETCHING_COMPLIANCE_REQUIREMENTS,
  GET_PUBLISHING_SCHEDULE_TAGS,
  GET_IS_FETCHING_PUBLISHING_SCHEDULE_TAGS
} from "@/store/modules/data/getters";

const {
  mapActions: mapOrganisationActions,
  mapGetters: mapOrganisationGetters
} = createNamespacedHelpers(ORGANISATIONS_NAMESPACE);

const { mapActions, mapGetters, mapState } = createNamespacedHelpers(
  DATA_NAMESPACE
);
const { mapState: mapAuthState } = createNamespacedHelpers(AUTH_NAMESPACE);

const YEARS_REFERENCES_OPTIONS = [1, 2, 3, 4, 5];

export default {
  name: "BasicBookingTemplateDetails",
  components: {
    Checkbox,
    Select,
    ValidationProvider,
    ValidationObserver,
    Permissioned
  },
  props: {
    bookingTemplate: Object
  },
  data() {
    return {
      bookingTemplateData: {
        client: {
          id: ""
        },
        jobTitle: "",
        description: "",
        otherRequirements: [],
        publishingScheduleTags: [],
        complianceRequirements: [],
        yearsReferencesRequired: 0,
        timeUnitName: ""
      },
      yearsReferencesOptions: YEARS_REFERENCES_OPTIONS,
      showValidationErrors: false
    };
  },
  computed: {
    ...mapAuthState(copyState(["userData"])),
    ...mapState({
      timeUnits: state => state.timeUnits,
      isFetchingTimeUnits: state => state.isFetchingTimeUnits
    }),
    ...mapGetters({
      isLoadingComplianceRequirements: GET_IS_FETCHING_COMPLIANCE_REQUIREMENTS,
      isLoadingOtherRequirements: GET_IS_FETCHING_OTHER_REQUIREMENTS,
      complianceRequirements: GET_COMPLIANCE_REQUIREMENTS,
      otherRequirements: GET_OTHER_REQUIREMENTS,
      tags: GET_PUBLISHING_SCHEDULE_TAGS,
      isFetchingPublishingScheduleTags: GET_IS_FETCHING_PUBLISHING_SCHEDULE_TAGS
    }),
    selfOrganisationId() {
      return get(this.userData, "organisation.id");
    },
    ...mapOrganisationGetters({
      organisations: GET_ORGANISATIONS,
      isLoadingOrganisations: GET_IS_FETCHING_ORGANISATIONS
    }),
    formattedComplianceRequirements() {
      return map(this.complianceRequirements, ({ name, id }) => ({
        value: id,
        label: name
      }));
    },
    formattedOtherRequirements() {
      return map(this.otherRequirements, ({ name }) => name);
    },
    formattedTimeUnits() {
      return map(this.timeUnits, ({ name }) => ({
        label: capitalize(name),
        name
      }));
    }
  },
  created() {
    if (this.bookingTemplate) {
      this.adjustBookingTemplateData();
    }
    this.fetchComplianceRequirements();
    this.fetchOtherRequirements();
    this.fetchPublishingSchedulesTags();
    this.fetchTimeUnits();
    this.adjustClientData();
  },
  methods: {
    ...mapActions({
      fetchComplianceRequirements: FETCH_COMPLIANCE_REQUIREMENTS,
      fetchOtherRequirements: FETCH_OTHER_REQUIREMENTS,
      fetchPublishingSchedulesTags: FETCH_PUBLISHING_SCHEDULE_TAGS,
      fetchTimeUnits: FETCH_TIME_UNITS
    }),
    ...mapOrganisationActions({
      fetchOrganisations: FETCH_ORGANISATIONS
    }),
    adjustBookingTemplateData() {
      this.bookingTemplateData = merge({}, this.bookingTemplate);
      this.bookingTemplateData = omit(this.bookingTemplateData, [
        "templatePayRates",
        "templateCosts"
      ]);
    },
    adjustClientData() {
      if (isPermissioned("settings.edit-booking-templates.client")) {
        this.fetchOrganisations({
          filter: {
            organisation_type: "client",
            is_root: true,
            is_live: true
          }
        });
      } else {
        this.bookingTemplateData.client.id = this.selfOrganisationId;
      }
    },
    close() {
      this.$emit("close");
    },
    async validateForm() {
      this.showValidationErrors = true;
      const isValid = await this.$refs.basicDetails.validate();
      if (isValid) {
        this.$emit("onSubmit", this.bookingTemplateData);
      }
      return isValid;
    },
    getErrorMessages(errors) {
      return this.showValidationErrors ? errors : "";
    }
  }
};
</script>

<style lang="scss"></style>
