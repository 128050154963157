<template>
  <DialogFull
    :steps="{ current: step, total: totalSteps }"
    :data="dialogSettings"
    v-if="dialogSettings.dialog"
    progress
    @onStepBack="stepBack"
    @close="close"
  >
    <template v-slot:dialog.body>
      <BasicDetails
        ref="basicDetails"
        @onSubmit="stepForward"
        v-show="shouldStepViewBeShown(1)"
      />
      <BookingCharges
        ref="bookingCharges"
        @onSubmit="onSubmit"
        v-if="shouldStepViewBeShown(2) || ifStepHasAlreadyBeenCompleted(1)"
        v-show="shouldStepViewBeShown(2)"
      />
    </template>
    <template v-slot:dialog.action>
      <PrimaryButton
        :loading="isLoading"
        @click.native="validateStep"
        v-if="step < totalSteps"
      >
        Continue
      </PrimaryButton>
      <PrimaryButton
        @click.native="validateBookingCharges"
        :loading="isAddingBookingTemplate"
        v-else
      >
        Create Template
      </PrimaryButton>
    </template>
  </DialogFull>
</template>

<script>
import DialogFull from "@/components/common/DialogFull";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import { createNamespacedHelpers } from "vuex";
import { ORGANISATION_BOOKING_TEMPLATES_NAMESPACE } from "@/store/modules/organisations/organisation-booking-templates";
import { copyState } from "@/utils/helpers";
import { ADD_ORGANISATION_BOOKING_TEMPLATE } from "@/store/modules/organisations/organisation-booking-templates/actions";
import BasicDetails from "@/views/settings/Organisation/BookingTemplates/components/BasicDetails";
import BookingCharges from "@/views/settings/Organisation/BookingTemplates/components/BookingCharges";

const { mapActions, mapState } = createNamespacedHelpers(
  ORGANISATION_BOOKING_TEMPLATES_NAMESPACE
);
const MAX_STEP = 2;

export default {
  name: "NewBookingTemplate",
  components: {
    PrimaryButton,
    DialogFull,
    BasicDetails,
    BookingCharges
  },
  data() {
    return {
      step: 1,
      savedStep: 0,
      totalSteps: MAX_STEP,
      bookingTemplateData: {},
      isLoading: false,
      shouldValidate: false
    };
  },
  computed: {
    ...mapState(copyState(["isAddingBookingTemplate"])),
    dialogSettings() {
      return {
        dialog: this.isOpen,
        title: "New Booking Template"
      };
    }
  },
  methods: {
    ...mapActions({
      addBookingTemplate: ADD_ORGANISATION_BOOKING_TEMPLATE
    }),
    stepBack() {
      if (this.step > 1) {
        this.step -= 1;
      }
    },
    updateData(bookingTemplateData) {
      this.bookingTemplateData = {
        ...this.bookingTemplateData,
        ...bookingTemplateData
      };
    },
    validateStep() {
      this.$refs.basicDetails.validateForm();
    },
    validateBookingCharges() {
      this.$refs.bookingCharges.validateForm();
    },
    stepForward(bookingTemplateData) {
      this.updateData(bookingTemplateData);
      this.step += 1;
      this.savedStep = 1;
    },
    async onSubmit(bookingTemplateData) {
      this.updateData(bookingTemplateData);
      const { data } = await this.addBookingTemplate(this.bookingTemplateData);
      this.close();
      this.$router.push({
        name: "bookingTemplateDetails",
        params: { id: data.id }
      });
    },
    close() {
      this.$emit("close");
    },
    shouldStepViewBeShown(viewStep) {
      return this.step === viewStep;
    },
    ifStepHasAlreadyBeenCompleted(viewStep) {
      return viewStep <= this.savedStep;
    }
  },
  props: {
    isOpen: Boolean
  }
};
</script>

<style lang="scss"></style>
