export const SET_IS_LOADING_SELF_DETAILS = "SET_IS_LOADING_SELF_DETAILS";
export const SET_IS_EDITING_DETAILS = "SET_IS_EDITING_DETAILS";
export const SET_ERROR = "SET_AUTHENTICATING_ERROR";
export const SET_USER_DATA = "SET_USER_DATA";
export const SET_USERS_TO_IMPERSONATE = "SET_USERS_TO_IMPERSONATE";
export const SET_FETCHING_USERS = "SET_FETCHING_USERS";
export const SET_PERMISSIONS = "SET_PERMISSIONS";
export const SET_MODULE_PERMISSIONS_AS_CURRENT =
  "SET_MODULE_PERMISSIONS_AS_CURRENT";
export const SET_SELECTED_PROFILE = "SET_SELECTED_PROFILE";
export const SET_PROFILES = "SET_PROFILES";
export const SET_APP_LOGO = "SET_APP_LOGO";
export const SET_API_ERROR = "SET_API_ERROR";

// external mutation types
export const NS_SET_PERMISSIONS = "auth/SET_PERMISSIONS";
export const NS_SET_API_ERROR = "auth/SET_API_ERROR";
