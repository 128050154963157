<template>
  <DialogFull
    :data="dialogSettings"
    progress
    :steps="steps"
    @onStepBack="stepBack"
    @close="handleClose"
  >
    <template v-slot:dialog.body>
      <div v-show="steps.current === 1">
        <div class="container new-report">
          <h2>Focus of report</h2>
          <v-progress-circular
            indeterminate
            size="64"
            color="primary"
            v-if="isCreating"
          />
          <ul class="new-report-list" v-else>
            <li
              v-for="focus in reportFocuses"
              :key="focus.key"
              class="new-report-item"
            >
              <SecondaryButton
                fullWidth
                @click="selectFocus(focus.key)"
                class="new-report-button"
              >
                <div class="d-flex flex-column align-center">
                  <inline-svg
                    class="mb-2"
                    width="45"
                    height="45"
                    :src="focus.icon"
                  />
                  {{ focus.name }}
                </div>
              </SecondaryButton>
            </li>
          </ul>
        </div>
      </div>
      <ReportEditor
        v-if="steps.current === 2"
        ref="reportFields"
        :isLoading="isCreating"
        :report="reportData"
        :showValidationErrors="showValidationErrors"
        @updated="value => (updatedReportData = value)"
      />
    </template>
    <template v-if="steps.current === 2" v-slot:dialog.action>
      <PrimaryButton :loading="isSaving" @click.native="saveReport">
        Create Report
      </PrimaryButton>
    </template>
  </DialogFull>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { REPORTS_NAMESPACE } from "@/store/modules/reports";
import DialogFull from "@/components/common/DialogFull";
import SecondaryButton from "@/components/common/Button/SecondaryButton";
import { REPORT_FOCUSES } from "@/constants/reports";
import {
  CREATE_REPORT,
  UPDATE_REPORT,
  DELETE_REPORT,
  FETCH_REPORTS
} from "@/store/modules/reports/action-types";
import InlineSvg from "vue-inline-svg";
import ReportEditor from "@/views/reports/forms/ReportEditor";
import PrimaryButton from "@/components/common/Button/PrimaryButton";

const { mapActions } = createNamespacedHelpers(REPORTS_NAMESPACE);
export default {
  title: "New Report - Track",
  components: {
    DialogFull,
    SecondaryButton,
    InlineSvg,
    ReportEditor,
    PrimaryButton
  },
  props: {
    open: Boolean
  },
  data() {
    return {
      steps: { current: 1, total: 2 },
      reportData: {
        name: "",
        meta: { fields: [] },
        filters: [],
        fields: [],
        groupings: [],
        focus: ""
      },
      isCreating: false,
      updatedReportData: {},
      isSaving: false,
      showValidationErrors: false
    };
  },
  computed: {
    dialogSettings() {
      return {
        dialog: this.open,
        title: "New Report"
      };
    },
    reportFocuses: () => REPORT_FOCUSES
  },
  methods: {
    ...mapActions({
      createReport: CREATE_REPORT,
      deleteReport: DELETE_REPORT,
      updateReport: UPDATE_REPORT,
      fetchReports: FETCH_REPORTS
    }),
    cancelReport() {
      if (this.reportData.id) {
        this.deleteReport(this.reportData.id);
      }
    },
    stepBack() {
      if (this.steps.current > 1) {
        this.steps.current -= 1;
        this.cancelReport();
      }
    },
    handleClose() {
      if (this.steps.current > 1) {
        this.cancelReport();
      }
      this.fetchReports();
      this.$emit("close");
    },
    async selectFocus(focus) {
      this.steps.current++;
      this.isCreating = true;
      const { data } = await this.createReport(focus);
      this.reportData = data;
      this.isCreating = false;
    },
    async saveReport() {
      this.isSaving = true;
      this.showValidationErrors = true;
      this.updatedReportData.id = this.reportData.id;
      const validate = await this.$refs.reportFields.validateFields();
      if (validate) {
        await this.updateReport({
          previousReport: this.reportData,
          newReportData: this.updatedReportData
        });
        this.fetchReports();
        this.showValidationErrors = false;
        this.$emit("close");
        this.$router.push(`/reports/${this.reportData.id}`);
      }
      this.isSaving = false;
    }
  }
};
</script>

<style scoped lang="scss">
.new-report {
  display: flex;
  margin-bottom: 10px;
  flex-direction: column;
  .new-report-title {
    margin-bottom: 32px;
  }
  .new-report-list {
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    padding-left: 0;
    .new-report-item {
      min-width: 240px;
      &:not(:last-child) {
        margin-right: 16px;
      }
      margin-bottom: 16px;
    }
    .new-report-button {
      padding: 32px 24px !important;
      @media only screen and (max-width: $mobile-breakpoint - 1) {
        padding: 20px 24px !important;
      }
    }
    @media only screen and (max-width: $mobile-breakpoint - 1) {
      justify-content: center;
      .new-report-item:not(:last-child) {
        margin-right: 0;
      }
    }
  }
  h2 {
    margin: 20px 0 20px !important;
    @media only screen and (max-width: $mobile-breakpoint - 1) {
      text-align: center;
    }
  }
}
</style>
