<template>
  <BaseAction>
    <ErrorButton shrink light @click.native="openDeleteAgencyFromTierDialog"
      >Remove</ErrorButton
    >
    <DeleteAgencyFromTierDialog
      :isOpen="isDialogOpen"
      :agency="agency"
      :tierId="tierId"
      @close="closeDeleteAgencyFromTierDialog"
    />
  </BaseAction>
</template>

<script>
import DeleteAgencyFromTierDialog from "@/views/settings/Organisation/PublishingSchedules/dialogs/DeleteAgencyFromTierDialog";
import ErrorButton from "@/components/common/Button/ErrorButton";
import BaseAction from "@/components/common/BaseAction";

export default {
  name: "DeleteAgencyFromTierAction",
  props: {
    agency: Object,
    tierId: String
  },
  components: { BaseAction, ErrorButton, DeleteAgencyFromTierDialog },
  data() {
    return {
      isDialogOpen: false
    };
  },
  methods: {
    openDeleteAgencyFromTierDialog() {
      this.isDialogOpen = true;
      this.$emit("open");
    },
    closeDeleteAgencyFromTierDialog() {
      this.isDialogOpen = false;
      this.$emit("close");
    }
  }
};
</script>
