/* eslint-disable prettier/prettier */
import { setStoreValue } from '@/store/utils';

export const SET_FILES = "SET_FILES";
export const SET_IS_FETCHING_FILES = "SET_IS_FETCHING_FILES";
export const SET_FILE = "SET_FILE";
export const SET_IS_FETCHING_FILE = "SET_IS_FETCHING_FILE";

const filesMutations = {
  [SET_FILES]: setStoreValue("files"),
  [SET_IS_FETCHING_FILES]: setStoreValue("isFetchingFiles"),
  [SET_FILE]: setStoreValue("file"),
  [SET_IS_FETCHING_FILE]: setStoreValue("isFetchingFile")
};

export default filesMutations;
