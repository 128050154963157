<template>
  <div
    @click="handleSelect"
    class="d-flex align-end timesheet-time-details clickable py-2 px-2"
  >
    <inline-svg width="20" :src="require('@/assets/svg/user-icon.svg')" />
    <span class="ml-2 font-weight-bold">
      {{ worker | fullName }}
    </span>
    <span class="ml-1" v-if="activityName">| {{ activityName }}</span>
    <span class="ml-1 secondary-text" v-if="actualTimeRange">
      | {{ actualTimeRange }}
    </span>
    <div
      v-if="showDiscrepancy && timeDiscrepancy !== 0"
      class="ml-1 discrepancy font-weight-bold"
      :class="{ 'text-green': timeDiscrepancy < 0 }"
    >
      {{ discrepancyText }}
    </div>
  </div>
</template>

<script>
import { includes } from "lodash";
import { isPermissioned } from "@/utils/permissions";
import { isWorker } from "@/utils/users";
import { getTimeRange, getMinutesAsHrsAndMinsTime } from "@/utils/time";
import InlineSvg from "vue-inline-svg";
import pluralize from "pluralize";
import { AUTH_NAMESPACE } from "@/store/modules/auth";
import { createNamespacedHelpers } from "vuex";

export const EDITABLE_STATUSES = ["draft", "disputed"];
const { mapState } = createNamespacedHelpers(AUTH_NAMESPACE);

export default {
  props: {
    worker: {
      type: Object,
      required: true
    },
    showDiscrepancy: Boolean,
    status: String
  },

  components: { InlineSvg },
  computed: {
    ...mapState({
      user: state => state.userData
    }),
    activityName() {
      return this.worker.activityTypeName;
    },
    actualTimeRange() {
      return getTimeRange({
        startTime: this.worker.startTime,
        endTime: this.worker.endTime
      });
    },
    timeDiscrepancy() {
      return this.worker.totalTime - this.worker.expectedTotalTime;
    },
    discrepancyText() {
      const prefix = this.timeDiscrepancy > 0 ? "+" : "";
      return `(${prefix}${getMinutesAsHrsAndMinsTime(this.timeDiscrepancy)})`;
    }
  },
  methods: {
    handleSelect() {
      const id = String(this.worker.timesheetId);
      const showSubmitTimesheetView =
        includes(EDITABLE_STATUSES, this.status) &&
        isPermissioned(`timesheets.edit`) &&
        isWorker(this.user.roles);
      this.$router.push({
        name: showSubmitTimesheetView ? "submitTimesheet" : "timesheetDetails",
        params: { id }
      });
    },
    pluralize
  }
};
</script>
