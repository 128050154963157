export const ROLES = [
  {
    value: "agency user",
    label: "Agency User",
    permission: "agency-user",
    hasOnlyRootOrganisation: true
  },
  {
    value: "agency finance",
    label: "Agency Finance",
    permission: "agency-finance",
    hasOnlyRootOrganisation: true
  },
  {
    value: "agency admin",
    label: "Agency Administrator",
    permission: "agency-administrator",
    hasOnlyRootOrganisation: true
  },
  {
    value: "client overseer",
    label: "Overseer",
    permission: "overseer",
    hasOnlyRootOrganisation: false
  },
  {
    value: "client admin",
    label: "Client Administrator",
    permission: "administrator",
    hasOnlyRootOrganisation: true
  },
  {
    value: "client hiring manager",
    label: "Hiring Manager",
    permission: "hiring-manager",
    hasOnlyRootOrganisation: false
  },
  {
    value: "client timesheet approver",
    label: "Timesheet Approver",
    permission: "timesheet-approver",
    hasOnlyRootOrganisation: false
  },
  {
    value: "client timesheet verifier",
    label: "Timesheet Verifier",
    permission: "timesheet-verifier",
    hasOnlyRootOrganisation: false
  },
  {
    value: "msp admin",
    label: "MSP Administrator",
    permission: "msp-administrator",
    hasOnlyRootOrganisation: false
  },
  {
    value: "msp finance",
    label: "MSP Finance",
    permission: "msp-finance",
    hasOnlyRootOrganisation: false
  },
  {
    value: "msp user",
    label: "MSP User",
    permission: "msp-user",
    hasOnlyRootOrganisation: false
  }
];
