export const PAY_RATE_ITEM = "pay-rate-item";

const payRateItemModel = {
  amount: 0,
  awr: 0,
  costName: "",
  costDisplayOrder: 0,
  costRate: "",
  createdAt: "",
  currencyCode: "",
  isDefault: 0,
  payRateName: "",
  updatedAt: ""
};

export default payRateItemModel;
