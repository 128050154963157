<template>
  <BaseAction>
    <SecondaryButton @click.native="openDialog" color="primary">
      <v-img class="mb-1" height="30px" contain :src="icon" />
      Approve
    </SecondaryButton>
    <ApproveTimesheetDialog
      :isOpen="isDialogOpen"
      @close="closeDialog"
      @approved="approved"
      :timesheetsIds="timesheetsIds"
    />
  </BaseAction>
</template>

<script>
import ApproveTimesheetDialog from "@/views/timesheets/components/dialogs/ApproveTimesheetDialog";
import SecondaryButton from "@/components/common/Button/SecondaryButton";
import BaseAction from "@/components/common/BaseAction";

export default {
  name: "ApproveTimesheetAction",
  props: {
    timesheetsIds: {
      type: Array,
      required: true
    }
  },
  components: {
    SecondaryButton,
    ApproveTimesheetDialog,
    BaseAction
  },
  data() {
    return {
      isDialogOpen: false
    };
  },
  computed: {
    icon() {
      return require(`@/assets/svg/approve.svg`);
    }
  },
  methods: {
    openDialog() {
      this.isDialogOpen = true;
    },
    closeDialog() {
      this.isDialogOpen = false;
    },
    approved() {
      this.$emit("onUpdate");
      this.closeDialog();
    }
  }
};
</script>
