<template>
  <div class="impersonate">
    <v-system-bar
      v-if="isImpersonating"
      color="black"
      class="v-system-bar--default"
      :height="50"
      app
    >
      <div class="v-system-bar__description">
        You are impersonating <strong>{{ impersonateUser | getName }}</strong>
      </div>
      <v-spacer />
      <v-btn
        small
        class="base-system-btn white--text"
        v-if="!this.$vuetify.breakpoint.smAndDown"
        @click="openImpersonateDialog"
      >
        Switch Session
      </v-btn>
      <v-btn
        small
        class="end-session-btn white--text"
        @click="handleEndSession"
      >
        End Session
      </v-btn>
    </v-system-bar>
    <!-- New Worker Dialog -->
    <DialogFull :data="impersonateDialog" v-if="impersonateDialog.dialog">
      <template v-slot:dialog.body>
        <SelectImpersonateUser @onChange="handleImpersonateChange" />
      </template>
      <template v-slot:dialog.action>
        <v-btn color="primary" @click="handleBeginSession">
          Begin Session
        </v-btn>
      </template>
    </DialogFull>
  </div>
</template>

<script>
import {
  NS_TOGGLE_IMPERSONATE_DIALOG,
  NS_UPDATE_IMPERSONATE_USER
} from "@/store/modules/layout/mutation-types";
import DialogFull from "@/components/common/DialogFull";
import SelectImpersonateUser from "@/components/impersonate/SelectImpersonateUser";
import { mapActions, mapState } from "vuex";
import { FETCH_SELF_DETAILS } from "@/store/modules/auth/action-types";

export default {
  components: {
    DialogFull,
    SelectImpersonateUser
  },
  data() {
    return {
      impersonateUser: JSON.parse(localStorage.getItem("impersonateUser"))
    };
  },
  computed: {
    ...mapState("layout", {
      impersonateDialog: state => state.impersonateDialog,
      isImpersonating: state => state.isImpersonating
    })
  },
  methods: {
    ...mapActions("auth", {
      fetchSelf: FETCH_SELF_DETAILS
    }),
    openImpersonateDialog() {
      this.handleEndSession();
      this.$store.commit(NS_TOGGLE_IMPERSONATE_DIALOG, true);
    },
    handleImpersonateChange(user) {
      this.impersonateUser = user;
    },
    handleBeginSession() {
      this.$store.commit(NS_TOGGLE_IMPERSONATE_DIALOG, false);
      this.$store.commit(NS_UPDATE_IMPERSONATE_USER, {
        user: this.impersonateUser
      });
      window.location.reload();
    },
    handleEndSession() {
      this.$store.commit(NS_UPDATE_IMPERSONATE_USER, {
        user: { id: null }
      });
      this.getUserPermissions();
    },
    getUserPermissions() {
      this.fetchSelf();
      this.$forceUpdate();
    }
  },
  filters: {
    getName(value) {
      return value ? value.name : "";
    }
  }
};
</script>

<style lang="scss"></style>
