export const USER_EXPORT_MODEL = "user-report";

const userExportModel = {
  id: 0,
  "report-title": "",
  "report-filters": "",
  "report-generated-at": "",
  "report-expires-at": "",
  "report-status": ""
};

export default userExportModel;
