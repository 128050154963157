<template>
  <BaseDialog :isOpen="isOpen">
    <template v-slot:title>
      <span>Are you sure you want to approve a timesheet?</span>
    </template>
    <template v-slot:body>
      <div class="approve-timesheet-description">
        Approving this timesheet will result in the worker being paid and for
        your organisation to be invoiced for the total cost of the timesheet.
      </div>
    </template>
    <template v-slot:actions>
      <div class="approve-timesheet-actions">
        <PrimaryButton @click.native="cancel" text>
          Cancel
        </PrimaryButton>
        <PrimaryButton @click.native="handleClick" :loading="isLoading">
          Approve Timesheet
        </PrimaryButton>
      </div>
    </template>
  </BaseDialog>
</template>

<script>
import BaseDialog from "@/components/common/BaseDialog";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import {
  GET_CURRENT_TIMESHEET,
  APPROVE_TIMESHEET
} from "@/store/modules/timesheets/actions";
import { createNamespacedHelpers } from "vuex";
import { TIMESHEETS_NAMESPACE } from "@/store/modules/timesheets";
import { map, size } from "lodash";

const { mapActions } = createNamespacedHelpers(TIMESHEETS_NAMESPACE);

export default {
  name: "ApproveTimesheetDialog",
  components: { PrimaryButton, BaseDialog },
  data() {
    return {
      isLoading: false
    };
  },
  props: {
    isOpen: Boolean,
    timesheetsIds: {
      type: Array,
      required: true
    }
  },
  methods: {
    ...mapActions({
      getCurrentTimesheet: GET_CURRENT_TIMESHEET,
      approveTimesheet: APPROVE_TIMESHEET
    }),
    async handleClick() {
      this.isLoading = true;
      try {
        const requests = map(this.timesheetsIds, async timesheetId => {
          const approved = await this.approveTimesheet(timesheetId);
          size(this.timesheetsIds) === 1 &&
            this.getCurrentTimesheet(timesheetId);
          return approved;
        });
        await Promise.all(requests);
        this.$emit("approved");
      } catch {
        this.cancel();
      } finally {
        this.isLoading = false;
      }
    },
    cancel() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss">
.approve-timesheet-description {
  min-height: 100px;
}
.approve-timesheet-actions {
  padding: 0.5rem;
}
</style>
