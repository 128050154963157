import { ORGANISATION_MODEL } from "@/models/organisation-model";
import { SCHEDULE_TIER_MODEL } from "@/models/schedule-tier-model";

export const PUBLISHING_SCHEDULE_TEMPLATE_MODEL =
  "publishing-schedule-template";

const publishingScheduleModel = {
  name: "",
  tags: [],
  organisations: {
    jsonApi: "hasMany",
    type: ORGANISATION_MODEL
  },
  tiers: {
    jsonApi: "hasMany",
    type: SCHEDULE_TIER_MODEL
  }
};

export default publishingScheduleModel;
