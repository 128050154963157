export const paginationMixin = {
  data() {
    return {
      page: {
        pageSize: 15,
        currentPage: 1,
        lastPage: 1
      }
    };
  },
  computed: {
    totalPagesVisible() {
      return this.$vuetify.breakpoint.smAndDown ? 4 : 7;
    },
    showPagination() {
      return this.page.lastPage > 1;
    },
    requestPageParams() {
      return {
        "page[size]": this.page.pageSize,
        "page[number]": this.page.currentPage
      };
    }
  },
  methods: {
    resetPagination() {
      this.page.currentPage = 1;
    },
    updatePageDetails({ page }) {
      this.page.currentPage = page["current-page"];
      this.page.lastPage = page["last-page"];
    },
    updatePageSize(size) {
      this.page.pageSize = size;
    }
  }
};
