<template>
  <BaseAction>
    <SecondaryButton
      @click.native="openDeleteBookingDialog"
      textColor="#d10a3a"
    >
      <v-icon>
        mdi-delete-outline
      </v-icon>
      Delete Booking
    </SecondaryButton>
    <DeleteBookingDialog
      :isOpen="isDeleteBookingOpen"
      @close="closeDeleteBookingDialog"
    />
  </BaseAction>
</template>

<script>
import DeleteBookingDialog from "@/views/bookings/components/ConfirmDialogs/DeleteBookingDialog";
import BaseAction from "@/components/common/BaseAction";
import SecondaryButton from "@/components/common/Button/SecondaryButton";

export default {
  name: "DeleteBookingAction",
  components: { BaseAction, SecondaryButton, DeleteBookingDialog },
  data() {
    return {
      isDeleteBookingOpen: false
    };
  },
  methods: {
    openDeleteBookingDialog() {
      this.isDeleteBookingOpen = true;
    },
    closeDeleteBookingDialog() {
      this.isDeleteBookingOpen = false;
    }
  }
};
</script>
