<template>
  <Chip :color="color">
    <slot />
  </Chip>
</template>

<script>
import { getColorByStatus } from "@/utils/organisation-units";
import Chip from "@/components/common/Chip";

export default {
  name: "UnitChip",
  components: { Chip },
  computed: {
    color() {
      return getColorByStatus(this.status);
    }
  },
  props: {
    status: String
  }
};
</script>

<style scoped></style>
