<template>
  <div class="range-picker-wrapper">
    <div class="d-flex range-picker">
      <div class="time-picker-from">
        <v-text-field
          v-mask="'##:##'"
          placeholder="00:00"
          :value="fromHour"
          :disabled="disabled"
          :required="required"
          @change="updateFromTime"
        />
      </div>
      <div class="dash-divider">-</div>
      <div class="time-picker-to">
        <v-text-field
          v-mask="'##:##'"
          placeholder="00:00"
          :value="toHour"
          :disabled="disabled"
          :required="required"
          @change="updateToTime"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";

export default {
  props: {
    fromHour: String,
    toHour: String,
    disabled: Boolean,
    required: Boolean
  },
  directives: { mask },
  methods: {
    updateFromTime(fromHour) {
      this.$emit("updateRange", { fromHour, toHour: this.toHour });
    },
    updateToTime(toHour) {
      this.$emit("updateRange", { toHour, fromHour: this.fromHour });
    }
  }
};
</script>
