<template>
  <DialogFull :data="settings" @close="close">
    <template v-slot:dialog.body>
      <v-container class="submit-workers-dialog col-8">
        <div class="d-flex" v-if="shifts.length">
          <ShiftLink
            v-for="shift in shifts"
            :shift-id="shift.id"
            :key="shift.id"
          />
        </div>
        <div v-else class="shift-link">
          <v-icon color="primary">mdi-calendar-range</v-icon>
          <span>All Shifts</span>
          <span v-if="filters.status">, {{ filters.status }}</span>
          <span v-if="showDateFilters"
            >, {{ filters.startDate }} to {{ filters.endDate }}
          </span>
        </div>
        <h1 class="workers-title">Candidates</h1>
        <p class="description">
          Select candidates to submit for all of the selected shifts
        </p>
        <WorkersSelection
          :workers="candidates"
          :isLoading="isLoadingCandidates"
          :selectedWorkers="selectedWorkersIds"
          @handleSelectedWorkers="selectedWorkersIds = $event"
          @unselectWorker="unselectWorker"
        />
      </v-container>
    </template>
    <template v-slot:dialog.action>
      <PrimaryButton @click.native="submitCandidates" :loading="isLoading">
        Submit Candidates
      </PrimaryButton>
    </template>
  </DialogFull>
</template>

<script>
import WorkersSelection from "@/components/bookings/WorkersSelection";
import DialogFull from "@/components/common/DialogFull";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import {
  SUBMIT_FOR_ALL_SHIFTS,
  SUBMIT_FOR_SHIFTS
} from "@/store/modules/applications/actions";
import { createNamespacedHelpers } from "vuex";
import { APPLICATION_NAMESPACE } from "@/store/modules/applications";
import {
  BOOKINGS_NAMESPACE,
  FETCH_BOOKING_CANDIDATES_FOR_SUBMIT
} from "@/store/modules/bookings/actions";
import {
  GET_CANDIDATES_FOR_SUBMIT,
  SET_IS_FETCHING_CANDIDATES_FOR_SUBMIT
} from "@/store/modules/bookings/getters";
import { map, filter, join, first, isEmpty } from "lodash";
import { getFullName } from "@/utils/users";
import ShiftLink from "@/components/bookings/ShiftLink";

const { mapActions: mapApplicationActions } = createNamespacedHelpers(
  APPLICATION_NAMESPACE
);
const {
  mapActions: mapBookingActions,
  mapGetters: mapBookingGetters
} = createNamespacedHelpers(BOOKINGS_NAMESPACE);

export default {
  name: "SubmitCandidatesDialog",
  components: { ShiftLink, WorkersSelection, DialogFull, PrimaryButton },
  props: {
    isOpen: Boolean,
    shifts: Array,
    filters: Object
  },
  data() {
    return {
      isLoading: false,
      selectedWorkersIds: []
    };
  },
  inject: ["currentBookingId"],
  created() {
    this.fetchCandidatesForSubmit({
      bookingId: this.currentBookingId,
      params: this.requestParams
    });
  },
  computed: {
    ...mapBookingGetters({
      candidatesForSubmit: GET_CANDIDATES_FOR_SUBMIT,
      isLoadingCandidates: SET_IS_FETCHING_CANDIDATES_FOR_SUBMIT
    }),
    settings() {
      return {
        title: "Submit Candidates for Shifts",
        dialog: this.isOpen
      };
    },
    candidates() {
      return map(this.candidatesForSubmit, candidate => ({
        ...candidate.user,
        fullName: getFullName(candidate.user),
        ...candidate
      }));
    },
    allShifts() {
      return isEmpty(this.shifts);
    },
    showDateFilters() {
      return this.filters.startDate && this.filters.endDate;
    },
    requestParams() {
      const shifts = this.allShifts
        ? "all"
        : join(map(this.shifts, "id"), ", ");
      let params = {
        filter: {
          ...(!(this.filters && this.filters.status && shifts === "all") && {
            submit_for_shift_id: shifts
          })
        }
      };
      if (this.filters) {
        params = {
          filter: {
            ...params.filter,
            ...(this.filters.status && { shiftStatus: this.filters.status }),
            ...(this.filters.startDate && {
              shiftStartDate: this.filters.startDate
            }),
            ...(this.filters.endDate && { shiftEndDate: this.filters.endDate })
          }
        };
      }
      return params;
    }
  },
  methods: {
    ...mapApplicationActions({
      submitForShift: SUBMIT_FOR_SHIFTS,
      submitForAllShifts: SUBMIT_FOR_ALL_SHIFTS
    }),
    ...mapBookingActions({
      fetchCandidatesForSubmit: FETCH_BOOKING_CANDIDATES_FOR_SUBMIT
    }),
    first,
    unselectWorker(selectedWorker) {
      this.selectedWorkersIds = filter(
        this.selectedWorkersIds,
        id => id !== selectedWorker.id
      );
    },
    async submitCandidates() {
      this.isLoading = true;
      try {
        const submitRequests = this.selectedWorkersIds.map(workerId =>
          this.submitForShift({
            worker: workerId,
            shifts: this.shifts,
            all: this.allShifts
          })
        );
        await Promise.all(submitRequests);

        this.$emit("close", { updateList: true });
        this.selectedWorkersIds = [];
      } finally {
        this.isLoading = false;
      }
    },
    close() {
      this.$emit("close", { updateList: false });
    }
  }
};
</script>
<style lang="scss">
.submit-workers-dialog {
  margin-top: 5rem;

  .workers-title {
    margin-bottom: 0.5rem;
  }
  .description {
    color: $secondary-text;
    font-size: 22px;
  }
}
</style>
