<template>
  <div class="role-field">
    <Checkbox
      @change="onCheckboxChange"
      :disabled="disabled"
      :selected-values="selectedValues"
      :options="[role]"
      isLarge
      isColumn
    />
    <Select
      :disabled="disabled"
      label=""
      v-if="showOrganisationSelect && !role.hasOnlyRootOrganisation"
      :loading="isLoading"
      autocomplete
      :value="selectedOrganisations"
      @input="handleSelectedOrganisations($event)"
      :items="organisations"
      item-text="name"
      item-value="id"
      ref="autocomplete"
      chips
      small-chips
      multiple
    >
      <template v-slot:selection="{ data }">
        <v-chip
          close
          @click:close="unselectOrganisation(data.item)"
          class="selected-worker-chip"
        >
          {{ data.item.name }}
        </v-chip>
      </template>
      <template v-slot:item="{ item }">
        {{ item.name }}
      </template>
    </Select>
  </div>
</template>

<script>
import Checkbox from "@/components/common/Checkbox";
import Select from "@/components/common/Select";
import { filter, size } from "lodash";

export default {
  name: "RoleField",
  components: { Checkbox, Select },
  props: {
    isSelected: Boolean,
    organisations: {
      type: Array,
      required: true
    },
    isLoading: Boolean,
    disabled: Boolean,
    role: {
      type: Object,
      required: true
    },
    initialSelectedOrganisations: {
      type: Array,
      required: false
    },
    rootOrganisationId: String
  },
  created() {
    this.adjustRoleOrganisations();
  },
  data() {
    return {
      showOrganisationSelect: false,
      selectedValues: this.isSelected ? [this.role.value] : [],
      selectedOrganisations: []
    };
  },
  methods: {
    adjustRoleOrganisations() {
      if (this.role.hasOnlyRootOrganisation) {
        this.selectedOrganisations = this.rootOrganisationId;
      } else if (size(this.initialSelectedOrganisations)) {
        this.showOrganisationSelect = true;
        this.selectedOrganisations = [...this.initialSelectedOrganisations];
      }
    },
    addRole() {
      this.$emit("addRole", {
        name: this.role.value,
        organisations: this.selectedOrganisations
      });
    },
    removeRole() {
      this.$emit("removeRole", this.role.value);
    },
    updateRole() {
      this.$emit("updateRole", {
        name: this.role.value,
        organisations: this.selectedOrganisations
      });
    },
    onCheckboxChange(val) {
      const checkboxValue = Boolean(size(val));
      this.showOrganisationSelect = checkboxValue;
      if (!checkboxValue) {
        this.selectedOrganisations = [];
        this.removeRole();
      } else {
        this.addRole();
      }
    },
    handleSelectedOrganisations(selectedOrganisations) {
      this.selectedOrganisations = [...selectedOrganisations];
      this.$nextTick(() => {
        this.$refs.autocomplete.isMenuActive = false;
      });
      this.updateRole();
    },
    unselectOrganisation(orgnisationToUnselect) {
      this.selectedOrganisations = filter(
        this.selectedOrganisations,
        organisation => organisation !== orgnisationToUnselect.id
      );
      this.updateRole();
    }
  }
};
</script>

<style lang="scss">
.role-field {
  .checkbox.checkbox--large {
    margin: 0;
  }
}
</style>
