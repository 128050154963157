<template>
  <BaseDialog :isOpen="isOpen">
    <template v-slot:title>
      <span>Are you sure you want to accept the booking?</span>
    </template>
    <template v-slot:body>
      <div class="accept-booking-description">
        Accepting the booking will immediately publish the booking to Tier 1
        agencies.
      </div>
    </template>
    <template v-slot:actions>
      <div class="accept-booking-actions">
        <PrimaryButton @click.native="cancel" text>
          Cancel
        </PrimaryButton>
        <PrimaryButton @click.native="handleSubmit" :loading="isLoading">
          Accept Booking
        </PrimaryButton>
      </div>
    </template>
  </BaseDialog>
</template>

<script>
import BaseDialog from "@/components/common/BaseDialog";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import { createNamespacedHelpers } from "vuex";
import {
  ACCEPT_BOOKING,
  BOOKINGS_NAMESPACE
} from "@/store/modules/bookings/actions";

const { mapActions } = createNamespacedHelpers(BOOKINGS_NAMESPACE);
export default {
  name: "AcceptBookingDialog",
  components: { PrimaryButton, BaseDialog },
  props: {
    isOpen: Boolean
  },
  data() {
    return {
      isLoading: false
    };
  },
  inject: ["currentBookingId"],
  methods: {
    ...mapActions({
      acceptBooking: ACCEPT_BOOKING
    }),
    async handleSubmit() {
      this.isLoading = true;
      try {
        await this.acceptBooking(this.currentBookingId);
        this.$emit("close");
      } finally {
        this.isLoading = false;
      }
    },
    cancel() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss">
.accept-booking-description {
  min-height: 100px;
}
</style>
