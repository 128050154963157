<template>
  <div class="compliance">
    <div class="compliance--checks">
      <div class="left">
        <v-icon class="mr-2 compliance--checks--icon">
          {{ icon }}
        </v-icon>
        <p class="no-select title">{{ compliance.complianceCheckName }}</p>
      </div>
      <Chip :status="status" class="chip--status">{{ status }}</Chip>
    </div>
    <ComplianceCheckDetails :compliance="compliance" :references="references" />
  </div>
</template>

<script>
import Chip from "@/components/common/Chip";
import ComplianceCheckDetails from "@/components/workers/common/ComplianceCheckDetails";

export default {
  props: {
    /**
     *  Compliance object
     * @example
     * {
     *  label: String
     *  checked: Boolean
     * }
     */
    compliance: {
      type: Object,
      required: true
    },
    references: Array,
    showDetails: Boolean
  },
  components: {
    Chip,
    ComplianceCheckDetails
  },
  computed: {
    status() {
      return this.compliance.checkVerified ? "complete" : "incomplete";
    },
    icon() {
      return `mdi-shield-${
        this.compliance.checkVerified ? "check" : "remove"
      }-outline`;
    }
  }
};
</script>

<style lang="scss">
.compliance {
  p.title {
    font-weight: 600;
    font-size: 18px !important;
  }
}
</style>
