import { setStoreValue } from "@/store/utils";

export const SET_BOOKING_TEMPLATES = "SET_BOOKING_TEMPLATES";
export const SET_BOOKING_TEMPLATE = "SET_BOOKING_TEMPLATE";
export const SET_INCOME_TYPES = "SET_INCOME_TYPES";
export const SET_LOCATIONS = "SET_LOCATIONS";
export const SET_REASONS_FOR_HIRE = "SET_REASONS_FOR_HIRE";
export const SET_COMPLIANCE_REQUIREMENTS = "SET_COMPLIANCE_REQUIREMENTS";
export const SET_OTHER_REQUIREMENTS = "SET_OTHER_REQUIREMENTS";
export const SET_REJECTION_REASONS = "SET_REJECTION_REASONS";
export const SET_REQUIREMENTS = "SET_REQUIREMENTS";
export const SET_COST_CODES = "SET_COST_CODES";
export const SET_IS_FETCHING_DATA = "SET_IS_FETCHING_DATA";
export const SET_COUNTRIES = "SET_COUNTRIES";
export const SET_FIELD_NECESSITIES = "SET_FIELD_NECESSITIES";
export const SET_IS_FETCHING_FIELD_NECESSITIES =
  "SET_IS_FETCHING_FIELD_NECESSITIES";
export const SET_IS_FETCHING_COMPLIANCE_REQUIREMENTS =
  "SET_IS_FETCHING_COMPLIANCE_REQUIREMENTS";
export const SET_IS_FETCHING_OTHER_REQUIREMENTS =
  "SET_IS_FETCHING_OTHER_REQUIREMENTS";
export const SET_IS_FETCHING_PUBLISHING_SCHEDULE_TAGS =
  "SET_IS_FETCHING_PUBLISHING_SCHEDULE_TAGS";
export const SET_PUBLISHING_SCHEDULE_TAGS = "SET_PUBLISHING_SCHEDULE_TAGS";
export const SET_IS_FETCHING_COST_CATEGORIES =
  "SET_IS_FETCHING_COST_CATEGORIES";
export const SET_COST_CATEGORIES = "SET_COST_CATEGORIES";
export const SET_TIME_UNITS = "SET_TIME_UNITS";
export const SET_IS_FETCHING_TIME_UNITS = "SET_IS_FETCHING_TIME_UNITS";
export const SET_SYSTEM_PARAMETERS = "SET_SYSTEM_PARAMETERS";

const dataMutations = {
  [SET_BOOKING_TEMPLATES]: setStoreValue("bookingTemplates"),
  [SET_BOOKING_TEMPLATE]: setStoreValue("currentBookingTemplate"),
  [SET_INCOME_TYPES]: setStoreValue("incomeTypes"),
  [SET_LOCATIONS]: setStoreValue("locations"),
  [SET_REASONS_FOR_HIRE]: setStoreValue("reasonsForHire"),
  [SET_COMPLIANCE_REQUIREMENTS]: setStoreValue("complianceRequirements"),
  [SET_IS_FETCHING_COMPLIANCE_REQUIREMENTS]: setStoreValue(
    "isFetchingComplianceRequirements"
  ),
  [SET_OTHER_REQUIREMENTS]: setStoreValue("otherRequirements"),
  [SET_IS_FETCHING_OTHER_REQUIREMENTS]: setStoreValue(
    "isFetchingOtherRequirements"
  ),
  [SET_REJECTION_REASONS]: setStoreValue("rejectionReasons"),
  [SET_REQUIREMENTS]: setStoreValue("requirements"),
  [SET_COST_CODES]: setStoreValue("costCodes"),
  [SET_IS_FETCHING_DATA]: setStoreValue("isFetchingData"),
  [SET_COUNTRIES]: setStoreValue("countries"),
  [SET_FIELD_NECESSITIES]: setStoreValue("fieldNecessities"),
  [SET_IS_FETCHING_FIELD_NECESSITIES]: setStoreValue(
    "isFetchingFieldNecessities"
  ),
  [SET_PUBLISHING_SCHEDULE_TAGS]: setStoreValue("publishingScheduleTags"),
  [SET_IS_FETCHING_PUBLISHING_SCHEDULE_TAGS]: setStoreValue(
    "isFetchingPublishingScheduleTags"
  ),
  [SET_IS_FETCHING_COST_CATEGORIES]: setStoreValue("isFetchingCostCategories"),
  [SET_COST_CATEGORIES]: setStoreValue("costCategories"),
  [SET_TIME_UNITS]: setStoreValue("timeUnits"),
  [SET_IS_FETCHING_TIME_UNITS]: setStoreValue("isFetchingTimeUnits"),
  [SET_SYSTEM_PARAMETERS]: setStoreValue("systemParameters")
};

export default dataMutations;
