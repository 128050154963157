<template>
  <div>
    <PrimaryButton light dense shrink @click.native="openDialog">
      Edit
      <template v-slot:append:icon>
        <v-icon class="ml-1">mdi-pencil</v-icon>
      </template>
    </PrimaryButton>
    <EditPONumberDialog v-if="isOpen" :isOpen="isOpen" @close="closeDialog" />
  </div>
</template>

<script>
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import EditPONumberDialog from "../dialogs/EditPONumberDialog";

export default {
  name: "EditPONumberAction",
  components: {
    PrimaryButton,
    EditPONumberDialog
  },
  data() {
    return {
      isOpen: false
    };
  },
  methods: {
    openDialog() {
      this.isOpen = true;
    },
    closeDialog() {
      this.isOpen = false;
    }
  }
};
</script>
