/* eslint-disable prettier/prettier */
import { setStoreValue } from '@/store/utils';
import { INITIAL_BOOKING_STATE } from '@/store/modules/bookings/bookings';

export const SET_IS_FETCHING_BOOKINGS = "SET_IS_FETCHING_BOOKINGS";
export const SET_IS_FETCHING_CURRENT_BOOKING = "SET_IS_FETCHING_CURRENT_BOOKING";
export const SET_IS_FETCHING_CURRENT_BOOKING_CANDIDATES = "SET_IS_FETCHING_CURRENT_BOOKING_CANDIDATES";
export const SET_IS_FETCHING_CURRENT_BOOKING_COMMENTS = "SET_IS_FETCHING_CURRENT_BOOKING_COMMENTS";
export const SET_IS_FETCHING_CURRENT_BOOKING_SHIFT_PATTERNS = "SET_IS_FETCHING_CURRENT_BOOKING_SHIFT_PATTERNS";
export const SET_IS_FETCHING_CURRENT_BOOKING_EVENTS = "SET_IS_FETCHING_CURRENT_BOOKING_EVENTS";
export const SET_IS_FETCHING_CURRENT_BOOKING_PUBLISHING_SCHEDULE = "SET_IS_FETCHING_CURRENT_BOOKING_PUBLISHING_SCHEDULE";
export const SET_BOOKINGS = "SET_BOOKINGS";
export const SET_CURRENT_BOOKING = "SET_CURRENT_BOOKING";
export const SET_CURRENT_BOOKING_COMMENTS = "SET_CURRENT_BOOKING_COMMENTS";
export const SET_CURRENT_BOOKING_CANDIDATES = "SET_CURRENT_BOOKING_CANDIDATES";
export const SET_CURRENT_BOOKING_SHIFT_PATTERNS = "SET_CURRENT_BOOKING_SHIFT_PATTERNS";
export const SET_CURRENT_BOOKING_PUBLISHING_SCHEDULE = "SET_CURRENT_BOOKING_PUBLISHING_SCHEDULE";
export const SET_CURRENT_BOOKING_EVENTS = "SET_CURRENT_BOOKING_EVENTS";
export const SET_BOOKING_SHIFTS = "SET_BOOKING_SHIFTS";
export const SET_IS_FETCHING_BOOKING_SHIFTS = "SET_IS_FETCHING_BOOKING_SHIFTS";
export const SET_IS_FETCHING_BOOKING_PAYRATES = "SET_IS_FETCHING_BOOKING_PAYRATES";
export const SET_BOOKING_PAYRATES = "SET_BOOKING_PAYRATES";
export const SET_IS_EDITING_BOOKING_PAYRATES = "SET_IS_EDITING_BOOKING_PAYRATES";
export const CLEAR_BOOKING_DETAILS = "CLEAR_BOOKING_DETAILS";
export const UPDATE_LOCAL_APPLICATION = 'UPDATE_LOCAL_APPLICATION';
export const SET_IS_FETCHING_CANDIDATES_FOR_SUBMIT = 'SET_IS_FETCHING_CANDIDATES_FOR_SUBMIT';
export const SET_CANDIDATES_FOR_SUBMIT = 'SET_CANDIDATES_FOR_SUBMIT';
export const SET_TOTAL_BOOKING_SHIFTS = 'SET_TOTAL_BOOKING_SHIFTS';
export const SET_BOOKING_FILTERS = "SET_BOOKING_FILTERS";
export const SET_BOOKING_LIST_FILTERS = 'SET_BOOKING_LIST_FILTERS';
export const SET_BOOKINGS_TO_APPROVE = "SET_BOOKINGS_TO_APPROVE";
export const SET_BOOKINGS_TO_ACCEPT = "SET_BOOKINGS_TO_ACCEPT";
export const SET_BOOKINGS_WITH_SHIFTS_TO_APPROVE = "SET_BOOKINGS_WITH_SHIFTS_TO_APPROVE";
export const SET_BOOKING_EXTENSIONS_TO_APPROVE = "SET_BOOKING_EXTENSIONS_TO_APPROVE";
export const SET_BUDGETS = "SET_BUDGETS";
export const SET_SHIFTS = "SET_SHIFTS";
export const SET_IS_FETCHING_SHIFTS = "SET_IS_FETCHING_SHIFTS";
export const SET_SHIFTS_FILTERS = 'SET_SHIFTS_FILTERS';

const bookingsMutations = {
  [SET_IS_FETCHING_BOOKINGS]: setStoreValue("isFetchingBookings"),
  [SET_BOOKINGS]: setStoreValue("bookings"),
  [SET_BOOKING_FILTERS]: setStoreValue("bookingFilters"),
  [SET_CURRENT_BOOKING]: setStoreValue("currentBooking"),
  [SET_CURRENT_BOOKING_COMMENTS]: setStoreValue("bookingComments"),
  [SET_CURRENT_BOOKING_CANDIDATES]: setStoreValue("currentBookingCandidates"),
  [SET_CANDIDATES_FOR_SUBMIT]: setStoreValue("candidatesForSubmit"),
  [SET_IS_FETCHING_CANDIDATES_FOR_SUBMIT]: setStoreValue("isFetchingCandidatesForSubmit"),
  [SET_CURRENT_BOOKING_SHIFT_PATTERNS]: setStoreValue("bookingShiftPatterns"),
  [SET_CURRENT_BOOKING_PUBLISHING_SCHEDULE]: setStoreValue("currentBookingPublishingSchedule"),
  [SET_CURRENT_BOOKING_EVENTS]: setStoreValue("currentBookingEvents"),
  [SET_BOOKING_SHIFTS]: setStoreValue("bookingShifts"),
  [SET_TOTAL_BOOKING_SHIFTS]: setStoreValue("totalBookingShifts"),
  [SET_IS_FETCHING_CURRENT_BOOKING]: setStoreValue("isFetchingCurrentBooking"),
  [SET_IS_FETCHING_CURRENT_BOOKING_CANDIDATES]: setStoreValue("isFetchingCurrentBookingCandidates"),
  [SET_IS_FETCHING_CURRENT_BOOKING_COMMENTS]: setStoreValue("isFetchingBookingComments"),
  [SET_IS_FETCHING_CURRENT_BOOKING_SHIFT_PATTERNS]: setStoreValue("isFetchingBookingShiftPatterns"),
  [SET_IS_FETCHING_CURRENT_BOOKING_EVENTS]: setStoreValue("isFetchingBookingEvents"),
  [SET_IS_FETCHING_BOOKING_SHIFTS]: setStoreValue("isFetchingBookingShifts"),
  [SET_BOOKING_PAYRATES]: setStoreValue("bookingPayRates"),
  [SET_IS_FETCHING_BOOKING_PAYRATES]: setStoreValue("isFetchingBookingPayRates"),
  [SET_IS_FETCHING_CURRENT_BOOKING_PUBLISHING_SCHEDULE]: setStoreValue("isFetchingBookingPublishingSchedule"),
  [SET_IS_EDITING_BOOKING_PAYRATES]: setStoreValue("isEditingBookingPayRates"),
  [CLEAR_BOOKING_DETAILS](state, currentBookingId) {
    const savedBookingId = state.currentBooking && state.currentBooking.id;
    if(savedBookingId !== currentBookingId) {
      state.currentBooking = INITIAL_BOOKING_STATE.currentBooking;
    }
    state.bookingComments = INITIAL_BOOKING_STATE.bookingComments;
    state.currentBookingCandidates = INITIAL_BOOKING_STATE.currentBookingCandidates;
    state.bookingShiftPatterns = INITIAL_BOOKING_STATE.bookingShiftPatterns;
    state.currentBookingPublishingSchedule = INITIAL_BOOKING_STATE.currentBookingPublishingSchedule;
    state.currentBookingEvents = INITIAL_BOOKING_STATE.currentBookingEvents;
    state.bookingShifts = INITIAL_BOOKING_STATE.bookingShifts;
    state.bookingPayRates = INITIAL_BOOKING_STATE.bookingPayRates;
    return state;
  },
  [UPDATE_LOCAL_APPLICATION](state,editedApplication) {
    state.currentBookingCandidates = state.currentBookingCandidates.map(
      application => {
        return application.id === editedApplication.id
          ? editedApplication
          : application;
      }
    );
},
[SET_BOOKING_LIST_FILTERS]: setStoreValue("bookingListFilters"),
[SET_BOOKINGS_TO_APPROVE]: setStoreValue("bookingsToApprove"),
[SET_BOOKINGS_TO_ACCEPT]: setStoreValue("bookingsToAccept"),
[SET_BOOKINGS_WITH_SHIFTS_TO_APPROVE]: setStoreValue("bookingsWithShiftsToApprove"),
[SET_BOOKING_EXTENSIONS_TO_APPROVE]: setStoreValue("bookingExtensionsToApprove"),
[SET_BUDGETS]: setStoreValue("budgets"),
[SET_IS_FETCHING_SHIFTS]: setStoreValue("isFetchingShifts"),
[SET_SHIFTS]: setStoreValue("shifts"),
[SET_SHIFTS_FILTERS]: setStoreValue("shiftsFilters"),
};

export default bookingsMutations;
