import { SET_TIMESHEETS } from "@/store/modules/timesheets/mutations";

export const updateTimesheetList = (commit, state, updatedTimesheet) => {
  commit(
    SET_TIMESHEETS,
    state.timesheets.map(timesheet => {
      if (timesheet.id !== updatedTimesheet.id) return timesheet;
      return updatedTimesheet;
    })
  );
};
