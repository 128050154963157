import JSONApiService from "@/services/JSONApiService";
import {
  ORGANISATION_MODEL,
  organisationModelActions
} from "@/models/organisation-model";
import { disablePluralizeForCall } from "@/services/utils";

const { PUBLISH, ARCHIVE, DUPLICATE } = organisationModelActions;

const callOrganisationUnitAction = ({ id, action, commit }) => {
  const api = JSONApiService(commit);
  api.one(ORGANISATION_MODEL, id);
  disablePluralizeForCall(api, () => api.all(action));
  return api.patch({});
};

const organisationApi = commit => ({
  getAll: async params =>
    JSONApiService(commit)
      .all(ORGANISATION_MODEL)
      .get(params),
  archiveOrganisationUnit: async id =>
    callOrganisationUnitAction({
      id,
      action: ARCHIVE,
      commit
    }),
  deleteDraftOrganisationUnit: async id =>
    JSONApiService(commit)
      .one(ORGANISATION_MODEL, id)
      .destroy(),
  duplicateOrganisationUnit: async id =>
    callOrganisationUnitAction({
      id,
      action: DUPLICATE,
      commit
    }),
  publishOrganisationUnit: async id =>
    callOrganisationUnitAction({
      id,
      action: PUBLISH,
      commit
    }),
  editOrganisationUnit: async ({ organisation, id }) =>
    JSONApiService(commit)
      .one(ORGANISATION_MODEL, id)
      .patch(organisation),
  createOrganisationUnit: async data =>
    JSONApiService(commit)
      .all(ORGANISATION_MODEL)
      .post(data)
});

export default organisationApi;
