import { redirectWithPermissionCheck } from "@/router/index";
import { MAIN_NAVIGATION_ITEMS } from "@/router/constants";

const { SETTINGS } = MAIN_NAVIGATION_ITEMS;

export const createRouteWithPermissionCheck = ({
  path,
  component,
  children,
  permission,
  name
}) => ({
  name,
  path,
  component,
  children,
  beforeEnter: (to, from, next) => {
    redirectWithPermissionCheck(next, permission || SETTINGS);
  }
});

export const getLinkParams = routeParams => {
  const { status, organisationId } = routeParams;
  return {
    ...(status && { status: [status] }),
    ...(organisationId && { root_client_id: [organisationId] })
  };
};
