import JSONApiService from "@/services/JSONApiService";
import APIService from "@/services/APIService";
import { disablePluralizeForCall } from "@/services/utils";
import {
  APPLICATION_MODEL,
  applicationActions
} from "@/models/application-model";
import { SHIFT_MODEL } from "@/models/shift-model";
import { SUBMIT_FOR_ALL_SHIFTS_MODEL } from "@/models/booking-submit-model";
import { ASSESSMENT_MODEL } from "@/models/assessment-model";
import { APPLICATION_SHIFTS_STATUS_MODEL } from "@/models/application-shifts-status-model";
import { BOOKING_MODEL } from "@/models/booking-model";
import {
  include,
  USER,
  CREATED_BY,
  BOOKING,
  nested,
  APPLICATIONS,
  SHIFTS
} from "@/models/relationships";
import { applicationIncludes } from "@/services/modules/bookings";
import { USER_ONLY_FULL_NAME } from "@/models/user-model";
import { ORGANISATION_MODEL } from "@/models/organisation-model";

const {
  APPROVE,
  REJECT,
  REQUEST_INTERVIEW,
  SHORTLIST,
  WITHDRAW,
  WITHDRAW_SHIFTS
} = applicationActions;

const callApplicationAction = (commit, applicationId, action, payload = {}) => {
  const api = JSONApiService(commit);
  api.one(APPLICATION_MODEL, applicationId);
  disablePluralizeForCall(api, () => api.all(action));
  return api.post(payload);
};

const applicationApi = commit => ({
  addAssessment: async ({ applicationId, assessment }) =>
    JSONApiService(commit)
      .all(ASSESSMENT_MODEL)
      .post(
        { ...assessment, application: { id: applicationId } },
        include(CREATED_BY)
      ),
  requestInterview: async applicationId =>
    callApplicationAction(commit, applicationId, REQUEST_INTERVIEW),
  withdrawCandidate: async ({ applicationId }) =>
    callApplicationAction(commit, applicationId, WITHDRAW),
  withdrawFromShifts: async ({ applicationId, shifts, all }) =>
    JSONApiService(commit)
      .one(APPLICATION_MODEL, applicationId)
      .all(applicationActions.SHIFT)
      .patch({ status: "submitted", shifts, all }, { all }),
  withdrawShifts: async (applicationId, workers) =>
    JSONApiService(commit)
      .one(APPLICATION_MODEL, applicationId)
      .all(applicationActions.SHIFT)
      .all(WITHDRAW_SHIFTS)
      .post(workers),
  submitForShifts: async ({ worker, shifts, all }) =>
    JSONApiService(commit)
      .one(APPLICATION_MODEL, worker)
      .all(applicationActions.SHIFT)
      .post({ shifts }, { all }),
  submitForAllShifts: async ({ workers, bookingId }) => {
    const api = JSONApiService();
    api.one(BOOKING_MODEL, bookingId);
    disablePluralizeForCall(api, () => api.all(SUBMIT_FOR_ALL_SHIFTS_MODEL));
    return api.post({ workers });
  },
  updateApplication: async application =>
    JSONApiService(commit).update(
      APPLICATION_MODEL,
      application,
      applicationIncludes
    ),
  confirmAllocation: async ({ applicationId, shifts, all }) =>
    JSONApiService(commit)
      .one(APPLICATION_MODEL, applicationId)
      .all(applicationActions.SHIFT)
      .patch({ status: "confirmed", shifts }, { all }),
  declineAllocation: async ({ applicationId, shifts, all }) =>
    JSONApiService(commit)
      .one(APPLICATION_MODEL, applicationId)
      .all(applicationActions.SHIFT)
      .patch({ status: "declined", shifts }, { all }),
  allocateWorkers: async ({ applicationId, shifts, all }) =>
    JSONApiService(commit)
      .one(APPLICATION_MODEL, applicationId)
      .all(applicationActions.SHIFT)
      .patch({ status: "allocated", shifts }, { all }),
  allocateCandidates: async ({ bookingId, shiftId, users }) =>
    APIService.client().post(
      `bookings/${bookingId}/shifts/${shiftId}/allocate`,
      { data: { attributes: { users } } }
    ),
  approveCandidate: async ({ applicationId, workerId }) =>
    callApplicationAction(commit, applicationId, APPROVE, workerId),
  shortlistCandidate: async ({ applicationId, workerId }) =>
    callApplicationAction(commit, applicationId, SHORTLIST, workerId),
  rejectCandidate: async ({ applicationId, reason }) =>
    callApplicationAction(commit, applicationId, REJECT, { reason }),
  submitWorkers: async ({ user, booking }) =>
    JSONApiService(commit)
      .all(APPLICATION_MODEL)
      .post({ user, booking }),
  getShiftCandidates: async (bookingId, selectedShifts) =>
    JSONApiService(commit)
      .one(BOOKING_MODEL, bookingId)
      .all(SHIFT_MODEL)
      .all(applicationActions.AVAILABLE_CANDIDATES)
      .get({ shifts: selectedShifts, ...include(USER) }),
  getShiftUnavailableCandidates: async ({
    bookingId,
    selectedShifts,
    search
  }) =>
    JSONApiService(commit)
      .one(BOOKING_MODEL, bookingId)
      .all(SHIFT_MODEL)
      .all(applicationActions.UNAVAILABLE_CANDIDATES)
      .get({
        shifts: selectedShifts,
        search,
        ...include(
          APPLICATIONS,
          nested(APPLICATIONS, SHIFTS),
          nested(APPLICATIONS, BOOKING)
        )
      }),
  getApplicationShifts: async ({ applicationId, params }) =>
    JSONApiService(commit)
      .one(APPLICATION_MODEL, applicationId)
      .all(applicationActions.APPLICATION_SHIFT)
      .get({ ...params, ...include(applicationActions.SHIFT) }),
  updateApplicationShiftsStatus: async ({
    applicationShifts,
    status,
    all,
    booking
  }) =>
    JSONApiService(commit)
      .all(APPLICATION_SHIFTS_STATUS_MODEL)
      .patch({ status, applicationShifts, booking }, { all }),
  getBookingAvailableCandidates: async bookingId =>
    JSONApiService(commit)
      .one(BOOKING_MODEL, bookingId)
      .all(applicationActions.AVAILABLE_CANDIDATES)
      .get({ ...include(USER_ONLY_FULL_NAME) }),
  getApplications: async params =>
    JSONApiService(commit)
      .all(APPLICATION_MODEL)
      .get({ ...params, ...include(USER, BOOKING, ORGANISATION_MODEL) }),
  getBookingUnavailableWorkers: async ({ bookingId, search }) =>
    JSONApiService(commit)
      .one(BOOKING_MODEL, bookingId)
      .all(applicationActions.UNAVAILABLE_CANDIDATES)
      .get({ search })
});

export default applicationApi;
