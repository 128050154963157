<template>
  <LocationDialog
    :is-open="isOpen"
    @close="close"
    :location="location"
    :title="getFormattedLocation(location)"
    actionName="Save Changes"
    @submit="handleEdit"
    :loading="isLoading"
  />
</template>

<script>
import LocationDialog from "@/views/settings/Organisation/Locations/dialogs/LocationDialog";
import { getFormattedLocation } from "@/utils/locations";
import { ORGANISATION_LOCATIONS_NAMESPACE } from "@/store/modules/organisations/organisation-locations";
import { createNamespacedHelpers } from "vuex";
import { UPDATE_ORGANISATION_LOCATION } from "@/store/modules/organisations/organisation-locations/actions";
import { omitBy, isNil } from "lodash";

const { mapActions } = createNamespacedHelpers(
  ORGANISATION_LOCATIONS_NAMESPACE
);

export default {
  name: "EditLocationAction",
  components: { LocationDialog },
  props: {
    isOpen: Boolean,
    location: Object
  },
  data() {
    return {
      isLoading: false
    };
  },
  methods: {
    ...mapActions({
      editLocation: UPDATE_ORGANISATION_LOCATION
    }),
    close() {
      this.$emit("close");
    },
    async handleEdit(newLocation) {
      this.isLoading = true;
      const editedLocation = omitBy(newLocation, isNil);
      try {
        await this.editLocation(editedLocation);
        this.close();
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    getFormattedLocation
  }
};
</script>

<style scoped></style>
