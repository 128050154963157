<template>
  <div class="summary-wrapper">
    <div
      class="d-flex justify-center"
      :class="{ 'flex-column text-center': isMobile }"
    >
      <h2 class="mb-2">Activity</h2>
    </div>
    <div class="dashboard__date">
      <DatePicker
        label=""
        class="date-picker-wrapper--no-label"
        data-test="datePicker"
        :class="{ 'pl-3': !isMobile }"
        @updatedDate="filterDates"
        isDark
        isRange
        clearable
      />
    </div>
    <div v-if="isLoading">
      <SkeletonLoader :count="5" width="50%" type="list-item-avatar-two-line" />
    </div>
    <div v-else class="mb-10">
      <Summary
        :organisationId="activity.organisationId && activity.organisationId"
        :isLoading="isLoading"
        :summaries="activity.summaries"
        :permissions="permissions"
      />
    </div>
  </div>
</template>

<script>
import Summary from "@/components/dashboard/Summary";
import DatePicker from "@/components/common/DatePicker";
import {
  FETCH_SUMMARIES,
  FETCH_EVENTS,
  FETCH_INVOICE_SUMMARY
} from "@/store/modules/dashboard/action-types";
import { SET_FILTERS } from "@/store/modules/dashboard/mutation-types";
import { DASHBOARD_NAMESPACE } from "@/store/modules/dashboard";
import { createNamespacedHelpers, mapState } from "vuex";
import { isEmpty, first, isArray } from "lodash";
import SkeletonLoader from "@/components/common/SkeletonLoader";

const dashboard = createNamespacedHelpers(DASHBOARD_NAMESPACE);

export default {
  components: {
    DatePicker,
    Summary,
    SkeletonLoader
  },
  props: {
    clientIds: Array
  },
  created() {
    this.getClients();
  },
  computed: {
    ...dashboard.mapState({
      events: state => state.events,
      clientsSummaries: state => state.clientsSummaries,
      filters: state => state.filters
    }),
    ...mapState("auth", {
      permissions: state => state.permissions
    })
  },
  data() {
    return {
      dashboardTabs: null,
      isLoading: true,
      activity: {},
      dates: []
    };
  },
  methods: {
    ...dashboard.mapActions({
      fetchSummaries: FETCH_SUMMARIES,
      fetchInvoiceSummary: FETCH_INVOICE_SUMMARY,
      fetchEvents: FETCH_EVENTS
    }),
    ...dashboard.mapMutations({
      setFilters: SET_FILTERS
    }),
    async filterDates(dates) {
      this.dates = dates;
      if (dates[1]) {
        this.setFilters({
          ...this.filters,
          period_start: dates[0],
          period_end: dates[1]
        });
        this.getClients();
      } else {
        this.clearFilter(true);
        if (isEmpty(dates)) {
          this.getClients();
        }
      }
    },
    async getClients() {
      this.isLoading = true;
      try {
        await this.fetchSummaries();
        this.activity = isArray(this.clientsSummaries)
          ? first(this.clientsSummaries)
          : this.clientsSummaries;
      } finally {
        this.isLoading = false;
      }
      // TODO: Uncomment when api is fixed
      // if (this.permissions["dashboard.invoice-details"]) {
      //    this.fetchInvoiceSummary();
      // }
    },
    async filterClients(organisations) {
      this.isLoading = true;
      this.setFilters({
        ...this.filters,
        organisation: organisations
      });
      this.getClients();
    },
    clearFilter(isDate) {
      if (isEmpty(this.dates) && isEmpty(this.clientIds)) {
        this.setFilters({});
        return this.getClients();
      }
      if (isDate) {
        this.setFilters({ organisation: this.clientIds });
      }
      if (!isDate && this.dates[1]) {
        this.setFilters({
          period_start: this.dates[0],
          period_end: this.dates[1]
        });
      }
    }
  },
  watch: {
    clientIds(newValue) {
      if (isEmpty(newValue)) {
        this.clearFilter(false);
        this.getClients();
      } else {
        this.filterClients(newValue);
      }
    }
  }
};
</script>
