<template>
  <div class="bulk-workers container">
    <SelectWorkerByAgency
      class="mb-4"
      :entries="[]"
      :generatingTimesheets="addingWorkers"
      @add="addWorkers"
    />
    <SecondaryButton
      v-if="groupedItems.length"
      class="my-2"
      @click.native="clearData"
      shrink
      dense
    >
      Clear
    </SecondaryButton>
    <p v-else class="text-center secondary-text">
      Paste the worker’s data from Excel
    </p>
    <v-simple-table class="my-2 container">
      <template v-slot:default>
        <thead>
          <draggable tag="tr" v-model="headers">
            <th v-for="(header, key) in headers" :id="key" :key="key">
              {{ header }}
            </th>
          </draggable>
        </thead>
        <tbody>
          <draggable
            tag="tr"
            v-model="groupedItems[key]"
            draggable=".user"
            v-for="(items, key) in groupedItems"
            :key="key"
          >
            <td v-for="(item, index) in items" :key="index" class="user">
              <v-text-field v-model="items[index]" @change="updateWorkers" />
            </td>
            <v-btn @click="removeWorker(key)" class="ml-2 mt-2" icon small>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </draggable>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { getItemsFromClipboard } from "@/utils/helpers";
import SecondaryButton from "@/components/common/Button/SecondaryButton";
import { filter, isEmpty } from "lodash";
import draggable from "vuedraggable";
import { getWorkersToCreate, formatWorkersToBePlace } from "@/utils/workers";
import SelectWorkerByAgency from "@/components/timesheets/SelectWorkerByAgency";

const DEFAULT_HEADERS = [
  "First Name",
  "Last Name",
  "Unique ID 1",
  "Unique ID 2"
];

export default {
  components: {
    SecondaryButton,
    draggable,
    SelectWorkerByAgency
  },
  props: {
    isOpen: Boolean
  },
  data() {
    return {
      showValidationErrors: false,
      isCreating: false,
      headers: DEFAULT_HEADERS,
      groupedItems: [],
      newItem: [],
      addingWorkers: false
    };
  },
  methods: {
    onPaste(event) {
      const { groupedItems } = getItemsFromClipboard(event);
      if (groupedItems) {
        this.groupedItems = groupedItems;
      }
      this.updateWorkers();
    },
    clearData() {
      this.headers = DEFAULT_HEADERS;
      this.groupedItems = [];
      this.updateWorkers();
    },
    async addItem() {
      this.showValidationErrors = true;
      const valid = await this.$refs.validation.validate();
      if (valid) {
        this.groupedItems.push(Object.assign([], this.newItem));
        this.showValidationErrors = false;
        this.newItem = [];
      }
      this.updateWorkers();
    },
    removeWorker(key) {
      this.groupedItems = filter(
        this.groupedItems,
        (item, index) => index !== key
      );
      this.updateWorkers();
    },
    updateWorkers() {
      const workersToCreate = getWorkersToCreate(
        this.headers,
        this.groupedItems
      );
      this.$emit("update", workersToCreate);
    },
    addWorkers(workers) {
      this.addingWorkers = true;
      const newItems = formatWorkersToBePlace(this.headers, workers);
      this.groupedItems = [...this.groupedItems, ...newItems];
      this.updateWorkers();
      this.addingWorkers = false;
    },
    isEmpty
  }
};
</script>

<style lang="scss">
.bulk-workers {
  td {
    .v-input.v-text-field {
      margin-top: 0 !important;
    }
    .v-text-field {
      padding-top: 0;
    }
    .v-text-field__details {
      display: none;
    }
    .v-text-field .v-input__slot {
      border: none !important;
      border-radius: 0;
      padding: 4px;
    }
  }
  .v-text-field .v-input__slot {
    border-radius: 0;
  }
  th {
    font-size: 17px;
    color: black;
  }
}
</style>
