export const REPORT_MODEL = "focused-report";
export const REPORT_CHART_MODEL = "report-chart";
export const REPORT_FIELD_MODEL = "focused-report-meta";
export const REPORT_FILTER_MODEL = "focused-report-filter";
export const REPORT_GROUPING_MODEL = "focused-report-grouping";
export const REPORT_SHARED_WITH_USER_MODEL = "reports-shared-with-user";

export const reportModel = {
  name: "",
  focus: "",
  status: "",
  chartOptions: [],
  labels: {},
  globallyVisible: false,
  fields: {
    jsonApi: "hasMany",
    type: REPORT_FIELD_MODEL
  },
  filters: {
    jsonApi: "hasMany",
    type: REPORT_FILTER_MODEL
  },
  groupings: {
    jsonApi: "hasMany",
    type: REPORT_GROUPING_MODEL
  },
  charts: {
    jsonApi: "hasMany",
    type: REPORT_CHART_MODEL
  }
};

export const reportChartModel = {
  title: "",
  chartType: "",
  xAxisPrimaryGroupingId: "",
  xAxisSecondaryGroupingId: "",
  yAxisFieldId: "",
  columnStackOption: null
};

export const reportFieldModel = {
  field: "",
  measure: ""
};

export const reportFilterModel = {
  field: "",
  operator: "",
  value: "",
  unit: ""
};

export const reportGroupingModel = {
  field: "",
  interval_number: 0,
  interval_date: ""
};

export const reportSharedWithUserModel = {
  permission: "",
  report: {
    jsonApi: "hasOne",
    type: "focused-reports"
  },
  user: {
    jsonApi: "hasOne",
    type: "users"
  }
};

export default reportModel;
