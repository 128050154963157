import api from "@/services/modules/workers";
import referenceApi from "@/services/modules/references";
import {
  SET_ERROR,
  SET_IS_FETCHING_DATA,
  SET_NEW_WORKER,
  SET_WORKER,
  SET_WORKER_APPLICATIONS,
  SET_WORKER_TIMESHEETS,
  SET_INTERMEDIARY_COMPANY,
  SET_WORKERS,
  SET_IS_FETCHING_APPLICATIONS,
  SET_IS_FETCHING_TIMESHEETS,
  SET_WORKER_COMPLIANCE_FILE,
  SET_WORKER_ID,
  CLEAR_INTERMEDIARY_COMPANY,
  CLEAR_CURRENT_WORKER,
  SET_WORKER_FILTERS
} from "./mutation-types";
import {
  ADD_NEW_WORKER,
  DELETE_WORKER,
  GET_ALL_WORKERS,
  GET_WORKER,
  GET_WORKER_APPLICATIONS,
  GET_WORKER_TIMESHEETS,
  GET_INTERMEDIARY_COMPANY,
  UPDATE_WORKER,
  ADD_NEW_REFERENCE,
  DELETE_REFERENCE,
  DELETE_WORKER_CV,
  DELETE_WORKER_COMPLIANCE_DOC
} from "./action-types";
import {
  addGlobalMessage,
  errorHandler,
  handleWithErrorMessage,
  handleWithGlobalMessage
} from "@/services/utils";
import { setStoreValue } from "@/store/utils";
import { isMockedDataEnabled } from "@/utils/mocked-data";
import { MOCKED_WORKERS_STATE } from "@/constants/workers";
import { first } from "lodash";

export const WORKERS_NAMESPACE = "workers";

const state = {
  workers: [],
  references: [],
  currentWorker: {},
  intermediaryCompany: {},
  currentWorkerId: null,
  applications: [],
  timesheets: [],
  isFetchingData: false,
  isFetchingApplications: false,
  isFetchingTimesheets: false,
  fetchingError: null,
  workerFilters: {}
};

const actions = {
  async [GET_ALL_WORKERS]({ commit, dispatch }, params) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api({ commit }).getAllWorkers(params),
      setLoadingFunction: isLoading => commit(SET_IS_FETCHING_DATA, isLoading),
      setDataFunction: ({ data }) => commit(SET_WORKERS, data)
    });
  },
  async [GET_WORKER_APPLICATIONS]({ commit, dispatch }, params) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api({ commit }).getApplications({ params }),
      setLoadingFunction: isLoading =>
        commit(SET_IS_FETCHING_APPLICATIONS, isLoading),
      setDataFunction: ({ data }) => commit(SET_WORKER_APPLICATIONS, data)
    });
  },
  async [GET_WORKER_TIMESHEETS]({ commit, dispatch }, params) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api({ commit }).getTimesheets({ params }),
      setLoadingFunction: isLoading =>
        commit(SET_IS_FETCHING_TIMESHEETS, isLoading),
      setDataFunction: ({ data }) => commit(SET_WORKER_TIMESHEETS, data)
    });
  },
  async [ADD_NEW_WORKER]({ commit, dispatch }, newWorker) {
    try {
      commit(SET_IS_FETCHING_DATA, true);
      const { data } = await api({ commit }).addNewWorker(newWorker);
      commit(SET_WORKER_ID, data.id);
      return data;
    } catch (exception) {
      errorHandler(dispatch, exception);
    } finally {
      commit(SET_IS_FETCHING_DATA, false);
    }
  },
  async [GET_WORKER]({ commit, dispatch }, id) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api({ commit }).getWorker(id),
      setLoadingFunction: isLoading => commit(SET_IS_FETCHING_DATA, isLoading),
      setDataFunction: ({ data }) => {
        commit(SET_WORKER_ID, data.id);
        commit(SET_WORKER, data);
      }
    });
  },
  async [UPDATE_WORKER]({ commit, dispatch }, { updatedWorker, hideMessage }) {
    try {
      const { data, meta } = await api({ commit }).updateWorker(updatedWorker);
      if (!hideMessage) {
        addGlobalMessage(dispatch, { type: "success", meta });
      }
      commit(SET_WORKER, data);
      return data;
    } catch (exception) {
      errorHandler(dispatch, exception);
    }
  },
  async [DELETE_WORKER]({ commit, dispatch }, { id, hideMessage }) {
    if (!hideMessage) {
      return handleWithGlobalMessage(
        () => api({ commit }).deleteWorker(id),
        dispatch
      );
    }
    return handleWithErrorMessage({
      dispatch,
      request: () => api({ commit }).deleteWorker(id)
    });
  },
  async [GET_INTERMEDIARY_COMPANY]({ commit, dispatch }, id) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api({ commit }).getIntermediaryCompany(id),
      setDataFunction: ({ data, included }) => {
        if (data === null) {
          commit(SET_INTERMEDIARY_COMPANY, {});
          return;
        }
        data.attributes.addresses = [];
        if (included) {
          const { id, type, attributes } = first(included);
          data.attributes.addresses = [{ id, type, ...attributes }];
        }

        commit(SET_INTERMEDIARY_COMPANY, { ...data.attributes, id: data.id });
      }
    });
  },
  async [ADD_NEW_REFERENCE]({ commit, dispatch }, newReference) {
    try {
      const { data } = await referenceApi({ commit }).addNewReference(
        newReference
      );
      return data;
    } catch (exception) {
      errorHandler(dispatch, exception);
    }
  },
  async [DELETE_REFERENCE]({ commit, dispatch }, id) {
    try {
      await referenceApi({ commit }).deleteReference(id);
      return id;
    } catch (exception) {
      errorHandler(dispatch, exception);
    }
  },
  async [DELETE_WORKER_CV]({ dispatch }, id) {
    try {
      await api().deleteWorkerCV(id);
    } catch (exception) {
      errorHandler(dispatch, exception.response.data);
    }
  },
  async [DELETE_WORKER_COMPLIANCE_DOC]({ dispatch }, id) {
    try {
      await api().deleteWorkerComplianceDocument(id);
    } catch (exception) {
      errorHandler(dispatch, exception.response.data);
    }
  }
};

const mutations = {
  [SET_WORKERS]: setStoreValue("workers"),
  [SET_WORKER]: setStoreValue("currentWorker"),
  [SET_WORKER_ID]: setStoreValue("currentWorkerId"),
  [SET_NEW_WORKER](state, worker) {
    state.workers.push(worker);
  },
  [SET_WORKER_APPLICATIONS]: setStoreValue("applications"),
  [SET_WORKER_TIMESHEETS]: setStoreValue("timesheets"),
  [SET_IS_FETCHING_DATA]: setStoreValue("isFetchingData"),
  [SET_IS_FETCHING_APPLICATIONS]: setStoreValue("isFetchingApplications"),
  [SET_IS_FETCHING_TIMESHEETS]: setStoreValue("isFetchingTimesheets"),
  [SET_ERROR]: setStoreValue("fetchingError"),
  [SET_INTERMEDIARY_COMPANY]: setStoreValue("intermediaryCompany"),
  [SET_WORKER_COMPLIANCE_FILE](state, file) {
    state.currentWorker.complianceSupportingFile = file;
  },
  [CLEAR_INTERMEDIARY_COMPANY](state) {
    state.intermediaryCompany = {};
  },
  [CLEAR_CURRENT_WORKER](state) {
    state.currentWorker = {};
  },
  [SET_WORKER_FILTERS]: setStoreValue("workerFilters")
};

export default {
  namespaced: true,
  state: isMockedDataEnabled() ? MOCKED_WORKERS_STATE : state,
  actions: isMockedDataEnabled() ? {} : actions,
  mutations: isMockedDataEnabled() ? {} : mutations
};
