<template>
  <ValidationObserver ref="publishingScheduleForm">
    <Dialog :data="dialogSettings">
      <v-label>Name</v-label>
      <ValidationProvider v-slot="{ errors }" name="name" rules="required">
        <v-text-field
          class="mb-4 mt-2"
          v-model="form.name"
          :error-messages="shouldValidate ? errors : ''"
        />
      </ValidationProvider>
      <ValidationProvider v-slot="{ errors }" name="tags" rules="required">
        <v-label>Tags</v-label>
        <div class="select">
          <v-combobox
            class="select--items mb-4 mt-2"
            v-model="form.tags"
            ref="select"
            :error-messages="shouldValidate ? errors : ''"
            :items="publishingScheduleTags"
            :loading="isFetchingPublishingScheduleTags"
            autocomplete
            full-width
            multiple
          >
            <template v-slot:prepend>
              <PrimaryButton text @click.native="closeSelect">
                Done
              </PrimaryButton>
            </template>
          </v-combobox>
        </div>
      </ValidationProvider>
      <template v-slot:dialog.actions>
        <PrimaryButton @click.native="close" text>Cancel</PrimaryButton>
        <PrimaryButton @click.native="submit" :loading="loading">
          {{ actionName }}
        </PrimaryButton>
      </template>
    </Dialog>
  </ValidationObserver>
</template>

<script>
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import Dialog from "@/components/common/Dialog";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { FETCH_PUBLISHING_SCHEDULE_TAGS } from "@/store/modules/data/actions";
import { GET_PUBLISHING_SCHEDULE_TAGS } from "@/store/modules/data/getters";
import { DATA_NAMESPACE } from "@/store/modules/data";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapActions, mapGetters } = createNamespacedHelpers(
  DATA_NAMESPACE
);

const INITIAL_FORM_DATA = {
  name: "",
  tags: []
};

export default {
  name: "PublishingScheduleDialog",
  components: {
    Dialog,
    PrimaryButton,
    ValidationObserver,
    ValidationProvider
  },
  props: {
    dialogSettings: Object,
    loading: Boolean,
    publishingSchedule: Object,
    actionName: String
  },
  created() {
    this.adjustFormFields();
    this.fetchPublishingSchedulesTags();
  },
  data() {
    return {
      form: {},
      shouldValidate: false
    };
  },
  computed: {
    ...mapState(["isFetchingPublishingScheduleTags"]),
    ...mapGetters({
      publishingScheduleTags: GET_PUBLISHING_SCHEDULE_TAGS
    })
  },
  methods: {
    ...mapActions({
      fetchPublishingSchedulesTags: FETCH_PUBLISHING_SCHEDULE_TAGS
    }),
    adjustFormFields() {
      if (this.publishingSchedule) {
        this.form.name = this.publishingSchedule.name;
        this.form.tags = this.publishingSchedule.tags;
      } else {
        this.form = Object.assign({}, INITIAL_FORM_DATA);
      }
    },
    close() {
      this.shouldValidate = false;
      this.$refs.publishingScheduleForm.reset();
      this.$emit("close");
      this.adjustFormFields();
    },
    async submit() {
      this.shouldValidate = true;
      const isValid = await this.$refs.publishingScheduleForm.validate();
      const publishingSchedule = {
        name: this.form.name,
        tags: this.form.tags
      };
      if (isValid) {
        this.$refs.publishingScheduleForm.reset();
        this.$emit("submit", publishingSchedule);
      }
    },
    closeSelect() {
      this.$refs.select.blur();
    }
  }
};
</script>

<style scoped></style>
