<template>
  <div class="hourly-cost">
    <BaseDetails>
      <template v-slot:header>
        <h2 class="pb-3">Pay Rate</h2>
        <Permissioned section="bookings.edit">
          <EditPayRates
            permission="worker-cost"
            :allActivities="allActivities"
            :is-open="isEditOpen"
            @close="closeHourlyRateEdit"
            @edit="editPayRates"
            v-if="!isEmpty(bookingPayRates)"
          />
          <PrimaryButton
            v-if="!isLoadingPayRates"
            permission="worker-cost"
            @click.native="openHourlyRateEdit"
            light
            dense
            shrink
          >
            Edit
            <template v-slot:append:icon
              ><v-icon class="ml-1">mdi-pencil</v-icon></template
            >
          </PrimaryButton>
        </Permissioned>
      </template>
      <template v-slot:body>
        <v-skeleton-loader v-if="isLoadingPayRates" type="table" />
        <div v-else-if="!isEmpty(bookingPayRates)">
          <v-tabs v-model="selectedTab">
            <v-tab v-for="activityName in activitiesNames" :key="activityName">
              {{ activityName }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="selectedTab">
            <v-tab-item
              v-for="activityName in activitiesNames"
              :key="activityName"
            >
              <div
                v-for="(awr, index) in sortBy(
                  Object.keys(allActivities[activityName])
                ).reverse()"
                :key="index"
              >
                <div class="mt-3">
                  <span class="cost-item-title"> {{ getAwrName(awr) }} </span>
                  <div
                    class="cost-item-wrapper"
                    v-for="payRateItem in allActivities[activityName][awr]
                      .payRateItems"
                    :key="payRateItem.id"
                    :class="{
                      'with-divider': isTotalWorkerCost(payRateItem.costName)
                    }"
                  >
                    <v-divider class="divider ma-0" />
                    <div class="cost-item">
                      <div>{{ payRateItem.costName }}</div>
                      <div class="values">
                        <div
                          v-if="payRateItem.costRate"
                          class="value percentage"
                        >
                          {{ payRateItem.costRate }}
                        </div>
                        <div class="value">
                          {{
                            payRateItem.amount
                              | currency(payRateItem.currencyCode)
                          }}
                        </div>
                      </div>
                    </div>
                    <v-divider class="divider ma-0" />
                  </div>
                </div>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </template>
    </BaseDetails>
  </div>
</template>

<script>
import BaseDetails from "@/components/common/BaseDetails/BaseDetails";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import EditPayRates from "@/views/bookings/EditBooking/EditPayRates";
import Permissioned from "@/components/common/Permissioned";
import { createNamespacedHelpers } from "vuex";
import { forEach, isEmpty, lowerCase, set, sortBy } from "lodash";
import {
  BOOKINGS_NAMESPACE,
  EDIT_BOOKING_PAYRATES,
  FETCH_BOOKING_PAYRATES
} from "@/store/modules/bookings/actions";
import {
  GET_BOOKING_PAYRATES,
  IS_LOADING_BOOKING_PAYRATES
} from "@/store/modules/bookings/getters";

const { mapActions, mapGetters } = createNamespacedHelpers(BOOKINGS_NAMESPACE);

export default {
  name: "HourlyCost",
  components: { EditPayRates, PrimaryButton, BaseDetails, Permissioned },
  created() {
    this.fetchBookingPayRates({
      bookingId: this.currentBookingId
    });
  },
  data: () => ({
    isEditOpen: false,
    selectedTab: null
  }),
  methods: {
    openHourlyRateEdit() {
      this.isEditOpen = true;
    },
    closeHourlyRateEdit() {
      this.isEditOpen = false;
    },
    async editPayRates(editedPayRatesObj) {
      const editedPayRates = Object.values(editedPayRatesObj);
      try {
        if (editedPayRates.length) {
          await this.editBookingPayRates({
            bookingId: this.currentBookingId,
            editedPayRates
          });
          this.isEditOpen = false;
          if (this.timeout) clearTimeout(this.timeout);
          this.timeout = setTimeout(() => {
            this.fetchBookingPayRates({
              bookingId: this.currentBookingId
            });
          }, 100);
        } else {
          this.isEditOpen = false;
        }
        // eslint-disable-next-line no-empty
      } catch {}
    },
    isTotalWorkerCost(name) {
      return name === "Total Worker Cost";
    },
    ...mapActions({
      fetchBookingPayRates: FETCH_BOOKING_PAYRATES,
      editBookingPayRates: EDIT_BOOKING_PAYRATES
    }),
    getAwrName(awr) {
      return `${awr === "preAwr" ? "Pre" : "Post"}-AWR`;
    },
    sortBy,
    isEmpty
  },
  computed: {
    allActivities() {
      const allActivities = {};
      if (this.bookingPayRates.length) {
        forEach(this.bookingPayRates, payRate => {
          const { awr, activityTypeName } = payRate;
          set(
            allActivities,
            `${lowerCase(activityTypeName)}.${awr ? "postAwr" : "preAwr"}`,
            payRate
          );
        });
      }
      return allActivities;
    },
    activitiesNames() {
      return Object.keys(this.allActivities);
    },
    ...mapGetters({
      bookingPayRates: GET_BOOKING_PAYRATES,
      isLoadingPayRates: IS_LOADING_BOOKING_PAYRATES
    }),
    currentBookingId() {
      return this.$route.params.id;
    }
  }
};
</script>
<style lang="scss"></style>
