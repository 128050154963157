<template>
  <div class="cost-estimate">
    <BaseDetails>
      <template v-slot:header>
        <h2 class="pb-3">Pay Rate</h2>
        <Permissioned section="settings" v-if="!isLoading">
          <EditBookingChargesAction
            permission="edit-booking-templates"
            :bookingTemplate="bookingTemplate"
          />
        </Permissioned>
      </template>
      <template v-slot:body>
        <v-skeleton-loader v-if="isLoading" type="table" />
        <div v-else-if="!isEmpty(bookingTemplate.templatePayRates)">
          <v-tabs v-model="selectedTab">
            <v-tab v-for="activityName in activitiesNames" :key="activityName">
              {{ activityName }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="selectedTab">
            <v-tab-item
              v-for="activityName in activitiesNames"
              :key="activityName"
            >
              <div
                v-for="(awr, index) in sortBy(
                  Object.keys(allActivities[activityName])
                ).reverse()"
                :key="index"
              >
                <div class="mt-3">
                  <span class="cost-item-title"> {{ getAwrName(awr) }} </span>
                  <div
                    class="cost-item-wrapper"
                    v-for="templatePayRateItem in allActivities[activityName][
                      awr
                    ].templatePayRateItems"
                    :key="templatePayRateItem.id"
                    :class="{
                      'with-divider': isTotalWorkerCost(
                        templatePayRateItem.costName
                      )
                    }"
                  >
                    <v-divider class="divider ma-0" />
                    <div class="cost-item">
                      <div>{{ templatePayRateItem.costName }}</div>
                      <div class="values">
                        <div
                          v-if="templatePayRateItem.costRate"
                          class="value percentage"
                        >
                          {{ templatePayRateItem.costRate }}
                        </div>
                        <div class="value">
                          {{
                            templatePayRateItem.amount
                              | currency(templatePayRateItem.currencyCode)
                          }}
                        </div>
                      </div>
                    </div>
                    <v-divider class="divider ma-0" />
                  </div>
                </div>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </template>
    </BaseDetails>
  </div>
</template>

<script>
import BaseDetails from "@/components/common/BaseDetails/BaseDetails";
import { forEach, isEmpty, lowerCase, set, sortBy } from "lodash";
import Permissioned from "@/components/common/Permissioned";
import EditBookingChargesAction from "@/views/settings/Organisation/BookingTemplates/actions/EditBookingChargesAction";

export default {
  name: "HourlyCost",
  components: { BaseDetails, Permissioned, EditBookingChargesAction },
  props: {
    bookingTemplate: {
      type: Object,
      required: true
    },
    isLoading: Boolean
  },
  data: () => ({
    isEditOpen: false,
    selectedTab: null
  }),
  methods: {
    openHourlyRateEdit() {
      this.isEditOpen = true;
    },
    closeHourlyRateEdit() {
      this.isEditOpen = false;
    },
    isTotalWorkerCost(name) {
      return name === "Total Worker Cost";
    },
    getAwrName(awr) {
      return `${awr === "preAwr" ? "Pre" : "Post"}-AWR`;
    },
    sortBy,
    isEmpty
  },
  computed: {
    allActivities() {
      const allActivities = {};
      if (this.bookingTemplate.templatePayRates.length) {
        forEach(this.bookingTemplate.templatePayRates, payRate => {
          const { awr, payRateName } = payRate;
          set(
            allActivities,
            `${lowerCase(payRateName)}.${awr ? "postAwr" : "preAwr"}`,
            payRate
          );
        });
      }
      return allActivities;
    },
    activitiesNames() {
      return Object.keys(this.allActivities);
    },
    currentBookingId() {
      return this.$route.params.id;
    }
  }
};
</script>
<style lang="scss"></style>
