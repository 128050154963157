<template>
  <BaseDialog isOpen>
    <template v-slot:title>
      <div>
        Are you sure you want to send an invitation email to
        <span> {{ user | fullName }} </span>
      </div>
    </template>
    <template v-slot:body>
      The email will contain a link to activate the user’s account. This link is
      valid for 7 days.
    </template>
    <template v-slot:actions>
      <div>
        <PrimaryButton @click.native="close" text>
          Cancel
        </PrimaryButton>
        <PrimaryButton @click.native="handleSubmit" :loading="isLoading">
          Resend Invite
        </PrimaryButton>
      </div>
    </template>
  </BaseDialog>
</template>

<script>
import BaseDialog from "@/components/common/BaseDialog";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import { createNamespacedHelpers } from "vuex";
import { USERS_NAMESPACE } from "@/store/modules/users";
import { INVITE_USER } from "@/store/modules/users/actions";

const { mapActions } = createNamespacedHelpers(USERS_NAMESPACE);

export default {
  name: "ResendInvitationDialog",
  components: { PrimaryButton, BaseDialog },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isLoading: false
    };
  },
  methods: {
    ...mapActions({
      inviteUser: INVITE_USER
    }),
    async handleSubmit() {
      this.isLoading = true;
      let { data } = await this.inviteUser(this.user.id);
      this.close();
      this.$emit("resent", data.link);
      this.isLoading = false;
    },
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss"></style>
