<template>
  <BaseAction>
    <SecondaryButton @click.native="openDialog" color="primary">
      <inline-svg
        class="my-2"
        :src="require('@/assets/svg/check.svg')"
        width="25"
      />
      Confirm Allocation
    </SecondaryButton>
    <ConfirmCandidateAllocationDialog
      :isOpen="isOpen"
      :applicationIds="applicationIds"
      @confirmed="$emit('confirmed')"
      @close="closeDialog"
    />
  </BaseAction>
</template>

<script>
import ConfirmCandidateAllocationDialog from "@/views/bookings/components/ConfirmDialogs/ConfirmCandidateAllocationDialog";
import BaseAction from "@/components/common/BaseAction";
import SecondaryButton from "@/components/common/Button/SecondaryButton";
import InlineSvg from "vue-inline-svg";

export default {
  name: "ConfirmCandidateAllocationAction",
  components: {
    BaseAction,
    ConfirmCandidateAllocationDialog,
    SecondaryButton,
    InlineSvg
  },
  props: {
    applicationIds: Array
  },
  data() {
    return {
      isOpen: false
    };
  },
  methods: {
    openDialog() {
      this.isOpen = true;
    },
    closeDialog() {
      this.isOpen = false;
    }
  }
};
</script>
