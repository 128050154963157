<template>
  <div>
    <AgencyDetailsDialog
      :agencyDetails="agency"
      v-if="isOpen"
      :is-open="isOpen"
      @close="closeNewAgencyDialog"
      title="Edit Agency"
      actionName="Save Changes"
      @submit="onSubmit"
      :loading="isLoading"
    />
  </div>
</template>

<script>
import AgencyDetailsDialog from "@/views/agencies/dialogs/AgencyDetailsDialog";
import { createNamespacedHelpers } from "vuex";
import { EDIT_AGENCY } from "@/store/modules/agencies/action-types";
import { AGENCY_NAMESPACE } from "@/store/modules/agencies";

const { mapActions } = createNamespacedHelpers(AGENCY_NAMESPACE);

export default {
  name: "EditAgencyAction",
  components: { AgencyDetailsDialog },
  props: {
    isOpen: Boolean,
    agency: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      userDialogOpen: false,
      isLoading: false
    };
  },
  methods: {
    ...mapActions({
      editAgency: EDIT_AGENCY
    }),
    async onSubmit(data) {
      try {
        this.isLoading = true;
        await this.editAgency({ data, id: this.agency.id });
        this.closeNewAgencyDialog();
      } finally {
        this.isLoading = false;
      }
    },
    closeNewAgencyDialog() {
      this.$emit("close");
    }
  }
};
</script>

<style scoped></style>
