<template>
  <div class="filter-indicator"></div>
</template>

<script>
export default {
  name: "FilterIndicator"
};
</script>

<style scoped lang="scss"></style>
