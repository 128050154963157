<template>
  <v-dialog
    v-model="data.dialog"
    fullscreen
    hide-overlay
    scrollable
    transition="dialog-bottom-transition"
    persistent
    :content-class="noOverflow ? 'no-overflow' : ''"
  >
    <v-card class="dialog--full">
      <v-toolbar color="white">
        <div class="back-button-wrapper">
          <v-btn
            text
            :icon="$vuetify.breakpoint.smAndDown"
            @click="handleStepBack"
            v-if="showBackButton"
          >
            <v-icon>mdi-chevron-left</v-icon>
            <div v-if="!$vuetify.breakpoint.smAndDown">
              Back
            </div>
          </v-btn>
        </div>
        <v-spacer class="dialog--spacer" />
        <v-toolbar-title>{{ data.title }}</v-toolbar-title>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-progress-linear
        v-if="progress"
        color="primary"
        background-opacity="0"
        :value="progressValue"
      />
      <v-card-text ref="body" style="height: 100vh">
        <slot name="dialog.body" />
        <slot />
      </v-card-text>
      <v-card-actions v-if="!customActions">
        <slot name="dialog.action" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    /**
     *  Dialog data object
     */
    data: {
      type: Object,
      required: true
    },
    /**
     * Boolean to show progress bar
     */
    progress: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     *  If the dialog need steps
     *  Object the contain current and total steps
     * @example
     * { current: 1, total: 10 }
     */
    steps: {
      type: Object,
      required: false
    },
    customActions: {
      type: Boolean,
      required: false
    },
    noOverflow: Boolean
  },
  computed: {
    showBackButton() {
      return this.progress && this.steps.current > 1;
    },
    progressValue() {
      return (this.steps.current / this.steps.total) * 100;
    }
  },
  methods: {
    handleStepBack() {
      this.$emit("onStepBack");
    },
    close() {
      this.data.dialog = false;
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss">
.no-overflow {
  overflow-y: unset !important;
}
</style>
