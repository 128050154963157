<template>
  <BaseDialog :isOpen="isOpen">
    <template v-slot:title>
      Edit {{ plurarize("Quota", schedules.length) }}
    </template>
    <template v-slot:body>
      <ValidationObserver ref="quotaForm">
        <div v-for="schedule in schedules" :key="schedule.id">
          <div class="d-flex align-center justify-space-between">
            <div class="d-flex mr-1">
              <v-icon color="black" class="mr-1">mdi-domain</v-icon>
              <p class="mb-0">{{ schedule.agency.name }}</p>
            </div>
            <div>
              <ValidationProvider
                v-slot="{ errors }"
                :name="`promisedWorker${schedule.id}`"
                rules="minNumber:0"
              >
                <v-text-field
                  type="number"
                  label="Promised Workers"
                  placeholder="Number of workers.."
                  v-model="schedule.numberOfWorkers"
                  :error-messages="getErrorMessages(errors)"
                  @input="value => (schedule.numberOfWorkers = value)"
                />
              </ValidationProvider>
              <ValidationProvider
                v-slot="{ errors }"
                :name="`quota${schedule.id}`"
                rules="minNumber:0"
              >
                <v-text-field
                  type="number"
                  label="Quota"
                  placeholder="Worker quota.."
                  v-model="schedule.workerQuota"
                  :error-messages="getErrorMessages(errors)"
                />
              </ValidationProvider>
            </div>
          </div>

          <v-divider />
        </div>
      </ValidationObserver>
    </template>
    <template v-slot:actions>
      <PrimaryButton @click.native="close" text :disabled="isLoading">
        Cancel
      </PrimaryButton>
      <PrimaryButton @click.native="onSubmit" :loading="isLoading">
        Save Changes
      </PrimaryButton>
    </template>
  </BaseDialog>
</template>

<script>
import BaseDialog from "@/components/common/BaseDialog";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import {
  CREATE_QUOTA,
  UPDATE_QUOTA,
  DELETE_QUOTA
} from "@/store/modules/bookings/actions";
import { createNamespacedHelpers } from "vuex";
import plurarize from "pluralize";
import { map, size } from "lodash";
import { ValidationObserver, ValidationProvider } from "vee-validate";

const { mapActions } = createNamespacedHelpers("bookings");

export default {
  components: {
    PrimaryButton,
    BaseDialog,
    ValidationObserver,
    ValidationProvider
  },
  props: {
    isOpen: Boolean,
    bookingId: String,
    publishingSchedules: Array
  },
  created() {
    this.setSchedules();
  },
  data() {
    return {
      showValidationErrors: false,
      isLoading: false,
      schedules: []
    };
  },
  methods: {
    ...mapActions({
      create: CREATE_QUOTA,
      update: UPDATE_QUOTA,
      delete: DELETE_QUOTA
    }),
    close() {
      this.$emit("close");
    },
    setSchedules() {
      this.schedules = map(this.publishingSchedules, schedule => ({
        ...schedule,
        workerQuota: schedule.quotas ? schedule.quotas.workerQuota : 0,
        numberOfWorkers: schedule.quotas ? schedule.quotas.workersPromised : 0
      }));
    },
    async onSubmit() {
      this.showValidationErrors = true;
      const isValid = await this.$refs.quotaForm.validate();
      if (isValid) {
        this.showValidationErrors = false;
        try {
          this.isLoading = true;
          const bookingId = this.bookingId;
          const requests = map(this.schedules, schedule => {
            const numberOfWorkers = schedule.numberOfWorkers
              ? schedule.numberOfWorkers
              : 0;
            const workerQuota = schedule.workerQuota ? schedule.workerQuota : 0;
            if (!workerQuota && !numberOfWorkers && schedule.quotas) {
              return this.delete({
                bookingId,
                scheduleId: schedule.id,
                quotaId: schedule.quotas.id
              });
            }
            return schedule.quotas
              ? this.update({
                  scheduleId: schedule.id,
                  data: {
                    ...schedule.quotas,
                    num_workers_promised: numberOfWorkers,
                    workerQuota
                  }
                })
              : (numberOfWorkers || workerQuota) &&
                  this.create({
                    scheduleId: schedule.id,
                    data: {
                      num_workers_promised: numberOfWorkers,
                      workerQuota
                    }
                  });
          });
          await Promise.all(requests);
          size(requests) && this.$emit("update");
          this.close();
        } finally {
          this.isLoading = false;
        }
      }
    },
    getErrorMessages(errors) {
      return this.showValidationErrors ? errors : "";
    },
    plurarize
  }
};
</script>

<style lang="scss"></style>
