<template>
  <div>
    <EditAgencyAction
      @close="closeEditAgencyDialog"
      :isOpen="isEditAgencyDialogOpen"
      :agency="currentAgency"
    />
    <div class="d-flex justify-space-between mb-3">
      <h2 class="pt-1 pb-3">
        Basic Details
      </h2>
      <PrimaryButton
        light
        @click.native="handleEdit"
        v-if="canEdit"
        dense
        shrink
      >
        Edit
        <v-icon class="ml-1">mdi-pencil</v-icon>
      </PrimaryButton>
    </div>
    <h5>Name</h5>
    <p>{{ currentAgency.name }}</p>
    <h5>Company Number</h5>
    <p>{{ currentAgency.companyRegistrationNumber }}</p>
    <h5 class="mb-1">VAT Number</h5>
    <p>{{ currentAgency.vatNumber }}</p>
    <h5>Address</h5>
    <div
      class="addresses"
      v-for="(address, index) in currentAgency.addresses"
      :key="index"
    >
      <p>{{ address.line1 }}</p>
      <p>{{ address.line2 }}</p>
      <p>{{ address.line3 }}</p>
      <p>{{ address.postCode }}</p>
      <p>{{ address.country }}</p>
    </div>

    <h5>Publishing Schedules</h5>
    <div class="d-flex mt-2 mb-3">
      <Chip
        v-for="(publishingSchedule, index) in publishingSchedules"
        :key="index"
        class="chip--status mr-2"
        >{{ publishingSchedule.tier_name }}</Chip
      >
    </div>
  </div>
</template>

<script>
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import { isPermissioned } from "@/utils/permissions";
import Chip from "@/components/common/Chip";
import EditAgencyAction from "@/views/agencies/actions/EditAgencyAction";

export default {
  props: {
    currentAgency: { type: Object, required: true }
  },
  components: { PrimaryButton, Chip, EditAgencyAction },
  data() {
    return {
      isEditAgencyDialogOpen: false
    };
  },
  computed: {
    canEdit() {
      return isPermissioned(`agencies[${this.currentAgency.id}].edit`);
    },
    publishingSchedules() {
      return this.currentAgency["publishing-schedule-tiers"];
    }
  },
  methods: {
    handleEdit() {
      this.isEditAgencyDialogOpen = true;
    },
    closeEditAgencyDialog() {
      this.isEditAgencyDialogOpen = false;
    }
  }
};
</script>

<style lang="scss" scoped></style>
