import { USER_MODEL } from "@/models/user-model";

export const BOOKING_APPROVER_MODEL = "booking-approver";

const bookingApproverModel = {
  levelNbr: 0,
  approvalDate: null,
  user: {
    jsonApi: "hasOne",
    type: USER_MODEL
  }
};

export default bookingApproverModel;
