<template>
  <div>
    <div
      :class="{ 'error-text': isExceeding(schedule) }"
      v-for="(schedule, key) in filteredSchedules"
      :key="key"
    >
      <p class="secondary-text" v-if="!schedule.workerQuota">
        This shift doesn't have worker quota ({{ title }} |
        {{ startDate | shiftHours }}-{{ endDate | shiftHours }}).
      </p>
      <div v-else class="d-flex align-center my-1">
        <p class="mb-0 ml-1">
          {{ schedule.confirmed }} of
          {{ schedule.workerQuota }}
        </p>
        <inline-svg
          class="mx-2"
          :src="require('@/assets/svg/user-icon.svg')"
          width="20"
        />
        <LinearProgress
          :color="getColorByPercentage(quotaProgress(schedule))"
          :value="quotaProgress(schedule)"
        />
        <inline-svg
          class="mx-2"
          :src="require('@/assets/svg/shift-icon-black.svg')"
          width="20"
        />
        <p
          class="secondary-text mb-0 mr-1"
          :class="{ 'error-text': isExceeding(schedule) }"
        >
          {{ title }}
        </p>
        <p
          class="secondary-text mb-0"
          :class="{ 'error-text': isExceeding(schedule) }"
        >
          | {{ startDate | shiftHours }}-{{ endDate | shiftHours }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getColorByPercentage,
  getSchedulesWithConfirmedWorkersCount
} from "@/utils/shifts";
import { createNamespacedHelpers } from "vuex";
import { isEmpty, filter, find, map } from "lodash";
import InlineSvg from "vue-inline-svg";
import LinearProgress from "@/components/common/LinearProgress";
import { isMSPOrSuperAdmin } from "@/utils/users";

const { mapState } = createNamespacedHelpers("auth");

export default {
  props: {
    shift: Object,
    workerSize: Number
  },
  components: {
    InlineSvg,
    LinearProgress
  },
  computed: {
    ...mapState({
      user: state => state.userData
    }),
    schedules() {
      const { applicationShifts } = this.shift;
      return (
        this.booking &&
        applicationShifts &&
        getSchedulesWithConfirmedWorkersCount({
          schedules: this.booking.publishingSchedules,
          applicationShifts,
          shiftQuotas: this.shift.quotas
        })
      );
    },
    booking() {
      return this.shift.booking;
    },
    bookingTitle() {
      return this.booking && this.booking.title;
    },
    activityName() {
      return `${this.shift.pattern &&
        !isEmpty(this.shift.pattern.payRates) &&
        find(this.shift.pattern.payRates, { isDefault: true })
          .activityTypeName}`;
    },
    title() {
      const activityName = this.activityName ? `| ${this.activityName}` : "";
      return `${this.bookingTitle} ${activityName}`;
    },
    endDate() {
      return this.shift.endDate;
    },
    startDate() {
      return this.shift.startDate;
    },
    userAgencyId() {
      return this.user.organisation && this.user.organisation.id;
    },
    isMSPAdmin() {
      return isMSPOrSuperAdmin(this.user.roles);
    },
    filteredSchedules() {
      return this.isMSPAdmin
        ? this.schedules
        : filter(
            this.schedules,
            ({ agency }) => Number(agency.id) === Number(this.userAgencyId)
          );
    }
  },
  methods: {
    getColorByPercentage,
    quotaProgress({ confirmed, workerQuota }) {
      return (confirmed / workerQuota) * 100;
    },
    isExceeding({ confirmed, workerQuota }) {
      return this.workerSize > workerQuota - confirmed;
    }
  },
  watch: {
    workerSize() {
      map(this.filteredSchedules, schedule => {
        this.$emit("count", this.isExceeding(schedule));
      });
    }
  }
};
</script>
