export const EXPENSE_MODEL = "expense";
import { FILE } from "@/models/relationships";

const expenseModel = {
  currencyCode: "",
  description: "",
  expenseAmount: 0,
  createdAt: "",
  updatedAt: "",
  timesheet: {
    jsonApi: "hasOne",
    type: "timesheets"
  },
  file: {
    jsonApi: "hasOne",
    type: FILE
  }
};

export default expenseModel;
