<template>
  <BaseAction>
    <AllocationsDialog
      :dialogSettings="dialogSettings"
      @close="closeDialog"
      @submit="onConfirmAllocations"
      @onSelectApplicationIds="updateSelectedApplicationIds"
      :shifts="shiftsToDisplay"
      :workersNotCompleted="workerComplianceChecksNotCompleted"
      :isLoading="isLoading"
      :isV2="isV2"
    />
    <SecondaryButton @click.native="openDialog" color="primary">
      <inline-svg
        class="my-2"
        :src="require('@/assets/svg/check.svg')"
        width="25"
      />
      Confirm Allocations
    </SecondaryButton>
  </BaseAction>
</template>

<script>
import AllocationsDialog from "@/views/bookings/components/ConfirmDialogs/AllocationsDialog";
import { UPDATE_APPLICATION_SHIFTS_STATUS } from "@/store/modules/applications/actions";
import { createNamespacedHelpers } from "vuex";
import { APPLICATION_NAMESPACE } from "@/store/modules/applications";
import { BOOKINGS_NAMESPACE } from "@/store/modules/bookings/actions";
import {
  getShiftsWithPermittedApplications,
  getShiftsWithPermittedApplicationsV2,
  getPermittedApplicationShiftsIds,
  getPermittedApplicationShiftsIdsV2
} from "@/utils/shifts";
import { SHIFT_PERMISSIONS_STATUSES, SHIFT_STATUSES } from "@/constants/shifts";
import { size, first, isEmpty, flatMap, find, forEach } from "lodash";
import SecondaryButton from "@/components/common/Button/SecondaryButton";
import InlineSvg from "vue-inline-svg";
import BaseAction from "@/components/common/BaseAction";
import { missingComplianceChecks } from "@/utils/applications";

const { mapActions } = createNamespacedHelpers(APPLICATION_NAMESPACE);
const { mapState } = createNamespacedHelpers(BOOKINGS_NAMESPACE);

export default {
  name: "ConfirmAllocationsAction",
  components: { AllocationsDialog, SecondaryButton, InlineSvg, BaseAction },
  props: {
    shifts: Array,
    isV2: Boolean
  },
  data() {
    return {
      isLoading: false,
      isOpen: false,
      selectedApplicationIds: []
    };
  },
  computed: {
    ...mapState({
      currentBooking: state => state.currentBooking
    }),
    dialogSettings() {
      return {
        title: "Confirm Allocations",
        message: this.allShifts
          ? "Are you sure you want to confirm all shifts allocations?"
          : "Are you sure you want to confirm the following shifts allocations?",
        dialog: this.isOpen
      };
    },
    shiftsToDisplay() {
      return first(this.shifts) === "all"
        ? []
        : this.isV2
        ? getShiftsWithPermittedApplicationsV2(
            this.shifts,
            SHIFT_PERMISSIONS_STATUSES.CONFIRM
          )
        : getShiftsWithPermittedApplications(
            this.shifts,
            SHIFT_PERMISSIONS_STATUSES.CONFIRM
          );
    },
    workerComplianceChecksNotCompleted() {
      const workers = flatMap(this.shiftsToDisplay, "workers");
      const bookingComplianceChecks = this.currentBooking
        .complianceRequirements;

      let workerNotCompleted = [];

      forEach(workers, worker => {
        let notCompletedComplianceChecks = [];
        let application = find(
          this.currentBooking.applications,
          application => application.id == worker.userApplicationId
        );
        notCompletedComplianceChecks = missingComplianceChecks(
          bookingComplianceChecks,
          application?.complianceChecks || []
        );

        if (
          this.currentBooking.yearsReferencesRequired &&
          worker.yearsReferenced < this.currentBooking.yearsReferencesRequired
        ) {
          notCompletedComplianceChecks.push(
            this.currentBooking.yearsReferencesRequired + " years reference"
          );
        }

        if (notCompletedComplianceChecks.length) {
          workerNotCompleted.push({
            worker: `${worker.firstName} ${worker.lastName}`,
            notCompletedCompliance: notCompletedComplianceChecks
          });
        }
      });
      return workerNotCompleted;
    },
    allShifts() {
      return isEmpty(this.shifts);
    }
  },
  methods: {
    ...mapActions({
      updateApplicationShiftsStatus: UPDATE_APPLICATION_SHIFTS_STATUS
    }),
    openDialog() {
      this.isOpen = true;
    },
    closeDialog() {
      this.isOpen = false;
    },
    updateSelectedApplicationIds(selectedApplicationIds) {
      this.selectedApplicationIds = selectedApplicationIds;
    },
    async onConfirmAllocations() {
      this.isLoading = true;
      try {
        let applicationShifts = [];

        if (!this.allShifts) {
          applicationShifts = size(this.selectedApplicationIds)
            ? this.selectedApplicationIds
            : this.isV2
            ? getPermittedApplicationShiftsIdsV2({
                shifts: this.shifts,
                permission: SHIFT_PERMISSIONS_STATUSES.CONFIRM
              })
            : getPermittedApplicationShiftsIds({
                shifts: this.shifts,
                permission: SHIFT_PERMISSIONS_STATUSES.CONFIRM
              });
        }

        await this.updateApplicationShiftsStatus({
          applicationShifts,
          status: SHIFT_STATUSES.CONFIRMED,
          all: this.allShifts,
          booking: this.allShifts ? this.currentBooking : null
        });
        this.closeDialog();
        this.$emit("updateList");
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style lang="scss"></style>
