/* eslint-disable no-unexpected-multiline */
import JSONApiService from "@/services/JSONApiService";
import {
  REPORT_MODEL,
  REPORT_CHART_MODEL,
  REPORT_SHARED_WITH_USER_MODEL
} from "@/models/report-model";
import { include } from "@/models/relationships";
import APIService, { withPrefix } from "@/services/APIService";

const reportsApi = ({ commit } = {}) => ({
  getReports: async () =>
    JSONApiService(commit).findAll(REPORT_MODEL, {
      include: "filters,groupings,fields,charts"
    }),
  getReport: async (id, params) =>
    JSONApiService(commit)
      .one(REPORT_MODEL, id)
      .get(params),
  getReportCharts: async params =>
    JSONApiService(commit)
      .all(REPORT_CHART_MODEL)
      .get(params),
  createReportChart: async data =>
    JSONApiService(commit)
      .all(REPORT_CHART_MODEL)
      .post(data),
  editReportChart: async (id, data) =>
    JSONApiService(commit)
      .one(REPORT_CHART_MODEL, id)
      .patch(data),
  getReportsSharedWithUsers: async filter =>
    JSONApiService(commit)
      .all(REPORT_SHARED_WITH_USER_MODEL)
      .get({ filter, ...include("user", "report") }),
  createReportsSharedWithUsers: async data =>
    JSONApiService(commit)
      .all(REPORT_SHARED_WITH_USER_MODEL)
      .post(data),
  editReportsSharedWithUsers: async data =>
    JSONApiService(commit)
      .one(REPORT_SHARED_WITH_USER_MODEL, data.id)
      .patch(data),
  deleteReportsSharedWithUsers: async id =>
    JSONApiService(commit)
      .one(REPORT_SHARED_WITH_USER_MODEL, id)
      .destroy(),
  createReport: async type =>
    JSONApiService(commit).create(REPORT_MODEL, { focus: type }),
  updateSingleReport: async data =>
    JSONApiService(commit)
      .one(REPORT_MODEL, data.id)
      .patch(data),
  deleteReport: async id => JSONApiService(commit).destroy(REPORT_MODEL, id),
  updateReport: async (previousReport, newReportData) => {
    const api = JSONApiService(commit);
    try {
      const updates = [
        api.update(
          REPORT_MODEL,
          {
            id: newReportData.id,
            name: newReportData.name,
            status: newReportData.status
          },
          include("charts")
        )
      ];
      const {
        newItems: newFields,
        changedItems: changedFields,
        removedIds: removedFields
      } = analyzeItems(previousReport.fields, newReportData.fields);
      const {
        newItems: newFilters,
        changedItems: changedFilters,
        removedIds: removedFilters
      } = analyzeItems(previousReport.filters, newReportData.filters);
      const {
        newItems: newGroupings,
        changedItems: changedGroupings,
        removedIds: removedGroupings
      } = analyzeItems(previousReport.groupings, newReportData.groupings);

      const reportId = previousReport.id;
      updates.push(
        ...postThings(newFields, reportId, "fields"),
        ...postThings(newFilters, reportId, "filters"),
        ...postThings(newGroupings, reportId, "groupings"),
        ...patchThings(changedFields, reportId, "fields"),
        ...patchThings(changedFilters, reportId, "filters"),
        ...patchThings(changedGroupings, reportId, "groupings"),
        ...deleteThings(removedFields, reportId, "fields"),
        ...deleteThings(removedFilters, reportId, "filters"),
        ...deleteThings(removedGroupings, reportId, "groupings")
      );

      return await Promise.all(updates);
    } catch (error) {
      console.error(error);
    }
  },
  getReportData: getData
});

const analyzeItems = (prevArray, nextArray) => {
  const newItems = [];
  const changedItems = [];
  const removedIds = [];
  prevArray.forEach(prevItem => {
    if (!nextArray.find(nextItem => nextItem.id === prevItem.id)) {
      removedIds.push(prevItem.id);
    }
  });
  nextArray.forEach(nextItem => {
    if (!nextItem.id) {
      newItems.push(nextItem);
    } else {
      changedItems.push(nextItem);
    }
  });
  return { newItems, changedItems, removedIds };
};

const API = withPrefix(APIService.client(), "focused-reports");

const postThings = (things, reportId, type) =>
  things.map(rawData => {
    const data = { ...rawData };
    // backend does not accept nulls on POST (as opposed to PATCH),
    // this is better than writing double logic in the component to handle
    // new and existing objects differently
    Object.keys(data).forEach(key => {
      if (data[key] === null) {
        delete data[key];
      }
    });
    return API.post(`${reportId}/${type}`, JSON.stringify(data));
  });

const patchThings = (things, reportId, type) =>
  things.map(({ id, ...data }) =>
    API.patch(`${reportId}/${type}/${id}`, JSON.stringify(data))
  );

const deleteThings = (ids, reportId, type) =>
  ids.map(id => API.delete(`${reportId}/${type}/${id}`));

const getData = async reportId => {
  return await API.get(`${reportId}/output`);
};
export default reportsApi;
