import api from "@/services/modules/addresses";
import { setStoreValue } from "@/store/utils";
import {
  SET_NEW_ADDRESS,
  SET_ADDRESS,
  SET_ADDRESSES,
  SET_IS_FETCHING_DATA
} from "./mutation-types";
import {
  ADD_NEW_ADDRESS,
  GET_ALL_ADDRESSES,
  GET_ADDRESS,
  UPDATE_ADDRESS
} from "./action-types";
import { errorHandler, handleWithErrorMessage } from "@/services/utils";
import { isMockedDataEnabled } from "@/utils/mocked-data";
import { MOCKED_ADDRESSES_STATE } from "@/constants/addresses";

const state = {
  addresses: [],
  newAddress: {},
  currentAddress: {},
  isFetchingData: false
};

const getters = {};

const actions = {
  async [ADD_NEW_ADDRESS]({ commit, dispatch }, newAddress) {
    try {
      const { data } = await api({ commit }).addNewAddress(newAddress);
      commit(SET_NEW_ADDRESS, data);
      return data.id;
    } catch (exception) {
      errorHandler(dispatch, exception);
    }
  },
  async [GET_ALL_ADDRESSES]({ commit, dispatch }) {
    commit(SET_IS_FETCHING_DATA, true);
    try {
      const { data } = await api({ commit }).getAllAddresses();
      commit(SET_ADDRESSES, data);
      commit(SET_IS_FETCHING_DATA, false);
    } catch (exception) {
      errorHandler(dispatch, exception);
    }
  },
  async [GET_ADDRESS]({ commit, dispatch }, id) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api().getAddress(id),
      setDataFunction: ({ data }) => commit(SET_ADDRESS, data)
    });
  },
  async [UPDATE_ADDRESS]({ commit, dispatch }, updatedAddress) {
    try {
      const { data } = await api({ commit }).updateAddress(updatedAddress);
      commit(SET_ADDRESS, data);
    } catch (exception) {
      errorHandler(dispatch, exception);
    }
  }
};

const mutations = {
  [SET_NEW_ADDRESS](state, address) {
    state.addresses.push(address);
  },
  [SET_ADDRESSES]: setStoreValue("addresses"),
  [SET_ADDRESS]: setStoreValue("currentAddress"),
  [SET_IS_FETCHING_DATA]: setStoreValue("isFetchingData")
};

export default {
  namespaced: true,
  state: isMockedDataEnabled() ? MOCKED_ADDRESSES_STATE : state,
  getters,
  actions: isMockedDataEnabled() ? {} : actions,
  mutations: isMockedDataEnabled() ? {} : mutations
};
