<template>
  <BaseDialog :isOpen="isOpen" @updateDialog="cancel">
    <template v-slot:title>
      <span>Are you sure you want to withdraw the candidate?</span>
    </template>
    <template v-slot:body>
      <div class="withdraw-candidate-description">
        Withdrawing the candidate will mean they are no longer able to be
        assessed or allocated to shifts.
      </div>
    </template>
    <template v-slot:actions>
      <div class="withdraw-candidate-actions">
        <v-btn color="primary cancel-button" @click="cancel" text>
          Cancel
        </v-btn>
        <ErrorButton light @click.native="handleClick" :loading="isLoading">
          Withdraw {{ pluralize("Candidate", size(applicationIds)) }}
        </ErrorButton>
      </div>
    </template>
  </BaseDialog>
</template>

<script>
import BaseDialog from "@/components/common/BaseDialog";
import ErrorButton from "@/components/common/Button/ErrorButton";
import { WITHDRAW_CANDIDATE } from "@/store/modules/applications/actions";
import { createNamespacedHelpers } from "vuex";
import { APPLICATION_NAMESPACE } from "@/store/modules/applications";
import pluralize from "pluralize";
import { size, map } from "lodash";
import { POST_GLOBAL_MESSAGE } from "@/store/modules/global/action-types";
import { mapActions } from "vuex";

const { mapActions: mapApplicationActions } = createNamespacedHelpers(
  APPLICATION_NAMESPACE
);

export default {
  name: "WithdrawCandidateDialog",
  components: { ErrorButton, BaseDialog },
  props: {
    isOpen: Boolean,
    applicationIds: Array
  },
  data() {
    return {
      isLoading: false
    };
  },
  methods: {
    ...mapActions({
      postGlobalMessage: POST_GLOBAL_MESSAGE
    }),
    ...mapApplicationActions({
      withdrawCandidate: WITHDRAW_CANDIDATE
    }),
    async handleClick() {
      this.isLoading = true;
      const requests = map(this.applicationIds, applicationId =>
        this.withdrawCandidate({ applicationId })
      );
      await Promise.all(requests);
      this.$emit("close", { success: true });
      this.isLoading = false;
    },
    postSuccesMessage(applicationNumber) {
      const message = {
        snackbar: true,
        type: "success",
        title: `You have withdrawn the ${pluralize(
          "application",
          applicationNumber
        )}`
      };
      this.postGlobalMessage(message);
    },
    cancel() {
      this.$emit("close", { success: false });
    },
    pluralize,
    size
  }
};
</script>

<style lang="scss">
.withdraw-candidate-description {
  min-height: 100px;
}
.withdraw-candidate-actions {
  padding: 0.5rem;

  .v-btn {
    span {
      text-transform: none;
      font-weight: bold;
    }

    &.withdraw-candidate-button {
      color: white;
      height: 50px;
    }
  }
}
</style>
