var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"compliance-details"},[(_vm.isDBS)?_c('DBSDetails',{attrs:{"compliance":_vm.compliance}}):_vm._e(),(_vm.isReferences)?_c('ReferencesDetails',{attrs:{"references":_vm.references}}):_vm._e(),(_vm.isRTW)?_c('RightToWorkDetails',{attrs:{"compliance":_vm.compliance}}):_vm._e(),(_vm.isHCPC)?_c('HCPCDetails',{attrs:{"label":"Registration Number","compliance":_vm.compliance}}):_vm._e(),(_vm.isQTS)?_c('QTSDetails',{attrs:{"label":"Qualification Number","compliance":_vm.compliance}}):_vm._e(),(
      _vm.compliance.documentValidFromDate &&
        _vm.isNotGroupedDetails &&
        _vm.compliance.checkVerified
    )?_c('CompletionDateDate',{attrs:{"label":_vm.isFTF || _vm.isBL ? 'Date Conducted' : 'Completion Date',"compliance":_vm.compliance}}):_vm._e(),(
      _vm.compliance.documentValidToDate &&
        _vm.isNotGroupedDetails &&
        _vm.compliance.checkVerified
    )?_c('ExpiryDate',{attrs:{"label":"Expiry Date","compliance":_vm.compliance}}):_vm._e(),(_vm.compliance.checkVerified)?_c('div',[_vm._l((_vm.files),function(file){return _c('FileComponent',{key:file.id,attrs:{"file":file,"disabled":"","customActions":""},on:{"show":_vm.onShow}})}),_c('PDFViewFull',{attrs:{"data":_vm.fileDialog}})],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }