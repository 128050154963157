<template>
  <div class="container mt-10">
    <h2 class="mb-5">Booking Charges</h2>
    <div>
      <PayRate
        v-for="(payRate, index) in templatePayRates"
        :key="payRate.id"
        ref="payRateComponent"
        :showValidationErrors="showValidationErrors"
        :canRemove="templatePayRates.length > 1"
        :index="index"
        :payRateData="payRate"
        class="mb-5"
        @remove="() => onRemovePayRate(index)"
      />
    </div>
    <PrimaryButton fullWidth light @click.native="addPayRate" class="mt-3">
      Add Pay Rate +
    </PrimaryButton>
    <h3 class="my-5">Costs</h3>
    <div v-if="isFetchingCostCategories">
      <v-skeleton-loader v-for="i in 6" :key="i" type="list-item" />
    </div>
    <div v-else>
      <Cost
        ref="costComponent"
        v-for="(cost, index) in costs"
        :key="cost.id"
        :showValidationErrors="showValidationErrors"
        :costCategories="costCategories"
        :cost="cost"
        class="mb-5"
        @remove="() => onRemoveCost(index)"
      />
    </div>
    <!-- TODO: Remove when not needed in the future
    <PrimaryButton
      fullWidth
      light
      @click.native="triggerCostList"
      v-if="!isFetchingCostCategories"
    >
      Add Cost
      <template v-slot:append:icon>
        <v-icon size="x-large">{{ addCostArrowIcon }}</v-icon>
      </template>
    </PrimaryButton>
    <v-list dark color="#475060" v-if="showCostList">
      <v-list-item-group>
        <v-list-item
          v-for="(cost, index) in fetchedCostCategories"
          :key="index"
        >
          <v-list-item-content>
            <v-list-item-title @click="addCost(cost)">
              {{ cost.displayName }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list> -->
  </div>
</template>

<script>
import PayRate from "@/views/settings/Organisation/BookingTemplates/components/PayRate";
import Cost from "@/views/settings/Organisation/BookingTemplates/components/Cost";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import { every, map, merge } from "lodash";
import { createNamespacedHelpers } from "vuex";
import { DATA_NAMESPACE } from "@/store/modules/data";
import { FETCH_COST_CATEGORIES } from "@/store/modules/data/actions";
import {
  GET_COST_CATEGORIES,
  GET_IS_FETCHING_COST_CATEGORIES
} from "@/store/modules/data/getters";
import {
  getMergedPayRates,
  getMergedCosts,
  getSplittedPayRates,
  getSplittedCosts
} from "@/views/settings/Organisation/BookingTemplates/utils/utils.js";
import generateRandomId from "uuid/v4";

const { mapActions, mapGetters } = createNamespacedHelpers(DATA_NAMESPACE);

const INITIAL_PAY_RATE = {
  currencyCode: "GBP",
  activityTypeName: "",
  preAwr: null,
  postAwr: null
};

export default {
  name: "BookingCharges",
  components: {
    PrimaryButton,
    PayRate,
    Cost
  },
  props: {
    bookingTemplate: Object
  },
  data() {
    return {
      showValidationErrors: false,
      initialPayRate: { ...INITIAL_PAY_RATE },
      templatePayRates: [{ ...INITIAL_PAY_RATE }],
      showCostList: false,
      costCategories: [],
      costs: []
    };
  },
  async created() {
    if (this.bookingTemplate) {
      this.bookingTemplateData = merge({}, this.bookingTemplate);
      this.adjustExistingPayRates();
      this.adjustExistingCosts();
    }
    const { data: costCategories } = await this.fetchCostCategories();
    this.costCategories = costCategories;
    if (!this.bookingTemplate) {
      this.costs = map(costCategories, ({ name }) => ({
        id: generateRandomId(),
        costCategoryName: name
      }));
    }
  },
  computed: {
    ...mapGetters({
      fetchedCostCategories: GET_COST_CATEGORIES,
      isFetchingCostCategories: GET_IS_FETCHING_COST_CATEGORIES
    }),
    addCostArrowIcon() {
      return `mdi-chevron-${this.showCostList ? "up" : "down"}`;
    }
  },
  methods: {
    ...mapActions({
      fetchCostCategories: FETCH_COST_CATEGORIES
    }),
    addCost(item) {
      this.costs.push({ costCategoryName: item.name, id: generateRandomId() });
      this.triggerCostList();
    },
    triggerCostList() {
      this.showCostList = !this.showCostList;
    },
    onRemovePayRate(index) {
      this.templatePayRates.splice(index, 1);
    },
    onRemoveCost(index) {
      this.costs.splice(index, 1);
    },
    async arePayRateComponentsValid() {
      const payRateValidations = map(
        this.$refs.payRateComponent,
        payRateComponent => payRateComponent.validate()
      );
      const validationStatuses = await Promise.all(payRateValidations);
      return this.isEveryStatusValid(validationStatuses);
    },
    areCostComponentsValid() {
      const validationStatuses = map(this.$refs.costComponent, costComponent =>
        costComponent.validate()
      );
      return this.isEveryStatusValid(validationStatuses);
    },
    isEveryStatusValid(statuses) {
      return every(statuses, status => status);
    },
    async addPayRate() {
      const isValid = await this.arePayRateComponentsValid();
      if (isValid) {
        this.templatePayRates = [
          ...this.templatePayRates,
          { ...this.initialPayRate, id: generateRandomId() }
        ];
      }
    },
    updateField(key, val) {
      this.bookingTemplateData[key] = val;
    },
    close() {
      this.$emit("close");
    },
    getAllPayrates() {
      const mergedPayRates = map(
        this.$refs.payRateComponent,
        payRateComponent => payRateComponent.getPayRate()
      );
      return getSplittedPayRates(mergedPayRates);
    },
    getAllCosts() {
      const mergedCosts = map(this.$refs.costComponent, costComponent =>
        costComponent.getCost()
      );
      return getSplittedCosts(mergedCosts);
    },
    async validateForm() {
      this.showValidationErrors = true;
      const areCostsValid = this.areCostComponentsValid();
      const arePayRatesValid = await this.arePayRateComponentsValid();
      const isValid = areCostsValid && arePayRatesValid;
      if (isValid && !this.isFetchingCostCategories) {
        const payRates = this.getAllPayrates();
        const costs = this.getAllCosts();
        this.$emit("onSubmit", { payRates, costs });
        this.showValidationErrors = true;
      }
    },
    adjustExistingPayRates() {
      this.templatePayRates = getMergedPayRates(
        this.bookingTemplateData.templatePayRates
      );
      delete this.bookingTemplateData.templatePayRates;
    },
    adjustExistingCosts() {
      this.costs = getMergedCosts(this.bookingTemplateData.templateCosts);
      delete this.bookingTemplateData.templateCosts;
    }
  }
};
</script>

<style lang="scss"></style>
