<template>
  <div>
    <div class="container col-lg-8 col-sm-12">
      <div v-if="isFetchingReports">
        <v-skeleton-loader v-for="i in 10" :key="i" type="list-item" />
      </div>
      <Permissioned section="reports" vertical v-else>
        <ReportListItem
          v-for="report in reports"
          :key="report.id"
          :report="report"
        />
      </Permissioned>
    </div>
  </div>
</template>

<script>
import Permissioned from "@/components/common/Permissioned";
import ReportListItem from "@/components/reports/ReportListItem";
import { createNamespacedHelpers } from "vuex";
import { REPORTS_NAMESPACE } from "@/store/modules/reports";
import { FETCH_REPORTS } from "@/store/modules/reports/action-types";

const { mapState, mapActions } = createNamespacedHelpers(REPORTS_NAMESPACE);

export default {
  name: "Reports",
  components: {
    ReportListItem,
    Permissioned
  },
  created() {
    this.fetchReports();
  },
  computed: {
    ...mapState({
      reports: state => state.reports,
      isFetchingReports: state => state.isFetchingReports
    })
  },
  methods: {
    ...mapActions({
      fetchReports: FETCH_REPORTS
    })
  }
};
</script>

<style lang="scss"></style>
