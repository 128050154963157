<template>
  <BaseAction>
    <PrimaryButton
      light
      dense
      shrink
      @click.native="openDialog"
      :disabled="isRemoving"
    >
      Edit
      <template v-slot:append:icon>
        <v-icon class="ml-1">mdi-pencil</v-icon>
      </template>
    </PrimaryButton>
    <ExpenseDialog
      v-if="isOpen"
      :expense="expense"
      :isOpen="isOpen"
      :title="title"
      @close="closeDialog"
      actionName="Save Changes"
    />
  </BaseAction>
</template>

<script>
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import ExpenseDialog from "@/views/timesheets/components/dialogs/ExpenseDialog";
import BaseAction from "@/components/common/BaseAction";

export default {
  name: "EditExpenseAction",
  components: {
    PrimaryButton,
    ExpenseDialog,
    BaseAction
  },
  data() {
    return {
      isOpen: false,
      title: "Edit Expense"
    };
  },
  props: {
    expense: Object,
    isRemoving: Boolean
  },
  methods: {
    openDialog() {
      this.isOpen = true;
    },
    closeDialog() {
      this.isOpen = false;
    }
  }
};
</script>
