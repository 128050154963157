<template>
  <div class="organisation-list">
    <div class="toolbar">
      <BaseSearchBar @search="text => (searchText = text)" />
    </div>
    <div v-if="isFetchingUnits">
      <v-skeleton-loader type="list-item" v-for="i in 5" :key="i" />
    </div>
    <div v-else>
      <OrganisationUnitItem
        :toggledByFilter="Boolean(searchText)"
        v-for="unit in filteredUnits"
        :key="unit.id"
        :unit="unit"
      />
    </div>
  </div>
</template>

<script>
import BaseSearchBar from "@/components/common/BaseSearchBar";
import { ORGANISATION_UNITS_NAMESPACE } from "@/store/modules/organisations/organisation-units";
import { copyState } from "@/utils/helpers";
import { createNamespacedHelpers } from "vuex";
import { FETCH_ORGANISATION_UNITS } from "@/store/modules/organisations/organisation-units/actions";
import OrganisationUnitItem from "@/views/settings/Organisation/Units/OrganisationUnitItem";
import {
  filterDeep,
  getOrganisationsAsTreeStructure
} from "@/utils/organisation-units";

const {
  mapActions: mapOrganisationUnitsActions,
  mapState: mapOrganisationUnitsState
} = createNamespacedHelpers(ORGANISATION_UNITS_NAMESPACE);

export default {
  name: "OrganisationUnitsList",
  components: { OrganisationUnitItem, BaseSearchBar },
  data() {
    return {
      searchText: ""
    };
  },
  created() {
    this.fetchOrganisationUnits();
  },
  computed: {
    ...mapOrganisationUnitsState(
      copyState(["organisationUnits", "isFetchingUnits"])
    ),
    filteredUnits() {
      const nestedOrganisationUnits = getOrganisationsAsTreeStructure(
        this.organisationUnits
      );
      return filterDeep(nestedOrganisationUnits, this.searchText);
    }
  },
  methods: {
    ...mapOrganisationUnitsActions({
      fetchOrganisationUnits: FETCH_ORGANISATION_UNITS
    })
  }
};
</script>

<style scoped></style>
