<template>
  <div class="shift-details py-2 px-2 multiple-select-list">
    <div
      class="d-flex multiselect-wrapper align-center"
      @click="$emit('select')"
    >
      <v-checkbox
        class="checkbox-large-icon mr-4"
        v-if="showCheckBox"
        :input-value="isSelected"
      />
      <div class="icon-wrapper" v-else>
        <v-icon>mdi-calendar-range-outline</v-icon>
      </div>
      <div class="d-flex flex-column">
        <h3>{{ startDate | shiftDate }}</h3>
        <p class="secondary-text mb-0">
          {{ startDate | shiftHours }} - {{ endDate | shiftHours }} |
          {{ `S-${shift.id}` }} |
          {{
            `${shift.workersRequired} ${pluralize(
              "Worker",
              shift.workersRequired
            )} required`
          }}
          |
          {{ activity }}
        </p>
      </div>
    </div>
    <div>
      <div
        class="d-flex px-2 py-2 justify-space-between full-with align-center"
        v-for="shift in approvedShifts"
        :key="shift.id"
      >
        <div class="d-flex align-center">
          <div
            class="icon-rounded mr-2"
            :style="{
              backgroundColor:
                styles[`${getColorByStatus(shift.status)}Background`]
            }"
          >
            <v-icon color="black">mdi-account-outline</v-icon>
          </div>
          <p class="mb-0">{{ applicationUser(shift) | fullName }}</p>
        </div>
        <Chip shrink :status="shift.status">
          {{ shift.status }}
        </Chip>
      </div>
      <div
        class="d-flex px-2 ml-1 mb-2 justify-space-between"
        v-for="key in unallocatedShifts"
        :key="key"
      >
        <div
          class="icon-rounded mr-2 empty-shift"
          :style="{ width: '32px', height: '32px' }"
        ></div>
        <div class="empty-shift full-width"></div>
      </div>
      <div>
        <div class="expanding-button pl-2" @click="expanded = !expanded">
          <v-icon color="black">
            {{ `mdi-chevron-${expanded ? "down" : "right"}` }}
          </v-icon>
          <strong>Submitted workers</strong>
        </div>
        <div class="d-flex flex-wrap" v-if="expanded">
          <p class="secondary-text pl-6" v-if="!size(submittedShifts)">
            No workers have been submitted
          </p>
          <div
            class="d-flex py-2 pl-2 mr-2 align-center shrink"
            v-for="shift in submittedShifts"
            :key="shift.id"
          >
            <div
              class="icon-rounded mr-2"
              :style="{
                backgroundColor: styles[`grayBackground`]
              }"
            >
              <v-icon color="black">mdi-account-outline</v-icon>
            </div>
            <p class="mb-0">{{ applicationUser(shift) | fullName }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import pluralize from "pluralize";
import { filter, size } from "lodash";
import Chip from "@/components/common/Chip";
import { getColorByStatus } from "@/utils/bookings";
import styles from "@/sass/abstracts/_chips-colors.scss";

const { mapState } = createNamespacedHelpers("auth");

export default {
  props: {
    shift: {
      type: Object,
      required: true
    },
    status: {
      type: String,
      required: false
    },
    isSelected: Boolean,
    showCheckBox: Boolean
  },
  components: { Chip },
  data() {
    return { styles, expanded: false };
  },
  computed: {
    ...mapState(["permissions"]),
    endDate() {
      return this.shift.endDate;
    },
    startDate() {
      return this.shift.startDate;
    },
    showId() {
      return this.shift.shift && this.shift.shift.id
        ? this.shift.shift.id
        : this.shift.id;
    },
    canViewFilledStatus() {
      return this.permissions["applications.allocate"];
    },
    approvedShifts() {
      return filter(
        this.shift.applicationShifts,
        ({ status }) => status === "confirmed" || status === "allocated"
      );
    },
    submittedShifts() {
      return filter(
        this.shift.applicationShifts,
        ({ status }) => status === "submitted"
      );
    },
    unallocatedShifts() {
      const unallocatedCount =
        this.shift.workersRequired - size(this.approvedShifts);
      // return 0 if we have more approved applications than workers required
      return this.shift.workersRequired < size(this.approvedShifts)
        ? 0
        : unallocatedCount;
    },
    pattern() {
      return this.shift.pattern || {};
    },
    payRate() {
      return this.pattern.payRates;
    },
    activity() {
      return this.payRate && this.payRate.activityTypeName;
    }
  },
  methods: {
    pluralize,
    getColorByStatus,
    size,
    applicationUser(shift) {
      return shift.application && shift.application.user;
    }
  }
};
</script>

<style lang="scss">
.shift-details {
  &:hover {
    background: #f9f9f9;
  }
  border-radius: 5px;
}
.empty-shift {
  border-style: dotted;
  border-width: 3px;
  border-color: #d2d2d2;
  border-radius: 20px;
}
</style>
