<template>
  <div class="file d-flex align-center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar color="white">
          <v-toolbar-title>File</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-img :src="file.url" height="100%" contain>
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate color="grey lighten-5" />
              </v-row>
            </template>
          </v-img>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div class="details">
      <v-img
        v-if="fileIcon === 'text'"
        src="@/assets/svg/file__icon-text.svg"
        class="file-icon"
      />
      <v-img
        v-if="fileIcon === 'word'"
        src="@/assets/svg/file__icon-word.svg"
        class="file-icon"
      />
      <v-img
        v-if="fileIcon === 'image'"
        src="@/assets/svg/file__icon-image.svg"
        class="file-icon"
      />
      <v-img
        v-if="fileIcon === 'pdf'"
        src="@/assets/svg/file__icon-pdf.svg"
        class="file-icon"
      />
      <v-img
        v-if="fileIcon === 'excel'"
        src="@/assets/svg/file__icon-excel.svg"
        class="file-icon"
      />
      {{ file.name }}
    </div>

    <div class="file-actions" v-if="!isRemoving">
      <v-btn
        v-if="!disablePreview"
        @click="handleViewFile"
        color="blue-grey lighten-1"
        class="file__preview-button"
        text
        icon
        small
      >
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      <v-btn
        v-if="!disabled"
        @click="handleRemoveFile"
        color="red darken-2"
        class="file__remove-button"
        text
        icon
        small
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import mime from "mime-types";

export default {
  props: {
    file: {
      type: Object,
      required: true
    },
    isRemoving: Boolean,
    customActions: Boolean,
    disablePreview: Boolean,
    disabled: Boolean
  },
  data() {
    return {
      dialog: false
    };
  },
  computed: {
    icon() {
      return (
        this.file.extension ||
        (this.file.mimeType && mime.extension(this.file.mimeType))
      );
    },
    fileIcon() {
      switch (this.icon) {
        case "pdf":
          return this.icon;
        case "png":
        case "jpg":
        case "gif":
        case "jpeg":
        case "bmp":
        case "svg":
          return "image";
        case "doc":
        case "docx":
          return "word";
        case "xls":
        case "xlsx":
          return "excel";
        default:
          return "text";
      }
    }
  },
  methods: {
    handleViewFile() {
      if (this.customActions) {
        this.$emit("show", this.file);
      } else {
        if (this.fileIcon === "image") {
          this.dialog = true;
          return;
        }
        window.open(this.file.url, "_blank");
      }
    },
    handleRemoveFile() {
      this.$emit("removeFile", this.file);
    }
  }
};
</script>

<style lang="scss"></style>
