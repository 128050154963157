import {
  handleWithErrorMessage,
  handleWithGlobalMessage
} from "@/services/utils";
import organisationReasonApi from "@/services/modules/organisations/organisation-reasons-for-booking";
import {
  SET_IS_FETCHING_REASONS,
  SET_ORGANISATION_REASONS
} from "@/store/modules/organisations/organisation-reasons/mutations";
import { fromAuth } from "@/store/modules/auth";
import { GET_CURRENT_ORGANISATION_ID } from "@/store/modules/auth/getters";
import { reverse, filter } from "lodash";

export const FETCH_ORGANISATION_REASONS = "FETCH_ORGANISATION_REASONS";
export const CREATE_ORGANISATION_REASON = "CREATE_ORGANISATION_REASON";
export const UPDATE_ORGANISATION_REASON = "UPDATE_ORGANISATION_REASON";
export const DELETE_ORGANISATION_REASON = "DELETE_ORGANISATION_REASON";

const actions = {
  async [FETCH_ORGANISATION_REASONS]({ commit, dispatch, rootGetters }) {
    const organisationId = rootGetters[fromAuth(GET_CURRENT_ORGANISATION_ID)];

    return handleWithErrorMessage({
      dispatch,
      request: () => organisationReasonApi(commit).getAll(organisationId),
      setLoadingFunction: isLoading =>
        commit(SET_IS_FETCHING_REASONS, isLoading),
      setDataFunction: ({ data }) =>
        commit(SET_ORGANISATION_REASONS, reverse(data))
    });
  },
  async [CREATE_ORGANISATION_REASON](
    { commit, dispatch, rootGetters, state },
    reason
  ) {
    const organisationId = rootGetters[fromAuth(GET_CURRENT_ORGANISATION_ID)];
    const reasonWithClient = { ...reason, client: { id: organisationId } };
    const { data: newReason } = await handleWithGlobalMessage(
      () => organisationReasonApi(commit).create(reasonWithClient),
      dispatch
    );
    const currentReasons = state.organisationReasons;
    commit(SET_ORGANISATION_REASONS, [...currentReasons, newReason]);
    dispatch(FETCH_ORGANISATION_REASONS);
  },
  async [UPDATE_ORGANISATION_REASON]({ commit, dispatch, state }, reason) {
    const { data: newReason } = await handleWithGlobalMessage(
      () => organisationReasonApi(commit).update(reason),
      dispatch
    );
    const currentReasons = state.organisationReasons;
    commit(
      SET_ORGANISATION_REASONS,
      currentReasons.map(reason => {
        if (reason.id !== newReason.id) return reason;
        return newReason;
      })
    );
    dispatch(FETCH_ORGANISATION_REASONS);
  },
  async [DELETE_ORGANISATION_REASON]({ commit, dispatch, state }, id) {
    await handleWithGlobalMessage(
      () => organisationReasonApi(commit).delete(id),
      dispatch
    );
    const currentReasons = state.organisationReasons;
    commit(
      SET_ORGANISATION_REASONS,
      filter(currentReasons, reason => reason.id !== id)
    );
    dispatch(FETCH_ORGANISATION_REASONS);
  }
};

export default actions;
