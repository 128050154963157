<template>
  <Chip :color="color" class="chip--status">
    <slot />
  </Chip>
</template>

<script>
import { getColorByStatus } from "@/utils/timesheets";
import Chip from "@/components/common/Chip";

export default {
  name: "TimesheetChip",
  components: { Chip },
  computed: {
    color() {
      return getColorByStatus(this.status);
    }
  },
  props: {
    status: String
  }
};
</script>

<style scoped></style>
