<template>
  <div>
    <PrimaryButton light shrink @click="isDialogOpen = true">
      Publish
    </PrimaryButton>
    <BaseDialog :is-open="isDialogOpen">
      <template v-slot:title>
        Are you sure you want to publish the organisation unit?
      </template>
      <template v-slot:actions>
        <PrimaryButton text @click="close">Cancel</PrimaryButton>
        <PrimaryButton @click="handleSubmit" :loading="isLoading">
          Publish
        </PrimaryButton>
      </template>
    </BaseDialog>
  </div>
</template>

<script>
import BaseDialog from "@/components/common/BaseDialog";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import { ORGANISATION_UNITS_NAMESPACE } from "@/store/modules/organisations/organisation-units";
import { createNamespacedHelpers } from "vuex";
import { PUBLISH_ORGANISATION_UNIT } from "@/store/modules/organisations/organisation-units/actions";

const { mapActions } = createNamespacedHelpers(ORGANISATION_UNITS_NAMESPACE);

export default {
  name: "PublishUnitDialog",
  components: { PrimaryButton, BaseDialog },
  data() {
    return {
      isDialogOpen: false,
      isLoading: false
    };
  },
  props: {
    id: [String, Number]
  },
  methods: {
    ...mapActions({
      publishOrganisationUnit: PUBLISH_ORGANISATION_UNIT
    }),
    close() {
      this.isDialogOpen = false;
    },
    async handleSubmit() {
      this.isLoading = true;
      try {
        await this.publishOrganisationUnit(this.id);
      } catch (e) {
        console.error(e);
      } finally {
        this.close();
        this.isLoading = false;
      }
    }
  }
};
</script>

<style scoped></style>
