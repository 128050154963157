<template>
  <div class="workers-selection">
    <ValidationProvider
      v-slot="{ errors }"
      name="selectedWorkers"
      rules="required"
    >
      <Select
        autocomplete
        :error-messages="showValidationErrors ? errors : ''"
        :value="selectedWorkers"
        @input="handleSelectedWorkers($event)"
        @search="handleSearch"
        :items="formattedWorkers"
        item-text="fullName"
        item-value="id"
        :menuProps="{
          openOnClick: preventSelecting,
          closeOnContentClick: preventSelecting,
          disableKeys: false,
          maxHeight
        }"
        :key="preventSelecting"
        ref="autocomplete"
        chips
        small-chips
        multiple
        shouldClearSearch
        :loading="isLoading"
        :appendIcon="appendIcon"
      >
        <template v-slot:selection="{ data }">
          <v-chip
            close
            @click:close="unselectWorker(data.item)"
            class="selected-worker-chip"
          >
            <v-icon>
              {{
                `mdi-account${data.item.isUnavailable ? "-off-outline" : ""}`
              }}
            </v-icon>
            {{ data.item.fullName }}
          </v-chip>
        </template>
        <template v-slot:item="{ item }">
          <StatusItem class="worker-select-item">
            <template v-slot:icon>
              <v-icon>
                {{ `mdi-account${item.isUnavailable ? "-off-outline" : ""}` }}
              </v-icon>
            </template>
            <div>
              <div class="font-weight-bold">
                {{ item.fullName }}
              </div>
              <p
                class="secondary-text mb-0"
                :style="{ 'white-space': 'normal' }"
              >
                {{ item.formattedReason }}
              </p>
            </div>
          </StatusItem>
        </template>
      </Select>
    </ValidationProvider>
  </div>
</template>

<script>
import StatusItem from "@/components/common/StatusItem";
import { ValidationProvider } from "vee-validate";
import Select from "@/components/common/Select";
import { getFullName } from "@/utils/users";
import { map } from "lodash";

export default {
  name: "WorkersSelection",
  components: { StatusItem, ValidationProvider, Select },
  computed: {
    formattedWorkers() {
      const availableCandidates = map(this.workers, worker => ({
        ...worker,
        fullName: getFullName(worker)
      }));
      return [...availableCandidates, ...this.unavailableCandidates];
    }
  },
  props: {
    workers: {
      type: Array,
      required: true
    },
    selectedWorkers: Array,
    preventSelecting: {
      type: Boolean,
      required: false
    },
    showValidationErrors: {
      type: Boolean,
      required: false
    },
    isLoading: Boolean,
    unavailableCandidates: {
      type: Array,
      required: false,
      default: () => []
    },
    maxHeight: {
      type: Number,
      default: 500
    },
    appendIcon: {
      type: String,
      default: "$dropdown"
    }
  },
  methods: {
    handleSelectedWorkers(selectedWorkers) {
      this.$refs.autocomplete.isMenuActive = true;
      this.$emit("handleSelectedWorkers", selectedWorkers);
    },
    unselectWorker(currentWorker) {
      this.$emit("unselectWorker", currentWorker);
    },
    handleSearch(key) {
      this.$emit("search", key);
    }
  }
};
</script>

<style lang="scss">
.worker-select-item {
  font-weight: bold;
  padding: 8px 0;

  .v-icon.v-icon {
    background: $background;
    height: 40px;
    width: 40px;
    margin-right: 1rem;
  }
}
.workers-selection {
  .v-input__control .v-input__slot {
    padding: 5px !important;
    height: 54px !important;
  }

  .selected-worker-chip {
    background: $selected !important;

    span {
      color: white;
      font-weight: bold;
    }

    .v-icon {
      margin-right: 0.5rem;
      color: white;
    }
  }
}
</style>
