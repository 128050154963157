<template>
  <ValidationObserver ref="expenseItem">
    <BaseDialog :isOpen="isOpen">
      <template v-slot:title>
        {{ title }}
      </template>
      <template v-slot:body>
        <ExpenseItemEditable
          @isLoading="onLoading"
          :showValidationErrors="showValidationErrors"
          ref="expenseItemEditable"
          :isEmpty="isEmpty"
          :data="expense"
        />
      </template>
      <template v-slot:actions>
        <PrimaryButton @click.native="close" text :disabled="isLoading">
          Cancel
        </PrimaryButton>
        <PrimaryButton @click.native="onSubmit" :loading="isLoading">
          {{ actionName }}
        </PrimaryButton>
      </template>
    </BaseDialog>
  </ValidationObserver>
</template>

<script>
import BaseDialog from "@/components/common/BaseDialog";
import { ValidationObserver } from "vee-validate";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import ExpenseItemEditable from "@/components/timesheets/expenses/ExpenseItemEditable";

export default {
  name: "ExpenseDialog",
  components: {
    PrimaryButton,
    BaseDialog,
    ValidationObserver,
    ExpenseItemEditable
  },
  props: {
    expense: Object,
    isOpen: Boolean,
    title: String,
    actionName: String,
    isEmpty: Boolean
  },
  data() {
    return {
      isLoading: false,
      showValidationErrors: false
    };
  },
  methods: {
    close() {
      this.$emit("close");
      (this.isLoading = false), (this.showValidationErrors = false);
    },
    onLoading(val) {
      if (val) {
        this.showValidationErrors = false;
      } else {
        this.close();
      }
      this.isLoading = val;
    },
    async onSubmit() {
      this.showValidationErrors = true;
      const isValid = await this.$refs.expenseItem.validate();
      if (isValid) {
        if (this.isEmpty) {
          await this.$refs.expenseItemEditable.add();
        } else {
          await this.$refs.expenseItemEditable.edit();
        }
      }
    }
  }
};
</script>

<style lang="scss"></style>
