<template>
  <div class="flex-gap-20 mb-3">
    <div class="settings__content--item">
      <ValidationProvider v-slot="{ errors }" name="firstName" rules="required">
        <v-text-field
          v-if="isPermissioned('settings.view-basic.first-name')"
          hide-details
          height="50"
          @input="value => handleChange('firstName', value)"
          v-model="user.firstName"
          :error-messages="getErrorMessages(errors)"
          label="First Name"
          placeholder="First Name"
          :readonly="readonly('settings.edit-basic.first-name')"
        />
      </ValidationProvider>
    </div>
    <div class="settings__content--item">
      <ValidationProvider v-slot="{ errors }" name="lastName" rules="required">
        <v-text-field
          v-if="isPermissioned('settings.view-basic.last-name')"
          hide-details
          height="50"
          @input="value => handleChange('lastName', value)"
          v-model="user.lastName"
          :error-messages="getErrorMessages(errors)"
          label="Last Name"
          placeholder="Last Name"
          :readonly="readonly('settings.edit-basic.last-name')"
        />
      </ValidationProvider>
    </div>
    <div class="password-item">
      <v-text-field
        v-if="isPermissioned('settings.view-basic.email-address')"
        type="email"
        label="Email Address"
        :value="user.email"
        disabled
      />
    </div>
  </div>
</template>

<script>
import { isPermissioned } from "@/utils/permissions";
import { ValidationProvider } from "vee-validate";
import { pick } from "lodash";

export default {
  props: {
    /**
     * Object for user data
     */
    userData: {
      type: Object,
      required: true
    },
    isEditable: Boolean,
    showValidationErrors: Boolean
  },
  components: { ValidationProvider },
  data() {
    return {
      user: pick(this.userData, ["firstName", "lastName", "email"])
    };
  },
  methods: {
    handleChange(property, value) {
      this.$emit("edit", { property, value });
    },
    getErrorMessages(errors) {
      return this.showValidationErrors ? errors : "";
    },
    readonly(field) {
      return !isPermissioned("settings.edit-basic") && !isPermissioned(field);
    },
    isPermissioned
  }
};
</script>

<style lang="scss">
.password-item {
  .v-text-field__details {
    display: none;
  }
}
</style>
