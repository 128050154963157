<template>
  <div>
    <UserDialog
      :is-open="userDialogOpen"
      v-if="userDialogOpen"
      @close="userDialogOpen = false"
      title="New User"
      actionName="Create User"
      @submit="addUser"
      :loading="isAddingUser"
    />
    <CreateButtonMobile @click.native="userDialogOpen = true" v-if="isMobile" />
    <PrimaryButton @click.native="userDialogOpen = true" v-else>
      New User
    </PrimaryButton>
  </div>
</template>

<script>
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import UserDialog from "@/views/settings/Organisation/Users/dialogs/UserDialog";
import { createNamespacedHelpers } from "vuex";
import { USERS_NAMESPACE } from "@/store/modules/users";
import { CREATE_USER } from "@/store/modules/users/actions";
import { GET_IS_ADDING_USER } from "@/store/modules/users/getters";
import CreateButtonMobile from "@/components/common/CreateButtonMobile";
import { AUTH_NAMESPACE } from "@/store/modules/auth";
import { FETCH_USERS_TO_IMPERSONATE } from "@/store/modules/auth/action-types";

const { mapActions, mapGetters } = createNamespacedHelpers(USERS_NAMESPACE);
const { mapActions: mapAuthActions } = createNamespacedHelpers(AUTH_NAMESPACE);

export default {
  name: "NewUserAction",
  components: { UserDialog, PrimaryButton, CreateButtonMobile },
  data() {
    return {
      userDialogOpen: false
    };
  },
  computed: {
    ...mapGetters({
      isAddingUser: GET_IS_ADDING_USER
    })
  },
  methods: {
    ...mapActions({
      createUser: CREATE_USER
    }),
    ...mapAuthActions({
      fetchImpersonateUsers: FETCH_USERS_TO_IMPERSONATE
    }),
    async addUser(userData) {
      await this.createUser(userData);
      this.fetchImpersonateUsers();
      this.userDialogOpen = false;
    }
  }
};
</script>

<style scoped></style>
