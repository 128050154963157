<template>
  <div class="organisation-list">
    <div class="toolbar">
      <BaseSearchBar @search="text => (searchText = text)" class="mr-2" />
      <NewLocationAction />
    </div>
    <Datatable
      :headers="headers"
      :itemsPerPage="15"
      hideRowSeparator
      :items="locations"
      :is-loading="isFetchingLocations"
      :search="searchText"
      :customFilter="customFilter"
      disableFiltering
    >
      <template v-slot:action="{ item }">
        <DotMenu :items="menuItems(item)" @select="openActionDialog" />
      </template>
    </Datatable>
    <EditLocationAction
      v-if="isEditLocationDialogOpen"
      :is-open="isEditLocationDialogOpen"
      :location="selectedLocation"
      @close="isEditLocationDialogOpen = false"
    />
  </div>
</template>

<script>
import BaseSearchBar from "@/components/common/BaseSearchBar";
import Datatable from "@/components/common/Datatable";
import NewLocationAction from "@/views/settings/Organisation/Locations/actions/NewLocationAction";
import { createNamespacedHelpers } from "vuex";
import { map } from "lodash";
import EditLocationAction from "@/views/settings/Organisation/Locations/actions/EditLocationAction";
import { FETCH_ORGANISATION_LOCATIONS } from "@/store/modules/organisations/organisation-locations/actions";
import { ORGANISATION_LOCATIONS_NAMESPACE } from "@/store/modules/organisations/organisation-locations";
import { customTableFussyFilter } from "@/utils/filters";
import { DotMenu } from "@/components/common/Menu";

const {
  mapState: mapLocationsState,
  mapActions: mapLocationActions
} = createNamespacedHelpers(ORGANISATION_LOCATIONS_NAMESPACE);

export default {
  name: "OrganisationLocationsList",
  components: {
    EditLocationAction,
    NewLocationAction,
    Datatable,
    BaseSearchBar,
    DotMenu
  },
  data() {
    return {
      searchText: "",
      isEditLocationDialogOpen: false,
      selectedLocation: {}
    };
  },
  computed: {
    ...mapLocationsState({
      locations: state => state.organisationLocations,
      isFetchingLocations: state => state.isFetchingLocations
    }),
    headers() {
      return [
        {
          text: "Address Line 1",
          value: "line1"
        },
        {
          text: "Address Line 2",
          value: "line2"
        },
        {
          text: "Address Line 3",
          value: "line3"
        },
        {
          text: "Post Code",
          value: "postCode"
        },
        {
          text: "Country",
          value: "country"
        },
        {
          text: "",
          value: "action",
          sortable: false,
          align: "end"
        }
      ];
    },
    searchKeys() {
      return map(this.headers, "value");
    }
  },
  created() {
    this.fetchOrganisationLocations();
  },
  methods: {
    ...mapLocationActions({
      fetchOrganisationLocations: FETCH_ORGANISATION_LOCATIONS
    }),
    customFilter(value, searchValue, item) {
      return customTableFussyFilter(searchValue, item, this.searchKeys);
    },
    menuItems(item) {
      return [
        {
          title: `Edit`,
          action: () => {
            this.handleEditReason(item);
          }
        }
      ];
    },
    handleEditReason(location) {
      this.selectedLocation = location;
      this.isEditLocationDialogOpen = true;
    },
    openActionDialog(action) {
      action();
    }
  }
};
</script>

<style scoped></style>
