<template>
  <BaseAction>
    <SecondaryButton @click.native="openDialog" color="primary">
      <inline-svg
        :src="require('@/assets/svg/check.svg')"
        width="25"
        class="mb-1"
      />
      Accept Booking
    </SecondaryButton>
    <AcceptBookingDialog :isOpen="isOpen" @close="closeDialog" />
  </BaseAction>
</template>

<script>
import AcceptBookingDialog from "@/views/bookings/components/ConfirmDialogs/AcceptBookingDialog";
import BaseAction from "@/components/common/BaseAction";
import SecondaryButton from "@/components/common/Button/SecondaryButton";
import InlineSvg from "vue-inline-svg";

export default {
  name: "AcceptBookingAction",
  components: {
    BaseAction,
    AcceptBookingDialog,
    SecondaryButton,
    InlineSvg
  },
  data() {
    return {
      isOpen: false
    };
  },
  methods: {
    openDialog() {
      this.isOpen = true;
    },
    closeDialog() {
      this.isOpen = false;
    }
  }
};
</script>
