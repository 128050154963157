<template>
  <v-dialog v-model="apiError" fullscreen>
    <v-card class="dialog--full">
      <div class="api-error-page container text-center">
        <v-icon color="#EA0B40" size="60px">mdi-alert-circle</v-icon>
        <h1 class="my-3">{{ title }}</h1>
        <p class="secondary-text px-10">
          {{ body }}
        </p>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("auth", {
      apiError: state => state.apiError
    }),
    title() {
      if (this.$route.name && this.$route.name.includes("finance")) {
        return "Sorry, there seems to be a data issue";
      }
      return "Sorry, something has gone wrong";
    },
    body() {
      if (this.$route.name && this.$route.name.includes("finance")) {
        return "It looks like there’s a problem with the data in the system. Our automated checks have picked up on this, and have blocked your request to avoid an incorrect output. We’ll look into this as soon as possible. Please contact us if this issue persists.";
      }
      return "We’re experiencing a system issue which has been logged for investigation. Please contact us if this issue persists.";
    }
  }
};
</script>

<style lang="scss">
.secondary-text {
  color: #686868;
}
.api-error-page {
  padding-top: 80px;
}
</style>
