import "mutationobserver-shim";
import Vue from "vue";
import store from "./store";
import router from "./router";
import vuetify from "./plugins/vuetify";
import vCalendar from "./plugins/vcalendar";
import isMobile from "@/utils/mixins/isMobileMixin";
import isSafari from "@/utils/mixins/isSafariMixin";
import VueApexCharts from "./plugins/v-apex-charts";
import "@/utils/validation/rules";
import "./utils/filters";
import { awrFilter } from "@/utils/filters/awrFilter";
import { currencyFilter } from "@/utils/filters/currencyFilter";
import { dayOfMonthFilter } from "@/utils/filters/dayOfMonthFilter";
import { dayOfWeekFilter } from "@/utils/filters/dayOfWeekFilter";
import fuseSearch from "@/utils/filters/fuseSearch";
import {
  capitalizeFilter,
  startCaseFilter,
  upperCaseFilter,
  lowerCaseFilter
} from "@/utils/filters/textCaseFilter";
import { hourAndMinutesFilter } from "@/utils/filters/hourAndMinuteFilter";
import {
  baseDateFilter,
  shortDateFilter,
  longDatewithTimeFilter
} from "@/utils/filters/baseDateFilter";
import { costFilter } from "@/utils/filters/costFilter";
import { numberToLocalStringFilter } from "@/utils/filters/numberToLocalStringFilter";
import vLazyInput from "./plugins/v-lazy-input";
import App from "./App.vue";
import {
  shiftDateFilter,
  shiftHoursFilter,
  fullDateFilter,
  rangeDateFilter
} from "@/utils/filters/dateFilters";
import {
  fullNameFilter,
  rolesFilter,
  accountTypeFilter
} from "@/utils/filters/formatFilters";
import vMoney from "./plugins/v-money";

Vue.config.productionTip = false;
Vue.config.performance = true;

Vue.mixin({
  computed: {
    isMobile,
    isSafari
  }
});

Vue.filter("awr", awrFilter);
Vue.filter("upperCase", upperCaseFilter);
Vue.filter("lowerCase", lowerCaseFilter);
Vue.filter("capitalize", capitalizeFilter);
Vue.filter("startCase", startCaseFilter);
Vue.filter("hourAndMinutes", hourAndMinutesFilter);
Vue.filter("baseDate", baseDateFilter);
Vue.filter("shortDate", shortDateFilter);
Vue.filter("currency", currencyFilter);
Vue.filter("cost", costFilter);
Vue.filter("dayOfMonth", dayOfMonthFilter);
Vue.filter("dayOfWeek", dayOfWeekFilter);
Vue.filter("numberToLocalString", numberToLocalStringFilter);
Vue.filter("shiftDate", shiftDateFilter);
Vue.filter("shiftHours", shiftHoursFilter);
Vue.filter("fullName", fullNameFilter);
Vue.filter("fullDate", fullDateFilter);
// Vue.filter("longDate", longDateFilter);
Vue.filter("longDatewithTime", longDatewithTimeFilter);
Vue.filter("roles", rolesFilter);
Vue.filter("accountType", accountTypeFilter);
Vue.filter("rangeDate", rangeDateFilter);

Vue.prototype.$search = fuseSearch;

Vue.component("apexchart", VueApexCharts);

export default new Vue({
  router,
  store,
  vuetify,
  vCalendar,
  vLazyInput,
  vMoney,
  render: h => h(App)
}).$mount("#app");
