<template>
  <div class="d-flex flex-column">
    <div v-if="isLoading">
      <v-skeleton-loader v-for="i in 5" :key="i" type="list-item-avatar" />
    </div>
    <div class="overflow-auto" v-else>
      <div
        v-for="(selected, index) in selectedUsers"
        :key="index"
        class="workers-select-item  d-flex justify-space-between"
        :class="{ 'flex-column': isMobile }"
      >
        <div class="d-flex align-center">
          <v-icon x-large class="icon-wrapper">mdi-account-outline</v-icon>
          <strong>
            {{ selected.user | fullName }}
          </strong>
        </div>
        <div class="d-flex">
          <Select
            :items="permissions"
            :value="selected.permission"
            item-text="text"
            item-value="value"
            @change="
              $event => $emit('change', { permission: $event, selected })
            "
          />
          <v-icon class="ml-2" @click="$emit('remove', selected)">
            mdi-close
          </v-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Select from "@/components/common/Select";
import { PERMISSIONS } from "@/constants/reports";

export default {
  props: {
    selectedUsers: Array,
    isLoading: Boolean
  },
  components: {
    Select
  },
  data() {
    return {
      permissions: PERMISSIONS
    };
  }
};
</script>
