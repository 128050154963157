<template>
  <v-btn text class="shift-link transparent">
    <v-icon color="primary">mdi-calendar-range</v-icon>
    <span>{{ shift }}</span>
  </v-btn>
</template>

<script>
export default {
  name: "ShiftLink",
  props: {
    shiftId: {
      type: [String, Number]
    }
  },
  computed: {
    shift() {
      return this.shiftId ? `S-${this.shiftId}` : "All Shifts";
    }
  }
};
</script>

<style lang="scss"></style>
