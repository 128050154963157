<template>
  <v-radio-group
    :row="showGroupInRow"
    :disabled="disabled"
    class="radio"
    v-model="value"
  >
    <div
      class="radio--standard"
      @click="setValue(option.value)"
      :class="{
        'radio--standard__active': value === option.value
      }"
      v-for="(option, index) in options"
      :key="`opt-${index}`"
    >
      <v-radio
        :label="option.label || option.text"
        :value="option.value"
        :ripple="false"
        color="white"
      />
    </div>
  </v-radio-group>
</template>

<script>
export default {
  props: {
    /**
     * Array of objects containing the label and values
     * [{
     *  value,
     *  label
     * }]
     */
    options: {
      type: Array,
      required: true
    },
    /**
     * Default selected value
     */
    selectedValue: {
      type: [String, Number, Object, Array, Boolean],
      required: false,
      default: null
    },
    /**
     * Boolean to display in row
     */
    showGroupInRow: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: Boolean
  },
  data() {
    return {
      value: this.selectedValue,
      darker: this.isDarker
    };
  },
  methods: {
    setValue(value) {
      this.$emit("select", value);
      this.value = value;
    }
  }
};
</script>

<style lang="scss"></style>
