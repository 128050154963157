<template>
  <div class="worker-details-mobile">
    <div class="d-flex">
      <v-btn
        x-small
        fab
        elevation="0"
        @click="close"
        class="mr-1 mr-2 back-button"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <div class="d-flex">
        <div class="worker-icon-container">
          <v-icon class="worker-icon">mdi-account</v-icon>
        </div>
        <div class="worker-header">
          <p class="font-weight-bold mb-0">
            {{ candidate }}
          </p>
          <p class="secondary-text mb-0">12.38 p/h</p>
        </div>
      </div>
    </div>
    <h3>Status</h3>
    <Chip :status="application.status" shrink class="mb-4">{{
      application.status
    }}</Chip>
    <CandidateDetails :application="application" />
  </div>
</template>

<script>
import CandidateDetails from "@/views/bookings/BookingDetails/components/CandidateDetails/CandidateDetails";
import Chip from "@/components/common/Chip";
import { getFullName } from "@/utils/users";

export default {
  name: "CandidateDetailsMobile",
  components: {
    Chip,
    CandidateDetails
  },
  props: {
    application: Object
  },
  computed: {
    worker() {
      return this.application.user || {};
    },
    candidate() {
      return this.worker.firstName && this.worker.lastName
        ? getFullName(this.worker)
        : `Candidate ${this.application.id}`;
    }
  },
  methods: {
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss">
.worker-details-mobile {
  position: fixed;
  left: 0;
  top: 0;
  background-color: $white;
  overflow: auto;
  height: 100%;
  width: 100%;
  z-index: 10;
  padding: 0.5rem 0.5rem 110px 0.5rem;

  > div {
    margin-bottom: 1rem;
  }

  h3 {
    margin-bottom: 0;
  }

  .worker-icon-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: $default-border-radius;
    height: 40px;
    width: 40px;
    background-color: $selected-transparent;
    padding: 0.5rem;
    margin: auto 0.5rem auto 0;
  }

  .back-button {
    margin: auto 0;
  }

  .worker-header {
    :first-child {
      font-size: 22px;
    }
    :last-child {
      font-size: 18px;
    }
  }
}
</style>
