<template>
  <DialogFull :data="dialogSettings" @close="$emit('close')">
    <template v-slot:dialog.body>
      <ReportEditor
        ref="reportFields"
        :isLoading="isFetchingReports"
        :showValidationErrors="showValidationErrors"
        :report="report"
        @updated="value => (updatedReportData = value)"
      />
    </template>
    <template v-slot:dialog.action>
      <PrimaryButton :loading="isSaving" @click.native="saveReport">
        Save Changes
      </PrimaryButton>
    </template>
  </DialogFull>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { REPORTS_NAMESPACE } from "@/store/modules/reports";
import ReportEditor from "@/views/reports/forms/ReportEditor";
import {
  UPDATE_REPORT,
  FETCH_REPORTS,
  GET_REPORT_DATA
} from "@/store/modules/reports/action-types";
import DialogFull from "@/components/common/DialogFull";
import PrimaryButton from "@/components/common/Button/PrimaryButton";

const { mapActions, mapState } = createNamespacedHelpers(REPORTS_NAMESPACE);
export default {
  name: "EditReportDetails",
  title: "Edit Report - Track",
  props: { open: Boolean },
  components: {
    ReportEditor,
    DialogFull,
    PrimaryButton
  },
  created() {
    this.fetchReports();
  },
  data() {
    return {
      updatedReportData: null,
      isSaving: false,
      showValidationErrors: false
    };
  },
  computed: {
    ...mapState({
      reports: state => state.reports,
      isFetchingReports: state => state.isFetchingReports
    }),
    dialogSettings() {
      return {
        dialog: this.open,
        title: "Edit Report"
      };
    },
    report() {
      return this.reports.find(r => r.id === this.currentReportId);
    },
    currentReportId() {
      return this.$route.params.reportId;
    }
  },
  methods: {
    ...mapActions({
      updateReport: UPDATE_REPORT,
      fetchReports: FETCH_REPORTS,
      getReportData: GET_REPORT_DATA
    }),
    async saveReport() {
      this.showValidationErrors = true;
      const validate = await this.$refs.reportFields.validateFields();
      if (validate) {
        if (this.updatedReportData) {
          this.isSaving = true;
          await this.updateReport({
            previousReport: this.report,
            newReportData: this.updatedReportData
          });
          await this.fetchReports();
          await this.getReportData(this.currentReportId);
          this.showValidationErrors = true;
          this.isSaving = false;
        }
        this.$emit("close", true);
      }
    }
  }
};
</script>

<style scoped lang="scss"></style>
