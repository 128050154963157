<template>
  <DialogFull :data="dialogSettings" @close="close">
    <template v-slot:dialog.body>
      <div class="row edit-basic-details-content">
        <div class="col-6">
          <Select
            autocomplete
            v-model="location"
            height="50"
            class="mt-2"
            label="Location"
            placeholder="Select location"
            item-text="label"
            item-value="value"
            :items="formattedLocations"
            :loading="isFetchingData"
          />
          <v-label>Description</v-label>
          <v-textarea
            v-model="description"
            :value="description"
            hide-details
            class="mt-2"
          />
          <div
            class="d-flex w-full my-2"
            v-if="booking.contractType === 'fixed-term'"
          >
            <DatePicker
              class="w-full mr-2"
              @onChange="updateStartDate"
              label="Start Date"
              :input-value="startDate"
              :showValidationErrors="true"
              validateName="startDate"
              required
            />
            <DatePicker
              class="w-full"
              :key="startDate"
              @onChange="updateEndDate"
              label="End Date"
              :input-value="endDate"
              :showValidationErrors="true"
              :minDate="startDate"
              validateName="endDate"
              required
            />
          </div>
        </div>
      </div>
    </template>
    <template v-slot:dialog.action>
      <PrimaryButton :loading="isLoading" @click.native="save">
        Save Changes
      </PrimaryButton>
    </template>
  </DialogFull>
</template>

<script>
import DialogFull from "@/components/common/DialogFull";
import {
  EDIT_BOOKING,
  DELETE_SHIFT_PATTERN,
  CREATE_SHIFT_PATTERN,
  FETCH_CURRENT_BOOKING
} from "@/store/modules/bookings/actions";
import { mapActions, mapGetters } from "vuex";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import Select from "@/components/common/Select";
import {
  GET_LOCATIONS,
  GET_IS_FETCHING_DATA
} from "@/store/modules/data/getters";
import { FETCH_LOCATIONS } from "@/store/modules/data/actions";
import { getFormattedLocation } from "@/utils/locations";
import { formatDate } from "@/utils/time";
import moment from "moment";
import DatePicker from "@/components/common/DatePicker";
import { find } from "lodash";
import { generateDefaultShiftForFixedTerm } from "@/utils/shifts";

export default {
  name: "EditBasicDetails",
  components: { PrimaryButton, DialogFull, Select, DatePicker },
  data() {
    return {
      description: this.booking.description,
      location: this.booking.location,
      startDate: this.shiftStartDate,
      endDate: this.shiftEndDate,
      isLoading: false,
      newShiftData: {}
    };
  },
  created() {
    const filter = { "not-primary": true };
    this.fetchLocations({ filter });
  },
  computed: {
    dialogSettings() {
      return {
        title: "Edit Basic Details",
        dialog: this.isOpen
      };
    },
    ...mapGetters("data", {
      locations: GET_LOCATIONS,
      isFetchingData: GET_IS_FETCHING_DATA
    }),
    formattedLocations() {
      return this.locations.map(location => {
        delete location.links;
        return {
          label: getFormattedLocation(location),
          value: location
        };
      });
    },
    minEndDate() {
      return moment(this.startDate).format("YYYY-MM-DD");
    },
    defaultActivity() {
      const defaultPayRate = find(this.booking.payRates, { isDefault: true });
      return defaultPayRate ? defaultPayRate.activityTypeName : "Standard";
    },
    isShiftUpdated() {
      return (
        this.startDate !== this.shiftStartDate ||
        this.endDate !== this.shiftEndDate
      );
    }
  },
  props: {
    isOpen: Boolean,
    booking: Object,
    shiftStartDate: String,
    shiftEndDate: String,
    shiftPattern: Object
  },
  inject: ["currentBookingId"],
  methods: {
    ...mapActions("bookings", {
      editBooking: EDIT_BOOKING,
      deleteShiftPattern: DELETE_SHIFT_PATTERN,
      createShiftPattern: CREATE_SHIFT_PATTERN,
      fetchCurrentBooking: FETCH_CURRENT_BOOKING
    }),
    ...mapActions("data", {
      fetchLocations: FETCH_LOCATIONS
    }),
    async save() {
      this.isLoading = true;
      try {
        await this.editBooking({
          bookingId: this.currentBookingId,
          editedBooking: {
            description: this.description,
            location: this.location
          }
        });
        if (this.isShiftUpdated) {
          await this.updateShiftPattern();
        }
        this.$emit("close");
      } finally {
        this.isLoading = false;
      }
    },
    close() {
      this.$emit("close");
    },
    updateStartDate(date) {
      this.startDate = formatDate({ date });
      if (
        moment(this.startDate, "YYYY-MM-DD").isAfter(
          moment(this.endDate, "YYYY-MM-DD")
        )
      ) {
        this.endDate = null;
      }
      this.setShiftForFixedTerm();
    },
    updateEndDate(date) {
      this.endDate = formatDate({ date });
      this.setShiftForFixedTerm();
    },
    setShiftForFixedTerm() {
      const defaultShift = generateDefaultShiftForFixedTerm({
        startDate: this.startDate,
        endDate: this.endDate,
        activity: this.defaultActivity
      });

      this.newShiftData = defaultShift;
    },
    async updateShiftPattern() {
      if (this.shiftPattern) {
        await this.deleteShiftPattern({ id: this.shiftPattern.id });
      }
      await this.createShiftPattern({
        bookingId: this.booking.id,
        shift: this.newShiftData
      });
      await this.fetchCurrentBooking(this.booking.id);
    }
  }
};
</script>

<style lang="scss">
.edit-basic-details-content {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
</style>
