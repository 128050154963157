<template>
  <BaseDetails class="base-details">
    <template v-slot:header>
      <h2 class="pb-3">Basic Details</h2>
      <Permissioned section="bookings">
        <PrimaryButton
          permission="edit.description"
          @click.native="openBasicDetailsEdit"
          light
          dense
          shrink
        >
          Edit
          <template v-slot:append:icon
            ><v-icon class="ml-1">mdi-pencil</v-icon></template
          >
        </PrimaryButton>
      </Permissioned>
    </template>

    <template v-slot:body>
      <EditBasicDetails
        :booking="booking"
        :shiftStartDate="shiftStartDate"
        :shiftEndDate="shiftEndDate"
        :shiftPattern="shiftPattern"
        :is-open="isEditOpen"
        @close="closeBasicDetailsEdit"
      />
      <Permissioned section="bookings.view" vertical>
        <LabeledText label="Role">
          {{ booking.title }}
        </LabeledText>
        <LabeledText label="Location" permission="location">
          {{ formattedLocation }}
        </LabeledText>
        <LabeledText label="Contract Type">
          {{ booking.contractType | startCase }}
        </LabeledText>
        <LabeledText label="Dates" v-if="isFixedTerm">
          {{ dateRange }}
        </LabeledText>
        <LabeledText
          label="Compliance Requirements"
          permission="compliance-requirements"
        >
          <div v-if="complianceRequirements">
            <v-chip
              v-for="(requirement, index) in complianceRequirements"
              :key="index"
              label
            >
              {{ requirement }}
            </v-chip>
          </div>
        </LabeledText>
      </Permissioned>

      <Permissioned section="bookings.view">
        <LabeledText label="Other Requirements" permission="other-requirements">
          <div v-if="booking.otherRequirements.length">
            <v-chip
              v-for="requirement in booking.otherRequirements"
              :key="requirement"
              label
            >
              {{ requirement }}
            </v-chip>
          </div>
        </LabeledText>
      </Permissioned>
      <Permissioned section="bookings.view">
        <LabeledText label="Description" permission="description">
          {{ booking.description }}
        </LabeledText>
      </Permissioned>
      <Permissioned section="bookings.view" vertical>
        <BookingFiles
          permission="files"
          :bookingId="booking.id"
          :bookingFiles="booking.files"
        />
      </Permissioned>
      <Permissioned section="bookings.view">
        <LabeledText label="Total Cost" permission="total-cost">
          <p class="sublabel">(at Standard Rate)</p>
          {{ totalCost | currency(currencyCode) }}
        </LabeledText>
      </Permissioned>
    </template>
  </BaseDetails>
</template>

<script>
import LabeledText from "@/components/common/BaseDetails/LabeledText";
import BaseDetails from "@/components/common/BaseDetails/BaseDetails";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import EditBasicDetails from "@/views/bookings/EditBooking/EditBasicDetails";
import { GET_CURRENT_BOOKING } from "@/store/modules/bookings/getters";
import { createNamespacedHelpers } from "vuex";
import { BOOKINGS_NAMESPACE } from "@/store/modules/bookings/actions";
import Permissioned from "@/components/common/Permissioned";
import { getWordInCorrectForm } from "@/utils/bookings";
import BookingFiles from "@/views/bookings/EditBooking/BookingFiles";
import { getShiftDateRange, shiftDayFormat } from "@/utils/shifts";
import { RRule } from "rrule";
import { first } from "lodash";
import { getFormattedLocation } from "@/utils/locations";

const { mapGetters } = createNamespacedHelpers(BOOKINGS_NAMESPACE);

export default {
  name: "BasicDetails",
  props: {
    isFixedTerm: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Permissioned,
    EditBasicDetails,
    PrimaryButton,
    LabeledText,
    BaseDetails,
    BookingFiles
  },
  data() {
    return {
      isEditOpen: false
    };
  },
  computed: {
    ...mapGetters({
      currentBooking: GET_CURRENT_BOOKING
    }),
    booking() {
      return this.currentBooking;
    },
    shiftPattern() {
      return first(this.booking.shiftPatterns);
    },
    parsedRRule() {
      return this.shiftPattern
        ? RRule.parseString(this.shiftPattern.rrule)
        : "";
    },
    shiftStartDate() {
      return this.parsedRRule
        ? shiftDayFormat(this.parsedRRule.dtstart, "YYYY-MM-DD")
        : "";
    },
    shiftEndDate() {
      return this.parsedRRule
        ? shiftDayFormat(this.parsedRRule.until, "YYYY-MM-DD")
        : "";
    },
    dateRange() {
      return this.shiftPattern ? getShiftDateRange(this.parsedRRule) : "";
    },
    complianceRequirements() {
      const {
        complianceRequirements,
        yearsReferencesRequired
      } = this.currentBooking;
      const yearsReferenced = `
        ${yearsReferencesRequired}
         ${getWordInCorrectForm("year", yearsReferencesRequired)} referenced
      `;
      return yearsReferencesRequired > 0
        ? [...complianceRequirements, yearsReferenced]
        : complianceRequirements;
    },
    totalCost() {
      return this.currentBooking.totalCost ? this.currentBooking.totalCost : 0;
    },
    currencyCode() {
      return this.currentBooking.currencyCode
        ? this.currentBooking.currencyCode
        : "GBP";
    },
    formattedLocation() {
      return getFormattedLocation(this.booking.location);
    }
  },
  methods: {
    openBasicDetailsEdit() {
      this.isEditOpen = true;
    },
    closeBasicDetailsEdit() {
      this.isEditOpen = false;
    }
  }
};
</script>

<style lang="scss">
.base-details {
  .v-chip {
    margin: 0.5rem 0.5rem 0 0;
    font-weight: bold;
  }

  .labeled-text .content {
    position: relative;
  }

  .sublabel {
    position: absolute;
    top: 0;
    left: 95px;
    width: 300px;
  }
}
</style>
