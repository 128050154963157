import { PUBLISHING_SCHEDULE_TIERS } from "@/models/schedule-tier-model";
import organisationModel from "@/models/organisation-model";

export const AGENCY_MODEL = "agency";

const agencyModel = {
  vatNumber: "",
  address: {
    line1: "",
    line2: "",
    line3: "",
    postCode: "",
    country: ""
  },
  admin: {
    firstName: "",
    lastName: "",
    email: ""
  },
  [PUBLISHING_SCHEDULE_TIERS]: {
    jsonApi: "hasMany",
    type: PUBLISHING_SCHEDULE_TIERS
  },
  ...organisationModel
};

export default agencyModel;
