export const ORGANISATION_MODEL = "organisation";
export const ORGANISATIONS_MODEL = "organisations";
import { PUBLISHING_SCHEDULE_TIERS } from "@/models/schedule-tier-model";

export const INTERMEDIARY_COMPANY_MODEL = "intermediary-organisation";
export const PARENT_MODEL = "parent";

const organisationModel = {
  name: "",
  vatNumber: "",
  organisationType: "",
  costCode: "",
  status: "",
  companyRegistrationNumber: "",
  parentId: "",
  addresses: {
    jsonApi: "hasMany",
    type: "addresses"
  },
  users: {
    jsonApi: "hasMany",
    type: "users"
  },
  parent: {
    jsonApi: "hasOne",
    type: ORGANISATIONS_MODEL
  },
  admins: {
    jsonApi: "hasMany",
    type: "admins"
  },
  [PUBLISHING_SCHEDULE_TIERS]: {
    jsonApi: "hasMany",
    type: PUBLISHING_SCHEDULE_TIERS
  }
};

export const organisationModelActions = {
  PUBLISH: "publish",
  ARCHIVE: "archive",
  DUPLICATE: "duplicate"
};
export default organisationModel;
