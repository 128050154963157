<template>
  <div class="mt-10 text-center">
    <v-img
      v-if="icon"
      class="mx-auto mb-2"
      :src="src"
      max-width="120px"
      height="100"
      contain
    />
    <h3>{{ description }}</h3>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: false
    },
    description: {
      type: String,
      required: true
    },
    isFiltered: Boolean
  },
  computed: {
    src() {
      const suffix = this.isFiltered ? "-no-results" : "";
      return require(`@/assets/svg/empty-icons/${this.icon}${suffix}.svg`);
    }
  }
};
</script>

<style lang="scss" scoped></style>
