import actions from "@/store/modules/organisations/organisation-locations/actions";
import {
  mutations,
  state
} from "@/store/modules/organisations/organisation-locations/mutations";

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
