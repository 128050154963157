<template>
  <v-text-field
    class="search-bar"
    v-model="searchText"
    :append-icon="searchText ? null : 'mdi-magnify'"
    :placeholder="placeholder"
    label="Test{"
    @input="handleSearch"
    color="primary"
    clearable
    hide-details
    v-lazy-input:debounce="1000"
  />
</template>

<script>
export default {
  name: "BaseSearchBar",
  data() {
    return {
      searchText: null
    };
  },
  props: {
    placeholder: {
      type: String,
      required: false,
      default: "Search"
    }
  },
  methods: {
    handleSearch() {
      this.$emit("search", this.searchText);
    }
  }
};
</script>

<style lang="scss"></style>
