import APIService, { withPrefix } from "@/services/APIService";
import JSONApiService from "@/services/JSONApiService";
import { USER_MODEL } from "@/models/user-model";
import { APPLICATION_MODEL } from "@/models/application-model";
import { TIMESHEET_MODEL } from "@/models/timesheet-model";
import {
  include,
  ORGANISATIONS,
  EVENTS,
  ADDRESSES,
  INTERMEDIARY_ORGANISATION,
  USER,
  BOOKING,
  WORKER,
  COMPLIANCE_CHECKS,
  REFERENCES,
  nested,
  LOCATION,
  ROOT_CLIENT
} from "@/models/relationships";
import { ORGANISATION_MODEL } from "@/models/organisation-model";
import { isNotTestingEnv } from "@/services/utils";

const API = isNotTestingEnv() && withPrefix(APIService.client(), "users");

const workersApi = ({ commit } = {}) => ({
  getAllWorkers: async params =>
    JSONApiService(commit)
      .all(USER)
      .get({
        ...params,
        filter: { account_type: "worker", ...params.filter },
        ...include(ORGANISATION_MODEL)
      }),
  deleteWorker: async id => API.delete(`${id}`),
  getIntermediaryCompany: async id =>
    API.get(`${id}/intermediary-organisation?include=addresses`),
  addNewWorker: async data => {
    const password = process.env.VUE_APP_WORKER_DEFAULT_PASSWORD;
    return JSONApiService(commit).create(
      USER_MODEL,
      {
        password,
        ...data
      },
      include(COMPLIANCE_CHECKS, ADDRESSES, ORGANISATION_MODEL)
    );
  },
  updateWorker: async data => {
    return JSONApiService(commit).update(
      USER_MODEL,
      data,
      include(
        ADDRESSES,
        ORGANISATIONS,
        INTERMEDIARY_ORGANISATION,
        EVENTS,
        COMPLIANCE_CHECKS,
        REFERENCES
      )
    );
  },
  getWorker: async id =>
    JSONApiService(commit).find(
      USER_MODEL,
      id,
      include(
        ADDRESSES,
        ORGANISATIONS,
        ORGANISATION_MODEL,
        INTERMEDIARY_ORGANISATION,
        EVENTS,
        COMPLIANCE_CHECKS,
        REFERENCES
      )
    ),
  getApplications: async ({ params }) =>
    JSONApiService(commit)
      .all(APPLICATION_MODEL)
      .get({
        ...params,
        ...include(BOOKING, nested(BOOKING, LOCATION), USER, ORGANISATION_MODEL)
      }),
  getTimesheets: async ({ params }) =>
    JSONApiService(commit)
      .all(TIMESHEET_MODEL)
      .get({ ...params, ...include(BOOKING, WORKER, ROOT_CLIENT) }),
  deleteWorkerCV: async id => API.delete(`${id}/cv`),
  deleteWorkerComplianceDocument: async id =>
    API.delete(`${id}/compliance-document`)
});

export default workersApi;
