<template>
  <div class="report-fields">
    <h2 class="mb-2">Fields</h2>
    <v-list>
      <draggable v-model="orderedFields">
        <v-list-item
          class="my-2"
          v-for="(selectedField, index) in selectedFields"
          :key="index"
        >
          <ReportField
            :index="index"
            :selectedField="selectedField"
            :availableFields="patchAvailableFields(selectedField.field)"
            :labels="labels"
            :showValidationErrors="showValidationErrors"
            :hasGrouping="hasGrouping"
            :onChange="handleChange(index)"
            :onDelete="handleDelete(index)"
          />
        </v-list-item>
      </draggable>
    </v-list>
    <v-btn
      v-if="availableFields.length"
      @click="addField"
      text
      color="primary"
      class="add-item my-2"
    >
      Add fields +
    </v-btn>
  </div>
</template>

<script>
import ReportField from "@/views/reports/forms/ReportField";
import draggable from "vuedraggable";
import { remove } from "lodash";

export default {
  name: "ReportFieldSection",
  props: {
    fields: Array,
    selectedFields: Array,
    hasGrouping: Boolean,
    labels: Object,
    showValidationErrors: Boolean
  },
  components: {
    ReportField,
    draggable
  },
  computed: {
    availableFields() {
      return this.fields.filter(
        field =>
          ((this.hasGrouping && field.field === "count") ||
            (!this.hasGrouping && field.field !== "count") ||
            (field.measures && field.measures.length)) &&
          !this.selectedFields.find(
            selectedField => selectedField.field === field.field
          )
      );
    },
    orderedFields: {
      get() {
        return this.selectedFields;
      },
      set(value) {
        this.$emit(
          "updated",
          value.map((field, display_order) => ({
            ...field,
            display_order
          }))
        );
      }
    }
  },
  methods: {
    addField() {
      if (this.availableFields.length > 0) {
        const field =
          this.availableFields.length > 1
            ? this.availableFields[1]
            : this.availableFields[0];
        this.selectedFields.push({
          field: field.field,
          ...(this.hasGrouping ? { measure: field.measures[0] } : {})
        });
      }
    },
    handleChange(index) {
      return newField => {
        this.selectedFields.splice(index, 1, newField);
      };
    },
    handleDelete(index) {
      return () => {
        this.selectedFields.splice(index, 1);
      };
    },
    patchAvailableFields(fieldName) {
      return [
        this.fields.find(field => field.field === fieldName),
        ...this.availableFields
      ];
    }
  },
  watch: {
    selectedFields(newValue) {
      this.$emit("updated", newValue);
    },
    hasGrouping(newValue) {
      if (newValue) {
        const selectedFields = [...this.selectedFields];
        selectedFields.forEach(selected => {
          const field = this.fields.find(
            ({ field }) => field === selected.field
          );
          if (field && (field.field === "count" || !field.measures.length)) {
            remove(
              this.selectedFields,
              selected => selected.field === field.field
            );
          }
        });
      }
    }
  }
};
</script>
