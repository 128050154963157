<template>
  <ReportWrapper
    :sections="sections"
    :data="data"
    @changeTab="key => $emit('changeTab', key)"
    @downloadCSV="$emit('downloadCSV')"
  >
    <template v-slot:content>
      <div class="report-chart" v-resize="onResize">
        <apexchart
          :options="chartOptions"
          :series="series"
          v-if="withGroupings"
        />
        <h3 v-else class="my-10">
          Please include more data in your report to be able to generate a chart
        </h3>
      </div>
    </template>
    <template v-slot:actions>
      <div
        class="d-flex align-center flex-wrap actions-bar"
        v-if="report.groupings && report.groupings.length > 1"
      >
        <strong class="mx-2">Stack</strong>
        <Select
          class="small-input"
          v-model="selectedStack"
          @change="val => handleOptionChanged('columnStackOption', val)"
          label=""
          :items="stackingOptions"
          item-text="label"
          item-value="value"
          dense
        />
      </div>
      <div class="d-flex align-center flex-wrap actions-bar">
        <strong class="mx-2">Y-Axis</strong>
        <Select
          label=""
          :items="yAxisOptions"
          v-model="selectedYAxisOption"
          @change="val => handleOptionChanged('yAxisFieldId', val)"
          item-text="label"
          item-value="value"
          dense
        />
      </div>
    </template>
  </ReportWrapper>
</template>

<script>
import { REPORTS_NAMESPACE } from "@/store/modules/reports";
import { createNamespacedHelpers } from "vuex";
import {
  CREATE_REPORT_CHART,
  EDIT_REPORT_CHART
} from "@/store/modules/reports/action-types";
import { SET_CURRENT_REPORT_CHART } from "@/store/modules/reports/mutation-types";
import { find, first, startCase } from "lodash";
import Select from "@/components/common/Select";
import { STACKING_OPTIONS } from "@/constants/reports";
import { reportChartMixin } from "@/mixins/report-chart.mixin";
import ReportWrapper from "@/views/reports/tabs/ReportWrapper";

const { mapActions, mapState, mapMutations } = createNamespacedHelpers(
  REPORTS_NAMESPACE
);

export default {
  name: "BarChart",
  components: {
    Select,
    ReportWrapper
  },
  mixins: [reportChartMixin],
  data() {
    return {
      stackingOptions: STACKING_OPTIONS
    };
  },
  async created() {
    const currentReportChart = find(this.reportCharts, { chartType: "bar" });
    this.setCurrentReportChart(currentReportChart);
    if (!currentReportChart) {
      const { groupings, fields } = this.report;
      await this.createChart({
        title: this.report.name,
        chartType: "bar",
        xAxisPrimaryGroupingId: first(groupings).id,
        ...(groupings[1] && { xAxisSecondaryGroupingId: groupings[1].id }),
        yAxisFieldId: first(fields).id
      });
    }
    this.selectedStack = this.currentChart.columnStackOption;
    this.adjustChartOptions(this.currentChart, this.report);
    this.adjustChartData(this.currentChart, this.report);
  },
  computed: {
    ...mapState({
      reportCharts: state => state.reportCharts,
      currentChart: state => state.currentReportChart
    }),
    chartOptions() {
      return {
        chart: {
          id: "reports-bar-chart",
          type: "bar",
          width: this.chartWidth,
          height: 850,
          stacked: Boolean(this.selectedStack),
          stackType: this.selectedStack === "hundred_percent" && "100%",
          toolbar: this.getExportOptions(this.report)
        },
        legend: {
          show: true
        },
        plotOptions: {
          bar: {
            columnWidth: "90%",
            borderRadius: 4,
            endingShape: "rounded",
            dataLabels: {
              position: "bottom",
              orientation: "vertical"
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"]
        },
        xaxis: {
          title: { text: this.xAxisTitle },
          labels: {
            rotateAlways: true,
            offsetY: 5,
            minHeight: 300,
            style: {
              fontFamily:
                "-apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica",
              fontSize: "12px"
            }
          },
          categories: this.categories
        },
        yaxis: {
          title: {
            text: startCase(this.fieldKey),
            style: {
              fontFamily:
                "-apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica",
              fontSize: "18px",
              fontWeight: "bold"
            }
          },
          labels: {
            formatter: value => {
              return this.getLabelValue(value);
            },
            style: {
              fontSize: "18px",
              fontFamily:
                "'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace"
            }
          }
        },
        fill: {
          opacity: 1
        }
      };
    }
  },
  methods: {
    ...mapMutations({
      setCurrentReportChart: SET_CURRENT_REPORT_CHART
    }),
    ...mapActions({
      createChart: CREATE_REPORT_CHART,
      editChart: EDIT_REPORT_CHART
    }),
    async handleOptionChanged(key, value) {
      await this.editChart({
        reportId: this.currentChart.id,
        data: {
          [key]: value
        }
      });
      this.selectedStack = this.currentChart.columnStackOption;
      this.adjustChartData(this.currentChart, this.report);
    }
  }
};
</script>
