import { PAY_RATE_ITEMS } from "@/models/relationships";
import { AGENCY } from "@/models/relationships";

export const PAY_RATE_MODEL = "pay-rate";

const payRate = {
  activityTypeName: "",
  payRateName: "",
  payRateAmount: 0,
  currencyCode: "",
  awr: 0,
  isDefault: 0,
  bookingPayRateId: 0,
  validFromDate: "",
  validToDate: "",
  payRateItems: {
    jsonApi: "hasMany",
    type: PAY_RATE_ITEMS
  },
  agency: {
    jsonApi: "hasOne",
    type: AGENCY
  }
};

export default payRate;
