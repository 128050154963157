<template>
  <v-form ref="form">
    <div v-if="isLoading">
      <v-skeleton-loader type="list-item" v-for="i in 10" :key="i" />
    </div>
    <v-row class="mx-0" v-else>
      <Permissioned section="bookings" class="flex-wrap pb-1 w-full">
        <v-col class="col-12 input-item mb-3" v-if="!onlyApproverVerifier">
          <Select
            autocomplete
            v-model="internalInfo.client"
            :rules="isRequired('Cost Code')"
            :items="costCodes"
            :loading="isLoadingOrganisations"
            @change="handleCostCodeChange"
            item-text="label"
            :value-comparator="compareBy('id')"
            :label="labelWithBudgetRemaining"
            single-line
            clearable
          />
        </v-col>
        <v-col
          class="col-md-6 col-12 input-item pr-5 mb-3"
          v-if="!onlyApproverVerifier"
        >
          <v-text-field
            v-model="internalInfo.postNumber"
            @change="handleChange"
            label="Post Number (optional)"
            placeholder=" "
          />
        </v-col>
        <v-col
          class="col-md-6 col-12 input-item mb-3"
          v-if="!onlyApproverVerifier"
        >
          <v-text-field
            v-model="internalInfo.poNumber"
            @change="handleChange"
            :label="
              shouldBeRequired('poNumber')
                ? 'PO Number'
                : 'PO Number (optional)'
            "
            :rules="shouldBeRequired('poNumber') ? isRequired('PO Number') : []"
            placeholder=" "
          />
        </v-col>
        <v-col class="col-12 input-item" v-if="!onlyApproverVerifier">
          <Select
            v-model="internalInfo.reason"
            @change="handleChange"
            :items="reasons"
            :loading="isLoadingData"
            :rules="isRequired('Reason for Hire')"
            item-text="reason"
            item-value="reason"
            label="Reason for Hire"
            single-line
            autocomplete
          />
        </v-col>
        <v-col class="col-12 input-item mb-3" v-if="!onlyApproverVerifier">
          <v-checkbox
            v-model="internalInfo.expensesAllowed"
            @change="handleChange"
            class="mt-0"
            label="Worker can claim expenses for this role"
          />
          <v-checkbox
            v-model="internalInfo.assessmentsAllowed"
            @change="handleChange"
            class="mt-0"
            label="Conduct quality assessments on workers"
          />
        </v-col>
        <v-col class="col-12 input-item">
          <h3 class="pb-3">Timesheets</h3>
        </v-col>
        <v-col class="col-12 input-item mb-3">
          <Select
            :loading="isLoadingTimesheetApprovers"
            :items="approversWithFullName"
            v-model="internalInfo.approver"
            label="Timesheet Approver"
            :value-comparator="compareBy('id')"
            :rules="!isDisabled ? isRequired('Timesheet Approver') : []"
            :disabled="isDisabled"
            @change="handleChange"
            item-text="fullName"
            single-line
            autocomplete
          />
        </v-col>
        <v-col class="col-12 input-item mb-3">
          <Select
            :loading="isLoadingTimesheetVerifiers"
            :items="verifiersWithFullName"
            v-model="internalInfo.verifier"
            label="Timesheet Verifier (optional)"
            :value-comparator="compareBy('id')"
            :disabled="isDisabled"
            @change="handleChange"
            item-text="fullName"
            single-line
            autocomplete
            clearable
          />
        </v-col>
        <v-col
          class="col-12 input-item"
          v-if="!onlyApproverVerifier"
          permission="edit.internal-notes"
        >
          <v-label>Internal Notes</v-label>
          <v-textarea
            v-model="internalInfo.internalNotes"
            hide-details
            class="mt-2"
            @change="handleChange"
          />
        </v-col>
      </Permissioned>
    </v-row>
  </v-form>
</template>

<script>
import Select from "@/components/common/Select";
import { get, pickBy, find, map, isEmpty, isObject, size, first } from "lodash";
import { createNamespacedHelpers } from "vuex";
import { USERS_NAMESPACE } from "@/store/modules/users";
import {
  GET_IS_FETCHING_APPROVERS,
  GET_IS_FETCHING_VERIFIERS,
  GET_TIMESHEET_APPROVERS,
  GET_TIMESHEET_VERIFIERS
} from "@/store/modules/users/getters";
import {
  FETCH_TIMESHEET_APPROVERS,
  FETCH_TIMESHEET_VERIFIERS
} from "@/store/modules/users/actions";
import {
  GET_IS_FETCHING_ORGANISATIONS,
  GET_ORGANISATIONS
} from "@/store/modules/organisations/getters";
import { FETCH_ORGANISATIONS } from "@/store/modules/organisations/actions";
import { ORGANISATIONS_NAMESPACE } from "@/store/modules/organisations";
import { DATA_NAMESPACE } from "@/store/modules/data";
import {
  FETCH_REASONS_FOR_HIRE,
  FETCH_FIELD_NECESSITIES
} from "@/store/modules/data/actions";
import {
  GET_IS_FETCHING_DATA,
  GET_REASONS_FOR_HIRE,
  GET_FIELD_NECESSITIES
} from "@/store/modules/data/getters";
import { withFullName } from "@/utils/users";
import { compareBy } from "@/utils/helpers";
import { getNumberAsMonetaryValue } from "@/utils/helpers";
import { getFullName } from "@/utils/users";
import Permissioned from "@/components/common/Permissioned";

const { mapActions, mapGetters } = createNamespacedHelpers(USERS_NAMESPACE);
const {
  mapActions: mapOrganisationActions,
  mapGetters: mapOrganisationGetters
} = createNamespacedHelpers(ORGANISATIONS_NAMESPACE);
const {
  mapActions: mapDataActions,
  mapGetters: mapDataGetters
} = createNamespacedHelpers(DATA_NAMESPACE);

const INIT_INTERNAL_INFORMATION_DATA = {
  client: {},
  poNumber: null,
  postNumber: null,
  reason: "",
  canClaimExpenses: false,
  conductQualityAssessments: false,
  approver: "",
  verifier: "",
  internalNotes: ""
};

export default {
  name: "InternalInformationSection",
  components: {
    Select,
    Permissioned
  },
  props: {
    initialValues: Object,
    validate: Boolean,
    onlyApproverVerifier: Boolean
  },
  data() {
    return {
      internalInfo:
        this.initialValues || Object.assign({}, INIT_INTERNAL_INFORMATION_DATA),
      isLoading: false,
      showValidationErrors: false
    };
  },
  async created() {
    this.isLoading = true;
    try {
      await Promise.all([
        this.fetchFieldNecessities({ filter: { form: "booking" } }),
        this.setHiringReasons(),
        this.setCostCodes()
      ]);
    } finally {
      this.isLoading = false;
      if (this.onlyApproverVerifier) {
        this.fetchApproversAndVerifiers(this.internalInfo.client.id);
      }
    }
  },
  methods: {
    ...mapActions({
      fetchApprovers: FETCH_TIMESHEET_APPROVERS,
      fetchVerifiers: FETCH_TIMESHEET_VERIFIERS
    }),
    ...mapOrganisationActions({
      fetchOrganisations: FETCH_ORGANISATIONS
    }),
    ...mapDataActions({
      fetchHireReasons: FETCH_REASONS_FOR_HIRE,
      fetchFieldNecessities: FETCH_FIELD_NECESSITIES
    }),
    isValid() {
      this.showValidationErrors = true;
      this.$refs.form.validate();
      const { client, reason, approver, poNumber } = this.internalInfo;
      const checks = [];

      if (client !== undefined) {
        checks.push(!isEmpty(client));
      }

      if (reason !== undefined) {
        checks.push(!!reason);
      }

      if (approver !== undefined) {
        checks.push(!!approver);
      }

      const isPoNumberValid =
        !this.shouldBeRequired("poNumber") ||
        (poNumber !== undefined && !!poNumber);
      if (isPoNumberValid) {
        checks.push(true);
      }

      return checks.every(Boolean);
    },
    handleCostCodeChange(val) {
      this.internalInfo.client = val || {};
      if (this.internalInfo.client) {
        this.fetchApproversAndVerifiers(this.internalInfo.client.id);
      }
      this.handleChange();
    },
    handleChange() {
      this.internalInfo = { ...this.internalInfo };
      this.$emit("change", pickBy(this.internalInfo));
    },
    isRequired(field) {
      if (!this.showValidationErrors) {
        return [];
      }
      return [
        value => {
          let isValid;
          if (isObject(value)) {
            isValid = !isEmpty(value);
          } else {
            isValid = !!value;
          }
          return isValid || `${field} is required`;
        }
      ];
    },
    shouldBeRequired(field) {
      const fieldDetails = find(this.fieldNecessities, { field });
      if (fieldDetails) {
        return fieldDetails.required;
      }
      return false;
    },
    compareBy,
    async fetchApproversAndVerifiers(id) {
      await this.fetchApprovers(id);
      await this.fetchVerifiers(id);
      if (size(this.approversWithFullName) === 1) {
        this.internalInfo.approver = first(this.approversWithFullName);
        this.handleChange();
      }
      if (size(this.verifiersWithFullName) === 1) {
        this.internalInfo.verifier = first(this.verifiersWithFullName);
        this.handleChange();
      }
    },
    async setCostCodes() {
      await this.fetchOrganisations({
        filter: { has_cost_code: true, is_live: true }
      });
      if (size(this.costCodes) === 1) {
        const client = first(this.costCodes);
        this.handleCostCodeChange(client);
      }
    },
    async setHiringReasons() {
      await this.fetchHireReasons(this.organisationId);
      if (size(this.reasons) === 1) {
        this.internalInfo.reason = first(this.reasons).reason;
        this.handleChange();
      }
    }
  },
  computed: {
    ...mapGetters({
      verifiers: GET_TIMESHEET_VERIFIERS,
      approvers: GET_TIMESHEET_APPROVERS,
      isLoadingTimesheetApprovers: GET_IS_FETCHING_APPROVERS,
      isLoadingTimesheetVerifiers: GET_IS_FETCHING_VERIFIERS
    }),
    ...mapOrganisationGetters({
      organisations: GET_ORGANISATIONS,
      isLoadingOrganisations: GET_IS_FETCHING_ORGANISATIONS
    }),
    ...mapDataGetters({
      hireReasons: GET_REASONS_FOR_HIRE,
      isLoadingData: GET_IS_FETCHING_DATA,
      fieldNecessities: GET_FIELD_NECESSITIES
    }),
    verifiersWithFullName() {
      const verifiers = this.verifiers.map(user => ({
        ...user,
        fullName: getFullName(user)
      }));

      if (verifiers.length) {
        return verifiers;
      }
      return this.internalInfo.verifier
        ? [withFullName(this.internalInfo.verifier)]
        : [];
    },
    reasons() {
      if (this.hireReasons.length) {
        return map(this.hireReasons, hireReason => {
          return { reason: hireReason.hireReason };
        });
      }
      return this.internalInfo.reason ? [this.internalInfo.reason] : [];
    },
    approversWithFullName() {
      const approvers = this.approvers.map(user => ({
        ...user,
        fullName: getFullName(user)
      }));
      if (approvers.length) {
        return approvers;
      }
      return this.internalInfo.approver
        ? [withFullName(this.internalInfo.approver)]
        : [];
    },
    costCodes() {
      const costCodes = this.organisations.map(organisation => {
        organisation.label = `${
          organisation.parent ? organisation.parent.name + " - " : ""
        } ${organisation.name} (${organisation.costCode})`;
        return organisation;
      });
      if (costCodes.length) {
        return costCodes;
      }
      return [];
    },
    organisationId() {
      return get(this.$store, "state.auth.userData.organisation.id");
    },
    isDisabled() {
      return Boolean(isEmpty(this.internalInfo.client));
    },
    labelWithBudgetRemaining() {
      return this.internalInfo.client && this.internalInfo.client.totalBudget
        ? `Cost Code (Budget Remaining: 
              ${getNumberAsMonetaryValue(
                this.internalInfo.client.totalBudget,
                this.internalInfo.client.currencyCode
              )})`
        : "Cost Code";
    }
  },
  watch: {
    validate(shouldValidate) {
      if (shouldValidate) {
        this.$emit(this.isValid() ? "valid" : "invalid");
      }
    },
    initialValues(values) {
      this.internalInfo = values || INIT_INTERNAL_INFORMATION_DATA;
    }
  }
};
</script>

<style scoped lang="scss">
.input-item {
  padding-bottom: 0;
  padding-top: 0;
}

.col {
  padding: 0px;
}
</style>
