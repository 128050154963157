<template>
  <div>
    <PublishingScheduleDialog
      :dialogSettings="dialogSettings"
      @close="close"
      :actionName="actionName"
      @submit="handleCreate"
      :loading="isLoading"
    />
    <CreateButtonMobile @click.native="openDialog" v-if="isMobile" />
    <PrimaryButton @click.native="openDialog" v-else>
      New Schedule
    </PrimaryButton>
  </div>
</template>

<script>
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import PublishingScheduleDialog from "@/views/settings/Organisation/PublishingSchedules/dialogs/PublishingScheduleDialog";
import { ORGANISATION_SCHEDULES_NAMESPACE } from "@/store/modules/organisations/organisation-schedules";
import { createNamespacedHelpers } from "vuex";
import { CREATE_PUBLISHING_SCHEDULE } from "@/store/modules/organisations/organisation-schedules/actions";
import CreateButtonMobile from "@/components/common/CreateButtonMobile";

const { mapActions } = createNamespacedHelpers(
  ORGANISATION_SCHEDULES_NAMESPACE
);

export default {
  name: "NewPublishingScheduleAction",
  components: { PrimaryButton, PublishingScheduleDialog, CreateButtonMobile },
  data() {
    return {
      actionName: "Create Schedule",
      isLoading: false,
      dialogSettings: {
        dialog: false,
        title: "New Publishing Schedule"
      }
    };
  },
  computed: {
    isValid() {
      return (
        this.publishingSchedule.name && this.publishingSchedule.tags.length
      );
    }
  },
  methods: {
    ...mapActions({
      createSchedule: CREATE_PUBLISHING_SCHEDULE
    }),
    openDialog() {
      this.dialogSettings.dialog = true;
    },
    close() {
      this.dialogSettings.dialog = false;
    },
    async handleCreate(newPublishingSchedule) {
      this.isLoading = true;
      try {
        await this.createSchedule(newPublishingSchedule);
        this.close();
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style scoped></style>
