<template>
  <DialogFull :data="bulkBookingDialog" @close="close">
    <template v-slot:dialog.body>
      <div @paste="onPaste" class="container">
        <h1 class="my-4 container">New Bookings</h1>
        <BulkBooking ref="bulkBookings" @update="updateBookings" />
      </div>
    </template>
    <template v-slot:dialog.action>
      <PrimaryButton
        :disabled="disabled"
        :loading="isCreating"
        @click.native="createBookings"
      >
        Create Bookings
      </PrimaryButton>
    </template>
  </DialogFull>
</template>

<script>
import DialogFull from "@/components/common/DialogFull";
import { mapActions } from "vuex";
import { CREATE_BULK_BOOKINGS } from "@/store/modules/bookings/actions";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import { isEmpty } from "lodash";
import { POST_GLOBAL_MESSAGE } from "@/store/modules/global/action-types";
import BulkBooking from "@/views/bookings/NewBooking/components/BulkBooking";

export default {
  name: "ImportBookings",
  components: {
    DialogFull,
    PrimaryButton,
    BulkBooking
  },
  props: {
    isOpen: Boolean
  },
  data() {
    return {
      isCreating: false,
      bookings: []
    };
  },
  computed: {
    bulkBookingDialog() {
      return {
        dialog: this.isOpen,
        title: "Bulk Bookings"
      };
    },
    disabled() {
      return isEmpty(this.bookings);
    }
  },
  methods: {
    ...mapActions("bookings", {
      createBulkBookings: CREATE_BULK_BOOKINGS
    }),
    ...mapActions({
      postGlobalMessage: POST_GLOBAL_MESSAGE
    }),
    close() {
      this.$emit("close");
    },
    async createBookings() {
      this.isCreating = true;
      try {
        await this.createBulkBookings({ data: this.bookings });
        this.$emit("success");
        this.clearData();
        this.postSuccessMessage();
      } finally {
        this.isCreating = false;
      }
    },
    postSuccessMessage() {
      const message = {
        snackbar: true,
        type: "success",
        title: `Bookings added.`
      };
      this.postGlobalMessage(message);
    },
    updateBookings(bookings) {
      this.bookings = bookings;
    },
    onPaste(event) {
      this.$refs.bulkBookings.onPaste(event);
    },
    clearData() {
      this.close();
      this.bookings = [];
    }
  }
};
</script>
