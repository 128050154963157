<template>
  <div class="container col-lg-12 col-sm-12 booking-shifts m-0 p-0">
    <ShiftListWrapper
      :isLoading="isLoading"
      :shifts="filteredShifts"
      :selectMultiple="selectMultiple"
      :filters="shiftFilters"
      :totalBookingShifts="totalBookingShifts"
      :hasDateFilter="hasDateFilter"
      @updateList="loadShifts"
    >
      <template v-slot:filters>
        <BookingShiftsFilter
          @filter="updateFilters"
          @onClearFilters="clearFilters"
          :isLoading="isLoading"
          :availableFilters="availableFilters"
          :outerSelectedFilters="shiftFilters"
        />
      </template>
    </ShiftListWrapper>
  </div>
</template>

<script>
// TODO Make this component reusable with CandidateShifts
import { createNamespacedHelpers } from "vuex";
import {
  BOOKINGS_NAMESPACE,
  FETCH_BOOKING_SHIFTS,
  FETCH_BOOKING_SHIFTS_V2
} from "@/store/modules/bookings/actions";
import {
  GET_BOOKING_SHIFTS,
  GET_CURRENT_BOOKING,
  IS_LOADING_BOOKING_SHIFTS,
  GET_TOTAL_BOOKING_SHIFTS
} from "@/store/modules/bookings/getters";
import {
  getDateTimeFiltersCount,
  getAllShiftUsers,
  getShiftStatusFilters,
  getShiftWorkerFilters,
  getFilteredShifts
} from "@/utils/shifts";
import ShiftListWrapper from "@/views/bookings/BookingDetails/components/Shifts/ShiftListWrapper";
import ClickOutside from "vue-click-outside";
import BookingShiftsFilter from "@/views/bookings/BookingDetails/components/BookingShiftsFilter";
import { get, isEmpty } from "lodash";
import { SHIFTS_STATUS } from "@/constants/shifts";

const { mapActions, mapGetters } = createNamespacedHelpers(BOOKINGS_NAMESPACE);

export default {
  name: "BookingShifts",
  components: {
    ShiftListWrapper,
    BookingShiftsFilter
  },
  data() {
    return {
      selectMultiple: false,
      selectedStatusFilter: "all",
      shiftFilters: {},
      availableFilters: {},
      shiftStatuses: {},
      showFilter: false,
      shiftUsers: [],
      filteredShifts: []
    };
  },
  created() {
    this.loadShifts();
  },
  computed: {
    ...mapGetters({
      bookingShifts: GET_BOOKING_SHIFTS,
      currentBooking: GET_CURRENT_BOOKING,
      isLoading: IS_LOADING_BOOKING_SHIFTS,
      totalBookingShifts: GET_TOTAL_BOOKING_SHIFTS
    }),
    currentBookingId() {
      return this.$route.params.id;
    },
    shiftFiltersCount() {
      return getDateTimeFiltersCount(this.shiftFilters);
    },
    filters() {
      return {
        filter: { ...this.shiftFilters }
      };
    },
    hasDateFilter() {
      return Boolean(
        get(this.shiftFilters, "startDate") || get(this.shiftFilters, "endDate")
      );
    }
  },
  methods: {
    ...mapActions({
      fetchBookingShifts: FETCH_BOOKING_SHIFTS,
      fetchBookingShiftsV2: FETCH_BOOKING_SHIFTS_V2
    }),
    async loadShifts() {
      await this.fetchBookingShiftsV2({
        id: this.currentBookingId
      });
      if (isEmpty(this.shiftFilters)) {
        this.shiftUsers = getAllShiftUsers(this.bookingShifts);
        this.availableFilters = this.getAvailableFilters();
      }
      this.setFilteredShift();
    },
    updateFilters(filters) {
      this.shiftFilters = Object.assign({}, filters);
      this.setFilteredShift();
    },
    setFilteredShift() {
      this.filteredShifts = getFilteredShifts(
        this.bookingShifts,
        this.shiftFilters
      );
    },
    clearFilters() {
      this.shiftFilters = {};
      this.setFilteredShift();
    },
    triggerCheckboxMode() {
      this.selectMultiple = !this.selectMultiple;
    },
    getAvailableFilters() {
      return {
        status: getShiftStatusFilters(SHIFTS_STATUS),
        worker: getShiftWorkerFilters(this.shiftUsers)
      };
    }
  },
  directives: {
    ClickOutside
  }
};
</script>

<style lang="scss"></style>
