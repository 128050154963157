import JSONApiService from "@/services/JSONApiService";
import { HIRE_REASON_MODEL } from "@/models/hire-reason-model";
import {
  disablePluralizeAsync,
  disablePluralizeForCall
} from "@/services/utils";
import { CLIENT, include } from "@/models/relationships";

export const DATA = "data";

const organisationReasonsApi = commit => ({
  getAll: organisationId => {
    const api = JSONApiService(commit);
    disablePluralizeForCall(api, () => api.all(DATA).all(HIRE_REASON_MODEL));
    return api.get({
      ...{ filter: { client_id: organisationId } },
      ...include(CLIENT)
    });
  },
  update: reason => {
    const api = JSONApiService(commit);
    return disablePluralizeAsync(api, () =>
      api
        .all(DATA)
        .one(HIRE_REASON_MODEL, reason.id)
        .patch(reason, include(CLIENT))
    );
  },
  create: reason => {
    const api = JSONApiService(commit);
    return disablePluralizeAsync(api, () =>
      api
        .all(DATA)
        .all(HIRE_REASON_MODEL)
        .post(reason, include(CLIENT))
    );
  },
  delete: id => {
    const api = JSONApiService(commit);
    disablePluralizeForCall(api, () =>
      api.all(DATA).one(HIRE_REASON_MODEL, id)
    );
    return api.destroy();
  }
});

export default organisationReasonsApi;
