export const SET_IS_FETCHING_REPORTS = "SET_IS_FETCHING_REPORTS";
export const SET_REPORTS = "SET_REPORTS";
export const ADD_REPORT = "ADD_REPORT";
export const ADD_REPORT_DATA = "ADD_REPORT_DATA";
export const SET_REPORT_CHARTS = "SET_REPORT_CHARTS";
export const SET_IS_FETCHING_REPORT_CHARTS = "SET_IS_FETCHING_REPORT_CHARTS";
export const SET_IS_CREATING_REPORT_CHART = "SET_IS_CREATING_REPORT_CHART";
export const SET_IS_EDITING_REPORT_CHART = "SET_IS_EDITING_REPORT_CHART";
export const SET_CURRENT_REPORT_CHART = "SET_CURRENT_REPORT_CHART";
export const SET_REPORTS_SHARED_WITH_USERS = "SET_REPORTS_SHARED_WITH_USERS";
export const SET_IS_FETCHING_SET_REPORTS_SHARED_WITH_USERS =
  "SET_IS_FETCHING_SET_REPORTS_SHARED_WITH_USERS";
