/* eslint-disable prettier/prettier */
export const GET_ORGANISATIONS = "GET_ORGANISATIONS";
export const GET_IS_FETCHING_ORGANISATIONS = "GET_IS_FETCHING_ORGANISATIONS";

const organisationsGetters = {
  [GET_ORGANISATIONS]: state => state.organisations,
  [GET_IS_FETCHING_ORGANISATIONS]: state => state.isOrganisationsLoading,
};

export default organisationsGetters;
