import {
  handleWithErrorMessage,
  handleWithGlobalMessage
} from "@/services/utils";
import api from "@/services/modules/applications";
import {
  SET_IS_FETCHING_SHIFT_CANDIDATES,
  SET_SHIFT_CANDIDATES,
  SET_IS_FETCHING_AVAILABLE_CANDIDATES,
  SET_AVAILABLE_CANDIDATES,
  SET_IS_FETCHING_APPLICATION_SHIFTS,
  SET_APPLICATION_SHIFTS,
  SET_APPLICATION_TO_REVIEW,
  SET_IS_FETCHING_APPLICATIONS,
  SET_UNAVAILABLE_CANDIDATES,
  SET_IS_FETCHING_UNAVAILABLE_CANDIDATES,
  SET_IS_FETCHING_UNAVAILABLE_WORKERS,
  SET_UNAVAILABLE_WORKERS
} from "@/store/modules/applications/mutations";
import { bookingCandidatesUpdater } from "@/store/modules/applications/utils";
import {
  SET_CURRENT_BOOKING_CANDIDATES,
  UPDATE_LOCAL_APPLICATION
} from "@/store/modules/bookings/mutations";
import { BOOKINGS_NAMESPACE } from "@/store/modules/bookings/actions";
import { GET_CURRENT_BOOKING_CANDIDATES } from "@/store/modules/bookings/getters";
import { fromBookings } from "@/store/modules/bookings";

export const ADD_ASSESSMENT = "ADD_ASSESSMENT";
export const REQUEST_INTERVIEW = "REQUEST_INTERVIEW";
export const WITHDRAW_CANDIDATE = "WITHDRAW_CANDIDATE";
export const ALLOCATE_WORKERS = "ALLOCATE_WORKERS";
export const ALLOCATE_CANDIDATES = "ALLOCATE_CANDIDATES";
export const SUBMIT_FOR_SHIFTS = "SUBMIT_FOR_SHIFTS";
export const SUBMIT_FOR_ALL_SHIFTS = "SUBMIT_FOR_ALL_SHIFTS";
export const CONFIRM_ALLOCATION = "CONFIRM_ALLOCATION";
export const DECLINE_ALLOCATION = "DECLINE_ALLOCATION";
export const WITHDRAW_FROM_SHIFTS = "WITHDRAW_FROM_SHIFTS";
export const WITHDRAW_SHIFTS = "WITHDRAW_SHIFTS";
export const APPROVE_CANDIDATE = "APPROVE_CANDIDATE";
export const SHORTLIST_CANDIDATE = "SHORTLIST_CANDIDATE";
export const REJECT_CANDIDATE = "REJECT_CANDIDATE";
export const SUBMIT_WORKERS = "SUBMIT_CANDIDATES";
export const FETCH_SHIFT_CANDIDATES = "FETCH_SHIFT_CANDIDATES";
export const UPDATE_APPLICATION = "UPDATE_APPLICATION";
export const UPDATE_APPLICATION_SHIFTS_STATUS =
  "UPDATE_APPLICATION_SHIFTS_STATUS";
export const FETCH_AVAILABLE_CANDIDATES = "FETCH_AVAILABLE_CANDIDATES";
export const FETCH_APPLICATION_SHIFTS = "FETCH_APPLICATION_SHIFTS";
export const FETCH_APPLICATION_TO_REVIEW = "FETCH_APPLICATION_TO_REVIEW";
export const FETCH_UNAVAILABLE_CANDIDATES = "FETCH_UNAVAILABLE_CANDIDATES";
export const FETCH_UNAVAILABLE_WORKERS = "FETCH_UNAVAILABLE_WORKERS";

const actions = {
  async [ADD_ASSESSMENT](
    { dispatch, commit, rootGetters },
    { applicationId, assessment }
  ) {
    return bookingCandidatesUpdater({
      dispatch,
      handler: async () => {
        const { data: createdAssessment } = await handleWithGlobalMessage(
          () => api(commit).addAssessment({ applicationId, assessment }),
          dispatch
        );
        const editedApplication = rootGetters[
          fromBookings(GET_CURRENT_BOOKING_CANDIDATES)
        ].find(({ id }) => id === applicationId);
        editedApplication.assessments = [
          ...editedApplication.assessments,
          createdAssessment
        ];
        commit(
          fromBookings(SET_CURRENT_BOOKING_CANDIDATES),
          editedApplication,
          { root: true }
        );
      }
    });
  },
  async [REQUEST_INTERVIEW]({ dispatch, commit }, applicationId) {
    return bookingCandidatesUpdater({
      dispatch,
      handler: () =>
        handleWithGlobalMessage(
          () => api(commit).requestInterview(applicationId),
          dispatch
        )
    });
  },
  async [ALLOCATE_WORKERS](
    { dispatch, commit },
    { applicationId, shifts, all, customSuccessMessage }
  ) {
    if (customSuccessMessage) {
      return handleWithErrorMessage({
        request: () =>
          api(commit).allocateWorkers({ applicationId, shifts, all }),
        dispatch
      });
    }
    return handleWithGlobalMessage(
      () => api(commit).allocateWorkers({ applicationId, shifts, all }),
      dispatch
    );
  },
  async [ALLOCATE_CANDIDATES](
    { dispatch, commit },
    { bookingId, shiftId, users }
  ) {
    return handleWithErrorMessage({
      request: () =>
        api(commit).allocateCandidates({ bookingId, shiftId, users }),
      dispatch
    });
  },
  async [WITHDRAW_CANDIDATE]({ dispatch, commit }, { applicationId }) {
    return bookingCandidatesUpdater({
      dispatch,
      handler: () =>
        handleWithGlobalMessage(
          () => api(commit).withdrawCandidate({ applicationId }),
          dispatch
        )
    });
  },
  async [APPROVE_CANDIDATE]({ dispatch, commit }, { applicationId }) {
    return bookingCandidatesUpdater({
      dispatch,
      handler: () =>
        handleWithGlobalMessage(
          () => api(commit).approveCandidate({ applicationId }),
          dispatch
        )
    });
  },
  async [SHORTLIST_CANDIDATE]({ dispatch, commit }, { applicationId }) {
    return bookingCandidatesUpdater({
      dispatch,
      handler: () =>
        handleWithGlobalMessage(
          () => api(commit).shortlistCandidate({ applicationId }),
          dispatch
        )
    });
  },
  async [REJECT_CANDIDATE]({ dispatch, commit }, { applicationId, reason }) {
    return bookingCandidatesUpdater({
      dispatch,
      handler: () =>
        handleWithGlobalMessage(
          () => api(commit).rejectCandidate({ applicationId, reason }),
          dispatch
        )
    });
  },
  async [SUBMIT_WORKERS]({ dispatch, commit }, { booking, user }) {
    return handleWithErrorMessage({
      request: () => api(commit).submitWorkers({ booking, user }),
      dispatch
    });
  },
  async [SUBMIT_FOR_SHIFTS]({ dispatch, commit }, { worker, shifts, all }) {
    return handleWithErrorMessage({
      request: () => api(commit).submitForShifts({ worker, shifts, all }),
      dispatch
    });
  },
  async [SUBMIT_FOR_ALL_SHIFTS]({ dispatch, commit }, { workers, bookingId }) {
    return handleWithErrorMessage({
      request: () => api(commit).submitForAllShifts({ workers, bookingId }),
      dispatch
    });
  },
  async [CONFIRM_ALLOCATION](
    { dispatch, commit },
    { applicationId, shifts, all }
  ) {
    return handleWithGlobalMessage(
      () => api(commit).confirmAllocation({ applicationId, shifts, all }),
      dispatch
    );
  },
  async [UPDATE_APPLICATION_SHIFTS_STATUS](
    { dispatch, commit },
    { applicationShifts, status, all, booking }
  ) {
    return handleWithGlobalMessage(
      () =>
        api(commit).updateApplicationShiftsStatus({
          applicationShifts,
          status,
          all,
          booking
        }),
      dispatch
    );
  },
  async [DECLINE_ALLOCATION](
    { dispatch, commit },
    { applicationId, shifts, all }
  ) {
    return handleWithGlobalMessage(
      () => api(commit).declineAllocation({ applicationId, shifts, all }),
      dispatch
    );
  },
  async [WITHDRAW_FROM_SHIFTS](
    { dispatch, commit },
    { applicationId, shifts, all }
  ) {
    return handleWithGlobalMessage(
      () => api(commit).withdrawFromShifts({ applicationId, shifts, all }),
      dispatch
    );
  },
  async [FETCH_SHIFT_CANDIDATES](
    { dispatch, commit },
    { bookingId, selectedShifts }
  ) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api(commit).getShiftCandidates(bookingId, selectedShifts),
      setLoadingFunction: isLoading =>
        commit(SET_IS_FETCHING_SHIFT_CANDIDATES, isLoading),
      setDataFunction: ({ data }) => commit(SET_SHIFT_CANDIDATES, data)
    });
  },
  async [FETCH_UNAVAILABLE_CANDIDATES]({ dispatch, commit }, params) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api(commit).getShiftUnavailableCandidates(params),
      setLoadingFunction: isLoading =>
        commit(SET_IS_FETCHING_UNAVAILABLE_CANDIDATES, isLoading),
      setDataFunction: ({ data }) => commit(SET_UNAVAILABLE_CANDIDATES, data)
    });
  },
  async [FETCH_AVAILABLE_CANDIDATES]({ dispatch, commit }, bookingId) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api(commit).getBookingAvailableCandidates(bookingId),
      setLoadingFunction: isLoading =>
        commit(SET_IS_FETCHING_AVAILABLE_CANDIDATES, isLoading),
      setDataFunction: ({ data }) => commit(SET_AVAILABLE_CANDIDATES, data)
    });
  },
  async [UPDATE_APPLICATION]({ dispatch, commit }, editedApplication) {
    const { data: fetchedApplication } = await handleWithGlobalMessage(
      () => api(commit).updateApplication(editedApplication),
      dispatch
    );
    commit(
      `${BOOKINGS_NAMESPACE}/${UPDATE_LOCAL_APPLICATION}`,
      fetchedApplication,
      { root: true }
    );
  },
  async [FETCH_APPLICATION_SHIFTS](
    { dispatch, commit },
    { applicationId, params }
  ) {
    return handleWithErrorMessage({
      dispatch,
      request: () =>
        api(commit).getApplicationShifts({ applicationId, params }),
      setLoadingFunction: isLoading =>
        commit(SET_IS_FETCHING_APPLICATION_SHIFTS, isLoading),
      setDataFunction: ({ data }) => {
        commit(SET_APPLICATION_SHIFTS, data);
      }
    });
  },
  async [FETCH_APPLICATION_TO_REVIEW]({ dispatch, commit }, { client_id }) {
    return handleWithErrorMessage({
      dispatch,
      request: () =>
        api({ commit }).getApplications({
          filter: { to_review_unsorted: true, client_id }
        }),
      setLoadingFunction: isLoading =>
        commit(SET_IS_FETCHING_APPLICATIONS, isLoading),
      setDataFunction: ({ data }) => commit(SET_APPLICATION_TO_REVIEW, data)
    });
  },
  async [FETCH_UNAVAILABLE_WORKERS]({ dispatch, commit }, params) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api(commit).getBookingUnavailableWorkers(params),
      setLoadingFunction: isLoading =>
        commit(SET_IS_FETCHING_UNAVAILABLE_WORKERS, isLoading),
      setDataFunction: ({ data }) => commit(SET_UNAVAILABLE_WORKERS, data)
    });
  }
};

export default actions;
