import JSONApiService from "@/services/JSONApiService";
import { ORGANISATION_MODEL } from "@/models/organisation-model";
import { include, PARENT } from "@/models/relationships";

const organisationApi = ({ commit } = {}) => ({
  getOrganisations: params =>
    JSONApiService(commit)
      .all(ORGANISATION_MODEL)
      .get({ ...params, ...include(PARENT) }),
  createOrganisation: data =>
    JSONApiService(commit).create(ORGANISATION_MODEL, data),
  updateOrganisation: ({
    id,
    companyRegistrationNumber,
    name,
    organisationType,
    addresses
  }) =>
    JSONApiService(commit)
      .one(ORGANISATION_MODEL, id)
      .patch({
        companyRegistrationNumber,
        name,
        organisationType,
        addresses
      })
});

export default organisationApi;
