import api from "@/services/modules/data";
import { handleWithErrorMessage } from "@/services/utils";
import {
  SET_BOOKING_TEMPLATE,
  SET_BOOKING_TEMPLATES,
  SET_COMPLIANCE_REQUIREMENTS,
  SET_OTHER_REQUIREMENTS,
  SET_COUNTRIES,
  SET_INCOME_TYPES,
  SET_IS_FETCHING_DATA,
  SET_LOCATIONS,
  SET_REASONS_FOR_HIRE,
  SET_REJECTION_REASONS,
  SET_REQUIREMENTS,
  SET_FIELD_NECESSITIES,
  SET_IS_FETCHING_FIELD_NECESSITIES,
  SET_IS_FETCHING_OTHER_REQUIREMENTS,
  SET_IS_FETCHING_COMPLIANCE_REQUIREMENTS,
  SET_PUBLISHING_SCHEDULE_TAGS,
  SET_IS_FETCHING_PUBLISHING_SCHEDULE_TAGS,
  SET_IS_FETCHING_COST_CATEGORIES,
  SET_COST_CATEGORIES,
  SET_IS_FETCHING_TIME_UNITS,
  SET_TIME_UNITS,
  SET_SYSTEM_PARAMETERS
} from "@/store/modules/data/mutations";

export const FETCH_BOOKING_TEMPLATES = "FETCH_BOOKING_TEMPLATES";
export const FETCH_BOOKING_TEMPLATE = "FETCH_BOOKING_TEMPLATE";
export const FETCH_INCOME_TYPES = "FETCH_INCOME_TYPES";
export const FETCH_LOCATIONS = "FETCH_LOCATIONS";
export const FETCH_REASONS_FOR_HIRE = "FETCH_REASONS_FOR_HIRE";
export const FETCH_REJECTION_REASONS = "FETCH_REJECTION_REASONS";
export const FETCH_COUNTRIES = "FETCH_COUNTRIES";
export const FETCH_COMPLIANCE_REQUIREMENTS = "FETCH_COMPLIANCE_REQUIREMENTS";
export const FETCH_OTHER_REQUIREMENTS = "FETCH_OTHER_REQUIREMENTS";
export const FETCH_REQUIREMENTS = "FETCH_REQUIREMENTS";
export const FETCH_FIELD_NECESSITIES = "FETCH_FIELD_NECESSITIES";
export const FETCH_PUBLISHING_SCHEDULE_TAGS = "FETCH_PUBLISHING_SCHEDULE_TAGS";
export const FETCH_COST_CATEGORIES = "FETCH_COST_CATEGORIES";
export const REMOVE_FILE = "REMOVE_FILE";
export const FETCH_TIME_UNITS = "FETCH_TIME_UNITS";
export const FETCH_SYSTEM_PARAMETERS = "FETCH_SYSTEM_PARAMETERS";

const dataActions = {
  async [FETCH_BOOKING_TEMPLATES]({ dispatch, commit }) {
    return handleWithErrorMessage({
      dispatch,
      request: api(commit).getBookingTemplates,
      setLoadingFunction: isLoading => commit(SET_IS_FETCHING_DATA, isLoading),
      setDataFunction: ({ data }) => commit(SET_BOOKING_TEMPLATES, data)
    });
  },
  async [FETCH_BOOKING_TEMPLATE]({ dispatch, commit }, id) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api(commit).getBookingTemplate(id),
      setLoadingFunction: isLoading => commit(SET_IS_FETCHING_DATA, isLoading),
      setDataFunction: ({ data }) => commit(SET_BOOKING_TEMPLATE, data)
    });
  },
  async [FETCH_COMPLIANCE_REQUIREMENTS]({ dispatch, commit }) {
    return handleWithErrorMessage({
      dispatch,
      request: api(commit).getComplianceRequirements,
      setLoadingFunction: isLoading =>
        commit(SET_IS_FETCHING_COMPLIANCE_REQUIREMENTS, isLoading),
      setDataFunction: ({ data }) => commit(SET_COMPLIANCE_REQUIREMENTS, data)
    });
  },
  async [FETCH_OTHER_REQUIREMENTS]({ dispatch, commit }) {
    return handleWithErrorMessage({
      dispatch,
      request: api(commit).getOtherRequirements,
      setLoadingFunction: isLoading =>
        commit(SET_IS_FETCHING_OTHER_REQUIREMENTS, isLoading),
      setDataFunction: ({ data }) => commit(SET_OTHER_REQUIREMENTS, data)
    });
  },
  async [FETCH_INCOME_TYPES]({ dispatch, commit }) {
    return handleWithErrorMessage({
      dispatch,
      request: api(commit).getIncomeTypes,
      setLoadingFunction: isLoading => commit(SET_IS_FETCHING_DATA, isLoading),
      setDataFunction: ({ data }) => commit(SET_INCOME_TYPES, data)
    });
  },
  async [FETCH_LOCATIONS]({ dispatch, commit }, params = {}) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api(commit).getLocations(params),
      setLoadingFunction: isLoading => commit(SET_IS_FETCHING_DATA, isLoading),
      setDataFunction: ({ data }) => commit(SET_LOCATIONS, data)
    });
  },
  async [FETCH_REASONS_FOR_HIRE]({ dispatch, commit }, organisationId) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api(commit).getReasonsForHire(organisationId),
      setLoadingFunction: isLoading => commit(SET_IS_FETCHING_DATA, isLoading),
      setDataFunction: ({ data }) => commit(SET_REASONS_FOR_HIRE, data)
    });
  },
  async [FETCH_REJECTION_REASONS]({ dispatch, commit }) {
    return handleWithErrorMessage({
      dispatch,
      request: api(commit).getRejectionReasons,
      setLoadingFunction: isLoading => commit(SET_IS_FETCHING_DATA, isLoading),
      setDataFunction: ({ data }) => commit(SET_REJECTION_REASONS, data)
    });
  },
  async [FETCH_REQUIREMENTS]({ dispatch, commit }) {
    return handleWithErrorMessage({
      dispatch,
      request: api(commit).getRequirements,
      setLoadingFunction: isLoading => commit(SET_IS_FETCHING_DATA, isLoading),
      setDataFunction: data => commit(SET_REQUIREMENTS, data)
    });
  },
  async [FETCH_COUNTRIES]({ dispatch, commit }) {
    return handleWithErrorMessage({
      dispatch,
      request: api(commit).getCountries,
      setLoadingFunction: isLoading => commit(SET_IS_FETCHING_DATA, isLoading),
      setDataFunction: ({ data }) => commit(SET_COUNTRIES, data)
    });
  },
  async [FETCH_FIELD_NECESSITIES]({ dispatch, commit }, params) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api(commit).getFieldNecessities(params),
      setLoadingFunction: isLoading =>
        commit(SET_IS_FETCHING_FIELD_NECESSITIES, isLoading),
      setDataFunction: ({ data }) => commit(SET_FIELD_NECESSITIES, data)
    });
  },
  async [FETCH_PUBLISHING_SCHEDULE_TAGS]({ dispatch, commit }) {
    return handleWithErrorMessage({
      dispatch,
      request: api(commit).getPublishingScheduleTags,
      setLoadingFunction: isLoading =>
        commit(SET_IS_FETCHING_PUBLISHING_SCHEDULE_TAGS, isLoading),
      setDataFunction: ({ data }) => commit(SET_PUBLISHING_SCHEDULE_TAGS, data)
    });
  },
  async [FETCH_COST_CATEGORIES]({ dispatch, commit }) {
    return handleWithErrorMessage({
      dispatch,
      request: api(commit).getCostCategories,
      setLoadingFunction: isLoading =>
        commit(SET_IS_FETCHING_COST_CATEGORIES, isLoading),
      setDataFunction: ({ data }) => commit(SET_COST_CATEGORIES, data)
    });
  },
  async [REMOVE_FILE]({ dispatch, commit }, url) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api(commit).removeFile(url)
    });
  },
  async [FETCH_TIME_UNITS]({ dispatch, commit }, params = {}) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api(commit).getTimeUnits(params),
      setLoadingFunction: isLoading =>
        commit(SET_IS_FETCHING_TIME_UNITS, isLoading),
      setDataFunction: ({ data }) => commit(SET_TIME_UNITS, data)
    });
  },
  async [FETCH_SYSTEM_PARAMETERS]({ dispatch, commit }, params = {}) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api(commit).getSystemParameters(params),
      setDataFunction: ({ data }) => commit(SET_SYSTEM_PARAMETERS, data)
    });
  }
};

export default dataActions;
