<template>
  <div class="confirm-allocation">
    <div class="confirm-allocation-content col-8 ml-auto mr-auto">
      <h2 class="mb-2">You are unable to confirm these allocations</h2>
      <p class="secondary-text">
        One or more of the workers in the selected shifts does not meet the
        compliance requirements, please update their profiles before continuing
      </p>
      <div v-for="(worker, index) in workers" :key="index" class="mb-8 d-flex">
        <div class="icon-wrapper">
          <v-icon>mdi-account</v-icon>
        </div>
        <div>
          <h3>{{ worker.worker }}</h3>
          <div>Doesn't meet the following requirements:</div>
          <div
            v-for="(complianceCheck, index) in worker.notCompletedCompliance"
            :key="index"
          >
            {{ index + 1 }}. {{ complianceCheck }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AllocationList",
  props: {
    workers: Array
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss">
.confirm-allocation {
  display: flex;
  flex-direction: column;
  > h2 {
    font-size: 32px;
  }
  > p {
    font-size: 22px;
  }

  .confirm-allocation-content {
    padding-bottom: 90px;
  }

  .icon-wrapper {
    background-color: rgba(94, 122, 141, 0.1);
    padding: 12px 12px;
    border-radius: 4px;
    margin-right: 14px;
    width: 50px;
    height: 50px;

    .v-icon {
      margin: auto;
      width: 100%;
    }
  }
}
</style>
