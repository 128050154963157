<template>
  <div>
    <component
      :key="fieldData.field"
      :is="component"
      :value="value"
      :onChange="onChange"
      :fieldType="fieldType"
      :filterType="filterType"
      :unit="unit"
      :onUnitChange="onUnitChange"
      :focus="focus"
      :field="fieldData.field"
      :intervals="fieldData.intervals"
      :errorMessages="errorMessages"
    />
  </div>
</template>

<script>
import ReportSingleInputFilter from "./ReportSingleInputFilter";
import ReportBetweenInputFilter from "./ReportBetweenInputFilter";
import ReportSingleDateFilter from "./ReportSingleDateFilter";
import ReportBetweenDateFilter from "./ReportBetweenDateFilter";
import ReportUnitDateFilter from "./ReportUnitDateFilter";

export default {
  name: "ReportFilterValueInput",
  components: {
    ReportSingleInputFilter,
    ReportBetweenInputFilter,
    ReportSingleDateFilter,
    ReportBetweenDateFilter,
    ReportUnitDateFilter
  },
  props: {
    value: String,
    onChange: Function,
    fieldType: String,
    filterType: String,
    unit: String,
    onUnitChange: Function,
    focus: String,
    fieldData: Object,
    errorMessages: [String, Array]
  },
  computed: {
    component() {
      const dateTime = this.fieldType === "datetime";
      const between = this.filterType === "between";
      const withUnit =
        this.filterType === "in_last_date_range" ||
        this.filterType === "in_next_date_range";
      return {
        [!between && !dateTime]: ReportSingleInputFilter,
        [between && !dateTime]: ReportBetweenInputFilter,
        [!between && dateTime]: ReportSingleDateFilter,
        [between && dateTime]: ReportBetweenDateFilter,
        [withUnit && dateTime]: ReportUnitDateFilter
      }[true];
    }
  }
};
</script>
