<template>
  <BaseAction>
    <SecondaryButton @click.native="print" textColor="#d10a3a">
      <inline-svg
        :src="require('@/assets/svg/file__icon-pdf.svg')"
        width="25"
      />
      Print Timesheet(s)
    </SecondaryButton>
  </BaseAction>
</template>

<script>
import BaseAction from "@/components/common/BaseAction";
import SecondaryButton from "@/components/common/Button/SecondaryButton";
import InlineSvg from "vue-inline-svg";
import {
  PRINTABLE_TIMESHEET,
  BOOKINGS_NAMESPACE
} from "@/store/modules/bookings/actions";
import { createNamespacedHelpers } from "vuex";

const { mapActions } = createNamespacedHelpers(BOOKINGS_NAMESPACE);

export default {
  name: "PrintTimesheetAction",
  components: {
    BaseAction,
    SecondaryButton,
    InlineSvg
  },
  props: {
    shifts: Array
  },
  data() {
    return {
      isLoading: false
    };
  },
  methods: {
    ...mapActions({
      printableTimesheet: PRINTABLE_TIMESHEET
    }),
    async print() {
      this.isLoading = true;
      try {
        await this.printableTimesheet({ shifts: this.shifts });
        this.$emit("close", { updateList: true });
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>
