<template>
  <BaseDetails class="base-details">
    <template v-slot:header>
      <h2 class="pb-3">Basic Details</h2>
      <Permissioned section="settings" v-if="!isLoading">
        <EditBasicDetailsAction
          permission="edit-booking-templates"
          :bookingTemplate="bookingTemplate"
        />
      </Permissioned>
    </template>
    <template v-slot:body>
      <div v-if="isLoading">
        <v-skeleton-loader
          v-for="i in 4"
          :key="i"
          width="100%"
          type="heading, list-item"
        />
      </div>
      <div v-else-if="!isEmpty(bookingTemplate)">
        <LabeledText label="Client">
          {{ bookingTemplate.client.name }}
        </LabeledText>
        <LabeledText label="Name">
          {{ bookingTemplate.jobTitle }}
        </LabeledText>
        <LabeledText
          label="Compliance Requirements"
          permission="compliance-requirements"
        >
          <div v-if="bookingTemplate.complianceRequirements.length">
            <v-chip
              v-for="(requirement,
              index) in bookingTemplate.complianceRequirements"
              :key="index"
              label
            >
              {{ requirement }}
            </v-chip>
          </div>
        </LabeledText>
        <LabeledText label="Other Requirements">
          <div v-if="bookingTemplate.otherRequirements.length">
            <v-chip
              v-for="requirement in bookingTemplate.otherRequirements"
              :key="requirement"
              label
            >
              {{ requirement }}
            </v-chip>
          </div>
        </LabeledText>
        <LabeledText label="Years of references required">
          {{ bookingTemplate.yearsReferencesRequired }}
        </LabeledText>
        <LabeledText label="Description">
          {{ bookingTemplate.description || "-" }}
        </LabeledText>
        <LabeledText label="Time Unit">
          {{ bookingTemplate.timeUnitName | capitalize }}
        </LabeledText>
      </div>
    </template>
  </BaseDetails>
</template>

<script>
import LabeledText from "@/components/common/BaseDetails/LabeledText";
import BaseDetails from "@/components/common/BaseDetails/BaseDetails";
import { isEmpty } from "lodash";
import Permissioned from "@/components/common/Permissioned";
import EditBasicDetailsAction from "@/views/settings/Organisation/BookingTemplates/actions/EditBasicDetailsAction";

export default {
  name: "BasicDetails",
  components: {
    LabeledText,
    BaseDetails,
    EditBasicDetailsAction,
    Permissioned
  },
  props: {
    bookingTemplate: {
      type: Object,
      required: true
    },
    isLoading: Boolean
  },
  methods: {
    isEmpty
  }
};
</script>

<style lang="scss">
.base-details {
  .v-chip {
    margin: 0.5rem 0.5rem 0 0;
    font-weight: bold;
  }

  .labeled-text .content {
    position: relative;
  }

  .sublabel {
    position: absolute;
    top: 0;
    left: 95px;
    width: 300px;
  }
}
</style>
