import { map, pick, reduce, forEach } from "lodash";
import { formatDate } from "@/utils/time";
import moment from "moment";

export const getAwrStatus = isAWR => (isAWR ? "Post-AWR" : "Pre-AWR");

export const getFormattedReferences = worker => {
  let { references } = worker;
  return (references = map(references, reference => {
    const formattedReference = pick(reference, [
      "id",
      "type",
      "referenceTypeName",
      "periodStartDate",
      "periodEndDate",
      "referenceReceivedDate"
    ]);
    formattedReference.periodStartDate = formatDate({
      date: reference.periodStartDate
    });
    formattedReference.periodEndDate = formatDate({
      date: reference.periodEndDate
    });
    formattedReference.referenceReceivedDate = formatDate({
      date: reference.referenceReceivedDate
    });

    return formattedReference;
  }));
};

export const getFormattedComplianceChecks = worker => {
  let { complianceChecks } = worker;

  return (complianceChecks = map(complianceChecks, compliance => {
    const formattedCompliance = pick(compliance, [
      "id",
      "type",
      "complianceCheckName",
      "checkVerified",
      "documentTypeName",
      "documentReferenceNumber",
      "documentValidFromDate",
      "documentValidToDate",
      "registeringBody",
      "checkHasUpdateService",
      "files"
    ]);
    formattedCompliance.documentValidFromDate = formatDate({
      date: compliance.documentValidFromDate
    });
    formattedCompliance.documentValidToDate = formatDate({
      date: compliance.documentValidToDate
    });

    return formattedCompliance;
  }));
};

export const findComplianceChecks = (worker, complianceName) => {
  const foundCompliance = worker.complianceChecks.find(
    compliance => compliance.complianceCheckName === complianceName
  );
  return foundCompliance ? foundCompliance : {};
};

export const getWorkerData = worker =>
  pick(worker, [
    "id",
    "type",
    "firstName",
    "lastName",
    "dob",
    "email",
    "phone",
    "gender",
    "cv",
    "awr",
    "dda",
    "countryOfBirth",
    "incomeTypeName",
    "ethnicGroupId",
    "incomeType",
    "utr",
    "yearsReferenced",
    "complianceSupportingFile",
    "ethnicOrigin",
    "address",
    "company",
    "companyAddress",
    "complianceChecks",
    "references",
    "addresses",
    "intermediaryOrganisation",
    "userComplianceChecks",
    "userReferences"
  ]);

export const getCompanyData = company =>
  pick(company, [
    "id",
    "type",
    "name",
    "organisationType",
    "companyRegistrationNumber"
  ]);
export const getAddressData = address =>
  pick(address, [
    "id",
    "type",
    "line1",
    "line2",
    "line3",
    "postCode",
    "country"
  ]);

export const getComplianceChecks = compliances => {
  return map(compliances, compliance =>
    pick(compliance, [
      "id",
      "type",
      "complianceCheckName",
      "checkVerified",
      "documentTypeName",
      "documentReferenceNumber",
      "documentValidFromDate",
      "documentValidToDate",
      "registeringBody",
      "checkHasUpdateService",
      "files"
    ])
  );
};

export const getColorByStatus = status => {
  switch (status) {
    case "active":
      return "green";
    case "invited":
      return "yellow";
    default:
      return "red"; // for 'inactive' and any other states not listed
  }
};

const getHeaderField = headers => {
  return map(headers, header => {
    switch (header.toLowerCase()) {
      case "first name":
        return "firstName";
      case "last name":
        return "lastName";
      case "unique id 1":
        return "agency_worker_reference";
      case "unique id 2":
        return "client_worker_reference";
      default:
        return "";
    }
  });
};

export const getWorkersToCreate = (headers, groupedItems) => {
  const fields = getHeaderField(headers);
  return reduce(
    groupedItems,
    (formattedItems, items, index) => {
      let formattedItem = {};
      forEach(items, (item, key) => {
        formattedItem[fields[key]] = item;
      });
      const email = `worker${moment().format(
        "YYYYMMDDHHMMsSSS"
      )}-${index}@example.com`;
      formattedItem = { ...formattedItem, email };
      return [...formattedItems, formattedItem];
    },
    []
  );
};

export const formatWorkersToBePlace = (headers, workers) => {
  const fields = getHeaderField(headers);
  return reduce(
    workers,
    (formattedItems, worker) => {
      const newItem = map(fields, field => {
        const key =
          field === "agency_worker_reference"
            ? "agencyWorkerReference"
            : field === "client_worker_reference"
            ? "clientWorkerReference"
            : field;
        return worker[key];
      });
      return [...formattedItems, newItem];
    },
    []
  );
};
