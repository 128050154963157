<template>
  <div class="confirm-allocation">
    <div class="confirm-allocation-content col-8 ml-auto mr-auto">
      <h2>{{ title }}</h2>
      <p class="secondary-text">
        {{ message }}
      </p>
      <div v-for="shift in shifts" :key="shift.id" class="mb-8">
        <ShiftLink :shift-id="shift.id" />
        <h3>{{ shift.startDate | startDate }}</h3>
        <p class="secondary-text">
          {{ shift.startDate | hourAndMinutes }}-{{
            shift.endDate | hourAndMinutes
          }}
        </p>
        <div v-if="isV2">
          <StatusItem
            v-for="applicationShift in shift.applicationShifts"
            :key="applicationShift.id"
            class="allocation-select-item mb-2"
            :class="{ selected: isSelected(applicationShift.id) }"
            @click.native="selectWorkerV2(applicationShift.id)"
          >
            <template v-slot:icon>
              <div class="icon-wrapper">
                <v-icon>mdi-account</v-icon>
              </div>
            </template>
            <template>
              {{ applicationShift.application.user | fullName }}
            </template>
          </StatusItem>
        </div>
        <div v-else>
          <StatusItem
            v-for="worker in shift.workers"
            :key="worker.id"
            class="allocation-select-item mb-2"
            :class="{ selected: isSelected(worker.applicationId) }"
            @click.native="selectWorker(worker)"
          >
            <template v-slot:icon>
              <div class="icon-wrapper">
                <v-icon>mdi-account</v-icon>
              </div>
            </template>
            <template>
              {{ worker.fullName }}
            </template>
          </StatusItem>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ShiftLink from "@/components/bookings/ShiftLink";
import StatusItem from "@/components/common/StatusItem";
import { API_DATE_FORMAT } from "@/constants/common";
import moment from "moment";
import { includes, filter } from "lodash";

export default {
  name: "AllocationList",
  components: {
    StatusItem,
    ShiftLink
  },
  props: {
    shifts: Array,
    title: String,
    message: String,
    isV2: Boolean
  },
  data() {
    return {
      selectedApplicationIds: []
    };
  },
  methods: {
    selectWorker(worker) {
      if (this.isSelected(worker.applicationId)) {
        this.unSelectWorker(worker.applicationId);
      } else {
        this.selectedApplicationIds.push(worker.applicationId);
      }
      this.emitSelectedApplicationIds();
    },
    selectWorkerV2(applicationId) {
      if (this.isSelected(applicationId)) {
        this.unSelectWorker(applicationId);
      } else {
        this.selectedApplicationIds.push(applicationId);
      }
      this.emitSelectedApplicationIds();
    },
    unSelectWorker(applicationId) {
      this.selectedApplicationIds = filter(
        this.selectedApplicationIds,
        selectedApplicationId => selectedApplicationId !== applicationId
      );
      this.emitSelectedApplicationIds();
    },
    isSelected(applicationId) {
      return includes(this.selectedApplicationIds, applicationId);
    },
    emitSelectedApplicationIds() {
      this.$emit("onSelectApplicationIds", this.selectedApplicationIds);
    }
  },
  filters: {
    startDate(date) {
      return moment(date, API_DATE_FORMAT).format("ddd Do MMM");
    }
  }
};
</script>

<style lang="scss">
.confirm-allocation {
  display: flex;
  flex-direction: column;
  > h2 {
    font-size: 32px;
  }
  > p {
    font-size: 22px;
  }

  .confirm-allocation-content {
    padding-bottom: 90px;
  }

  .icon-wrapper {
    background-color: rgba(94, 122, 141, 0.1);
    padding: 6px 8px;
    border-radius: 4px;
    margin-right: 14px;
    width: 40px;
    height: 40px;

    .v-icon {
      margin: auto;
      width: 100%;
    }
  }

  .allocation-select-item {
    padding: 5px;
    cursor: pointer;
    &:hover {
      background-color: rgba(94, 122, 141, 0.1);
    }
  }

  .selected {
    background-color: rgba(94, 122, 141, 0.1);
  }

  .footer {
    position: fixed;
    width: 100%;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    padding: 0.5rem;
    left: 0;

    @media only screen and (max-width: $mobile-breakpoint - 1) {
      justify-content: center;
    }
  }
}
</style>
