<template>
  <AppLayout
    v-if="booking"
    class="tabs-container"
    parent="/bookings"
    :title="booking.title"
    isDetails
    centered
  >
    <template v-slot:backButtonText>Bookings</template>
    <template v-slot:header>
      <div class="booking-details-header content__header__more">
        <Chip :status="booking.status">
          {{ booking.status }}
        </Chip>
        <div class="entity-id">B-{{ currentBookingId }}</div>
      </div>
    </template>
    <template v-slot:body>
      <div class="subheader d-flex flex-column">
        <v-tabs
          centered
          v-model="selectedTab"
          class="booking-tabs"
          id="booking-tabs"
          @change="handleTabChange"
        >
          <v-tab v-for="section in bookingSections" :key="section">
            {{ section | capitalize }}
          </v-tab>
        </v-tabs>
      </div>
      <div class="container booking-tab-view col-lg-10 col-sm-12 pt-0">
        <router-view
          v-if="booking"
          :currentBooking="booking"
          :submittedWorkerIds="submittedWorkerIds"
        />
      </div>
    </template>
  </AppLayout>
  <v-overlay v-else z-index="9">
    <v-progress-circular indeterminate size="64" />
  </v-overlay>
</template>

<script>
import AppLayout from "@/components/common/base-layouts/AppLayout";
import {
  GET_CURRENT_BOOKING,
  IS_LOADING_BOOKING
} from "@/store/modules/bookings/getters";
import {
  BOOKINGS_NAMESPACE,
  FETCH_CURRENT_BOOKING,
  FETCH_BUDGETS,
  CLEAR_BUDGET
} from "@/store/modules/bookings/actions";
import { createNamespacedHelpers } from "vuex";
import Chip from "@/components/common/Chip";
import { CLEAR_BOOKING_DETAILS } from "@/store/modules/bookings/mutations";
import { SET_MODULE_PERMISSIONS_AS_CURRENT } from "@/store/modules/auth/mutation-types";
import { AUTH_NAMESPACE } from "@/store/modules/auth";
import { MAIN_NAVIGATION_ITEMS } from "@/router/constants";
import { includes } from "lodash";
import { CONTRACT_TYPE_NAMES } from "@/constants/bookings";

const { mapActions, mapGetters, mapMutations } = createNamespacedHelpers(
  BOOKINGS_NAMESPACE
);
const { mapMutations: mapAuthMutations, mapState } = createNamespacedHelpers(
  AUTH_NAMESPACE
);
const { BOOKINGS } = MAIN_NAVIGATION_ITEMS;
const TAB_INDEXES = {
  DETAILS: 0,
  CANDIDATES: 1,
  SHIFTS: 2,
  TIMESHEETS: 3
};

export default {
  name: "BookingTabs",
  components: {
    Chip,
    AppLayout
  },
  data() {
    return {
      savedBookingId: null,
      selectedTab: null,
      submittedWorkerIds: []
    };
  },
  mounted() {
    if (this.booking) {
      const tabsElement = this.$el.querySelector("#booking-tabs");
      const mainHeader = this.$el.querySelector("header");
      tabsElement.style.top = `${mainHeader.offsetHeight - 4}px`;
    }
  },
  computed: {
    ...mapState({
      permissions: state => state.permissions
    }),
    ...mapGetters({
      booking: GET_CURRENT_BOOKING,
      isLoadingBooking: IS_LOADING_BOOKING
    }),
    currentBookingId() {
      return this.$route.params.id;
    },
    initialSection() {
      const path = this.$router.currentRoute.path;
      switch (true) {
        case includes(path, "candidates"):
          return TAB_INDEXES.CANDIDATES;
        case includes(path, "shifts"):
          return TAB_INDEXES.SHIFTS;
        case includes(path, "timesheets"):
          return TAB_INDEXES.TIMESHEETS;
        case includes(path, "details"):
        default:
          return TAB_INDEXES.DETAILS;
      }
    },
    bookingSections() {
      return [
        "details",
        ...(this.isLiveOrClosed && this.canViewCandidates
          ? ["candidates"]
          : []),
        ...(this.isLiveOrClosed && !this.isFixedTerm ? ["shifts"] : []),
        ...(this.isLiveOrClosed && this.canViewTimesheets ? ["timesheets"] : [])
      ];
    },
    isLiveOrClosed() {
      return this.booking.status === "live" || this.booking.status === "closed";
    },
    canViewCandidates() {
      return this.permissions["applications.view"];
    },
    canViewTimesheets() {
      return this.permissions["timesheets.view"];
    },
    budgetParams() {
      return {
        filter: {
          ...(this.booking.poNumber && { po_number: this.booking.poNumber }),
          ...(this.booking.rootClient && {
            organisation_unit_id: this.booking.rootClient.id
          })
        }
      };
    },
    shouldFetchBudgets() {
      return this.booking.poNumber && this.booking.rootClient;
    },
    isFixedTerm() {
      return this.booking.contractType === CONTRACT_TYPE_NAMES.FIXED_TERM;
    }
  },
  async created() {
    this.setModulePermissionsAsCurrent({
      module: "bookings",
      id: this.currentBookingId
    });
    this.selectedTab = this.initialSection;
    this.clearBookingDetails(this.currentBookingId);
    try {
      await this.fetchCurrentBooking(this.currentBookingId);
    } catch ({ status }) {
      if (status && status === 404) {
        this.$router.push({ name: "pageNotFound" });
      }
    }
    this.shouldFetchBudgets
      ? this.fetchBudgets(this.budgetParams)
      : this.clearBudgets();
  },
  methods: {
    ...mapActions({
      fetchCurrentBooking: FETCH_CURRENT_BOOKING,
      fetchBudgets: FETCH_BUDGETS,
      clearBudgets: CLEAR_BUDGET
    }),
    ...mapMutations({
      clearBookingDetails: CLEAR_BOOKING_DETAILS
    }),
    ...mapAuthMutations({
      setModulePermissionsAsCurrent: SET_MODULE_PERMISSIONS_AS_CURRENT
    }),
    handleTabChange(selectedTab) {
      const tabName = this.bookingSections[selectedTab];
      this.$router.replace(`/${BOOKINGS}/${this.currentBookingId}/${tabName}`);
    }
  },
  provide() {
    return {
      currentBookingId: this.currentBookingId
    };
  }
};
</script>

<style lang="scss"></style>
