<template>
  <div>
    <div v-if="isFetchingData">
      <v-skeleton-loader
        class="mt-4"
        v-for="i in 5"
        type="list-item-avatar-two-line"
        :key="i"
      />
    </div>
    <div>
      <ActionItem
        type="bookingsToApprove"
        v-if="bookingsToApprove.length"
        :items="bookingsToApprove"
        :isFetching="isFetchingBookings"
      />
      <ActionItem
        type="bookingsToAccept"
        v-if="bookingsToAccept.length"
        :items="bookingsToAccept"
        :isFetching="isFetchingBookings"
      />
    </div>
    <div class="worker-applications">
      <ActionItem
        type="applicationsToReview"
        v-if="applicationsToReview.length"
        :items="applicationsToReview"
        :isFetching="isFetchingApplications"
      />
    </div>
    <div>
      <ActionItem
        type="bookingsWithShiftsToApprove"
        v-if="bookingsWithShiftsToApprove.length"
        :items="bookingsWithShiftsToApprove"
        :isFetching="isFetchingBookings"
      />
      <ActionItem
        type="bookingExtensionsToApprove"
        v-if="bookingExtensionsToApprove.length"
        :items="bookingExtensionsToApprove"
        :isFetching="isFetchingBookings"
      />
    </div>
    <ActionItem
      type="timesheetsToApprove"
      v-if="timesheetsToApprove.length"
      :items="timesheetsToApprove"
      :isFetching="isFetchingTimesheets"
    />
    <ActionItem
      type="timesheetsToVerify"
      v-if="timesheetsToVerify.length"
      :items="timesheetsToVerify"
      :isFetching="isFetchingTimesheets"
    />
  </div>
</template>

<script>
import ActionItem from "@/components/dashboard/action-list/ActionItem";
import {
  BOOKINGS_NAMESPACE,
  FETCH_BOOKINGS_WITH_ACTION
} from "@/store/modules/bookings/actions";
import { createNamespacedHelpers } from "vuex";
import { TIMESHEETS_NAMESPACE } from "@/store/modules/timesheets";
import { FETCH_TIMESHEETS_WITH_ACTION } from "@/store/modules/timesheets/actions";
import { FETCH_APPLICATION_TO_REVIEW } from "@/store/modules/applications/actions";
import { APPLICATION_NAMESPACE } from "@/store/modules/applications";

const {
  mapActions: mapBookingActions,
  mapState: mapBookingState
} = createNamespacedHelpers(BOOKINGS_NAMESPACE);

const {
  mapState: timesheetMapState,
  mapActions: timesheetMapActions
} = createNamespacedHelpers(TIMESHEETS_NAMESPACE);
const {
  mapActions: mapApplicationActions,
  mapState: mapApplicationState
} = createNamespacedHelpers(APPLICATION_NAMESPACE);

export default {
  props: { permissions: Object, clientIds: Array },
  components: {
    ActionItem
  },
  created() {
    this.fetchActionLists();
  },
  data() {
    return { isFetchingData: false };
  },
  computed: {
    ...mapBookingState({
      isFetchingBookings: state => state.isFetchingBookings,
      bookingsToApprove: state => state.bookingsToApprove,
      bookingsToAccept: state => state.bookingsToAccept,
      bookingsWithShiftsToApprove: state => state.bookingsWithShiftsToApprove,
      bookingExtensionsToApprove: state => state.bookingExtensionsToApprove
    }),
    ...timesheetMapState({
      timesheetsToVerify: state => state.timesheetsToVerify,
      timesheetsToApprove: state => state.timesheetsToApprove,
      isFetchingTimesheets: state => state.isFetchingTimesheets
    }),
    ...mapApplicationState({
      applicationsToReview: state => state.applicationsToReview,
      isFetchingApplications: state => state.isFetchingApplications
    })
  },
  methods: {
    ...mapBookingActions({
      fetchBookingsWithAction: FETCH_BOOKINGS_WITH_ACTION
    }),
    ...timesheetMapActions({
      fetchTimesheetsWithAction: FETCH_TIMESHEETS_WITH_ACTION
    }),
    ...mapApplicationActions({
      fetchApplicationToReview: FETCH_APPLICATION_TO_REVIEW
    }),
    async fetchActionLists() {
      this.isFetchingData = true;
      await this.fetchBookingsWithAction({
        type: "approve",
        root_client_id: this.clientIds
      });
      if (this.permissions["dashboard.bookings-to-accept"]) {
        await this.fetchBookingsWithAction({
          type: "accept",
          root_client_id: this.clientIds
        });
      }
      await this.fetchBookingsWithAction({
        type: "shifts",
        root_client_id: this.clientIds
      });
      await this.fetchBookingsWithAction({
        type: "extensions",
        root_client_id: this.clientIds
      });
      await this.fetchTimesheetsWithAction({
        type: "approve",
        root_client_id: this.clientIds
      });
      await this.fetchTimesheetsWithAction({
        type: "verify",
        root_client_id: this.clientIds
      });
      await this.fetchApplicationToReview({ client_id: this.clientIds });
      this.isFetchingData = false;
    }
  },
  watch: {
    clientIds() {
      this.fetchActionLists();
    }
  }
};
</script>

<style lang="scss"></style>
