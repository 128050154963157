<template>
  <ValidationObserver ref="addAgenciesValidation">
    <DialogFull :data="settings" @close="close">
      <template v-slot:dialog.body>
        <v-container class="submit-agencies-dialog col-12 col-md-8">
          <h1 class="agencies-title">Agencies</h1>
          <p class="description">
            Select agencies to add to the publishing schedule tier
          </p>
          <AgenciesSelection
            :showValidationErrors="showValidationErrors"
            :outerSelectedAgencies="selectedAgenciesIds"
            @handleSelectedAgencies="setSelectedAgencies"
            @unselectAgency="unselectAgency"
            :agencies="filteredAgencies"
            :is-loading="isFetchingData"
          />
        </v-container>
      </template>
      <template v-slot:dialog.action>
        <PrimaryButton @click.native="addAgencies" :loading="isLoading">
          Add Agencies
        </PrimaryButton>
      </template>
    </DialogFull>
  </ValidationObserver>
</template>

<script>
import AgenciesSelection from "@/views/settings/components/AgenciesSelection";
import DialogFull from "@/components/common/DialogFull";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import { createNamespacedHelpers } from "vuex";
import { ValidationObserver } from "vee-validate";
import { filter, map, find } from "lodash";

// agency store
import { FETCH_ALL_AGENCIES } from "@/store/modules/agencies/action-types";
import { AGENCY_NAMESPACE } from "@/store/modules/agencies";

const { mapState, mapActions: mapAgencyActions } = createNamespacedHelpers(
  AGENCY_NAMESPACE
);

// publishing schedule store
import { ADD_AGENCIES_TO_SCHEDULE_TIER } from "@/store/modules/organisations/organisation-schedules/actions";
import { ORGANISATION_SCHEDULES_NAMESPACE } from "@/store/modules/organisations/organisation-schedules";

const { mapActions: mapOrganisationScheduleActions } = createNamespacedHelpers(
  ORGANISATION_SCHEDULES_NAMESPACE
);

export default {
  name: "AddAgenciesToTierDialog",
  components: {
    PrimaryButton,
    AgenciesSelection,
    DialogFull,
    ValidationObserver
  },
  props: {
    isOpen: Boolean,
    tierId: String,
    tierAgencies: Array
  },
  data() {
    return {
      showValidationErrors: false,
      isLoading: false,
      selectedAgenciesIds: []
    };
  },
  computed: {
    ...mapState({
      agencies: state => state.agencies,
      isFetchingData: state => state.isFetchingData
    }),
    settings() {
      return {
        title: "Add Agencies to Publishing Schedule Tier",
        dialog: this.isOpen
      };
    },
    filteredAgencies() {
      return filter(this.agencies, agency =>
        Boolean(!find(this.tierAgencies, { id: agency.id }))
      );
    }
  },
  created() {
    this.getAllAgencies({ "filter[organisation_type]": "agency" });
  },
  methods: {
    ...mapOrganisationScheduleActions({
      addAgenciesToScheduleTier: ADD_AGENCIES_TO_SCHEDULE_TIER
    }),
    ...mapAgencyActions({
      getAllAgencies: FETCH_ALL_AGENCIES
    }),
    async addAgencies() {
      this.showValidationErrors = true;
      const isFormValid = await this.$refs.addAgenciesValidation.validate();
      if (isFormValid) {
        this.isLoading = true;
        try {
          const agencies = map(this.selectedAgenciesIds, id => ({ id }));
          await this.addAgenciesToScheduleTier({
            tierId: this.tierId,
            agencies
          });
          this.$emit("added");
          this.$emit("close");
        } finally {
          this.isLoading = false;
          this.selectedAgenciesIds = [];
        }
      }
    },
    setSelectedAgencies(selectedAgenciesIds) {
      this.selectedAgenciesIds = selectedAgenciesIds;
    },
    unselectAgency(selectedWorker) {
      this.selectedAgenciesIds = filter(
        this.selectedAgenciesIds,
        id => id !== selectedWorker.id
      );
    },
    close() {
      this.$refs.addAgenciesValidation.reset();
      this.selectedAgenciesIds = [];
      this.$emit("close");
    }
  }
};
</script>
<style lang="scss">
.submit-agencies-dialog {
  margin-top: 5rem;

  .agencies-title {
    margin-bottom: 0.5rem;
  }
  .description {
    color: $secondary-text;
    font-size: 22px;
  }
}
</style>
