<template>
  <DialogFull :data="dialogSettings" @close="close">
    <template v-slot:dialog.body>
      <div class="container col-lg-6 col-sm-12 mt-6">
        <h1 class="pb-3">Why are you rejecting the candidate?</h1>
        <div class="reject-error-info" v-if="showError">
          <v-icon class="mr-2" color="white">mdi-arrow-down</v-icon>
          <div>
            You need to give a reason why you're rejecting the candidate
          </div>
        </div>
        <v-skeleton-loader v-if="isLoadingData" type="list-item-three-line" />
        <div class="select-wrapper">
          <div :class="{ 'error-highlight': showError }"></div>
          <RadioButton :options="reasons" @select="selectedReason = $event" />
        </div>
      </div>
    </template>
    <template v-slot:dialog.action>
      <ErrorButton @click.native="handleReject" :loading="isLoading">
        Reject Application(s)
      </ErrorButton>
    </template>
  </DialogFull>
</template>

<script>
import DialogFull from "@/components/common/DialogFull";
import RadioButton from "@/components/common/RadioButton";
import ErrorButton from "@/components/common/Button/ErrorButton";
import { REJECT_CANDIDATE } from "@/store/modules/applications/actions";
import { createNamespacedHelpers } from "vuex";
import { APPLICATION_NAMESPACE } from "@/store/modules/applications";
import { FETCH_REJECTION_REASONS } from "@/store/modules/data/actions";
import {
  GET_IS_FETCHING_DATA,
  GET_REJECTION_REASONS
} from "@/store/modules/data/getters";
import { DATA_NAMESPACE } from "@/store/modules/data";
import { map } from "lodash";

const {
  mapActions: mapDataActions,
  mapGetters: mapDataGetters
} = createNamespacedHelpers(DATA_NAMESPACE);

const { mapActions } = createNamespacedHelpers(APPLICATION_NAMESPACE);

export default {
  name: "RejectCandidate",
  components: { ErrorButton, DialogFull, RadioButton },
  data() {
    return {
      showError: false,
      selectedReason: null,
      isLoading: false
    };
  },
  computed: {
    ...mapDataGetters({
      rejectionReasons: GET_REJECTION_REASONS,
      isLoadingData: GET_IS_FETCHING_DATA
    }),
    dialogSettings() {
      return {
        dialog: this.isOpen,
        title: "Reject Candidate"
      };
    },
    reasons() {
      return this.rejectionReasons.map(({ reason }) => ({
        label: reason,
        value: reason
      }));
    }
  },
  props: {
    isOpen: Boolean,
    applications: Array
  },
  created() {
    this.fetchRejectionReasons();
  },
  methods: {
    ...mapActions({
      rejectCandidate: REJECT_CANDIDATE
    }),
    ...mapDataActions({
      fetchRejectionReasons: FETCH_REJECTION_REASONS
    }),
    async handleReject() {
      if (this.selectedReason) {
        this.showError = false;
        this.isLoading = true;
        const requests = map(this.applications, ({ id }) =>
          this.rejectCandidate({
            applicationId: id,
            reason: this.selectedReason
          })
        );
        await Promise.all(requests);
        this.$emit("close", { success: true });
        this.isLoading = false;
      } else {
        this.showError = true;
      }
    },
    close() {
      this.$emit("close", { success: false });
    }
  }
};
</script>
<style lang="scss">
.reject-error-info {
  display: flex;
  border-radius: 4px;
  align-items: center;
  padding: 10px 8px;
  font-weight: bold;
  font-size: 18px;
  background-color: $error;
  color: $white;
}
.select-wrapper {
  display: flex;

  .error-highlight {
    margin: 16px 10px 16px 0;
    background-color: $error;
    width: 9px;
    border-radius: 40px;
  }
}
</style>
