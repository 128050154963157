export const ENTRY_MODEL = "entry";

const entryModel = {
  cost: 0,
  currency_code: "",
  startTime: "",
  endTime: "",
  dayDate: "",
  breakMinutes: 0,
  absent: false,
  worked: false,
  createdAt: "",
  updatedAt: "",
  totalTime: 0,
  expectedTotalTime: 0,
  expected_cost: 0,
  expectedStartTime: "",
  expectedEndTime: "",
  expectedBreakMinutes: 0,
  expected_currency_code: "",
  shiftId: "",
  timesheet: {
    jsonApi: "hasOne",
    type: "timesheets"
  },
  "pay-rates": {
    jsonApi: "hasOne",
    type: "pay-rates"
  }
};

export default entryModel;
