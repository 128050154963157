import {
  ADD_GLOBAL_MESSAGE,
  REMOVE_MESSAGE,
  SET_AUTH_DIALOG_VISIBILITY
} from "@/store/modules/global/mutation-types";
import { POST_GLOBAL_MESSAGE } from "@/store/modules/global/action-types";
import { reverse, dropRight } from "lodash";
import getters from "./getters";

const state = {
  /** 
  *  @example {
      snackbar: false,
      type: "success",
      title: "title",
      message: "message"
    } 
  */
  messages: [],
  showAuthDialog: false
};

const actions = {
  [POST_GLOBAL_MESSAGE]({ commit }, message, duration = 4000) {
    commit(ADD_GLOBAL_MESSAGE, message);
    setTimeout(() => commit(REMOVE_MESSAGE), duration);
  }
};

const mutations = {
  [ADD_GLOBAL_MESSAGE](state, newMessage) {
    state.messages.push(newMessage);
  },
  [REMOVE_MESSAGE](state) {
    state.messages = dropRight(reverse(state.messages));
  },
  [SET_AUTH_DIALOG_VISIBILITY](state, showDialog) {
    state.showAuthDialog = showDialog;
  }
};

export default {
  mutations,
  state,
  actions,
  getters
};
