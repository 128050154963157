<template>
  <div>
    <PublishingScheduleTierDialog
      :is-open="isDialogOpen"
      @close="close"
      :title="title"
      :actionName="actionName"
      @submit="handleCreate"
      :loading="isLoading"
    />
    <PrimaryButton light fullWidth @click.native="openDialog">
      Add Tier
    </PrimaryButton>
  </div>
</template>

<script>
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import { createNamespacedHelpers } from "vuex";
import PublishingScheduleTierDialog from "@/views/settings/Organisation/PublishingSchedules/dialogs/PublishingScheduleTierDialog";
import { ADD_TIER } from "@/store/modules/organisations/organisation-schedules/actions";
import { ORGANISATION_SCHEDULES_NAMESPACE } from "@/store/modules/organisations/organisation-schedules";

const { mapActions } = createNamespacedHelpers(
  ORGANISATION_SCHEDULES_NAMESPACE
);

export default {
  name: "AddPublishingScheduleTierAction.",
  props: {
    publishing_schedule_id: {
      type: String,
      required: true
    }
  },
  components: { PublishingScheduleTierDialog, PrimaryButton },
  data() {
    return {
      isDialogOpen: false,
      title: "Add Publishing Schedule Tier",
      actionName: "Create Tier",
      isLoading: false
    };
  },
  methods: {
    ...mapActions({
      createTier: ADD_TIER
    }),
    openDialog() {
      this.isDialogOpen = true;
    },
    close() {
      this.isDialogOpen = false;
    },
    async handleCreate(newTier) {
      this.isLoading = true;
      try {
        newTier.publishing_schedule_id = this.publishing_schedule_id;
        await this.createTier(newTier);
        this.$emit("added");
        this.close();
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style scoped></style>
