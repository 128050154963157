import bookingsActions from "@/store/modules/bookings/actions";
import bookingsMutations from "@/store/modules/bookings/mutations";
import bookingsGetters from "@/store/modules/bookings/getters";
import { MOCKED_BOOKINGS_STATE } from "@/constants/bookings";
import { isMockedDataEnabled } from "@/utils/mocked-data";

export const INITIAL_BOOKING_STATE = {
  bookings: [],
  bookingPayRates: [],
  currentBooking: null,
  currentBookingCandidates: [],
  currentBookingEvents: [],
  currentBookingPublishingSchedule: [],
  bookingShifts: [],
  bookingComments: [],
  bookingShiftPatterns: [],
  candidatesForSubmit: [],
  isFetchingBookingShifts: false,
  isFetchingBookings: false,
  isFetchingCurrentBooking: false,
  isFetchingCurrentBookingCandidates: false,
  isFetchingBookingShiftPatterns: false,
  isFetchingBookingPublishingSchedule: false,
  isFetchingBookingEvents: false,
  isFetchingBookingComments: false,
  isFetchingBookingPayRates: false,
  isEditingBookingPayRates: false,
  isFetchingCandidatesForSubmit: false,
  totalBookingShifts: 0,
  bookingFilters: {},
  bookingListFilters: {},
  bookingsToApprove: [],
  bookingsToAccept: [],
  bookingsWithShiftsToApprove: [],
  bookingExtensionsToApprove: [],
  budgets: [],
  isFetchingShifts: false,
  shifts: [],
  shiftsFilters: {}
};

export default {
  namespaced: true,
  state: isMockedDataEnabled()
    ? MOCKED_BOOKINGS_STATE
    : { ...INITIAL_BOOKING_STATE },
  actions: isMockedDataEnabled() ? {} : bookingsActions,
  mutations: isMockedDataEnabled() ? {} : bookingsMutations,
  getters: bookingsGetters
};
