<template>
  <DialogFull :data="workerDialog" @close="close">
    <template v-slot:dialog.body>
      <div @paste="onPaste" class="container">
        <h1 class="mt-10">Place Workers onto Shifts</h1>
        <div class="my-2" v-for="(shifts, key) in groupedShifts" :key="key">
          <h3 class="my-2">{{ key | shiftDate }}</h3>
          <ShiftAgencyDetails
            v-for="(shift, index) in shifts"
            :key="index"
            :shift="shift"
            :workerSize="workerSize"
            @count="handleWorkersCount"
          />
        </div>
        <v-divider class="mt-8" />
        <BulkWorkers ref="bulkWorkers" @update="updateWorkers" />
      </div>
    </template>
    <template v-slot:dialog.action>
      <p class="mb-0 mr-1" color="red" v-if="isExceeding">
        Too many workers for at least one of your selected shifts. Reduce number
        of workers to be placed.
      </p>
      <PrimaryButton
        :disabled="disabledButton"
        :loading="isLoading"
        @click.native="placeWorkers"
      >
        Place Workers
      </PrimaryButton>
    </template>
  </DialogFull>
</template>

<script>
import DialogFull from "@/components/common/DialogFull";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import { map, size } from "lodash";
import BulkWorkers from "@/components/workers/BulkWorkers";
import { groupByDate } from "@/utils/helpers";
import ShiftAgencyDetails from "@/views/shifts/components/ShiftAgencyDetails";
import {
  SUBMIT_AND_CONFIRM_WORKERS,
  BOOKINGS_NAMESPACE
} from "@/store/modules/bookings/actions";
import { createNamespacedHelpers } from "vuex";

const { mapActions } = createNamespacedHelpers(BOOKINGS_NAMESPACE);

export default {
  components: {
    DialogFull,
    PrimaryButton,
    BulkWorkers,
    ShiftAgencyDetails
  },
  props: {
    isOpen: Boolean,
    shifts: Array
  },
  data() {
    return {
      isLoading: false,
      workers: [],
      isExceeding: false
    };
  },
  computed: {
    workerDialog() {
      return {
        dialog: this.isOpen,
        title: "Place Workers"
      };
    },
    groupedShifts() {
      return groupByDate({ items: this.shifts, format: "MM/DD/YYYY" });
    },
    workerSize() {
      return size(this.workers);
    },
    disabledButton() {
      return !this.workerSize || this.isExceeding;
    }
  },
  methods: {
    ...mapActions({
      submitAndConfirmWorkers: SUBMIT_AND_CONFIRM_WORKERS
    }),
    onPaste(event) {
      this.$refs.bulkWorkers.onPaste(event);
    },
    close() {
      this.$emit("close");
    },
    updateWorkers(workers) {
      this.isExceeding = false;
      this.workers = workers;
    },
    handleWorkersCount(isExceeding) {
      if (isExceeding) {
        this.isExceeding = isExceeding;
      }
    },
    async placeWorkers() {
      this.isLoading = true;
      try {
        const requests = map(this.shifts, ({ id, booking }) => {
          return this.submitAndConfirmWorkers({
            bookingId: booking.id,
            shiftId: id,
            workers: this.workers
          });
        });
        await Promise.all(requests);
        this.$emit("update");
        this.close();
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>
