import moment from "moment";
import {
  first,
  includes,
  replace,
  toNumber,
  groupBy,
  map,
  filter,
  find,
  forEach,
  toLower,
  isArray,
  isObject,
  camelCase,
  transform
} from "lodash";
import { BASE_CURRENCY_FORMAT, BASE_DATE_FORMAT } from "@/constants/common.js";
import Dinero from "dinero.js";
import axios from "axios";
import { getToken } from "@/services/utils";

export const compareDates = (a, b, format) => {
  const formattedA = moment(a, format);
  const formattedB = moment(b, format);

  return formattedA.diff(formattedB);
};

export const getMonetaryValueAsNumber = value => {
  return toNumber(replace(value.substring(1), /,/g, ""));
};

export const getMonetaryValue = value => {
  return Dinero({
    amount: toNumber(value)
  }).toUnit();
};

export const getNumberAsMonetaryValue = (value, currency) => {
  if (!value) value = 0;
  return Dinero({
    amount: toNumber(value),
    currency
  }).toFormat(BASE_CURRENCY_FORMAT);
};

export const getAmountInApiFormat = value => {
  return Number(value * 100);
};

export const getAmountInDisplayFormat = value => {
  return Number(value / 100);
};

export const getMonetaryDisplayFormat = value => {
  if (!value) {
    return 0;
  }
  return Dinero({
    amount: toNumber(value)
  }).toFormat("0,0.00");
};

export const getMonetaryAsNumber = value => {
  return toNumber(replace(value, /,/g, ""));
};

export const getAmountInMonetaryApiFormat = value => {
  return getAmountInApiFormat(value).toFixed(2);
};

export const getAmountInMonetaryDisplayFormat = value => {
  return getAmountInDisplayFormat(value).toFixed(2);
};

export const getNameInitials = ({ firstName, lastName }) =>
  firstName && lastName
    ? first(firstName).toUpperCase() + first(lastName).toUpperCase()
    : "-";

export const sortTableItems = (items, arr, isDescArray, options) => {
  const { monetaryValuesLabels, dateValuesLabels } = options;
  const isDesc = first(isDescArray);
  const index = first(arr);
  const sortDirection = isDesc ? -1 : 1;
  return items.sort((a, b) => {
    let firstValue = a[index];
    let secondValue = b[index];
    if (includes(dateValuesLabels, index)) {
      return (
        compareDates(firstValue, secondValue, BASE_DATE_FORMAT) * sortDirection
      );
    }
    if (index === "id") {
      firstValue = Number(firstValue);
      secondValue = Number(secondValue);
    }
    if (includes(monetaryValuesLabels, index)) {
      firstValue = getMonetaryValueAsNumber(firstValue);
      secondValue = getMonetaryValueAsNumber(secondValue);
    }
    return (firstValue < secondValue ? -1 : 1) * sortDirection;
  });
};

export const getSortingParam = (paramNameArray, sortDescArray) => {
  const paramName = first(paramNameArray);
  const sortingDirection = first(sortDescArray) ? "-" : "+";
  return paramName && `${sortingDirection}${paramName}`;
};

export const getTimeForHumans = date => {
  const today = moment();
  if (today.diff(date, "days") > 1) {
    return moment(date).format("Do MMMM YYYY hh:mm");
  }

  return moment(date).fromNow();
};

export const groupByDate = ({
  items,
  key = "startDate",
  format = "DD/MM/YYYY"
}) =>
  items.reduce((groupedByDate, item) => {
    const day = moment(item[key]).format(format);
    if (groupedByDate[day]) {
      groupedByDate[day].push(item);
      return groupedByDate;
    }
    return { ...groupedByDate, [day]: [item] };
  }, {});

export const groupByMonth = items =>
  groupBy(items, item => moment(first(item).startDate).format("MMM"));

export const deduplicate = array => [...new Set(array)];

export const compareBy = key => (firstObject, secondObject) =>
  firstObject && secondObject && firstObject[key] === secondObject[key];

export const copyState = keys =>
  keys.reduce(
    (mapStateObject, currentKey) => ({
      ...mapStateObject,
      [currentKey]: state => state[currentKey]
    }),
    {}
  );

export const forceFileDownload = (response, fileName) => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  document.body.appendChild(link);
  link.setAttribute("download", fileName);
  link.click();
};

export const fileToDownload = file => {
  const token = `token=${getToken()}`;
  const url = `${file.url}?${token}`;
  axios({
    url,
    method: "GET",
    responseType: "arraybuffer"
  })
    .then(response => forceFileDownload(response, file.fileName))
    .catch(error => console.log(error));
};

export const setGlobalClientIdFilter = filters => {
  if (filters["root_client_id"] || filters["client_id"]) {
    let ids = filters["root_client_id"] || filters["client_id"];
    ids = map(ids, id => Number(id));
    localStorage.setItem("client_id", JSON.stringify(ids));
  } else {
    localStorage.removeItem("client_id");
  }
};

export const getClientIdFilter = () => {
  return (
    localStorage.getItem("client_id") &&
    JSON.parse(localStorage.getItem("client_id"))
  );
};

export const setClientIdFilter = (availableFilters, filters) => {
  return filter(filters, filter =>
    Boolean(find(availableFilters, { value: filter }))
  );
};

const isHeader = items => {
  items = map(items, item => toLower(item));
  return (
    includes(items, "first name") &&
    includes(items, "last name") &&
    includes(items, "unique id")
  );
};

export const getItemsFromClipboard = event => {
  const clipboardData =
    event.clipboardData ||
    (event.originalEvent && event.originalEvent.clipboardData);
  const pastedText =
    clipboardData.getData("Text") || clipboardData.getData("text/plain");
  if (!pastedText && pastedText.length) {
    return;
  }
  const rows = pastedText
    .replace(/"((?:[^"]*(?:\r\n|\n\r|\n|\r))+[^"]+)"/gm, (match, p1) => {
      return p1.replace(/""/g, '"').replace(/\r\n|\n\r|\n|\r/g, " ");
    })
    .split(/\r\n|\n\r|\n|\r/g);
  const groupedItems = [];
  let headers = [];
  forEach(rows, (row, key) => {
    const items = row.split("\t");
    if (key === 0 && isHeader(items)) {
      headers = items;
    } else {
      groupedItems.push(Object.assign([], items));
    }
  });
  return { headers, groupedItems };
};

export const camelize = object =>
  transform(object, (acc, value, key, target) => {
    const camelKey = isArray(target) ? key : camelCase(key);

    acc[camelKey] = isObject(value) ? camelize(value) : value;
  });
