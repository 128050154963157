export const BOOKING_NOTIFICATIONS_MODEL = "booking-notifications";

const bookingNotificationsModel = {
  accept: false,
  approve: false,
  accepted: false,
  approved: false,
  cancelled: false,
  published: false,
  rejected: false
};

export default bookingNotificationsModel;
