export const EVENT_MODEL = "event";

const eventModel = {
  description: "",
  logName: "",
  properties: "",
  updatedAt: "",
  createdAt: "",
  causer: {
    jsonApi: "hasOne",
    type: "user"
  },
  subject: {
    jsonApi: "hasOne",
    type: "subject"
  }
};

export default eventModel;
