export const TEMPLATE_COST_MODEL = "template-cost";

const templateCostModel = {
  costCategoryName: "",
  costTypeName: "",
  awr: false,
  payRateBandLower: 0,
  payRateBandUpper: 0,
  organisation_unit_id: "",
  currencyCode: "",
  costAmount: 0
};

export default templateCostModel;
