<template>
  <div
    class="container col-lg-12 col-sm-12 booking-shifts ma-0 p-0 m-0"
    :class="{ 'pl-0 pr-0': isMobile }"
  >
    <div>
      <div class="container col-12 shifts-wrapper ma-0 p-0 m-0">
        <slot name="filters" />
        <ShiftList
          ref="shiftList"
          :isLoading="isLoading"
          :shifts="shifts"
          :hasDateFilter="hasDateFilter"
          @onSelectShifts="onSelectShifts"
          @onSelectAllShifts="onSelectAllShifts"
        />
      </div>
    </div>
    <BaseFooter v-if="showFooter">
      <ExpandedButtons class="actions-bar">
        <SubmitCandidatesAction
          v-if="showSubmitButton"
          :selected-shifts="shiftsToProcess"
          :filters="filters"
          @updateList="updateList"
        />
        <AllocateWorkersAction
          v-if="showAllocateButton"
          :selected-shifts="shiftsToProcess"
          isV2
          @updateList="updateList"
        />
        <ConfirmAllocationsAction
          :shifts="shiftsToProcess"
          v-if="showConfirmAllocationsButton"
          isV2
          @updateList="updateList"
        />
        <CancelShiftAction
          v-if="showCancelButton"
          :shifts="shiftsToProcess"
          isV2
          @updateList="updateList"
        />
        <DeclineAllocationsAction
          :shifts="shiftsToProcess"
          v-if="showDeclineAllocationsButton"
          isV2
          @updateList="updateList"
        />
      </ExpandedButtons>
    </BaseFooter>
  </div>
</template>

<script>
import { first, isEmpty, groupBy } from "lodash";
import SubmitCandidatesAction from "@/views/bookings/components/actions/SubmitCandidatesAction";
import { getColorByStatus } from "@/utils/bookings";
import AllocateWorkersAction from "@/views/bookings/BookingDetails/components/Shifts/AllocateWorkers/AllocateWorkersAction";
import { getFullName } from "@/utils/users";
import ConfirmAllocationsAction from "@/views/bookings/components/actions/ConfirmAllocationsAction";
import DeclineAllocationsAction from "@/views/bookings/components/actions/DeclineAllocationsAction";
import CancelShiftAction from "@/views/bookings/components/actions/CancelShiftAction";
import moment from "moment";
import { SHIFT_PERMISSIONS_STATUSES } from "@/constants/shifts";
import { API_DATE_FORMAT } from "@/constants/common";
import {
  checkIfSelectedApplicationsArePermitted,
  checkIfSelectedShiftsArePermitted
} from "@/utils/shifts";
import ShiftList from "@/components/bookings/ShiftList";
import pluralize from "pluralize";
import ExpandedButtons from "@/components/common/ExpandedButtons";
import BaseFooter from "@/components/common/BaseFooter";

const {
  DECLINE,
  CONFIRM,
  ALLOCATE,
  SUBMIT,
  CANCEL
} = SHIFT_PERMISSIONS_STATUSES;

export default {
  name: "ShiftListWrapper",
  components: {
    ShiftList,
    AllocateWorkersAction,
    SubmitCandidatesAction,
    DeclineAllocationsAction,
    ConfirmAllocationsAction,
    ExpandedButtons,
    BaseFooter,
    CancelShiftAction
  },
  props: {
    shifts: Array,
    isLoading: Boolean,
    selectMultiple: Boolean,
    filters: Object,
    totalBookingShifts: Number,
    hasDateFilter: Boolean
  },
  data() {
    return {
      selectedShifts: [],
      isSelectAll: false
    };
  },
  computed: {
    firstSelectedShift() {
      return first(this.selectedShifts) || {};
    },
    showConfirmAllocationsButton() {
      return checkIfSelectedApplicationsArePermitted({
        shifts: this.selectedShifts,
        permission: CONFIRM,
        nestedApplicationShifts: true
      });
    },
    showDeclineAllocationsButton() {
      return checkIfSelectedApplicationsArePermitted({
        shifts: this.selectedShifts,
        permission: DECLINE,
        nestedApplicationShifts: true
      });
    },
    areMultipleShiftSelected() {
      return this.selectedShiftsCount > 1;
    },
    showFooter() {
      return (
        (this.showConfirmAllocationsButton ||
          this.showDeclineAllocationsButton ||
          this.showAllocateButton ||
          this.showSubmitButton) &&
        !isEmpty(this.selectedShifts)
      );
    },
    showAllocateButton() {
      return (
        checkIfSelectedApplicationsArePermitted({
          shifts: this.selectedShifts,
          permission: ALLOCATE,
          nestedApplicationShifts: true
        }) ||
        checkIfSelectedShiftsArePermitted(
          {
            shifts: this.selectedShifts,
            permission: "allocate.unavailable-candidates"
          },
          "shifts"
        )
      );
    },
    showSubmitButton() {
      return checkIfSelectedShiftsArePermitted(
        {
          shifts: this.selectedShifts,
          permission: SUBMIT
        },
        "shifts"
      );
    },
    applicationShifts() {
      return this.firstSelectedShift.applicationShifts || [];
    },
    groupedApplicationShifts() {
      return groupBy(this.applicationShifts, "status");
    },
    showCancelButton() {
      return checkIfSelectedShiftsArePermitted(
        {
          shifts: this.selectedShifts,
          permission: CANCEL
        },
        "shifts"
      );
    },
    shiftsToProcess() {
      return this.isSelectAll ? [] : this.selectedShifts;
    }
  },
  methods: {
    clearSelectedShifts() {
      this.$refs.shiftList.clearSelectedShifts();
    },
    onSelectShifts(shifts) {
      this.selectedShifts = shifts;
    },
    onSelectAllShifts(value) {
      this.isSelectAll = value;
    },
    getStatusColor(status) {
      return getColorByStatus(status);
    },
    updateList() {
      this.$emit("updateList");
    },
    pluralize,
    getFullName
  },
  filters: {
    getStartDate(date) {
      return moment(date, API_DATE_FORMAT).format("ddd Do MMM");
    },
    shiftTime(date) {
      return moment(date, API_DATE_FORMAT).format("HH:mm:ss");
    }
  }
};
</script>

<style lang="scss">
.booking-shifts {
  margin: 0;
  padding: 0;
  overflow-x: hidden;

  .workers-progress {
    width: 50%;
  }
  .filters {
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    margin-right: 1rem;

    @media only screen and (max-width: $mobile-breakpoint) {
      margin-left: 0;
    }
  }

  .shifts-wrapper {
    margin-bottom: 50px;
    padding: 0px;
  }

  .header {
    width: 100%;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: $mobile-breakpoint - 1) {
      padding: 0 25px;
    }
  }

  .day {
    display: inline-block;
    padding-right: 10px;
    font-size: 18px;
    text-align: center;
    width: 70px;
    color: $secondary-text;

    &__word {
      font-weight: normal;
      font-size: 18px;
      line-height: 23px;
      color: $secondary-text;
    }
    &__number {
      font-weight: bold;
      font-size: 32px;
      line-height: 38px;
      letter-spacing: -1px;
      color: $primary-text;
    }
  }
  .shifts-group {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    position: relative;

    .shift-list {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    .past-shifts-divider {
      display: flex;
      align-items: center;
      position: absolute;
      width: 100%;
      left: 0;

      .line {
        background-color: $error;
        height: 2px;
        width: 100%;
      }

      .dot {
        height: 12px;
        width: 12px;
        background-color: $error;
        border-radius: 50%;
        display: inline-block;
      }
    }

    .shift-item {
      display: flex;
      align-items: center;
      padding: 5px 10px;
      cursor: pointer;

      &.expired {
        filter: grayscale(100%);
      }

      @media only screen and (max-width: $mobile-breakpoint) {
        padding: 0;
      }

      @media only screen and (min-width: $mobile-breakpoint) {
        &:hover {
          background-color: rgba(94, 122, 141, 0.1);
        }
      }
    }
    .shift-selected {
      background-color: rgba(94, 122, 141, 0.1);
    }
  }

  .application-count {
    font-size: 28px;
  }

  .confirmed {
    color: #0a70f58e;
  }

  .allocated {
    color: #a126ff7a;
  }

  .submitted {
    color: rgba(0, 0, 0, 0.15);
  }
}
</style>
