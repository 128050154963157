export const getAwrValidationErrors = (value, percentage) => {
  const isValueInPercentageRange = Number(value) >= 0 && Number(value) <= 100;
  switch (true) {
    case !value:
      return "This field is required";
    case percentage && !isValueInPercentageRange:
      return "The value must be in the range 0 to 100";
    case Number(value) < 0:
      return "The number can't be less than 0";
  }
};

export const getPayBandValidationError = (bandLower, bandUpper) => {
  switch (true) {
    case !bandLower && !bandUpper:
      return "";
    case Number(bandLower) > Number(bandUpper):
      return "Pay Band range is invalid";
  }
};
