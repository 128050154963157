<template>
  <div>
    <v-skeleton-loader type="date-picker" v-if="isLoadingBooking" />
    <BaseDetails class="booking-details-calendar" v-else>
      <template v-slot:header>
        <div class="shift-calendar-header">
          <h2>Shifts</h2>
          <Permissioned section="bookings">
            <PrimaryButton
              class="mr-1"
              permission="extend"
              @click.native="isExtendShiftsOpen = true"
              light
              dense
              shrink
            >
              Extend
            </PrimaryButton>
            <PrimaryButton
              v-if="!isLiveBooking"
              permission="edit.shift-patterns"
              @click.native="openEditShifts"
              light
              dense
              shrink
            >
              Edit
              <template v-slot:append:icon>
                <v-icon>mdi-pencil</v-icon>
              </template>
            </PrimaryButton>
          </Permissioned>
        </div>
      </template>
      <template v-slot:body>
        <EditShifts
          :isOpen="isEditShiftsOpen"
          @close="closeEditShifts"
          :initial-shifts="initialShifts"
          :booking-status="booking.status"
        />
        <EditShifts
          :isOpen="isExtendShiftsOpen"
          @close="isExtendShiftsOpen = false"
          :initial-shifts="[]"
          :booking-status="booking.status"
          isExtend
        />
        <ExtendBooking
          :isOpen="isExtendBookingOpen"
          @close="closeExtendBooking"
        />
        <div class="body">
          <ShiftStepMobileCalendar
            :shift-patterns="calendarPatterns"
            :first-shift-date="firstShiftDate"
          />
          <ShiftEntry
            v-for="(shiftPattern, index) in approvedShifts"
            :bookingId="booking.id"
            :shiftPattern="shiftPattern"
            :key="index"
          />
          <h2 id="pending-extensions" class="my-3" v-if="pendingShifts.length">
            Extensions Pending Approval
          </h2>
          <div class="my-2">
            <h4 v-if="approvers.length">Approvers</h4>
            <v-chip
              class="mx-1 my-1"
              v-for="approver in approvers"
              :key="approver.id"
              label
            >
              <v-icon color="black">mdi-account-outline</v-icon>
              {{ approver | fullName }}
            </v-chip>
          </div>
          <ShiftEntry
            v-for="shiftPattern in pendingShifts"
            :bookingId="booking.id"
            :shiftPattern="shiftPattern"
            :key="shiftPattern.id"
          />
          <h2 class="my-3" v-if="rejectedShifts.length">Rejected Extensions</h2>
          <ShiftEntry
            v-for="shiftPattern in rejectedShifts"
            :bookingId="booking.id"
            :shiftPattern="shiftPattern"
            :key="shiftPattern.id"
          />
        </div>
      </template>
    </BaseDetails>
  </div>
</template>

<script>
import ShiftEntry from "@/components/bookings/ShiftEntry";
import BaseDetails from "@/components/common/BaseDetails/BaseDetails";
import EditShifts from "@/views/bookings/EditBooking/EditShifts";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import ExtendBooking from "@/views/bookings/EditBooking/ExtendBooking";
import {
  GET_CURRENT_BOOKING,
  IS_LOADING_BOOKING,
  GET_CURRENT_BOOKING_SHIFT_PATTERNS,
  IS_LOADING_BOOKING_SHIFT_PATTERNS
} from "@/store/modules/bookings/getters";
import { createNamespacedHelpers } from "vuex";
import { BOOKINGS_NAMESPACE } from "@/store/modules/bookings/actions";
import { rrulestr } from "rrule";
import { getSequencesOfDates } from "@/utils/time";
import ShiftStepMobileCalendar from "@/views/bookings/NewBooking/steps/components/ShiftStepMobileCalendar";
import Permissioned from "@/components/common/Permissioned";
import { filter, map } from "lodash";

const { mapGetters } = createNamespacedHelpers(BOOKINGS_NAMESPACE);

export default {
  name: "ShiftCalendar",
  components: {
    Permissioned,
    ShiftStepMobileCalendar,
    ExtendBooking,
    PrimaryButton,
    EditShifts,
    BaseDetails,
    ShiftEntry
  },
  data() {
    return {
      isEditShiftsOpen: false,
      isExtendBookingOpen: false,
      isExtendShiftsOpen: false
    };
  },
  computed: {
    ...mapGetters({
      booking: GET_CURRENT_BOOKING,
      isLoadingBooking: IS_LOADING_BOOKING,
      shiftPatterns: GET_CURRENT_BOOKING_SHIFT_PATTERNS,
      isLoadingShiftPatterns: IS_LOADING_BOOKING_SHIFT_PATTERNS
    }),
    calendarPatterns() {
      return getSequencesOfDates(
        this.booking.shiftPatterns.flatMap(({ rrule }) => rrulestr(rrule).all())
      );
    },
    firstShiftDate() {
      if (this.calendarPatterns.length > 0) {
        const firstShiftDate = new Date(this.calendarPatterns[0].start);
        const month = firstShiftDate.getMonth() + 1;
        const year = firstShiftDate.getFullYear();

        return { month, year };
      }
      return null;
    },
    isLiveBooking() {
      return this.booking.status === "live";
    },
    initialShifts() {
      return this.isLiveBooking ? this.filteredShifts : this.shiftPatterns;
    },
    filteredShifts() {
      return filter(
        this.shiftPatterns,
        shift => shift.status === "draft" || shift.status === "rejected"
      );
    },
    approvedShifts() {
      return filter(this.shiftPatterns, shift => shift.status === "approved");
    },
    pendingShifts() {
      return filter(
        this.shiftPatterns,
        shift => shift.status === "pending-approval" || shift.status === "draft"
      );
    },
    rejectedShifts() {
      return filter(this.shiftPatterns, shift => shift.status === "rejected");
    },
    approvers() {
      return map(this.booking.bookingApprovers, ({ user }) => user);
    }
  },
  methods: {
    openEditShifts() {
      this.isEditShiftsOpen = true;
    },
    closeEditShifts() {
      this.isEditShiftsOpen = false;
    },
    openExtendBooking() {
      this.isExtendBookingOpen = true;
    },
    closeExtendBooking() {
      this.isExtendBookingOpen = false;
    }
  }
};
</script>

<style lang="scss"></style>
