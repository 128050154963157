<template>
  <div>
    <v-dialog fullscreen :value="showSelector" v-if="showSelector" persistent>
      <v-card class="d-flex justify-center align-center">
        <v-progress-circular
          indeterminate
          size="64"
          color="primary"
          v-if="isLoadingSelfDetails"
        />
        <div class="agency__profiles">
          <v-btn
            class="mb-2"
            elevation="0"
            color="primary"
            x-large
            icon
            fab
            disabled
          >
            <v-icon dark>mdi-account</v-icon>
          </v-btn>
          <h1 class="mb-1">Select {{ profileLabel | capitalize }}</h1>
          <p>You have a profile with more than one {{ profileLabel }}</p>
          <v-list-item-group class="mt-4">
            <v-list-item
              v-for="(profile, index) in profiles"
              :key="index"
              max-width="300"
              @click="onSelectProfile(profile)"
            >
              <v-list-item-content>
                <h6 class="mb-1">{{ profile.organisation_unit_name }}</h6>
                <p
                  v-if="isWorker(profile.roles)"
                  :class="{ 'font-italic': !profile.last_booking }"
                >
                  <v-icon v-if="profile.last_booking" color="primary">
                    mdi-briefcase-outline
                  </v-icon>
                  {{ profile.last_booking | lastBookingName }}
                </p>
                <p v-else>{{ profile.roles | roles }}</p>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { AUTH_NAMESPACE } from "@/store/modules/auth";
import { SELECT_PROFILE } from "@/store/modules/auth/action-types";
import { getLastBookingName, getRoles, isWorker } from "@/utils/users";
import { getImpersonateUserId } from "@/services/utils";
import { isEmpty } from "lodash";

const { mapState, mapActions } = createNamespacedHelpers(AUTH_NAMESPACE);
export default {
  computed: {
    ...mapState({
      selectedProfile: state => state.selectedProfile,
      isLoadingSelfDetails: state => state.isLoadingSelfDetails,
      profiles: state => state.profiles,
      user: state => state.userData
    }),
    showSelector() {
      return (
        Boolean(!this.selectedProfile) &&
        !this.isLoadingSelfDetails &&
        !isEmpty(this.profiles)
      );
    },
    profileLabel() {
      return isWorker(this.user.roles) ? "agency" : "organisation";
    }
  },
  methods: {
    ...mapActions({
      selectProfile: SELECT_PROFILE
    }),
    isWorker,
    onSelectProfile(profile) {
      if (!getImpersonateUserId()) {
        this.selectProfile(profile);
      }
    }
  },
  filters: {
    lastBookingName(lastBooking) {
      return getLastBookingName(lastBooking);
    },
    roles(roles) {
      return getRoles(roles);
    }
  }
};
</script>

<style lang="scss" scoped>
.agency {
  &__profiles {
    p {
      color: $secondary-text;
      margin: 0;
    }

    i {
      margin-bottom: 5px;
    }

    .v-list-item.v-list-item--link {
      border: 2px solid #d2d2d2;
      box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      margin-bottom: 10px;
    }

    .v-list-item__content {
      padding: 22px 0;
    }

    button.v-btn.v-btn--fab.v-btn--flat.v-btn--icon.v-btn--round {
      background: lighten($primary, 44);
    }

    .theme--light.v-btn.v-btn--disabled .v-icon {
      color: $primary !important;
    }
  }
}
</style>
