import JSONApiService from "@/services/JSONApiService";
import { USER_MODEL, USERS_TO_IMPERSONATE } from "@/models/user-model";
import { disablePluralizeForCall } from "@/services/utils";
import { ORGANISATION, ORGANISATIONS, include } from "@/models/relationships";
import APIService, { withPrefix } from "@/services/APIService";

const API = withPrefix(APIService.client(), "system-parameters");

const authService = commit => ({
  me: async () => {
    const api = JSONApiService(commit);
    disablePluralizeForCall(api, () => {
      api.one(USER_MODEL, "self");
    });
    return api.get(include(ORGANISATION, ORGANISATIONS));
  },
  update: async (id, editedUser) =>
    JSONApiService(commit).update(
      USER_MODEL,
      { id, ...editedUser },
      include(ORGANISATION, ORGANISATIONS)
    ),
  getUsersToImpersonate: async () => {
    const api = JSONApiService(commit);
    disablePluralizeForCall(api, () => {
      api.all(USERS_TO_IMPERSONATE);
    });
    return api.get();
  },
  getAppLogo: async id => API.get(`${id}?fields[system-parameters]=app-logo`)
});

export default authService;
