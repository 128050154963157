<template>
  <div class="list-type-selection" @click="selectListType">
    <div class="line mr-2" />
    <span class="list-type-text">
      {{ text }}
    </span>
    <slot name="icon" />
    <div class="line ml-2" />
  </div>
</template>

<script>
export default {
  name: "ListTypeSelection",
  props: {
    text: String
  },
  methods: {
    selectListType() {
      this.$emit("selectListType");
    }
  }
};
</script>
