export const HIRE_REASON_MODEL = "reasons-for-hire";

const hireReasonModel = {
  hireReason: "",
  status: "",
  client: {
    jsonApi: "hasOne",
    type: "organisations"
  }
};

export const hireReasonEndpoints = {
  REASONS_FOR_HIRE: "reasons-for-hire"
};

export default hireReasonModel;
