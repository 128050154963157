<template>
  <app-layout class="dashboard tabs-container" centered>
    <template v-slot:body>
      <div class="dashboard-container container col-lg-7 col-sm-12">
        <div class="dashboard__logo-container">
          <img class="dashboard__logo-container__logo" :src="logoUrl" />
        </div>
        <h1 class="text-center">{{ greeting }} {{ user.firstName }}</h1>
        <Permissioned
          class="d-flex justify-center mb-5"
          section="bookings.view"
        >
          <Select
            v-model="clientIds"
            class="ml-2 mt-2"
            permission="root-client"
            label=""
            placeholder="Select clients"
            item-text="name"
            item-value="id"
            :items="organisations"
            :loading="isLoading || isLoadingOrganisations"
            :disabled="isLoading || isLoadingOrganisations"
            @change="handleClientChange"
            autocomplete
            multiple
            chips
            clearable
          />
        </Permissioned>
        <ActionList :clientIds="clientIds" :permissions="permissions" />
        <UnfilledShifts v-if="showUnfilledShifts" />
        <SummaryList :clientIds="clientIds" permission="activity" />
      </div>
    </template>
  </app-layout>
</template>

<script>
import appLayout from "@/components/common/base-layouts/AppLayout";
import SummaryList from "@/components/dashboard/SummaryList";
import { createNamespacedHelpers, mapState } from "vuex";
import ActionList from "@/components/dashboard/action-list/ActionList";
import Select from "@/components/common/Select";
import { isEmpty, map } from "lodash";
import { setGlobalClientIdFilter, getClientIdFilter } from "@/utils/helpers";
import { FETCH_ORGANISATIONS } from "@/store/modules/organisations/actions";
import { ORGANISATIONS_NAMESPACE } from "@/store/modules/organisations";
import {
  GET_IS_FETCHING_ORGANISATIONS,
  GET_ORGANISATIONS
} from "@/store/modules/organisations/getters";
import Permissioned from "@/components/common/Permissioned";
import { isPermissioned } from "@/utils/permissions";
import UnfilledShifts from "@/components/dashboard/shifts/UnfilledShifts";

const {
  mapActions: mapOrganisationActions,
  mapGetters: mapOrganisationGetters
} = createNamespacedHelpers(ORGANISATIONS_NAMESPACE);

export default {
  title: "Home - Track",
  components: {
    appLayout,
    SummaryList,
    ActionList,
    Select,
    Permissioned,
    UnfilledShifts
  },
  created() {
    this.getOrganisationFilters();
  },
  data() {
    return {
      dates: [],
      clientIds: [],
      rootClients: [],
      isLoading: false,
      logoUrl: process.env.VUE_APP_LOGO
    };
  },
  computed: {
    ...mapState("auth", {
      permissions: state => state.permissions,
      user: state => state.userData
    }),
    ...mapOrganisationGetters({
      organisations: GET_ORGANISATIONS,
      isLoadingOrganisations: GET_IS_FETCHING_ORGANISATIONS
    }),
    greeting() {
      const date = new Date();
      const current_hour = date.getHours();
      switch (true) {
        case current_hour < 12:
          return "Good Morning";
        case current_hour < 18:
          return "Good Afternoon";
        default:
          return "Good Evening";
      }
    },
    showUnfilledShifts() {
      return isPermissioned("dashboard.unfilled-shifts.view");
    }
  },
  methods: {
    ...mapOrganisationActions({
      fetchOrganisations: FETCH_ORGANISATIONS
    }),
    updateDates(dates) {
      this.dates = dates;
    },
    async getOrganisationFilters() {
      this.isLoading = true;
      const filter = {
        organisation_type: "client",
        is_root: true,
        is_live: true
      };
      await this.fetchOrganisations({ filter });
      if (isPermissioned("bookings.view.root-client")) {
        const clientIdFilters = getClientIdFilter();
        if (clientIdFilters) {
          this.clientIds = map(clientIdFilters, id => String(id));
        }
      }
      this.isLoading = false;
    },
    clearClientId() {
      this.clientIds = [];
      setGlobalClientIdFilter({});
    },
    handleClientChange(values) {
      isEmpty(values)
        ? this.clearClientId()
        : setGlobalClientIdFilter({ client_id: values });
    }
  }
};
</script>

<style lang="scss">
.dashboard {
  &__date {
    width: 386px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;

    @media only screen and (max-width: $mobile-breakpoint - 1) {
      width: 100%;
    }
  }

  &__logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;

    &__logo {
      width: 300px;
      margin-bottom: 20px;
    }
  }
}

.home_logo {
  margin: 0 auto 20px auto;
  max-width: 200px;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    height: 136.67px;
    max-width: 200px;
  }
}

.dashboard-container {
  padding: 0px;
}
</style>
