<template>
  <div class="shift-entry my-2">
    <div class="d-flex justify-space-between flex-wrap">
      <LabeledText :label="dateRange" bold>
        <template v-slot:icon>
          <div class="mr-2">
            <v-icon :color="iconColor" size="32px">
              mdi-calendar-range-outline
            </v-icon>
            <v-icon :color="iconColor" size="22px">
              mdi-dots-horizontal
            </v-icon>
            <v-icon :color="iconColor" size="32px">
              mdi-calendar-range-outline
            </v-icon>
          </div>
        </template>
        <div>
          {{
            `${hourRange} | ${pattern} | ${workersRequired} | ${activityName}`
          }}
        </div>
      </LabeledText>
      <ShiftEntryActions
        v-if="!isApproved"
        :id="shiftPattern.id"
        :loading="loading"
        @submit="handleShiftEntryActions('submit')"
        @delete="handleShiftEntryActions('delete')"
        @approve="handleShiftEntryActions('approve')"
        @reject="handleShiftEntryActions('reject')"
      />
    </div>
  </div>
</template>

<script>
import LabeledText from "@/components/common/BaseDetails/LabeledText";
import { RRule } from "rrule";
import { WEEKDAYS } from "@/utils/time";
import { repeatOptions } from "@/utils/recurrence-rules";
import { reverse, map, first } from "lodash";
import ShiftEntryActions from "@/components/bookings/ShiftEntryActions";
import { createNamespacedHelpers } from "vuex";
import { BOOKINGS_NAMESPACE } from "@/store/modules/bookings/actions";
import {
  SUBMIT_SHIFT_PATTERN,
  DELETE_SHIFT_PATTERN,
  APPROVE_SHIFT_PATTERN,
  REJECT_SHIFT_PATTERN
} from "@/store/modules/bookings/actions";
import pluralize from "pluralize";
import { shiftHourRange, getShiftDateRange } from "@/utils/shifts";

const { mapActions } = createNamespacedHelpers(BOOKINGS_NAMESPACE);

export default {
  name: "ShiftEntry",
  components: {
    LabeledText,
    ShiftEntryActions
  },
  data() {
    return { loading: false };
  },
  computed: {
    shiftPatternRules() {
      return RRule.parseString(this.shiftPattern.rrule);
    },
    dateRange() {
      return getShiftDateRange(this.shiftPatternRules);
    },
    hourRange() {
      return shiftHourRange(
        this.shiftPattern.shiftStart,
        this.shiftPattern.shiftEnd
      );
    },
    frequency() {
      const frequency = reverse(Object.values(repeatOptions))[
        this.shiftPatternRules.freq
      ];
      if (frequency === "Doesn't repeat") return null;
      return frequency;
    },
    weekDays() {
      const weekDays = WEEKDAYS;
      return map(this.shiftPatternRules.byweekday, ({ weekday }) => {
        return weekDays[weekday];
      });
    },
    pattern() {
      if (this.frequency === "Monthly") {
        return `${this.frequency} | First ${this.weekDays}`;
      }
      return this.frequency
        ? `${this.frequency} | ${this.weekDays}`
        : `${this.weekDays}`;
    },
    isApproved() {
      return this.shiftPattern.status === "approved";
    },
    iconColor() {
      return this.shiftPattern.status === "rejected" ? "gray" : "black";
    },
    workersRequired() {
      return `${this.shiftPattern.workersRequired} ${pluralize(
        "Worker",
        this.shiftPattern.workersRequired
      )}`;
    },
    activityName() {
      const payRate =
        this.shiftPattern.payRates && first(this.shiftPattern.payRates);
      return payRate && payRate.activityTypeName;
    }
  },
  methods: {
    ...mapActions({
      submit: SUBMIT_SHIFT_PATTERN,
      delete: DELETE_SHIFT_PATTERN,
      approve: APPROVE_SHIFT_PATTERN,
      reject: REJECT_SHIFT_PATTERN
    }),
    async handleShiftEntryActions(action) {
      const id = this.shiftPattern.id;
      const bookingId = this.bookingId;
      this.loading = true;
      switch (action) {
        case "submit":
          await this.submit({ id, bookingId });
          break;
        case "delete":
          await this.delete({ id, bookingId });
          break;
        case "approve":
          await this.approve({ id, bookingId });
          break;
        case "reject":
          await this.reject({ id, bookingId });
          break;
      }
      this.loading = false;
    }
  },
  props: {
    bookingId: String,
    shiftPattern: Object
  }
};
</script>

<style lang="scss"></style>
