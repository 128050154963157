<template>
  <div>
    <PublishingScheduleTierDialog
      :is-open="isDialogOpen"
      @close="close"
      :tier="tier"
      :title="title"
      :actionName="actionName"
      @submit="handleEdit"
      :loading="isLoading"
    />
    <PrimaryButton shrink light class="mr-2" @click.native="openDialog">
      Edit
      <template v-slot:append:icon>
        <v-icon class="ml-1">mdi-pencil</v-icon>
      </template>
    </PrimaryButton>
  </div>
</template>

<script>
import PublishingScheduleTierDialog from "@/views/settings/Organisation/PublishingSchedules/dialogs/PublishingScheduleTierDialog";
import { EDIT_TIER } from "@/store/modules/organisations/organisation-schedules/actions";
import { ORGANISATION_SCHEDULES_NAMESPACE } from "@/store/modules/organisations/organisation-schedules";
import { createNamespacedHelpers } from "vuex";
import PrimaryButton from "@/components/common/Button/PrimaryButton";

const { mapActions } = createNamespacedHelpers(
  ORGANISATION_SCHEDULES_NAMESPACE
);

export default {
  name: "EditPublishingScheduleTierAction",
  components: { PublishingScheduleTierDialog, PrimaryButton },
  props: {
    tier: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      title: "Edit Publishing Schedule Tier",
      actionName: "Save Changes",
      isDialogOpen: false,
      isLoading: false
    };
  },
  methods: {
    ...mapActions({
      editTier: EDIT_TIER
    }),
    openDialog() {
      this.isDialogOpen = true;
    },
    close() {
      this.isDialogOpen = false;
    },
    async handleEdit(editedTier) {
      this.isLoading = true;
      try {
        await this.editTier({ id: this.tier.id, editedTier });
        this.$emit("updated");
        this.close();
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style scoped></style>
