<template>
  <BaseDialog :isOpen="isOpen">
    <template v-slot:title>
      Edit Agency Quotas
    </template>
    <template v-slot:body>
      <ValidationObserver ref="quotaForm">
        <div v-for="schedule in shiftScheduleQuotas" :key="schedule.id">
          <div class="d-flex align-center justify-space-between">
            <div class="d-flex mr-1 flex-grow-3">
              <v-icon color="black" class="mr-1">mdi-domain</v-icon>
              <p class="mb-0">{{ schedule.agency.name }}</p>
            </div>
            <div class="flex-grow-0">
              <ValidationProvider
                v-slot="{ errors }"
                :name="`quota${schedule.id}`"
                rules="minNumber:0"
              >
                <v-text-field
                  v-model="schedule.workerQuota"
                  type="number"
                  label=""
                  :min="0"
                  :error-messages="getErrorMessages(errors)"
                />
              </ValidationProvider>
            </div>
          </div>
          <v-divider class="my-3" />
        </div>
      </ValidationObserver>
      <div v-if="allowsEditWorkersRequired">
        <h3 class="mt-6">Edit Shift Requirements</h3>
        <div class="d-flex align-center justify-space-between">
          <v-text-field
            v-model="workersRequired"
            class="flex-grow-0"
            type="number"
            label=""
            :min="0"
            dense
          />
          <p class="mb-0 ml-2 flex-grow-1">workers required</p>
        </div>
      </div>
    </template>
    <template v-slot:actions>
      <PrimaryButton @click.native="close" text :disabled="isLoading">
        Cancel
      </PrimaryButton>
      <PrimaryButton @click.native="onSubmit" :loading="isLoading">
        Save Changes
      </PrimaryButton>
    </template>
  </BaseDialog>
</template>

<script>
import BaseDialog from "@/components/common/BaseDialog";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import {
  CREATE_QUOTA,
  UPDATE_QUOTA,
  DELETE_QUOTA,
  UPDATE_SHIFT,
  BOOKINGS_NAMESPACE
} from "@/store/modules/bookings/actions";
import { createNamespacedHelpers } from "vuex";
import { map } from "lodash";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { getShiftSchedulesQuotas } from "@/utils/shifts";
import { AUTH_NAMESPACE } from "@/store/modules/auth";

const { mapActions } = createNamespacedHelpers(BOOKINGS_NAMESPACE);
const { mapState: authMapState } = createNamespacedHelpers(AUTH_NAMESPACE);

export default {
  components: {
    PrimaryButton,
    BaseDialog,
    ValidationObserver,
    ValidationProvider
  },
  props: {
    isOpen: Boolean,
    shift: Object
  },
  data() {
    return {
      showValidationErrors: false,
      isLoading: false,
      workersRequired: this.shift.workersRequired
    };
  },
  computed: {
    ...authMapState(["permissions"]),
    publishingSchedules() {
      return this.shift.booking ? this.shift.booking.publishingSchedules : [];
    },
    shiftScheduleQuotas() {
      return getShiftSchedulesQuotas({
        schedules: this.publishingSchedules,
        shiftQuotas: this.shift.quotas
      });
    },
    bookingId() {
      return this.shift.booking && this.shift.booking.id;
    },
    allowsEditWorkersRequired() {
      return this.permissions["shifts.edit.workers-required"];
    }
  },
  methods: {
    ...mapActions({
      create: CREATE_QUOTA,
      update: UPDATE_QUOTA,
      delete: DELETE_QUOTA,
      updateShift: UPDATE_SHIFT
    }),
    close() {
      this.$emit("close");
    },
    async updateQuotas() {
      const bookingId = this.bookingId;
      const num_workers_promised = 0;
      const requests = map(this.shiftScheduleQuotas, schedule => {
        const workerQuota = schedule.workerQuota ? schedule.workerQuota : 0;
        if (!workerQuota && schedule.isShiftQuota) {
          return this.delete({
            bookingId,
            scheduleId: schedule.id,
            quotaId: schedule.quotas.id
          });
        }
        return workerQuota && schedule.isShiftQuota
          ? this.update({
              scheduleId: schedule.id,
              data: {
                id: schedule.quotaId,
                num_workers_promised,
                workerQuota
              }
            })
          : workerQuota &&
              this.create({
                scheduleId: schedule.id,
                data: {
                  workerQuota,
                  num_workers_promised,
                  shift_id: this.shift.id
                }
              });
      });
      await Promise.all(requests);
    },
    async patchShift() {
      await this.updateShift({
        id: this.shift.id,
        workersRequired: this.workersRequired
      });
    },
    async onSubmit() {
      this.showValidationErrors = true;
      const isValid = await this.$refs.quotaForm.validate();
      if (isValid) {
        this.showValidationErrors = false;
        this.isLoading = true;
        try {
          this.allowsEditWorkersRequired && (await this.patchShift());
          await this.updateQuotas();
          this.$emit("update");
        } finally {
          this.isLoading = false;
        }
        this.close();
      }
    },
    getErrorMessages(errors) {
      return this.showValidationErrors ? errors : "";
    }
  }
};
</script>

<style lang="scss"></style>
