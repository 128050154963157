<template>
  <div>
    <NewBookingTemplateSteps
      v-if="newBookingTemplateDialogOpen"
      :is-open="newBookingTemplateDialogOpen"
      @close="close"
      @submit="handleCreate"
    />
    <CreateButtonMobile
      @click.native="newBookingTemplateDialogOpen = true"
      v-if="isMobile"
    />
    <PrimaryButton @click.native="newBookingTemplateDialogOpen = true" v-else>
      New Booking Template
    </PrimaryButton>
  </div>
</template>

<script>
import NewBookingTemplateSteps from "@/views/settings/Organisation/BookingTemplates/dialogs/NewBookingTemplateSteps";
import CreateButtonMobile from "@/components/common/CreateButtonMobile";
import PrimaryButton from "@/components/common/Button/PrimaryButton";

export default {
  name: "NewBookingTemplateAction",
  components: { CreateButtonMobile, NewBookingTemplateSteps, PrimaryButton },
  data() {
    return {
      newBookingTemplateDialogOpen: false
    };
  },
  methods: {
    close() {
      this.newBookingTemplateDialogOpen = false;
    },
    handleCreate() {
      this.close();
    }
  }
};
</script>

<style scoped></style>
