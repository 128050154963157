import JSONApiService from "@/services/JSONApiService";
import { include, ADDRESSES, ADMINS } from "@/models/relationships";
import { ORGANISATION_MODEL } from "@/models/organisation-model";
import { AGENCY_MODEL } from "@/models/agency-model";
import { SCHEDULE_TIER_MODEL } from "@/models/schedule-tier-model";
import { ADDRESS_MODEL } from "@/models/address-model";
import { disablePluralizeForCall } from "@/services/utils";
import pluralize from "pluralize";

const agencyIncludes = include(
  ADMINS,
  pluralize(SCHEDULE_TIER_MODEL),
  pluralize(ADDRESS_MODEL)
);

const callAgencyAction = ({ ogranisationId, action, commit }) => {
  const api = JSONApiService(commit);
  api.one(ORGANISATION_MODEL, ogranisationId);
  disablePluralizeForCall(api, () => api.all(action));
  return api.patch({});
};

const addressesApi = ({ commit } = {}) => ({
  addAgency: async data =>
    JSONApiService(commit)
      .all(ORGANISATION_MODEL)
      .all(AGENCY_MODEL)
      .post({ ...data }, agencyIncludes),
  editAgency: async ({ data, id }) =>
    JSONApiService(commit)
      .all(ORGANISATION_MODEL)
      .one(AGENCY_MODEL, id)
      .patch({ ...data }, agencyIncludes),
  getAgency: async id =>
    JSONApiService(commit).find(ORGANISATION_MODEL, id, agencyIncludes),
  getAllOrganisation: async () =>
    JSONApiService(commit).findAll(ORGANISATION_MODEL, include(ADDRESSES)),
  updateOrganisation: async data =>
    JSONApiService(commit).update(ORGANISATION_MODEL, data),
  publishOrganisation: async ogranisationId =>
    callAgencyAction({ ogranisationId, action: "publish", commit }),
  archiveOrganisation: async ogranisationId =>
    callAgencyAction({ ogranisationId, action: "archive", commit }),
  getAllAgencies: async params =>
    JSONApiService(commit)
      .all(ORGANISATION_MODEL)
      .get({ ...params, ...agencyIncludes })
});

export default addressesApi;
