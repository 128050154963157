<template>
  <div class="report-between-input-filter d-flex">
    <v-text-field
      placeholder="From"
      v-model="from"
      :type="type"
      :max="type === 'number' && to"
      :errorMessages="errorMessages"
      @change="handleFromChange"
      dense
    />
    <v-text-field
      placeholder="To"
      v-model="to"
      :type="type"
      :min="type === 'number' && from"
      :errorMessages="errorMessages"
      :disabled="!from"
      @change="handleToChange"
      dense
    />
  </div>
</template>

<script>
export default {
  name: "ReportBetweenInputFilter",
  props: {
    value: String,
    onChange: Function,
    fieldType: String,
    filterType: String,
    errorMessages: [String, Array]
  },
  data() {
    return {
      from: this.value.split(",")[0],
      to: this.value.split(",")[1]
    };
  },
  computed: {
    type() {
      return this.fieldType === "number" || this.fieldType === "id"
        ? "number"
        : "text";
    }
  },
  methods: {
    handleFromChange(from) {
      Number(from) < Number(this.to) || !this.to
        ? this.onChange(`${from},${this.to}`)
        : (this.from = this.value.split(",")[0]);
    },
    handleToChange(to) {
      Number(to) > Number(this.from)
        ? this.onChange(`${this.from},${to}`)
        : (this.to = this.value.split(",")[1]);
    }
  }
};
</script>
