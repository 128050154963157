import { findIndex } from "lodash";
import {
  getAmountInApiFormat,
  getAmountInDisplayFormat,
  getAmountInMonetaryDisplayFormat,
  getAmountInMonetaryApiFormat
} from "@/utils/helpers";
import generateRandomId from "uuid/v4";

const getAwrName = awr => (awr ? "postAwr" : "preAwr");
const getAwrIdName = awr => (awr ? "postAwrId" : "preAwrId");

export const getMergedPayRates = templatePayRates =>
  templatePayRates.reduce((mergedPayRates, payRate) => {
    const { payRateName, awr, amount, id, currencyCode } = payRate;
    const payRateIndex = findIndex(mergedPayRates, {
      activityTypeName: payRateName
    });
    const awrName = getAwrName(awr);
    const awrId = getAwrIdName(awr);
    const sharedDetails = {
      id: generateRandomId(),
      [awrId]: id,
      [awrName]: getAmountInMonetaryDisplayFormat(amount),
      currencyCode
    };

    if (payRateIndex >= 0) {
      mergedPayRates[payRateIndex] = {
        ...mergedPayRates[payRateIndex],
        ...sharedDetails
      };
    } else {
      mergedPayRates.push({
        activityTypeName: payRateName,
        ...sharedDetails
      });
    }
    return mergedPayRates;
  }, []);

const getCostIndex = (costs, { costCategoryName, organisationUnitId }) =>
  findIndex(costs, {
    costCategoryName,
    ...(organisationUnitId && { organisationUnitId })
  });

export const getMergedCosts = costs =>
  costs.reduce((mergedCosts, payRate) => {
    const {
      id,
      costCategoryName,
      costAmount,
      awr,
      costTypeName,
      currencyCode,
      payRateBandLower,
      payRateBandUpper,
      organisation_unit_id: organisationUnitId
    } = payRate;
    const costIndex = getCostIndex(mergedCosts, {
      costCategoryName,
      organisationUnitId
    });
    const awrName = getAwrName(awr);
    const costTypeNameKey = awr ? "postAwrCostTypeName" : "preAwrCostTypeName";
    const awrId = getAwrIdName(awr);
    const sharedDetails = {
      [awrName]: getCostAmountValueInDisplayFormat(costAmount, costTypeName),
      [costTypeNameKey]: costTypeName,
      [awrId]: id
    };
    if (costIndex >= 0) {
      mergedCosts[costIndex] = {
        ...mergedCosts[costIndex],
        ...sharedDetails
      };
    } else {
      mergedCosts.push({
        costCategoryName,
        organisationUnitId: organisationUnitId,
        payRateBandUpper: getAmountInMonetaryDisplayFormat(payRateBandUpper),
        payRateBandLower: getAmountInMonetaryDisplayFormat(payRateBandLower),
        currencyCode,
        ...sharedDetails,
        id
      });
    }
    return mergedCosts;
  }, []);

export const getSplittedPayRates = payRates =>
  payRates.reduce((splittedPayRates, payRate) => {
    const {
      preAwr,
      postAwr,
      activityTypeName,
      currencyCode,
      preAwrId,
      postAwrId
    } = payRate;
    const sharedDetails = { currencyCode, activityTypeName };
    const splittedPayRate = [
      {
        awr: false,
        ...(preAwrId && { id: preAwrId }),
        payRateAmount: getAmountInApiFormat(preAwr),
        ...sharedDetails
      },
      {
        awr: true,
        ...(postAwrId && { id: postAwrId }),
        payRateAmount: getAmountInApiFormat(postAwr),
        ...sharedDetails
      }
    ];
    return [...splittedPayRates, ...splittedPayRate];
  }, []);

const isPercentageCostType = type => type === "percentage";

const getCostAmountValueInApiFormat = (value, costType) =>
  isPercentageCostType(costType)
    ? getAmountInDisplayFormat(value)
    : getAmountInApiFormat(value);

const getCostAmountValueInDisplayFormat = (value, costType) => {
  return isPercentageCostType(costType)
    ? getAmountInMonetaryApiFormat(value)
    : getAmountInMonetaryDisplayFormat(value);
};

export const getSplittedCosts = costs =>
  costs.reduce((splittedCosts, cost) => {
    const {
      preAwr,
      postAwr,
      payRateBandUpper,
      payRateBandLower,
      preAwrCostTypeName,
      postAwrCostTypeName,
      organisationUnitId,
      costCategoryName,
      currencyCode,
      preAwrId,
      postAwrId
    } = cost;
    const sharedDetails = {
      payRateBandUpper: getAmountInApiFormat(payRateBandUpper),
      payRateBandLower: getAmountInApiFormat(payRateBandLower),
      currencyCode,
      ...(organisationUnitId && {
        organisation_unit_id: organisationUnitId
      }),
      costCategoryName
    };
    const splittedCost = [
      {
        awr: false,
        costAmount: getCostAmountValueInApiFormat(preAwr, preAwrCostTypeName),
        costTypeName: preAwrCostTypeName,
        ...(preAwrId && { id: preAwrId }),
        ...sharedDetails
      },
      {
        awr: true,
        costAmount: getCostAmountValueInApiFormat(postAwr, postAwrCostTypeName),
        costTypeName: postAwrCostTypeName,
        ...(postAwrId && { id: postAwrId }),
        ...sharedDetails
      }
    ];
    return [...splittedCosts, ...splittedCost];
  }, []);
