<template>
  <BaseAction>
    <PrimaryButton
      @click.native="openDialog"
      fullWidth
      light
      :class="{ 'mb-10': !isMobile }"
    >
      Add Expense+
    </PrimaryButton>
    <ExpenseDialog
      isEmpty
      v-if="isOpen"
      :expense="initialExpense"
      :isOpen="isOpen"
      :title="title"
      @close="closeDialog"
      actionName="Add expense"
    />
  </BaseAction>
</template>

<script>
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import BaseAction from "@/components/common/BaseAction";
import ExpenseDialog from "@/views/timesheets/components/dialogs/ExpenseDialog";

const INITIAL_EXPENSE = {
  description: "",
  expenseAmount: "",
  currencyCode: "GBP"
};

export default {
  name: "AddExpenseAction",
  components: {
    BaseAction,
    PrimaryButton,
    ExpenseDialog
  },
  data() {
    return {
      isOpen: false,
      title: "New Expense",
      initialExpense: INITIAL_EXPENSE
    };
  },
  methods: {
    openDialog() {
      this.isOpen = true;
    },
    closeDialog() {
      this.isOpen = false;
    }
  }
};
</script>
