<template>
  <div class="d-flex message-box" :style="{ 'background-color': color }">
    <div class="icon-container">
      <v-img v-if="type === 'info'" src="@/assets/svg/info-icon.svg" />
      <v-img
        v-else-if="type === 'warning'"
        src="@/assets/svg/warning-icon.svg"
      />
    </div>
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import styles from "@/sass/abstracts/_variables.scss";

const TYPE_TO_COLOR = {
  warning: styles.warningMessage,
  info: styles.infoMessage
};

const VALID_TYPES = ["info", "warning"];

export default {
  name: "MessageBox",
  props: {
    type: {
      type: String,
      validator(type) {
        return VALID_TYPES.includes(type);
      }
    }
  },
  computed: {
    color() {
      return TYPE_TO_COLOR[this.type];
    }
  }
};
</script>

<style scoped lang="scss">
.message-box {
  padding: 1rem;
  border-radius: $default-border-radius;

  .icon-container {
    width: 30px;
    height: 30px;
    margin-right: 1rem;
  }
}
</style>
