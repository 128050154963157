<template>
  <div>
    <PrimaryButton light @click.native="openChangePassword" shrink>
      Change
      <v-icon class="ml-3">mdi-pencil</v-icon>
    </PrimaryButton>
    <ChangePasswordDialog
      :isOpen="changePasswordOpen"
      @close="closeChangePassword"
    />
  </div>
</template>

<script>
import ChangePasswordDialog from "@/views/settings/components/dialogs/ChangePasswordDialog";
import PrimaryButton from "@/components/common/Button/PrimaryButton";

export default {
  name: "ChangePasswordAction",
  components: { PrimaryButton, ChangePasswordDialog },
  data() {
    return {
      changePasswordOpen: false
    };
  },
  methods: {
    openChangePassword() {
      this.changePasswordOpen = true;
    },
    closeChangePassword() {
      this.changePasswordOpen = false;
    }
  }
};
</script>

<style scoped>
.v-btn {
  margin: 6px !important;
}
</style>
