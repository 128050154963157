<template>
  <BaseAction>
    <DisputeDialog
      :isOpen="isDisputeDialogOpen"
      @close="onClose"
      :timesheetsIds="timesheetsIds"
      :isMultiple="isMultiple"
      @disputed="$emit('disputed')"
    />
    <SecondaryButton
      @click.native="isDisputeDialogOpen = true"
      textColor="#d10a3a"
    >
      <v-img class="mb-1" height="30px" contain :src="icon" />
      Dispute...
    </SecondaryButton>
  </BaseAction>
</template>

<script>
import DisputeDialog from "@/views/timesheets/components/dialogs/DisputeDialog";
import SecondaryButton from "@/components/common/Button/SecondaryButton";
import BaseAction from "@/components/common/BaseAction";

export default {
  name: "DisputeTimesheetAction",
  props: {
    timesheetsIds: {
      type: Array,
      required: true
    },
    isMultiple: Boolean
  },
  components: {
    SecondaryButton,
    DisputeDialog,
    BaseAction
  },
  computed: {
    icon() {
      return require(`@/assets/svg/dispute.svg`);
    }
  },
  data() {
    return {
      isLoading: false,
      isDisputeDialogOpen: false
    };
  },
  methods: {
    onClose() {
      this.isDisputeDialogOpen = false;
    }
  }
};
</script>
