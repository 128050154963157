<template>
  <AppLayout
    v-if="report"
    class="report-details"
    parent="/reports"
    :title="title"
    :isLoading="isLoading"
    centered
    isDetails
  >
    <template v-slot:header>
      <Permissioned section="focused-reports">
        <DeleteReportAction class="ml-1" permission="delete" />
      </Permissioned>
    </template>
    <template v-slot:body>
      <div class="container mb-5 d-flex flex-column align-center">
        <ReportConfig :report="report" v-if="report" />
        <Permissioned :vertical="isMobile" section="focused-reports">
          <v-btn
            permission="edit"
            color="primary"
            @click.native="open = true"
            text
          >
            <v-icon class="mr-1">
              mdi-pencil
            </v-icon>
            Edit
          </v-btn>
          <v-btn
            permission="share"
            color="primary"
            @click.native="sharedDialog = true"
            text
          >
            <v-icon class="mr-1">
              mdi-account-group-outline
            </v-icon>
            Share
          </v-btn>
        </Permissioned>
        <div class="wrapper" :class="{ 'mb-10': isMobile }">
          <ReportLoader v-if="isLoading" />
          <router-view
            v-else-if="reportData && report && reportData.length"
            :data="reportData"
            :report="report"
            :withGroupings="withGroupings"
            :key="viewKey"
            :sections="sections"
            @changeTab="handleTabChange"
            @downloadCSV="downloadCSV"
          />
          <ReportEmptyState
            :class="{ 'mt-1 report-empty': isMobile }"
            v-if="
              report && reportData && !reportData.length && selectedTab === 0
            "
          />
        </div>
      </div>
      <EditReportDetails :open="open" v-if="open" @close="handleCloseEdit" />
      <ShareReport
        :sharedDialog="sharedDialog"
        :report="report"
        v-if="sharedDialog"
        @close="sharedDialog = false"
      />
    </template>
  </AppLayout>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { writeToString } from "@fast-csv/format";
import { REPORTS_NAMESPACE } from "@/store/modules/reports";
import {
  GET_REPORT,
  GET_REPORT_DATA,
  DELETE_REPORT
} from "@/store/modules/reports/action-types";
import AppLayout from "@/components/common/base-layouts/AppLayout";
import Permissioned from "@/components/common/Permissioned";
import ReportConfig from "./ReportConfig";
import ReportEmptyState from "./ReportEmptyState";
import { REPORT_FOCUSES } from "@/constants/reports";
import { includes, size, map, first } from "lodash";
import ReportLoader from "./ReportLoader";
import EditReportDetails from "@/views/reports/dialogs/EditReportDetails";
import { sortReportDataByGroupingFields, formatValues } from "@/utils/reports";
import ShareReport from "@/views/reports/dialogs/ShareReport";
import DeleteReportAction from "@/views/reports/actions/DeleteReportAction";

const { mapActions, mapState } = createNamespacedHelpers(REPORTS_NAMESPACE);

const TAB_INDEXES = {
  TABLE: 0,
  BAR_CHART: 1,
  LINE_CHART: 2,
  DONUT_CHART: 3
};

export default {
  name: "ReportDetails",
  title: "Report - Track",
  components: {
    Permissioned,
    AppLayout,
    ReportConfig,
    ReportEmptyState,
    ReportLoader,
    EditReportDetails,
    ShareReport,
    DeleteReportAction
  },
  data() {
    return {
      selectedTab: 0,
      isLoading: false,
      reportFocuses: REPORT_FOCUSES,
      open: false,
      isDeleting: false,
      sharedDialog: false,
      viewKey: Date.now()
    };
  },
  async created() {
    this.isLoading = true;
    await Promise.all([
      this.getReport({
        id: this.currentReportId,
        params: { include: "fields,filters,groupings,charts" }
      }),
      this.getReportData(this.currentReportId)
    ]);
    this.isLoading = false;
    this.selectedTab = this.initialSection;
  },
  computed: {
    ...mapState({
      reports: state => state.reports,
      reportsData: state => state.reportsData,
      reportCharts: state => state.reportCharts
    }),
    title() {
      return this.report.name || "Untitled Report";
    },
    initialSection() {
      const path = this.$router.currentRoute.path;
      switch (true) {
        case includes(path, "table-data"):
          return TAB_INDEXES.TABLE;
        case includes(path, "bar-chart"):
          return TAB_INDEXES.BAR_CHART;
        case includes(path, "line-chart"):
          return TAB_INDEXES.LINE_CHART;
        case includes(path, "donut-chart"):
          return TAB_INDEXES.DONUT_CHART;
        case includes(path, "details"):
        default:
          return TAB_INDEXES.TABLE;
      }
    },
    currentReportId() {
      return this.$route.params.reportId;
    },
    report() {
      return this.reports.find(r => r.id === this.currentReportId);
    },
    reportFocus() {
      if (!this.report) {
        return undefined;
      }
      const type = this.reportFocuses.find(
        focus => focus.key === this.report.focus
      );
      if (!type) {
        return undefined;
      }
      return type.name;
    },
    reportData() {
      const groupings = map(
        this.report.groupings,
        ({ field, interval_date }) =>
          `${field}${interval_date ? "_" + interval_date : ""}`
      );
      if (size(this.reportsData)) {
        return sortReportDataByGroupingFields({
          groupings,
          reportData: this.reportsData[this.currentReportId]
        });
      }
      return null;
    },
    sections() {
      const path = this.$router.currentRoute.path;
      return [
        {
          path: "table-data",
          icon: require("@/assets/svg/table-tab.svg"),
          active: includes(path, "table-data")
        },
        {
          path: "bar-chart",
          icon: require("@/assets/svg/bar-tab.svg"),
          disabled: !this.isChartOptionAvailable("bar"),
          active: includes(path, "bar-chart")
        },
        {
          path: "line-chart",
          icon: require("@/assets/svg/line-tab.svg"),
          disabled: !this.isChartOptionAvailable("line"),
          active: includes(path, "line-chart")
        },
        {
          path: "donut-chart",
          icon: require("@/assets/svg/donut-tab.svg"),
          disabled:
            !this.isChartOptionAvailable("donut") || this.hideDonutChart,
          active: includes(path, "donut-chart")
        }
      ].filter(Boolean);
    },
    withGroupings() {
      return Boolean(size(this.report.groupings));
    },
    hideDonutChart() {
      return size(this.report.groupings) > 1;
    }
  },
  methods: {
    ...mapActions({
      getReport: GET_REPORT,
      getReportData: GET_REPORT_DATA,
      deleteReport: DELETE_REPORT
    }),
    isChartOptionAvailable(option) {
      const { chartOptions } = this.report;
      return includes(chartOptions, option);
    },
    handleTabChange(tab) {
      this.selectedTab = tab;
      const { path } = this.sections[tab];
      this.$router.replace(`/reports/${this.currentReportId}/${path}`);
    },
    async downloadCSV() {
      const formattedReportData = formatValues({
        reportData: this.reportData,
        fields: this.report.meta.fields
      });
      const data = await writeToString([
        Object.keys(first(this.reportData)),
        ...formattedReportData
      ]);
      const uri = encodeURIComponent(data);
      const link = document.createElement("a");
      link.setAttribute("href", `data:text/csv;charset=utf-8,${uri}`);
      link.setAttribute("download", `${this.report.name}.csv`);
      link.click();
    },
    handleCloseEdit(refreshView) {
      this.open = false;
      if (refreshView) {
        this.viewKey = Date.now();
      }
    }
  },
  provide() {
    return {
      currentReportId: this.currentReportId
    };
  }
};
</script>
<style lang="scss">
.report-details {
  margin-bottom: 100px;

  .basic-tabs {
    .v-tab {
      min-width: 50px;
    }
  }

  .wrapper {
    width: 100%;
  }

  @media only screen and (max-width: $mobile-breakpoint - 1) {
    .v-data-table-header-mobile__wrapper .v-select .v-chip {
      height: 36px;
    }
  }

  .footer {
    background: white;
    padding: 10px 5px !important;
    margin-bottom: 62px !important;
    box-shadow: 1px 1px 6px -1px rgb(0 0 0 / 30%) !important;
    width: auto;
    border-radius: 40px 5px 5px 40px !important;
    z-index: 100;
  }

  .footer button {
    height: 50px !important;
  }

  .select-multiple-buttons {
    padding: 0 !important;
    height: 50px !important;

    .v-btn {
      margin-left: 0px !important;
      padding: 0 10px;
      border-radius: 50px !important;
    }

    .v-btn--disabled {
      svg path {
        fill: rgb(133 123 123 / 26%) !important;
        stroke: rgb(133 123 123 / 26%) !important;
      }
      rect {
        fill: rgb(133 123 123 / 26%) !important;
      }
    }
    .active {
      background: #4b5d69;
    }
    .v-btn.active {
      svg path {
        fill: white;
      }
      rect {
        fill: white;
      }
    }
  }

  .v-text-field__details {
    display: none;
  }
  .select .v-input.v-text-field {
    margin-top: -11px;
  }
  .actions-bar {
    top: 0 !important;
    box-shadow: none !important;
    padding: 0 5px;
    .select-multiple-buttons {
      .v-btn {
        min-width: 55px !important;
      }
    }
  }
  .actions-bar:not(:empty) {
    padding: 0px 10px;
  }

  @media only screen and (max-width: 1300px) {
    .v-select.v-text-field input {
      display: none !important;
    }
    .footer button {
      height: 30px !important;
    }

    .footer {
      padding: 0px 2px !important;
      border-radius: 30px 5px 5px 30px !important;
    }

    .select-multiple-buttons {
      height: 36px !important;
    }
    .v-btn:not(.v-btn--round).v-size--default {
      height: 36px !important;
      min-width: 35px !important;
      padding: 0px 10px !important;
    }

    strong {
      font-size: 12px;
    }

    v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
      > .v-input__control
      > .v-input__slot,
    .v-text-field.v-text-field--enclosed .v-text-field__details {
      padding: 0px 4px;
    }

    .v-text-field--full-width > .v-input__control > .v-input__slot {
      min-height: 40px;
    }
    .v-select__selection {
      font-size: 12px;
    }
  }

  @media only screen and (max-width: $mobile-breakpoint - 1) {
    .select-multiple-buttons {
      box-shadow: none !important;
      display: flex !important;
      justify-content: center !important;
      padding: 22px !important;
      align-items: center;
    }

    .actions-bar {
      top: -3px !important;
      padding: 5px !important;
    }
  }
}

.report-chart,
.report-empty {
  @media only screen and (max-width: $mobile-breakpoint - 1) {
    margin-bottom: 100px;
  }
}

.report-chart {
  display: flex;
  justify-content: center;
}

.report-details .container {
  max-width: 100%;
}
</style>
