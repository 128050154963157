<template>
  <v-card class="dashboard__card" elevation="0">
    <v-card-text>
      <div
        class="dashboard__card--total"
        :class="{ clickable: resource.linkTo }"
        @click="() => handleClick()"
      >
        <h1>{{ getFormattedValue(summary.value) }}</h1>
      </div>
    </v-card-text>
    <v-card-title>
      <div class="dashboard__card--icon" :class="resource.class">
        <v-img
          :src="resource.icon"
          class="dashboard__card--subtotals--icon"
          max-width="28px"
          height="20"
          contain
        />
      </div>
      <p class="font-weight-bold mb-0 ml-2">
        {{ resource.name || summary.name }}
      </p>
    </v-card-title>
  </v-card>
</template>

<script>
import { getNumberAsMonetaryValue } from "@/utils/helpers";

export default {
  props: {
    /**
     *  Summary Object
     */
    summary: {
      type: Object,
      required: false
    },
    organisationId: String
  },
  computed: {
    resource() {
      switch (this.summary.name) {
        case "Live Bookings":
          return {
            icon: require("@/assets/svg/booking_icon_clickable.svg"),
            linkTo: "bookings"
          };
        case "Bookings have applications":
          return {
            icon: require("@/assets/svg/application_icon.svg")
          };
        case "Shifts filled":
          return {
            icon: require("@/assets/svg/shifts-icon-blue.svg")
          };
        case "Timesheets submitted":
          return {
            icon: require("@/assets/svg/timesheet-icon-blue.svg"),
            linkTo: "timesheets"
          };
        case "Invoice Value":
          return {
            icon: require("@/assets/svg/invoices-icon-blue.svg"),
            isAmount: true,
            name: "Invoices"
          };
        default:
          return {
            icon: require("@/assets/svg/booking_icon_clickable.svg")
          };
      }
    }
  },
  methods: {
    handleClick(status) {
      if (this.resource.linkTo) {
        this.$router.push({
          name: this.resource.linkTo,
          params: {
            ...(status && { status }),
            organisationId: this.organisationId
          }
        });
      }
    },
    getFormattedValue(value) {
      return this.resource.isAmount
        ? getNumberAsMonetaryValue(value, "GBP")
        : value;
    }
  }
};
</script>

<style lang="scss"></style>
