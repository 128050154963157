<template>
  <BaseDialog :isOpen="isOpen">
    <template v-slot:title>
      <span>Are you sure you want to approve the booking?</span>
    </template>
    <template v-slot:body>
      <div class="accept-booking-description">
        Approving the booking will allow the hiring manager to publish and fill
        the role once any other approval levels have been completed.
      </div>
    </template>
    <template v-slot:actions>
      <div class="accept-booking-actions">
        <PrimaryButton @click.native="cancel" text>
          Cancel
        </PrimaryButton>
        <PrimaryButton @click.native="handleSubmit" :loading="isLoading">
          Approve Booking
        </PrimaryButton>
      </div>
    </template>
  </BaseDialog>
</template>

<script>
import BaseDialog from "@/components/common/BaseDialog";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import {
  APPROVE_BOOKING,
  BOOKINGS_NAMESPACE
} from "@/store/modules/bookings/actions";
import { createNamespacedHelpers } from "vuex";

const { mapActions } = createNamespacedHelpers(BOOKINGS_NAMESPACE);

export default {
  name: "ApproveBookingDialog",
  components: { PrimaryButton, BaseDialog },
  props: {
    isOpen: Boolean
  },
  data() {
    return {
      isLoading: false
    };
  },
  inject: ["currentBookingId"],
  methods: {
    ...mapActions({
      approveBooking: APPROVE_BOOKING
    }),
    async handleSubmit() {
      this.isLoading = true;
      try {
        await this.approveBooking(this.currentBookingId);
        this.$emit("close");
        this.$emit("approved");
      } finally {
        this.isLoading = false;
      }
    },
    cancel() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss">
.accept-booking-description {
  min-height: 100px;
}
</style>
