<template>
  <BaseAction>
    <SecondaryButton @click.native="openDialog" color="primary">
      <inline-svg :src="require('@/assets/svg/thumb_up.svg')" width="25" />
      Approve Booking
    </SecondaryButton>
    <ApproveBookingDialog
      :isOpen="isOpen"
      @approved="$emit('approved')"
      @close="closeDialog"
    />
  </BaseAction>
</template>

<script>
import ApproveBookingDialog from "@/views/bookings/components/ConfirmDialogs/ApproveBookingDialog";
import BaseAction from "@/components/common/BaseAction";
import SecondaryButton from "@/components/common/Button/SecondaryButton";
import InlineSvg from "vue-inline-svg";

export default {
  name: "ApproveBookingAction",
  components: {
    BaseAction,
    ApproveBookingDialog,
    SecondaryButton,
    InlineSvg
  },
  data() {
    return {
      isOpen: false
    };
  },
  methods: {
    openDialog() {
      this.isOpen = true;
    },
    closeDialog() {
      this.isOpen = false;
    }
  }
};
</script>
