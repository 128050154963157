<template>
  <div v-if="isFetchingData">
    <v-skeleton-loader
      class="mt-4"
      v-for="i in 5"
      width="50%"
      type="list-item-avatar-two-line"
      :key="i"
    />
  </div>
  <div class="dashboard__client" v-else>
    <div class="dashboard__client--summary">
      <DashboardCard
        v-for="(summary, index) in filteredSummary"
        :key="index"
        :summary="summary"
        :organisationId="organisationId"
        :isLastItem="index + 1 === count"
      />
      <v-skeleton-loader
        v-if="isFetchingInvoiceSummary"
        class="mt-5"
        width="70%"
        type="list-item-avatar-two-line"
      />
    </div>
  </div>
</template>

<script>
import DashboardCard from "@/components/dashboard/DashboardCard";
import { filter, map } from "lodash";
import { createNamespacedHelpers } from "vuex";
import { DASHBOARD_NAMESPACE } from "@/store/modules/dashboard";

const dashboard = createNamespacedHelpers(DASHBOARD_NAMESPACE);

export default {
  props: {
    /** Array of object for summaries */
    summaries: {
      type: Object,
      required: false
    },
    organisationId: String,
    permissions: Object
  },
  computed: {
    ...dashboard.mapState({
      isFetchingData: state => state.isFetchingData,
      filters: state => state.filters,
      isFetchingInvoiceSummary: state => state.isFetchingInvoiceSummary
    }),
    count() {
      return this.filteredSummary.length;
    },
    formattedEvents() {
      return map(this.events, ({ attributes }) => {
        const createdAt = attributes.created_at;
        return { createdAt, ...attributes };
      });
    },
    filteredSummary() {
      let summaryList = Object.entries(this.summaries).map(([name, value]) => ({
        name,
        value
      }));
      return filter(summaryList, summary => {
        switch (summary.name) {
          case "Live Bookings": {
            if (this.permissions["dashboard.bookings-total"]) return summary;
            break;
          }
          case "Bookings have applications": {
            if (this.permissions["dashboard.applications-total"])
              return summary;
            break;
          }
          case "Shifts filled": {
            if (this.permissions["dashboard.shifts-total"]) return summary;
            break;
          }
          case "Timesheets submitted": {
            if (this.permissions["dashboard.timesheets-total"]) return summary;
            break;
          }
          default:
            return false;
        }
      });
    }
  },
  components: {
    DashboardCard
  }
};
</script>

<style lang="scss"></style>
