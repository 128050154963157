<template>
  <div class="comments" :class="{ minimize: isMinimize }" v-resize="onResize">
    <div
      class="comments--head"
      @click="toggleMinimize"
      :class="{ minimized: isMinimize }"
    >
      <div
        class="py-1 px-1 d-flex align-center mb-2"
        :class="isMinimize ? 'flex-column' : 'pb-1 pt-1'"
      >
        <div v-if="!isMinimize">
          <h2>Comments</h2>
        </div>
        <v-chip
          color="primary"
          small
          :class="{ 'count-minimized': isMinimize }"
        >
          {{ comments | count }}
        </v-chip>
        <v-icon v-if="isMinimize" large>mdi-comment</v-icon>
      </div>
      <v-btn text icon v-show="minimizable && !isMinimize">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <div v-if="isLoading">
      <v-skeleton-loader
        type="list-item-avatar-three-line"
        v-for="(comments, index) in skeletonComments"
        :key="index"
      />
    </div>
    <div class="comments--body" v-show="!isMinimize" v-else>
      <div class="comments__items">
        <div
          v-for="(comments, index) in commentsByDate"
          :key="index"
          class="comments__items__container"
        >
          <p class="comments__item__date">
            {{ comments[0].createdAt | timeForHumans }}
          </p>
          <div
            class="comments__item mb-4"
            :class="{ 'comments__item--self': isSelf(comment.author.id) }"
            v-for="(comment, index) in comments"
            :key="index"
          >
            <v-avatar
              size="36px"
              v-if="!isSelf(comment.author.id)"
              color="grey lighten-4"
            >
              <span class="grey--text">
                {{ comment.author | initials }}
              </span>
            </v-avatar>
            <div class="comments__item__message">
              <div
                class="comments__item__message__author"
                v-if="!isSelf(comment.author.id)"
              >
                {{ comment.author.firstName }} {{ comment.author.lastName }}
              </div>
              <v-lazy>
                <v-textarea
                  rows="1"
                  auto-grow
                  rounded
                  readonly
                  :value="comment.body"
                />
              </v-lazy>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-text-field
      :disabled="isLoading"
      v-show="!isMinimize"
      v-if="!readOnly"
      v-model="newComment"
      class="comments-input mt-2 mb-2"
      color="primary"
      placeholder="Add comment..."
      append-icon="mdi-arrow-up-circle"
      @click:append="addComment"
      @keydown.enter="addComment"
    />
  </div>
</template>

<script>
import {
  getNameInitials,
  getTimeForHumans,
  groupByDate
} from "@/utils/helpers";
import { createNamespacedHelpers } from "vuex";
import { AUTH_NAMESPACE } from "@/store/modules/auth";

const { mapState } = createNamespacedHelpers(AUTH_NAMESPACE);

export default {
  props: {
    /**
     * An array of objects for comments
     */
    comments: {
      type: Array,
      required: false,
      default: null
    },
    /**
     * Boolean for read only
     */
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Boolean to allow minimize comment
     */
    minimizable: {
      type: Boolean,
      required: false,
      default: true
    },
    isLoading: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      newComment: null,
      isMinimize: this.minimizable && this.$vuetify.breakpoint.mdAndDown,
      skeletonComments: new Array(5).fill().map((a, i) => i)
    };
  },
  computed: {
    ...mapState({
      self: state => state.userData
    }),
    commentsByDate() {
      return groupByDate({ items: this.comments });
    }
  },
  methods: {
    isSelf(id) {
      return this.self.id === id;
    },
    addComment() {
      if (this.newComment) {
        this.$emit("comment", this.newComment);
        this.newComment = null;
        this.scrollToBottom();
      }
    },
    toggleMinimize() {
      if (this.minimizable) {
        this.isMinimize = !this.isMinimize;
      }
    },
    onResize() {
      if (this.$vuetify.breakpoint.mdAndDown && this.minimizable) {
        this.isMinimize = true;
      }
    },
    scrollToBottom() {
      const commentsBody = this.$el.querySelector(".comments--body");
      if (commentsBody) {
        commentsBody.scrollTop = commentsBody.scrollHeight;
      }
    }
  },
  filters: {
    initials(author) {
      return getNameInitials(author);
    },
    timeForHumans(date) {
      return getTimeForHumans(date);
    },
    count(value) {
      return value.length;
    }
  },
  mounted() {
    this.scrollToBottom();
  },
  watch: {
    comments() {
      this.scrollToBottom();
    }
  }
};
</script>

<style lang="scss"></style>
