export const include = (...relationships) => ({
  include: relationships.join(",")
});

export const nested = (...relationships) => relationships.join(".");

export const ADDRESSES = "addresses";
export const ADMINS = "admins";
export const AGENCY = "agency";
export const APPLICATION_SHIFTS_USERS = "application-shifts.application.user";
export const APPLICATION_SHIFTS_USER_AGENCY =
  "application-shifts.application.user.organisation";
export const APPLICATIONS = "applications";
export const APPROVER = "approver";
export const ASSESSMENTS = "assessments";
export const AUTHOR = "author";
export const BOOKING = "booking";
export const BOOKING_APPROVERS = "booking-approvers,booking-approvers.user";
export const CAUSER = "causer";
export const CHARTS = "CHARTS";
export const CLIENT = "client";
export const COMMENTS = "comments";
export const COMPLIANCE_CHECKS = "compliance-checks";
export const CREATED_BY = "created-by";
export const ENTRIES = "entries";
export const EVENTS = "events";
export const EXPENSES = "expenses";
export const FILE = "file";
export const INTERMEDIARY_ORGANISATION = "intermediary-organisation";
export const LOCATION = "location";
export const MANAGER = "manager";
export const ORGANISATION = "organisation";
export const ORGANISATIONS = "organisations";
export const PAY_RATE_ITEMS = "pay-rate-items";
export const PARENT = "parent";
export const PAY_RATES = "pay-rates";
export const PUBLISHING_SCHEDULE = "publishing-schedules";
export const REFERENCES = "references";
export const SETTINGS = "settings";
export const SHIFT = "shift";
export const SHIFT_PATTERNS = "shift-patterns";
export const SHIFTS = "shifts";
export const TEMPLATE_PAY_RATES = "template-pay-rates";
export const TEMPLATE_PAY_RATES_ITEMS = `${TEMPLATE_PAY_RATES}.template-pay-rate-items`;
export const USER = "user";
export const ROLES = "roles";
export const VERIFIER = "verifier";
export const WORKER = "worker";
export const WORKER_AGENCY = "worker.organisation";
export const TIERS = "tiers";
export const ROOT_CLIENT = "root-client";
export const TEMPLATE_COSTS = "template-costs";
export const CLIENT_INVOICE_SET = "client-invoice-sets";
export const QUOTAS = "quotas";
export const PATTERN = "pattern";
export const APPLICATION_USER_ORGANISATION =
  "application-shifts.application.user.organisation";
export const APPLICATION_SHIFTS = "application-shifts";
