import { has, keys } from "lodash";
import Vue from "vue";

export const setStoreValue = storeProperty => (state, value) => {
  if (!has(state, storeProperty)) {
    console.error(
      `Setting non-existing store property: ${storeProperty}. 
      Current keys: ${keys(state)}
      `
    );
  }
  Vue.set(state, storeProperty, value);
};

const defaultIdGetter = item => String(item.id);

export const appendStoreArrayValue = (
  storeProperty,
  { getId = defaultIdGetter } = {}
) => (state, value) => {
  if (!has(state, storeProperty)) {
    console.error(
      `Setting non-existing store property: ${storeProperty}.
      Current keys: ${keys(state)}
      `
    );
  }
  const array = state[storeProperty];
  if (!Array.isArray(array)) {
    console.error(
      `Trying append to a store property that is not an array: ${storeProperty}.`
    );
    return;
  }
  const existingIndex = array.findIndex(item => getId(item) === getId(value));
  if (existingIndex < 0) {
    array.push(value);
  } else {
    array.splice(existingIndex, 1, value);
  }
};

export const setStoreValueById = (
  property,
  { getId = defaultIdGetter, getData = data => data } = {}
) => (state, value) => {
  const id = getId(value);
  if (!state[property]) {
    state[property] = {};
  }
  const data = getData(value);
  if (data) {
    state[property] = { ...state[property], [id]: data };
  } else {
    delete state[property][id];
  }
};
