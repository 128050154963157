// internal action types
export const GET_WORKER_TIMESHEETS = "GET_WORKER_TIMESHEETS";
export const GET_WORKER_APPLICATIONS = "GET_WORKER_APPLICATIONS";
export const GET_ALL_WORKERS = "GET_ALL_WORKERS";
export const ADD_NEW_WORKER = "ADD_NEW_WORKER";
export const GET_WORKER = "GET_WORKER";
export const UPDATE_WORKER = "UPDATE_WORKER";
export const DELETE_WORKER = "DELETE_WORKER";
export const GET_INTERMEDIARY_COMPANY = "GET_INTERMEDIARY_COMPANY";
export const ADD_NEW_REFERENCE = "ADD_NEW_REFERENCE";
export const DELETE_REFERENCE = "DELETE_REFERENCE";
export const DELETE_WORKER_CV = "DELETE_WORKER_CV";
export const DELETE_WORKER_COMPLIANCE_DOC = "DELETE_WORKER_COMPLIANCE_DOC";

// external action types
export const NS_GET_WORKER_TIMESHEETS = "workers/GET_WORKER_TIMESHEETS";
export const NS_GET_WORKER_APPLICATIONS = "workers/GET_WORKER_APPLICATIONS";
export const NS_GET_ALL_WORKERS = "workers/GET_ALL_WORKERS";
export const NS_ADD_NEW_WORKER = "workers/ADD_NEW_WORKER";
export const NS_GET_WORKER = "workers/GET_WORKER";
export const NS_UPDATE_WORKER = "workers/UPDATE_WORKER";
export const NS_DELETE_WORKER = "workers/DELETE_WORKER";
