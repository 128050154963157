/* eslint-disable prettier/prettier */
export const GET_TIMESHEET_VERIFIERS = "GET_TIMESHEET_VERIFIERS";
export const GET_TIMESHEET_APPROVERS = "GET_TIMESHEET_APPROVERS";
export const GET_IS_FETCHING_APPROVERS = "GET_IS_FETCHING_APPROVERS";
export const GET_IS_FETCHING_VERIFIERS = "GET_IS_FETCHING_VERIFIERS";
export const GET_IS_FETCHING_USER_SETTINGS = "GET_IS_FETCHING_USER_SETTINGS";
export const GET_USER_SETTINGS = "GET_USER_SETTINGS";
export const GET_IS_ADDING_USER = "GET_IS_ADDING_USER";
export const GET_IS_EDITING_USER_DETAILS = "GET_IS_EDITING_USER_DETAILS";
export const GET_IS_FETCHING_USER_ROLES = "GET_IS_FETCHING_USER_ROLES";

const usersGetters = {
  [GET_TIMESHEET_VERIFIERS]: state => state.timesheetVerifiers,
  [GET_TIMESHEET_APPROVERS]: state => state.timesheetApprovers,
  [GET_IS_FETCHING_APPROVERS]: state => state.isFetchingApprovers,
  [GET_IS_FETCHING_VERIFIERS]: state => state.isFetchingVerifiers,
  [GET_IS_FETCHING_USER_SETTINGS]: state => state.isFetchingUserSettings,
  [GET_USER_SETTINGS]: state => state.userSettings,
  [GET_IS_ADDING_USER]: state => state.isAddingUser,
  [GET_IS_EDITING_USER_DETAILS]: state => state.isEditingUserDetails,
  [GET_IS_FETCHING_USER_ROLES]: state => state.isFetchingUserRoles,
};

export default usersGetters;
