<template>
  <BaseAction>
    <PrimaryButton shrink class="mr-2" @click.native="openDialog">
      <v-icon class="bold-icon" size="x-large">
        mdi-plus
      </v-icon>
    </PrimaryButton>
    <AddAngenciesToTierDialog
      v-if="isDialogOpen"
      :tierId="tierId"
      :isOpen="isDialogOpen"
      :tierAgencies="tierAgencies"
      @added="handleAdded"
      @close="closeDialog"
    />
  </BaseAction>
</template>

<script>
import AddAngenciesToTierDialog from "@/views/settings/Organisation/PublishingSchedules/dialogs/AddAngenciesToTierDialog";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import BaseAction from "@/components/common/BaseAction";

export default {
  name: "AddAgenciesToTierAction",
  components: { BaseAction, PrimaryButton, AddAngenciesToTierDialog },
  props: {
    tierId: String,
    tierAgencies: Array
  },
  data() {
    return {
      isDialogOpen: false
    };
  },
  methods: {
    openDialog() {
      this.isDialogOpen = true;
    },
    closeDialog() {
      this.isDialogOpen = false;
    },
    handleAdded() {
      this.$emit("added");
    }
  }
};
</script>

<style scoped></style>
