<template>
  <BaseAction>
    <ErrorButton class="mr-2" shrink light @click.native="openDeleteTierDialog">
      Remove
    </ErrorButton>
    <DeleteTierDialog
      :tierId="tierId"
      :isOpen="isDialogOpen"
      @close="closeDeleteTierDialog"
      @deleted="handleDeleted"
    />
  </BaseAction>
</template>

<script>
import DeleteTierDialog from "@/views/settings/Organisation/PublishingSchedules/dialogs/DeleteTierDialog";
import ErrorButton from "@/components/common/Button/ErrorButton";
import BaseAction from "@/components/common/BaseAction";

export default {
  name: "DeleteTierAction",
  components: { BaseAction, ErrorButton, DeleteTierDialog },
  props: {
    tierId: String
  },
  data() {
    return {
      isDialogOpen: false
    };
  },
  methods: {
    openDeleteTierDialog() {
      this.isDialogOpen = true;
    },
    closeDeleteTierDialog() {
      this.isDialogOpen = false;
    },
    handleDeleted() {
      this.$emit("deleted");
    }
  }
};
</script>
