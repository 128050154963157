<template>
  <ReportWrapper
    :sections="sections"
    :data="data"
    @changeTab="key => $emit('changeTab', key)"
    @downloadCSV="$emit('downloadCSV')"
  >
    <template v-slot:content>
      <v-data-table
        :headers="headers"
        :items="formattedItems"
        :customSort="customSort"
        class="report-table"
        no-data-text=""
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
      >
        <template
          v-for="measure in measures"
          v-slot:[`item.${measure}salary`]="{ item }"
        >
          <span class="text-mono" :key="`${measure}salary`">
            {{ item[`${measure}salary`] }}
          </span>
        </template>
        <template
          v-for="measure in measures"
          v-slot:[`item.${measure}offer_salary`]="{ item }"
        >
          <span class="text-mono" :key="`${measure}offer_salary`">
            {{ item[`${measure}offer_salary`] }}
          </span>
        </template>
        <template
          v-for="measure in measures"
          v-slot:[`item.${measure}salary_band_lower`]="{ item }"
        >
          <span class="text-mono" :key="`${measure}salary_band_lower`">
            {{ item[`${measure}salary_band_lower`] }}
          </span>
        </template>
        <template
          v-for="measure in measures"
          v-slot:[`item.${measure}salary_band_upper`]="{ item }"
        >
          <span class="text-mono" :key="`${measure}salary_band_upper`">
            {{ item[`${measure}salary_band_upper`] }}
          </span>
        </template>
        <template
          v-for="measure in measures"
          v-slot:[`item.${measure}total_cost`]="{ item }"
        >
          <span class="text-mono" :key="`${measure}total_cost`">
            {{ item[`${measure}total_cost`] }}
          </span>
        </template>
        <template
          v-for="measure in measures"
          v-slot:[`item.${measure}cost_per_hire`]="{ item }"
        >
          <span class="text-mono" :key="`${measure}cost_per_hire`">
            {{ item[`${measure}cost_per_hire`] }}
          </span>
        </template>
        <template
          v-for="measure in measures"
          v-slot:[`item.${measure}number_of_applications`]="{ item }"
        >
          <span class="text-mono" :key="`${measure}number_of_applications`">
            {{ item[`${measure}number_of_applications`] }}
          </span>
        </template>
        <template
          v-for="measure in measures"
          v-slot:[`item.${measure}vacancies`]="{ item }"
        >
          <span class="text-mono" :key="`${measure}vacancies`">
            {{ item[`${measure}vacancies`] }}
          </span>
        </template>
        <template
          v-for="measure in measures"
          v-slot:[`item.${measure}total_page_views`]="{ item }"
        >
          <span class="text-mono" :key="`${measure}total_page_views`">
            {{ item[`${measure}total_page_views`] }}
          </span>
        </template>
        <template
          v-for="measure in measures"
          v-slot:[`item.${measure}number_of_offers`]="{ item }"
        >
          <span class="text-mono" :key="`${measure}number_of_offers`">
            {{ item[`${measure}number_of_offers`] }}
          </span>
        </template>
        <template
          v-for="measure in measures"
          v-slot:[`item.${measure}candidate_to_appointees_ratio`]="{ item }"
        >
          <span
            class="text-mono"
            :key="`${measure}candidate_to_appointees_ratio`"
          >
            {{ item[`${measure}candidate_to_appointees_ratio`] }}
          </span>
        </template>
        <template
          v-for="measure in measures"
          v-slot:[`item.${measure}channel_1_cost`]="{ item }"
        >
          <span class="text-mono" :key="`${measure}channel_1_cost`">
            {{ item[`${measure}channel_1_cost`] }}
          </span>
        </template>
        <template
          v-for="measure in measures"
          v-slot:[`item.${measure}channel_2_cost`]="{ item }"
        >
          <span class="text-mono" :key="`${measure}channel_2_cost`">
            {{ item[`${measure}channel_2_cost`] }}
          </span>
        </template>
        <template
          v-for="measure in measures"
          v-slot:[`item.${measure}channel_3_cost`]="{ item }"
        >
          <span class="text-mono" :key="`${measure}channel_3_cost`">
            {{ item[`${measure}channel_3_cost`] }}
          </span>
        </template>
        <template
          v-for="measure in measures"
          v-slot:[`item.${measure}channel_4_cost`]="{ item }"
        >
          <span class="text-mono" :key="`${measure}channel_4_cost`">
            {{ item[`${measure}channel_4_cost`] }}
          </span>
        </template>
        <template
          v-for="measure in measures"
          v-slot:[`item.${measure}channel_5_cost`]="{ item }"
        >
          <span class="text-mono" :key="`${measure}channel_5_cost`">
            {{ item[`${measure}channel_5_cost`] }}
          </span>
        </template>
        <template
          v-for="measure in measures"
          v-slot:[`item.${measure}channel_1_page_views`]="{ item }"
        >
          <span class="text-mono" :key="`${measure}channel_1_page_views`">
            {{ item[`${measure}channel_1_page_views`] }}
          </span>
        </template>
        <template
          v-for="measure in measures"
          v-slot:[`item.${measure}channel_2_page_views`]="{ item }"
        >
          <span class="text-mono" :key="`${measure}channel_2_page_views`">
            {{ item[`${measure}channel_2_page_views`] }}
          </span>
        </template>
        <template
          v-for="measure in measures"
          v-slot:[`item.${measure}channel_3_page_views`]="{ item }"
        >
          <span class="text-mono" :key="`${measure}channel_3_page_views`">
            {{ item[`${measure}channel_3_page_views`] }}
          </span>
        </template>
        <template
          v-for="measure in measures"
          v-slot:[`item.${measure}channel_4_page_views`]="{ item }"
        >
          <span class="text-mono" :key="`${measure}channel_4_page_views`">
            {{ item[`${measure}channel_4_page_views`] }}
          </span>
        </template>
        <template
          v-for="measure in measures"
          v-slot:[`item.${measure}channel_5_page_views`]="{ item }"
        >
          <span class="text-mono" :key="`${measure}channel_5_page_views`">
            {{ item[`${measure}channel_5_page_views`] }}
          </span>
        </template>
        <template
          v-for="measure in measures"
          v-slot:[`item.${measure}application_to_offer_days`]="{ item }"
        >
          <span class="text-mono" :key="`${measure}application_to_offer_days`">
            {{ item[`${measure}application_to_offer_days`] }}
          </span>
        </template>
        <template
          v-for="measure in measures"
          v-slot:[`item.${measure}application_to_start_days`]="{ item }"
        >
          <span class="text-mono" :key="`${measure}application_to_start_days`">
            {{ item[`${measure}application_to_start_days`] }}
          </span>
        </template>
        <template
          v-for="measure in measures"
          v-slot:[`item.${measure}approval_to_offer_start_date`]="{ item }"
        >
          <span
            class="text-mono"
            :key="`${measure}approval_to_offer_start_date`"
          >
            {{ item[`${measure}approval_to_offer_start_date`] }}
          </span>
        </template>
        <template
          v-for="measure in measures"
          v-slot:[`item.${measure}total_applications`]="{ item }"
        >
          <span class="text-mono" :key="`${measure}total_applications`">
            {{ item[`${measure}total_applications`] }}
          </span>
        </template>
        <template v-slot:item.id="{ item }">
          <span class="text-mono">{{ item.id }}</span>
        </template>
        <template v-slot:item.role_id="{ item }">
          <span class="text-mono">{{ item.role_id }}</span>
        </template>
        <template v-slot:item.candidate_id="{ item }">
          <span class="text-mono">{{ item.candidate_id }}</span>
        </template>
        <template v-slot:item.user_application_id="{ item }">
          <span class="text-mono">{{ item.user_application_id }}</span>
        </template>
        <template v-slot:item.count="{ item }">
          <span class="text-mono">{{ item.count }}</span>
        </template>
      </v-data-table>
    </template>
  </ReportWrapper>
</template>

<script>
import { startCase, snakeCase, lowerCase, find, includes } from "lodash";
import { formatValues } from "@/utils/reports";
import { MEASURE_TYPES } from "@/constants/reports";
import { getMonetaryValueAsNumber } from "@/utils/helpers";
import { sortItems } from "vuetify/lib/util/helpers";
import moment from "moment";
import ReportWrapper from "@/views/reports/tabs/ReportWrapper";

export default {
  name: "ReportData",
  props: {
    data: Array,
    report: Object,
    isLoading: Boolean,
    withGroupings: Boolean,
    sections: Array
  },
  components: { ReportWrapper },
  data() {
    return {
      sortBy: "",
      sortDesc: false
    };
  },
  computed: {
    headers() {
      const headerSet = new Set();
      if (this.data[0]) {
        Object.keys(this.data[0]).forEach(key => {
          headerSet.add(key);
        });
      }
      return Array.from(headerSet).map(key => ({
        text: startCase(key),
        align: "start",
        sortable: true,
        value: snakeCase(key),
        class: "min-width-250"
      }));
    },
    formattedItems() {
      return formatValues({
        reportData: this.data,
        fields: this.report.meta.fields,
        isDataTable: true,
        groupings: this.report.groupings
      });
    },
    currencyCode() {
      return "GBP";
    },
    measures() {
      return [
        ...Object.keys(MEASURE_TYPES).map(
          measure => `${lowerCase(MEASURE_TYPES[measure])}_`
        ),
        ""
      ];
    }
  },
  methods: {
    customSort(items, index, isDesc, locale) {
      if (index.length) {
        const field = find(this.report.meta.fields, ({ field }) =>
          includes(snakeCase(index), field)
        );
        if (field && field.type === "currency") {
          items.sort((a, b) => {
            const aValue = includes(a[index], " - ")
              ? a[index].split(" - ")[0]
              : a[index];
            const bValue = includes(b[index], " - ")
              ? b[index].split(" - ")[0]
              : b[index];
            return aValue && bValue
              ? !isDesc[0]
                ? getMonetaryValueAsNumber(aValue) -
                  getMonetaryValueAsNumber(bValue)
                : getMonetaryValueAsNumber(bValue) -
                  getMonetaryValueAsNumber(aValue)
              : -1;
          });
          return items;
        }
        if (field && field.type === "datetime") {
          items.sort((a, b) => {
            if (!isDesc[0]) {
              return moment(b[index]).isBefore(moment(a[index])) ? 1 : -1;
            } else {
              return moment(b[index]).isAfter(moment(a[index])) ? 1 : -1;
            }
          });
          return items;
        }
      }
      return sortItems(items, index, isDesc, locale);
    }
  }
};
</script>

<style lang="scss">
.report-table {
  margin-top: 0;
}
.report-table >>> .v-data-footer {
  position: relative;
  bottom: unset;
}
.report-details {
  @media only screen and (max-width: $mobile-breakpoint) {
    .v-data-footer {
      bottom: 160px;
    }
  }
  .v-data-footer {
    padding-top: 25px;
    padding-bottom: 100px;
  }
}
</style>
