<template>
  <AppLayout class="reports" customToolbar centered>
    <template v-slot:body>
      <div>
        <div class="container mb-5 d-flex flex-column align-center">
          <h1 class="text-center">
            Reports
          </h1>
        </div>

        <Reports :permissions="permissions" ref="reportsContent" />
      </div>

      <NewReport :open="open" v-if="open" @close="open = false" />

      <BaseFooter>
        <Permissioned
          :vertical="isMobile"
          section="focused-reports"
          class="actions-bar"
        >
          <BaseAction permission="create">
            <SecondaryButton color="primary" @click="open = true">
              <v-img :src="require('@/assets/svg/new_report_icon.svg')" />
              New Report
            </SecondaryButton>
          </BaseAction>
        </Permissioned>
      </BaseFooter>
    </template>
  </AppLayout>
</template>

<script>
import AppLayout from "@/components/common/base-layouts/AppLayout";
import Reports from "@/views/reports/Reports";
import BaseFooter from "@/components/common/BaseFooter";
import BaseAction from "@/components/common/BaseAction";
import Permissioned from "@/components/common/Permissioned";
import SecondaryButton from "@/components/common/Button/SecondaryButton";
import { AUTH_NAMESPACE } from "@/store/modules/auth";
import { createNamespacedHelpers } from "vuex";
import { REPORTS_NAMESPACE } from "@/store/modules/reports";
import NewReport from "@/views/reports/dialogs/NewReport";

const { mapState: authMapState } = createNamespacedHelpers(AUTH_NAMESPACE);
const { mapState: mapReportState } = createNamespacedHelpers(REPORTS_NAMESPACE);

export default {
  title: "Reports - Track",
  components: {
    AppLayout,
    Reports,
    BaseFooter,
    BaseAction,
    SecondaryButton,
    Permissioned,
    NewReport
  },
  computed: {
    ...mapReportState({
      isFetchingReports: state => state.isFetchingReports
    }),
    ...authMapState(["permissions"])
  },
  data: () => ({
    open: false
  })
};
</script>

<style lang="scss"></style>
