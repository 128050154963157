import { render, staticRenderFns } from "./DeleteUnitDialog.vue?vue&type=template&id=356b6d7b&scoped=true&"
import script from "./DeleteUnitDialog.vue?vue&type=script&lang=js&"
export * from "./DeleteUnitDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "356b6d7b",
  null
  
)

export default component.exports