export const AGENCY_STATUSES = {
  ACTIVE: "live",
  INACTIVE: "archived"
};

export const getColorByStatus = status => {
  switch (status.toLowerCase()) {
    case AGENCY_STATUSES.ACTIVE:
      return "green";
    case AGENCY_STATUSES.INACTIVE:
    default:
      return "gray";
  }
};
