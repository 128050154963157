export const CALCULATED_COST_MODEL = "calculated-cost";

const calculatedCostModel = {
  body: "",
  createdAt: "",
  updatedAt: "",
  author: {
    jsonApi: "hasOne",
    type: "user"
  }
};

export default calculatedCostModel;
