<template>
  <BaseAction>
    <PlaceWorkersDialog
      v-if="isOpen"
      :shifts="shifts"
      :isOpen="isOpen"
      @close="closeDialog"
      @update="$emit('update')"
    />
    <SecondaryButton @click.native="openDialog" color="primary">
      <div class="d-flex mb-2">
        <inline-svg
          :src="require('@/assets/svg/arrow-right-white.svg')"
          width="28"
        />
        <inline-svg :src="require('@/assets/svg/user-white.svg')" width="28" />
      </div>
      Place Workers
    </SecondaryButton>
  </BaseAction>
</template>

<script>
import PlaceWorkersDialog from "@/views/shifts/components/dialogs/PlaceWorkersDialog";
import BaseAction from "@/components/common/BaseAction";
import SecondaryButton from "@/components/common/Button/SecondaryButton";
import InlineSvg from "vue-inline-svg";

export default {
  props: {
    shifts: Array
  },
  components: {
    BaseAction,
    PlaceWorkersDialog,
    SecondaryButton,
    InlineSvg
  },
  data() {
    return {
      isOpen: false
    };
  },
  methods: {
    openDialog() {
      this.isOpen = true;
    },
    closeDialog() {
      this.isOpen = false;
    }
  }
};
</script>
