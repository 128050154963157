<template>
  <div class="inline-filters mt-0 pt-0" v-if="!isLoading">
    <div
      v-for="(filterOptions, filterKey, index) in availableFilters"
      :key="index"
    >
      <InlineFilter
        v-if="size(filterOptions)"
        :label="startCase(filterKey)"
        :filters="filters[filterKey]"
        :options="filterOptions"
        @clear="handleClear(filterKey)"
      >
        <FilterCheckbox
          :options="getFilterOptions(filterOptions, filterKey)"
          :selectedValues="filters[filterKey]"
          @change="values => updateFilterFor(filterKey, values)"
        />
      </InlineFilter>
    </div>
    <InlineFilter
      v-if="!isEmpty(availableFilters)"
      label="Date"
      :filters="[filters.startDate, filters.endDate]"
      @clear="clearDates"
      isDate
      rangeDate
    >
      <div class="flex-gap-15">
        <DatePicker
          :inputValue="filters.startDate"
          @updatedDate="value => updateFilterFor('startDate', value)"
          label="From"
        />
        <DatePicker
          :inputValue="filters.endDate"
          @updatedDate="value => updateFilterFor('endDate', value)"
          label="Until"
        />
      </div>
    </InlineFilter>
  </div>
</template>

<script>
import DatePicker from "@/components/common/DatePicker";
import { filterMixin } from "@/mixins/filter.mixin";
import { size, startCase, isEmpty } from "lodash";

export default {
  name: "BookingShiftsFilter",
  components: {
    DatePicker
  },
  props: {
    isLoading: Boolean,
    outerSelectedFilters: {
      type: Object,
      default: () => ({})
    },
    filterLabels: {
      type: Object,
      default: () => ({})
    },
    availableFilters: Object
  },
  mixins: [filterMixin],
  methods: {
    clearDates() {
      this.filters.startDate = null;
      this.filters.endDate = null;
      this.saveChanges();
    },
    size,
    startCase,
    isEmpty
  }
};
</script>

<style lang="scss">
.booking-shifts-filters {
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;

  .v-label {
    margin-bottom: 0.5rem;
  }

  .hour-select {
    flex: none !important;
    height: auto;

    div[role="button"] {
      min-height: 50px !important;
    }
  }

  .shifts-filter-buttons {
    margin-top: auto;
    margin-bottom: 10px;
  }
}
</style>
