<template>
  <div>
    <div :class="{ 'd-flex justify-space-between': !isMobile }">
      <StarReview
        :value="assessment.rating"
        size="20px"
        read-only
        :class="{ 'mb-0': isMobile }"
      />
      <p>
        <span class="font-weight-bold">
          {{ assessment.createdBy | getFullName }}
        </span>
        -
        {{ assessment.createdAt | date }}
      </p>
    </div>
    <p>
      {{ assessment.notes }}
    </p>
  </div>
</template>

<script>
import StarReview from "@/components/common/StarReview";
import { DAY_MONTH_FORMAT } from "@/utils/time";
import moment from "moment";
import { getFullName } from "@/utils/users";

export default {
  name: "AssessmentItem",
  components: { StarReview },
  props: {
    assessment: Object
  },
  filters: {
    date(date) {
      return moment(date).format(DAY_MONTH_FORMAT);
    },
    getFullName
  }
};
</script>

<style lang="scss"></style>
