<template>
  <BaseAction>
    <PrimaryButton @click.native="openDialog" :full-width="isMobile">
      Add Assessment
    </PrimaryButton>
    <AssessmentDialog
      :isOpen="isOpen"
      @close="closeDialog"
      :applications="applications"
    />
  </BaseAction>
</template>

<script>
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import AssessmentDialog from "@/views/bookings/Assessment/AssessmentDialog";
import BaseAction from "@/components/common/BaseAction";

export default {
  name: "AddAssessmentAction",
  components: {
    BaseAction,
    AssessmentDialog,
    PrimaryButton
  },
  data() {
    return {
      isOpen: false
    };
  },
  props: {
    applications: {
      type: Array,
      required: true
    }
  },
  methods: {
    openDialog() {
      this.isOpen = true;
    },
    closeDialog() {
      this.isOpen = false;
    }
  }
};
</script>
