<template>
  <v-navigation-drawer
    :width="width"
    v-model="drawer"
    stateless
    fixed
    right
    temporary
    hide-overlay
  >
    <v-container class="filter-container">
      <div class="d-flex justify-space-between">
        <h2>Filters</h2>
        <v-icon @click="toggleFilter">mdi-close</v-icon>
      </div>
      <v-row>
        <slot name="table-filters" />
      </v-row>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    /**
     * Boolean to show filter
     */
    showFilter: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      drawer: this.showFilter,
      start_date: null,
      show_start_date: false
    };
  },
  computed: {
    width() {
      return this.$vuetify.breakpoint.mdAndUp ? 320 : 280;
    }
  },
  watch: {
    showFilter(newValue) {
      this.drawer = newValue;
    }
  },
  methods: {
    toggleFilter() {
      this.$emit("toggle-filter");
    }
  }
};
</script>

<style lang="scss"></style>
