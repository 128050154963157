<template>
  <div class="select-multiple-buttons rounded">
    <label class="mt-1">{{ selectedCount }} selected </label>
    <v-divider v-if="!isSelectAll && !isMobile" class="mx-1" vertical />
    <v-btn
      @click="handleSelectAll"
      shrink
      text
      color="primary"
      v-if="!isSelectAll && !isMenuList"
    >
      <div class="d-flex">
        Select All
        <v-icon class="ml-1">
          mdi-view-grid-outline
        </v-icon>
      </div>
    </v-btn>
    <v-menu offset-y v-if="!isSelectAll && isMenuList">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" shrink text v-on="on">
          Select All
          <v-icon>mdi-view-grid-outline</v-icon>
        </v-btn>
      </template>
      <MenuList
        :items="menuItems"
        @select="selectedAction => selectedAction()"
      />
    </v-menu>
    <v-divider class="mx-1" v-if="!isMobile" vertical />
    <v-btn @click="handleCancel" shrink text color="error">
      <div class="d-flex">
        Cancel
        <v-icon class="ml-1">
          mdi-close
        </v-icon>
      </div>
    </v-btn>
  </div>
</template>

<script>
import MenuList from "@/components/common/Menu/MenuList";
import { map, size, startCase } from "lodash";

export default {
  props: {
    selectedCount: Number,
    isSelectAll: Boolean,
    items: Array
  },
  components: { MenuList },
  computed: {
    isMenuList() {
      return size(this.items);
    },
    menuItems() {
      let menuItems = [
        {
          id: "all",
          title: "All",
          action: () => {
            this.$emit("selectAll");
          }
        }
      ];

      map(this.items, item => {
        const newItem = {
          id: item,
          title: startCase(item),
          action: () => {
            this.$emit("selectAll", item);
          }
        };
        menuItems.push(newItem);
      });

      return menuItems;
    }
  },
  methods: {
    handleSelectAll() {
      this.$emit("selectAll");
    },
    handleCancel() {
      this.$emit("cancel");
    }
  }
};
</script>

<style lang="scss">
.shifts {
  .select-multiple-buttons {
    background: white;
    position: absolute;
    padding: 5px 15px;
    top: -95px;
    box-shadow: 1px 1px 6px -1px rgba(0, 0, 0, 0.3);
    .v-divider--vertical {
      display: initial;
    }
    @media only screen and (max-width: $mobile-breakpoint - 1) {
      text-align: center;
      top: -($base-footer-height + 100px);
    }
  }
}
.footer.relative {
  width: 100% !important;
  .select-multiple-buttons button {
    height: 35px !important;
    margin: 0;
  }
}
</style>
