<template>
  <BaseAction>
    <SecondaryButton @click.native="openCancelShiftDialog" textColor="#d10a3a">
      <v-icon class="mb-1" x-large>
        mdi-delete-outline
      </v-icon>
      Delete {{ pluralize("Shift", size(shifts)) }}
    </SecondaryButton>
    <CancelShiftDialog
      :shifts="shifts"
      :isOpen="isCancelShiftOpen"
      :isV2="isV2"
      @close="closeCancelShiftDialog"
    />
  </BaseAction>
</template>

<script>
import CancelShiftDialog from "@/views/bookings/components/ConfirmDialogs/CancelShiftDialog";
import BaseAction from "@/components/common/BaseAction";
import SecondaryButton from "@/components/common/Button/SecondaryButton";
import pluralize from "pluralize";
import { size } from "lodash";

export default {
  name: "CancelShiftAction",
  components: { BaseAction, SecondaryButton, CancelShiftDialog },
  props: {
    shifts: Array,
    isV2: Boolean
  },
  data() {
    return {
      isCancelShiftOpen: false
    };
  },
  methods: {
    openCancelShiftDialog() {
      this.isCancelShiftOpen = true;
    },
    closeCancelShiftDialog({ updateList }) {
      if (updateList) {
        this.$emit("updateList");
      }
      this.isCancelShiftOpen = false;
    },
    pluralize,
    size
  }
};
</script>
