<template>
  <div>
    <v-dialog :value="isOpen" @input="close" max-width="600" v-if="isOpen">
      <v-card class="iframe-wrapper">
        <iframe
          scrolling="no"
          ref="iframe"
          frameBorder="0"
          height="700"
          :src="iframeSrc"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { POST_GLOBAL_MESSAGE } from "@/store/modules/global/action-types";
import { mapActions } from "vuex";

const IFRAME_CHANGE_PASSWORD_VIEW_MESSAGE = "closeChangePasswordView";

export default {
  name: "ChangePasswordDialog",
  props: {
    isOpen: Boolean
  },
  created() {
    window.addEventListener("message", this.onMessage);
  },
  data() {
    return {
      iframeSrc: `${process.env.VUE_APP_AUTH_URL}/password/change`
    };
  },
  methods: {
    ...mapActions({
      postGlobalMessage: POST_GLOBAL_MESSAGE
    }),
    onMessage({ data: message }) {
      if (message === IFRAME_CHANGE_PASSWORD_VIEW_MESSAGE) {
        this.close();
        const message = {
          snackbar: true,
          type: "success",
          title: `Your password has been changed successfully`
        };
        this.postGlobalMessage(message);
      }
    },
    close() {
      this.$emit("close");
    }
  }
};
</script>
