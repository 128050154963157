import APIService, { withPrefix } from "@/services/APIService";
import JSONApiService from "@/services/JSONApiService";
import { ADDRESS_MODEL } from "@/models/address-model";
import { isNotTestingEnv } from "@/services/utils";

const API = isNotTestingEnv() && withPrefix(APIService.client(), "addresses");

const addressesApi = ({ commit } = {}) => ({
  getAddress: async id => API.get(`${id}`),
  deleteAddress: async id =>
    JSONApiService(commit)
      .one(ADDRESS_MODEL, id)
      .destroy(),
  getAllAddresses: async () => JSONApiService(commit).findAll(ADDRESS_MODEL),
  addNewAddress: async data => {
    return JSONApiService(commit).create(ADDRESS_MODEL, data);
  },
  updateAddress: async data => {
    return JSONApiService(commit).update(ADDRESS_MODEL, data);
  }
});

export default addressesApi;
