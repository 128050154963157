import moment from "moment";
import {
  BASE_DATE_FORMAT,
  SHORT_DATE_FORMAT,
  LONG_DATE_FORMAT_WITH_TIME
} from "@/constants/common.js";

export const baseDateFilter = date => {
  return date ? moment(date).format(BASE_DATE_FORMAT) : "";
};

export const shortDateFilter = date => {
  return date ? moment(date).format(SHORT_DATE_FORMAT) : "";
};

export const longDatewithTimeFilter = date => {
  return date ? moment(date).format(LONG_DATE_FORMAT_WITH_TIME) : "";
};
