<template>
  <div>
    <div
      v-for="payRateItem in payRateItems"
      :key="payRateItem.id"
      class="cost-item"
    >
      <div class="cost-label">{{ payRateItem.costName }}</div>
      <div class="d-flex">
        <div class="cost-rate">{{ payRateItem.costRate }}</div>
        <div class="cost-amount">
          {{ payRateItem.amount | currency(payRateItem.currencyCode) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PayRateItems",
  props: {
    payRateItems: Array
  }
};
</script>

<style scoped lang="scss"></style>
