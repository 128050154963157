<template>
  <app-layout
    parent="/files"
    isDetails
    class="container col-lg-10 col-md-8 col-sm-12"
    :title="file.name"
    :isLoading="isFetchingFile"
  >
    <template #backButtonText>Files</template>
    <template v-slot:body>
      <div v-if="isFetchingFile">
        <v-skeleton-loader
          v-bind="{ attrs: { class: 'mb-6', boilerplate: true, elevation: 2 } }"
          type="card-heading, image"
        />
      </div>
      <div class="text-center" v-else>
        <PrimaryButton @click.native="download" text shrink dense>
          Download
          <inline-svg
            class="ml-1"
            :src="require('@/assets/svg/download-primary.svg')"
            width="15"
          />
        </PrimaryButton>
        <ErrorButton
          @click.native="removeFile"
          :loading="deleting"
          text
          shrink
          dense
        >
          Delete File
          <v-icon>mdi-trash-can-outline</v-icon>
        </ErrorButton>
        <div class="my-4">
          <v-img
            v-if="isImage"
            :src="fileUrlWithToken"
            contain
            aspect-ratio="1.8"
          />
          <embed
            v-else-if="file.file"
            :src="fileUrlWithToken"
            :style="styles"
            :type="file.file.mimeType"
          />
        </div>
      </div>
    </template>
  </app-layout>
</template>

<script>
import appLayout from "@/components/common/base-layouts/AppLayout";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import ErrorButton from "@/components/common/Button/ErrorButton";
import { createNamespacedHelpers } from "vuex";
import { FILES_NAMESPACE } from "@/store/modules/files";
import { FETCH_FILE, DELETE_FILE } from "@/store/modules/files/actions";
import InlineSvg from "vue-inline-svg";
import { getToken } from "@/services/utils";
import { forceFileDownload } from "@/utils/helpers";
import { includes } from "lodash";

const { mapState, mapActions } = createNamespacedHelpers(FILES_NAMESPACE);

export default {
  components: {
    appLayout,
    PrimaryButton,
    ErrorButton,
    InlineSvg
  },
  created() {
    this.fetchFile(this.currentFileId);
  },
  data() {
    return {
      deleting: false
    };
  },
  computed: {
    ...mapState(["file", "isFetchingFile"]),
    currentFileId() {
      return this.$route.params.id;
    },
    fileUrlWithToken() {
      const token = getToken();
      const { file } = this.file;
      return file && `${file.url}?token=${token}`;
    },
    styles() {
      return { width: "100%", height: "750px", marginBottom: "15px" };
    },
    isImage() {
      const { file } = this.file;
      return file && includes(file.mimeType, "image");
    }
  },
  methods: {
    ...mapActions({
      fetchFile: FETCH_FILE,
      deleteFile: DELETE_FILE
    }),
    async download() {
      const { file } = this.file;
      if (file) {
        await forceFileDownload(this.fileUrlWithToken, file.fileName);
      }
    },
    async removeFile() {
      this.deleting = true;
      await this.deleteFile(this.currentFileId);
      this.$router.push({
        name: "files"
      });
      this.deleting = false;
    }
  }
};
</script>
