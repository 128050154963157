import JSONApiService from "@/services/JSONApiService";
import { EVENT_MODEL } from "@/models/event-model";
import { SUMMARY_MODEL } from "@/models/summary-model";
import { include, CAUSER } from "@/models/relationships";
import { INVOICE_MODEL } from "@/models/invoice-model";

export default ({ commit } = {}) => ({
  getAllSummaries: async params =>
    JSONApiService(commit)
      .all(SUMMARY_MODEL)
      .get(params),
  getEvents: async filters =>
    JSONApiService(commit)
      .all(EVENT_MODEL)
      .get({ ...filters, ...include(CAUSER) }),
  getInvoiceSummaries: async params =>
    JSONApiService(commit)
      .all(SUMMARY_MODEL)
      .all(INVOICE_MODEL)
      .get(params)
});
