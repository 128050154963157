<template>
  <div class="global-messages">
    <Snackbar
      v-for="(message, index) in globalMessages"
      :key="index"
      :data="message"
      :index="index"
      isVisible
    />
  </div>
</template>

<script>
import Snackbar from "@/components/common/Snackbar";

export default {
  name: "GlobalMessage",
  components: { Snackbar },
  computed: {
    globalMessages() {
      return this.$store.state.global.messages;
    }
  }
};
</script>

<style lang="scss"></style>
