<template>
  <app-layout title="">
    <template v-slot:body>
      <div class="access-denied">
        <v-icon size="5rem">mdi-lock</v-icon>
        <span class="description">
          You don't have permission to view this page
        </span>
      </div>
    </template>
  </app-layout>
</template>

<script>
import appLayout from "@/components/common/base-layouts/AppLayout";

export default {
  name: "accessDenied",
  components: {
    appLayout
  }
};
</script>

<style lang="scss">
.access-denied {
  text-align: center;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  .v-icon {
    cursor: auto;
  }

  .description {
    margin-top: 20px;
    font-size: 20px;
    font-weight: bold;
  }
}
</style>
