<template>
  <div class="d-flex justify-center">
    <UploadFileDialog @close="closeDialog" :isOpen="isOpen" />
    <SecondaryButton
      @click.native="openDialog"
      permission="create"
      color="primary"
    >
      <inline-svg
        class="mb-1"
        :src="require('@/assets/svg/upload-file.svg')"
        width="25"
      />
      Upload File
    </SecondaryButton>
  </div>
</template>

<script>
import UploadFileDialog from "@/views/files/components/dialogs/UploadFileDialog";
import SecondaryButton from "@/components/common/Button/SecondaryButton";
import InlineSvg from "vue-inline-svg";

export default {
  components: {
    UploadFileDialog,
    SecondaryButton,
    InlineSvg
  },
  data() {
    return {
      isOpen: false
    };
  },
  methods: {
    openDialog() {
      this.isOpen = true;
    },
    closeDialog() {
      this.isOpen = false;
    }
  }
};
</script>
