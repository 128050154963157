<template>
  <BaseAction>
    <SecondaryButton @click.native="openSubmitCandidates" textColor="#0A70F5">
      <div>
        <v-icon>mdi-account-group-outline</v-icon>
        <v-icon>mdi-arrow-right</v-icon>
      </div>
      Submit Candidates
    </SecondaryButton>
    <SubmitCandidatesDialog
      :shifts="selectedShifts"
      :filters="filters"
      v-if="submitCandidatesOpen"
      :isOpen="submitCandidatesOpen"
      @close="closeSubmitCandidates"
    />
  </BaseAction>
</template>

<script>
import SubmitCandidatesDialog from "@/views/bookings/components/SubmitCandidatesDialog";
import SecondaryButton from "@/components/common/Button/SecondaryButton";
import BaseAction from "@/components/common/BaseAction";

export default {
  name: "SubmitCandidatesAction",
  components: { SecondaryButton, SubmitCandidatesDialog, BaseAction },
  props: {
    selectedShifts: Array,
    fullWidth: Boolean,
    filters: Object
  },
  data() {
    return {
      submitCandidatesOpen: false
    };
  },
  methods: {
    openSubmitCandidates() {
      this.submitCandidatesOpen = true;
    },
    closeSubmitCandidates({ updateList }) {
      if (updateList) {
        this.$emit("updateList");
      }
      this.submitCandidatesOpen = false;
    }
  }
};
</script>

<style scoped></style>
