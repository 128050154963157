import {
  find,
  reduce,
  map,
  get,
  toString,
  startCase,
  forEach,
  lowerCase,
  snakeCase,
  uniq,
  uniqBy,
  first
} from "lodash";
import $ from "jquery";
import { thousandSeparator, formatValues, fieldType } from "@/utils/reports";
import { MEASURE_TYPES } from "@/constants/reports";
import { currencyFilter } from "@/utils/filters/currencyFilter";
import { costFilter } from "@/utils/filters/costFilter";
import { getMonetaryValueAsNumber } from "@/utils/helpers";

export const reportChartMixin = {
  data() {
    return {
      series: [],
      categories: [],
      fieldKey: "",
      chartWidth: 0,
      selectedXAxisOption: null,
      selectedYAxisOption: null,
      selectedStack: null
    };
  },
  props: {
    data: Array,
    report: Object,
    withGroupings: Boolean,
    sections: Array
  },
  mounted() {
    this.setChartDimensions();
  },
  computed: {
    isSalary() {
      return this.fieldType(this.fieldKey) === "currency";
    },
    currencyCode() {
      return "GBP";
    },
    xAxisOptions() {
      return this.getXAxisOptions(this.report);
    },
    yAxisOptions() {
      return this.getYAxisOptions(this.report);
    },
    formattedItems() {
      return formatValues({
        reportData: this.data,
        fields: this.report.meta.fields,
        groupings: this.report.groupings
      });
    },
    groupings() {
      return map(this.report.groupings, ({ field, id, interval_date }) => ({
        label: interval_date
          ? startCase(`${field}_${interval_date}`)
          : startCase(field),
        value: id
      }));
    },
    xAxisTitle() {
      return first(this.groupings).label;
    }
  },
  methods: {
    adjustChartData(chartData, report) {
      if (this.selectedYAxisOption) {
        const { measure, field } = find(report.fields, {
          id: toString(this.selectedYAxisOption)
        });
        this.fieldKey = measure
          ? `${lowerCase(MEASURE_TYPES[measure])}_${field}`
          : field;
      } else {
        this.fieldKey = null;
      }

      this.adjustChartSeries(report);
    },
    adjustChartOptions(chartData, report) {
      this.selectedYAxisOption = get(
        find(report.fields, {
          id: toString(chartData.yAxisFieldId)
        }),
        "id"
      );
      this.selectedXAxisOption = get(
        find(report.groupings, {
          id: toString(chartData.xAxisPrimaryGroupingId)
        }),
        "id"
      );
    },
    adjustChartSeries() {
      this.categories = [];
      let groupingSeriesObj = null;
      const field = this.selectedYAxisOption
        ? find(this.yAxisOptions, { value: this.selectedYAxisOption }).label
        : "";
      this.categories = uniq(
        map(this.formattedItems, item => item[this.groupings[0].label] || "")
      );
      if (this.groupings.length > 1) {
        groupingSeriesObj = reduce(
          this.categories,
          (allSeriesObject, category) => {
            forEach(this.xAxisOptions, ({ label }) => {
              const item = find(this.formattedItems, {
                [this.groupings[0].label]: category,
                [this.groupings[1].label]: label
              });
              const value = this.getNumberValue(
                get(item, field),
                snakeCase(field)
              );
              if (allSeriesObject[label]) {
                allSeriesObject[label].push(value);
              } else {
                allSeriesObject[label] = [value];
              }
            });
            return allSeriesObject;
          },
          {}
        );
      } else {
        groupingSeriesObj = reduce(
          this.formattedItems,
          (allSeriesObject, item) => {
            let value = this.getNumberValue(
              get(item, startCase(this.fieldKey))
            );
            if (allSeriesObject[field]) {
              allSeriesObject[field].push(value);
            } else {
              allSeriesObject[field] = [value];
            }
            return allSeriesObject;
          },
          {}
        );
      }
      this.series = map(Object.keys(groupingSeriesObj), name => ({
        name,
        data: groupingSeriesObj[name]
      }));
    },
    setChartDimensions() {
      const $container = $(".container");
      this.chartWidth = $container.first().width();
    },
    onResize() {
      this.setChartDimensions();
    },
    getXAxisOptions(report) {
      if (report.groupings.length > 1) {
        return uniqBy(
          map(this.formattedItems, item => ({
            label: item[startCase(report.groupings[1].field)] || ""
          })),
          "label"
        );
      }
      return [];
    },
    getYAxisOptions(report) {
      return map(report.fields, ({ measure, field, id }) => ({
        label: `${MEASURE_TYPES[measure] || ""} ${startCase(field)}`.trim(),
        value: id
      }));
    },
    getExportOptions(report) {
      return {
        export: {
          csv: {
            filename: report.name
          },
          svg: {
            filename: report.name
          },
          png: {
            filename: report.name
          }
        }
      };
    },
    getLabelValue(value, isCurrency) {
      return this.isSalary || isCurrency
        ? `${currencyFilter(
            costFilter(Number(value * 100)),
            this.currencyCode
          )}`
        : thousandSeparator(value);
    },
    getNumberValue(value = 0, field = this.fieldKey) {
      return this.fieldType(field) === "currency"
        ? getMonetaryValueAsNumber(value.toString())
        : value;
    },
    dataLabels() {
      const selectedStack = this.selectedStack;
      return {
        formatter: value => {
          const formattedValue = this.getLabelValue(value);
          return selectedStack === "hundred_percent"
            ? `${formattedValue.toFixed()}%`
            : formattedValue;
        }
      };
    },
    fieldType(field) {
      return fieldType(this.report.meta.fields, field);
    }
  }
};
