<template>
  <BaseAction>
    <SecondaryButton light @click.native="openDialog" textColor="#d10a3a">
      <v-img class="mb-1" height="30px" contain :src="icon" />
      Recall Timesheet
    </SecondaryButton>
    <RecallDialog
      :isOpen="isDialogOpen"
      @close="closeDialog"
      @onUpdate="onUpdate"
      :id="id"
    />
  </BaseAction>
</template>

<script>
import SecondaryButton from "@/components/common/Button/SecondaryButton";
import RecallDialog from "@/views/timesheets/components/dialogs/RecallDialog";
import BaseAction from "@/components/common/BaseAction";

export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },
  components: {
    SecondaryButton,
    RecallDialog,
    BaseAction
  },
  data() {
    return {
      isDialogOpen: false
    };
  },
  computed: {
    icon() {
      return require(`@/assets/svg/recall.svg`);
    }
  },
  methods: {
    openDialog() {
      this.isDialogOpen = true;
    },
    closeDialog() {
      this.isDialogOpen = false;
    },
    onUpdate() {
      this.$emit("onUpdate");
      this.closeDialog();
    }
  }
};
</script>
