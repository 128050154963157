export const BOOKING_TEMPLATE_MODEL = "booking-template";

const bookingTemplateModel = {
  jobTitle: "",
  description: "",
  status: "",
  yearsReferencesRequired: "",
  otherRequirements: [],
  complianceRequirements: [],
  publishingScheduleTags: [],
  payRates: [],
  costs: [],
  timeUnitName: "",
  templateCosts: {
    jsonApi: "hasMany",
    type: "templateCost"
  },
  templatePayRates: {
    jsonApi: "hasMany",
    type: "payRate"
  },
  client: {
    jsonApi: "hasOne",
    type: "organisations"
  }
};

export default bookingTemplateModel;
