<template>
  <div class="report-between-date-filter d-flex">
    <v-menu
      ref="menuFrom"
      v-model="menuFrom"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="dateFromInput"
          placeholder="From"
          v-bind="attrs"
          v-on="on"
          v-mask="'##/##/####'"
          @change="onDateFromChanged"
        />
      </template>
      <v-date-picker v-model="dateFrom" :max="dateTo" no-title scrollable>
        <v-spacer />
        <v-btn text color="primary" @click="menuFrom = false">
          Cancel
        </v-btn>
        <v-btn text color="primary" @click="$refs.menuFrom.save(dateFrom)">
          OK
        </v-btn>
      </v-date-picker>
    </v-menu>
    <v-menu
      ref="menuTo"
      v-model="menuTo"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :value="dateToInput"
          placeholder="To"
          v-bind="attrs"
          :errorMessages="errorMessages"
          v-on="on"
          v-mask="'##/##/####'"
          @change="onDateToChanged"
        />
      </template>
      <v-date-picker :min="this.dateFrom" v-model="dateTo" no-title scrollable>
        <v-spacer />
        <v-btn text color="primary" @click="menuTo = false">
          Cancel
        </v-btn>
        <v-btn text color="primary" @click="$refs.menuTo.save(dateTo)">
          OK
        </v-btn>
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import {
  formatDate,
  getReportFormatDate,
  isValidDateString
} from "@/utils/time";
import { BASE_DATE_FORMAT } from "@/constants/common";

export default {
  name: "ReportBetweenDateFilter",
  props: {
    value: String,
    onChange: Function,
    fieldType: String,
    filterType: String,
    errorMessages: [String, Array]
  },
  data: instance => ({
    dateFrom: new Date(instance.value.split(",")[0])
      .toISOString()
      .substr(0, 10),
    dateTo: new Date(instance.value.split(",")[1]).toISOString().substr(0, 10),
    menuFrom: false,
    menuTo: false,
    modal: false,
    dateFromInput: getReportFormatDate(instance.dateFrom),
    dateToInput: getReportFormatDate(instance.dateTo)
  }),
  methods: {
    onDateFromChanged() {
      if (isValidDateString(this.dateFromInput)) {
        this.dateFrom = formatDate({
          date: this.dateFromInput,
          startFormat: BASE_DATE_FORMAT
        });
      } else {
        this.dateFromInput = getReportFormatDate(this.dateFrom);
      }
    },
    onDateToChanged() {
      if (isValidDateString(this.dateToInput)) {
        this.dateTo = formatDate({
          date: this.dateFromInput,
          startFormat: BASE_DATE_FORMAT
        });
      } else {
        this.dateToInput = getReportFormatDate(this.dateTo);
      }
    }
  },
  watch: {
    dateFrom(next) {
      this.dateFromInput = getReportFormatDate(next);
      this.onChange(`${next},${this.dateTo}`);
    },
    dateTo(next) {
      this.dateToInput = getReportFormatDate(next);
      this.onChange(`${this.dateFrom},${next}`);
    }
  }
};
</script>
