import Fuse from "fuse.js";
import { first, size, includes, map } from "lodash";

const REF_INDEX = "refIndex";

const fuseSearch = (term, list, options) => {
  const defaultOptions = {
    threshold: 0.4
  };
  const run = new Fuse(list, { ...defaultOptions, ...options });
  if (term) {
    const results = run.search(term);
    // Fuse appends some additional information to the returned object. We should remove it to return the same object.
    const includesRefIndex =
      size(results) && includes(Object.keys(first(results)), REF_INDEX);
    return includesRefIndex ? map(results, "item") : results;
  }
  return list;
};

export default fuseSearch;
