<template>
  <BaseAction>
    <EditQuotasDialog
      v-if="isOpen"
      :shift="shift"
      :isOpen="isOpen"
      @close="closeDialog"
      @update="$emit('update')"
    />
    <SecondaryButton @click.native="openDialog" textColor="#0A70F5">
      <v-icon class="mb-2">mdi-pencil</v-icon>
      Edit Quotas
    </SecondaryButton>
  </BaseAction>
</template>

<script>
import EditQuotasDialog from "@/views/shifts/components/dialogs/EditQuotasDialog";
import BaseAction from "@/components/common/BaseAction";
import SecondaryButton from "@/components/common/Button/SecondaryButton";

export default {
  props: {
    shift: Object
  },
  components: {
    BaseAction,
    EditQuotasDialog,
    SecondaryButton
  },
  data() {
    return {
      isOpen: false
    };
  },
  methods: {
    openDialog() {
      this.isOpen = true;
    },
    closeDialog() {
      this.isOpen = false;
    }
  }
};
</script>
