import dataActions from "@/store/modules/data/actions";
import dataMutations from "@/store/modules/data/mutations";
import dataGetters from "@/store/modules/data/getters";
import { isMockedDataEnabled } from "@/utils/mocked-data";
import { MOCKED_DATA_STATE } from "@/constants/data";

const state = {
  isFetchingData: false,
  bookingTemplates: [],
  currentBookingTemplate: {},
  incomeTypes: [],
  locations: [],
  reasonsForHire: [],
  complianceRequirements: [],
  otherRequirements: [],
  rejectionReasons: [],
  requirements: [],
  costCodes: [],
  countries: [],
  isFetchingFieldNecessities: false,
  fieldNecessities: [],
  isFetchingComplianceRequirements: false,
  isFetchingOtherRequirements: false,
  publishingScheduleTags: [],
  isFetchingPublishingScheduleTags: false,
  costCategories: [],
  isFetchingCostCategories: false,
  timeUnits: [],
  isFetchingTimeUnits: false,
  systemParameters: {}
};

export default {
  namespaced: true,
  state: isMockedDataEnabled() ? MOCKED_DATA_STATE : state,
  actions: isMockedDataEnabled() ? {} : dataActions,
  mutations: isMockedDataEnabled() ? {} : dataMutations,
  getters: dataGetters
};
