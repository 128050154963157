import { setStoreValue } from "@/store/utils";

export const SET_ORGANISATION_UNITS = "SET_ORGANISATION_UNITS";
export const SET_IS_FETCHING_ORGANISATION_UNITS =
  "SET_IS_FETCHING_ORGANISATION_UNITS";

const mutations = {
  [SET_ORGANISATION_UNITS]: setStoreValue("organisationUnits"),
  [SET_IS_FETCHING_ORGANISATION_UNITS]: setStoreValue("isFetchingUnits")
};

const state = {
  organisationUnits: [],
  isFetchingUnits: false
};

export { mutations, state };
