<template>
  <div class="align-center">
    <Chip :color="color">
      <v-img class="ml-1 mr-1" height="20px" contain :src="icon" />
      <div class="mr-1">
        {{ data.name }}
      </div>
      <v-img height="30px" contain :src="status" />
    </Chip>
    <div class="mx-1 my-2" v-if="data.date">
      {{ data.date | longDatewithTime }}
    </div>
  </div>
</template>

<script>
import Chip from "@/components/common/Chip";

export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  components: { Chip },
  computed: {
    icon() {
      return require(`@/assets/svg/user-icon.svg`);
    },
    color() {
      return this.data.date ? "green" : "gray";
    },
    status() {
      if (this.data.date) {
        return require(`@/assets/svg/complete.svg`);
      }
      return require(`@/assets/svg/pending.svg`);
    }
  }
};
</script>

<style lang="scss" scoped>
.timesheet--details {
  max-width: 100%;
  .v-chip--label.v-chip {
    color: #000 !important;
    text-transform: none !important;
    font-weight: 600 !important;
    padding: 0px;
  }
  .v-chip .v-icon {
    color: #000;
  }
}
</style>
