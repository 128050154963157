export const REFERENCE_MODEL = "reference";

const referenceModel = {
  referenceTypeName: "",
  periodStartDate: "",
  periodEndDate: "",
  referenceReceivedDate: "",
  user: {
    jsonApi: "hasOne",
    type: "users"
  }
};

export default referenceModel;
