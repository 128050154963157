export const APPLICATION_STATUSES = {
  OFFERED: {
    title: "offered",
    color: "green"
  },
  REJECTED: {
    title: "rejected",
    color: "red"
  },
  SHORTLISTED: {
    title: "in progress",
    color: "blue"
  },
  EVALUATED: {
    title: "in progress",
    color: "blue"
  },
  UNSORTED: {
    title: "submitted",
    color: "yellow"
  },
  DRAFT: {
    title: "draft",
    color: "gray"
  }
};

export const SORTED_APPLICATION_STATUSES = [
  "draft",
  "unsorted",
  "shortlisted",
  "evaluated",
  "approved",
  "interviewing",
  "withdrawn",
  "rejected",
  "offered"
];
