<template>
  <ReportWrapper
    :sections="sections"
    :data="data"
    @changeTab="key => $emit('changeTab', key)"
    @downloadCSV="$emit('downloadCSV')"
  >
    <template v-slot:content>
      <div class="report-chart" v-resize="onResize">
        <apexchart
          :options="chartOptions"
          :series="series"
          v-if="withGroupings"
        />
        <h3 v-else class="my-10">
          Please include more data in your report to be able to generate a chart
        </h3>
      </div>
    </template>
    <template v-slot:actions>
      <div class="d-flex align-center flex-wrap actions-bar">
        <strong class="mx-2">Y-Axis</strong>
        <Select
          label=""
          :items="yAxisOptions"
          v-model="selectedYAxisOption"
          @change="val => handleOptionChanged('yAxisFieldId', val)"
          item-text="label"
          item-value="value"
        />
      </div>
    </template>
  </ReportWrapper>
</template>

<script>
import { REPORTS_NAMESPACE } from "@/store/modules/reports";
import { createNamespacedHelpers } from "vuex";
import {
  CREATE_REPORT_CHART,
  EDIT_REPORT_CHART
} from "@/store/modules/reports/action-types";
import { SET_CURRENT_REPORT_CHART } from "@/store/modules/reports/mutation-types";
import { first, find, startCase } from "lodash";
import Select from "@/components/common/Select";
import { reportChartMixin } from "@/mixins/report-chart.mixin";
import ReportWrapper from "@/views/reports/tabs/ReportWrapper";

const { mapActions, mapState, mapMutations } = createNamespacedHelpers(
  REPORTS_NAMESPACE
);

export default {
  name: "LineChart",
  components: {
    Select,
    ReportWrapper
  },
  mixins: [reportChartMixin],
  async created() {
    const currentReportChart = find(this.reportCharts, { chartType: "line" });
    this.setCurrentReportChart(currentReportChart);
    if (!currentReportChart) {
      const { groupings, fields } = this.report;
      await this.createChart({
        title: this.report.name,
        chartType: "line",
        xAxisPrimaryGroupingId: first(groupings).id,
        ...(groupings[1] && { xAxisSecondaryGroupingId: groupings[1].id }),
        yAxisFieldId: first(fields).id
      });
    }
    this.adjustChartOptions(this.currentChart, this.report);
    this.adjustChartData(this.currentChart, this.report);
  },
  computed: {
    ...mapState({
      reportCharts: state => state.reportCharts,
      currentChart: state => state.currentReportChart
    }),
    chartOptions() {
      return {
        chart: {
          id: "reports-line-chart",
          type: "line",
          width: this.chartWidth,
          height: 850,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          }
        },
        legend: {
          show: true
        },
        dataLabels: {
          enabled: false
        },
        grid: {
          show: true
        },
        stroke: {
          show: true,
          curve: "smooth",
          lineCap: "sqaure"
        },
        markers: {
          size: 8,
          strokeColors: "#fff",
          strokeWidth: 2,
          strokeOpacity: 0.9,
          strokeDashArray: 0,
          fillOpacity: 1,
          shape: "circle",
          radius: 2,
          offsetX: 0,
          offsetY: 0,
          showNullDataPoints: false
        },
        xaxis: {
          title: { text: this.xAxisTitle },
          labels: {
            rotateAlways: true,
            offsetY: 5,
            minHeight: 300,
            style: {
              fontFamily:
                "-apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica",
              fontSize: "10px"
            }
          },
          categories: this.categories
        },
        yaxis: {
          title: {
            text: startCase(this.fieldKey)
          },
          labels: {
            formatter: value => {
              return this.getLabelValue(value);
            },
            style: {
              fontFamily:
                "'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace",
              fontSize: "10px"
            }
          }
        }
      };
    }
  },
  methods: {
    ...mapMutations({
      setCurrentReportChart: SET_CURRENT_REPORT_CHART
    }),
    ...mapActions({
      createChart: CREATE_REPORT_CHART,
      editChart: EDIT_REPORT_CHART
    }),
    async handleOptionChanged(key, value) {
      await this.editChart({
        reportId: this.currentChart.id,
        data: {
          [key]: value
        }
      });
      this.adjustChartData(this.currentChart, this.report);
    }
  }
};
</script>
