// internal mutation types
export const TOGGLE_DRAWER = "TOGGLE_DRAWER";
export const UPDATE_IMPERSONATE_USER = "UPDATE_IMPERSONATE_USER";
export const TOGGLE_IMPERSONATE_DIALOG = "TOGGLE_IMPERSONATE_DIALOG";
export const SET_API_AVAILABILITY = "SET_API_AVAILABILITY";

// external mutation types
export const NS_TOGGLE_DRAWER = "layout/TOGGLE_DRAWER";
export const NS_UPDATE_IMPERSONATE_USER = "layout/UPDATE_IMPERSONATE_USER";
export const NS_TOGGLE_IMPERSONATE_DIALOG = "layout/TOGGLE_IMPERSONATE_DIALOG";
export const NS_SET_API_AVAILABILITY = "layout/SET_API_AVAILABILITY";
