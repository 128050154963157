<template>
  <div class="d-flex align-center">
    <inline-svg v-if="this.status !== 'interviewing'" :src="icon" width="60" />
    <div class="d-flex flex-column">
      <div
        class="d-flex"
        :class="{ confirmed: isConfirmed, rejected: isRejected }"
      >
        <h3 class="text-capitalize">{{ status }}</h3>
        <div class="status-count" v-if="!isConfirmed">
          {{ size }}
        </div>
      </div>
      <p class="secondary-text mb-0" v-if="subHeaderText">
        {{ subHeaderText }}
      </p>
    </div>
  </div>
</template>

<script>
import InlineSvg from "vue-inline-svg";

export default {
  props: {
    status: String,
    size: Number
  },
  components: {
    InlineSvg
  },
  computed: {
    icon() {
      return require(`@/assets/svg/${this.status}-icon.svg`);
    },
    subHeaderText() {
      switch (this.status) {
        case "unsorted":
          return "Awaiting shortlisting";
        case "shortlisted":
          return "Awaiting Client Approval";
        case "approved":
          return "Able to allocate to shifts";
        case "allocated":
          return "Pending shift confirmation";
        case "confirmed":
          return "Timesheet will be generated for worker's shifts";
        default:
          return "";
      }
    },
    isConfirmed() {
      return this.status === "confirmed";
    },
    isRejected() {
      return this.status === "rejected" || this.status === "withdrawn";
    }
  }
};
</script>

<style lang="scss" scoped>
.confirmed {
  color: #16795b;
  font-size: 25px;
  font-weight: 600;
}

.rejected {
  color: #d10a3a;
  font-size: 25px;
  font-weight: 600;
}
</style>
