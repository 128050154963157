export const SHIFT_STATUSES = {
  SUBMITTED: "submitted",
  ALLOCATED: "allocated",
  CONFIRMED: "confirmed",
  DECLINED: "declined"
};

export const SHIFT_PERMISSIONS_STATUSES = {
  CONFIRM: "confirm",
  DECLINE: "decline",
  ALLOCATE: "allocate",
  SUBMIT: "submit-candidate",
  CANCEL: "cancel"
};

export const CANDIDATE_SHIFTS_ACTIONS = {
  ALLOCATE_SHIFTS: {
    title: "Allocate Shifts",
    link: "AllocateWorkersConfirmDialog",
    color: "primary",
    permission: "allocate"
  },
  WITHDRAW_FROM_SHIFTS: {
    title: "Withdraw from shifts",
    link: "WithdrawFromShiftsDialog",
    type: "error",
    outlined: true,
    permission: "withdraw"
  },
  CONFIRM_ALLOCATION: {
    title: "Confirm Allocation",
    link: "ConfirmAllocationDialog",
    color: "primary",
    permission: "confirm"
  },
  DECLINE_ALLOCATION: {
    title: "Decline Allocation",
    link: "DeclineAllocationDialog",
    type: "error",
    outlined: true,
    permission: "decline"
  }
};

export const SHIFTS_STATUS = {
  ALLOCATED: "allocated",
  CONFIRMED: "confirmed",
  PART_ALLOCATED: "part-allocated",
  UNALLOCATED: "unallocated"
};
