<template>
  <div class="flex-gap-15">
    <div class="text-center">
      <Avatar :initials="userInitials" size="60" />
      <h5>{{ userFullName }}</h5>
      <p class="secondary-text mb-0">{{ userEmail }}</p>
    </div>
    <v-divider />
    <h6>Profiles</h6>
    <div class="switch-profile">
      <p v-if="isImpersonating" class="secondary-text px-3">
        Disabled switch profile when impersonating
      </p>
      <v-radio-group class="radio" v-else>
        <div
          class="radio--standard"
          @click="setSelectedProfile(profile)"
          :class="{
            'radio--standard__active': isSelected(profile)
          }"
          v-for="(profile, index) in profiles"
          :key="`opt-${index}`"
          aria-label="Select profile"
        >
          <v-radio :value="profile.id" color="white">
            <template v-slot:label>
              <div class="profile__text">
                <b class="w-full mb-1">
                  {{ profile.organisation_unit_name }}
                </b>
                <span
                  v-if="isWorker(profile.roles)"
                  :class="{ 'font-italic': !profile.last_booking }"
                  class="w-full"
                >
                  <v-icon v-if="profile.last_booking">
                    mdi-briefcase-outline
                  </v-icon>
                  {{ profile.last_booking | lastBookingName }}
                </span>
                <span v-else>{{ profile.roles | roles }}</span>
              </div>
            </template>
          </v-radio>
        </div>
      </v-radio-group>
    </div>
  </div>
</template>

<script>
import { getNameInitials } from "@/utils/helpers";
import { getFullName, isWorker, getLastBookingName } from "@/utils/users";
import Avatar from "@/components/common/Avatar";
import { createNamespacedHelpers } from "vuex";
import { AUTH_NAMESPACE } from "@/store/modules/auth";
import { SELECT_PROFILE } from "@/store/modules/auth/action-types";
import { size } from "lodash";
import { getImpersonateUserId } from "@/services/utils";

const { mapState, mapActions } = createNamespacedHelpers(AUTH_NAMESPACE);
export default {
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  components: { Avatar },
  computed: {
    ...mapState({
      selectedProfile: state => state.selectedProfile,
      profiles: state => state.profiles
    }),
    userInitials() {
      return getNameInitials(this.user);
    },
    userFullName() {
      return getFullName(this.user);
    },
    userEmail() {
      return this.user.email;
    },
    isImpersonating() {
      return Boolean(getImpersonateUserId());
    },
    selectedProfileId() {
      return this.selectedProfile.id;
    }
  },
  methods: {
    ...mapActions({
      selectProfile: SELECT_PROFILE
    }),
    setSelectedProfile(profile) {
      if (size(this.profiles) > 1 && this.selectedProfileId !== profile.id) {
        this.selectProfile(profile);
      }
    },
    isSelected(profile) {
      return profile.id === this.selectedProfileId;
    },
    isWorker
  },
  filters: {
    lastBookingName(lastBooking) {
      return getLastBookingName(lastBooking);
    }
  }
};
</script>

<style lang="scss">
.agency__profiles {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    padding-bottom: 50px;
  }
  h6 {
    margin-bottom: 3px;
    text-align: center;
  }
  p {
    color: $secondary-text;
  }
  .radio {
    &--standard {
      padding: 0 0 10px 0 !important;
      background-color: #ffffff !important;
      border: none !important;
      margin: 0;

      h6,
      p {
        color: $secondary-text;
      }

      i {
        margin-bottom: 5px;
      }

      :hover {
        h6,
        p {
          color: $primary-text;
        }

        i {
          color: $primary;
        }
      }

      &__active {
        background-color: #ffffff !important;
        border: none !important;
        box-shadow: none !important;

        h6,
        p {
          color: $primary-text;
        }

        i {
          color: $primary;
        }
      }
    }
  }

  .radio .v-radio label {
    left: 5px;
    top: 12px;
    margin-right: 20px;
  }

  i.v-icon.notranslate.mdi.mdi-briefcase-outline.theme--light {
    display: inline-flex !important;
  }
}
.switch-profile .radio {
  display: flex;
  padding: 15px;
  width: 100%;
  margin-top: 0px !important;
}
h6 {
  text-align: center;
}

.profile__text {
  display: flex;
  flex-direction: column;
  width: 100%;
}
</style>
