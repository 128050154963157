<template>
  <div class="input-item">
    <div class="inputs">
      <Select
        autocomplete
        :items="allFieldsNames"
        item-text="text"
        item-value="field"
        v-model="filterField"
        @change="handleFieldChange($event)"
      />
      <Select
        :items="operators"
        :value="filter.operator"
        @change="handleOperatorChange($event)"
        item-text="label"
        item-value="value"
      />
      <ValidationProvider
        v-slot="{ errors }"
        :name="`filters-${index}`"
        rules="required"
      >
        <ReportFilterValueInput
          :style="{ maxWidth: '600px' }"
          :key="filterField"
          :value="filter.value"
          :unit="filter.unit"
          :onChange="value => handleValueChange(value)"
          :onUnitChange="unit => handleUnitChange(unit)"
          :errorMessages="showValidationErrors ? errors : ''"
          :fieldType="field.type"
          :filterType="filter.operator"
          :fieldData="field"
          :focus="focus"
        />
      </ValidationProvider>
    </div>
    <v-btn icon @click="onDelete"><v-icon>mdi-close</v-icon></v-btn>
  </div>
</template>

<script>
import ReportFilterValueInput from "@/views/reports/forms/filters/ReportFilterValueInput";
import { getDefaultFilterValue, getFieldOptions } from "@/utils/reports";
import { OPERATOR_TYPES } from "@/constants/reports";
import Select from "@/components/common/Select";
import { ValidationProvider } from "vee-validate";
import { filter } from "lodash";

export default {
  name: "ReportFilter",
  components: {
    Select,
    ValidationProvider,
    ReportFilterValueInput
  },
  props: {
    filter: Object,
    availableFields: Array,
    onChange: Function,
    onDelete: Function,
    showValidationErrors: Boolean,
    focus: String,
    labels: Object,
    index: Number
  },
  data() {
    return {
      operatorTypes: OPERATOR_TYPES,
      filterField: this.filter.field
    };
  },
  computed: {
    allFieldsNames() {
      return filter(
        getFieldOptions({
          fields: this.availableFields,
          labels: this.labels
        }),
        x => x.field !== "job_description"
      );
    },
    field() {
      return this.availableFields.find(
        field => field.field === this.filter.field
      );
    },
    operators() {
      return this.field.operators.map(operator => ({
        label: this.operatorTypes[operator] || operator,
        value: operator
      }));
    },
    filterValue() {
      return this.filter.value === "0" ? null : this.filter.value;
    }
  },
  methods: {
    handleFieldChange(field) {
      const newField = this.availableFields.find(
        avField => avField.field === field
      );
      const filterType = newField.operators[0];
      const fieldType = newField.type;
      this.onChange({
        field: newField.field,
        operator: filterType,
        ...getDefaultFilterValue({ fieldType, filterType })
      });
    },
    handleOperatorChange(operator) {
      this.onChange({
        field: this.filter.field,
        operator,
        ...getDefaultFilterValue({
          fieldType: this.field.type,
          filterType: operator
        })
      });
    },
    handleValueChange(value) {
      this.onChange({
        ...this.filter,
        value: Array.isArray(value) ? value.join(" | ") : String(value)
      });
    },
    handleUnitChange(unit) {
      this.onChange({ ...this.filter, unit: String(unit) });
    }
  },
  watch: {
    filter(newValue) {
      this.filterField = newValue.field;
    }
  }
};
</script>
