import MOCKED_VERIFIERS from "./users/verifier-list";
import MOCKED_APPROVERS from "./users/approver-list";

export const MOCKED_USERS_STATE = {
  timesheetVerifiers: MOCKED_VERIFIERS,
  timesheetApprovers: MOCKED_APPROVERS
};

export const USER_STATUSES = {
  INCOMPLETE: "incomplete",
  INACTIVE: "inactive",
  ACTIVE: "active",
  INVITED: "invited",
  INVITE_EXPIRED: "invite expired"
};
