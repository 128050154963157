<template>
  <ReasonForBookingDialog
    v-if="dialogSettings.dialog"
    :dialogSettings="dialogSettings"
    @close="close"
    :reason-for-booking="reasonForBooking"
    actionName="Save Changes"
    :key="id"
    @submit="handleEdit"
    :loading="isLoading"
  />
</template>

<script>
import ReasonForBookingDialog from "@/views/settings/Organisation/ReasonsForBooking/dialogs/ReasonForBookingDialog";
import { UPDATE_ORGANISATION_REASON } from "@/store/modules/organisations/organisation-reasons/actions";
import { ORGANISATION_REASONS_NAMESPACE } from "@/store/modules/organisations/organisation-reasons";
import { createNamespacedHelpers } from "vuex";

const { mapActions } = createNamespacedHelpers(ORGANISATION_REASONS_NAMESPACE);

export default {
  name: "EditReasonAction",
  components: { ReasonForBookingDialog },
  props: {
    isOpen: Boolean,
    reasonForBooking: Object
  },
  data() {
    return {
      id: null,
      isLoading: false
    };
  },
  computed: {
    dialogSettings() {
      return {
        title: "Edit Reason for Booking",
        dialog: this.isOpen
      };
    }
  },
  methods: {
    ...mapActions({
      editReason: UPDATE_ORGANISATION_REASON
    }),
    close() {
      this.$emit("close");
    },
    async handleEdit(newReason) {
      this.isLoading = true;
      try {
        await this.editReason(newReason);
        this.close();
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style scoped></style>
