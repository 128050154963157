export const PAYROLL_MODEL = "payroll";

const payrollModel = {
  id: "",
  organisationName: "",
  dateCreated: "",
  periodEndDate: "",
  payrollCsv: null,
  organisationUnitId: null
};

export default payrollModel;
