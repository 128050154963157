import { capitalize } from "lodash";

const NOTIFICATION_NAMES = {
  bookings: {
    approve: "New Booking for Approval",
    approved: "Booking Approved",
    accept: "Accept Booking",
    rejected: "Booking Rejected",
    accepted: "Booking Accepted",
    cancelled: "Booking Cancelled",
    published: "New Booking"
  },
  applications: {
    received: "New Application",
    shortlisted: "Application Shortlisted",
    interview: "Interview Requested",
    approved: "Application Approved",
    rejected: "Application Rejected",
    withdrawn: "Application Withdrawn",
    "shift-cancelled": "Shift Cancelled",
    "shift-allocated": "Shift Allocated",
    "shift-confirmed": "Shift Confirmed",
    "shift-declined": "Shift Declined"
  },
  comments: {
    created: "New Comment"
  },
  timesheets: {
    submit: "Timesheet Submitted",
    verify: "Verify Timesheet",
    verified: "Timesheet Verified",
    approve: "Timesheet Approved",
    disputed: "Timesheet Disputed"
  }
};

const getNotificationName = (type, baseName) => {
  return NOTIFICATION_NAMES[type]
    ? NOTIFICATION_NAMES[type][baseName]
    : capitalize(name);
};

export default getNotificationName;
