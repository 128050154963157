import Vue from "vue";
import {
  SET_SUMMARIES,
  SET_IS_FETCHING_DATA,
  SET_IS_FETCHING_INVOICE_SUMMARY,
  SET_EVENTS,
  SET_FILTERS,
  SET_ORGANISATION_SUMMARY,
  SET_INVOICE_SUMMARY
} from "./mutation-types";
import {
  FETCH_SUMMARIES,
  FETCH_EVENTS,
  FETCH_INVOICE_SUMMARY
} from "./action-types";
import api from "@/services/modules/dashboard";
import { isMockedDataEnabled } from "@/utils/mocked-data";
import { MOCKED_DASHBOARD_STATE } from "@/constants/dashboard";
import { findIndex, first, find } from "lodash";

const state = {
  clientsSummaries: [],
  events: [],
  filters: {},
  isFetchingData: false,
  isFetchingInvoiceSummary: false
};

const actions = {
  async [FETCH_SUMMARIES]({ commit, state }) {
    try {
      commit(SET_IS_FETCHING_DATA, true);
      const { data } = await api().getAllSummaries({
        filter: state.filters
      });
      commit(SET_SUMMARIES, data);
      return data;
    } catch (exception) {
      console.error(exception);
    } finally {
      commit(SET_IS_FETCHING_DATA, false);
    }
  },
  async [FETCH_EVENTS]({ commit }, filters) {
    try {
      commit(SET_IS_FETCHING_DATA, true);
      const { data } = await api(commit).getEvents({ filter: filters });
      commit(SET_EVENTS, data);
    } catch (exception) {
      console.error(exception);
    } finally {
      commit(SET_IS_FETCHING_DATA, false);
    }
  },
  async [FETCH_INVOICE_SUMMARY]({ commit, state }) {
    try {
      commit(SET_IS_FETCHING_INVOICE_SUMMARY, true);
      const { data } = await api().getInvoiceSummaries({
        filter: state.filters
      });
      commit(SET_INVOICE_SUMMARY, first(data));
    } catch (exception) {
      console.error(exception);
    } finally {
      commit(SET_IS_FETCHING_INVOICE_SUMMARY, false);
    }
  }
};

const mutations = {
  [SET_IS_FETCHING_DATA](state, isFetching) {
    state.isFetchingData = isFetching;
  },
  [SET_IS_FETCHING_INVOICE_SUMMARY](state, isFetching) {
    state.isFetchingInvoiceSummary = isFetching;
  },
  [SET_SUMMARIES](state, summaries) {
    state.clientsSummaries = summaries;
  },
  [SET_ORGANISATION_SUMMARY](state, summary) {
    const index = findIndex(
      state.clientsSummaries,
      client => client.id === summary.id
    );
    Vue.set(state.clientsSummaries, index, summary);
  },
  [SET_INVOICE_SUMMARY](state, invoiceSummary) {
    const summaryIndex = find(
      state.clientsSummaries[0].summaries,
      item => item.name === "Invoice Value"
    );

    const invoice = invoiceSummary.summaries
      ? first(invoiceSummary.summaries)
      : {};
    if (summaryIndex) {
      Vue.set(state.clientsSummaries[0].summaries, summaryIndex, invoice);
    } else {
      state.clientsSummaries[0].summaries.push(invoice);
    }
  },
  [SET_EVENTS](state, events) {
    state.events = events;
  },
  [SET_FILTERS](state, filters) {
    state.filters = filters;
  }
};

export default {
  namespaced: true,
  state: isMockedDataEnabled() ? MOCKED_DASHBOARD_STATE : state,
  actions: isMockedDataEnabled() ? {} : actions,
  mutations: isMockedDataEnabled() ? {} : mutations
};
