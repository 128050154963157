<template>
  <UserDialog
    v-if="isDialogOpen"
    :is-open="isDialogOpen"
    @close="close"
    title="Edit User"
    actionName="Save Changes"
    @submit="handleEdit"
    :userRoles="userRoles"
    :userData="user"
    :key="id"
    :isLoadingUserRoles="isLoadingUserRoles"
    :loading="isEditingUserDetails"
    isUpdate
  />
</template>

<script>
import UserDialog from "@/views/settings/Organisation/Users/dialogs/UserDialog";
import generateRandomId from "uuid/v4";
import { createNamespacedHelpers } from "vuex";
import { USERS_NAMESPACE } from "@/store/modules/users";
import {
  EDIT_USER_DETAILS,
  FETCH_USER_ROLES
} from "@/store/modules/users/actions";
import {
  GET_IS_EDITING_USER_DETAILS,
  GET_IS_FETCHING_USER_ROLES
} from "@/store/modules/users/getters";
import { AUTH_NAMESPACE } from "@/store/modules/auth";
import { FETCH_USERS_TO_IMPERSONATE } from "@/store/modules/auth/action-types";

const { mapActions, mapGetters } = createNamespacedHelpers(USERS_NAMESPACE);
const { mapActions: mapAuthActions } = createNamespacedHelpers(AUTH_NAMESPACE);

export default {
  name: "EditUserAction",
  components: { UserDialog },
  props: {
    isOpen: Boolean,
    user: Object
  },
  data() {
    return {
      isLoadingUserRoles: false,
      id: null,
      userRoles: [],
      isDialogOpen: false
    };
  },
  computed: {
    ...mapGetters({
      isEditingUserDetails: GET_IS_EDITING_USER_DETAILS,
      isFetchingUserRoles: GET_IS_FETCHING_USER_ROLES
    })
  },
  methods: {
    ...mapActions({
      editUserDetails: EDIT_USER_DETAILS,
      fetchUserRoles: FETCH_USER_ROLES
    }),
    ...mapAuthActions({
      fetchImpersonateUsers: FETCH_USERS_TO_IMPERSONATE
    }),
    close() {
      this.$emit("close");
    },
    async adjustRoles() {
      try {
        this.isLoadingUserRoles = true;
        const { data } = await this.fetchUserRoles(this.user.id);
        this.userRoles = data.roles;
      } finally {
        this.isLoadingUserRoles = false;
      }
    },
    async handleEdit(userData) {
      await this.editUserDetails({ ...userData, id: this.user.id });
      this.fetchImpersonateUsers();
      this.close();
    }
  },
  watch: {
    async isOpen(value) {
      if (value) {
        this.id = generateRandomId();
        await this.adjustRoles();
      }
      this.isDialogOpen = value;
    }
  }
};
</script>

<style scoped></style>
