<template>
  <BaseAction>
    <AllocationsDialog
      :dialogSettings="dialogSettings"
      @close="closeDialog"
      @submit="onDeclineAllocations"
      @onSelectApplicationIds="updateSelectedApplicationIds"
      :shifts="shiftsToDisplay"
      errorButton
      :isLoading="isLoading"
      :isV2="isV2"
    />
    <SecondaryButton @click.native="openDialog" textColor="#d10a3a">
      <v-icon class="mb-1" x-large>mdi-close</v-icon>
      Decline Allocation
    </SecondaryButton>
  </BaseAction>
</template>

<script>
import { UPDATE_APPLICATION_SHIFTS_STATUS } from "@/store/modules/applications/actions";
import { createNamespacedHelpers } from "vuex";
import { APPLICATION_NAMESPACE } from "@/store/modules/applications";
import AllocationsDialog from "@/views/bookings/components/ConfirmDialogs/AllocationsDialog";
import {
  getShiftsWithPermittedApplications,
  getShiftsWithPermittedApplicationsV2,
  getPermittedApplicationShiftsIds,
  getPermittedApplicationShiftsIdsV2
} from "@/utils/shifts";
import { SHIFT_PERMISSIONS_STATUSES, SHIFT_STATUSES } from "@/constants/shifts";
import { isEmpty, size } from "lodash";
import { BOOKINGS_NAMESPACE } from "@/store/modules/bookings/actions";
import SecondaryButton from "@/components/common/Button/SecondaryButton";
import BaseAction from "@/components/common/BaseAction";

const { mapActions } = createNamespacedHelpers(APPLICATION_NAMESPACE);
const { mapState } = createNamespacedHelpers(BOOKINGS_NAMESPACE);

export default {
  name: "DeclineAllocationsAction",
  components: { AllocationsDialog, SecondaryButton, BaseAction },
  props: {
    shifts: Array,
    isV2: Boolean
  },
  data() {
    return {
      isOpen: false,
      isLoading: false,
      selectedApplicationIds: []
    };
  },
  computed: {
    ...mapState({
      currentBooking: state => state.currentBooking
    }),
    dialogSettings() {
      return {
        title: "Decline Allocations",
        message: `Are you sure you want to decline ${
          this.allShifts ? "all" : "the following"
        } shifts allocations?`,
        dialog: this.isOpen
      };
    },
    shiftsToDisplay() {
      return this.isV2
        ? getShiftsWithPermittedApplicationsV2(
            this.shifts,
            SHIFT_PERMISSIONS_STATUSES.DECLINE
          )
        : getShiftsWithPermittedApplications(
            this.shifts,
            SHIFT_PERMISSIONS_STATUSES.DECLINE
          );
    },
    allShifts() {
      return isEmpty(this.shifts);
    }
  },
  methods: {
    ...mapActions({
      updateApplicationShiftsStatus: UPDATE_APPLICATION_SHIFTS_STATUS
    }),
    openDialog() {
      this.isOpen = true;
    },
    closeDialog() {
      this.isOpen = false;
    },
    updateSelectedApplicationIds(selectedApplicationIds) {
      this.selectedApplicationIds = selectedApplicationIds;
    },
    async onDeclineAllocations() {
      this.isLoading = true;
      try {
        const applicationShifts = size(this.selectedApplicationIds)
          ? this.selectedApplicationIds
          : this.isV2
          ? getPermittedApplicationShiftsIdsV2({
              shifts: this.shifts,
              permission: SHIFT_PERMISSIONS_STATUSES.DECLINE
            })
          : getPermittedApplicationShiftsIds({
              shifts: this.shifts,
              permission: SHIFT_PERMISSIONS_STATUSES.DECLINE
            });
        await this.updateApplicationShiftsStatus({
          applicationShifts,
          status: SHIFT_STATUSES.DECLINED,
          all: this.allShifts,
          booking: this.allShifts ? this.currentBooking : null
        });
        this.closeDialog();
        this.$emit("updateList");
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style lang="scss"></style>
