<template>
  <BaseAction>
    <SecondaryButton @click.native="openDialog" color="primary">
      <inline-svg
        :src="require('@/assets/svg/arrow-right-white.svg')"
        width="25"
      />
      Allocate
    </SecondaryButton>
    <AllocateCandidateDialog
      :isOpen="isOpen"
      :workerIds="workerIds"
      :shifts="shifts"
      @allocated="$emit('allocated')"
      @close="closeDialog"
    />
  </BaseAction>
</template>

<script>
import AllocateCandidateDialog from "@/views/bookings/components/ConfirmDialogs/AllocateCandidateDialog";
import BaseAction from "@/components/common/BaseAction";
import SecondaryButton from "@/components/common/Button/SecondaryButton";
import InlineSvg from "vue-inline-svg";

export default {
  name: "AllocateCandidateAction",
  components: {
    BaseAction,
    AllocateCandidateDialog,
    SecondaryButton,
    InlineSvg
  },
  props: {
    shifts: Array,
    applications: Array
  },
  data() {
    return {
      isOpen: false
    };
  },
  computed: {
    workerIds() {
      return this.applications.map(application => ({
        id: application.user.id
      }));
    }
  },
  methods: {
    openDialog() {
      this.isOpen = true;
    },
    closeDialog() {
      this.isOpen = false;
    }
  }
};
</script>
