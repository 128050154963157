import pluralize from "pluralize";
import { ORGANISATION_MODEL } from "@/models/organisation-model";
import { PUBLISHING_SCHEDULE_TEMPLATE_MODEL } from "@/models/publishing-schedule-template-model";

export const SCHEDULE_TIER_MODEL = "publishing-schedule-tier";
export const PUBLISHING_SCHEDULE_TIERS = pluralize(SCHEDULE_TIER_MODEL);

const tierModel = {
  id: "",
  publishing_delay_hours: 0,
  tier_name: "",
  tier_nbr: "",
  publishing_schedule_id: 0,
  organisations: {
    jsonApi: "hasMany",
    type: ORGANISATION_MODEL
  },
  publishingScheduleTemplate: {
    jsonApi: "hasMany",
    type: PUBLISHING_SCHEDULE_TEMPLATE_MODEL
  }
};

export default tierModel;
