<template>
  <div class="compliance__details">
    <div class="compliance__details__label">Check Type</div>
    <div class="compliance__details__value">
      {{ compliance.documentTypeName }}
    </div>

    <div class="compliance__details__label">Certificate Number</div>
    <div class="compliance__details__value">
      <ShowHideText>{{ compliance.documentReferenceNumber }}</ShowHideText>
    </div>

    <div class="compliance__details__label">Registering Body</div>
    <div class="compliance__details__value">
      {{ compliance.registeringBody }}
    </div>

    <div class="compliance__details__label">Issue Date</div>
    <div class="compliance__details__value">
      {{ compliance.documentValidFromDate | baseDate }}
    </div>

    <div class="compliance__details__label">Expiry Date</div>
    <div class="compliance__details__value">
      {{ compliance.documentValidToDate | baseDate }}
    </div>
  </div>
</template>

<script>
import ShowHideText from "@/components/common/ShowHideText";

export default {
  components: {
    ShowHideText
  },
  props: {
    /**
     *  Compliance object
     * @example
     * {
     *  label: String
     *  checked: Boolean
     * }
     */
    compliance: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped></style>
