import Moment from "moment";
import { compareDates } from "@/utils/helpers";
import { first, last, split } from "lodash";
import { groupByPropertyName } from "@/utils/bookings";
import { extendMoment } from "moment-range";
import {
  API_DATE_FORMAT,
  DATE_FORMAT_WITH_SEPARATOR_REVERSED,
  BASE_DATE_FORMAT,
  ENTRY_TIME_FORMAT,
  EMPTY_TIME,
  API_TIME_FORMAT,
  DAY_MILLISECONDS
} from "@/constants/common";
import pluralize from "pluralize";

const moment = extendMoment(Moment);
const HR_SUFFIX = "hr";
const MIN_SUFFIX = "min";
const MINUTES_PER_HOUR = 60;
const SECONDS_PER_MINUTE = 60;
const MILLISECONDS_PER_SECOND = 1000;

export const SHIFT_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";

export const getDurationBetween = (startTime, endTime, format = "HH:mm") => {
  const duration = compareDates(endTime, startTime, format);
  return duration < 0
    ? moment.duration(duration + DAY_MILLISECONDS)
    : moment.duration(duration);
};

export const formatDateForVCalendar = dateInstance => {
  return (
    dateInstance.getFullYear() +
    "-" +
    (dateInstance.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    dateInstance.getDate()
  );
};

export const formatDate = ({
  date,
  startFormat = API_DATE_FORMAT,
  endFormat = DATE_FORMAT_WITH_SEPARATOR_REVERSED
}) => (date ? moment(date, startFormat).format(endFormat) : date);

export const formatTime = (
  timeString,
  { startFormat = API_TIME_FORMAT, endFormat = ENTRY_TIME_FORMAT } = {}
) => moment(timeString || EMPTY_TIME, startFormat).format(endFormat);

export const getSequencesOfDates = dates => {
  if (!dates.length) {
    return [];
  }

  const sortedDates = [...dates].sort((a, b) => a.valueOf() - b.valueOf());
  const dateSequences = [];
  let currentSequence = [first(sortedDates)];

  sortedDates.forEach(date => {
    const lastDate = last(currentSequence);
    if (areContiguousDates(date, lastDate)) {
      currentSequence.push(date);
    } else {
      dateSequences.push({
        start: first(currentSequence),
        end: last(currentSequence)
      });
      currentSequence = [date];
    }
  });

  if (currentSequence.length) {
    dateSequences.push({
      start: first(currentSequence),
      end: last(currentSequence)
    });
  }

  return dateSequences;
};

/*
  Expects dates to be an array of objects {date, fromHour, toHour}
 */
export const hasOverlappingDates = dates => {
  const datesByDay = groupByPropertyName(dates, "date");

  return Object.values(datesByDay).some(group => hasOverlappingHours(group));
};

export const hasOverlappingHours = dates =>
  dates.some(({ fromHour, toHour }, currentIndex) => {
    const currentShift = createRange(fromHour, toHour);
    return dates.some((anotherDate, otherIndex) => {
      const anotherShift = createRange(
        anotherDate.fromHour,
        anotherDate.toHour
      );
      return currentIndex !== otherIndex && currentShift.overlaps(anotherShift);
    });
  });

const createRange = (start, end) => moment().range(time(start), time(end));
const time = hourAndMinutes => moment(hourAndMinutes, "HH:mm");

export const areContiguousDates = (firstDate, secondDate) =>
  Math.abs(moment(firstDate).diff(moment(secondDate), "days")) <= 1;

export const splitHourAndMinutes = time => split(time, ":");

export const convertStringDate = date => {
  return new Date(
    Date.UTC(
      moment(date).year(),
      moment(date).month(),
      moment(date).date(),
      moment(date).hour(),
      moment(date).minute()
    )
  );
};

export const isValidDateString = (date, format = BASE_DATE_FORMAT) =>
  moment(date, format, true).isValid();

export const setHoursAndMinutes = (date, { hours, minutes }) => {
  const dateToChange = moment(date);
  dateToChange.hours(hours);
  dateToChange.minutes(minutes);
  return dateToChange.format(SHIFT_TIME_FORMAT);
};
export const getDayOfWeek = date => moment(date, API_DATE_FORMAT).format("ddd");
export const getDayOfMonth = date => moment(date, API_DATE_FORMAT).format("DD");
export const getMonth = date => moment(date, BASE_DATE_FORMAT).format("MMM");

export const DAY_MONTH_FORMAT = "ddd Do MMM";
export const FULL_DAY_MONTH_FORMAT = "dddd Do MMMM";
export const WEEKDAYS = [
  " Mon",
  " Tue",
  " Wed",
  " Thu",
  " Fri",
  " Sat",
  " Sun"
];

export const formatRangeDate = (
  date,
  format = "YYYY-MM-DD",
  currentFormat = BASE_DATE_FORMAT
) => (date ? moment(date, currentFormat).format(format) : date);

export const isValidDateRange = dateCover => {
  const isStartDateValid =
    dateCover[0] && moment(dateCover[0], "YYYY-MM-DD").isValid();
  const isEndDateValid =
    dateCover[1] && moment(dateCover[1], "YYYY-MM-DD").isValid();
  return isStartDateValid && isEndDateValid;
};

export const formatDateText = date =>
  date ? formatDate({ date, endFormat: BASE_DATE_FORMAT }) : "";

export const formatDateTextReverved = date =>
  date
    ? formatDate({ date, endFormat: DATE_FORMAT_WITH_SEPARATOR_REVERSED })
    : "";

export const splitDateRange = dates => {
  const dateCovered = dates.split("-");
  const startDate = formatRangeDate(dateCovered[0]);
  const endDate = formatRangeDate(dateCovered[1]);
  return { dateCovered, startDate, endDate };
};

export const isDateInTheFuture = dateToCheck => {
  const currentDate = new Date().toISOString();
  return moment(currentDate).isSameOrBefore(moment(dateToCheck), "day");
};

export const formatDateRange = (
  startDate,
  endDate,
  endFormat = BASE_DATE_FORMAT,
  startFormat = DATE_FORMAT_WITH_SEPARATOR_REVERSED
) => {
  const start = formatDate({ date: startDate, startFormat, endFormat });
  const end = formatDate({ date: endDate, startFormat, endFormat });
  if (moment(startDate).isSame(endDate, "month")) {
    const format = endFormat.includes("Do") ? "Do" : "DD";
    return `${moment(startDate, startFormat).format(format)}-${end}`;
  }
  return `${start}-${end}`;
};

export const getReportFormatDate = date => moment(date).format("DD/MM/YYYY");

export const getDateRange = ({ startDate, endDate, format }) => {
  const start = formatDate({
    date: startDate,
    startFormat: API_DATE_FORMAT,
    endFormat: format
  });
  const end =
    endDate === "Present"
      ? endDate
      : formatDate({
          date: endDate,
          startFormat: API_DATE_FORMAT,
          endFormat: format
        });
  const singleDate = formatDate({
    date: startDate,
    startFormat: API_DATE_FORMAT,
    endFormat: "dddd Do MMMM"
  });
  return start === end ? singleDate : `${start} - ${end}`;
};

export const totalDuration = (time, unit = "Day") => {
  return `${time % 1 !== 0 ? time.toFixed(2) : time} ${pluralize(unit, time)}`;
};

export const getMinutesAsHrsAndMinsTime = minutes => {
  const ms = minutes * SECONDS_PER_MINUTE * MILLISECONDS_PER_SECOND;
  const totalHours = Math.floor(moment.duration(ms).asHours());
  const totalMinutes = minutes % MINUTES_PER_HOUR;
  const totalHoursAsHrs = `${totalHours}${pluralize(HR_SUFFIX, totalHours)}`;
  const totalMinutesAsMins = `${totalMinutes}${pluralize(
    MIN_SUFFIX,
    totalMinutes
  )}`;
  return totalHours === 0
    ? totalMinutesAsMins
    : totalMinutes === 0
    ? totalHoursAsHrs
    : `${totalHoursAsHrs} ${totalMinutesAsMins}`;
};

export const getTimeRange = ({ startTime, endTime }) =>
  `${formatTime(startTime)}-${formatTime(endTime)}`;

export const getFormattedDuration = duration => {
  return `
        ${Math.floor(duration.asHours())}h
        ${duration.minutes()}m
      `;
};

export const isDateCorrect = date => {
  return date && Boolean(new Date(date).getTime());
};

export const getTotalMinutes = (startTime, endTime) => {
  const start = moment(startTime, "HH:mm");
  const end = moment(endTime, "HH:mm");
  return end.diff(start, "minutes");
};
