<template>
  <BaseDialog :isOpen="isOpen">
    <template v-slot:title>
      <span>Edit Internal Reference Number</span>
    </template>
    <template v-slot:body>
      <div class="edit-reference-description">
        <v-text-field
          outlined
          hide-details
          :value="application.applicationReference"
          v-model="newReferenceNumber"
        />
      </div>
    </template>
    <template v-slot:actions>
      <div class="edit-reference-actions">
        <ErrorButton @click.native="cancel" text>
          Cancel
        </ErrorButton>
        <PrimaryButton @click.native="saveReferenceNumber" :loading="isLoading">
          Save Changes
        </PrimaryButton>
      </div>
    </template>
  </BaseDialog>
</template>

<script>
import BaseDialog from "@/components/common/BaseDialog";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import ErrorButton from "@/components/common/Button/ErrorButton";
import { UPDATE_APPLICATION } from "@/store/modules/applications/actions";
import { APPLICATION_NAMESPACE } from "@/store/modules/applications";
import { createNamespacedHelpers } from "vuex";

const { mapActions } = createNamespacedHelpers(APPLICATION_NAMESPACE);

export default {
  name: "EditInternalReferenceNumber",
  components: { ErrorButton, PrimaryButton, BaseDialog },
  data() {
    return {
      newReferenceNumber: this.application.applicationReference,
      isLoading: false
    };
  },
  props: {
    isOpen: Boolean,
    application: Object
  },
  computed: {
    referenceNumber() {
      return this.application.applicationReference;
    }
  },
  methods: {
    ...mapActions({
      updateApplication: UPDATE_APPLICATION
    }),
    async saveReferenceNumber() {
      try {
        this.isLoading = true;
        await this.updateApplication({
          id: this.application.id,
          applicationReference: this.newReferenceNumber
        });
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
      this.$emit("close");
    },
    cancel() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss">
.edit-reference-description {
  margin-top: 1rem;
}
.edit-reference-actions {
  padding: 0.5rem;
}
</style>
