<template>
  <DialogFull :data="dialogSettings" @close="close">
    <template v-slot:dialog.body>
      <ShiftStep
        ref="form"
        @change="handleChange"
        :initial-shifts="initialShifts"
        :key="editId"
        :validate="validate"
        :isNewBooking="isNewBooking"
        isEdit
      />
    </template>
    <template v-slot:dialog.action>
      <PrimaryButton @click.native="save" :loading="isLoading">
        Save Changes
      </PrimaryButton>
    </template>
  </DialogFull>
</template>

<script>
import DialogFull from "@/components/common/DialogFull";
import {
  BOOKINGS_NAMESPACE,
  CREATE_SHIFT_PATTERN,
  DELETE_SHIFT_PATTERNS,
  FETCH_CURRENT_BOOKING_SHIFT_PATTERNS,
  DELETE_SHIFT_PATTERN
} from "@/store/modules/bookings/actions";
import { createNamespacedHelpers } from "vuex";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import ShiftStep from "@/views/bookings/NewBooking/steps/ShiftStep";
import generateId from "uuid/v4";
import { FETCH_CURRENT_BOOKING } from "@/store/modules/bookings/actions";

const { mapActions } = createNamespacedHelpers(BOOKINGS_NAMESPACE);

export default {
  name: "EditShifts",
  components: {
    ShiftStep,
    PrimaryButton,
    DialogFull
  },
  computed: {
    dialogSettings() {
      return {
        title: "Edit Shift Schedule",
        dialog: this.isOpen
      };
    },
    currentBookingId() {
      return this.$route.params.id;
    },
    isNewBooking() {
      return this.bookingStatus == "draft";
    }
  },
  data() {
    return {
      isLoading: false,
      editId: generateId(),
      shifts: [],
      validate: false
    };
  },
  props: {
    isOpen: Boolean,
    initialShifts: Array,
    bookingStatus: String,
    isExtend: Boolean
  },
  methods: {
    ...mapActions({
      createShiftPattern: CREATE_SHIFT_PATTERN,
      deleteShiftPatterns: DELETE_SHIFT_PATTERNS, // deletes ALL patterns attached to a booking
      fetchCurrentBooking: FETCH_CURRENT_BOOKING,
      fetchShiftPatterns: FETCH_CURRENT_BOOKING_SHIFT_PATTERNS,
      deleteShiftPattern: DELETE_SHIFT_PATTERN // delete a singe shift pattern
    }),
    handleChange({ shifts }) {
      this.shifts = shifts;
    },
    close() {
      this.editId = generateId();
      this.$emit("close");
    },
    async save() {
      this.validate = true;
      const isValid = await this.$refs.form.$refs.shiftsValidation.validate();
      if (isValid) {
        this.validate = false;
        this.isLoading = true;
        try {
          if (this.shifts.length) {
            // Delete from the database any shift patterns which were loaded as we are going to save them again.
            if (this.initialShifts.length) {
              const deleteRequests = this.initialShifts.map(shift =>
                this.deleteShiftPattern({ id: shift.id })
              );
              await Promise.all(deleteRequests);
            }
            // Add the new/edited shift patterns to the booking
            const createRequests = this.shifts.map(shift => {
              const shiftData = this.isExtend
                ? { ...shift, status: "pending-approval" }
                : shift;
              return this.createShiftPattern({
                bookingId: this.currentBookingId,
                shift: shiftData
              });
            });
            await Promise.all(createRequests);
          }
          this.close();
        } finally {
          this.fetchCurrentBooking(this.currentBookingId);
          this.fetchShiftPatterns(this.currentBookingId);
          this.isLoading = false;
        }
      }
    }
  }
};
</script>

<style lang="scss"></style>
