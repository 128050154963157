<template>
  <BaseDialog :isOpen="isOpen">
    <template v-slot:title>
      Edit PO Number Budget
    </template>
    <template v-slot:body>
      <v-text-field
        v-model="total"
        v-money="moneyConfig"
        prefix="£"
        label="Total Budget"
      />
    </template>
    <template v-slot:actions>
      <PrimaryButton @click.native="close" text :disabled="isLoading">
        Cancel
      </PrimaryButton>
      <PrimaryButton @click.native="onSubmit" :loading="isLoading">
        Save Changes
      </PrimaryButton>
    </template>
  </BaseDialog>
</template>

<script>
import BaseDialog from "@/components/common/BaseDialog";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import {
  CREATE_BUDGET,
  UPDATE_BUDGET,
  FETCH_BUDGETS
} from "@/store/modules/bookings/actions";
import { createNamespacedHelpers } from "vuex";
import { isEmpty } from "lodash";
import {
  getAmountInApiFormat,
  getMonetaryDisplayFormat,
  getMonetaryAsNumber
} from "@/utils/helpers";

const { mapActions } = createNamespacedHelpers("bookings");

export default {
  components: {
    PrimaryButton,
    BaseDialog
  },
  props: {
    isOpen: Boolean,
    booking: Object,
    budget: Object
  },
  data() {
    return {
      showValidationErrors: false,
      isLoading: false,
      total: 0,
      moneyConfig: { precision: 2 }
    };
  },
  created() {
    this.total = this.hasBudget
      ? getMonetaryDisplayFormat(this.budget.total)
      : null;
  },
  computed: {
    poNumber() {
      return this.booking.poNumber;
    },
    rootClientId() {
      return this.booking.rootClient ? this.booking.rootClient.id : null;
    },
    budgetParams() {
      return {
        filter: {
          po_number: this.poNumber,
          organisation_unit_id: this.rootClientId
        }
      };
    },
    hasBudget() {
      return !isEmpty(this.budget);
    }
  },
  methods: {
    ...mapActions({
      create: CREATE_BUDGET,
      update: UPDATE_BUDGET,
      fetchBudgets: FETCH_BUDGETS
    }),
    close() {
      this.$emit("close");
    },
    async onSubmit() {
      try {
        this.isLoading = true;
        this.hasBudget
          ? await this.update({
              id: this.budget.id,
              total: getAmountInApiFormat(getMonetaryAsNumber(this.total)),
              organisation_unit_id: this.rootClientId
            })
          : await this.create({
              total: getAmountInApiFormat(getMonetaryAsNumber(this.total)),
              po_number: this.poNumber,
              organisation_unit_id: this.rootClientId,
              currency_code: this.booking.currencyCode
            });
        this.fetchBudgets(this.budgetParams);
        this.close();
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style lang="scss"></style>
