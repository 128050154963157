import BOOKING_LIST from "./bookings/booking-list";
import BOOKING from "./bookings/booking";
import MOCKED_CANDIDATES from "./bookings/candidates";
import MOCKED_BOOKING_EVENTS from "./bookings/events";
import MOCKED_BOOKING_COMMENTS from "./bookings/comments";
import MOCKED_BOOKING_SHIFTS from "./bookings/shifts";

export const MOCKED_BOOKINGS_STATE = {
  bookings: BOOKING_LIST,
  currentBooking: BOOKING,
  currentBookingCandidates: MOCKED_CANDIDATES,
  currentBookingEvents: MOCKED_BOOKING_EVENTS,
  bookingShifts: MOCKED_BOOKING_SHIFTS,
  bookingComments: MOCKED_BOOKING_COMMENTS,
  bookingShiftPatterns: []
};

export const COMPLIANCES_WITH_DETAILS = {
  DBS: "DBS Check",
  HCPC: "HCPC/CCW/SSSC Check",
  QTS: "QTS/NCTL Qualification Check",
  REFERENCES: "Employment History Check",
  RTW: "Right to Work",
  BL: "Barred List Check",
  FTF: "Face-To-Face Interview",
  NIN: "Proof of National Insurance Number"
};

export const SORTING_DATA = [
  {
    default: true,
    label: "Date",
    value: "createdAt",
    options: [
      {
        value: "-",
        label: "Newest to oldest"
      },
      {
        value: "+",
        label: "Oldest to newest"
      }
    ]
  }
];

export const CONTRACT_TYPE_NAMES = {
  TEMPORARY: "temporary",
  FIXED_TERM: "fixed-term"
};

export const CONTRACT_TYPES = [
  CONTRACT_TYPE_NAMES.TEMPORARY,
  CONTRACT_TYPE_NAMES.FIXED_TERM
];
