export const APPLICATION_NOTIFICATIONS_MODEL = "application-notifications";

const applicationNotificationsModel = {
  received: false,
  rejected: false,
  interview: false,
  withdrawn: false,
  approved: false,
  shortlisted: false,
  "shift-allocated": false,
  "shift-confirmed": false,
  "shift-declined": false,
  "shift-cancelled": false
};

export default applicationNotificationsModel;
