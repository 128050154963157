<template>
  <LabeledText
    :class="{ hover }"
    class="agency-item"
    @mouseover.native="hover = true"
    @mouseleave.native="hover = false"
  >
    <template v-slot:icon>
      <div class="gray-background-icon square-40 agency-label">
        <v-img
          :src="require('@/assets/svg/agency-icon.svg')"
          class="agency-icon my-auto"
          max-width="20"
          max-height="20"
          aspect-ratio="1"
        />
        <div class="agency-item-name">
          <p class="mb-0">{{ agency.name }}</p>
        </div>
      </div>
    </template>
    <template v-slot:action>
      <DeleteAgencyFromTierAction
        v-if="hover || isDeleteDialogOpen"
        :agency="agency"
        :tierId="tierId"
        @open="isDeleteDialogOpen = true"
        @close="isDeleteDialogOpen = false"
      />
    </template>
  </LabeledText>
</template>

<script>
import LabeledText from "@/components/common/BaseDetails/LabeledText";
import DeleteAgencyFromTierAction from "@/views/settings/Organisation/PublishingSchedules/actions/DeleteAgencyFromTierAction";

export default {
  name: "AgencyItem",
  components: { LabeledText, DeleteAgencyFromTierAction },
  props: {
    agency: Object,
    tierId: String
  },
  data() {
    return {
      isDeleteDialogOpen: false,
      hover: false
    };
  }
};
</script>

<style scoped lang="scss">
.agency-item {
  min-height: 40px;
  padding: 0.25rem;

  &-name {
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &.hover {
    background-color: $background;
  }

  .agency-icon {
    width: 20px;
    height: 20px;
  }
}
.agency-label {
  display: flex;
  gap: 10px;
  align-items: center;
}
</style>
