<template>
  <v-container class="expenses">
    <template>
      <div class="text-center mt-10" v-if="isFetchingTimesheetExpenses">
        <v-progress-circular size="50" indeterminate color="primary" />
      </div>
      <div v-else>
        <ExpenseItem
          v-for="expense in expenses"
          :isRemoving="itemsToRemove.includes(expense.id)"
          :key="expense.key"
          :canEditExpenses="canEditExpenses"
          :data="expense"
          @delete="deleteExpense"
        />
        <AddExpenseAction v-if="canEditExpenses" />
      </div>
    </template>
  </v-container>
</template>

<script>
import ExpenseItem from "@/components/timesheets/expenses/ExpenseItem";
import { filter, map } from "lodash";
import { createNamespacedHelpers } from "vuex";
import { TIMESHEETS_NAMESPACE } from "@/store/modules/timesheets";
import {
  REMOVE_EXPENSE,
  FETCH_CURRENT_TIMESHEET_EXPENSES,
  UPDATE_EXPENSES
} from "@/store/modules/timesheets/actions";
import AddExpenseAction from "@/views/timesheets/components/actions/AddExpenseAction";
import generateRandomId from "uuid/v4";

const { mapActions, mapState } = createNamespacedHelpers(TIMESHEETS_NAMESPACE);

const INITIAL_EXPENSE = {
  description: "",
  expenseAmount: "",
  currencyCode: "GBP"
};

export default {
  name: "ExpenseList",
  props: { permissions: Object },
  data() {
    return {
      itemsToRemove: [],
      initialExpense: INITIAL_EXPENSE,
      addedNewExpense: false
    };
  },
  components: {
    ExpenseItem,
    AddExpenseAction
  },
  async created() {
    await this.fetchCurrentTimesheetExpenses(this.currentTimesheetId);
  },
  computed: {
    ...mapState({
      storedExpenses: state => state.timesheetExpenses,
      isFetchingTimesheetExpenses: state => state.isFetchingTimesheetExpenses
    }),
    expenses() {
      return map(this.storedExpenses, expense => {
        return { ...expense, key: generateRandomId() };
      });
    },
    currentTimesheetId() {
      return this.$route.params.id;
    },
    canEditExpenses() {
      return this.permissions["timesheets.edit.expenses"];
    }
  },
  methods: {
    ...mapActions({
      removeExpense: REMOVE_EXPENSE,
      fetchCurrentTimesheetExpenses: FETCH_CURRENT_TIMESHEET_EXPENSES,
      updateExpenses: UPDATE_EXPENSES
    }),
    async deleteExpense(idToRemove) {
      this.itemsToRemove = [...this.itemsToRemove, idToRemove];
      try {
        await this.removeExpense({ id: idToRemove, showSucccessMessage: true });
        this.updateExpenses([
          ...this.expenses.filter(({ id }) => id !== idToRemove)
        ]);
        this.itemsToRemove = filter(this.itemsToRemove, idToRemove);
      } catch {
        this.itemsToRemove = filter(this.itemsToRemove, idToRemove);
      }
    },
    onNewAddExpense() {
      this.newExpenseItemUpdate(true);
    },
    handleRemoveExpense() {
      this.newExpenseItemUpdate(false);
    },
    newExpenseItemUpdate(isAdded) {
      this.addedNewExpense = isAdded;
      this.$emit("onUpdate", isAdded);
    }
  }
};
</script>

<style lang="scss">
.expenses {
  margin-bottom: 50px;

  @media only screen and (max-width: $mobile-breakpoint - 1) {
    margin-bottom: 0;
  }
}
</style>
