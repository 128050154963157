<template>
  <ValidationObserver ref="userForm">
    <DialogFull
      :data="dialogSettings"
      @close="close"
      noOverflow
      v-if="dialogSettings.dialog"
    >
      <template v-slot:dialog.body>
        <div class="container flex-gap-20 mt-3">
          <ValidationProvider
            v-slot="{ errors }"
            name="firstName"
            rules="required"
          >
            <v-text-field
              :disabled="loading"
              :error-messages="showValidationErrors ? errors : ''"
              class=""
              label="First Name"
              v-model="firstName"
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="lastName"
            rules="required"
          >
            <v-text-field
              :disabled="loading"
              :error-messages="showValidationErrors ? errors : ''"
              label="Last Name"
              class=""
              v-model="lastName"
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="email"
            rules="required|email"
          >
            <v-text-field
              @blur="checkEmail"
              :disabled="loading || isUpdate"
              :error-messages="showValidationErrors ? errors : ''"
              class=""
              label="Email Address"
              v-model="email"
            />
          </ValidationProvider>
          <div v-if="status">
            <v-label>Status</v-label>
            <Select
              v-model="status"
              label=""
              :disabled="loading"
              autocomplete
              :items="statusItems"
              height="50"
            />
          </div>
          <Permissioned section="settings" class="d-flex flex-column">
            <div permission="new-user.primary-organisation">
              <ValidationProvider
                v-slot="{ errors }"
                name="selectedOrganisation"
                rules="required"
              >
                <Select
                  :errorMessages="errors"
                  label="Primary Organisation"
                  autocomplete
                  v-model="selectedOrganisation"
                  :items="organisations"
                  item-text="name"
                  item-value="id"
                  ref="autocomplete"
                  chips
                  small-chips
                />
              </ValidationProvider>
            </div>
          </Permissioned>
          <div>
            <div class="font-weight-bold mb-3">Roles</div>
            <div v-if="isLoadingUserRoles">
              <v-skeleton-loader type="list-item" v-for="i in 4" :key="i" />
            </div>
            <Permissioned
              v-else
              section="settings.grant-user-role"
              class="d-flex flex-column"
            >
              <div
                v-if="showValidationErrors && !selectedRoles.length"
                class="role-error-info"
              >
                At least one Role must be selected
              </div>
              <RoleField
                @addRole="addRole"
                @updateRole="updateRole"
                @removeRole="removeRole"
                v-for="(role, index) in roles"
                :role="role"
                :permission="role.permission"
                :key="index"
                :organisations="organisations"
                :rootOrganisationId="rootOrganisationId"
                :isLoading="isLoadingOrganisations"
                :disabled="loading"
                :isSelected="checkIfRoleIsAlreadySelected(role.value)"
                :initialSelectedOrganisations="
                  getInitialSelectedOrganisations(role.value)
                "
              />
            </Permissioned>
          </div>
        </div>
      </template>
      <template v-slot:dialog.action>
        <PrimaryButton
          @click.native="onSubmit"
          :loading="loading"
          :disabled="isLoadingUserRoles"
        >
          {{ actionName }}
        </PrimaryButton>
      </template>
    </DialogFull>
  </ValidationObserver>
</template>

<script>
import DialogFull from "@/components/common/DialogFull";
import RoleField from "@/views/settings/Organisation/Users/components/RoleField";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { ROLES } from "@/views/settings/Organisation/Users/constants/roles";
import { createNamespacedHelpers } from "vuex";
import { filter, findIndex, find, toString, map } from "lodash";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import Permissioned from "@/components/common/Permissioned";
import { FETCH_ORGANISATIONS } from "@/store/modules/organisations/actions";
import Select from "@/components/common/Select";
import { ORGANISATIONS_NAMESPACE } from "@/store/modules/organisations";
import {
  GET_IS_FETCHING_ORGANISATIONS,
  GET_ORGANISATIONS
} from "@/store/modules/organisations/getters";
import { AUTH_NAMESPACE } from "@/store/modules/auth";
import { emailExistingError } from "@/utils/users";
import { USERS_NAMESPACE } from "@/store/modules/users";
import { CHECK_EXISTING_EMAIL } from "@/store/modules/users/actions";

const {
  mapActions: mapOrganisationActions,
  mapGetters: mapOrganisationGetters
} = createNamespacedHelpers(ORGANISATIONS_NAMESPACE);

const { mapState: mapAuthState } = createNamespacedHelpers(AUTH_NAMESPACE);
const { mapActions: userMapActions } = createNamespacedHelpers(USERS_NAMESPACE);

export default {
  name: "UserDialog",
  components: {
    PrimaryButton,
    DialogFull,
    RoleField,
    Permissioned,
    ValidationObserver,
    ValidationProvider,
    Select
  },
  props: {
    isOpen: Boolean,
    loading: Boolean,
    userData: Object,
    title: String,
    actionName: String,
    userRoles: Array,
    isLoadingUserRoles: Boolean,
    isUpdate: Boolean
  },
  created() {
    this.fetchOrganisations();
    this.adjustFormFields();
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      roles: ROLES,
      status: "",
      selectedRoles: [],
      showValidationErrors: false,
      statusItems: ["active", "inactive"],
      selectedOrganisation: ""
    };
  },
  computed: {
    ...mapAuthState({
      rootUserData: state => state.userData
    }),
    rootOrganisationId() {
      return this.rootUserData.organisation.id;
    },
    initialSelectedRoles() {
      return this.userRoles || [];
    },
    dialogSettings() {
      return {
        dialog: this.isOpen,
        title: this.title
      };
    },
    ...mapOrganisationGetters({
      organisations: GET_ORGANISATIONS,
      isLoadingOrganisations: GET_IS_FETCHING_ORGANISATIONS
    })
  },
  methods: {
    ...mapOrganisationActions({
      fetchOrganisations: FETCH_ORGANISATIONS
    }),
    ...userMapActions({ checkExistingEmail: CHECK_EXISTING_EMAIL }),
    adjustFormFields() {
      if (this.userData) {
        const {
          firstName,
          lastName,
          email,
          status,
          organisation
        } = this.userData;
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.status = status;
        this.selectedOrganisation = organisation.id;
        this.selectedRoles = this.initialSelectedRoles;
      }
    },
    close() {
      this.$emit("close");
    },
    addRole(role) {
      this.selectedRoles = [...this.selectedRoles, role];
    },
    updateRole(newRoleData) {
      this.selectedRoles[
        findIndex(this.selectedRoles, { name: newRoleData.name })
      ] = newRoleData;
    },
    removeRole(nameToRemove) {
      this.selectedRoles = filter(
        this.selectedRoles,
        role => role.name !== nameToRemove
      );
    },
    async onSubmit() {
      this.showValidationErrors = true;
      const isValid = await this.$refs.userForm.validate();
      const newData = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        roles: this.selectedRoles,
        ...(this.selectedOrganisation && {
          organisation: { id: this.selectedOrganisation }
        }),
        ...(this.status && { status: this.status })
      };
      if (isValid && this.selectedRoles.length) {
        this.$emit("submit", newData);
      }
    },
    getRoleFromInitialSelectedRoles(name) {
      return find(this.initialSelectedRoles, { name });
    },
    checkIfRoleIsAlreadySelected(name) {
      return Boolean(this.getRoleFromInitialSelectedRoles(name));
    },
    getInitialSelectedOrganisations(name) {
      const selectedRole = this.getRoleFromInitialSelectedRoles(name);
      return selectedRole
        ? map(selectedRole.organisations, val => toString(val))
        : [];
    },
    async checkEmail() {
      const { user, isExistingEmail } = await this.checkExistingEmail(
        this.email
      );
      const id = this.userData ? this.userData.id : null;
      if (isExistingEmail && user.id !== id) {
        emailExistingError(this.email);
      }
    }
  }
};
</script>

<style lang="scss">
.role-error-info {
  display: flex;
  border-radius: 4px;
  align-items: center;
  padding: 10px 8px;
  font-weight: bold;
  font-size: 18px;
  background-color: $error;
  color: $white;
}
</style>
