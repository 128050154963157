<template>
  <v-footer class="footer">
    <slot />
  </v-footer>
</template>

<script>
export default {
  name: "BaseFooter"
};
</script>

<style scoped lang="scss">
.footer {
  max-height: 91px;
  display: flex;
  justify-content: flex-end;
  padding: 15px;
  position: sticky;
  bottom: 0;
  width: 100%;
  z-index: 6;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8.15485px);

  @media only screen and (max-width: $mobile-breakpoint - 1) {
    overflow-y: scroll;
    align-items: baseline;
  }
}
</style>
