import api from "@/services/modules/agencies";
import {
  SET_AGENCIES,
  SET_ERROR,
  SET_IS_FETCHING_DATA,
  SET_CURRENT_AGENCY,
  SET_AGENCY_STATUS_FILTER
} from "./mutation-types";
import getters, { GET_ALL_AGENCIES } from "./getters";
import {
  DEACTIVATE_AGENCY,
  FETCH_ALL_AGENCIES,
  PUBLISH_AGENCY,
  UPDATE_AGENCY,
  ADD_AGENCY,
  EDIT_AGENCY,
  FETCH_AGENCY
} from "./action-types";
import {
  errorHandler,
  addGlobalMessage,
  handleWithGlobalMessage,
  handleWithErrorMessage
} from "@/services/utils";
import { setStoreValue } from "@/store/utils";
import { isMockedDataEnabled } from "@/utils/mocked-data";
import { MOCKED_AGENCIES_STATE } from "@/constants/agencies";
import { findIndex } from "lodash";
import { sortBy } from "lodash";

const state = {
  agencies: [],
  isFetchingData: false,
  fetchingError: null,
  currentAgency: {},
  agencyStatusFilter: ""
};

const actions = {
  async [FETCH_ALL_AGENCIES]({ commit, dispatch }, params) {
    try {
      commit(SET_IS_FETCHING_DATA, true);
      const { data } = await api({ commit }).getAllAgencies(params);
      commit(SET_AGENCIES, sortBy(data, ["name"]));
    } catch (exception) {
      errorHandler(dispatch, exception);
    } finally {
      commit(SET_IS_FETCHING_DATA, false);
    }
  },
  async [UPDATE_AGENCY]({ commit, dispatch }, updatedAgency) {
    try {
      const { meta } = await api({ commit }).updateOrganisation(updatedAgency);
      addGlobalMessage(dispatch, { type: "success", meta });
    } catch (exception) {
      errorHandler(dispatch, exception);
    }
  },
  async [PUBLISH_AGENCY]({ commit, dispatch }, { id }) {
    try {
      const { meta } = await api({ commit }).publishOrganisation(id);
      addGlobalMessage(dispatch, { type: "success", meta });
    } catch (exception) {
      errorHandler(dispatch, exception);
    }
  },
  async [DEACTIVATE_AGENCY]({ commit, dispatch }, { id }) {
    try {
      const { meta } = await api({ commit }).archiveOrganisation(id);
      addGlobalMessage(dispatch, { type: "success", meta });
    } catch (exception) {
      errorHandler(dispatch, exception);
    }
  },
  async [ADD_AGENCY]({ commit, dispatch }, data) {
    await handleWithGlobalMessage(
      () => api({ commit }).addAgency(data),
      dispatch
    );
    dispatch(FETCH_ALL_AGENCIES);
  },
  async [EDIT_AGENCY]({ commit, dispatch, getters }, { data, id }) {
    const currentAgencies = [...getters[GET_ALL_AGENCIES]];

    const { data: editedAgency } = await handleWithGlobalMessage(
      () => api({ commit }).editAgency({ data, id }),
      dispatch
    );
    const editedAgencyIndex = findIndex(currentAgencies, { id });
    currentAgencies[editedAgencyIndex] = {
      ...currentAgencies[editedAgencyIndex],
      ...editedAgency
    };
    commit(SET_CURRENT_AGENCY, editedAgency);
    commit(SET_AGENCIES, currentAgencies);
  },
  async [FETCH_AGENCY]({ commit, dispatch }, id) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api({ commit }).getAgency(id),
      setLoadingFunction: isLoading => commit(SET_IS_FETCHING_DATA, isLoading),
      setDataFunction: ({ data }) => {
        commit(SET_CURRENT_AGENCY, data);
      }
    });
  }
};

const mutations = {
  [SET_AGENCIES]: setStoreValue("agencies"),
  [SET_IS_FETCHING_DATA]: setStoreValue("isFetchingData"),
  [SET_ERROR]: setStoreValue("fetchingError"),
  [SET_CURRENT_AGENCY]: setStoreValue("currentAgency"),
  [SET_AGENCY_STATUS_FILTER]: setStoreValue("agencyStatusFilter")
};

export default {
  namespaced: true,
  state: isMockedDataEnabled() ? MOCKED_AGENCIES_STATE : state,
  actions: isMockedDataEnabled() ? {} : actions,
  mutations: isMockedDataEnabled() ? {} : mutations,
  getters
};
