import {
  FETCH_REPORTS,
  GET_REPORT,
  GET_REPORT_DATA,
  FETCH_REPORT_CHARTS,
  CREATE_REPORT_CHART,
  EDIT_REPORT_CHART,
  CREATE_REPORT,
  UPDATE_REPORT,
  DELETE_REPORT,
  FETCH_REPORTS_SHARED_WITH_USERS,
  CREATE_REPORTS_SHARED_WITH_USER,
  EDIT_REPORTS_SHARED_WITH_USER,
  DELETE_REPORTS_SHARED_WITH_USER,
  UPDATE_SINGLE_REPORT
} from "@/store/modules/reports/action-types";

import {
  SET_IS_FETCHING_REPORTS,
  SET_REPORTS,
  ADD_REPORT,
  ADD_REPORT_DATA,
  SET_REPORT_CHARTS,
  SET_IS_FETCHING_REPORT_CHARTS,
  SET_IS_CREATING_REPORT_CHART,
  SET_IS_EDITING_REPORT_CHART,
  SET_CURRENT_REPORT_CHART,
  SET_REPORTS_SHARED_WITH_USERS,
  SET_IS_FETCHING_SET_REPORTS_SHARED_WITH_USERS
} from "@/store/modules/reports/mutation-types";
import { handleWithErrorMessage, errorHandler } from "@/services/utils";
import api from "@/services/modules/reports";
import {
  appendStoreArrayValue,
  setStoreValue,
  setStoreValueById
} from "@/store/utils";
import { map } from "lodash";

const state = {
  reports: [],
  reportsData: {},
  isFetchingReports: false,
  isFetchingReportCharts: false,
  reportCharts: [],
  isCreatingReportChart: false,
  currentReportChart: null,
  isEditingReportChart: false,
  reportsSharedWithUsers: [],
  isFetchingReportsSharedWithUsers: false
};

const actions = {
  async [FETCH_REPORTS]({ commit, dispatch }) {
    return handleWithErrorMessage({
      dispatch,
      request: api({ commit }).getReports,
      setLoadingFunction: isLoading =>
        commit(SET_IS_FETCHING_REPORTS, isLoading),
      setDataFunction: ({ data }) => commit(SET_REPORTS, data)
    });
  },
  async [GET_REPORT]({ commit, dispatch }, { id, params }) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api({ commit }).getReport(id, params),
      setLoadingFunction: isLoading =>
        commit(SET_IS_FETCHING_REPORTS, isLoading),
      setDataFunction: ({ data }) => {
        if (data.charts) {
          commit(SET_REPORT_CHARTS, data.charts);
        }
        commit(ADD_REPORT, data);
      }
    });
  },
  async [FETCH_REPORT_CHARTS]({ commit, dispatch }, params) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api({ commit }).getReportCharts(params),
      setLoadingFunction: isLoading =>
        commit(SET_IS_FETCHING_REPORT_CHARTS, isLoading),
      setDataFunction: ({ data }) => commit(SET_REPORT_CHARTS, data)
    });
  },
  async [CREATE_REPORT_CHART]({ commit, dispatch, state }, data) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api({ commit }).createReportChart(data),
      setLoadingFunction: isLoading =>
        commit(SET_IS_CREATING_REPORT_CHART, isLoading),
      setDataFunction: ({ data }) => {
        commit(SET_CURRENT_REPORT_CHART, data);
        commit(SET_REPORT_CHARTS, [...state.reportCharts, data]);
      }
    });
  },
  async [EDIT_REPORT_CHART]({ commit, dispatch, state }, { reportId, data }) {
    const { data: updatedReportChart } = await handleWithErrorMessage({
      dispatch,
      request: () => api({ commit }).editReportChart(reportId, data),
      setLoadingFunction: isLoading =>
        commit(SET_IS_EDITING_REPORT_CHART, isLoading)
    });
    const updatedCharts = map(state.reportCharts, chart => {
      if (chart.id !== updatedReportChart.id) return chart;
      return updatedReportChart;
    });
    commit(SET_CURRENT_REPORT_CHART, updatedReportChart);
    commit(SET_REPORT_CHARTS, updatedCharts);
    return updatedReportChart;
  },
  async [FETCH_REPORTS_SHARED_WITH_USERS]({ commit, dispatch }, filter) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api({ commit }).getReportsSharedWithUsers(filter),
      setLoadingFunction: isLoading =>
        commit(SET_IS_FETCHING_SET_REPORTS_SHARED_WITH_USERS, isLoading),
      setDataFunction: ({ data }) => commit(SET_REPORTS_SHARED_WITH_USERS, data)
    });
  },
  async [CREATE_REPORTS_SHARED_WITH_USER]({ commit, dispatch }, data) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api({ commit }).createReportsSharedWithUsers(data)
    });
  },
  async [EDIT_REPORTS_SHARED_WITH_USER]({ commit, dispatch }, data) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api({ commit }).editReportsSharedWithUsers(data)
    });
  },
  async [DELETE_REPORTS_SHARED_WITH_USER]({ commit, dispatch }, id) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api({ commit }).deleteReportsSharedWithUsers(id)
    });
  },
  async [GET_REPORT_DATA]({ commit, dispatch }, id) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api({ commit }).getReportData(id),
      setLoadingFunction: isLoading =>
        commit(SET_IS_FETCHING_REPORTS, isLoading),
      setDataFunction: data => commit(ADD_REPORT_DATA, { id, data })
    });
  },
  async [CREATE_REPORT]({ commit, dispatch }, type) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api({ commit }).createReport(type),
      setLoadingFunction: isLoading =>
        commit(SET_IS_FETCHING_REPORTS, isLoading),
      setDataFunction: ({ data }) => {
        commit(ADD_REPORT, data);
      }
    });
  },
  async [DELETE_REPORT]({ commit, dispatch }, id) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api({ commit }).deleteReport(id),
      setLoadingFunction: isLoading =>
        commit(SET_IS_FETCHING_REPORTS, isLoading)
    });
  },
  async [UPDATE_REPORT](
    { commit, dispatch },
    { previousReport, newReportData }
  ) {
    return handleWithErrorMessage({
      dispatch,
      request: async () =>
        await api({ commit }).updateReport(previousReport, newReportData),
      setLoadingFunction: isLoading =>
        commit(SET_IS_FETCHING_REPORTS, isLoading),
      setDataFunction: response => {
        if (
          response &&
          response[0] &&
          response[0].data &&
          response[0].data.charts
        ) {
          commit(SET_REPORT_CHARTS, response[0].data.charts);
        }
      }
    });
  },
  async [UPDATE_SINGLE_REPORT]({ commit, dispatch }, data) {
    try {
      await api({ commit }).updateSingleReport(data);
      dispatch(FETCH_REPORTS);
    } catch (exception) {
      errorHandler(dispatch, exception);
    }
  }
};

const mutations = {
  [SET_REPORTS]: setStoreValue("reports"),
  [ADD_REPORT]: appendStoreArrayValue("reports"),
  [ADD_REPORT_DATA]: setStoreValueById("reportsData", {
    getData: value => value.data
  }),
  [SET_IS_FETCHING_REPORTS]: setStoreValue("isFetchingReports"),
  [SET_IS_FETCHING_REPORT_CHARTS]: setStoreValue("isFetchingReportCharts"),
  [SET_REPORT_CHARTS]: setStoreValue("reportCharts"),
  [SET_IS_CREATING_REPORT_CHART]: setStoreValue("isCreatingReportChart"),
  [SET_IS_EDITING_REPORT_CHART]: setStoreValue("isEditingReportChart"),
  [SET_CURRENT_REPORT_CHART]: setStoreValue("currentReportChart"),
  [SET_REPORTS_SHARED_WITH_USERS]: setStoreValue("reportsSharedWithUsers"),
  [SET_IS_FETCHING_SET_REPORTS_SHARED_WITH_USERS]: setStoreValue(
    "isFetchingReportsSharedWithUsers"
  )
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
