<template>
  <BaseAction>
    <SecondaryButton @click.native="openDialog" textColor="#d10a3a">
      <div>
        <inline-svg
          :src="require('@/assets/svg/arrow-left-red.svg')"
          width="25"
        />
        <inline-svg :src="require('@/assets/svg/user-red.svg')" width="25" />
      </div>
      Withdraw Candidate
    </SecondaryButton>
    <WithdrawCandidateDialog
      :isOpen="isOpen"
      @close="closeDialog"
      :applicationIds="applicationIds"
    />
  </BaseAction>
</template>

<script>
import WithdrawCandidateDialog from "@/views/bookings/components/ConfirmDialogs/WithdrawCandidateDialog";
import BaseAction from "@/components/common/BaseAction";
import SecondaryButton from "@/components/common/Button/SecondaryButton";
import InlineSvg from "vue-inline-svg";

export default {
  name: "WithdrawCandidateAction",
  components: {
    BaseAction,
    WithdrawCandidateDialog,
    SecondaryButton,
    InlineSvg
  },
  props: {
    applicationIds: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isOpen: false
    };
  },
  methods: {
    openDialog() {
      this.isOpen = true;
    },
    closeDialog({ success }) {
      this.isOpen = false;
      if (success) {
        this.$emit("statusChanged");
      }
    }
  }
};
</script>
