var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DialogFull',{attrs:{"data":_vm.dialogSettings},on:{"close":_vm.close},scopedSlots:_vm._u([{key:"dialog.body",fn:function(){return [_c('div',{staticClass:"edit-basic-details-content"},[(_vm.isEditingBookingPayRates)?_c('div',{staticClass:"text-center mb-2"},[_c('v-progress-circular',{attrs:{"size":"30","indeterminate":"","color":"primary"}})],1):_c('div',{staticClass:"col-md-6 col-sm-12"},[_c('v-tabs',{model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},_vm._l((_vm.activitiesNames),function(activityName){return _c('v-tab',{key:activityName},[_vm._v(" "+_vm._s(activityName)+" ")])}),1),_c('v-tabs-items',{model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},_vm._l((_vm.activitiesNames),function(activityName){return _c('v-tab-item',{key:activityName},[_c('v-form',{ref:"form",refInFor:true,attrs:{"lazy-validation":""}},_vm._l((_vm.sortBy(
                  Object.keys(_vm.allActivities[activityName])
                ).reverse()),function(awrType,index){return _c('div',{key:index},[_c('div',{staticClass:"cost-item pa-0"},[_c('div',{staticClass:"hourly-rate"},[_c('div',{staticClass:"rate-name"},[_vm._v(" "+_vm._s(_vm.getPayRateName( _vm.allActivities[activityName][awrType].awr ))+" ")]),_c('div',{staticClass:"hourly-rate-input"},[_c('v-text-field',{attrs:{"rules":_vm.validationRules,"prepend-inner-icon":"mdi-currency-gbp","dense":"","type":"number","value":_vm.payRateDisplayAmount(
                            _vm.allActivities[activityName][awrType]
                          )},on:{"input":val =>
                            _vm.editPayRate(
                              val,
                              _vm.allActivities[activityName][awrType]
                            )}})],1)])])])}),0)],1)}),1)],1)])]},proxy:true},{key:"dialog.action",fn:function(){return [_c('v-spacer'),_c('PrimaryButton',{staticClass:"edit-basic-details-action",attrs:{"loading":_vm.isEditingBookingPayRates},nativeOn:{"click":function($event){return _vm.save.apply(null, arguments)}}},[_vm._v(" Save Changes ")])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }