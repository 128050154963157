<template>
  <div class="timesheet-details" v-if="currentTimesheet && !isLoadingTimesheet">
    <div class="wrapper">
      <div class="table-wrapper pr-0">
        <AppLayout
          :parent="parentLink"
          isDetails
          class="timesheet--details container col-lg-8 col-md-9 col-sm-12"
        >
          <template #backButtonText>
            <span v-text="backButtonText"></span>
          </template>
          <template v-slot:header>
            <TimesheetDetailsHeader :timesheet="currentTimesheet" />
          </template>
          <template v-slot:body>
            <Permissioned
              section="timesheets"
              :id="currentTimesheetId"
              class="timesheet-comments-wrapper"
            >
              <Comment
                :isLoading="isLoadingComments"
                class="timesheet-comments"
                permission="view.comments"
                :comments="comments"
                @comment="addNewComment"
                :readOnly="isApproved"
              />
            </Permissioned>
            <div class="d-flex flex-column">
              <h2 class="pb-2 mt-4">Submitted</h2>
              {{ currentTimesheet.submitDate | longDatewithTime }}
              <h2 class="pb-2 mt-4">Approver</h2>
              <TimesheetPeople :data="approverDetails" />
              <h2 class="pb-2 mt-4">Verifier</h2>
              <TimesheetPeople :data="verifierDetails" />
            </div>
            <div class="timesheet-time-header mt-4 mb-5">
              <h2>Time</h2>
              <Permissioned
                v-if="!isWorker"
                section="timesheets"
                :id="currentTimesheetId"
              >
                <PrimaryButton
                  permission="edit"
                  @click.native="handleEditTimesheet"
                  light
                  dense
                  shrink
                >
                  Edit
                  <v-icon class="ml-1">mdi-pencil</v-icon>
                </PrimaryButton>
              </Permissioned>
            </div>
            <Permissioned
              class="d-flex"
              section="timesheets"
              :id="currentTimesheetId"
            >
              <TimesheetDetailsTime
                :permissions="permissions"
                :times="currentTimesheet.entries"
                permission="view.time-entries"
                :showDetails="true"
                @changeDetailsVisibility="true"
              />
            </Permissioned>
            <Permissioned
              class="d-flex"
              section="timesheets"
              :id="currentTimesheetId"
            >
              <TimesheetDetailsExpenses
                :showDetails="true"
                :permissions="permissions"
                :expenses="formattedTimesheet.expenses"
                permission="view.expenses"
              />
            </Permissioned>
          </template>
        </AppLayout>
      </div>
    </div>
    <BaseFooter class="mb-6">
      <ExpandedButtons>
        <Permissioned
          class="d-flex actions-bar"
          section="timesheets"
          :id="currentTimesheetId"
          :class="{ vertical: isMobile }"
        >
          <RecallTimesheetAction permission="recall" :id="currentTimesheetId" />
          <ApproveTimesheetAction
            permission="approve"
            :timesheetsIds="[currentTimesheetId]"
            @onUpdate="onStatusUpdate"
          />
          <VerifyTimesheetAction
            permission="verify"
            :timesheetsIds="[currentTimesheetId]"
            @onUpdate="onStatusUpdate"
          />
          <DisputeTimesheetAction
            permission="dispute"
            :timesheetsIds="[currentTimesheetId]"
            @onUpdate="onStatusUpdate"
          />
          <CreditTimesheetAction
            @onUpdate="onStatusUpdate"
            permission="credit"
            :id="currentTimesheetId"
          />
        </Permissioned>
      </ExpandedButtons>
    </BaseFooter>
  </div>
  <v-overlay v-else z-index="9">
    <v-progress-circular indeterminate size="64" />
  </v-overlay>
</template>

<script>
import AppLayout from "@/components/common/base-layouts/AppLayout";
import { sortTableItems } from "@/utils/helpers";
import Comment from "@/components/common/Comment";
import Permissioned from "@/components/common/Permissioned";
import ApproveTimesheetAction from "@/views/timesheets/components/actions/ApproveTimesheetAction";
import DisputeTimesheetAction from "@/views/timesheets/components/actions/DisputeTimesheetAction";
import CreditTimesheetAction from "@/views/timesheets/components/actions/CreditTimesheetAction";
import VerifyTimesheetAction from "@/views/timesheets/components/actions/VerifyTimesheetAction";
import RecallTimesheetAction from "@/views/timesheets/components/actions/RecallTimesheetAction";
import { TIMESHEETS_NAMESPACE } from "@/store/modules/timesheets";
import { createNamespacedHelpers } from "vuex";
import {
  GET_CURRENT_TIMESHEET,
  ADD_COMMENT,
  FETCH_CURRENT_TIMESHEET_COMMENTS
} from "@/store/modules/timesheets/actions";
import { SET_CURRENT_TIMESHEET } from "@/store/modules/timesheets/mutations";
import TimesheetPeople from "@/views/timesheets/components/TimesheetPeople";
import TimesheetDetailsHeader from "@/views/timesheets/details/components/TimesheetDetailsHeader";
import TimesheetDetailsTime from "@/views/timesheets/details/components/TimesheetDetailsTime";
import TimesheetDetailsExpenses from "@/views/timesheets/details/components/TimesheetDetailsExpenses";
import { TIMESHEET_MODEL } from "@/models/timesheet-model";
import { TIMESHEET_STATUSES } from "@/utils/timesheets";
import { AUTH_NAMESPACE } from "@/store/modules/auth";
import { size } from "lodash";
import { isPermissioned } from "@/utils/permissions";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import { isWorker } from "@/utils/users";
import BaseFooter from "@/components/common/BaseFooter";
import ExpandedButtons from "@/components/common/ExpandedButtons";

const { VERIFIED, UNVERIFIED } = TIMESHEET_STATUSES;

const { mapActions, mapState, mapMutations } = createNamespacedHelpers(
  TIMESHEETS_NAMESPACE
);

const { mapState: authMapState } = createNamespacedHelpers(AUTH_NAMESPACE);

export default {
  name: "Timesheets",
  components: {
    TimesheetDetailsTime,
    TimesheetDetailsExpenses,
    TimesheetPeople,
    VerifyTimesheetAction,
    DisputeTimesheetAction,
    ApproveTimesheetAction,
    CreditTimesheetAction,
    Permissioned,
    AppLayout,
    Comment,
    RecallTimesheetAction,
    TimesheetDetailsHeader,
    PrimaryButton,
    BaseFooter,
    ExpandedButtons
  },
  props: {
    customButtons: Boolean
  },
  data() {
    return {
      newComment: null,
      selectedTab: null,
      backButtonText: "Timesheets"
    };
  },
  created() {
    this.getCurrentTimesheet(this.currentTimesheetId);
    this.fetchCurrentTimesheetComments(this.currentTimesheetId);
    if (this.$route.params.workerId) {
      this.backButtonText = "Worker's activity";
    }
  },
  methods: {
    ...mapActions({
      getCurrentTimesheet: GET_CURRENT_TIMESHEET,
      fetchCurrentTimesheetComments: FETCH_CURRENT_TIMESHEET_COMMENTS,
      addComment: ADD_COMMENT
    }),
    ...mapMutations({
      setCurrentTimesheet: SET_CURRENT_TIMESHEET
    }),
    async addNewComment(comment) {
      await this.addComment({
        timesheetId: this.currentTimesheetId,
        comment: {
          body: comment,
          commentableType: TIMESHEET_MODEL,
          commentableId: this.currentTimesheetId
        }
      });
      await this.fetchCurrentTimesheetComments(this.currentTimesheetId);
    },
    customSort(items, arr, isDescArray) {
      const options = {
        monetaryValuesLabels: ["cost"],
        dateValuesLabels: ["date"]
      };
      return sortTableItems(items, arr, isDescArray, options);
    },
    onStatusUpdate() {
      this.getCurrentTimesheet(this.currentTimesheetId);
    },
    showDivider(index) {
      return index !== this.lastItemIndex;
    },
    viewBookingDetails(id) {
      this.$router.push({
        name: "bookingDetails",
        params: { id }
      });
    },
    handleEditTimesheet() {
      this.$router.push({
        name: "submitTimesheet",
        params: { id: this.currentTimesheetId }
      });
    }
  },
  computed: {
    ...authMapState(["permissions", "userData"]),
    ...mapState({
      currentTimesheet: state => state.currentTimesheet,
      isLoadingTimesheet: state => state.isFetchingCurrentTimesheet,
      comments: state => state.timesheetComments,
      isFetchingTimesheetComments: state => state.isFetchingTimesheetComments
    }),
    detailsTabs() {
      return [
        "Time",
        ...(this.showExpensesTab &&
        this.permissions[`timesheets[${this.currentTimesheetId}].view.expenses`]
          ? ["Expenses"]
          : [])
      ];
    },
    isCommentSectionVisible() {
      return (
        this.showComments ||
        this.customButtons ||
        (size(this.comments) && this.showDetails)
      );
    },
    areCommentsReadOnly() {
      return !isPermissioned(`timesheets[${this.timesheetId}].comment`);
    },
    showExpensesTab() {
      return size(this.formattedTimesheet.expenses);
    },
    isLoadingComments() {
      return this.isFetchingTimesheetComments && !this.comments.length;
    },
    currentTimesheetId() {
      return this.$route.params.id;
    },
    formattedTimesheet() {
      const { booking, title, currency_code } = this.currentTimesheet;
      return {
        ...this.currentTimesheet,
        role: title,
        currencyCode: currency_code,
        bookingId: booking ? booking.id : 0
      };
    },
    status() {
      return this.formattedTimesheet.status;
    },
    verificationStatus() {
      return this.formattedTimesheet.verificationDate ? VERIFIED : UNVERIFIED;
    },
    isApproved() {
      return this.status === "approved";
    },
    approverDetails() {
      const approver =
        this.currentTimesheet.approvedBy || this.currentTimesheet.approver;
      return {
        name: approver,
        date: this.currentTimesheet.approvalDate
      };
    },
    verifierDetails() {
      const verifier =
        this.currentTimesheet.verifiedBy || this.currentTimesheet.verifier;
      return {
        name: verifier,
        date: this.currentTimesheet.verificationDate
      };
    },
    tableHeaderItems() {
      const headerItems = [
        { label: "Booking ID", value: "bookingId", isClickable: true },
        { label: "Role", value: "role" },
        { label: "Worker", value: "worker" },
        { label: "Total Cost", value: "cost", isCurrency: true },
        { label: "Approved On", value: "approvalDate", isDate: true },
        { label: "Approver", value: "approver" }
      ];
      isPermissioned("timesheets.view.approved-by") &&
        headerItems.splice(5, 0, {
          label: "Approved By",
          value: "approvedBy"
        });
      return headerItems;
    },
    isWorker() {
      return isWorker(this.userData.roles);
    },
    parentLink() {
      if (this.$route.params.workerId) {
        return `/workers/${this.$route.params.workerId}/activity`;
      }

      return "/timesheets";
    }
  }
};
</script>

<style lang="scss">
.dispute-field .v-text-field__slot {
  display: flex;
  align-items: flex-start !important;
}
.timesheet-details {
  $custom-desktop-breakpoint: 1320px;

  .content-wrapper {
    min-height: auto;
    overflow: auto;

    margin-bottom: $timesheet-footer-height;
  }

  .wrapper {
    display: flex;
  }
  .table-wrapper {
    width: 100%;
  }

  .dispute-button {
    &.v-btn {
      color: $error !important;
      background-color: $error-transparent !important;
    }
  }

  .header-items {
    overflow-x: auto;

    .header-item {
      padding: 1rem;
      min-width: 100px;

      @media only screen and (max-width: $mobile-breakpoint - 1) {
        width: auto;
      }

      .v-divider {
        margin: auto;
      }
    }
  }

  .clickable {
    cursor: pointer;
    padding: 5px;
  }
}

.timesheet-time-header {
  position: relative;

  .permissioned-container {
    position: absolute;
    right: 0px;
    top: 0px;
  }
}
</style>
