<template>
  <BaseDialog :isOpen="isOpen">
    <template v-slot:title>
      <span>Are you sure you want to remove this tier ?</span>
    </template>
    <template v-slot:body>
      <div class="delete-agency-description">
        The agencies in this tier will no longer be notified about a booking via
        this publishing schedule.
      </div>
    </template>
    <template v-slot:actions>
      <div class="delete-agency-actions">
        <PrimaryButton @click.native="cancel" text>
          Cancel
        </PrimaryButton>
        <ErrorButton @click.native="handleSubmit" :loading="isLoading">
          Remove Tier
        </ErrorButton>
      </div>
    </template>
  </BaseDialog>
</template>

<script>
import BaseDialog from "@/components/common/BaseDialog";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import ErrorButton from "@/components/common/Button/ErrorButton";
import { createNamespacedHelpers } from "vuex";
import { DELETE_TIER } from "@/store/modules/organisations/organisation-schedules/actions";
import { ORGANISATION_SCHEDULES_NAMESPACE } from "@/store/modules/organisations/organisation-schedules";

const { mapActions } = createNamespacedHelpers(
  ORGANISATION_SCHEDULES_NAMESPACE
);

export default {
  name: "DeleteTierDialog",
  components: { ErrorButton, PrimaryButton, BaseDialog },
  data() {
    return {
      isLoading: false
    };
  },
  props: {
    isOpen: Boolean,
    tierId: String
  },
  methods: {
    ...mapActions({
      deleteTier: DELETE_TIER
    }),
    async handleSubmit() {
      this.isLoading = true;
      try {
        await this.deleteTier(this.tierId);
        this.$emit("deleted");
        this.$emit("close");
      } finally {
        this.isLoading = false;
      }
    },
    cancel() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss">
.delete-agency-description {
  min-height: 100px;
}
.delete-agency-actions {
  padding: 0.5rem;
}
</style>
