<template>
  <div class="review">
    <v-icon
      :size="size"
      v-for="starIndex in 5"
      :key="starIndex"
      class="review-star"
      :class="{
        marked: starIndex <= reviewValue,
        hovered: starIndex <= hoverValue && isUnmarked(starIndex)
      }"
      @click="ifInteractive(review, starIndex)"
      @mouseenter="ifInteractive(hover, starIndex)"
      @mouseleave="hover(0)"
    >
      mdi-star
    </v-icon>
    <ValidationProvider v-slot="{ errors }" name="reviewValue" rules="required">
      <v-text-field
        :error-messages="errors"
        class="review-error-message"
        :value="reviewValue"
        readonly
      />
    </ValidationProvider>
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  name: "StarReview",
  components: { ValidationProvider },
  data() {
    return {
      reviewValue: this.value,
      hoverValue: 0
    };
  },
  props: {
    value: {
      type: Number,
      required: false,
      default: 0
    },
    readOnly: {
      type: Boolean,
      required: false
    },
    size: {
      type: String,
      required: false,
      default: "45px"
    },
    reviewRequired: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  methods: {
    review(reviewValue) {
      this.reviewValue = reviewValue;
      this.$emit("review", reviewValue);
    },
    isUnmarked(value) {
      return value > this.reviewValue;
    },
    hover(value) {
      this.hoverValue = value;
    },
    ifInteractive(method, args) {
      if (!this.readOnly) {
        method(args);
      }
    }
  }
};
</script>

<style lang="scss">
.review {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 10px;

  .review-star {
    color: $secondary-line;
  }

  .marked {
    color: $_yellow;
  }
  .hovered {
    color: $secondary-line-hover;
  }
  .review-error-message {
    min-height: unset;

    .v-input__slot {
      display: none;
    }
  }
}
</style>
