<template>
  <BaseDialog :isOpen="isOpen">
    <template v-slot:title>
      <span>Select type of credit</span>
    </template>
    <template v-slot:body>
      <div class="credit-timesheet-description">
        Credit and rebill will create a duplicate draft timesheet for the Worker
        to resubmit and will credit the total cost of the timesheet. Credit Only
        will credit the timesheet and not create a duplicate.
      </div>
      <RadioButton
        @select="val => (creditType = val)"
        :selected-value="creditType"
        :options="creditOptions"
      />
    </template>
    <template v-slot:actions>
      <div class="credit-timesheet-actions">
        <PrimaryButton @click.native="cancel" text>
          Cancel
        </PrimaryButton>
        <PrimaryButton @click.native="handleClick" :loading="isLoading">
          Credit Timesheet
        </PrimaryButton>
      </div>
    </template>
  </BaseDialog>
</template>

<script>
import BaseDialog from "@/components/common/BaseDialog";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import RadioButton from "@/components/common/RadioButton";
import { createNamespacedHelpers } from "vuex";
import { TIMESHEETS_NAMESPACE } from "@/store/modules/timesheets";
import { first } from "lodash";
import {
  GET_CURRENT_TIMESHEET,
  CREDIT_TIMESHEET
} from "@/store/modules/timesheets/actions";

const { mapActions } = createNamespacedHelpers(TIMESHEETS_NAMESPACE);
const CREDIT_OPTIONS = [
  { label: "Credit and rebill", value: "credit_rebill" },
  { label: "Credit only", value: "credit_only" }
];

export default {
  name: "CreditDialog",
  components: { PrimaryButton, BaseDialog, RadioButton },
  data() {
    return {
      creditOptions: CREDIT_OPTIONS,
      creditType: first(CREDIT_OPTIONS).value,
      isLoading: false
    };
  },
  props: {
    isOpen: Boolean,
    id: {
      type: String,
      required: true
    }
  },
  methods: {
    ...mapActions({
      getCurrentTimesheet: GET_CURRENT_TIMESHEET,
      creditTimesheet: CREDIT_TIMESHEET
    }),
    async handleClick() {
      this.isLoading = true;
      try {
        await this.creditTimesheet({
          timesheetId: this.id,
          data: {
            creditType: this.creditType
          }
        });
        await this.getCurrentTimesheet(this.id);
        this.$emit("onUpdate");
      } catch {
        this.$emit("close");
      } finally {
        this.isLoading = false;
      }
    },
    cancel() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss">
.credit-timesheet-description {
  min-height: 100px;
}
.credit-timesheet-actions {
  padding: 0.5rem;
}
</style>
