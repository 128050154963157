<template>
  <div class="d-flex justify-center">
    <NewBooking ref="newBookingForm" @close="closeDialog" :isOpen="isOpen" />
    <SecondaryButton
      @click.native="openDialog"
      permission="create"
      color="primary"
    >
      <div class="d-flex align-center justify-center mb-1">
        <svg
          class="job-icon"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            class="fill"
            d="M12 4.21053V2.10526H8V4.21053H12ZM2 6.31579V17.8947H18V6.31579H2ZM18 4.21053C19.11 4.21053 20 5.14737 20 6.31579V17.8947C20 19.0632 19.11 20 18 20H2C0.89 20 0 19.0632 0 17.8947L0.00999999 6.31579C0.00999999 5.14737 0.89 4.21053 2 4.21053H6V2.10526C6 0.936842 6.89 0 8 0H12C13.11 0 14 0.936842 14 2.10526V4.21053H18Z"
          />
        </svg>
        <v-icon small>mdi-plus</v-icon>
      </div>
      New Booking
    </SecondaryButton>
  </div>
</template>

<script>
import NewBooking from "@/views/bookings/NewBooking/NewBooking";
import SecondaryButton from "@/components/common/Button/SecondaryButton";

export default {
  name: "AddJobAction",
  components: {
    NewBooking,
    SecondaryButton
  },
  data() {
    return {
      isOpen: false,
      contractType: null
    };
  },
  methods: {
    onSelectJobType(contractType) {
      this.contractType = contractType;
    },
    openDialog() {
      this.isOpen = true;
      document.title = "New Role - Roles - Track";
    },
    closeDialog() {
      this.isOpen = false;
      document.title = " Roles - Track";
    }
  }
};
</script>
<style lang="scss">
.job-icon {
  path.stroke {
    stroke: $white;
  }

  path.fill {
    fill: $white;
  }

  rect.stroke {
    stroke: $white;
  }

  rect.fill {
    fill: $white;
  }

  line.stroke {
    stroke: $white;
  }
}
</style>
