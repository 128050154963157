<template>
  <div>
    <PrimaryButton light dense shrink @click.native="openDialog">
      Edit Budget
      <template v-slot:append:icon>
        <v-icon class="ml-1">mdi-pencil</v-icon>
      </template>
    </PrimaryButton>
    <EditBudgetDialog
      v-if="isOpen"
      :booking="booking"
      :budget="budget"
      :isOpen="isOpen"
      @close="closeDialog"
    />
  </div>
</template>

<script>
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import EditBudgetDialog from "../dialogs/EditBudgetDialog";

export default {
  props: {
    booking: Object,
    budget: Object
  },
  components: {
    PrimaryButton,
    EditBudgetDialog
  },
  data() {
    return {
      isOpen: false
    };
  },
  methods: {
    openDialog() {
      this.isOpen = true;
    },
    closeDialog() {
      this.isOpen = false;
    }
  }
};
</script>
