<template>
  <ValidationObserver ref="addAssessmentValidation">
    <DialogFull :data="dialogSettings" @close="close">
      <template v-slot:dialog.body>
        <v-container class="col-8 assessment-content">
          <v-form ref="form">
            <h1>Assessment of {{ workers }}</h1>
            <p class="label">
              Based on your observations, what is your indicative assessment of
              this individual?
            </p>
            <StarReview
              @review="setReview"
              :value="form.review"
              class="star-review-section"
            />
            <v-label class="description-label">Notes</v-label>
            <ValidationProvider
              v-slot="{ errors }"
              name="description"
              rules="required"
            >
              <v-textarea
                :error-messages="showValidationErrors ? errors : ''"
                v-model="form.description"
                class="description-input"
              />
            </ValidationProvider>
            <StatusItem class="visibility-note">
              <template v-slot:icon><v-icon>mdi-lock</v-icon></template>
              <p class="visibility-note-description">
                This assessment will only visible to you and users in the
                managed service organisation. The worker and the worker’s agency
                <b>will not</b> be able to see this assessment
              </p>
            </StatusItem>
          </v-form>
        </v-container>
      </template>
      <template v-slot:dialog.action>
        <PrimaryButton @click.native="submitAssessment" :loading="isLoading">
          Submit Assessment
        </PrimaryButton>
      </template>
    </DialogFull>
  </ValidationObserver>
</template>

<script>
import StarReview from "@/components/common/StarReview";
import DialogFull from "@/components/common/DialogFull";
import StatusItem from "@/components/common/StatusItem";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import { ADD_ASSESSMENT } from "@/store/modules/applications/actions";
import { createNamespacedHelpers } from "vuex";
import { APPLICATION_NAMESPACE } from "@/store/modules/applications";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { getFullName } from "@/utils/users";
import { join, map } from "lodash";
import { POST_GLOBAL_MESSAGE } from "@/store/modules/global/action-types";
import { mapActions } from "vuex";

const { mapActions: mapApplicationActions } = createNamespacedHelpers(
  APPLICATION_NAMESPACE
);
const INIT_FORM_DATA = {
  description: "",
  review: null
};

export default {
  name: "AssessmentDialog",
  components: {
    PrimaryButton,
    StatusItem,
    DialogFull,
    StarReview,
    ValidationObserver,
    ValidationProvider
  },
  data() {
    return {
      showValidationErrors: false,
      form: Object.assign({}, INIT_FORM_DATA),
      descriptionRules: [],
      isLoading: false
    };
  },
  computed: {
    dialogSettings() {
      return {
        dialog: this.isOpen,
        title: "Assessment"
      };
    },
    workers() {
      return join(
        map(this.applications, ({ user }) => getFullName(user)),
        ", "
      );
    }
  },
  props: {
    isOpen: Boolean,
    applications: {
      type: Array,
      required: true
    }
  },
  methods: {
    ...mapActions({
      postGlobalMessage: POST_GLOBAL_MESSAGE
    }),
    ...mapApplicationActions({
      addAssessment: ADD_ASSESSMENT
    }),
    clearForm() {
      this.form = Object.assign({}, INIT_FORM_DATA);
    },
    setReview(review) {
      this.form.review = review;
    },
    close() {
      this.clearForm();
      this.$refs.addAssessmentValidation.reset();
      this.$emit("close");
    },
    async submitAssessment() {
      this.showValidationErrors = true;
      const isValid = await this.$refs.addAssessmentValidation.validate();
      if (isValid) {
        this.isLoading = true;
        try {
          const requests = map(this.applications, ({ id: applicationId }) =>
            this.addAssessment({
              applicationId,
              assessment: {
                notes: this.form.description,
                rating: this.form.review
              }
            })
          );
          await Promise.all(requests);
          this.postSuccesMessage();
          this.clearForm();
          this.$emit("close");
        } finally {
          this.isLoading = false;
        }
      }
    },
    postSuccesMessage() {
      const message = {
        snackbar: true,
        type: "success",
        title: "You have completed an assessment"
      };
      this.postGlobalMessage(message);
    }
  }
};
</script>

<style lang="scss">
.assessment-content {
  padding: 3rem;
  > h1 {
    margin-bottom: 0.5rem;
  }

  > .label {
    font-size: 22px;
    color: $secondary-text;
  }

  > .description-label {
    font-size: 18px;
  }

  > div {
    margin-bottom: 1rem;
  }

  .visibility-note {
    background-color: $selected-transparent;
    border-radius: $default-border-radius;
    padding: 0.5rem;

    i {
      margin: auto 1rem;
    }

    p {
      white-space: unset !important;
      margin-bottom: 0;
    }
  }
}
</style>
