export const STACKING_OPTIONS = [
  {
    label: "Don't stack",
    value: null
  },
  {
    label: "Stack",
    value: "standard"
  },
  {
    label: "Stack 100%",
    value: "hundred_percent"
  }
];

export const REPORT_FOCUSES = [
  {
    key: "bookings",
    name: "Bookings",
    icon: require("@/assets/svg/navigation-drawer/bookings_blue.svg")
  },
  {
    key: "applications",
    name: "Applications",
    icon: require("@/assets/svg/navigation-drawer/applications_blue.svg")
  },
  {
    key: "timesheets",
    name: "Timesheets",
    icon: require("@/assets/svg/timesheet-icon-blue.svg")
  },
  {
    key: "shifts",
    name: "Shifts",
    icon: require("@/assets/svg/shifts-icon-blue.svg")
  }
];

export const MEASURE_TYPES = {
  minimum: "Minimum",
  maximum: "Maximum",
  average: "Average",
  sum: "Total"
};

export const OPERATOR_TYPES = {
  equals: "Is",
  not_equals: "Is not",
  contains: "Contains",
  not_contains: "Doesn't contain",
  gt: "Greater than",
  gte: "Greater than or equal to",
  lt: "Less than",
  lte: "Less than or equal to",
  between: "Between",
  before_date: "Before or on",
  in_last_date_range: "In the last",
  in_next_date_range: "In the next",
  after_date: "After"
};

export const UNIT_TYPES = {
  second: "Second",
  minute: "Minute",
  hour: "Hour",
  day: "Day",
  week: "Week",
  month: "Month",
  quarter: "Quarter",
  year: "Year"
};

export const PERMISSIONS = [
  { text: "Editor", value: "write" },
  { text: "Viewer", value: "read" }
];
