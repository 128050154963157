<template>
  <BaseAction>
    <SecondaryButton @click.native="openDialog" color="primary">
      Bulk Timesheets
    </SecondaryButton>
    <BulkTimesheetsDialog
      :isOpen="isDialogOpen"
      @close="closeDialog"
      @save="$emit('save')"
    />
  </BaseAction>
</template>

<script>
import BulkTimesheetsDialog from "@/views/timesheets/components/dialogs/BulkTimesheetsDialog";
import SecondaryButton from "@/components/common/Button/SecondaryButton";
import BaseAction from "@/components/common/BaseAction";

export default {
  props: {
    bookingId: String
  },
  components: {
    SecondaryButton,
    BulkTimesheetsDialog,
    BaseAction
  },
  data() {
    return {
      isDialogOpen: false
    };
  },
  methods: {
    openDialog() {
      this.isDialogOpen = true;
    },
    closeDialog() {
      this.isDialogOpen = false;
      this.$emit("close");
    },
    onUpdate() {
      this.closeDialog();
    }
  }
};
</script>
