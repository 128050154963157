import REASONS_FOR_HIRE from "./data/reasons-for-hire";
import BOOKING_TEMPLATE from "./data/template";
import BOOKING_TEMPLATES from "./data/template-list";
import COUNTRIES from "./data/countries";
import LOCATIONS from "./data/locations";

export const MOCKED_DATA_STATE = {
  bookingTemplates: BOOKING_TEMPLATES,
  currentBookingTemplate: BOOKING_TEMPLATE,
  reasonsForHire: REASONS_FOR_HIRE,
  countries: COUNTRIES,
  rejectionReasons: [],
  locations: LOCATIONS
};
