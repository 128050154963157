<template>
  <div>
    <LabeledText
      class="unit-item"
      :class="{ hover }"
      :clickable="hasChildren"
      @mouseover.native="hover = true"
      @mouseleave.native="hover = false"
    >
      <div class="d-flex align-center" @click="toggled = !toggled">
        <div class="mr-2">{{ unit.name }}</div>
        <UnitChip :status="unit.status" v-if="unit.status">
          {{ unit.status }}
        </UnitChip>
      </div>
      <template v-slot:icon>
        <div
          class="gray-background-icon square-30 mr-2"
          v-if="hasChildren"
          @click="toggled = !toggled"
        >
          <v-icon v-if="toggled">mdi-chevron-down</v-icon>
          <v-icon v-else>mdi-chevron-right</v-icon>
        </div>
      </template>
      <template v-slot:action>
        <div v-show="hover || isMobile">
          <Permissioned section="settings" :id="unit.id">
            <EditUnitDialog
              class="mr-2"
              :unit="unit"
              permission="edit-organisation-unit.edit"
            />
            <DuplicateUnitDialog
              class="mr-2"
              :id="unit.id"
              permission="edit-organisation-unit.duplicate"
            />
            <DeleteUnitDialog
              class="mr-2"
              :id="unit.id"
              permission="edit-organisation-unit.delete"
            />
            <ArchiveUnitDialog
              class="mr-2"
              :id="unit.id"
              permission="edit-organisation-unit.archive"
            />
            <PublishUnitDialog
              class="mr-2"
              :id="unit.id"
              permission="edit-organisation-unit.publish"
            />
            <CreateUnitDialog
              :initial-parent="unit"
              permission="create-organisation-unit"
            />
          </Permissioned>
        </div>
      </template>
    </LabeledText>
    <div class="unit-children" v-if="toggled">
      <OrganisationUnitItem
        toggledByFilter
        v-for="child in children"
        :key="child.id"
        :unit="child"
      />
    </div>
  </div>
</template>

<script>
import LabeledText from "@/components/common/BaseDetails/LabeledText";
import UnitChip from "@/views/settings/Organisation/Units/UnitChip";
import CreateUnitDialog from "@/views/settings/Organisation/Units/dialogs/CreateUnitDialog";
import ArchiveUnitDialog from "@/views/settings/Organisation/Units/dialogs/ArchiveUnitDialog";
import DeleteUnitDialog from "@/views/settings/Organisation/Units/dialogs/DeleteUnitDialog";
import DuplicateUnitDialog from "@/views/settings/Organisation/Units/dialogs/DuplicateUnitDialog";
import PublishUnitDialog from "@/views/settings/Organisation/Units/dialogs/PublishUnitDialog";
import EditUnitDialog from "@/views/settings/Organisation/Units/dialogs/EditUnitDialog";
import Permissioned from "@/components/common/Permissioned";

export default {
  name: "OrganisationUnitItem",
  components: {
    Permissioned,
    EditUnitDialog,
    PublishUnitDialog,
    DuplicateUnitDialog,
    DeleteUnitDialog,
    ArchiveUnitDialog,
    CreateUnitDialog,
    UnitChip,
    LabeledText
  },
  props: {
    unit: {
      type: Object,
      required: true
    },
    toggledByFilter: Boolean
  },
  data() {
    return {
      toggled: this.toggledByFilter,
      hover: false
    };
  },
  computed: {
    hasChildren() {
      return !!this.children.length;
    },
    children() {
      return this.unit.organisationUnits || [];
    }
  },
  watch: {
    toggledByFilter(val) {
      this.toggled = val;
    }
  }
};
</script>

<style lang="scss" scoped>
.unit-item {
  padding: 0.2rem;

  &.hover {
    background-color: $background;
    display: flex;
    justify-content: row;
  }

  .actions {
    display: flex;
  }
}
.unit-children {
  margin-left: 1.5rem;
}
</style>
