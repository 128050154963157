<template>
  <v-card class="report--card">
    <v-card-title>
      <v-img src="@/assets/svg/csv-icon.svg" class="file-icon" contain />
      {{ report.title }}
    </v-card-title>
    <v-card-text>
      {{ report.description }}
    </v-card-text>
    <v-card-actions>
      <PrimaryButton
        :loading="isGeneratingReport"
        light
        @click.native="handleDownload"
      >
        Request
      </PrimaryButton>
    </v-card-actions>
  </v-card>
</template>

<script>
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import { GET_REPORT } from "@/store/modules/exports/action-types";
import { createNamespacedHelpers } from "vuex";
import { EXPORTS_NAMESPACE } from "@/store/modules/exports";
import { size } from "lodash";

const { mapActions } = createNamespacedHelpers(EXPORTS_NAMESPACE);

export default {
  name: "ReportCard",
  components: {
    PrimaryButton
  },
  data() {
    return {
      isGeneratingReport: false
    };
  },
  methods: {
    ...mapActions({
      getReport: GET_REPORT
    }),
    async handleDownload() {
      let report = {
        id: this.report.id
      };

      if (this.dateFrom && !this.dateTo) {
        this.$emit("validate");
        return;
      }
      if ((this.dateFrom && this.dateTo) || size(this.clients)) {
        report = {
          ...report,
          params: {
            filter: {
              ...(this.dateFrom && { date_from: this.dateFrom }),
              ...(this.dateTo && { date_to: this.dateTo }),
              ...(size(this.clients) && { client_id: this.clients })
            }
          }
        };
      }

      this.isGeneratingReport = true;
      try {
        // request before downloading file to catch potential errors, endpoint url is prepared for instant file downloading
        await this.getReport(report);
        // this.downloadFile(isFiltered);
      } finally {
        this.isGeneratingReport = false;
      }
    }
  },
  props: {
    report: {
      type: Object,
      required: true
    },
    dateFrom: String,
    dateTo: String,
    clients: Array
  }
};
</script>

<style lang="scss" scoped></style>
