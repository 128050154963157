<template>
  <div class="compliances py-2">
    <Chip
      class="mx-1 my-1"
      :color="color"
      v-for="compliance in compliances"
      :key="compliance.id"
    >
      <v-icon class="mr-1">{{ icon }}</v-icon>
      {{ compliance.complianceCheckName }}
    </Chip>
  </div>
</template>

<script>
import Chip from "@/components/common/Chip";

export default {
  props: {
    compliances: {
      type: Array,
      required: true
    },
    complete: Boolean
  },
  components: { Chip },
  computed: {
    icon() {
      return `mdi-shield-${this.complete ? "check" : "remove"}-outline`;
    },
    color() {
      return this.complete ? "green" : "gray";
    }
  }
};
</script>

<style lang="scss" scoped>
.compliances {
  .v-chip--label.v-chip {
    color: #000 !important;
    text-transform: none !important;
    font-weight: 600 !important;
  }
  .v-chip .v-icon {
    color: #000;
  }
}
</style>
