<template>
  <div>
    <div class="list-item with-icon multiple-select-list">
      <div @click="$emit('select', timesheetsId)">
        <div v-if="selectMultiple" class="multiselect-wrapper">
          <v-checkbox
            class="checkbox-large-icon mr-4"
            :input-value="isGroupSelected"
          />
        </div>
        <v-list-item-avatar v-else tile>
          <v-img height="30px" contain src="@/assets/svg/timesheet_icon.svg" />
        </v-list-item-avatar>
      </div>
      <v-list-item-content @click="expanded = !expanded">
        <div class="item-right timesheet-time-details">
          <div class="description">
            <div class="d-flex" :class="{ 'flex-column': isMobile }">
              <span class="font-weight-bold">
                {{ `${shiftTime} | ${booking.bookingName}` }}
              </span>
            </div>
            <div class="status-wrapper">
              <p class="mb-0 my-1">{{ workerCountText }}</p>
            </div>
          </div>
          <div class="d-flex flex-column align-center">
            <span class="booking-list-item__mono mb-1">
              B-{{ booking.bookingId }}
            </span>
            <Chip
              :color="getColorByStatus(booking.timesheetStatus)"
              class="chip--status"
              shrink
            >
              {{ booking.timesheetStatus | upperCase }}
            </Chip>
          </div>
        </div>
      </v-list-item-content>
    </div>
    <div class="px-10" v-if="expanded">
      <div
        class="pt-2"
        v-for="(agency, index) in booking.agencies"
        :key="index"
      >
        <div class="d-flex">
          <v-icon class="mr-1" color="black">mdi-domain</v-icon>
          <label>{{ agency.agencyName }}</label>
        </div>
        <p class="secondary-text d-flex" v-if="isEmpty(agency.workers)">
          This timesheet has no entries.
        </p>
        <TimesheetByBookingsAgency
          v-for="worker in agency.workers"
          :key="worker.id"
          :worker="worker"
          :status="booking.timesheetStatus"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TimesheetByBookingsAgency from "@/views/timesheets/details/components/TimesheetByBookingsAgency";
import { getColorByStatus } from "@/utils/timesheets";
import pluralize from "pluralize";
import { size, split, first, reduce, map, isEmpty, some } from "lodash";
import Chip from "@/components/common/Chip";
import { getTimeRange } from "@/utils/time";

export default {
  name: "TimesheetByBookings",
  props: {
    booking: Object,
    selectedTimesheets: {
      type: Array,
      required: true
    },
    selectMultiple: Boolean
  },
  components: {
    TimesheetByBookingsAgency,
    Chip
  },
  data() {
    return {
      expanded: false
    };
  },
  computed: {
    workerCountText() {
      const count = size(this.timesheetsId);
      return `${count} ${pluralize("worker", count)}`;
    },
    firstTimesheet() {
      return first(this.timesheets);
    },
    timesheetsId() {
      const status = this.booking.timesheetStatus;
      return reduce(
        this.booking.agencies,
        (ids, agency) => {
          map(agency.workers, ({ timesheetId }) => {
            // TODO: include permissions when the api is ready
            ids = [
              ...ids,
              { id: timesheetId, status, meta: { permissions: {} } }
            ];
          });
          return ids;
        },
        []
      );
    },
    shiftTime() {
      return getTimeRange({
        startTime: this.booking.expectedStartTime,
        endTime: this.booking.expectedEndTime
      });
    },
    isGroupSelected() {
      const firstTimesheet = first(this.timesheetsId);
      return this.isSelected(firstTimesheet.id);
    }
  },
  methods: {
    isSelected(id) {
      return some(this.selectedTimesheets, timesheet => {
        return timesheet.id === id;
      });
    },
    getColorByStatus,
    isEmpty
  },
  filters: {
    splitText(value) {
      const splittedText = split(value, ",");
      return first(splittedText);
    }
  }
};
</script>
