import axios from "axios";
import {
  isUnauthorized,
  HTTP_UNAUTHORIZED_MESSAGE
} from "@/utils/requestUtils";
import { forEach } from "lodash";
import store from "@/store";
import { SET_AUTH_DIALOG_VISIBILITY } from "@/store/modules/global/mutation-types";
import { getToken, getImpersonateUserId } from "@/services/utils";
import { NS_SET_API_AVAILABILITY } from "@/store/modules/layout/mutation-types";

export class APIService {
  static instance;

  constructor() {
    if (!APIService.instance) {
      this.createInstance();
    }

    return APIService.instance;
  }

  createInstance() {
    this.host = `${process.env.VUE_APP_API_URL}/api/v1`;
    this.hostv2 = `${process.env.VUE_APP_API_URL}/api/v2`;
    this.defaultHeaders = {
      "Content-Type": "application/json"
    };
    this.defaultTimeout = 30000;

    this.http = axios.create({
      baseURL: this.host,
      headers: this.defaultHeaders,
      timeout: this.defaultTimeout
    });

    this.http.interceptors.response.use(
      ({ data }) => data,
      error => {
        if (isUnauthorized(error)) {
          clearLocalStorageItems();
          store.commit(SET_AUTH_DIALOG_VISIBILITY, true);
          return {
            message: HTTP_UNAUTHORIZED_MESSAGE
          };
        }

        store.commit(NS_SET_API_AVAILABILITY, false);
        return Promise.reject(error);
      }
    );

    APIService.instance = this;
  }

  getAuthorizedCallConfig = async (config, options = {}) => {
    if (options.disableHeaders) {
      return config;
    }

    const headers = {
      ...this.defaultHeaders,
      Authorization: `Bearer ${getToken()}`
    };
    const impersonateUserId = getImpersonateUserId();

    if (impersonateUserId) {
      headers["X-Impersonate"] = impersonateUserId;
    }

    return {
      ...config,
      headers
    };
  };

  getdefaultHeadersWithToken = async () => {
    const headers = {
      ...this.defaultHeaders,
      Authorization: `Bearer ${getToken()}`
    };
    const impersonateUserId = getImpersonateUserId();

    if (impersonateUserId) {
      headers["X-Impersonate"] = impersonateUserId;
    }
    return headers;
  };

  client = () => ({
    delete: async (params, config = {}, options = {}) => {
      const callConfig = await this.getAuthorizedCallConfig(config, options);

      return this.http.delete(params, callConfig);
    },
    get: async (params, config = {}, options = {}) => {
      const callConfig = await this.getAuthorizedCallConfig(config, options);

      return this.http.get(params, callConfig);
    },
    patch: async (params, data, config = {}, options = {}) => {
      const callConfig = await this.getAuthorizedCallConfig(config, options);

      return this.http.patch(params, data, callConfig);
    },
    post: async (params, data, config = {}, options = {}) => {
      const callConfig = await this.getAuthorizedCallConfig(config, options);

      return this.http.post(params, data, callConfig);
    },
    put: async (params, data, config = {}, options = {}) => {
      const callConfig = await this.getAuthorizedCallConfig(config, options);

      return this.http.put(params, data, callConfig);
    },
    postFile: async (params, data) => {
      let headers = await this.getdefaultHeadersWithToken();
      headers["Content-Type"] = "multipart/form-data";
      return axios.post(`${this.host}/${params}`, data, {
        headers,
        timeout: this.defaultTimeout
      });
    },
    getV2: async (prefix, params) => {
      const headers = await this.getdefaultHeadersWithToken();
      return axios.get(`${this.hostv2}/${prefix}`, {
        ...params,
        headers,
        timeout: this.defaultTimeout
      });
    }
  });
}

export const withPrefix = (apiClient, prefix) => ({
  delete: async (params = "", ...args) =>
    apiClient.delete(`${prefix}${getPathParams(params)}`, ...args),
  get: async (params = "", ...args) =>
    apiClient.get(`${prefix}${getPathParams(params)}`, ...args),
  patch: async (params = "", ...args) =>
    apiClient.patch(`${prefix}${getPathParams(params)}`, ...args),
  post: async (params = "", ...args) =>
    apiClient.post(`${prefix}${getPathParams(params)}`, ...args),
  put: async (params = "", ...args) =>
    apiClient.put(`${prefix}${getPathParams(params)}`, ...args)
});

export const getPathParams = params => (params ? `/${params}` : "");

export const getAuthLogoutUrl = () => {
  const redirectTo = `redirect_to=${window.location.href}`;
  return `${process.env.VUE_APP_AUTH_URL}/logout?${redirectTo}`;
};

export const clearLocalStorageItems = () => {
  const keysToRemove = [
    "token",
    "impersonateUser",
    "impersonateUserId",
    "role",
    "selectedProfile"
  ];
  forEach(keysToRemove, key => {
    localStorage.removeItem(key);
  });
};

export default new APIService();
