<template>
  <BaseDialog :isOpen="isOpen" class="dialog--confirm">
    <template v-slot:title>
      Are you sure you want to delete this report?
    </template>
    <template v-slot:body>
      <div class="dialog--confirm__description">
        This cannot be undone
      </div>
    </template>
    <template v-slot:actions>
      <div class="dialog--confirm__actions">
        <PrimaryButton @click.native="cancel" text>
          Cancel
        </PrimaryButton>
        <ErrorButton @click.native="handleDelete" :loading="isLoading">
          Delete Report
        </ErrorButton>
      </div>
    </template>
  </BaseDialog>
</template>

<script>
import BaseDialog from "@/components/common/BaseDialog";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import ErrorButton from "@/components/common/Button/ErrorButton";
import { createNamespacedHelpers } from "vuex";
import { REPORTS_NAMESPACE } from "@/store/modules/reports";
import { DELETE_REPORT } from "@/store/modules/reports/action-types";

const { mapActions } = createNamespacedHelpers(REPORTS_NAMESPACE);
export default {
  components: { ErrorButton, PrimaryButton, BaseDialog },
  data() {
    return {
      isLoading: false
    };
  },
  props: {
    isOpen: Boolean
  },
  inject: ["currentReportId"],
  methods: {
    ...mapActions({
      deleteReport: DELETE_REPORT
    }),
    async handleDelete() {
      this.isLoading = true;
      await this.deleteReport(this.currentReportId);
      this.isLoading = false;
      this.$emit("close");
      await this.$router.push(`/reports`);
    },
    cancel() {
      this.$emit("close");
    }
  }
};
</script>
