<template>
  <div>
    <AgencyDetailsDialog
      v-if="isOpen"
      :is-open="isOpen"
      @close="closeNewAgencyDialog"
      title="New Agency"
      actionName="Create Agency"
      @submit="onSubmit"
      :loading="isLoading"
    />
  </div>
</template>

<script>
import AgencyDetailsDialog from "@/views/agencies/dialogs/AgencyDetailsDialog";
import { createNamespacedHelpers } from "vuex";
import { ADD_AGENCY } from "@/store/modules/agencies/action-types";
import { AGENCY_NAMESPACE } from "@/store/modules/agencies";

const { mapActions } = createNamespacedHelpers(AGENCY_NAMESPACE);

export default {
  name: "NewAgencyAction",
  components: { AgencyDetailsDialog },
  props: {
    isOpen: Boolean
  },
  data() {
    return {
      userDialogOpen: false,
      isLoading: false
    };
  },
  methods: {
    ...mapActions({
      createAgency: ADD_AGENCY
    }),
    async onSubmit(userData) {
      try {
        this.isLoading = true;
        await this.createAgency(userData);
        this.closeNewAgencyDialog();
      } finally {
        this.isLoading = false;
      }
    },
    closeNewAgencyDialog() {
      this.$emit("close");
    }
  }
};
</script>

<style scoped></style>
