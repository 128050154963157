<script>
import { createNamespacedHelpers } from "vuex";
import { get, size, some } from "lodash";

const { mapState } = createNamespacedHelpers("auth");

export default {
  name: "Permissioned",
  computed: {
    ...mapState(["permissions"]),
    sectionSelector() {
      return `${this.section}${this.id ? `[${this.id}]` : ""}`;
    }
  },
  methods: {
    permittedComponents() {
      if (!this.$slots.default) {
        return [];
      }
      if (this.permitAll) {
        return this.$slots.default;
      }
      return this.$slots.default.filter(({ data }) => {
        const permission = get(data, "attrs.permission");
        const oneOfPermissions = get(data, "attrs.oneOfPermissions") || [];

        if (size(oneOfPermissions)) {
          return some(oneOfPermissions, permissionToCheck =>
            this.checkIfPermitted(permissionToCheck)
          );
        } else {
          return !permission || this.checkIfPermitted(permission);
        }
      });
    },
    checkIfPermitted(permission) {
      const namespacedPermission = this.sectionSelector
        ? `${this.sectionSelector}.${permission}`
        : permission;

      return get(this.permissions, namespacedPermission);
    }
  },
  props: {
    section: {
      type: String,
      default: ""
    },
    id: {
      type: String,
      default: ""
    },
    vertical: Boolean,
    permitAll: Boolean
  },
  render(createElement) {
    return createElement(
      "div",
      {
        class: {
          "permissioned-container": true,
          vertical: this.vertical
        }
      },
      [this.permittedComponents()]
    );
  }
};
</script>

<style lang="scss">
.permissioned-container {
  display: flex;
  overflow: auto;
  @media only screen and (max-width: $tablet-breakpoint - 1px) {
  }

  &.vertical {
    width: 100%;
    flex-direction: column;
  }
}
</style>
