<template>
  <DialogFull :data="dialogSettings" @close="close">
    <template v-slot:dialog.body>
      <ShiftStep @change="handleChange">
        <div class="mt-9">
          <v-label>Reason for Extension</v-label>
          <TextField class="mt-2" />
        </div>
      </ShiftStep>
    </template>
    <template v-slot:dialog.action>
      <v-spacer />
      <PrimaryButton @click.native="save" :loading="isLoading">
        Extend Booking
      </PrimaryButton>
    </template>
  </DialogFull>
</template>

<script>
import DialogFull from "@/components/common/DialogFull";
import {
  BOOKINGS_NAMESPACE,
  EDIT_BOOKING
} from "@/store/modules/bookings/actions";
import { createNamespacedHelpers } from "vuex";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import ShiftStep from "@/views/bookings/NewBooking/steps/ShiftStep";
import TextField from "@/components/common/TextField";

const { mapActions } = createNamespacedHelpers(BOOKINGS_NAMESPACE);

export default {
  name: "ExtendBooking",
  components: {
    TextField,
    ShiftStep,
    PrimaryButton,
    DialogFull
  },
  computed: {
    dialogSettings() {
      return {
        title: "Extend Booking",
        dialog: this.isOpen
      };
    }
  },
  data() {
    return {
      isLoading: false
    };
  },
  props: {
    isOpen: Boolean,
    shifts: Object
  },
  methods: {
    ...mapActions({
      editBooking: EDIT_BOOKING
    }),
    handleChange(shifts) {
      this.shifts = shifts;
    },
    close() {
      this.$emit("close");
    },
    async save() {
      this.isLoading = true;
      try {
        // TODO Insert booking id
        await this.editBooking({
          bookingId: 1,
          editedBooking: { payload: "" }
        });
        this.$emit("close");
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style lang="scss"></style>
