<template>
  <TimesheetList
    :additional-filters="{ booking_id: currentBookingId }"
    :show-columns="['worker']"
  />
</template>

<script>
import TimesheetList from "@/components/timesheets/TimesheetList";

export default {
  name: "BookingTimesheets",
  components: {
    TimesheetList
  },
  computed: {
    currentBookingId() {
      return this.$route.params.id;
    }
  }
};
</script>

<style lang="scss"></style>
