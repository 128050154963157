<template>
  <BaseDialog :isOpen="isOpen">
    <template v-slot:title>
      <span
        >Are you sure you want to delete
        {{ pluralize("this", size(shifts)) }}
        {{ pluralize("shift", size(shifts)) }}?
      </span>
    </template>
    <template v-slot:body>
      <div class="cancel-shift-description">
        This can’t be undone.
      </div>
    </template>
    <template v-slot:actions>
      <div class="cancel-shift-actions">
        <PrimaryButton @click.native="cancel" text>
          Cancel
        </PrimaryButton>
        <ErrorButton @click.native="handleSubmit" :loading="isLoading">
          Delete {{ pluralize("Shift", size(shifts)) }}?
        </ErrorButton>
      </div>
    </template>
  </BaseDialog>
</template>

<script>
import BaseDialog from "@/components/common/BaseDialog";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import { createNamespacedHelpers } from "vuex";
import {
  BOOKINGS_NAMESPACE,
  CANCEL_SHIFTS
} from "@/store/modules/bookings/actions";
import ErrorButton from "@/components/common/Button/ErrorButton";
import { map, size } from "lodash";
import pluralize from "pluralize";

const { mapActions, mapState } = createNamespacedHelpers(BOOKINGS_NAMESPACE);

export default {
  name: "CancelShiftDialog",
  components: { ErrorButton, PrimaryButton, BaseDialog },
  props: {
    isOpen: Boolean,
    shifts: Array,
    isV2: Boolean
  },
  data() {
    return {
      isLoading: false
    };
  },
  computed: {
    ...mapState({
      booking: state => state.currentBooking
    })
  },
  methods: {
    ...mapActions({
      cancelShifts: CANCEL_SHIFTS
    }),
    async handleSubmit() {
      this.isLoading = true;
      try {
        const shiftIds = map(this.shifts, "id");
        const bookingId = this.isV2
          ? this.shifts[0].bookingId
          : this.shifts[0].booking.id;
        await this.cancelShifts({
          bookingId: bookingId,
          shiftIds
        });

        this.$emit("close", { updateList: true });
      } finally {
        this.isLoading = false;
      }
    },
    cancel() {
      this.$emit("close", { updateList: false });
    },
    pluralize,
    size
  }
};
</script>

<style lang="scss">
.cancel-shift-description {
  min-height: 100px;
}
.cancel-shift-actions {
  padding: 0.5rem;
}
</style>
