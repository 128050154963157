import { BOOKING_TEMPLATE_MODEL } from "@/models/booking-template-model";
import JSONApiService from "@/services/JSONApiService";
import {
  TEMPLATE_PAY_RATES,
  TEMPLATE_PAY_RATES_ITEMS,
  CLIENT,
  TEMPLATE_COSTS,
  include
} from "@/models/relationships";

const bookingTemplateIncludes = include(
  TEMPLATE_PAY_RATES,
  TEMPLATE_PAY_RATES_ITEMS,
  TEMPLATE_COSTS,
  CLIENT
);

const organisationBookingTemplateApi = commit => ({
  getAll: () =>
    JSONApiService(commit)
      .all(BOOKING_TEMPLATE_MODEL)
      .get(),
  getBookingTemplate: id =>
    JSONApiService(commit)
      .one(BOOKING_TEMPLATE_MODEL, id)
      .get(bookingTemplateIncludes),
  add: data => JSONApiService(commit).create(BOOKING_TEMPLATE_MODEL, data),
  edit: data =>
    JSONApiService(commit)
      .one(BOOKING_TEMPLATE_MODEL, data.id)
      .patch(data, bookingTemplateIncludes)
});

export default organisationBookingTemplateApi;
