<template>
  <div>
    <PrimaryButton shrink @click="isDialogOpen = true">
      <v-icon class="bold-icon" size="x-large">
        mdi-plus
      </v-icon>
    </PrimaryButton>
    <BaseDialog :is-open="isDialogOpen" v-if="isDialogOpen">
      <template v-slot:title>
        New Organisation Unit
      </template>
      <template v-slot:body>
        <v-form ref="form" lazy-validation class="flex-gap-20 mb-5 mt-3">
          <TextField
            :disabled="isLoading"
            label="Name"
            @change="organisationData.name = $event"
            outlined
            dense
            :rules="isRequired('Name', shouldValidate)"
            with-errors
          />
          <TextField
            :disabled="isLoading"
            label="Cost Code (optional)"
            :value="organisationData.costCode"
            @change="organisationData.costCode = $event"
            outlined
            dense
          />
          <Select
            :disabled="isLoading"
            @change="organisationData.parent.id = $event"
            :value="initialParent.id"
            label="Parent Organisation Unit"
            placeholder="Select Organisation Unit"
            :items="units"
            item-text="name"
            item-value="id"
            :rules="isRequired('Parent Organisation Unit', shouldValidate)"
          />
        </v-form>
      </template>
      <template v-slot:actions>
        <PrimaryButton text @click="close">Cancel</PrimaryButton>
        <PrimaryButton @click.native="handleSubmit" :loading="isLoading">
          Create Organisation Unit
        </PrimaryButton>
      </template>
    </BaseDialog>
  </div>
</template>

<script>
import BaseDialog from "@/components/common/BaseDialog";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import { ORGANISATION_UNITS_NAMESPACE } from "@/store/modules/organisations/organisation-units";
import { createNamespacedHelpers } from "vuex";
import { isRequired } from "@/utils/validation/rules";
import Select from "@/components/common/Select";
import TextField from "@/components/common/TextField";
import { CREATE_ORGANISATION_UNIT } from "@/store/modules/organisations/organisation-units/actions";
import { copyState } from "@/utils/helpers";

const { mapActions, mapState } = createNamespacedHelpers(
  ORGANISATION_UNITS_NAMESPACE
);

export default {
  name: "CreateUnitDialog",
  components: { TextField, Select, PrimaryButton, BaseDialog },
  data() {
    return {
      isDialogOpen: false,
      isLoading: false,
      shouldValidate: false,
      organisationData: {
        name: "",
        costCode: "",
        organisationType: this.initialParent.organisationType,
        parent: {
          id: this.initialParent.id
        }
      }
    };
  },
  props: {
    initialParent: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState(copyState(["organisationUnits"])),
    isValid() {
      return this.organisationData.name && this.organisationData.parent.id;
    },
    units() {
      return this.organisationUnits;
    }
  },
  methods: {
    ...mapActions({
      createOrganisationUnit: CREATE_ORGANISATION_UNIT
    }),
    isRequired,
    close() {
      this.shouldValidate = false;
      this.isDialogOpen = false;
    },
    async handleSubmit() {
      this.shouldValidate = true;
      this.$refs.form.validate();
      if (this.isValid) {
        try {
          this.isLoading = true;
          await this.createOrganisationUnit(this.organisationData);
        } catch (e) {
          console.error(e);
        } finally {
          this.close();
          this.isLoading = false;
        }
      }
    }
  }
};
</script>

<style scoped></style>
