<template>
  <ValidationProvider v-slot="{ errors }" rules="required">
    <div class="terms d-flex">
      <v-checkbox
        name="isConfirmed"
        :error-messages="errors"
        hide-details
        :label="confirmText"
        @change="handleChangeValue"
        v-model="isConfirmed"
        class="mt-0"
        :class="isMobile ? 'py-5' : 'mr-2'"
      />
    </div>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

const CONFIRM_TEXT =
  "I confirm that the recorded hours are a true and accurate reflection of the time I worked.";

export default {
  props: {
    value: {
      type: Boolean,
      required: false,
      default: null
    }
  },
  components: {
    ValidationProvider
  },
  data() {
    return {
      confirmText: CONFIRM_TEXT,
      isConfirmed: this.value
    };
  },
  methods: {
    handleChangeValue(newValue) {
      this.$emit("onConfirmationValueChanged", newValue);
    }
  }
};
</script>

<style lang="scss">
.terms {
  min-width: 250px;
  flex-grow: 1;
  padding: 5px 10px;
}
</style>
