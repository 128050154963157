<template>
  <div class="approval-levels">
    <div class="shift-calendar-header my-2">
      <h2>Booking Approval</h2>
    </div>
    <LabeledText
      :label="`Level ${level}`"
      v-for="(approvalLevel, level) in approvalGroups"
      :key="level"
    >
      <template v-slot:icon>
        <v-icon
          v-if="isLevelApproved(approvalLevel)"
          color="success"
          class="mr-1 mt-1"
        >
          mdi-check-circle
        </v-icon>
        <v-icon v-else class="gray mt-2 mr-1">
          mdi-minus-circle
        </v-icon>
      </template>
      <div
        v-for="approver in approvalLevel"
        :key="approver.id"
        class="d-flex justify-space-between"
      >
        <div class="approver_name" :class="{ bold: approver.approvalDate }">
          {{ approver.user | getFullName }}
        </div>
        <v-icon class="ml-1 mt-0" v-if="approver.approvalDate">
          mdi-check
        </v-icon>
      </div>
    </LabeledText>
    <LabeledText v-if="this.approvers.length == 0">
      <em>No approvers</em>
    </LabeledText>
  </div>
</template>

<script>
import LabeledText from "@/components/common/BaseDetails/LabeledText";
import { groupByPropertyName } from "@/utils/bookings";
import { getFullName } from "@/utils/users";
import { some } from "lodash";

export default {
  name: "ApprovalProgress",
  components: { LabeledText },
  props: {
    detailsVisible: Boolean,
    approvers: Array
  },
  computed: {
    approvalGroups() {
      return groupByPropertyName(this.approvers, "levelNbr");
    },
    approvalProgress() {
      const approvedCount = this.approvers
        .map(approver => approver.approvalDate)
        .filter(Boolean).length;
      return (100 * approvedCount) / this.approvers.length;
    }
  },
  filters: {
    getFullName
  },
  methods: {
    isLevelApproved(approvals) {
      return some(approvals, approval => approval.approvalDate);
    }
  }
};
</script>

<style lang="scss"></style>
