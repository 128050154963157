<template>
  <div class="timesheets-list-filters filters">
    <v-col cols="12">
      <div v-if="isLoading">
        <v-skeleton-loader v-for="i in 10" :key="i" type="list-item" />
      </div>
      <div v-else>
        <label class="font-weight-bold">Week Commencing</label>
        <FilterProperty :selected="filters.before_date">
          <DatePicker
            :inputValue="filters.before_date"
            @updatedDate="value => updateFilterFor('before_date', value)"
            label="Before"
          />
        </FilterProperty>
        <FilterProperty :selected="filters.after_date">
          <DatePicker
            :inputValue="filters.after_date"
            @updatedDate="value => updateFilterFor('after_date', value)"
            label="After"
          />
        </FilterProperty>
        <div
          v-for="(filterOptions, filterKey, index) in availableFilters"
          :key="index"
        >
          <label class="font-weight-bold">
            {{ getFilterTitle(filterKey) }}
          </label>
          <FilterProperty :selected="filters[filterKey]">
            <Select
              item-text="text"
              item-value="value"
              placeholder="Select"
              label=""
              multiple
              :items="filterOptions"
              v-model="filters[filterKey]"
              hide-details
              clearable
            />
          </FilterProperty>
        </div>
        <PrimaryButton @click.native="saveChanges" fullWidth class="mb-2">
          Save Changes
        </PrimaryButton>
      </div>
    </v-col>
  </div>
</template>

<script>
import { omitBy, isNil } from "lodash";
import DatePicker from "@/components/common/DatePicker";
import Select from "@/components/common/Select";
import FilterProperty from "@/components/common/FilterProperty";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import { filterMixin } from "@/mixins/filter.mixin";

export default {
  name: "TimesheetListFilter",
  mixins: [filterMixin],
  components: { FilterProperty, DatePicker, Select, PrimaryButton },
  methods: {
    getFilterTitle(filterName) {
      switch (filterName) {
        case "timesheet_approver_id":
          return "Approver";
        case "timesheet_verifier_id":
          return "Verifier";
        case "root_client_id":
          return "Organisation";
        case "status":
          return "Status";
        default:
          return "";
      }
    },
    saveChanges() {
      const { before_date, after_date } = this.filters;

      const filtersInApiFormat = {
        ...this.filters,
        before_date,
        after_date
      };
      this.$emit("filter", omitBy(filtersInApiFormat, isNil));
    }
  }
};
</script>

<style lang="scss"></style>
