<template>
  <ValidationObserver ref="rejectBookingValidation">
    <BaseDialog :isOpen="isOpen">
      <template v-slot:title>
        <span>Why do you want to reject the booking request?</span>
      </template>
      <template v-slot:body>
        <v-form>
          <div class="reject-booking-request-description">
            <ValidationProvider
              v-slot="{ errors }"
              name="description"
              rules="required"
            >
              <v-textarea
                :error-messages="showValidationErrors ? errors : ''"
                v-model="description"
                outlined
                required
              />
            </ValidationProvider>
          </div>
        </v-form>
      </template>
      <template v-slot:actions>
        <div class="reject-booking-request-actions">
          <PrimaryButton @click.native="cancel" text>
            Cancel
          </PrimaryButton>
          <ErrorButton @click.native="handleSubmit" :loading="isLoading">
            Reject Booking
          </ErrorButton>
        </div>
      </template>
    </BaseDialog>
  </ValidationObserver>
</template>

<script>
import BaseDialog from "@/components/common/BaseDialog";
import ErrorButton from "@/components/common/Button/ErrorButton";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import { createNamespacedHelpers } from "vuex";
import {
  ADD_COMMENT,
  BOOKINGS_NAMESPACE,
  REJECT_BOOKING
} from "@/store/modules/bookings/actions";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { BOOKING_MODEL } from "@/models/booking-model";

const { mapActions } = createNamespacedHelpers(BOOKINGS_NAMESPACE);

export default {
  name: "RejectBookingDialog",
  components: {
    PrimaryButton,
    ErrorButton,
    BaseDialog,
    ValidationObserver,
    ValidationProvider
  },
  data() {
    return {
      showValidationErrors: false,
      description: "",
      isLoading: false
    };
  },
  props: {
    isOpen: Boolean
  },
  inject: ["currentBookingId"],
  methods: {
    ...mapActions({
      rejectBooking: REJECT_BOOKING,
      addComment: ADD_COMMENT
    }),
    async handleSubmit() {
      this.showValidationErrors = true;
      const isFormValid = await this.$refs.rejectBookingValidation.validate();
      if (isFormValid) {
        this.isLoading = true;
        try {
          await this.rejectBooking(this.currentBookingId);
          await this.addComment({
            bookingId: this.currentBookingId,
            comment: {
              body: this.description,
              commentableType: BOOKING_MODEL,
              commentableId: this.currentBookingId
            }
          });
          this.$emit("close");
          this.$emit("rejected");
        } finally {
          this.isLoading = false;
          this.description = "";
        }
      }
    },
    cancel() {
      this.description = "";
      this.$refs.rejectBookingValidation.reset();
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss">
.reject-booking-request-actions {
  padding: 0.5rem;
}
</style>
