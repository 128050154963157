<template>
  <div class="report-filter">
    <h2 class="mb-2">Filters</h2>
    <v-list>
      <v-list-item
        class="my-2"
        v-for="(filter, index) in filters"
        :key="index"
        :class="{ 'd-flex flex-column': isMobile }"
      >
        <ReportFilter
          :index="index"
          :filter="filter"
          :availableFields="patchAvailableFields(filter.field)"
          :showValidationErrors="showValidationErrors"
          :focus="focus"
          :labels="labels"
          :onChange="handleChange(index)"
          :onDelete="handleDelete(index)"
        />
        <div class="separator" v-if="isMobile" />
      </v-list-item>
    </v-list>
    <v-btn
      v-if="availableFields.length"
      @click="addFilter"
      text
      color="primary"
      class="add-item my-2"
    >
      Add filters +
    </v-btn>
  </div>
</template>

<script>
import { getDefaultFilterValue } from "@/utils/reports";
import ReportFilter from "@/views/reports/forms/ReportFilter";

export default {
  name: "ReportFilterSection",
  components: {
    ReportFilter
  },
  props: {
    filters: Array,
    fields: Array,
    showValidationErrors: Boolean,
    labels: Object,
    focus: String
  },
  computed: {
    availableFields() {
      return this.fields.filter(
        field =>
          !this.filters.find(filter => filter.field === field.field) &&
          field.operators &&
          field.operators.length
      );
    }
  },
  methods: {
    addFilter() {
      if (this.availableFields.length > 0) {
        const { field, type, operators } = this.availableFields[0];
        const operator = operators && operators[0];
        this.filters.push({
          field,
          operator,
          ...getDefaultFilterValue({
            fieldType: type,
            filterType: operator
          })
        });
      }
    },
    handleChange(index) {
      return newFilter => {
        this.filters.splice(index, 1, newFilter);
      };
    },
    handleDelete(index) {
      return () => {
        this.filters.splice(index, 1);
      };
    },
    patchAvailableFields(fieldName) {
      return [
        this.fields.find(field => field.field === fieldName),
        ...this.availableFields
      ];
    }
  },
  watch: {
    filters(newValue) {
      this.$emit("updated", newValue);
    }
  }
};
</script>

<style scoped lang="scss">
.report-filter {
  .separator {
    height: 1px;
    width: 100%;
    margin: 10px 0;
    background-color: $default-line-transparent;
  }
}
</style>
