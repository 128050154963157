/* eslint-disable prettier/prettier */
export const GET_BOOKINGS = "GET_BOOKINGS";
export const GET_BOOKING_SHIFTS = "GET_BOOKING_SHIFTS";
export const IS_LOADING_BOOKINGS = "IS_LOADING_BOOKINGS";
export const GET_CURRENT_BOOKING = "GET_CURRENT_BOOKING";
export const GET_CURRENT_BOOKING_CANDIDATES = "GET_CURRENT_BOOKING_CANDIDATES";
export const GET_CURRENT_BOOKING_COMMENTS = "GET_CURRENT_BOOKING_COMMENTS";
export const GET_CURRENT_BOOKING_SHIFT_PATTERNS = "GET_CURRENT_BOOKING_SHIFT_PATTERNS";
export const GET_CURRENT_BOOKING_EVENTS = "GET_CURRENT_BOOKING_EVENTS";
export const GET_BOOKING_PAYRATES = "GET_BOOKING_PAYRATES";
export const GET_CURRENT_BOOKING_PUBLISHING_SCHEDULE = "GET_CURRENT_BOOKING_PUBLISHING_SCHEDULE";
export const IS_LOADING_BOOKING = "IS_LOADING_BOOKING";
export const IS_LOADING_BOOKING_CANDIDATES = "IS_LOADING_BOOKING_CANDIDATES";
export const IS_LOADING_BOOKING_COMMENTS = "IS_LOADING_BOOKING_COMMENTS";
export const IS_LOADING_BOOKING_SHIFT_PATTERNS = "IS_LOADING_BOOKING_SHIFT_PATTERNS";
export const IS_LOADING_BOOKING_EVENTS = "IS_LOADING_BOOKING_EVENTS";
export const IS_LOADING_BOOKING_SHIFTS = "IS_LOADING_BOOKING_SHIFTS";
export const IS_LOADING_BOOKING_PUBLISHING_SCHEDULE = "IS_LOADING_BOOKING_PUBLISHING_SCHEDULE";
export const IS_LOADING_BOOKING_PAYRATES = "IS_LOADING_BOOKING_PAYRATES";
export const IS_EDITING_BOOKING_PAYRATES = "IS_EDITING_BOOKING_PAYRATES";
export const GET_ACTIVITIES = "GET_ACTIVITIES";
export const GET_CANDIDATES_FOR_SUBMIT = "GET_CANDIDATES_FOR_SUBMIT";
export const SET_IS_FETCHING_CANDIDATES_FOR_SUBMIT = "SET_IS_FETCHING_CANDIDATES_FOR_SUBMIT";
export const GET_TOTAL_BOOKING_SHIFTS = 'GET_TOTAL_BOOKING_SHIFTS';
export const GET_BOOKING_LIST_FILTERS = 'GET_BOOKING_LIST_FILTERS';
export const GET_BUDGETS = 'GET_BUDGETS';
export const GET_CURRENT_PAY_RATES = 'GET_CURRENT_PAY_RATES';
import { camelize } from "@/utils/helpers";

const bookingsGetters = {
  [GET_BOOKINGS]: state => state.bookings,
  [GET_BOOKING_SHIFTS]: state => camelize(state.bookingShifts),
  [GET_TOTAL_BOOKING_SHIFTS]: state => state.totalBookingShifts,
  [GET_BOOKING_PAYRATES]: state => state.bookingPayRates,
  [GET_CURRENT_BOOKING]: state => state.currentBooking,
  [GET_CURRENT_BOOKING_CANDIDATES]: state => state.currentBookingCandidates,
  [GET_CURRENT_BOOKING_COMMENTS]: state => state.bookingComments,
  [GET_CURRENT_BOOKING_SHIFT_PATTERNS]: state => state.bookingShiftPatterns,
  [GET_CURRENT_BOOKING_EVENTS]: state => state.currentBookingEvents,
  [GET_CURRENT_BOOKING_PUBLISHING_SCHEDULE]: state => state.currentBookingPublishingSchedule,
  [IS_LOADING_BOOKING]: state => state.isFetchingCurrentBooking,
  [IS_LOADING_BOOKING_SHIFTS]: state => state.isFetchingBookingShifts,
  [IS_LOADING_BOOKING_COMMENTS]: state => state.isFetchingBookingComments,
  [IS_LOADING_BOOKING_CANDIDATES]: state => state.isFetchingCurrentBookingCandidates,
  [IS_LOADING_BOOKING_SHIFT_PATTERNS]: state => state.isFetchingBookingShiftPatterns,
  [IS_LOADING_BOOKING_EVENTS]: state => state.isFetchingBookingEvents,
  [IS_LOADING_BOOKING_PUBLISHING_SCHEDULE]: state => state.isFetchingBookingPublishingSchedule,
  [IS_LOADING_BOOKINGS]: state => state.isFetchingBookings,
  [IS_LOADING_BOOKING_PAYRATES]: state => state.isFetchingBookingPayRates,
  [IS_EDITING_BOOKING_PAYRATES]: state => state.isEditingBookingPayRates,
  [IS_EDITING_BOOKING_PAYRATES]: state => state.isEditingBookingPayRates,
  [GET_ACTIVITIES]: state => state.bookingPayRates.map(payRate => payRate.activityTypeName),
  [GET_CURRENT_PAY_RATES]: state => state.bookingPayRates,
  [GET_CANDIDATES_FOR_SUBMIT]: state => state.candidatesForSubmit,
  [SET_IS_FETCHING_CANDIDATES_FOR_SUBMIT]: state => state.isFetchingCandidatesForSubmit,
  [GET_BOOKING_LIST_FILTERS]: state => state.bookingListFilters,
  [GET_BUDGETS]: state => state.budgets,
};

export default bookingsGetters;
