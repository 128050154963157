import ReportTabs from "@/views/reports/ReportTabs.vue";
import ReportDetails from "@/views/reports/ReportDetails.vue";
import ReportDataTable from "@/views/reports/tabs/ReportDataTable.vue";
import ReportLineChart from "@/views/reports/tabs/ReportLineChart.vue";
import ReportDonutChart from "@/views/reports/tabs/ReportDonutChart.vue";
import ReportBarChart from "@/views/reports/tabs/ReportBarChart.vue";
import { redirectWithPermissionCheck } from "@/router/index";
import { MAIN_NAVIGATION_ITEMS } from "@/router/constants";

const FOCUSED_REPORT = "focused-reports.view";
const { REPORTS } = MAIN_NAVIGATION_ITEMS;

export const reportsRouter = [
  {
    path: "/reports",
    name: REPORTS,
    component: ReportTabs,
    beforeEnter: (to, from, next) => {
      return redirectWithPermissionCheck(next, FOCUSED_REPORT);
    }
  },
  {
    path: "/reports/:reportId",
    name: "reportDetails",
    redirect: "/reports/:reportId/table-data",
    component: ReportDetails,
    beforeEnter: (to, from, next) => {
      return redirectWithPermissionCheck(next, FOCUSED_REPORT);
    },
    children: [
      {
        path: "table-data",
        name: "reportDataTable",
        component: ReportDataTable,
        beforeEnter: (to, from, next) => {
          return redirectWithPermissionCheck(next, FOCUSED_REPORT);
        }
      },
      {
        path: "bar-chart",
        name: "reportBarChart",
        component: ReportBarChart,
        beforeEnter: (to, from, next) => {
          return redirectWithPermissionCheck(next, FOCUSED_REPORT);
        }
      },
      {
        path: "line-chart",
        name: "reportLineChart",
        component: ReportLineChart,
        beforeEnter: (to, from, next) => {
          return redirectWithPermissionCheck(next, FOCUSED_REPORT);
        }
      },
      {
        path: "donut-chart",
        name: "reportDonutChart",
        component: ReportDonutChart,
        beforeEnter: (to, from, next) => {
          return redirectWithPermissionCheck(next, FOCUSED_REPORT);
        }
      }
    ]
  }
];
