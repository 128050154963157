/* eslint-disable no-unexpected-multiline */
import JSONApiService from "@/services/JSONApiService";
import { EXPORT_MODEL } from "@/models/export-model";
import { USER_EXPORT_MODEL } from "@/models/user-export-model";

const exportsApi = ({ commit } = {}) => ({
  getReports: async () => JSONApiService(commit).findAll(EXPORT_MODEL),
  getReport: async (id, params) =>
    JSONApiService(commit)
      .one(EXPORT_MODEL, id)
      .get({ ...params }),
  getUserReports: async () => JSONApiService(commit).findAll(USER_EXPORT_MODEL),
  deleteUserReport: async id =>
    JSONApiService(commit)
      .one(USER_EXPORT_MODEL, id)
      .destroy()
});

export default exportsApi;
