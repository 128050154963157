<template>
  <div class="cost-estimate">
    <Permissioned section="bookings.view" vertical>
      <HourlyCost permission="worker-cost" />
    </Permissioned>
  </div>
</template>

<script>
import HourlyCost from "@/views/bookings/BookingDetails/components/CostEstimate/components/HourlyCost";
import Permissioned from "@/components/common/Permissioned";

export default {
  name: "CostEstimate",
  components: { Permissioned, HourlyCost }
};
</script>
<style lang="scss"></style>
