import { size, toLower, includes, filter } from "lodash";

export const ORGANISATION_UNIT_STATUSES = {
  DRAFT: "draft",
  ARCHIVED: "archived"
};

export const getColorByStatus = status => {
  switch (status.toLowerCase()) {
    case ORGANISATION_UNIT_STATUSES.ARCHIVED:
      return "red";
    case ORGANISATION_UNIT_STATUSES.DRAFT:
    default:
      return "gray";
  }
};

export const searchDeep = (searchText, unit) => {
  if (includes(toLower(unit.name), toLower(searchText))) {
    return true;
  }
  unit.organisationUnits = filter(unit.organisationUnits, childUnit =>
    searchDeep(searchText, childUnit)
  );

  return size(unit.organisationUnits);
};

export const filterDeep = (units, searchText) =>
  filter(units, unit => searchDeep(searchText, unit));

export const getOrganisationsAsTreeStructure = units => {
  return nest(units);
};

export const nest = (items, id = null, link = "parentId") =>
  items
    .filter(item => item[link] === id)
    .map(item => ({
      ...item,
      organisationUnits: nest(items, item.id)
    }));
