<template>
  <div class="pl-6 my-2" @click="$emit('select')">
    <div>
      <EntryListItem
        class="my-1"
        :worker="worker"
        :status="status"
        :showDiscrepancy="showDiscrepancy"
      />
    </div>
    <div class="pl-6 d-flex align-end" v-if="comment">
      <inline-svg
        class="mb-1"
        width="35"
        :src="require('@/assets/svg/comment-icon.svg')"
      />
      <p class="mx-1 mb-0 p-1 comment">
        {{ comment.author | fullName }} - {{ comment.body }}
      </p>
    </div>
  </div>
</template>

<script>
import EntryListItem from "@/views/timesheets/details/components/EntryListItem";
import { FETCH_CURRENT_TIMESHEET_COMMENTS } from "@/store/modules/timesheets/actions";
import { TIMESHEETS_NAMESPACE } from "@/store/modules/timesheets";
import { createNamespacedHelpers } from "vuex";
import { last } from "lodash";
import InlineSvg from "vue-inline-svg";

const { mapActions } = createNamespacedHelpers(TIMESHEETS_NAMESPACE);

export default {
  props: {
    worker: {
      type: Object,
      required: true
    },
    status: String
  },
  components: { EntryListItem, InlineSvg },
  data() {
    return {
      comment: ""
    };
  },
  async mounted() {
    const { data: comments } = await this.fetchCurrentTimesheetComments(
      this.worker.timesheetId
    );
    this.comment = last(comments);
  },
  computed: {
    entries() {
      return this.worker.entries;
    },
    showDiscrepancy() {
      return this.status !== "draft";
    }
  },
  methods: {
    ...mapActions({
      fetchCurrentTimesheetComments: FETCH_CURRENT_TIMESHEET_COMMENTS
    })
  }
};
</script>

<style lang="scss">
p.comment {
  background: #fffee5;
  padding: 8px;
}
</style>
