export const REJECTION_REASON_MODEL = "application-rejection-reason";

const rejectionReasonModel = {
  reason: ""
};

export const rejectionReasonEndpoints = {
  REASONS_FOR_REJECTION: "rejection-reason"
};

export default rejectionReasonModel;
