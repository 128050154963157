export const APPLICATION_SHIFTS_STATUS_MODEL = "application-shifts-status";

const applicationShiftsStatusModel = {
  status: "",
  applicationShifts: [],
  booking: {
    jsonApi: "hasOne",
    type: "bookings"
  }
};

export default applicationShiftsStatusModel;
