<template>
  <div class="submit-worker-details">
    <div v-if="!comparingMode">
      <div class="d-flex align-center">
        <span class="tag mr-2">C-{{ worker.id }}</span>
        <Chip shrink :status="application.status">
          {{ application.status }}
        </Chip>
        <p class="secondary-text mb-0 ml-2" v-if="application.rejectionReason">
          {{ application.rejectionReason }}
        </p>
      </div>
      <h2 class="mb-1">{{ candidate }}</h2>
      <div class="text-primary" v-if="showContactDetails">
        {{ worker.email }}
        |
        <a :href="tel">{{ worker.phone }}</a>
      </div>
    </div>
    <CandidateDetails
      :application="application"
      :comparingMode="comparingMode"
    />
  </div>
</template>

<script>
import CandidateDetails from "@/views/bookings/BookingDetails/components/CandidateDetails/CandidateDetails";
import { getFullName } from "@/utils/users";
import Chip from "@/components/common/Chip";

export default {
  name: "CandidateDetailsDesktop",
  components: {
    CandidateDetails,
    Chip
  },
  data() {
    return {
      editReferenceNumber: false,
      editAwrStatus: false,
      files: [],
      workerAddress: null,
      isAddingComment: false
    };
  },
  computed: {
    worker() {
      return this.application.user || {};
    },
    showCandidateName() {
      return Boolean(this.worker.firstName) && Boolean(this.worker.lastName);
    },
    candidate() {
      return this.showCandidateName
        ? getFullName(this.worker)
        : `Candidate ${this.application.id}`;
    },
    showContactDetails() {
      return this.worker.email || this.worker.phone;
    },
    tel() {
      return `tel:${this.worker.phone}`;
    },
    mailto() {
      return `mailto:${this.worker.email}`;
    }
  },
  props: {
    comparingMode: Boolean,
    application: Object
  }
};
</script>

<style lang="scss">
.submit-worker-details {
  padding-bottom: 150px;
  overflow: auto;
  top: 0;
  background-color: $white;

  > div {
    margin-bottom: 1rem;
  }

  h3 {
    margin-bottom: 0;
  }

  .tag {
    color: $secondary-text;
    @include mono;
  }

  .reference-number {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
</style>
