<template>
  <BaseAction>
    <SecondaryButton @click.native="openSubmitWorkers" color="primary">
      <div>
        <v-icon>mdi-account-group-outline</v-icon>
        <v-icon>mdi-arrow-right</v-icon>
      </div>
      Submit Workers
    </SecondaryButton>
    <SubmitWorkers
      v-if="submitWorkersOpen"
      :isFixedTerm="isFixedTerm"
      :isOpen="submitWorkersOpen"
      @close="closeSubmitWorkers"
    />
  </BaseAction>
</template>

<script>
import SubmitWorkers from "@/views/bookings/components/SubmitWorkers/SubmitWorkers";
import BaseAction from "@/components/common/BaseAction";
import SecondaryButton from "@/components/common/Button/SecondaryButton";

export default {
  name: "SubmitWorkerAction",
  props: {
    isFixedTerm: {
      type: Boolean,
      default: false
    }
  },
  components: { BaseAction, SecondaryButton, SubmitWorkers },
  data() {
    return {
      submitWorkersOpen: false
    };
  },
  methods: {
    openSubmitWorkers() {
      this.submitWorkersOpen = true;
    },
    closeSubmitWorkers() {
      this.submitWorkersOpen = false;
    }
  }
};
</script>

<style scoped></style>
