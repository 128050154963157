<template>
  <div class="inline-filters" v-if="!isLoading">
    <div
      v-for="(filterOptions, filterKey, index) in availableFilters"
      :key="index"
    >
      <Permissioned section="bookings.filters">
        <InlineFilter
          v-if="size(filterOptions)"
          :label="getFilterTitle(filterKey)"
          :filters="filters[filterKey]"
          :options="filterOptions"
          @clear="handleClear(filterKey)"
        >
          <RadioButton
            v-if="filterKey === 'shifts'"
            :options="shiftOptions(filterOptions, filterKey)"
            @select="values => updateFilterFor(filterKey, values)"
            :selected-value="filters[filterKey]"
          />
          <FilterCheckbox
            v-if="filterKey != 'shifts'"
            :options="getFilterOptions(filterOptions, filterKey)"
            :selectedValues="filters[filterKey]"
            @change="values => updateFilterFor(filterKey, values)"
          />
        </InlineFilter>
        <InlineFilter
          permission="date-created"
          v-if="index === size(availableFilters) - 1"
          label="Start Date"
          :filters="[filters.after_date, filters.before_date]"
          @clear="clearDates"
          isDate
          rangeDate
        >
          <DatePicker
            :inputValue="filters.after_date"
            @updatedDate="value => updateFilterFor('after_date', value)"
            label="After"
          />
          <DatePicker
            :inputValue="filters.before_date"
            @updatedDate="value => updateFilterFor('before_date', value)"
            label="Before"
          />
        </InlineFilter>
      </Permissioned>
    </div>
  </div>
</template>

<script>
import DatePicker from "@/components/common/DatePicker";
import Permissioned from "@/components/common/Permissioned";
import { size, map } from "lodash";
import { filterMixin } from "@/mixins/filter.mixin";
import RadioButton from "../../components/common/RadioButton";

export default {
  components: {
    RadioButton,
    Permissioned,
    DatePicker
  },
  mixins: [filterMixin],
  props: {
    isLoading: Boolean,
    outerSelectedFilters: {
      type: Object,
      default: () => ({})
    },
    filterLabels: {
      type: Object,
      default: () => ({})
    },
    availableFilters: Object
  },
  methods: {
    getFilterTitle(filterName) {
      const label = this.filterLabels[filterName];
      if (label) {
        return label;
      }
      switch (filterName) {
        case "location_id":
          return "Location";
        case "cost_code":
          return "Cost Code";
        case "timesheet_approver_id":
          return "Timesheet Approver";
        case "timesheet_verifier_id":
          return "Timesheet Verifier";
        case "root_client_id":
          return "Client";
        case "manager_id":
          return "Hiring Manager";
        case "status":
          return "Status";
        case "shifts":
          return "Shifts Filled";
        case "client_id":
          return "Org Unit";
        default:
          return filterName;
      }
    },
    shiftOptions(options, filterName) {
      return filterName === "shifts"
        ? map(options, option => {
            return { label: option.text, value: option.value };
          })
        : options;
    },
    clearDates() {
      this.filters.after_date = null;
      this.filters.before_date = null;
      this.saveChanges();
    },
    size
  }
};
</script>
