<template>
  <div :key="field">
    <v-text-field
      v-if="isTextField"
      placeholder="Value"
      min="0"
      :type="isNumber ? 'number' : 'text'"
      :errorMessages="errorMessages"
      v-model="selectedValue"
      dense
    />
    <v-text-field
      v-else-if="isCurrency"
      placeholder="Value"
      min="0"
      type="text"
      :errorMessages="errorMessages"
      prefix="£"
      v-model="selectedValue"
      v-money="{ precision: 2 }"
      dense
    />
    <Select
      v-else
      class="fixed-height"
      placeholder="Value"
      :items="fieldValues"
      v-model="selectedValue"
      :loading="isFetchingFieldValues"
      :errorMessages="errorMessages"
      autocomplete
      multiple
      chips
    />
  </div>
</template>

<script>
import Select from "@/components/common/Select";
import APIService from "@/services/APIService";
import { debounce, orderBy } from "lodash";

export default {
  name: "ReportSingleInputFilter",
  props: {
    value: String,
    onChange: Function,
    fieldType: String,
    filterType: String,
    focus: String,
    field: String,
    errorMessages: [String, Array]
  },
  components: { Select },
  data() {
    return {
      fieldValues: [],
      isFetchingFieldValues: false
    };
  },
  created() {
    if (this.field && !this.isNumber) {
      this.fetchFieldValues(this.field);
    }
  },
  computed: {
    isNumber() {
      return (
        this.fieldType === "number" ||
        (this.fieldType === "id" &&
          ["gt", "gte", "lt", "lte"].includes(this.filterType))
      );
    },
    isCurrency() {
      return this.fieldType === "currency";
    },
    isTextField() {
      return (
        ["contains", "not_contains", "gt", "gte", "lt", "lte"].includes(
          this.filterType
        ) ||
        (this.isNumber && !this.isCurrency)
      );
    },
    selectedValue: {
      get() {
        return this.value && !this.isCurrency
          ? this.value.split(" | ")
          : this.value || "";
      },
      set: debounce(function(newValue) {
        if (this.isNumber && Number(newValue) < 0) {
          this.onChange(0);
        } else {
          this.onChange(newValue);
        }
      }, 200)
    }
  },
  methods: {
    async fetchFieldValues(field) {
      this.fieldValues = [];
      this.isFetchingFieldValues = true;
      const options = await APIService.client().get(
        `focused-reports/field-values/${this.focus}/${field}`
      );
      this.fieldValues = orderBy(
        field.includes("id") ? options.map(option => String(option)) : options
      );
      this.isFetchingFieldValues = false;
    }
  }
};
</script>
