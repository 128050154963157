<template>
  <BaseButton
    :disabled="disabled"
    color="primary"
    :text="text"
    :loading="loading"
    class="base-primary-button"
    :class="{ light }"
    :dense="dense"
    :fullWidth="fullWidth"
    :shrink="shrink"
    @click="handleClick"
  >
    <slot name="prepend:icon" />
    <slot />
    <slot name="append:icon" />
  </BaseButton>
</template>

<script>
import BaseButton from "@/components/common/Button/BaseButton";

export default {
  name: "PrimaryButton",
  components: { BaseButton },
  props: {
    disabled: Boolean,
    loading: Boolean,
    text: Boolean,
    light: Boolean,
    dense: Boolean,
    fullWidth: Boolean,
    shrink: Boolean
  },
  methods: {
    handleClick(event) {
      this.$emit("click", event);
    }
  }
};
</script>
