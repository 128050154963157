<template>
  <div>
    <v-skeleton-loader
      v-for="i in count"
      :key="i"
      :width="width"
      type="list-item-avatar-two-line"
    />
  </div>
</template>

<script>
export default {
  name: "SkeletonLoader",
  props: {
    type: {
      type: String,
      required: true
    },
    count: {
      type: Number,
      default: 3
    },
    width: {
      type: String,
      default: "100%"
    }
  }
};
</script>
