<template>
  <div>
    <div v-if="readonly">
      <FileComponent
        :isRemoving="isLoading"
        class="py-2"
        v-for="file in files"
        :key="file.id"
        :file="file"
        disabled
        @show="onShow(file)"
        customActions
      />
    </div>
    <FileUpload
      v-else
      @removeFile="onRemoveFile"
      @show="onShow"
      @selectFile="selectFile"
      @onUploadSuccess="updateFiles"
      :uploadingUrl="getAddFileUrl"
      :fileTypes="[
        '.pdf',
        '.jpeg',
        '.jpg',
        '.png',
        '.doc',
        '.docx',
        '.xlsx',
        '.ppt'
      ]"
      :files="files"
      :id="bookingId"
      :isLoading="isLoading"
      customActions
      isMultiple
    />
    <PDFViewFull :data="fileDialog" />
  </div>
</template>

<script>
import FileUpload from "@/components/common/FileUpload";
import APIService from "@/services/APIService";
import { mapActions } from "vuex";
import PDFViewFull from "@/components/common/PDFViewFull";
import { getToken } from "@/services/utils";
import { REMOVE_FILE } from "@/store/modules/data/actions";
import { filter } from "lodash";
import FileComponent from "@/components/common/FileComponent";
import { isPermissioned } from "@/utils/permissions";

export default {
  props: {
    /**
     *  Data object
     */
    bookingFiles: {
      type: Array,
      required: true
    },
    bookingId: String
  },
  components: {
    FileUpload,
    PDFViewFull,
    FileComponent
  },
  data() {
    return {
      isLoading: false,
      fileDialog: {
        dialog: false
      },
      files: this.bookingFiles
    };
  },
  computed: {
    getAddFileUrl() {
      const host = APIService.host;
      return `${host}/bookings/${this.bookingId}/file`;
    },
    readonly() {
      return !isPermissioned(`bookings.edit.files`);
    }
  },
  methods: {
    ...mapActions("data", {
      removeFile: REMOVE_FILE
    }),
    selectFile({ uppyInstance, file }) {
      uppyInstance.setFileState(file.id, {
        xhrUpload: { endpoint: this.getAddFileUrl }
      });
    },
    async onRemoveFile({ file: fileToBeRemoved }) {
      this.files = filter(this.files, file => file.id !== fileToBeRemoved.id);
      this.isLoading = true;
      if (fileToBeRemoved.url) {
        await this.removeFile(fileToBeRemoved.url);
      }
      this.isLoading = false;
    },
    updateFiles(files) {
      this.files = files;
    },
    handleChange() {
      this.$emit("onChange", this.currentWorker);
    },
    async onShow(file) {
      const token = getToken();
      this.fileDialog.src = `${file.url}?token=${token}`;
      this.fileDialog.title = file.fileName;
      this.fileDialog.dialog = true;
    }
  }
};
</script>
