import { capitalize, startCase, upperCase, lowerCase } from "lodash";

export const capitalizeFilter = value => {
  return capitalize(value);
};

export const startCaseFilter = value => {
  return startCase(value);
};

export const upperCaseFilter = value => {
  return upperCase(value);
};

export const lowerCaseFilter = value => {
  return lowerCase(value);
};
