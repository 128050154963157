<template>
  <ReportWrapper
    :sections="sections"
    :data="data"
    @changeTab="key => $emit('changeTab', key)"
    @downloadCSV="$emit('downloadCSV')"
  >
    <template v-slot:content>
      <div class="report-chart" v-resize="onResize">
        <apexchart
          :width="isMobile ? chartWidth : chartWidth / 1.5"
          :options="chartOptions"
          :series="formattedSeries"
          v-if="withGroupings"
        />
        <h3 v-else class="my-10">
          Please include more data in your report to be able to generate a chart
        </h3>
      </div>
    </template>
    <template v-slot:actions>
      <div
        class="d-flex align-center flex-wrap actions-bar"
        v-if="yAxisOptions.length > 1"
      >
        <strong class="mx-2">Values</strong>
        <Select
          label=""
          :items="yAxisOptions"
          v-model="selectedYAxisOption"
          @change="val => handleOptionChanged('yAxisFieldId', val)"
          item-text="label"
          item-value="value"
        />
      </div>
    </template>
  </ReportWrapper>
</template>

<script>
import { REPORTS_NAMESPACE } from "@/store/modules/reports";
import { createNamespacedHelpers } from "vuex";
import {
  CREATE_REPORT_CHART,
  EDIT_REPORT_CHART
} from "@/store/modules/reports/action-types";
import { SET_CURRENT_REPORT_CHART } from "@/store/modules/reports/mutation-types";
import { first, find, map, flatten } from "lodash";
import Select from "@/components/common/Select";
import { reportChartMixin } from "@/mixins/report-chart.mixin";
import ReportWrapper from "@/views/reports/tabs/ReportWrapper";

const { mapActions, mapState, mapMutations } = createNamespacedHelpers(
  REPORTS_NAMESPACE
);

export default {
  name: "DonutChart",
  components: {
    Select,
    ReportWrapper
  },
  mixins: [reportChartMixin],
  props: {
    data: Array,
    report: Object,
    withGroupings: Boolean
  },
  async created() {
    const currentReportChart = find(this.reportCharts, { chartType: "donut" });
    this.setCurrentReportChart(currentReportChart);
    if (!currentReportChart) {
      const { groupings, fields } = this.report;
      await this.createChart({
        title: this.report.name,
        chartType: "donut",
        xAxisPrimaryGroupingId: first(groupings).id,
        ...(groupings[1] && { xAxisSecondaryGroupingId: groupings[1].id }),
        yAxisFieldId: first(fields).id
      });
    }
    this.adjustChartOptions(this.currentChart, this.report);
    this.adjustChartData(this.currentChart, this.report);
  },
  computed: {
    ...mapState({
      reportCharts: state => state.reportCharts,
      currentChart: state => state.currentReportChart
    }),
    formattedSeries() {
      return map(flatten(map(this.series, ({ data }) => data)), item =>
        Number(item)
      );
    },
    chartOptions() {
      return {
        chart: {
          id: "reports-donut-chart",
          type: "donut",
          toolbar: this.getExportOptions(this.report)
        },
        labels: this.categories,
        legend: {
          position: "bottom",
          horizontalAlign: "left",
          fontFamily:
            "-apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica",
          fontSize: "18px",
          labels: {
            colors: ["#000"]
          },
          itemMargin: {
            horizontal: 5,
            vertical: 10
          }
        },
        dataLabels: {
          formatter: (value, { seriesIndex, w }) => {
            return this.getLabelValue(w.config.series[seriesIndex]) && value
              ? `${Math.round(Number(value))}%
              (${this.getLabelValue(w.config.series[seriesIndex])})`
              : `0%`;
          },
          style: {
            fontSize: "18px",
            fontFamily:
              "'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace",
            fontWeight: "bold",
            colors: ["#000"]
          },
          background: {
            enabled: true,
            foreColor: "#fff",
            padding: 6,
            borderWidth: 0,
            borderRadius: 4
          },
          dropShadow: {
            enabled: false
          }
        },
        tooltip: {
          y: {
            formatter: value => {
              return this.getLabelValue(value);
            }
          }
        }
      };
    }
  },
  methods: {
    ...mapMutations({
      setCurrentReportChart: SET_CURRENT_REPORT_CHART
    }),
    ...mapActions({
      createChart: CREATE_REPORT_CHART,
      editChart: EDIT_REPORT_CHART
    }),
    async handleOptionChanged(key, value) {
      await this.editChart({
        reportId: this.currentChart.id,
        data: {
          [key]: value
        }
      });
      this.adjustChartOptions(this.currentChart, this.report);
      this.adjustChartData(this.currentChart, this.report);
    }
  }
};
</script>

<style lang="scss"></style>
