<template>
  <div v-if="items.length">
    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <v-btn @click.native.stop icon v-on="on">
          <v-icon>mdi-dots-horizontal</v-icon>
        </v-btn>
      </template>
      <MenuList :items="items" @select="handleSelect" />
    </v-menu>
  </div>
</template>

<script>
import MenuList from "./MenuList";

export default {
  name: "DotMenu",
  components: { MenuList },
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  methods: {
    handleSelect(action) {
      this.$emit("select", action);
    }
  }
};
</script>
