<template>
  <div class="booking-details-wrapper">
    <v-row
      class="booking-details"
      :class="{ 'base-footer-spacing': showBaseFooter }"
    >
      <v-col class="col-12">
        <div class="mt-2" v-if="isLoadingBooking">
          <div v-for="i in 3" :key="i">
            <v-skeleton-loader width="100%" type="card-heading" />
            <v-skeleton-loader
              v-for="i in 4"
              :key="i"
              width="100%"
              type="heading, list-item"
            />
          </div>
        </div>
        <Permissioned section="bookings.view" vertical v-else>
          <Comment
            permission="comments"
            :comments="comments"
            :is-loading="isLoadingComments"
            @comment="createNewComment"
            :minimizable="false"
          />
          <v-divider permission="comments" />
          <BasicDetails :isFixedTerm="isFixedTerm" />
          <v-divider />
          <InternalInformation />
          <v-divider v-if="showApprovers" />
          <Permissioned section="bookings" vertical>
            <ApprovalProgress
              class="flex-column"
              permission="view.shift-pattern-approval-status"
              :approvers="currentBooking.bookingApprovers"
              :details-visible="true"
              v-if="showApprovers"
            />
          </Permissioned>
          <v-divider v-if="!isFixedTerm" />
          <ShiftCalendar v-if="!isFixedTerm" />
          <v-divider permission="publishing-schedule" />
          <PublishingSchedule
            permission="publishing-schedule"
            class="mb-4 pb-4 base-border-bottom"
          />
          <v-divider />
          <CostEstimate />
        </Permissioned>
      </v-col>
    </v-row>
    <BaseFooter v-if="showBaseFooter && !isLoadingBooking">
      <ExpandedButtons>
        <Permissioned
          class="d-flex actions-bar"
          :vertical="isMobile"
          section="bookings"
        >
          <DeleteBookingAction permission="delete" />
          <CancelBookingAction permission="cancel" />
          <SubmitForApprovalAction permission="submit-for-approval" />
          <RejectBookingAction permission="reject" @reject="handleReject" />
          <ApproveBookingAction
            permission="approve"
            @approved="reloadBooking"
          />
          <AcceptBookingAction permission="accept" />
          <SubmitWorkerAction
            :isFixedTerm="isFixedTerm"
            permission="submit-worker"
          />
          <ReopenBookingAction permission="reopen" />
        </Permissioned>
      </ExpandedButtons>
    </BaseFooter>
  </div>
</template>
<script>
import BasicDetails from "@/views/bookings/BookingDetails/components/BasicDetails";
import InternalInformation from "@/views/bookings/BookingDetails/components/InternalInformation";
import PublishingSchedule from "@/views/bookings/BookingDetails/components/PublishingSchedule";
import Comment from "@/components/common/Comment";
import CostEstimate from "@/views/bookings/BookingDetails/components/CostEstimate/CostEstimate";
import ShiftCalendar from "@/views/bookings/BookingDetails/components/ShiftCalendar";
import BaseFooter from "@/components/common/BaseFooter";
import Permissioned from "@/components/common/Permissioned";
import DeleteBookingAction from "@/views/bookings/components/actions/DeleteBookingAction";
import SubmitForApprovalAction from "@/views/bookings/components/actions/SubmitForApprovalAction";
import RejectBookingAction from "@/views/bookings/components/actions/RejectBookingAction";
import ApproveBookingAction from "@/views/bookings/components/actions/ApproveBookingAction";
import AcceptBookingAction from "@/views/bookings/components/actions/AcceptBookingAction";
import SubmitWorkerAction from "@/views/bookings/components/actions/SubmitWorkerAction";
import CancelBookingAction from "@/views/bookings/components/actions/CancelBookingAction";
import ApprovalProgress from "@/components/bookings/ApprovalProgress";
import ReopenBookingAction from "@/views/bookings/components/actions/ReopenBookingAction";
import {
  ADD_COMMENT,
  BOOKINGS_NAMESPACE,
  FETCH_CURRENT_BOOKING_COMMENTS,
  FETCH_CURRENT_BOOKING_EVENTS,
  FETCH_CURRENT_BOOKING,
  FETCH_CURRENT_BOOKING_SHIFT_PATTERNS
} from "@/store/modules/bookings/actions";
import {
  GET_CURRENT_BOOKING_COMMENTS,
  GET_CURRENT_BOOKING_EVENTS,
  IS_LOADING_BOOKING_COMMENTS,
  IS_LOADING_BOOKING_EVENTS,
  IS_LOADING_BOOKING
} from "@/store/modules/bookings/getters";
import { createNamespacedHelpers, mapState } from "vuex";
import { BOOKING_MODEL } from "@/models/booking-model";
import ExpandedButtons from "@/components/common/ExpandedButtons";
import { CONTRACT_TYPE_NAMES } from "@/constants/bookings";
import { size } from "lodash";

const { mapActions, mapGetters } = createNamespacedHelpers(BOOKINGS_NAMESPACE);

export default {
  name: "BookingDetails",
  components: {
    CancelBookingAction,
    SubmitWorkerAction,
    AcceptBookingAction,
    ApproveBookingAction,
    RejectBookingAction,
    SubmitForApprovalAction,
    DeleteBookingAction,
    Permissioned,
    BaseFooter,
    ShiftCalendar,
    Comment,
    PublishingSchedule,
    InternalInformation,
    BasicDetails,
    CostEstimate,
    ApprovalProgress,
    ExpandedButtons,
    ReopenBookingAction
  },
  props: {
    currentBooking: {
      type: Object,
      required: true
    },
    approvers: Array
  },
  inject: ["currentBookingId"],
  created() {
    this.loadBookings();
  },
  computed: {
    ...mapState("auth", {
      permissions: state => state.permissions
    }),
    ...mapGetters({
      comments: GET_CURRENT_BOOKING_COMMENTS,
      events: GET_CURRENT_BOOKING_EVENTS,
      isLoadingBookingComments: IS_LOADING_BOOKING_COMMENTS,
      isLoadingBookingEvents: IS_LOADING_BOOKING_EVENTS,
      isLoadingBooking: IS_LOADING_BOOKING
    }),
    isLoadingComments() {
      return this.isLoadingBookingComments && !this.comments.length;
    },
    showBaseFooter() {
      return (
        this.permissions["bookings.delete"] ||
        this.permissions["bookings.cancel"] ||
        this.permissions["bookings.submit-for-approval"] ||
        this.permissions["bookings.reject"] ||
        this.permissions["bookings.approve"] ||
        this.permissions["bookings.accept"] ||
        this.permissions["bookings.submit-worker"]
      );
    },
    showApprovers() {
      return size(this.currentBooking.bookingApprovers);
    },
    isFixedTerm() {
      return (
        this.currentBooking &&
        this.currentBooking.contractType === CONTRACT_TYPE_NAMES.FIXED_TERM
      );
    }
  },
  methods: {
    ...mapActions({
      fetchCurrentBookingComments: FETCH_CURRENT_BOOKING_COMMENTS,
      fetchCurrentBookingEvents: FETCH_CURRENT_BOOKING_EVENTS,
      addComment: ADD_COMMENT,
      fetchCurrentBooking: FETCH_CURRENT_BOOKING,
      fetchShiftPatterns: FETCH_CURRENT_BOOKING_SHIFT_PATTERNS
    }),
    async createNewComment(comment) {
      await this.addComment({
        bookingId: this.currentBookingId,
        comment: {
          body: comment,
          commentableType: BOOKING_MODEL,
          commentableId: this.currentBookingId
        }
      });
      await this.fetchCurrentBookingComments(this.currentBookingId);
    },
    async handleReject() {
      await this.fetchCurrentBookingComments(this.currentBookingId);
    },
    loadBookings() {
      if (!this.currentBooking) {
        this.fetchCurrentBooking(this.currentBookingId);
      }
      this.fetchCurrentBookingComments(this.currentBookingId);
      this.fetchCurrentBookingEvents(this.currentBookingId);
      this.fetchShiftPatterns(this.currentBookingId);
    },
    reloadBooking() {
      this.fetchCurrentBooking(this.currentBookingId);
      this.fetchCurrentBookingComments(this.currentBookingId);
      this.fetchCurrentBookingEvents(this.currentBookingId);
      this.fetchShiftPatterns(this.currentBookingId);
    }
  }
};
</script>

<style lang="scss"></style>
