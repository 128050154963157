<template>
  <div class="bulk-bookings container">
    <SecondaryButton
      v-if="groupedItems.length"
      class="my-2"
      @click.native="clearData"
      shrink
      dense
    >
      Clear
    </SecondaryButton>
    <p v-else class="text-center secondary-text">
      Paste the bookings' data from Excel
    </p>
    <v-simple-table fixed-header class="my-2">
      <template v-slot:default>
        <thead>
          <tr>
            <th
              v-for="(header, key) in headers"
              :class="{ 'w-full': header === 'RRule' }"
              class="text-center"
              :id="key"
              :key="key"
            >
              {{ header }}
            </th>
          </tr>
        </thead>
        <tbody>
          <draggable
            tag="tr"
            v-model="groupedItems[key]"
            draggable=".booking"
            v-for="(items, key) in groupedItems"
            :key="key"
          >
            <td
              v-for="(item, index) in items"
              :key="index"
              :class="`booking col-${index}`"
            >
              <v-text-field v-model="items[index]" @change="updateBookings" />
            </td>
            <v-btn @click="removeWorker(key)" class="ml-2 mt-2" icon small>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </draggable>
        </tbody>
      </template>
    </v-simple-table>
    <div v-if="!isEmpty(this.groupedItems)">
      <ValidationObserver class="d-flex" ref="validation">
        <ValidationProvider
          v-for="(header, index) in headers"
          v-slot="{ errors }"
          :key="index"
          :name="header"
          class="full-width"
          rules="required"
        >
          <v-text-field
            v-model="newItem[index]"
            @keyup.enter="addItem"
            :error-messages="showValidationErrors ? errors : ''"
            label=""
          />
        </ValidationProvider>
      </ValidationObserver>
      <PrimaryButton full-width light @click.native="addItem">
        Add Booking +
      </PrimaryButton>
    </div>
  </div>
</template>

<script>
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import { getItemsFromClipboard } from "@/utils/helpers";
import SecondaryButton from "@/components/common/Button/SecondaryButton";
import { filter, isEmpty } from "lodash";
import draggable from "vuedraggable";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { getBookingsFormattedPayload } from "@/utils/bookings";

const DEFAULT_HEADERS = [
  "Booking Template",
  "Location",
  "Organisation Unit",
  "Booking Reason",
  "RRule",
  "Workers Required",
  "Rate",
  "Timesheet Approver"
];

export default {
  name: "BulkBooking",
  components: {
    PrimaryButton,
    SecondaryButton,
    draggable,
    ValidationObserver,
    ValidationProvider
  },
  props: {
    isOpen: Boolean
  },
  data() {
    return {
      showValidationErrors: false,
      isCreating: false,
      headers: DEFAULT_HEADERS,
      groupedItems: [],
      newItem: []
    };
  },
  methods: {
    onPaste(event) {
      const { groupedItems } = getItemsFromClipboard(event);
      if (groupedItems) {
        this.groupedItems = groupedItems;
      }
      this.updateBookings();
    },
    clearData() {
      this.headers = DEFAULT_HEADERS;
      this.groupedItems = [];
      this.updateBookings();
    },
    async addItem() {
      this.showValidationErrors = true;
      const valid = await this.$refs.validation.validate();
      if (valid) {
        this.groupedItems.push(Object.assign([], this.newItem));
        this.showValidationErrors = false;
        this.newItem = [];
      }
      this.updateBookings();
    },
    removeWorker(key) {
      this.groupedItems = filter(
        this.groupedItems,
        (item, index) => index !== key
      );
      this.updateBookings();
    },
    updateBookings() {
      const bookingsToCreate = getBookingsFormattedPayload(
        this.headers,
        this.groupedItems
      );
      this.$emit("update", bookingsToCreate);
    },
    isEmpty
  }
};
</script>

<style lang="scss">
.bulk-bookings {
  td {
    .v-input.v-text-field {
      margin-top: 0 !important;
    }
    .v-text-field {
      padding-top: 0;
    }
    .v-text-field__details {
      display: none;
    }
    .v-text-field .v-input__slot {
      border: none !important;
      border-radius: 0;
      padding: 4px;
    }
    input {
      font-size: 15px;
    }
  }
  td.col-4 {
    input {
      font-size: 13px;
      width: 350px;
    }
  }
  .v-text-field .v-input__slot {
    border-radius: 0;
  }
  th {
    font-size: 18px;
    color: black;
  }
}
</style>
