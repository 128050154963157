export const FILE_MODEL = "file";

const fileModel = {
  id: "",
  name: "",
  fileName: "",
  size: 0,
  mimeType: "application/pdf",
  collection: "",
  url: "",
  organisationUnitId: "",
  userName: "",
  file: {},
  updatedAt: "",
  organisationName: ""
};

export default fileModel;
