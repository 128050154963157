import { setStoreValue } from "@/store/utils";

export const SET_ORGANISATION_LOCATIONS = "SET_ORGANISATION_LOCATIONS";
export const SET_IS_FETCHING_LOCATIONS = "SET_IS_FETCHING_LOCATIONS";

const mutations = {
  [SET_ORGANISATION_LOCATIONS]: setStoreValue("organisationLocations"),
  [SET_IS_FETCHING_LOCATIONS]: setStoreValue("isFetchingLocations")
};

const state = {
  organisationLocations: [],
  isFetchingLocations: false
};

export { mutations, state };
