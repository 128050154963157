<template>
  <div>
    <PrimaryButton light shrink @click="isDialogOpen = true">
      Edit
    </PrimaryButton>
    <BaseDialog :is-open="isDialogOpen" v-if="isDialogOpen">
      <template v-slot:title>
        Edit Organisation Unit
      </template>
      <template v-slot:body>
        <v-form ref="form" lazy-validation class="flex-gap-20 mb-4 mt-3">
          <TextField
            :disabled="isLoading"
            label="Name"
            :value="organisationData.name"
            @change="organisationData.name = $event"
            outlined
            dense
            :rules="isRequired('Name', shouldValidate)"
            with-errors
          />
          <TextField
            :disabled="isLoading"
            label="Cost Code (optional)"
            :value="organisationData.costCode"
            @change="organisationData.costCode = $event"
            outlined
            dense
          />
          <Select
            :disabled="isLoading"
            label="Parent Organisation Unit"
            @change="organisationData.parent.id = $event"
            :value="organisationData.parent.id"
            placeholder="Select Organisation Unit"
            :items="units"
            item-text="name"
            item-value="id"
            autocomplete
            :rules="isRequired('Parent Organisation Unit', shouldValidate)"
          />
        </v-form>
      </template>
      <template v-slot:actions>
        <PrimaryButton text @click="close">Cancel</PrimaryButton>
        <PrimaryButton @click="handleSubmit" :loading="isLoading"
          >Save Changes</PrimaryButton
        >
      </template>
    </BaseDialog>
  </div>
</template>

<script>
import BaseDialog from "@/components/common/BaseDialog";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import { ORGANISATION_UNITS_NAMESPACE } from "@/store/modules/organisations/organisation-units";
import { createNamespacedHelpers } from "vuex";
import { isRequired } from "@/utils/validation/rules";
import Select from "@/components/common/Select";
import TextField from "@/components/common/TextField";
import { EDIT_ORGANISATION_UNIT } from "@/store/modules/organisations/organisation-units/actions";
import { copyState } from "@/utils/helpers";
import { filter } from "lodash";

const { mapActions, mapState } = createNamespacedHelpers(
  ORGANISATION_UNITS_NAMESPACE
);
export default {
  name: "EditUnitDialog",
  components: { TextField, Select, PrimaryButton, BaseDialog },
  data() {
    return {
      isDialogOpen: false,
      isLoading: false,
      shouldValidate: false,
      organisationData: {
        name: this.unit.name,
        costCode: this.unit.costCode,
        organisationType: this.unit.organisationType,
        parent: {
          id: this.unit.parentId
        }
      }
    };
  },
  props: {
    unit: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState(copyState(["organisationUnits"])),
    isValid() {
      return ["name", "parent"].every(value => this.organisationData[value]);
    },
    units() {
      return filter(this.organisationUnits, unit => {
        if (this.unit.parentId) {
          return unit.id !== this.unit.id;
        }
        return unit;
      });
    }
  },
  methods: {
    ...mapActions({
      editOrganisationUnit: EDIT_ORGANISATION_UNIT
    }),
    isRequired,
    close() {
      this.shouldValidate = false;
      this.isDialogOpen = false;
    },
    async handleSubmit() {
      this.shouldValidate = true;
      this.$refs.form.validate();
      if (this.isValid) {
        try {
          this.isLoading = true;
          await this.editOrganisationUnit({
            organisation: this.organisationData,
            id: this.unit.id
          });
        } catch (e) {
          console.error(e);
        } finally {
          this.close();
          this.isLoading = false;
        }
      }
    }
  }
};
</script>

<style scoped></style>
