<template>
  <BaseAction>
    <AllocateWorkers
      v-if="allocateWorkersSettings.dialog"
      :settings="allocateWorkersSettings"
      :selectedShifts="selectedShifts"
      :isV2="isV2"
      @updateList="updateList"
    />
    <SecondaryButton
      color="primary"
      @click.native="allocateWorkersSettings.dialog = true"
    >
      <div>
        <inline-svg
          :src="require('@/assets/svg/arrow-right-white.svg')"
          width="25"
        />
        <inline-svg :src="require('@/assets/svg/user-white.svg')" width="25" />
      </div>
      Allocate Workers
    </SecondaryButton>
  </BaseAction>
</template>

<script>
import AllocateWorkers from "@/views/bookings/BookingDetails/components/Shifts/AllocateWorkers/AllocateWorkers";
import SecondaryButton from "@/components/common/Button/SecondaryButton";
import InlineSvg from "vue-inline-svg";
import BaseAction from "@/components/common/BaseAction";

export default {
  name: "AllocateWorkersAction",
  components: {
    SecondaryButton,
    AllocateWorkers,
    InlineSvg,
    BaseAction
  },
  data() {
    return {
      allocateWorkersSettings: {
        dialog: false
      }
    };
  },
  methods: {
    updateList() {
      this.$emit("updateList");
    }
  },
  props: {
    selectedShifts: Array,
    fullWidth: Boolean,
    isV2: Boolean
  },
  inject: ["currentBookingId"]
};
</script>
