<template>
  <ValidationObserver ref="basicDetails">
    <div class="booking-basic-details">
      <div class="container col-lg-10 col-sm-12">
        <h1 class="pb-3">Basic Details</h1>
        <JobItem
          :role="initialTemplate.jobTitle"
          :description="initialTemplate.description"
          class=""
        />
        <div class="flex-gap-15 mb-5">
          <div class="detail">
            <RadioButton
              @select="handleRadioButtonChange"
              :options="formattedContractTypes"
              :selectedValue="basicDetails.contractType"
              showGroupInRow
            />
            <div
              class="d-flex w-full"
              v-if="basicDetails.contractType === 'fixed-term'"
            >
              <DatePicker
                class="w-full mr-2"
                @onChange="updateStartDate"
                label="Start Date"
                :input-value="basicDetails.startDate"
                :showValidationErrors="showValidationErrors"
                validateName="startDate"
                required
              />
              <DatePicker
                class="w-full"
                :key="basicDetails.startDate"
                @onChange="updateEndDate"
                label="End Date"
                :input-value="basicDetails.endDate"
                :showValidationErrors="showValidationErrors"
                :minDate="basicDetails.startDate"
                validateName="endDate"
                required
              />
            </div>
          </div>
          <div class="detail">
            <ValidationProvider
              v-slot="{ errors }"
              name="location"
              rules="required"
            >
              <Select
                :errorMessages="errors"
                autocomplete
                v-model="basicDetails.location"
                @input="handleChange"
                item-value="value"
                height="50"
                class="mt-2"
                label="Location"
                placeholder="Select location"
                :items="formattedLocations"
                item-text="label"
                :loading="isFetchingData"
              />
            </ValidationProvider>
          </div>
          <div class="detail">
            <v-textarea
              class="details-description"
              v-model="basicDetails.description"
              @input="handleChange"
              height="200"
              color="primary"
              label="Description"
            />
          </div>
          <Select
            autocomplete
            label="Compliance Requirements"
            v-model="basicDetails.complianceRequirements"
            @change="handleChange"
            :items="initialTemplate.complianceRequirements"
            :loading="isFetchingData"
            :disabled="isFetchingData"
            chips
            multiple
          />
          <Select
            autocomplete
            label="Other Requirements"
            v-model="basicDetails.otherRequirements"
            @change="handleChange"
            :items="initialTemplate.otherRequirements"
            :loading="isFetchingData"
            :disabled="isFetchingData"
            chips
            multiple
          />
        </div>
        <v-skeleton-loader v-if="isFetchingData" type="article" />
        <div v-else class="hourly-cost new-booking-preview">
          <v-tabs v-model="selectedTemplate">
            <v-tab
              v-for="(payRates, payRateName) in payRates"
              :key="payRateName"
            >
              {{ payRateName }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="selectedTemplate">
            <v-tab-item
              v-for="(payRates, payRateName) in payRates"
              :key="payRateName"
            >
              <div v-if="hasAWRBasedRates(payRates)">
                <div class="awr-label">Pre-AWR</div>
                <PayRateItems
                  :pay-rate-items="payRates[0].templatePayRateItems"
                />
                <div class="awr-label">Post-AWR</div>
                <PayRateItems
                  :pay-rate-items="payRates[1].templatePayRateItems"
                />
              </div>
              <PayRateItems
                v-else
                :pay-rate-items="payRates[0].templatePayRateItems"
              />
            </v-tab-item>
          </v-tabs-items>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import JobItem from "@/views/bookings/NewBooking/steps/components/JobItem";
import { DATA_NAMESPACE } from "@/store/modules/data";
import { createNamespacedHelpers } from "vuex";
import {
  FETCH_BOOKING_TEMPLATE,
  FETCH_LOCATIONS
} from "@/store/modules/data/actions";
import {
  GET_BOOKING_TEMPLATE,
  GET_IS_FETCHING_DATA,
  GET_LOCATIONS
} from "@/store/modules/data/getters";
import { groupByPayRateName } from "@/utils/bookings";
import PayRateItems from "@/components/bookings/PayRateItems";
import Select from "@/components/common/Select";
import { getFormattedLocation } from "@/utils/locations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { size, first, startCase } from "lodash";
import { CONTRACT_TYPES, CONTRACT_TYPE_NAMES } from "@/constants/bookings";
import RadioButton from "@/components/common/RadioButton";
import DatePicker from "@/components/common/DatePicker";
import { formatDate } from "@/utils/time";
import moment from "moment";

const { mapActions, mapGetters } = createNamespacedHelpers(DATA_NAMESPACE);

export default {
  name: "BasicDetails",
  props: {
    validate: Boolean,
    initialTemplate: Object,
    showValidationErrors: Boolean
  },
  components: {
    Select,
    PayRateItems,
    JobItem,
    ValidationProvider,
    ValidationObserver,
    RadioButton,
    DatePicker
  },
  data() {
    return {
      basicDetails: {
        description: null,
        location: null,
        startDate: null,
        contractType: CONTRACT_TYPE_NAMES.TEMPORARY,
        complianceRequirements: [],
        otherRequirements: []
      },
      selectedTemplate: 0,
      shouldValidate: false
    };
  },
  computed: {
    ...mapGetters({
      template: GET_BOOKING_TEMPLATE,
      isFetchingData: GET_IS_FETCHING_DATA,
      locations: GET_LOCATIONS
    }),
    payRates() {
      return this.template.templatePayRates
        ? groupByPayRateName(this.template.templatePayRates)
        : [];
    },
    formattedLocations() {
      return this.locations.map(location => ({
        label: getFormattedLocation(location),
        value: location
      }));
    },
    formattedContractTypes() {
      return CONTRACT_TYPES.map(contractType => ({
        label: startCase(contractType),
        value: contractType
      }));
    },
    minEndDate() {
      return moment(this.basicDetails.startDate).format("YYYY-MM-DD");
    }
  },
  async created() {
    await this.fetchBookingTemplate(this.initialTemplate.id);
    this.setRequirements();
    this.setLocations();
  },
  methods: {
    ...mapActions({
      fetchBookingTemplate: FETCH_BOOKING_TEMPLATE,
      fetchLocations: FETCH_LOCATIONS
    }),
    hasAWRBasedRates(payRates) {
      return payRates.length > 1;
    },
    handleChange() {
      this.$emit("change", this.basicDetails);
    },
    async validateForm() {
      const isValid = await this.$refs.basicDetails.validate();
      this.$emit(isValid ? "valid" : "invalid");
    },
    setRequirements() {
      this.basicDetails.complianceRequirements = this.initialTemplate.complianceRequirements;
      this.basicDetails.otherRequirements = this.initialTemplate.otherRequirements;
      this.basicDetails.description = this.initialTemplate.description;
    },
    async setLocations() {
      const filter = { "not-primary": true };
      await this.fetchLocations({ filter });
      if (size(this.formattedLocations) === 1) {
        this.basicDetails.location = first(this.formattedLocations).value;
        this.handleChange();
      }
    },
    handleRadioButtonChange(value) {
      this.basicDetails.contractType = value;
      this.handleChange();
    },
    updateStartDate(date) {
      this.basicDetails.startDate = formatDate({ date });
      if (
        moment(this.basicDetails.startDate, "YYYY-MM-DD").isAfter(
          moment(this.basicDetails.endDate, "YYYY-MM-DD")
        )
      ) {
        this.basicDetails.endDate = null;
      }
      this.handleChange();
    },
    updateEndDate(date) {
      this.basicDetails.endDate = formatDate({ date });
      this.handleChange();
    }
  },
  watch: {
    validate(shouldValidate) {
      if (shouldValidate) {
        this.validateForm();
      }
    },
    initialTemplate(newValue, previousValue) {
      if (previousValue !== newValue) {
        this.fetchBookingTemplate(newValue.id);
      }
    }
  }
};
</script>
<style lang="scss"></style>
