<template>
  <div>
    <div class="expense-item-wrapper">
      <div class="expense-item-top">
        <div class="description">
          <div class="font-weight-bold mr-2">
            {{ description }}
          </div>
          <div class="amount">
            ({{ expenseAmount | currency(data.currencyCode) }})
          </div>
        </div>
        <div class="d-flex align-start ml-3">
          <div class="text-center mx-1" v-if="showLoader">
            <v-progress-circular size="30" indeterminate color="primary" />
          </div>
          <EditExpenseAction
            v-if="canEditExpenses"
            :expense="data"
            :isRemoving="isRemoving"
          />
          <v-icon
            class="mx-2 mt-1"
            v-if="canEditExpenses"
            @click="deleteExpense"
          >
            mdi-close
          </v-icon>
        </div>
      </div>
      <div v-if="files.length" class="file-wrapper">
        <FileUpload
          customActions
          disabled
          :id="data.id"
          :files="files"
          :fileTypes="['.pdf', '.jpeg', '.jpg', '.png']"
          @show="onShow"
        />
      </div>
    </div>
    <PDFViewFull :data="fileDialog" />
  </div>
</template>

<script>
import FileUpload from "@/components/common/FileUpload";
import PDFViewFull from "@/components/common/PDFViewFull";
import { getToken } from "@/services/utils";
import EditExpenseAction from "@/views/timesheets/components/actions/EditExpenseAction";

export default {
  name: "ExepnseItem",
  components: {
    FileUpload,
    PDFViewFull,
    EditExpenseAction
  },
  computed: {
    currentTimesheetId() {
      return this.$route.params.id;
    },
    showLoader() {
      return this.isRemoving;
    },
    showRemoveButton() {
      return !this.showLoader;
    }
  },
  data() {
    return {
      description: this.data.description,
      expenseAmount: this.data.expenseAmount,
      currencyCode: this.data.currencyCode,
      files: this.data.file ? [this.data.file] : [],
      fileDialog: {
        title: "",
        dialog: false,
        src: ""
      }
    };
  },
  methods: {
    onShow(file) {
      const token = getToken();
      this.fileDialog.src = `${file.url}?token=${token}`;
      this.fileDialog.title = file.fileName;
      this.fileDialog.dialog = true;
    },
    deleteExpense() {
      if (this.showRemoveButton) {
        this.$emit("delete", this.data.id);
        return;
      }

      this.$emit("onRemove");
    }
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    isEmpty: Boolean,
    isRemoving: Boolean,
    canEditExpenses: Boolean
  }
};
</script>

<style lang="scss">
.expense-item-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 30px;

  .expense-item-top {
    display: flex;
  }

  .description {
    display: flex;
    width: 100%;

    .amount {
      word-break: initial;
    }
  }
  .file-wrapper {
    display: flex;

    @media only screen and (max-width: $mobile-breakpoint - 1) {
      width: 100%;
    }

    .details {
      align-items: center;
      display: flex;
      margin-right: 70px;
    }
  }
}
</style>
