<template>
  <v-list-item :to="`/reports/${report.id}`">
    <div class="list-item report-item">
      <v-list-item-avatar tile v-if="!isMobile">
        <v-img
          src="@/assets/svg/report_icon.svg"
          class="report-list-item-icon"
          alt="File Icon"
        />
      </v-list-item-avatar>
      <v-list-item-content>
        <div class="item-right">
          <div
            class="description d-flex flex-grow-1"
            :class="{ 'flex-row': !isMobile }"
          >
            <div class="d-flex flex-column">
              <span class="font-weight-bold mb-2">{{ report.name }}</span>
              <span>{{ reportFocus }}</span>
            </div>
          </div>
        </div>
      </v-list-item-content>
    </div>
  </v-list-item>
</template>

<script>
import { REPORT_FOCUSES } from "@/constants/reports";

export default {
  name: "ReportListItem",
  data() {
    return {
      isGeneratingReport: false
    };
  },
  computed: {
    reportFocus() {
      if (!this.report) {
        return undefined;
      }
      const focus = REPORT_FOCUSES.find(
        focus => focus.key === this.report.focus
      );
      if (!focus) {
        return undefined;
      }
      return focus.name;
    }
  },
  props: {
    report: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss">
.report-item {
  line-height: normal;

  .v-avatar.v-list-item__avatar {
    background-color: rgba(#00ffb2, 0.15) !important;
    border-radius: 4px;
  }
  .v-image__image--cover {
    background-size: auto;
  }

  @media only screen and (max-width: $mobile-breakpoint - 1) {
    margin-bottom: 0px;
  }
}
</style>
