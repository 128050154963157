<template>
  <v-menu
    v-model="open"
    min-width="200"
    offset-y
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on }">
      <v-btn :class="{ active: isFiltered }" text>
        <div v-on="on">
          <span>
            {{ isFiltered ? filteredText : label }}
          </span>
          <v-icon v-if="!isFiltered">
            {{ `mdi-chevron-${open ? "up" : "down"}` }}
          </v-icon>
        </div>
        <v-icon @click.native="clear" class="ml-1" v-if="isFiltered">
          mdi-close
        </v-icon>
      </v-btn>
    </template>
    <div class="inline-filter-items">
      <div class="d-flex justify-space-between mb-2">
        <h4>{{ label }}</h4>
        <v-icon @click="open = false">
          mdi-close
        </v-icon>
      </div>
      <slot />
    </div>
  </v-menu>
</template>

<script>
import { isArray, size, first, find } from "lodash";
import moment from "moment";

export default {
  props: {
    label: {
      type: String,
      required: true
    },
    options: [Array, Object],
    filters: [Boolean, String, Number, Array],
    isDate: Boolean,
    rangeDate: Boolean,
    isText: Boolean
  },
  data() {
    return {
      open: false
    };
  },
  computed: {
    isFiltered() {
      if (this.isDate && this.filters) {
        return this.filters[0] || this.filters[1];
      }
      return isArray(this.filters) ? size(this.filters) : this.filters;
    },
    filteredText() {
      if (this.isText && this.filters) {
        return `${this.label}: ${this.filters}`;
      }
      if (this.isDate && this.filters) {
        return this.rangeDate ? this.filteredDates : this.singleDate;
      }
      const suffix = size(this.filters) > 1 ? `+${size(this.filters) - 1}` : "";
      return isArray(this.filters)
        ? find(this.options, ["value", first(this.filters)]) &&
            `${
              find(this.options, ["value", first(this.filters)]).text
            } ${suffix}`
        : find(this.options, ["value", this.filters]) &&
            find(this.options, ["value", this.filters]).text;
    },
    filteredDates() {
      const after = this.filters[0] && moment(this.filters[0]);
      const before = this.filters[1] && moment(this.filters[1]);

      if (after && before) {
        return `Start Date ${after.format("DD/MM/YYYY")}-${before.format(
          "DD/MM/YYYY"
        )}`;
      }
      return after
        ? `Start Date After ${after.format("DD/MM/YYYY")}`
        : `Start Date Before ${before.format("DD/MM/YYYY")}`;
    },
    singleDate() {
      return `${this.label} ${moment(this.filters).format("DD/MM/YYYY")}`;
    }
  },
  methods: {
    clear() {
      this.open = false;
      this.$emit("clear");
    }
  }
};
</script>

<style lang="scss">
.v-menu__content {
  background: white;
}
.inline-filter-items {
  position: relative;
  min-width: 325px;

  h4 {
    font-size: 1.2rem;
  }
}
.filter-checkbox {
  background: white;
  left: 0;
  bottom: 0;
}
</style>
