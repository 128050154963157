import { handleWithErrorMessage } from "@/services/utils";
import {
  SET_IS_FETCHING_USERS,
  SET_ORGANISATION_USERS
} from "@/store/modules/settings/mutations";
import userApi from "@/services/modules/users";

export const FETCH_ORGANISATION_USERS = "FETCH_ORGANISATION_USERS";
export const FETCH_ORGANISATION_REASONS = "FETCH_ORGANISATION_REASONS";
export const FETCH_ORGANISATION_BOOKING_TEMPLATES =
  "FETCH_ORGANISATION_BOOKING_TEMPLATES";

const actions = {
  async [FETCH_ORGANISATION_USERS]({ commit, dispatch }, params) {
    return handleWithErrorMessage({
      dispatch,
      request: () => userApi(commit).getUsers(params),
      setLoadingFunction: isLoading => commit(SET_IS_FETCHING_USERS, isLoading),
      setDataFunction: ({ data }) => commit(SET_ORGANISATION_USERS, data)
    });
  },
  async [FETCH_ORGANISATION_REASONS]() {
    // TODO: Implement
  }
};

export default actions;
