<template>
  <div class="icon">
    <v-icon size="1.5rem">
      mdi-history
    </v-icon>
  </div>
</template>

<script>
export default {
  name: "WaitingIcon"
};
</script>

<style scoped lang="scss">
.icon {
  margin: auto 0.75rem;

  i {
    background-color: rgba($awaiting, 0.5);
    border-radius: 50%;
    padding: 0.2rem;
    width: auto;
  }
}
</style>
