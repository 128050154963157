<template>
  <v-snackbar v-model="visible" :style="styles" top multi-line>
    <v-icon :color="data.type" class="mr-3 pb-3" :size="28">
      {{ data.type | icon }}
    </v-icon>
    <div class="message">
      <strong>{{ data.title }}</strong>
      <p>{{ data.message }}</p>
    </div>
    <v-btn color="grey" text @click="visible = false">
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </v-snackbar>
</template>

<script>
export default {
  props: {
    /**
     *  Snackbar data object
     */
    data: {
      type: Object,
      required: true
    },
    /**
     *  Boolean for snackbar model
     */
    isVisible: {
      type: Boolean,
      default: false
    },
    index: Number
  },
  data() {
    return {
      visible: this.isVisible
    };
  },
  computed: {
    styles() {
      const margin = this.index * 80;
      return { marginTop: `${margin}px` };
    }
  },
  filters: {
    icon(type) {
      switch (type) {
        case "success":
          return "mdi-checkbox-marked-circle";
        case "error":
          return "mdi-alert";
        default:
          return "mdi-checkbox-marked-circle";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.message {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
}
</style>
