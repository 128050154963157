import { first, map, reduce, forEach } from "lodash";
import generateId from "uuid/v4";
import { RRule } from "rrule";
import moment from "moment";
import pluralize from "pluralize";
import { API_DATE_FORMAT } from "@/constants/common";

export const CANDIDATE_STATUSES = {
  LONGLIST: "longlist",
  SHORTLISTED: "shortlisted",
  ALLOCATED: "allocated",
  UNALLOCATED: "unallocated",
  SUBMITTED: "submitted",
  UNSUBMITTED: "unsubmitted",
  CONFIRMED: "confirmed",
  WITHDRAWN: "withdrawn",
  INTERVIEWING: "interviewing"
};

export const BOOKING_STATUSES = {
  APPROVED: "approved",
  DRAFT: "draft",
  LIVE: "live",
  PENDING: "pending-approval",
  REJECTED: "rejected",
  CANCELLED: "cancelled",
  CLOSED: "closed"
};

export const COMPILANCE_STATUSES = {
  INCOMPLETE: "incomplete",
  PARTIAL: "partial",
  COMPLETE: "complete"
};

export const getColorByStatus = status => {
  const {
    SHORTLISTED,
    ALLOCATED,
    CONFIRMED,
    WITHDRAWN,
    INTERVIEWING
  } = CANDIDATE_STATUSES;

  const {
    APPROVED,
    DRAFT,
    LIVE,
    PENDING,
    REJECTED,
    CANCELLED,
    CLOSED
  } = BOOKING_STATUSES;

  const { PARTIAL, COMPLETE } = COMPILANCE_STATUSES;

  switch (status.toLowerCase()) {
    case CONFIRMED:
    case COMPLETE:
    case LIVE:
      return "green";
    case APPROVED:
      return "blue";
    case SHORTLISTED:
    case PARTIAL:
    case ALLOCATED:
      return "yellow";
    case INTERVIEWING:
      return "yellow";
    case REJECTED:
    case WITHDRAWN:
    case CANCELLED:
    case CLOSED:
      return "red";
    case DRAFT:
    case PENDING:
    default:
      return "gray";
  }
};

export const groupByPayRateName = payRates =>
  groupByPropertyName(payRates, "payRateName");

export const groupByPropertyName = (items, propertyName) =>
  items.reduce((grouped, item) => {
    const groupedByProperty = grouped[item[propertyName]];
    if (!groupedByProperty) {
      grouped[item[propertyName]] = [item];
    } else {
      grouped[item[propertyName]] = [...groupedByProperty, item];
    }
    return grouped;
  }, {});

export const convertToShiftPatterns = shifts => {
  return map(shifts, shift => {
    const rrule = RRule.parseString(shift.rrule);
    const fromHour = moment(shift.shiftStart, API_DATE_FORMAT).format(
      "HH:mm:ss"
    );
    const toHour = moment(shift.shiftEnd, API_DATE_FORMAT).format("HH:mm:ss");
    const recurrence = {
      dtstart: new Date(shift.shiftStart),
      until: rrule.until,
      byweekday: rrule.byweekday || [],
      freq: rrule.freq
    };
    const activity = shift.payRates && first(shift.payRates).activityTypeName;

    return {
      id: generateId(),
      recurrence,
      workersRequired: shift.workersRequired,
      activity,
      fromHour,
      toHour
    };
  });
};

export const convertToWeekdays = rruleWeekdays =>
  rruleWeekdays.map(weekday => toWeekdayFromRRule(weekday));

export const toWeekdayFromRRule = rruleWeekday =>
  moment(rruleWeekday.getJsWeekday(), "e").format("dddd");

export const getWordInCorrectForm = (word, number) =>
  number > 1 ? pluralize(word) : word;

export const getApplicationActionIcon = action => {
  switch (action) {
    case "withdraw":
      return "mdi-account-arrow-left-outline";
    case "reject":
      return "mdi-thumb-down-outline";
    case "request-interview":
      return "mdi-card-account-phone-outline";
    case "approve":
      return "mdi-check";
    case "shortlist":
      return "mdi-clipboard-text-outline";
    default:
      return "request-interview";
  }
};

const getHeaderField = headers => {
  return map(headers, header => {
    switch (header.toLowerCase()) {
      case "booking template":
        return "templateId";
      case "location":
        return "locationId";
      case "organisation unit":
        return "organisationUnitId";
      case "rrule":
        return "rrule";
      case "workers required":
        return "workersRequired";
      case "rate":
        return "payRateId";
      case "booking reason":
        return "reasonId";
      case "timesheet approver":
        return "timesheetApproverId";
      default:
        return "";
    }
  });
};

export const getBookingsFormattedPayload = (headers, groupedItems) => {
  const fields = getHeaderField(headers);
  return reduce(
    groupedItems,
    (formattedItems, items) => {
      let formattedItem = {};
      forEach(items, (item, key) => {
        formattedItem[fields[key]] = item;
      });
      return [...formattedItems, formattedItem];
    },
    []
  );
};
