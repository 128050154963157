import {
  join,
  includes,
  startCase,
  map,
  capitalize,
  isEmpty,
  some
} from "lodash";
import store from "@/store";
import { errorHandler } from "@/services/utils";

const showEmptyFullName = user => {
  return !user || !(user.firstName || user.lastName);
};

export const getFullName = user => {
  if (showEmptyFullName(user)) {
    return "";
  }
  return `${user.firstName} ${user.lastName}`;
};

export const withFullName = user => {
  if (!user) {
    return {};
  }
  return {
    ...user,
    fullName: showEmptyFullName(user)
      ? ""
      : `${user.firstName} ${user.lastName}`
  };
};

export const getUserRoles = user => {
  return join(user.roles, ", ");
};

export const getLastBookingName = booking => {
  return booking ? booking.name : "No Live Bookings";
};

export const emailExistingError = email => {
  const errors = [
    {
      title: "Duplicate Email!",
      detail: `${email} already exist.`,
      status: 409
    }
  ];
  errorHandler(store.dispatch, { errors });
};

export const isWorker = roles => {
  return includes(roles, "worker");
};

export const getRoles = roles => {
  roles = map(roles, role => startCase(role));
  const rolesData = roles.join(", ");
  return rolesData;
};

export const getReasons = user => {
  const reasons = map(user.reasons, reason => {
    const { shift } = reason;
    return shift
      ? `${capitalize(reason.name)} with CSA-${shift.id}`
      : capitalize(reason.name);
  });
  return join(reasons, ", ");
};

export const getRequirements = user => {
  const { compliance_requirements } = user;
  return !isEmpty(compliance_requirements)
    ? `Doesn't meet the following requirements: ${join(
        compliance_requirements,
        ", "
      )}`
    : "";
};

export const isNotApproved = user =>
  some(user.reasons, reason => {
    return includes(reason.name, "unapproved");
  });

export const isNotSubmitted = user =>
  some(user.reasons, reason => {
    return includes(reason.name, "no");
  });

export const getUnavailableReason = (users, forUnapproved = false) => {
  return some(users, user =>
    forUnapproved ? isNotApproved(user) : isNotSubmitted(user)
  );
};

export const isMSPOrSuperAdmin = roles => {
  return includes(roles, "msp admin") || includes(roles, "super admin");
};
