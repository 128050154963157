<template>
  <div class="text-center my-4">
    <h2 class="my-2">{{ action.title }}</h2>
    <div v-if="isFetching">
      <v-skeleton-loader v-for="i in 3" :key="i" type="list-item-avatar" />
    </div>
    <div v-else>
      <v-list-item
        v-for="item in items"
        :key="item.id"
        @click="handleSelect(item)"
      >
        <div class="full-width">
          <component
            :is="action.component"
            :booking="item"
            :timesheet="item"
            :application="item"
          />
        </div>
      </v-list-item>
    </div>
  </div>
</template>

<script>
import BookingListItem from "@/views/bookings/components/BookingListItem";
import TimesheetListItem from "@/views/timesheets/details/components/TimesheetListItem";
import ApplicationItem from "@/components/applications/ApplicationItem";

export default {
  props: {
    type: String,
    isFetching: Boolean,
    items: {
      type: Array,
      required: true
    }
  },
  components: {
    BookingListItem,
    TimesheetListItem,
    ApplicationItem
  },
  computed: {
    action() {
      switch (this.type) {
        case "bookingsToApprove":
          return {
            component: BookingListItem,
            title: "Bookings to Approve"
          };
        case "bookingsToAccept":
          return {
            component: BookingListItem,
            title: "Bookings to Accept"
          };
        case "bookingsWithShiftsToApprove":
          return {
            component: BookingListItem,
            title: "Bookings with Shifts to Approve"
          };
        case "bookingExtensionsToApprove":
          return {
            component: BookingListItem,
            title: "Extensions to Approve"
          };
        case "timesheetsToApprove":
          return {
            component: TimesheetListItem,
            title: "Timesheets to Approve"
          };
        case "timesheetsToVerify":
          return {
            component: TimesheetListItem,
            title: "Timesheets to Verify"
          };
        case "applicationsToReview":
          return {
            component: ApplicationItem,
            title: "Applications to Review"
          };
        default:
          return {};
      }
    }
  },
  methods: {
    handleSelect(item) {
      const { id } = item;
      if (
        [
          "bookingsToApprove",
          "bookingsToAccept",
          "bookingExtensionsToApprove"
        ].includes(this.type)
      ) {
        this.$router.push({
          name: "bookingDetails",
          params: { id }
        });
      }
      if (this.type === "bookingsWithShiftsToApprove") {
        this.$router.push({
          path: `/bookings/${id}/shifts`
        });
      }

      if (
        this.type === "timesheetsToApprove" ||
        this.type === "timesheetsToVerify"
      ) {
        this.$router.push({
          name: "timesheetDetails",
          params: { id }
        });
      }

      if (this.type === "applicationsToReview") {
        const { booking } = item;
        this.$router.push({
          path: `/bookings/${booking.id}/candidates?applicationId=${id}`
        });
      }
    }
  }
};
</script>

<style lang="scss"></style>
