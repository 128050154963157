<template>
  <div class="d-flex">
    <v-text-field
      placeholder="Value"
      v-model="initialValue"
      @change="onChange"
      :errorMessages="errorMessages"
      type="number"
    />
    <v-select
      placeholder="Unit"
      :items="unitTypes"
      :value="unit"
      @change="onUnitChange"
      :errorMessages="errorMessages"
      item-text="text"
    />
  </div>
</template>

<script>
import { UNIT_TYPES } from "@/constants/reports";

export default {
  name: "ReportUnitDateFilter",
  props: {
    value: String,
    onChange: Function,
    fieldType: String,
    filterType: String,
    unit: String,
    intervals: Array,
    onUnitChange: Function,
    errorMessages: [String, Array]
  },
  data() {
    return {
      initialValue: this.value
    };
  },
  computed: {
    unitTypes() {
      return this.intervals.map(interval => ({
        value: interval,
        text: UNIT_TYPES[interval]
      }));
    }
  },
  watch: {
    value(newValue) {
      if (Number(newValue) < 0) {
        this.initialValue = 0;
        this.onChange(this.initialValue);
      }
    }
  }
};
</script>
