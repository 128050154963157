import { EMAIL_NOTIFICATIONS_MODEL } from "./emails/email-notifications-model";

export const NOTIFICATION_MODEL = "notification";

const notificationModel = {
  emails: {
    jsonApi: "hasMany",
    type: EMAIL_NOTIFICATIONS_MODEL
  }
};

export default notificationModel;
