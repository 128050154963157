import { APPLICATION_NOTIFICATIONS_MODEL } from "./application-notifications-model";
import { BOOKING_NOTIFICATIONS_MODEL } from "./booking-notifications-model";
import { TIMESHEET_NOTIFICATIONS_MODEL } from "./timesheet-notifications-model";
import { COMMENT_NOTIFICATIONS_MODEL } from "./comment-notifications-model";

export const EMAIL_NOTIFICATIONS_MODEL = "email-notifications";

const emailNotificationsModel = {
  applications: {
    jsonApi: "hasMany",
    type: APPLICATION_NOTIFICATIONS_MODEL
  },
  bookings: {
    jsonApi: "hasMany",
    type: BOOKING_NOTIFICATIONS_MODEL
  },
  timesheets: {
    jsonApi: "hasMany",
    type: TIMESHEET_NOTIFICATIONS_MODEL
  },
  comments: {
    jsonApi: "hasMany",
    type: COMMENT_NOTIFICATIONS_MODEL
  }
};

export default emailNotificationsModel;
