<template>
  <div>
    <div
      class="checkbox-wrapper col-12"
      :class="{ 'checkbox-wrapper--column': isColumn }"
      v-if="isLarge"
    >
      <div
        class="checkbox checkbox--large"
        @click="!disabled && setValue(option.value)"
        :class="{
          'checkbox--large__active': values.includes(option.value),
          mixed
        }"
        v-for="(option, index) in options"
        :key="`opt-${index}`"
      >
        <v-checkbox
          :disabled="disabled"
          v-model="values"
          @click="setValue(option.value)"
          :label="option.label"
          :value="option.value"
          on-icon="mdi-check"
          color="black"
          dense
        />
      </div>
    </div>
    <div class="checkbox-wrapper col-12" v-else>
      <v-checkbox
        :disabled="disabled"
        class="checkbox"
        v-model="values"
        :label="option.label"
        :value="option.value"
        v-for="(option, index) in options"
        on-icon="mdi-check"
        :key="`opt-${index}`"
        color="black"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    /**
     * Array of objects containing the label and values
     * [{
     *  value,
     *  label
     * }]
     */
    options: {
      type: Array,
      required: true
    },
    /**
     * Default selected value
     */
    selectedValues: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    },
    /**
     * Boolean for  large checkboxes
     */
    isLarge: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Boolean to set flex-direction to column
     */
    isColumn: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    mixed: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      values: this.selectedValues
    };
  },
  methods: {
    setValue(value) {
      if (this.values.includes(value)) {
        this.values.splice(this.values.indexOf(value), 1);
      } else {
        this.values.push(value);
      }
      this.$emit("change", this.values);
    }
  }
};
</script>

<style lang="scss">
.v-input--selection-controls .v-input__slot > .v-label,
.v-input--selection-controls .v-radio > .v-label {
  top: 0;
}
</style>
