<template>
  <ValidationObserver ref="tierForm">
    <Dialog :data="dialogSettings">
      <div class="flex-gap-15">
        <ValidationProvider v-slot="{ errors }" name="name" rules="required">
          <v-text-field
            label="Name"
            :disabled="loading"
            v-model="form.tier_name"
            :error-messages="shouldValidate ? errors : ''"
          />
        </ValidationProvider>
        <ValidationProvider
          v-slot="{ errors }"
          name="delay"
          rules="required|minNumber:0"
        >
          <v-text-field
            label="Hours delay"
            :disabled="loading"
            :error-messages="shouldValidate ? errors : ''"
            type="number"
            v-model="form.publishing_delay_hours"
          />
        </ValidationProvider>
        <ValidationProvider
          v-slot="{ errors }"
          name="nbr"
          rules="required|minNumber:0"
        >
          <v-text-field
            label="NBR"
            :disabled="loading"
            :error-messages="shouldValidate ? errors : ''"
            type="number"
            v-model="form.tier_nbr"
          />
        </ValidationProvider>
      </div>
      <template v-slot:dialog.actions>
        <PrimaryButton @click.native="close" text>Cancel</PrimaryButton>
        <PrimaryButton @click.native="submit" :loading="loading">
          {{ actionName }}
        </PrimaryButton>
      </template>
    </Dialog>
  </ValidationObserver>
</template>

<script>
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import Dialog from "@/components/common/Dialog";
import { ValidationObserver, ValidationProvider } from "vee-validate";

const INITIAL_FORM_DATA = {
  delay: null,
  name: ""
};

export default {
  name: "PublishingScheduleTierDialog",
  components: {
    Dialog,
    PrimaryButton,
    ValidationObserver,
    ValidationProvider
  },
  props: {
    isOpen: Boolean,
    loading: Boolean,
    tier: Object,
    title: String,
    actionName: String
  },

  data() {
    return {
      form: {},
      shouldValidate: false,
      statuses: [
        { label: "Active", value: "active" },
        { label: "Inactive", value: "inactive" }
      ]
    };
  },
  computed: {
    dialogSettings() {
      return {
        dialog: this.isOpen,
        title: this.title
      };
    }
  },
  created() {
    this.adjustFormFields();
  },
  methods: {
    adjustFormFields() {
      if (this.tier) {
        this.form.tier_name = this.tier.tier_name;
        this.form.publishing_delay_hours = this.tier.publishing_delay_hours;
        this.form.tier_nbr = this.tier.tier_nbr;
      } else {
        this.form = Object.assign({}, INITIAL_FORM_DATA);
      }
    },
    async submit() {
      this.shouldValidate = true;
      const isValid = await this.$refs.tierForm.validate();
      const tier = {
        tier_name: this.form.tier_name,
        publishing_delay_hours: this.form.publishing_delay_hours,
        tier_nbr: this.form.tier_nbr
      };
      if (isValid) {
        this.$refs.tierForm.reset();
        this.$emit("submit", tier);
      }
    },
    close() {
      this.shouldValidate = false;
      this.$refs.tierForm.reset();
      this.adjustFormFields();
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss"></style>
