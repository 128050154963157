<template>
  <v-list dark>
    <v-list-item-group>
      <v-list-item v-for="(item, index) in items" :key="index">
        <v-list-item-content class="min-width-100">
          <v-list-item-title @click="handleSelect(item.action)">
            {{ item.title }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  name: "MenuList",
  props: {
    items: {
      type: Array,
      required: true
    },
    dark: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  methods: {
    handleSelect(action) {
      this.$emit("select", action);
    }
  }
};
</script>

<style scoped lang="scss"></style>
