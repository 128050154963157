<template>
  <DialogFull :data="dialogSettings" @close="close">
    <template v-slot:dialog.body>
      <div class="row edit-internal-information-content">
        <InternalInformationSection
          :key="editKey"
          :initial-values="internalInformation"
          :onlyApproverVerifier="onlyApproverVerifier"
          @change="handleChange"
          class="col-12 col-md-6"
          ref="internalInformationForm"
        />
      </div>
    </template>
    <template v-slot:dialog.action>
      <PrimaryButton @click.native="save" :loading="isLoading">
        Save
      </PrimaryButton>
    </template>
  </DialogFull>
</template>

<script>
import DialogFull from "@/components/common/DialogFull";
import InternalInformationSection from "@/views/bookings/NewBooking/steps/components/InternalInformationSection";
import {
  EDIT_BOOKING_WITH_MESSAGE,
  EDIT_APROVER_VERIFIER
} from "@/store/modules/bookings/actions";
import { createNamespacedHelpers } from "vuex";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import { pick, get } from "lodash";
import generateId from "uuid/v4";

const { mapActions } = createNamespacedHelpers("bookings");

export default {
  name: "EditInternalInfo",
  components: {
    PrimaryButton,
    InternalInformationSection,
    DialogFull
  },
  computed: {
    dialogSettings() {
      return {
        title: "Edit Internal Information",
        dialog: this.isOpen
      };
    },
    initialData() {
      return pick(this.internalInfo, [
        "postNumber",
        "poNumber",
        "manager",
        "hireReason",
        "expensesAllowed",
        "assessmentsAllowed",
        "approver",
        "verifier",
        "client",
        "internalNotes"
      ]);
    },
    initialDataWithReason() {
      return {
        ...this.initialData,
        reason: this.initialData && this.initialData.hireReason
      };
    }
  },
  mounted() {
    this.internalInformation = this.initialDataWithReason;
  },
  data() {
    return {
      isLoading: false,
      internalInformation: this.initialDataWithReason,
      editKey: generateId()
    };
  },
  props: {
    isOpen: Boolean,
    internalInfo: Object,
    onlyApproverVerifier: Boolean
  },
  inject: ["currentBookingId"],
  methods: {
    ...mapActions({
      editBooking: EDIT_BOOKING_WITH_MESSAGE,
      editApproverVerifier: EDIT_APROVER_VERIFIER
    }),
    handleChange(editedInformation) {
      this.internalInformation = editedInformation;
    },
    close() {
      this.$emit("close");
    },
    save() {
      const isFormValid = this.$refs.internalInformationForm.isValid();
      if (isFormValid) {
        this.onlyApproverVerifier
          ? this.saveApproverAndVerifier()
          : this.saveBookingDetails();
      }
    },
    async saveBookingDetails() {
      this.isLoading = true;
      try {
        await this.editBooking({
          bookingId: this.currentBookingId,
          editedBooking: {
            ...this.internalInformation,
            assessmentsAllowed: !!this.internalInformation.assessmentsAllowed,
            expensesAllowed: !!this.internalInformation.expensesAllowed,
            hireReason: this.internalInformation.reason,
            poNumber: this.internalInformation.poNumber || "",
            timesheetApproverUserId: get(
              this.internalInformation,
              "approver.id"
            ),
            timesheetVerifierUserId: get(
              this.internalInformation,
              "verifier.id"
            ),
            costCode: this.internalInformation.client.costCode,
            client: this.internalInformation.client,
            internalNotes: this.internalInformation.internalNotes || ""
          }
        });
        this.close();
      } finally {
        this.isLoading = false;
      }
    },
    async saveApproverAndVerifier() {
      this.isLoading = true;
      try {
        await this.editApproverVerifier({
          bookingId: this.currentBookingId,
          editedApprover: {
            approver: { id: get(this.internalInformation, "approver.id") }
          },
          editedVerifier: {
            verifier: { id: get(this.internalInformation, "verifier.id") }
          }
        });
      } finally {
        this.close();
        this.isLoading = false;
      }
    }
  },
  watch: {
    isOpen() {
      this.editKey = generateId();
    }
  }
};
</script>

<style lang="scss">
.edit-internal-information-content {
  display: flex;
  justify-content: center;
  margin: 2rem 0 0 0;
}
</style>
