<template>
  <div>
    <LocationDialog
      v-if="newLocationDialogOpen"
      :is-open="newLocationDialogOpen"
      :loading="isLoading"
      title="New Location"
      actionName="Save Changes"
      @close="close"
      @submit="handleCreate"
    />
    <CreateButtonMobile
      @click.native="newLocationDialogOpen = true"
      v-if="isMobile"
    />
    <PrimaryButton @click.native="newLocationDialogOpen = true" v-else>
      New Location
    </PrimaryButton>
  </div>
</template>

<script>
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import LocationDialog from "@/views/settings/Organisation/Locations/dialogs/LocationDialog";
import { ORGANISATION_LOCATIONS_NAMESPACE } from "@/store/modules/organisations/organisation-locations";
import { createNamespacedHelpers } from "vuex";
import { CREATE_ORGANISATION_LOCATION } from "@/store/modules/organisations/organisation-locations/actions";
import CreateButtonMobile from "@/components/common/CreateButtonMobile";

const { mapActions } = createNamespacedHelpers(
  ORGANISATION_LOCATIONS_NAMESPACE
);

export default {
  name: "NewLocationAction",
  components: { LocationDialog, PrimaryButton, CreateButtonMobile },
  data() {
    return {
      newLocationDialogOpen: false,
      isLoading: false
    };
  },
  methods: {
    ...mapActions({
      createLocation: CREATE_ORGANISATION_LOCATION
    }),
    close() {
      this.newLocationDialogOpen = false;
    },
    async handleCreate(newLocation) {
      this.isLoading = true;
      try {
        await this.createLocation(newLocation);
        this.close();
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style scoped></style>
