<template>
  <div class="compliance-details">
    <DBSDetails v-if="isDBS" :compliance="compliance" />
    <ReferencesDetails v-if="isReferences" :references="references" />
    <RightToWorkDetails v-if="isRTW" :compliance="compliance" />

    <HCPCDetails
      v-if="isHCPC"
      label="Registration Number"
      :compliance="compliance"
    />
    <QTSDetails
      v-if="isQTS"
      label="Qualification Number"
      :compliance="compliance"
    />

    <!-- Completion/Conducted Date -->
    <CompletionDateDate
      v-if="
        compliance.documentValidFromDate &&
          isNotGroupedDetails &&
          compliance.checkVerified
      "
      :label="isFTF || isBL ? 'Date Conducted' : 'Completion Date'"
      :compliance="compliance"
    />

    <!-- Expiry Date -->
    <ExpiryDate
      v-if="
        compliance.documentValidToDate &&
          isNotGroupedDetails &&
          compliance.checkVerified
      "
      label="Expiry Date"
      :compliance="compliance"
    />

    <div v-if="compliance.checkVerified">
      <FileComponent
        v-for="file in files"
        :key="file.id"
        :file="file"
        @show="onShow"
        disabled
        customActions
      />
      <PDFViewFull :data="fileDialog" />
    </div>
  </div>
</template>

<script>
import DBSDetails from "@/components/workers/compliance/details/DBSDetails";
import HCPCDetails from "@/components/workers/compliance/details/ComplianceNumberDetails";
import QTSDetails from "@/components/workers/compliance/details/ComplianceNumberDetails";
import ReferencesDetails from "@/components/workers/compliance/details/ReferencesDetails";
import RightToWorkDetails from "@/components/workers/compliance/details/RightToWorkDetails";
import { COMPLIANCE_NAMES } from "@/constants/workers";
import FileComponent from "@/components/common/FileComponent";
import PDFViewFull from "@/components/common/PDFViewFull";
import { getToken } from "@/services/utils";
import ExpiryDate from "@/components/workers/compliance/details/ExpiryDateDetails";
import CompletionDateDate from "@/components/workers/compliance/details/ValidFromDateDetails";

const { DBS, HCPC, QTS, REFERENCES, RTW, BL, FTF, NIN, ST } = COMPLIANCE_NAMES;

export default {
  props: {
    compliance: {
      type: Object,
      required: true
    },
    references: {
      type: Array,
      required: false
    }
  },
  data() {
    return {
      fileDialog: {
        dialog: false
      }
    };
  },
  computed: {
    files() {
      return this.compliance.files;
    },
    isDBS() {
      return (
        this.compliance.complianceCheckName === DBS &&
        this.compliance.checkVerified
      );
    },
    isHCPC() {
      return (
        this.compliance.complianceCheckName === HCPC &&
        this.compliance.checkVerified
      );
    },
    isQTS() {
      return (
        this.compliance.complianceCheckName === QTS &&
        this.compliance.checkVerified
      );
    },
    isReferences() {
      return (
        this.compliance.complianceCheckName === REFERENCES &&
        this.compliance.checkVerified
      );
    },
    isRTW() {
      return (
        this.compliance.complianceCheckName === RTW &&
        this.compliance.checkVerified
      );
    },
    isBL() {
      return (
        this.compliance.complianceCheckName === BL &&
        this.compliance.checkVerified
      );
    },
    isFTF() {
      return (
        this.compliance.complianceCheckName === FTF &&
        this.compliance.checkVerified
      );
    },
    isNIN() {
      return (
        this.compliance.complianceCheckName === NIN &&
        this.compliance.checkVerified
      );
    },
    isST() {
      return (
        this.compliance.complianceCheckName === ST &&
        this.compliance.checkVerified
      );
    },
    isNotGroupedDetails() {
      return !this.isRTW && !this.isDBS && !this.isReferences;
    }
  },
  methods: {
    onShow(file) {
      const token = getToken();
      this.fileDialog.src = `${file.url}?token=${token}`;
      this.fileDialog.title = file.fileName;
      this.fileDialog.dialog = true;
    }
  },
  components: {
    DBSDetails,
    HCPCDetails,
    QTSDetails,
    ReferencesDetails,
    RightToWorkDetails,
    FileComponent,
    PDFViewFull,
    ExpiryDate,
    CompletionDateDate
  }
};
</script>

<style lang="scss">
.compliance-details {
  margin-left: 36px;

  p.secondary-text {
    margin-bottom: 0;
    margin-top: 10px;
  }

  .v-divider--inset:not(.v-divider--vertical) {
    margin-left: 0;
  }
}
</style>
