<template>
  <div>
    <LabeledText>
      <div class="agency-item-name">
        <p class="mb-0 font-weight-bold">
          {{ tier.tier_name }}
          <span class="ml-2 secondary-text font-weight-light">
            {{ tier.publishing_delay_hours }}
            {{ pluralize("hours", tier.publishing_delay_hours) }}
          </span>
        </p>
      </div>
      <template v-slot:action>
        <div class="d-flex">
          <EditPublishingScheduleTierAction
            :tier="tier"
            @updated="handleUpdated"
          />
          <DeleteTierAction
            v-if="canRemove"
            @deleted="handleUpdated"
            :tierId="id"
          />
          <AddAgenciesToTierAction
            @added="handleUpdated"
            :tierId="id"
            :tierAgencies="tierAgencies"
          />
        </div>
      </template>
    </LabeledText>
    <AgencyItem
      v-for="(agency, index) in tierAgencies"
      :key="index"
      :agency="agency"
      :tierId="id"
    />
    <v-divider class="mt-2 mb-2" />
  </div>
</template>

<script>
import LabeledText from "@/components/common/BaseDetails/LabeledText";
import AddAgenciesToTierAction from "@/views/settings/Organisation/PublishingSchedules/actions/AddAgenciesToTierAction";
import EditPublishingScheduleTierAction from "@/views/settings/Organisation/PublishingSchedules/actions/EditPublishingScheduleTierAction";
import DeleteTierAction from "@/views/settings/Organisation/PublishingSchedules/actions/DeleteTierAction";
import AgencyItem from "@/views/settings/Organisation/PublishingSchedules/AgencyItem";
import pluralize from "pluralize";
import { size } from "lodash";

export default {
  name: "PublishingTier",
  components: {
    AgencyItem,
    LabeledText,
    AddAgenciesToTierAction,
    DeleteTierAction,
    EditPublishingScheduleTierAction
  },
  props: {
    tier: {
      type: Object,
      required: true
    },
    publishing_schedule_id: {
      type: String,
      required: true
    }
  },
  computed: {
    id() {
      return this.tier.id;
    },
    canRemove() {
      return !size(this.tier.organisations);
    },
    tierAgencies() {
      return this.tier.organisations;
    }
  },
  data() {
    return {
      hover: false
    };
  },
  methods: {
    pluralize,
    handleUpdated() {
      this.$emit("updated");
    }
  }
};
</script>

<style scoped lang="scss">
.agency-item {
  min-height: 40px;
  padding: 0.25rem;

  &-name {
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &.hover {
    background-color: $background;
  }

  .agency-icon {
    width: 20px;
    height: 20px;
  }
}
</style>
