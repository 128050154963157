import { redirectWithPermissionCheck } from "@/router/index";
import { MAIN_NAVIGATION_ITEMS } from "@/router/constants";
import Bookings from "@/views/bookings/BookingList";
import BookingTabs from "@/views/bookings/BookingTabs";
import BookingDetails from "@/views/bookings/BookingDetails/BookingDetails";
import Candidates from "@/views/bookings/BookingDetails/components/Candidates";
import BookingShifts from "@/views/bookings/BookingShifts/BookingShifts";
import BookingTimesheets from "@/views/bookings/BookingTimesheets";

const { BOOKINGS } = MAIN_NAVIGATION_ITEMS;

export const bookingsRouter = [
  {
    path: "/bookings",
    name: BOOKINGS,
    component: Bookings,
    beforeEnter: (to, from, next) => {
      redirectWithPermissionCheck(next, BOOKINGS);
    }
  },
  {
    path: "/bookings/:id",
    name: "bookingTabs",
    redirect: "/bookings/:id/details",
    component: BookingTabs,
    beforeEnter: (to, from, next) => {
      redirectWithPermissionCheck(next, "bookings.view");
    },
    children: [
      {
        path: "details",
        name: "bookingDetails",
        component: BookingDetails,
        beforeEnter: (to, from, next) => {
          redirectWithPermissionCheck(next, "bookings.view");
        }
      },
      {
        path: "candidates",
        name: "bookingCandidates",
        component: Candidates,
        beforeEnter: (to, from, next) => {
          redirectWithPermissionCheck(next, "bookings.view");
        }
      },
      {
        path: "shifts",
        name: "bookingShifts",
        component: BookingShifts,
        beforeEnter: (to, from, next) => {
          redirectWithPermissionCheck(next, "bookings.view");
        }
      },
      {
        path: "timesheets",
        name: "bookingTimesheets",
        component: BookingTimesheets,
        beforeEnter: (to, from, next) => {
          redirectWithPermissionCheck(next, "bookings.view");
        }
      }
    ]
  }
];
