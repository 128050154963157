<template>
  <div class="organisation-list table-row-pointer">
    <div class="toolbar">
      <BaseSearchBar @search="text => (searchText = text)" class="mr-2" />
      <NewPublishingScheduleAction />
    </div>
    <Datatable
      :headers="headers"
      :itemsPerPage="15"
      :items="items"
      :is-loading="isFetchingScheduleTemplates"
      :search="searchText"
      :customFilter="customFilter"
      @onSelect="handleSelect"
      hideRowSeparator
      disableFiltering
    >
      <template v-slot:name="{ item: { name } }">
        {{ name }}
      </template>
    </Datatable>
  </div>
</template>

<script>
import BaseSearchBar from "@/components/common/BaseSearchBar";
import Datatable from "@/components/common/Datatable";
import { createNamespacedHelpers } from "vuex";
import { AUTH_NAMESPACE } from "@/store/modules/auth";
import { copyState } from "@/utils/helpers";
import { get, map, join } from "lodash";
import NewPublishingScheduleAction from "@/views/settings/Organisation/PublishingSchedules/actions/NewPublishingScheduleAction";
import { FETCH_PUBLISHING_SCHEDULE_TEMPLATES } from "@/store/modules/organisations/organisation-schedules/actions";
import { ORGANISATION_SCHEDULES_NAMESPACE } from "@/store/modules/organisations/organisation-schedules";
import { customTableFussyFilter } from "@/utils/filters";

const { mapActions, mapState } = createNamespacedHelpers(
  ORGANISATION_SCHEDULES_NAMESPACE
);
const { mapState: mapAuthState } = createNamespacedHelpers(AUTH_NAMESPACE);

export default {
  name: "PublishingSchedulesList",
  components: {
    NewPublishingScheduleAction,
    Datatable,
    BaseSearchBar
  },
  data() {
    return {
      searchText: ""
    };
  },
  computed: {
    ...mapState(
      copyState(["publishingScheduleTemplates", "isFetchingScheduleTemplates"])
    ),
    ...mapAuthState(copyState(["userData"])),
    headers() {
      return [
        {
          text: "Name",
          value: "name"
        },
        {
          text: "Tags",
          value: "formattedTags"
        }
      ];
    },
    items() {
      return map(
        this.publishingScheduleTemplates,
        publishingScheduleTemplate => {
          const { tags } = publishingScheduleTemplate;
          const formattedTags = join(tags, ", ");
          return { ...publishingScheduleTemplate, formattedTags };
        }
      );
    },
    searchKeys() {
      return map(this.headers, "value");
    },
    selfOrganisationId() {
      return get(this.userData, "organisation.id");
    }
  },
  created() {
    if (this.selfOrganisationId) {
      this.fetchSchedules(this.selfOrganisationId);
    }
  },
  methods: {
    ...mapActions({
      fetchSchedules: FETCH_PUBLISHING_SCHEDULE_TEMPLATES
    }),
    handleSelect({ id }) {
      this.$router.push({ name: "scheduleDetails", params: { id } });
    },
    customFilter(value, searchValue, item) {
      return customTableFussyFilter(searchValue, item, this.searchKeys);
    }
  },
  watch: {
    selfOrganisationId(organisationId) {
      this.fetchSchedules(organisationId);
    }
  }
};
</script>

<style lang="scss"></style>
