var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cost-estimate"},[_c('BaseDetails',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h2',{staticClass:"pb-3"},[_vm._v("Pay Rate")]),(!_vm.isLoading)?_c('Permissioned',{attrs:{"section":"settings"}},[_c('EditBookingChargesAction',{attrs:{"permission":"edit-booking-templates","bookingTemplate":_vm.bookingTemplate}})],1):_vm._e()]},proxy:true},{key:"body",fn:function(){return [(_vm.isLoading)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):(!_vm.isEmpty(_vm.bookingTemplate.templatePayRates))?_c('div',[_c('v-tabs',{model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},_vm._l((_vm.activitiesNames),function(activityName){return _c('v-tab',{key:activityName},[_vm._v(" "+_vm._s(activityName)+" ")])}),1),_c('v-tabs-items',{model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},_vm._l((_vm.activitiesNames),function(activityName){return _c('v-tab-item',{key:activityName},_vm._l((_vm.sortBy(
                Object.keys(_vm.allActivities[activityName])
              ).reverse()),function(awr,index){return _c('div',{key:index},[_c('div',{staticClass:"mt-3"},[_c('span',{staticClass:"cost-item-title"},[_vm._v(" "+_vm._s(_vm.getAwrName(awr))+" ")]),_vm._l((_vm.allActivities[activityName][
                    awr
                  ].templatePayRateItems),function(templatePayRateItem){return _c('div',{key:templatePayRateItem.id,staticClass:"cost-item-wrapper",class:{
                    'with-divider': _vm.isTotalWorkerCost(
                      templatePayRateItem.costName
                    )
                  }},[_c('v-divider',{staticClass:"divider ma-0"}),_c('div',{staticClass:"cost-item"},[_c('div',[_vm._v(_vm._s(templatePayRateItem.costName))]),_c('div',{staticClass:"values"},[(templatePayRateItem.costRate)?_c('div',{staticClass:"value percentage"},[_vm._v(" "+_vm._s(templatePayRateItem.costRate)+" ")]):_vm._e(),_c('div',{staticClass:"value"},[_vm._v(" "+_vm._s(_vm._f("currency")(templatePayRateItem.amount,templatePayRateItem.currencyCode))+" ")])])]),_c('v-divider',{staticClass:"divider ma-0"})],1)})],2)])}),0)}),1)],1):_vm._e()]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }