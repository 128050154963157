<template>
  <div class="d-flex p-2 job-item">
    <div class="job-item__icon-container">
      <v-img
        src="@/assets/svg/booking-icon.svg"
        class="job-item__icon-container__icon"
        size="50"
        label="Booking Icon"
      />
    </div>
    <div class="job-item__label">{{ role }}</div>
  </div>
</template>

<script>
export default {
  name: "JobItem",
  data() {
    return {
      hovered: false
    };
  },
  props: {
    role: String,
    description: String,
    loading: Boolean
  }
};
</script>

<style scoped lang="scss"></style>
