import {
  APPLICATION_STATUSES,
  SORTED_APPLICATION_STATUSES
} from "@/constants/applications";
import {
  every,
  find,
  first,
  get,
  lowerCase,
  map,
  partition,
  reduce,
  sortBy,
  union,
  upperCase
} from "lodash";

export const getApplicationStatusInfo = status =>
  APPLICATION_STATUSES[upperCase(status)] || {
    title: status,
    color: "gray"
  };

export const getApplicationStatusColor = ({ status }) =>
  getApplicationStatusInfo(status).color;

export const getApplicationStatusTitle = ({ status }) =>
  getApplicationStatusInfo(status).title;

export const sortApplicationStatuses = statuses =>
  statuses.sort(
    (a, b) =>
      SORTED_APPLICATION_STATUSES.indexOf(a) -
      SORTED_APPLICATION_STATUSES.indexOf(b)
  );

const groupByEvaluationAndApplicationStatus = applications => {
  const sortedApplications = sortBy(applications, "user.lastName");
  return reduce(
    sortedApplications,
    (grouped, application) => {
      const propertyName = "status";
      const status = checkAllocatedApplication(application.shifts)
        ? "allocated"
        : checkConfirmedApplicationShifts(application.shifts)
        ? "confirmed"
        : application[propertyName];
      const groupedByProperty = grouped[status];
      if (!groupedByProperty) {
        grouped[status] = [application];
      } else {
        grouped[status] = [...groupedByProperty, application];
      }
      return grouped;
    },
    {}
  );
};

export const checkAllocatedApplication = shifts => {
  return (
    shifts &&
    shifts.length &&
    every(shifts, shift => lowerCase(shift.status) === "allocated")
  );
};

export const checkConfirmedApplicationShifts = shifts => {
  if (!shifts || shifts.length === 0) {
    return false;
  }
  return every(shifts, shift => lowerCase(shift.status) === "confirmed");
};
export const missingComplianceChecks = (
  requiredCompliance,
  workerCompliance
) => {
  // DBS is handled differently to all the other checks!
  // Booking may require one or more of "DBS", "Basic DBS" or "Enhanced DBS Check"
  // Split the booking's compliance requirements in to two arrays, the DBS checks and everything else.
  const [dbsCompliance, otherCompliance] = partition(
    requiredCompliance,
    check => check.includes("DBS")
  );

  let dbsChecks = [];

  function verifyDBS(complianceCheckName, documentTypeNames) {
    if (dbsCompliance.includes(complianceCheckName)) {
      dbsChecks[complianceCheckName] = workerCompliance.some(check => {
        return (
          check.complianceCheckName === "DBS" &&
          documentTypeNames.includes(check.documentTypeName) &&
          check.checkVerified === true
        );
      })
        ? "yes"
        : "no";
    }
  }

  // Check for each of the DBS types.
  // If a check is not required, it will not be added to the dbsCompliance array.
  // A required check will have 'yes' or 'no' to show the worker's compliance.
  if (dbsCompliance.length) {
    verifyDBS("Basic DBS Check", [
      "Basic",
      "Standard",
      "Enhanced",
      "Enhanced Child",
      "Enhanced Adult",
      "Enhanced Child & Adult"
    ]);
    verifyDBS("DBS", [
      "Standard",
      "Enhanced",
      "Enhanced Child",
      "Enhanced Adult",
      "Enhanced Child & Adult"
    ]);
    // If the booking requires an enhanced DBS any verified enhanced document type is acceptable.
    verifyDBS("Enhanced DBS Check", [
      "Enhanced",
      "Enhanced Child",
      "Enhanced Adult",
      "Enhanced Child & Adult"
    ]);
  }

  // Calculating combined list of DBS, missing and "required but not completed" compliance checks
  return union(
    // Required checks missing from the user's list
    map(
      otherCompliance.filter(check => !workerCompliance.includes(check)),
      "complianceCheckName"
    ),

    // Incomplete DBS checks
    Object.keys(dbsChecks).filter(key => dbsChecks[key] === "no"),

    // Other incomplete checks required by booking
    otherCompliance.filter(
      check =>
        !workerCompliance.some(
          compliance =>
            compliance.complianceCheckName === check &&
            compliance.checkVerified === true
        )
    )
  ).filter(item => item);
};

export const areBothStatusesEvaluated = ({ statusEntriesToCompare }) => {
  return every(
    statusEntriesToCompare,
    statusEntry => first(statusEntry[1]).status === "evaluated"
  );
};

export const sortByApplicationStatus = groupedApplicationsObj => {
  return Object.entries(groupedApplicationsObj)
    .sort((a, b) => {
      // if (areBothStatusesEvaluated({ statusEntriesToCompare: [a, b] })) {
      //   // compare evaluation statuses by display order
      //   return (
      //     getDisplayOrder(a[0], evaluationStatuses) -
      //     getDisplayOrder(b[0], evaluationStatuses)
      //   );
      // }
      // compare by application status
      return (
        SORTED_APPLICATION_STATUSES.indexOf(first(a[1]).status) -
        SORTED_APPLICATION_STATUSES.indexOf(first(b[1]).status)
      );
    })
    .reduce((sortedObj, item) => {
      sortedObj[item[0]] = item[1];
      return sortedObj;
    }, {});
};

export const getGroupedAndSortedApplications = applications => {
  return sortByApplicationStatus(
    groupByEvaluationAndApplicationStatus(applications)
  );
};

export const getDisplayOrder = (statusName, evaluationStatuses) => {
  return get(find(evaluationStatuses, { name: statusName }), "displayOrder");
};
