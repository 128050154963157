export const getFormattedLocation = location => {
  if (!location) {
    return "";
  }

  const addressLine1 = location.locationAddressLine1 || location.line1 || "";
  const addressLine2 = location.locationAddressLine2 || location.line2 || "";
  const addressLine3 = getOptionalAddressValue(
    location.locationAddressLine3 || location.line3
  );

  return `${addressLine1}${
    addressLine2 ? ", " + addressLine2 : ""
  }${addressLine3}`;
};

const getOptionalAddressValue = optionalAddress => {
  return optionalAddress ? `, ${optionalAddress}` : "";
};

export const locationUtils = {
  getFormattedLocation
};
