<template>
  <v-menu max-width="360">
    <template v-slot:activator="{ on }">
      <div class="icon-account-wrapper" v-on="on">
        <inline-svg
          width="20"
          :src="require('@/assets/svg/navigation-drawer/user-account_blue.svg')"
        />
        <v-icon color="primary" size="1rem">
          mdi-chevron-down
        </v-icon>
      </div>
    </template>
    <v-list>
      <v-list-item-group class="py-2" aria-label="Profile details">
        <SwitchProfile :user="user" />
        <v-divider class="my-2" />
        <v-list-item v-if="canImpersonate" @click="openImpersonateDialog">
          <v-list-item-action>
            <v-icon>
              mdi-account-multiple-outline
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Impersonate User
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="signOut">
          <v-list-item-action>
            <v-icon>
              mdi-logout
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Log Out</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { getNameInitials } from "@/utils/helpers";
import { get } from "lodash";
import {
  getAuthLogoutUrl,
  clearLocalStorageItems
} from "@/services/APIService";
import { NS_TOGGLE_IMPERSONATE_DIALOG } from "@/store/modules/layout/mutation-types";
import { AUTH_NAMESPACE } from "@/store/modules/auth";
import SwitchProfile from "@/components/common/SwitchProfile";
import InlineSvg from "vue-inline-svg";

const { mapState } = createNamespacedHelpers(AUTH_NAMESPACE);

export default {
  name: "NavigationAccountMenu",
  components: { SwitchProfile, InlineSvg },
  computed: {
    ...mapState({
      user: state => state.userData,
      permissions: state => state.permissions
    }),
    userInitials() {
      return getNameInitials(this.user);
    },
    userFirstName() {
      return this.user.firstName;
    },
    userLastName() {
      return this.user.lastName;
    },
    canImpersonate() {
      return get(this.permissions, "authentication.impersonate-user");
    }
  },
  methods: {
    openImpersonateDialog() {
      this.$store.commit(NS_TOGGLE_IMPERSONATE_DIALOG, true);
    },
    signOut() {
      clearLocalStorageItems();
      window.location.href = getAuthLogoutUrl();
    }
  }
};
</script>

<style lang="scss">
.icon-account-wrapper {
  cursor: pointer;
  height: 48px;
  width: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 4px;
}
</style>
