<template>
  <div class="schedule-details">
    <AppLayout
      parent="/settings/organisation/schedules"
      isDetails
      :title="name"
    >
      <template v-slot:header>
        <div class="button-wrapper">
          <EditPublishingScheduleAction
            v-if="!isFetchingCurrentSchedule"
            :publishingSchedule="currentPublishingSchedule"
          />
        </div>
      </template>
      <template v-slot:body>
        <div class="wrapper">
          <div class="tags">
            <div class="header-item">
              Tags
            </div>
            <div class="chip-loader" v-if="isFetchingCurrentSchedule">
              <v-skeleton-loader
                type="chip"
                v-for="i in 3"
                :key="i"
                class="mr-3"
              />
            </div>
            <div v-else>
              <v-chip
                v-for="(tag, index) in currentPublishingSchedule.tags"
                :key="index"
              >
                {{ tag }}
              </v-chip>
            </div>
          </div>
          <div class="header-item">
            Tiers
          </div>
          <div v-if="isFetchingCurrentSchedule">
            <v-skeleton-loader
              type="list-item-three-line"
              v-for="i in 3"
              :key="i"
              class="mr-3"
            />
          </div>
          <div v-else>
            <PublishingTier
              :tier="tier"
              :publishing_schedule_id="currentScheduleId"
              v-for="tier in sortedTiers"
              :key="tier.id"
              @updated="handleUpdatedTier"
            />
            <AddPublishingScheduleTierAction
              :publishing_schedule_id="currentScheduleId"
              @added="handleUpdatedTier"
            />
          </div>
        </div>
      </template>
    </AppLayout>
  </div>
</template>

<script>
import AppLayout from "@/components/common/base-layouts/AppLayout";
import PublishingTier from "@/views/settings/Organisation/PublishingSchedules/PublishingTier";
import AddPublishingScheduleTierAction from "@/views/settings/Organisation/PublishingSchedules/actions/AddPublishingScheduleTierAction";
import EditPublishingScheduleAction from "@/views/settings/Organisation/PublishingSchedules/actions/EditPublishingScheduleAction";
import { createNamespacedHelpers } from "vuex";
import { copyState } from "@/utils/helpers";
import { GET_PUBLISHING_SCHEDULE } from "@/store/modules/organisations/organisation-schedules/actions";
import { ORGANISATION_SCHEDULES_NAMESPACE } from "@/store/modules/organisations/organisation-schedules";
import { orderBy } from "lodash";

const { mapActions, mapState } = createNamespacedHelpers(
  ORGANISATION_SCHEDULES_NAMESPACE
);

export default {
  name: "PublishingScheduleDetails",
  components: {
    AppLayout,
    PublishingTier,
    AddPublishingScheduleTierAction,
    EditPublishingScheduleAction
  },
  created() {
    this.getSchedule(this.currentScheduleId);
  },
  methods: {
    ...mapActions({
      getSchedule: GET_PUBLISHING_SCHEDULE
    }),
    handleUpdatedTier() {
      this.getSchedule(this.currentScheduleId);
    }
  },
  computed: {
    ...mapState(
      copyState(["currentPublishingSchedule", "isFetchingCurrentSchedule"])
    ),
    sortedTiers() {
      return orderBy(
        this.currentPublishingSchedule.tiers,
        "publishing_delay_hours"
      );
    },
    currentScheduleId() {
      return this.$route.params.id;
    },
    name() {
      return this.currentPublishingSchedule
        ? this.currentPublishingSchedule.name
        : "";
    }
  }
};
</script>

<style lang="scss">
.schedule-details {
  margin-left: auto;
  margin-right: auto;
  width: 1000px;

  .button-wrapper {
    display: flex;
    justify-content: flex-end;
  }
  .wrapper {
    margin-bottom: 100px;

    .chip-loader {
      display: flex;
    }
  }
  .tags {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    .v-chip {
      font-size: 18px;
      font-weight: normal;
      margin-right: 0.5rem;
    }
  }
  .header-item {
    font-size: 22px;
    font-weight: bold;
  }
}
</style>
