<template>
  <div class="shift-details multiple-select-list">
    <div class="d-flex multiselect-wrapper" :class="{ 'py-1': isDashboard }">
      <div @click="$emit('select')">
        <v-checkbox
          v-if="showCheckBox"
          class="checkbox-large-icon mr-4"
          :input-value="isSelected"
        />
        <div class="icon-wrapper" v-else>
          <v-icon color="black">mdi-calendar-range-outline</v-icon>
        </div>
      </div>
      <div class="d-flex justify-space-between full-width" @click="handleClick">
        <div class="d-flex flex-column align-baseline">
          <h3>{{ title }}</h3>
          <p class="secondary-text mb-0 mt-1">
            {{ startDate | shiftHours }} - {{ endDate | shiftHours }} |
            {{ location }}
          </p>
        </div>
        <div class="d-flex flex-column text-center">
          <div class="d-flex justify-space-between">
            <p class="mb-0">
              {{ shift.workersConfirmed }} of {{ shift.workersRequired }}
            </p>
            <v-icon class="ml-1" size="23" color="black">
              mdi-account-outline
            </v-icon>
          </div>
          <LinearProgress
            :color="getColorByPercentage(shiftProgress)"
            :value="shiftProgress"
          />
        </div>
      </div>
    </div>
    <div v-if="expanded">
      <div
        class="d-flex justify-space-between mx-10 my-2"
        v-for="schedule in filteredSchedules"
        :key="schedule.id"
      >
        <div class="d-flex align-center">
          <v-icon color="black">mdi-domain</v-icon>
          <p class="ml-1 mb-0">{{ schedule.agency.name }}</p>
        </div>
        <div>
          <div class="d-flex align-center" v-if="schedule.workerQuota !== null">
            <LinearProgress
              :color="getColorByPercentage(quotaProgress(schedule))"
              :value="quotaProgress(schedule)"
            />
            <p class="mb-0 ml-1">
              {{ schedule.confirmed }} of {{ schedule.workerQuota }}
            </p>
          </div>
          <div v-else>
            {{
              `${schedule.confirmed} ${pluralize(
                "workers",
                schedule.confirmed
              )}`
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import pluralize from "pluralize";
import { filter, size, first } from "lodash";
import LinearProgress from "@/components/common/LinearProgress";
import {
  getColorByPercentage,
  getSchedulesWithConfirmedWorkersCount
} from "@/utils/shifts";
import { getFormattedLocation } from "@/utils/locations";
import { isMSPOrSuperAdmin } from "@/utils/users";

const { mapState } = createNamespacedHelpers("auth");

export default {
  props: {
    shift: {
      type: Object,
      required: true
    },
    status: {
      type: String,
      required: false
    },
    isSelected: Boolean,
    showCheckBox: Boolean,
    isDashboard: Boolean
  },
  components: { LinearProgress },
  data() {
    return { expanded: false };
  },
  computed: {
    ...mapState(["permissions", "userData"]),
    endDate() {
      return this.shift.endDate;
    },
    startDate() {
      return this.shift.startDate;
    },
    showId() {
      return this.shift.shift && this.shift.shift.id
        ? this.shift.shift.id
        : this.shift.id;
    },
    canViewFilledStatus() {
      return this.permissions["applications.allocate"];
    },
    approvedShifts() {
      return filter(
        this.shift.applicationShifts,
        ({ status }) => status === "confirmed" || status === "allocated"
      );
    },
    submittedShifts() {
      return filter(
        this.shift.applicationShifts,
        ({ status }) => status === "submitted"
      );
    },
    booking() {
      return this.shift.booking;
    },
    unallocatedShifts() {
      return this.shift.workersRequired - size(this.approvedShifts);
    },
    bookingTitle() {
      return this.booking && this.booking.title;
    },
    shiftProgress() {
      return (this.shift.workersConfirmed / this.shift.workersRequired) * 100;
    },
    location() {
      return (
        this.booking.location && getFormattedLocation(this.booking.location)
      );
    },
    client() {
      return this.booking && this.booking.client;
    },
    activityTypeName() {
      const payRate =
        this.shift.pattern &&
        this.shift.pattern.payRates &&
        first(this.shift.pattern.payRates);
      return payRate ? payRate.activityTypeName : "";
    },
    title() {
      const activityTypeName = this.activityTypeName
        ? `- ${this.activityTypeName}`
        : "";
      const clientName = this.client ? `, ${this.client.name}` : "";
      return `${this.bookingTitle} ${activityTypeName}${clientName} - B${this.booking.id}`;
    },
    schedules() {
      const { applicationShifts } = this.shift;
      return (
        this.booking &&
        applicationShifts &&
        getSchedulesWithConfirmedWorkersCount({
          schedules: this.booking.publishingSchedules,
          applicationShifts,
          shiftQuotas: this.shift.quotas,
          shiftId: this.shift.id
        })
      );
    },
    isMSPAdmin() {
      return isMSPOrSuperAdmin(this.userData.roles);
    },
    userAgencyId() {
      return this.userData.organisation && this.userData.organisation.id;
    },
    filteredSchedules() {
      return this.isMSPAdmin
        ? this.schedules
        : filter(
            this.schedules,
            ({ agency }) => Number(agency.id) === Number(this.userAgencyId)
          );
    }
  },
  methods: {
    pluralize,
    getColorByPercentage,
    size,
    quotaProgress({ confirmed, workerQuota }) {
      return (confirmed / workerQuota) * 100;
    },
    handleClick() {
      if (this.isDashboard) {
        this.$router.push({
          path: `/bookings/${this.booking.id}/shifts`
        });
      }
      this.expanded = !this.expanded;
    }
  }
};
</script>

<style lang="scss">
.shift-details {
  &:hover {
    background: #f9f9f9;
  }
  border-radius: 5px;
}
</style>
