import MOCKED_ORGANISATION_SCHEDULE from "@/constants/organisations/schedule";
import { PUBLISHING_SCHEDULE_TEMPLATE_MODEL } from "@/models/publishing-schedule-template-model";
import { SCHEDULE_TIER_MODEL } from "@/models/schedule-tier-model";
import { ORGANISATIONS_MODEL } from "@/models/organisation-model";
import { TIERS, include, nested, ORGANISATIONS } from "@/models/relationships";
import JSONApiService from "@/services/JSONApiService";

const organisationSchedulesApi = ({ commit }) => ({
  get: async id =>
    JSONApiService(commit)
      .one(PUBLISHING_SCHEDULE_TEMPLATE_MODEL, id)
      .get(include(TIERS, nested(TIERS, ORGANISATIONS))),
  update: async data =>
    JSONApiService(commit).update(
      PUBLISHING_SCHEDULE_TEMPLATE_MODEL,
      data,
      include(TIERS, nested(TIERS, ORGANISATIONS))
    ),
  create: async data =>
    JSONApiService(commit).create(PUBLISHING_SCHEDULE_TEMPLATE_MODEL, data),
  delete: async () => {
    return Promise.resolve({ data: MOCKED_ORGANISATION_SCHEDULE });
  },
  deleteAgencyFromTier: async (tierId, agency) =>
    JSONApiService(commit)
      .one(SCHEDULE_TIER_MODEL, tierId)
      .relationships()
      .all(ORGANISATIONS_MODEL)
      .destroy([agency]),
  addAgenciesToScheduleTier: async (tierId, organisations) =>
    JSONApiService(commit)
      .one(SCHEDULE_TIER_MODEL, tierId)
      .relationships()
      .all(ORGANISATIONS_MODEL)
      .post(organisations),
  addTier: async data =>
    JSONApiService(commit).create(SCHEDULE_TIER_MODEL, data),
  editTier: async ({ id, editedTier }) =>
    JSONApiService(commit)
      .one(SCHEDULE_TIER_MODEL, id)
      .patch(editedTier),
  deleteTier: async id =>
    JSONApiService(commit)
      .one(SCHEDULE_TIER_MODEL, id)
      .destroy(),
  getPublishingScheduleTemplates: async () =>
    JSONApiService(commit)
      .all(PUBLISHING_SCHEDULE_TEMPLATE_MODEL)
      .get(include(TIERS, nested(TIERS, ORGANISATIONS)))
});

export default organisationSchedulesApi;
