<template>
  <div>
    <div class="d-flex justify-space-between">
      <p class="mb-3 font-weight-bold">{{ costCategoryDisplayName }}</p>
      <div @click="onRemove">
        <v-icon>mdi-close</v-icon>
      </div>
    </div>
    <div class="cost-category">
      <v-row>
        <v-col class="col-12">
          <div class="d-flex">
            <div class="full-width">
              <v-label>Pre-AWR</v-label>
              <div class="mr-5">
                <div
                  class="text-with-select"
                  :class="{ 'awr-error': preAwrValidationError }"
                >
                  <Select
                    label=""
                    item-text="text"
                    item-value="value"
                    v-model="costData.preAwrCostTypeName"
                    :items="costTypes"
                    hide-details
                  />
                  <div class="separator" />
                  <v-text-field
                    type="number"
                    class="text-field"
                    :disabled="loading"
                    v-model="costData.preAwr"
                    hide-details
                  />
                </div>
                <div class="text-error" v-if="preAwrValidationError">
                  {{ preAwrValidationError }}
                </div>
              </div>
            </div>
            <div class="full-width">
              <v-label>Post-AWR</v-label>
              <div
                class="text-with-select"
                :class="{ 'awr-error': postAwrValidationError }"
              >
                <Select
                  label=""
                  item-text="text"
                  item-value="value"
                  v-model="costData.postAwrCostTypeName"
                  :items="costTypes"
                  hide-details
                />
                <div class="separator" />
                <v-text-field
                  type="number"
                  class="text-field"
                  :disabled="loading"
                  hide-details
                  v-model="costData.postAwr"
                />
              </div>
              <div class="text-error" v-if="postAwrValidationError">
                {{ postAwrValidationError }}
              </div>
            </div>
          </div>
        </v-col>
        <!-- TODO: Remove when not needed in the future
        <v-col class="col-md-6 col-12">
          <div class="d-flex">
            <div class="full-width">
              <v-label>Pay Band</v-label>
              <div class="pay-band-range">
                <v-text-field
                  type="number"
                  prepend-inner-icon="mdi-currency-gbp"
                  class="text-field"
                  :disabled="loading"
                  hide-details
                  v-model="costData.payRateBandLower"
                  :error-messages="payBandRangeError"
                />
                <div class="range-separator">
                  to
                </div>
                <v-text-field
                  type="number"
                  prepend-inner-icon="mdi-currency-gbp"
                  class="text-field"
                  :disabled="loading"
                  hide-details
                  v-model="costData.payRateBandUpper"
                  :error-messages="payBandRangeError"
                />
              </div>
              <div class="text-error" v-if="payBandRangeError">
                {{ payBandRangeError }}
              </div>
            </div>
          </div>
        </v-col> -->
      </v-row>
    </div>
  </div>
</template>

<script>
import Select from "@/components/common/Select";
import {
  getAwrValidationErrors,
  getPayBandValidationError
} from "@/views/settings/Organisation/BookingTemplates/utils/validation";

import { AGENCY_NAMESPACE } from "@/store/modules/agencies";
import { FETCH_ALL_AGENCIES } from "@/store/modules/agencies/action-types";
import { createNamespacedHelpers } from "vuex";
import { find, merge } from "lodash";

const { mapState, mapActions } = createNamespacedHelpers(AGENCY_NAMESPACE);

export default {
  name: "Cost",
  components: { Select },
  props: {
    id: Number,
    cost: Object,
    showValidationErrors: Boolean,
    costCategories: Array
  },
  data() {
    return {
      costData: {
        currencyCode: "GBP",
        preAwr: null,
        postAwr: null,
        payRateBandUpper: null,
        payRateBandLower: null,
        preAwrCostTypeName: "fixed",
        postAwrCostTypeName: "fixed",
        costCategoryName: ""
      },
      loading: false,
      costTypes: [
        {
          text: "£",
          value: "fixed"
        },
        {
          text: "%",
          value: "percentage"
        }
      ]
    };
  },
  computed: {
    ...mapState({
      agencies: state => state.agencies,
      isLoading: state => state.isFetchingData
    }),
    costCategoryDisplayName() {
      const costCategory =
        find(this.costCategories, {
          name: this.costData.costCategoryName
        }) || {};
      return costCategory.displayName;
    },
    preAwrValidationError() {
      return (
        this.showValidationErrors &&
        getAwrValidationErrors(
          this.costData.preAwr,
          this.costData.preAwrCostTypeName === "percentage"
        )
      );
    },
    postAwrValidationError() {
      return (
        this.showValidationErrors &&
        getAwrValidationErrors(
          this.costData.postAwr,
          this.costData.postAwrCostTypeName === "percentage"
        )
      );
    },
    payBandRangeError() {
      return this.showValidationErrors
        ? getPayBandValidationError(
            this.costData.payRateBandLower,
            this.costData.payRateBandUpper
          )
        : "";
    }
  },
  created() {
    merge(this.costData, this.cost);
    if (this.costData.costCategoryName === "agency_fee") {
      this.getAllAgencies({ "filter[organisation_type]": "agency" });
    }
  },
  methods: {
    ...mapActions({
      getAllAgencies: FETCH_ALL_AGENCIES
    }),
    validate() {
      return (
        this.costData.preAwr && this.costData.postAwr && !this.payBandRangeError
      );
    },
    onRemove() {
      this.$emit("remove");
    },
    getCost() {
      return this.costData;
    }
  }
};
</script>

<style lang="scss">
.cost-category {
  width: 100%;
  display: flex;

  .v-text-field .v-input__slot {
    height: 50px;
  }
  .v-input,
  .v-input.select--items,
  .v-input.v-text-field {
    margin: 0;
    padding: 0;
  }

  .text-error {
    font-size: 0.8rem;
  }

  .text-with-select {
    margin-top: 10px;
    border-radius: 4px;
    border: 1px solid $default-line;
    display: flex;
    align-items: center;
    height: 50px;

    &:not(.awr-error):focus-within {
      border: 2px solid $primary;

      .separator {
        border: 1px solid $primary;
      }
    }
    &.awr-error {
      border: 2px solid $error;

      .separator {
        border: 1px solid $error;
      }
    }
    .v-input,
    .v-input.select--items {
      margin: 0;
      padding: 0;
    }
    .v-text-field .v-input__slot {
      height: 50px;
      box-shadow: none;
      border: none !important;
      width: 70px;
    }

    .text-field .v-input__control .v-input__slot {
      width: auto;
    }
    .separator {
      height: 50px;
      width: 1px;
      background-color: $default-line;
    }
  }
  .pay-band-range {
    display: flex;
    margin-top: 10px;

    .range-separator {
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
