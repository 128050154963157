export const TIMESHEET_MODEL = "timesheet";

const timesheetModel = {
  submitDate: "",
  currency_code: "",
  cost: 0,
  weekCommencing: "",
  approvalDate: "",
  status: "",
  verificationDate: "",
  workerConfirmed: false,
  totalMinutes: 0,
  awr: 0,
  variationFromExpectedTotal: 0,
  weekEnding: "",
  allowedTransitions: [],
  createdAt: "",
  updatedAt: "",
  title: "",
  approver: "",
  verifier: "",
  approvedBy: "",
  timeUnitName: "",
  invoiceNumbers: [],
  worker_id: "",
  booking_id: "",
  verifiedBy: "",
  booking: {
    jsonApi: "hasOne",
    type: "booking"
  },
  expenses: {
    jsonApi: "hasMany",
    type: "expense"
  },
  entries: {
    jsonApi: "hasMany",
    type: "entry"
  },
  comments: {
    jsonApi: "hasMany",
    type: "comment"
  },
  rootClient: {
    jsonApi: "hasOne",
    type: "organisations"
  },
  worker: {
    jsonApi: "hasOne",
    type: "users"
  }
};

export const timesheetModelActions = [
  "approve",
  "verify",
  "dispute",
  "credit",
  "recall"
];

export default timesheetModel;
