<template>
  <Chip :color="color">
    <slot />
  </Chip>
</template>

<script>
import Chip from "@/components/common/Chip";

export const STATUS = {
  ACTIVE: "active",
  INACTIVE: "inactive"
};

export const getColorByStatus = status => {
  switch (status.toLowerCase()) {
    case STATUS.ACTIVE:
      return "green";
    case STATUS.INACTIVE:
    default:
      return "gray";
  }
};

export default {
  name: "ActivityStatusChip",
  components: { Chip },
  computed: {
    color() {
      return getColorByStatus(this.status);
    }
  },
  props: {
    status: String
  }
};
</script>

<style scoped></style>
