<template>
  <div class="agency__container">
    <NewAgencyAction
      @close="closeNewAgencyDialog"
      :isOpen="isNewAgencyDialogOpen"
    />
    <EditAgencyAction
      @close="closeEditAgencyDialog"
      :isOpen="isEditAgencyDialogOpen"
      :agency="selectedAgency"
    />
    <app-layout title="Agencies" class="agency">
      <template v-slot:header>
        <snackbar-error
          :isVisible="isErrorVisible"
          :error="error"
          :hideError="hideError"
        />
        <Snackbar :data="snackbar" />
        <BaseSearchBar
          :class="{ 'ml-3': !isMobile }"
          @search="text => (filters.search = text)"
        />
        <Permissioned section="agencies">
          <PrimaryButton
            class="desktop-button"
            @click.native="addAgency"
            v-if="!isMobile"
            permission="create"
          >
            New Agency
            <v-icon small>mdi-plus</v-icon>
          </PrimaryButton>
        </Permissioned>
      </template>
      <template v-slot:body>
        <Datatable
          :headers="headers"
          :items="filteredAgencies"
          :search="filters.search"
          :customFilter="customFilter"
          :itemsPerPage="15"
          :filterCount="filterCount"
          :isLoading="isLoading"
          @onSelect="handleSelect"
          @onClearFilters="clearFilters"
        >
          <template v-slot:action="{ item }">
            <DotMenu :items="menuItems(item)" @select="openActionDialog" />
          </template>
          <template v-slot:filters>
            <div class="filters">
              <v-col cols="12">
                <div class="subtitle">Status</div>
                <FilterProperty :selected="agencyStatusFilter">
                  <Select
                    :value="agencyStatusFilter"
                    @change="updateFilter"
                    label="Enter a status"
                    :items="statusItems"
                    hide-details
                    clearable
                  />
                </FilterProperty>
              </v-col>
            </div>
          </template>
          <template v-slot:status="{ item }">
            <Chip :color="getStatusColor(item.status)">
              {{ item.status | statusOutput }}
            </Chip>
          </template>
          <template v-slot:name="{ item }">
            {{ item.name }}
          </template>
        </Datatable>
        <Permissioned section="agencies">
          <CreateButtonMobile permission="create" @click.native="addAgency" />
        </Permissioned>
        <Dialog :data="updateAgencyDialog">
          <template v-slot:dialog.actions>
            <PrimaryButton
              @click.native="updateAgencyDialog.dialog = false"
              text
            >
              Cancel
            </PrimaryButton>
            <ErrorButton
              @click.native="
                handleUpdateAgencyStatus('archived', updateAgencyDialog.id)
              "
              :loading="isUpdating"
              v-if="updateAgencyDialog.status === 'live'"
            >
              Deactivate Agency
            </ErrorButton>
            <PrimaryButton
              @click.native="
                handleUpdateAgencyStatus('live', updateAgencyDialog.id)
              "
              :loading="isUpdating"
              v-else
            >
              Activate Agency
            </PrimaryButton>
          </template>
        </Dialog>
      </template>
    </app-layout>
  </div>
</template>

<script>
import appLayout from "@/components/common/base-layouts/AppLayout";
import Datatable from "@/components/common/Datatable";
import { DotMenu } from "@/components/common/Menu";
import Snackbar from "@/components/common/Snackbar";
import BaseSearchBar from "@/components/common/BaseSearchBar";
import { map, filter, capitalize, first, size, lowerCase, find } from "lodash";
import SnackbarError from "@/components/common/SnackbarError";
import Dialog from "@/components/common/Dialog";
import ErrorButton from "@/components/common/Button/ErrorButton";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import { filterCount } from "@/utils/filters";
import { getFullName } from "@/utils/users";
import { getColorByStatus } from "@/utils/agencies";
import { customTableFussyFilter } from "@/utils/filters";
import Permissioned from "@/components/common/Permissioned";
import NewAgencyAction from "@/views/agencies/actions/NewAgencyAction";
import EditAgencyAction from "@/views/agencies/actions/EditAgencyAction";
import {
  DEACTIVATE_AGENCY,
  FETCH_ALL_AGENCIES,
  PUBLISH_AGENCY,
  UPDATE_AGENCY
} from "@/store/modules/agencies/action-types";
import {
  SET_ERROR,
  SET_CURRENT_AGENCY,
  SET_AGENCY_STATUS_FILTER
} from "@/store/modules/agencies/mutation-types";
import { AGENCY_NAMESPACE } from "@/store/modules/agencies";
import { createNamespacedHelpers } from "vuex";
import Chip from "@/components/common/Chip";
import Select from "@/components/common/Select";
import FilterProperty from "@/components/common/FilterProperty";
import CreateButtonMobile from "@/components/common/CreateButtonMobile";
import { isPermissioned } from "@/utils/permissions";

const { mapMutations, mapState, mapActions } = createNamespacedHelpers(
  AGENCY_NAMESPACE
);

const INIT_FILTERS_DATA = {
  search: "",
  status: null
};

export default {
  components: {
    Permissioned,
    FilterProperty,
    BaseSearchBar,
    appLayout,
    Datatable,
    DotMenu,
    Snackbar,
    SnackbarError,
    Dialog,
    ErrorButton,
    PrimaryButton,
    Chip,
    Select,
    CreateButtonMobile,
    NewAgencyAction,
    EditAgencyAction
  },
  created() {
    this.getAllAgencies({ "filter[organisation_type]": "agency" });
    this.filters.status = this.agencyStatusFilter;
  },
  computed: {
    ...mapState({
      agencies: state => state.agencies,
      isLoading: state => state.isFetchingData,
      fetchingError: state => state.fetchingError,
      agencyStatusFilter: state => state.agencyStatusFilter
    }),
    statusItems() {
      return map(this.items, item =>
        capitalize(this.getStatusOutput(item.status))
      );
    },
    items() {
      return map(this.agencies, agency => {
        let admin = {};

        if (size(agency.admins) > 0) {
          admin = {
            name: getFullName(first(agency.admins)),
            email: first(agency.admins).email
          };
        }
        return { ...agency, admin };
      });
    },
    error() {
      return this.fetchingError;
    },
    isErrorVisible() {
      return !!this.error;
    },
    headers() {
      return [
        {
          text: "Id",
          value: "id",
          class: "max-width-200"
        },
        {
          text: "Name",
          value: "name",
          class: "min-width-200"
        },
        {
          text: "Administrator Name",
          value: "admin.name",
          class: "min-width-200"
        },
        {
          text: "Administrator Email",
          value: "admin.email",
          class: "min-width-200"
        },
        {
          text: "Status",
          value: "status",
          class: "max-width-200"
        },
        {
          text: "",
          value: "action",
          sortable: false,
          align: "end"
        }
      ];
    },
    searchKeys() {
      return map(this.headers, "value");
    },
    filterCount() {
      return filterCount(this.filters);
    },
    filteredAgencies() {
      return this.filters.status
        ? filter(this.items, agency => {
            const status = this.reversedStatusOutput(
              lowerCase(this.filters.status)
            );
            return this.filterStatus(agency, status);
          })
        : this.items;
    }
  },
  data() {
    return {
      filters: Object.assign({}, INIT_FILTERS_DATA),
      snackbar: {
        snackbar: false
      },
      updateAgencyDialog: {
        dialog: false,
        title: null,
        status: "live"
      },
      isUpdating: false,
      isNewAgencyDialogOpen: false,
      isEditAgencyDialogOpen: false,
      selectedAgency: {}
    };
  },
  methods: {
    ...mapMutations({
      hideError: SET_ERROR,
      setCurrentAgency: SET_CURRENT_AGENCY,
      setAgencyStatusFilter: SET_AGENCY_STATUS_FILTER
    }),
    ...mapActions({
      updateAgency: UPDATE_AGENCY,
      getAllAgencies: FETCH_ALL_AGENCIES,
      publishAgency: PUBLISH_AGENCY,
      deactivateAgency: DEACTIVATE_AGENCY
    }),
    addAgency() {
      this.isNewAgencyDialogOpen = true;
    },
    menuItems(item) {
      const { id, name, status } = item;
      let statusAction =
        lowerCase(status) === "live" ? "Deactivate" : "Activate";
      const actions = [
        this.showToggleStatusButton(id) && {
          title: `${statusAction} Agency`,
          action: () => {
            this.updateAgencyDialog = {
              dialog: true,
              title: `Are you sure you want to ${lowerCase(
                statusAction
              )} ${name}?`,
              status,
              id
            };
          }
        },
        this.showEditButton(id) && {
          title: `Edit Agency`,
          action: () => {
            // object destructing to prevent store mutation in next components
            this.selectedAgency = item;
            this.openEditDialog();
          }
        }
      ];

      return actions.filter(Boolean);
    },
    openEditDialog() {
      this.isEditAgencyDialogOpen = true;
    },
    filterStatus(agency, status) {
      return status === "archived"
        ? status === agency.status || "draft" === agency.status
        : status === agency.status;
    },
    handleDataChange(agency) {
      this.currentAgency = agency;
    },
    openActionDialog(action) {
      action();
    },
    handleUpdateAgencyStatus(status, id) {
      this.isUpdating = true;

      this[status === "live" ? "publishAgency" : "deactivateAgency"]({
        id,
        status
      })
        .then(() => {
          this.updateAgencyDialog.dialog = false;
          this.getAllAgencies({ "filter[organisation_type]": "agency" });
        })
        .finally(() => (this.isUpdating = false));
    },
    clearFilters() {
      this.filters = Object.assign({}, INIT_FILTERS_DATA);
      this.setAgencyStatusFilter("");
    },
    updateFilter(status) {
      this.setAgencyStatusFilter(status);
      this.filters.status = status;
    },
    getStatusColor(status) {
      return status ? getColorByStatus(status) : "";
    },
    getStatusOutput(status) {
      return status === "live" ? "ACTIVE" : "INACTIVE";
    },
    reversedStatusOutput(status) {
      return status === "active" ? "live" : "archived";
    },
    customFilter(value, searchValue, item) {
      return customTableFussyFilter(searchValue, item, this.searchKeys);
    },
    closeNewAgencyDialog() {
      this.isNewAgencyDialogOpen = false;
    },
    closeEditAgencyDialog() {
      this.isEditAgencyDialogOpen = false;
    },
    showToggleStatusButton(id) {
      return isPermissioned(`agencies[${id}].toggle-status`);
    },
    showEditButton(id) {
      return isPermissioned(`agencies[${id}].edit`);
    },
    handleSelect({ id }) {
      const selectedAgency = find(this.agencies, agency => agency.id === id);
      this.setCurrentAgency(selectedAgency);
      this.$router.push({
        name: "agencyDetails",
        params: { id }
      });
    }
  },
  filters: {
    statusOutput(status) {
      return status === "live" ? "ACTIVE" : "INACTIVE";
    }
  }
};
</script>

<style lang="scss">
.agency__container {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}

.agency {
  max-width: 1100px;
  width: 100%;

  .v-toolbar__title {
    width: 50%;
  }
  .v-card__text {
    color: #000 !important;
  }
  tr {
    cursor: pointer;
  }
}
</style>
