import actions from "@/store/modules/organisations/organisation-schedules/actions";
import organisationSchedulesGetters from "@/store/modules/organisations/organisation-schedules/getters";
import {
  mutations,
  state
} from "@/store/modules/organisations/organisation-schedules/mutations";

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters: organisationSchedulesGetters
};
