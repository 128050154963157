<template>
  <DialogFull
    progress
    :data="dialogSettings"
    :steps="{ current: step, total: totalSteps }"
    @close="close"
    @onStepBack="stepBack"
  >
    <SelectWorkers
      v-if="shouldStepViewBeShown(1)"
      v-show="shouldStepViewBeShown(1)"
      :isLoading="isLoadingCandidates || isFetchingUnavailableCandidates"
      :shifts="selectedShifts"
      :workers="candidates"
      :unavailableCandidates="unavailableCandidates"
      :validate="shouldStepViewBeShown(1) && shouldValidate"
      :searchKey="searchKey"
      :isV2="isV2"
      @change="setSelectedWorkers"
      @valid="stepForward"
      @invalid="shouldValidate = false"
      @search="handleSearch"
    />
    <ConfirmAllocation
      v-if="shouldStepViewBeShown(2)"
      v-show="shouldStepViewBeShown(2)"
      @confirm="confirmAllocation"
      :workers="selectedWorkers"
      :shifts="selectedShifts"
    />
    <template class="justify-content-between" v-slot:dialog.action>
      <v-checkbox
        v-if="step === 2 && showFooter && withUnavilableCandidates"
        v-model="allowUnavailableCandidates"
        :label="unavailabelLabel"
      />
      <PrimaryButton @click.native="validateStep" v-if="step === 1">
        Continue
      </PrimaryButton>
      <PrimaryButton
        @click.native="confirmAllocation"
        :loading="isLoading"
        v-if="step === 2 && showFooter"
        :disabled="!allowUnavailableCandidates && withUnavilableCandidates"
      >
        Confirm Allocation
      </PrimaryButton>
    </template>
  </DialogFull>
</template>

<script>
import DialogFull from "@/components/common/DialogFull";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import SelectWorkers from "@/views/bookings/BookingDetails/components/Shifts/AllocateWorkers/steps/SelectWorkers";
import ConfirmAllocation from "@/views/bookings/components/ConfirmAllocation";
import pluralize from "pluralize";
import {
  ALLOCATE_CANDIDATES,
  FETCH_SHIFT_CANDIDATES,
  FETCH_UNAVAILABLE_CANDIDATES
} from "@/store/modules/applications/actions";
import { createNamespacedHelpers } from "vuex";
import { APPLICATION_NAMESPACE } from "@/store/modules/applications";
import {
  GET_SHIFT_CANDIDATES,
  IS_LOADING_SHIFT_CANDIDATES,
  GET_UNAVAILABLE_CANDIDATES,
  GET_IS_FETCHING_UNAVAILABLE_CANDIDATES
} from "@/store/modules/applications/getters";
import { getFullName, getUnavailableReason } from "@/utils/users";
import { map, filter, size, some } from "lodash";
import { mapActions } from "vuex";
import { POST_GLOBAL_MESSAGE } from "@/store/modules/global/action-types";

const {
  mapActions: mapApplicationActions,
  mapGetters: mapApplicationGetters
} = createNamespacedHelpers(APPLICATION_NAMESPACE);

export default {
  name: "AllocateWorkers",
  components: {
    ConfirmAllocation,
    SelectWorkers,
    DialogFull,
    PrimaryButton
  },
  data() {
    return {
      step: 1,
      totalSteps: 2,
      selectedWorkers: [],
      shouldValidate: false,
      isLoading: false,
      showFooter: true,
      searchKey: "",
      allowUnavailableCandidates: false
    };
  },
  inject: ["currentBookingId"],
  created() {
    const params = {
      bookingId: this.currentBookingId,
      selectedShifts: this.selectedShifts.length
        ? this.selectedShifts.map(shift => shift.id)
        : []
    };
    this.fetchShiftCandidates(params);
  },
  computed: {
    ...mapApplicationGetters({
      shiftCandidates: GET_SHIFT_CANDIDATES,
      isLoadingCandidates: IS_LOADING_SHIFT_CANDIDATES,
      isFetchingUnavailableCandidates: GET_IS_FETCHING_UNAVAILABLE_CANDIDATES,
      unavailableCandidates: GET_UNAVAILABLE_CANDIDATES
    }),
    candidates() {
      const filteredCandidates = filter(
        this.shiftCandidates,
        candidate => candidate.status === "approved"
      );
      return map(filteredCandidates, candidate => ({
        ...candidate.user,
        fullName: getFullName(candidate.user),
        ...candidate
      }));
    },
    progressValue() {
      return (100 * this.step) / this.totalSteps;
    },
    dialogSettings() {
      return {
        title: "Allocate Workers",
        dialog: this.settings.dialog
      };
    },
    withUnavilableCandidates() {
      return some(this.selectedWorkers, worker => worker.isUnavailable);
    },
    unavailabelLabel() {
      const unapprovedText = "approved by the client";
      const unsubmittedText = "submitted by the agency";
      let reasonText = unsubmittedText;
      const isThereUnapproved = getUnavailableReason(
        this.selectedWorkers,
        true
      );
      const isThereUnsubmitted = getUnavailableReason(
        this.selectedWorkers,
        false
      );
      if (isThereUnapproved && !isThereUnsubmitted) {
        reasonText = unapprovedText;
      }
      if (isThereUnapproved && isThereUnsubmitted) {
        reasonText = `${unapprovedText} or ${unsubmittedText}`;
      }
      return `Allocate workers who haven’t been ${reasonText} for ${pluralize(
        "this",
        size(this.selectedShifts)
      )} ${pluralize("shift", size(this.selectedShifts))}`;
    }
  },
  methods: {
    ...mapActions({
      postGlobalMessage: POST_GLOBAL_MESSAGE
    }),
    ...mapApplicationActions({
      allocateWorkers: ALLOCATE_CANDIDATES,
      fetchShiftCandidates: FETCH_SHIFT_CANDIDATES,
      fetchUnavailableCandidates: FETCH_UNAVAILABLE_CANDIDATES
    }),
    setSelectedWorkers(selectedWorkers) {
      this.selectedWorkers = selectedWorkers;
    },
    stepBack() {
      if (this.step > 1) {
        this.step -= 1;
      }
    },
    stepForward() {
      this.step += 1;
      this.shouldValidate = false;
    },
    validateStep() {
      this.shouldValidate = true;
    },
    async confirmAllocation() {
      this.isLoading = true;
      try {
        const users = this.selectedWorkers.map(worker => {
          return { id: worker.isUnavailable ? worker.id : worker.user.id };
        });
        const allocationRequests = map(this.selectedShifts, (shift, index) => {
          return new Promise(resolve => setTimeout(resolve, index * 300)).then(
            () =>
              this.allocateWorkers({
                bookingId: this.currentBookingId,
                shiftId: shift.id,
                users
              })
          );
        });

        await Promise.all(allocationRequests);
        this.$emit("updateList");
        this.close();
        this.postSuccesMessage(size(allocationRequests));
      } finally {
        this.isLoading = false;
      }
    },
    postSuccesMessage(allocationsSize) {
      const message = {
        snackbar: true,
        type: "success",
        title: `${pluralize("Shift", allocationsSize)} allocated.`
      };
      this.postGlobalMessage(message);
    },
    close() {
      this.settings.dialog = false;
      this.step = 1;
    },
    shouldStepViewBeShown(viewStep) {
      return this.step === viewStep;
    },
    ifStepHasAlreadyBeenCompleted(viewStep) {
      return viewStep <= this.savedStep;
    },
    handleSearch(search) {
      this.searchKey = search;
      if (size(search) > 1) {
        const params = {
          search,
          bookingId: this.currentBookingId,
          selectedShifts: this.selectedShifts.length
            ? this.selectedShifts.map(shift => shift.id)
            : []
        };
        this.fetchUnavailableCandidates(params);
      }
    }
  },
  props: {
    settings: {
      type: Object,
      required: true
    },
    selectedShifts: {
      type: Array,
      required: true
    },
    isV2: Boolean
  }
};
</script>

<style scoped lang="scss">
.allocate-workers-dialog {
  .v-stepper__content {
    padding: 24px 0 0;
  }

  .toolbar {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;

    button {
      text-transform: none;
      flex: 0;
    }
    .back-arrow {
      width: 36px;
      display: flex;

      @media only screen and (max-width: $tablet-breakpoint) {
        .v-btn.v-btn--flat.v-btn--text.theme--light.v-size--default {
          min-width: 36px;
          padding: 0;
        }
      }
    }
  }

  .progress-bar {
    background-color: $background;
  }
}
</style>
