<template>
  <BaseButton
    :disabled="disabled"
    class="base-error-button"
    :class="{ text, light }"
    :loading="loading"
    :dense="dense"
    :fullWidth="fullWidth"
    :shrink="shrink"
    @click="handleClick"
  >
    <slot name="prepend:icon" />
    <slot />
    <slot name="append:icon" />
  </BaseButton>
</template>

<script>
import BaseButton from "@/components/common/Button/BaseButton";

export default {
  name: "ErrorButton",
  components: { BaseButton },
  props: {
    text: Boolean,
    loading: Boolean,
    disabled: Boolean,
    light: Boolean,
    fullWidth: Boolean,
    dense: Boolean,
    shrink: Boolean
  },
  methods: {
    handleClick(event) {
      this.$emit("click", event);
    }
  }
};
</script>

<style lang="scss">
.base-error-button {
  &.v-btn {
    color: $error !important;

    &:not(.text) {
      &.light {
        background-color: $error-transparent !important;
      }

      &:not(.light) {
        color: white !important;
        background-color: $error !important;
      }
    }

    &.text {
      color: $error !important;
      background-color: $white !important;
    }
  }
}
</style>
