<template>
  <AppLayout
    parent="/settings/organisation/templates"
    :title="currentBookingTemplateName"
    isDetails
  >
    <template v-slot:body>
      <div>
        <v-row class="booking-details">
          <v-col class="col-12">
            <BasicDetails
              :bookingTemplate="currentBookingTemplate"
              :isLoading="isFetchingBookingTemplate"
            />
            <v-divider />
            <CostSection
              :bookingTemplate="currentBookingTemplate"
              :isLoading="isFetchingBookingTemplate"
            />
          </v-col>
        </v-row>
      </div>
    </template>
  </AppLayout>
</template>
<script>
import BasicDetails from "@/views/settings/Organisation/BookingTemplates/BookingTemplateDetails/components/BasicDetails";
import CostSection from "@/views/settings/Organisation/BookingTemplates/BookingTemplateDetails/components/CostSection";
import AppLayout from "@/components/common/base-layouts/AppLayout";
import { createNamespacedHelpers } from "vuex";
import { ORGANISATION_BOOKING_TEMPLATES_NAMESPACE } from "@/store/modules/organisations/organisation-booking-templates";
import { FETCH_ORGANISATION_BOOKING_TEMPLATE } from "@/store/modules/organisations/organisation-booking-templates/actions";
import { copyState } from "@/utils/helpers";

const { mapActions, mapState } = createNamespacedHelpers(
  ORGANISATION_BOOKING_TEMPLATES_NAMESPACE
);

export default {
  name: "BookingTemplateDetails",
  components: {
    AppLayout,
    BasicDetails,
    CostSection
  },
  async created() {
    await this.fetchOrganisationBookingTemplate(this.currentBookingTemplateId);
  },
  computed: {
    ...mapState(
      copyState(["currentBookingTemplate", "isFetchingBookingTemplate"])
    ),
    currentBookingTemplateId() {
      return this.$route.params.id;
    },
    currentBookingTemplateName() {
      return this.currentBookingTemplate.jobTitle;
    }
  },
  methods: {
    ...mapActions({
      fetchOrganisationBookingTemplate: FETCH_ORGANISATION_BOOKING_TEMPLATE
    })
  }
};
</script>

<style lang="scss"></style>
