<template>
  <div>
    <h2 class="mb-2">Group By</h2>
    <v-list>
      <v-list-item
        class="my-2"
        v-for="(grouping, index) in groupings"
        :key="index"
      >
        <ReportGrouping
          :index="index"
          :grouping="grouping"
          :labels="labels"
          :availableFields="patchAvailableFields(grouping.field)"
          :showValidationErrors="showValidationErrors"
          :onChange="handleChange(index)"
          :onDelete="handleDelete(index)"
        />
      </v-list-item>
    </v-list>
    <v-btn
      v-if="availableFields.length && groupings.length < 2"
      @click="addGrouping"
      text
      color="primary"
      class="add-item my-2"
    >
      Add grouping +
    </v-btn>
  </div>
</template>

<script>
import ReportGrouping from "@/views/reports/forms/ReportGrouping.vue";
import { size, find } from "lodash";

export default {
  name: "ReportGroupingSection",
  props: {
    fields: Array,
    groupings: Array,
    labels: Object,
    showValidationErrors: Boolean
  },
  components: {
    ReportGrouping
  },
  computed: {
    availableFields() {
      return this.fields.filter(field => {
        const isIdField = field.field === "id";
        const isAlreadySelected = find(this.groupings, { field: field.field });
        const isCount = field.field === "count";
        return !isIdField && !isAlreadySelected && !isCount;
      });
    }
  },
  methods: {
    addGrouping() {
      if (this.availableFields.length > 0) {
        const field = this.availableFields[0];
        this.groupings.push({
          field: field.field,
          ...(field.type === "number" && {
            interval_number: field.intervals[0]
          }),
          ...(field.type === "datetime" && {
            interval_date: field.intervals[0]
          })
        });
      }
    },
    handleChange(index) {
      return newGrouping => {
        this.groupings.splice(index, 1, newGrouping);
      };
    },
    handleDelete(index) {
      return () => {
        this.groupings.splice(index, 1);
      };
    },
    patchAvailableFields(fieldName) {
      return [
        this.fields.find(field => field.field === fieldName),
        ...this.availableFields
      ];
    },
    size
  },
  watch: {
    groupings(newValue) {
      this.$emit("updated", newValue);
    }
  }
};
</script>
