export const SUMMARY_MODEL = "summary";

const summaryModel = {
  name: "",
  organisationId: "",
  summaries: [],
  events: []
};

export default summaryModel;
