<template>
  <div>
    <v-text-field
      aria-label="Search"
      class="dynamic-search-bar"
      v-model="searchText"
      :prepend-inner-icon="'mdi-magnify'"
      :placeholder="placeholder"
      @input="handleSearch"
      color="primary"
      clearable
      hide-details
      v-lazy-input:debounce="1000"
    />
  </div>
</template>

<script>
export default {
  name: "DynamicSearchBar",
  data() {
    return {
      searchText: null
    };
  },
  props: {
    placeholder: {
      type: String,
      required: false,
      default: "Search"
    }
  },
  methods: {
    handleSearch() {
      this.$emit("search", this.searchText);
    }
  }
};
</script>

<style lang="scss">
:not(.v-input--is-focused).dynamic-search-bar .v-input__slot {
  border: none !important;

  .theme--light.v-icon {
    color: $primary;
  }
}

.dynamic-search-bar {
  .v-input__slot {
    max-width: 260px;
    height: 52px;

    input::placeholder {
      color: $primary !important;
      font-weight: 700;
    }

    @media only screen and (max-width: $large-tablet-breakpoint) {
      margin: auto;
    }
  }
}

.v-input.dynamic-search-bar.v-text-field {
  padding-top: 0;
  margin-top: 0;
  i {
    margin-top: 5px;
  }
}
</style>
