import { setStoreValue } from "@/store/utils";

export const SET_ORGANISATION_REASONS = "SET_ORGANISATION_REASONS";
export const SET_IS_FETCHING_REASONS = "SET_IS_FETCHING_REASONS";

const mutations = {
  [SET_ORGANISATION_REASONS]: setStoreValue("organisationReasons"),
  [SET_IS_FETCHING_REASONS]: setStoreValue("isFetchingReasons")
};

const state = {
  organisationReasons: [],
  isFetchingReasons: false
};

export { mutations, state };
