<template>
  <BaseAction>
    <SecondaryButton
      @click.native="openSubmitForApprovalDialog"
      color="primary"
    >
      <v-icon>mdi-briefcase-upload-outline</v-icon>
      Submit for Approval
    </SecondaryButton>
    <BookingApprovalDialog
      :isOpen="isSubmitForApprovalOpen"
      @close="closeSubmitForApprovalDialog"
    />
  </BaseAction>
</template>

<script>
import BookingApprovalDialog from "@/views/bookings/components/ConfirmDialogs/BookingApprovalDialog";
import BaseAction from "@/components/common/BaseAction";
import SecondaryButton from "@/components/common/Button/SecondaryButton";

export default {
  name: "SubmitForApprovalAction",
  components: { BaseAction, BookingApprovalDialog, SecondaryButton },
  data() {
    return {
      isSubmitForApprovalOpen: false
    };
  },
  methods: {
    openSubmitForApprovalDialog() {
      this.isSubmitForApprovalOpen = true;
    },
    closeSubmitForApprovalDialog() {
      this.isSubmitForApprovalOpen = false;
    }
  }
};
</script>
