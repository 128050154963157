<template>
  <BaseDetails class="base-details" v-if="hasValue">
    <template v-slot:header>
      <h2 class="pb-3">Internal Information</h2>
      <Permissioned section="bookings">
        <PrimaryButton
          v-if="canEdit"
          @click.native="openInternalInfoEdit"
          light
          dense
          shrink
        >
          Edit
          <template v-slot:append:icon
            ><v-icon class="ml-1">mdi-pencil</v-icon></template
          >
        </PrimaryButton>
      </Permissioned>
    </template>

    <template v-slot:body>
      <EditInternalInfo
        v-if="isEditOpen"
        :internal-info="booking"
        :is-open="isEditOpen"
        :onlyApproverVerifier="onlyApproverVerifier"
        @close="closeInternalInfoEdit"
      />
      <Permissioned section="bookings.view" vertical>
        <LabeledText label="Cost Code" permission="cost-centre">
          {{ costCodeWithClientName }}
        </LabeledText>
        <LabeledText label="Hiring Manager" permission="hiring-manager">
          <div>{{ booking.manager ? getFullName(booking.manager) : "-" }}</div>
        </LabeledText>
        <LabeledText label="Timesheet Approver" permission="timesheet-approver">
          <div>
            {{ booking.approver ? getFullName(booking.approver) : "-" }}
          </div>
        </LabeledText>
        <LabeledText label="Timesheet Verifier" permission="timesheet-verifier">
          <div>
            {{ booking.verifier ? getFullName(booking.verifier) : "-" }}
          </div>
        </LabeledText>
        <LabeledText label="Reason for Hire" permission="reason-for-hire">
          {{ booking.hireReason }}
        </LabeledText>
        <LabeledText label="Expenses" permission="expenses">
          Worker can<span v-if="!booking.expensesAllowed">not</span>
          claim expenses
        </LabeledText>
        <div class="d-flex justify-space-between">
          <LabeledText label="PO Number" permission="po-number">
            <div class="d-flex">
              <div class="mr-2">
                {{ booking.poNumber ? booking.poNumber : "-" }}
              </div>
              <Permissioned section="budgets">
                <div permission="view" v-if="budgets.length">
                  ({{ totalRemainingBudget | currency(currencyCode) }}
                  of
                  {{ totalBudget | currency(currencyCode) }}
                  budget remaining)
                </div>
              </Permissioned>
            </div>
          </LabeledText>
          <div class="d-flex">
            <Permissioned section="bookings.edit">
              <EditPONumberAction class="mr-2" permission="po-number" />
            </Permissioned>
            <Permissioned section="budgets">
              <EditBudgetAction
                :booking="booking"
                :budget="budget ? budget : {}"
                permission="edit"
              />
            </Permissioned>
          </div>
        </div>
        <LabeledText label="Post Number" permission="post-number">
          <div>{{ booking.postNumber ? booking.postNumber : "-" }}</div>
        </LabeledText>
        <LabeledText label="Shifts Filled">
          {{ `${booking.filledPercentage}%` }}
        </LabeledText>
        <LabeledText label="Internal Notes" permission="internal-notes">
          {{ booking.internalNotes || "-" }}
        </LabeledText>
      </Permissioned>
    </template>
  </BaseDetails>
</template>

<script>
import LabeledText from "@/components/common/BaseDetails/LabeledText";
import BaseDetails from "@/components/common/BaseDetails/BaseDetails";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import EditInternalInfo from "@/views/bookings/EditBooking/EditInternalInfo";
import { createNamespacedHelpers } from "vuex";
import { BOOKINGS_NAMESPACE } from "@/store/modules/bookings/actions";
import Permissioned from "@/components/common/Permissioned";
import { getFullName } from "@/utils/users";
import { isPermissioned } from "@/utils/permissions";
import EditPONumberAction from "../actions/EditPONumberAction";
import { first, reduce } from "lodash";
import EditBudgetAction from "../actions/EditBudgetAction";

const { mapState } = createNamespacedHelpers(BOOKINGS_NAMESPACE);

export default {
  name: "InternalInformation",
  components: {
    Permissioned,
    EditInternalInfo,
    PrimaryButton,
    LabeledText,
    BaseDetails,
    EditPONumberAction,
    EditBudgetAction
  },
  data() {
    return {
      isEditOpen: false
    };
  },
  computed: {
    ...mapState({
      currentBooking: state => state.currentBooking,
      budgets: state => state.budgets
    }),
    booking() {
      return this.currentBooking;
    },
    costCodeWithClientName() {
      return this.booking.client
        ? `${this.booking.client.name} (${this.booking.costCode})`
        : this.booking.costCode;
    },
    hasValue() {
      return (
        this.booking.costCode ||
        this.booking.manager ||
        this.booking.approver ||
        this.booking.verifier ||
        this.booking.hireReason ||
        this.booking.poNumber ||
        this.booking.postNumber
      );
    },
    canEdit() {
      return isPermissioned("bookings.edit") || this.onlyApproverVerifier;
    },
    onlyApproverVerifier() {
      return (
        !isPermissioned("bookings.edit") &&
        (isPermissioned("bookings.edit.timesheet-approver") ||
          isPermissioned("bookings.edit.timesheet-verifier"))
      );
    },
    totalRemainingBudget() {
      return reduce(
        this.budgets,
        (totalRemainingBudget, budget) => {
          return totalRemainingBudget + budget.remaining_budget;
        },
        0
      );
    },
    totalBudget() {
      return reduce(
        this.budgets,
        (totalBudget, budget) => {
          return totalBudget + budget.total;
        },
        0
      );
    },
    budget() {
      return first(this.budgets);
    },
    currencyCode() {
      return this.currentBooking.currencyCode
        ? this.currentBooking.currencyCode
        : "GBP";
    }
  },
  methods: {
    getFullName,
    openInternalInfoEdit() {
      this.isEditOpen = true;
    },
    closeInternalInfoEdit() {
      this.isEditOpen = false;
    }
  }
};
</script>
